<mat-dialog-content scrollEnd (endReached)="scrollEndReached()">
  <h3>
    {{ 'connect_jibbie_dialog.title' | translate: {
      organizationUnitType: getOUTypeName(data.organizationUnitType) | translate,
      organizationUnitName: data.organizationUnitName
    } }}
  </h3>
  <form [formGroup]="jibbieSearch" text-center>
    <mat-form-field class="organization-name" appearance="outline">
      <mat-label>Jibbie {{ getOUTypeName(data.organizationUnitType) | translate }}</mat-label>
      <input formControlName="name" matInput>
    </mat-form-field>

    <div class="jibbie-search-results">
      <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.LOCATION && jibbieDetails">
        <ng-container *ngFor="let jibbieLocation of (jibbieLocationResults$ | async)">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-icon mat-card-avatar svgIcon="doenkids-location"></mat-icon>
              <mat-card-title>{{jibbieLocation.name}} <span class="jibbie-title-suffix">({{ jibbieLocation.type }})</span></mat-card-title>
              <mat-card-subtitle>
                <strong>{{jibbieLocation.external_id}} </strong>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p>
                <span *ngIf="jibbieLocation.street">{{jibbieLocation.street}}</span>&nbsp;
                <span *ngIf="jibbieLocation.housenumber">{{jibbieLocation.housenumber}}</span>
              </p>
              <p>
                <span *ngIf="jibbieLocation.postalcode">{{jibbieLocation.postalcode}}</span>&nbsp;
                <span *ngIf="jibbieLocation.city">{{jibbieLocation.city}}</span>
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button (click)="linkJibbieLocationToOrganization(jibbieLocation)" mat-button>
                {{ 'generic.link' | translate | uppercase }}
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="(jibbieLocationResults$ | async)?.length === 0">
          <span>{{ 'connnect_jibbie_dialog.no_jibbie_locations' | translate }}</span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.ORGANIZATION">
        <ng-container *ngFor="let jibbieOrganisatie of (jibbieOrganisationResults$ | async)">
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-icon mat-card-avatar svgIcon="doenkids-customer"></mat-icon>
              <mat-card-title>{{jibbieOrganisatie.name}}</mat-card-title>
              <mat-card-subtitle>
                <strong>{{jibbieOrganisatie.slug}} </strong>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
              <button (click)="linkJibbieOrganisationToOrganization(jibbieOrganisatie)" mat-button>
                {{ 'generic.link' | translate | uppercase }}
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="(jibbieOrganisationResults$ | async)?.length === 0">
          <span>{{ 'connect_jibbie_dialog.no_jibbie_organizations' | translate }}</span>
        </ng-container>
      </ng-container>
    </div>
  </form>
</mat-dialog-content>
