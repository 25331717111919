import { Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  DoenkidsSessionProvider,
  IDoenKidsSession,
} from 'src/providers/session.provider';
import { isNil } from 'lodash';
import { filter, map } from 'rxjs/operators';
import { IMediaItem } from 'typings/section-types';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-panel',
  templateUrl: './profile-panel.component.html',
  styleUrls: ['./profile-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [],
})
export class ProfilePanelComponent {
  public stop$ = new Subject<void>();

  public session$: BehaviorSubject<IDoenKidsSession>;

  // The profile picture
  //
  mediaItem$: Observable<IMediaItem>;

  // The e-mail address of the logged-in user
  //
  emailAddress$;

  avatarPlaceholder = environment.avatarPlaceholder;

  constructor(public $doenkidsSession: DoenkidsSessionProvider, private router: Router) {
    this.session$ = this.$doenkidsSession.getSession$;

    this.mediaItem$ = this.session$.pipe(
      filter((session) => !isNil(session)),
      map((session: IDoenKidsSession) => {
        const { mediaUuid } = session;
        const mediaItem: IMediaItem = {
          uuid: mediaUuid,
          description: '',
        };
        return mediaItem;
      }),
    );

    this.emailAddress$ = this.session$.pipe(
      filter((session) => !isNil(session)),
      map((session: IDoenKidsSession) => session.emailAddress),
    );
  }

  async navigateToProfile() {
    const organization = this.$doenkidsSession.getCurrentOu();
    const currentUser = this.$doenkidsSession.getCurrentUser();

    this.router.navigate([`/organization/${organization.id}/users/${currentUser.user.id}`]);
  }
}
