<mat-dialog-content class="add-organization-dialog-content" scrollEnd (endReached)="scrollEndReached()">
  <h3>{{ 'update_ou_lrk_identifier_dialog.title' | translate: {organizationUnitName: data.currentOu.name} }}</h3>
  <mat-spinner class="center-horizontal" *ngIf="(loading$ | async)"></mat-spinner>
  <div *ngIf="!(loading$ | async)">
    <form [formGroup]="lrkSearch" text-center>
      <mat-form-field class="organization-name" appearance="outline">
        <mat-label>{{ 'lrk_location.name.label' | translate }}</mat-label>
        <input formControlName="name" matInput (keydown.enter)="$event.preventDefault()">
      </mat-form-field>

      <div class="lrk-search-results">
        <ng-container *ngFor="let organization of (lrkSearchResults$ | async)" >
          <mat-card appearance="outlined" *ngVar="!!organization.used_by_organization_unit_id as inUse" [class.lrk-in-use]="inUse">
            <mat-card-header>
              <mat-icon mat-card-avatar svgIcon="doenkids-location"></mat-icon>
              <mat-card-title>{{organization.actuele_naam_oko}}</mat-card-title>
              <mat-card-subtitle>
                <strong>{{organization.lrk_id}} </strong>
                <span *ngIf="organization.correspondentie_adres">{{organization.correspondentie_adres}}, </span>
                <span *ngIf="organization.correspondentie_postcode">{{organization.correspondentie_postcode}}, </span>
                <span *ngIf="organization.correspondentie_woonplaats">{{organization.correspondentie_woonplaats}}</span>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p>
                <span *ngIf="organization.naam_houder">
                  {{ 'lrk_location.owner' | translate: {locationOwner: organization.naam_houder} }},
                </span>
                <span *ngIf="organization.status">
                  {{ 'lrk_location.status' | translate: {status: organization.status} }}
                </span>
              </p>
              <p *ngIf="inUse">
                <span class="in-use">
                  {{ 'lrk_location.already_in_use' | translate: {organizationUnitName: organization.used_by_organization_unit_name} }}
                </span>
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button [disabled]="inUse" (click)="linkLRKToOU(organization)" mat-button>
                {{ 'generic.link' | translate | uppercase }}
              </button>
            </mat-card-actions>
          </mat-card>
        </ng-container>
      </div>
    </form>
  </div>
</mat-dialog-content>
