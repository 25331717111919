import {
  Component, OnInit, ExistingProvider, forwardRef, HostListener,
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, NG_VALUE_ACCESSOR, UntypedFormArray, Validators,
} from '@angular/forms';
import { noop, isNil } from 'lodash';
import { IYoutube } from 'typings/section-types';

export const SECTION_YOUTUBE_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => YoutubeSectionComponent),
  multi: true,
};

@Component({
  selector: 'app-youtube-section',
  templateUrl: './youtube-section.component.html',
  styleUrls: ['./youtube-section.component.scss'],
  providers: [SECTION_YOUTUBE_CONTROL_VALUE_ACCESSOR],
})
export class YoutubeSectionComponent implements OnInit {
  public form: UntypedFormGroup;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: IYoutube) => void = noop;

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  public get videoFormArray(): UntypedFormArray {
    return this.form.get('video') as UntypedFormArray;
  }

  constructor(public fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      subtitle: [''],
      video: this.fb.array([this.fb.control('', Validators.required)]),
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((formValue) => {
      this.onChangeCallback(formValue);
    });
  }

  writeValue(value: IYoutube) {
    if (value) {
      this.form.get('title').setValue(value.title || '');
      this.form.get('subtitle').setValue(value.subtitle || '');

      const videoFormArray = this.form.get('video') as UntypedFormArray;

      value.video.forEach((video, index) => {
        const control = videoFormArray.controls[index];
        if (isNil(control)) {
          videoFormArray.push(this.fb.control(video));
        }
      });

      videoFormArray.setValue(value.video || []);
    }
  }

  removeVideo(index) {
    this.videoFormArray.removeAt(index);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
