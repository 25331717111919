<app-doenkids-menu-bar [showBackButton]="true">
  <div class="left-items" left>
    <button
      *ngIf="!(isReader$ | async) && currentFolder?.activities?.length > 0"
      mat-icon-button
      type="button"
      (click)="createProgram()"
      [matTooltip]="'pinboard.folder.program.create.tooltip' | translate"
    >
      <mat-icon>date_range</mat-icon>
    </button>
    <mat-checkbox *ngIf="!(isReader$ | async) && currentFolder?.activities?.length > 0" class="top-checkbox" [formControl]="selectActivitiesControl">
      {{ 'pinboard.folder.activities.select.label' | translate }}
    </mat-checkbox>
    <a *ngIf="!(isReader$ | async)" class="activities-link" (click)="goToActivities()">
      {{ 'pinboard.folder.activities.add.label' | translate }}
    </a>
  </div>
  <div right>
    <button
      *ngIf="( hasOUWritePermission$ | async )"
      mat-icon-button
      type="button"
      (click)="deletePinbordFolder()"
      [matTooltip]="'pinboard.folder.delete.tooltip' | translate"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</app-doenkids-menu-bar>
<div class="page-content" *ngIf="currentFolder">
  <h2>{{ currentFolder.name }}</h2>
  <p *ngIf="currentFolder.activities.length === 0">
    <span>{{ 'pinboard.folder.activities.none' | translate }}&nbsp;</span>
  </p>
  <ngx-masonry *ngIf="currentFolder.activities.length > 0"
    [options]="{ gutter: 25, transitionDuration: '0s', fitWidth: true  }">
    <ngxMasonryItem class="masonry-item" *ngFor="let activity of currentFolder.activities">
      <app-activity-card (click)="activityClicked( activity )" [activity]="activity"
        [class.selected]="( isSelectingActivties$ | async ) && activityIsSelected(activity)"></app-activity-card>
    </ngxMasonryItem>
  </ngx-masonry>

</div>
<app-selected-activities [pinbord]="currentFolder" *ngIf="!(isReader$ | async) && (isSelectingActivties$ | async)"></app-selected-activities>
