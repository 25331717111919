import {
  Component, OnDestroy, ViewEncapsulation, Inject,
} from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IActivityType, ICustomerSymbol } from 'typings/doenkids/doenkids';
import { OrganizationUnitSymbolListService } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.service';
import { IMediaItem } from 'typings/section-types';
import { IUploadResponse } from 'typings/custom-app-types';
import { isNil, max } from 'lodash';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';

@Component({
  selector: 'app-add-organization-customer-symbol-dialog',
  templateUrl: './add-organization-customer-symbol-dialog.component.html',
  styleUrls: ['./add-organization-customer-symbol-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddOrganizationCustomerSymbolDialogComponent implements OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public newSymbolForm: UntypedFormGroup;

  public activityTypes$: Observable<IActivityType[]>;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddOrganizationCustomerSymbolDialogComponent>,
    private organizationUntiSymbolListService: OrganizationUnitSymbolListService,
    private $session: DoenkidsSessionProvider,
    @Inject(MAT_DIALOG_DATA) public data: {
      organizationUnitId: number,
      organizationUnitName: string,
      currentSymbols: { [activityTypeId: string]: { [symbolId: string]: ICustomerSymbol } }
    },
  ) {
    this.newSymbolForm = this.fb.group({
      activity_type_id: new UntypedFormControl(null),
      name: ['', Validators.required],
      media_uuid: ['', Validators.required],
    });

    this.activityTypes$ = this.$session.availableActivityTypes$.pipe(takeUntil(this.stop$));
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  async createNewSymbol() {
    const symbolFormValues = this.newSymbolForm.value;
    const newSymbolActivityTypeId = symbolFormValues.activity_type_id ? symbolFormValues.activity_type_id : null;
    let newSymbolDisplayOrder = 0;
    const currentActivityTypeSymbols = this.data.currentSymbols[newSymbolActivityTypeId];

    if (!isNil(currentActivityTypeSymbols)) {
      const currentMaxDisplayOrder = max(Object.values(currentActivityTypeSymbols).map((symbol) => symbol.display_order ?? 0));

      if (currentMaxDisplayOrder > 0) {
        newSymbolDisplayOrder = currentMaxDisplayOrder + 1;
      }
    }

    const newSymbol: ICustomerSymbol = {
      name: symbolFormValues.name,
      media_uuid: symbolFormValues.media_uuid,
      activity_type_id: newSymbolActivityTypeId,
      display_order: newSymbolDisplayOrder,
    };

    await this.organizationUntiSymbolListService.create(this.data.organizationUnitId, newSymbol);

    this.closeDialog();
  }

  addSymbolImage($event: IUploadResponse | IMediaItem) {
    this.newSymbolForm.get('media_uuid').setValue($event.uuid);
  }

  closeDialog() {
    this.stop$.next();
    this.dialogRef.close('success');
  }
}
