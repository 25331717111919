import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { IProgramSummaryPeriod, IProgramSummaryPeriodSection } from 'typings/api-activity';

export interface IMigratePeriodSectionsDialogData {
  periodId: number,
  periods: IProgramSummaryPeriod[];
  selectedSectionIds: number[],
  title: string,
  confirmText: string,
  cancelText: string,
  isCopying?: boolean,
}

@Component({
  selector: 'app-migrate-period-sections-dialog',
  templateUrl: './migrate-period-sections-dialog.component.html',
  styleUrls: ['./migrate-period-sections-dialog.component.scss'],
})
export class MigratePeriodSectionsDialogComponent implements OnInit {
  public periods$ = new BehaviorSubject<IProgramSummaryPeriod[]>([]);

  public currentPeriod$ = new BehaviorSubject<IProgramSummaryPeriod | null>(null);

  public selectedSections$ = new BehaviorSubject<IProgramSummaryPeriodSection[]>([]);

  public periodControl: UntypedFormControl;

  constructor(
    fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MigratePeriodSectionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IMigratePeriodSectionsDialogData,
  ) {
    this.periodControl = fb.control(undefined, Validators.required);

    this.periods$.next(this.data.periods);

    this.currentPeriod$.next(this.data.periods.find((period) => period.id === this.data.periodId));

    this.selectedSections$.next((this.currentPeriod$.value?.section ?? [])?.filter((section) => this.data.selectedSectionIds.includes(section.id)));
  }

  async ngOnInit() {
    const periods = await firstValueFrom(this.periods$);
    if (periods.length >= 1) {
      this.periodControl.setValue(periods[0].id);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  close() {
    const period = this.periodControl.value;
    this.dialogRef.close(period);
  }

  copyToOtherProgram() {
    this.dialogRef.close({ action: 'other-program' });
  }
}
