import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IActivityType } from 'typings/doenkids/doenkids';
import { IActivityTypeState, ActivityTypeStore } from './activity-type.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivityTypeQuery extends QueryEntity<IActivityTypeState, IActivityType> {
  constructor(protected store: ActivityTypeStore) {
    super(store);
  }

  getActivityTypeByCountryCodeStream(countryCode: string) {
    return this.selectAll().pipe(
      map((activityTypes) => activityTypes.filter((activityType) => activityType.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }

  getActivityTypeByCountryCode(countryCode: string) {
    return this.getAll().filter(
      (activityType) => activityType.country_code.toLowerCase() === countryCode.toLowerCase(),
    );
  }

  getActivityTypeNamesByCountryCodeStream(countryCode: string) {
    return this.getActivityTypeByCountryCodeStream(countryCode)
      .pipe(map((activityTypes) => activityTypes.map((activityType) => activityType.name)));
  }

  getActivityTypeNamesByCountryCode(countryCode: string) {
    return this.getActivityTypeByCountryCode(countryCode).map((activityType) => activityType.name);
  }

  getActivityTypeByCountryCodeAndIdStream(countryCode: string, activityTypeIds: number[]) {
    return this.selectAll().pipe(
      map((activityTypes) => activityTypes.filter((activityType) => {
        const countryCodeMatch = activityType.country_code.toLowerCase() === countryCode.toLowerCase();
        if (activityTypeIds.length > 0) {
          return countryCodeMatch && activityTypeIds.includes(activityType.id);
        }
        return countryCodeMatch;
      })),
    );
  }
}
