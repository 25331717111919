import { Component, ContentChild, TemplateRef, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Observable, firstValueFrom } from 'rxjs';
import { Location as ALocation } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ScreenSizeProvider } from 'src/providers/screen-size.provider';
import { fadeInOut } from 'src/animations';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { ConfirmationDialogComponent } from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import { openMenu } from '../split-pane/split-pane.component';
import { getRootUrlAndQueryParamsFromUrl } from 'src/helpers/url.helper';

@Component({
  selector: 'app-doenkids-menu-bar',
  templateUrl: './doenkids-menu-bar.component.html',
  styleUrls: ['./doenkids-menu-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut],
})
export class DoenkidsMenuBarComponent {
  // A template reference is required for the template outlet to work.
  //
  @ContentChild(TemplateRef, { read: ElementRef }) template: TemplateRef<any>;

  @Input() showBackButton = false;

  @Input() loading: boolean;

  @Input() backUrl: string;

  public isLoggedIn$: Observable<boolean>;

  public isXSmall$: Observable<boolean>;

  constructor(
    private $sessionProvider: DoenkidsSessionProvider,
    private $breakpoints: ScreenSizeProvider,
    private matDialog: MatDialog,
    private location: ALocation,
    private router: Router,
    private $translateService: TranslateService,
  ) {
    this.isLoggedIn$ = this.$sessionProvider.isLoggedInSuccessfully$.pipe(distinctUntilChanged());

    this.isXSmall$ = this.$breakpoints.isXSmall$;
  }

  // Logout the current user
  //
  async logout() {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('doenkids_menu_bar.logout')),
        description: this.$translateService.instant(_('doenkids_menu_bar.logout.dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      this.$sessionProvider.logout();
    }
  }

  toggleDrawer() {
    openMenu.next();
  }

  navigateBack() {
    if (this.backUrl && this.backUrl !== '') {
      const { rootUrl, queryParams } = getRootUrlAndQueryParamsFromUrl(this.backUrl);
      this.router.navigate([rootUrl], { replaceUrl: true, queryParams });
    } else if (this.router.navigated) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }
}
