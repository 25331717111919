import { IAuthenticatedUser } from 'typings/api-customer';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IActivityReviews } from 'typings/doenkids/doenkids.d';
import { ActivityReviewService } from 'src/api/activity/activity-review/activity-review.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Component, Input, OnInit, ViewEncapsulation, OnChanges, SimpleChanges, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { isNil, sum, sumBy } from 'lodash';
import { IActivityReviewOverviewResponse } from 'typings/api-activity';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { ReviewComponent } from './review/review.component';

_('review_overview.no_content');

@Component({
  selector: 'app-review-overview',
  templateUrl: './review-overview.component.html',
  styleUrls: ['./review-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewOverviewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChildren(ReviewComponent) reviewElements: QueryList<ReviewComponent>;

  @Input() preview = false;

  @Input() pageSize = 10;

  /**
   * If we need to add reviews for other items such as template programs in the future,
   * we can add more types here and switch out the requests accordingly
   */
  @Input() type: 'activity' = 'activity';

  @Input() reviewOverview: IActivityReviewOverviewResponse;

  @Input() id: number;

  public reviews$ = new BehaviorSubject<IActivityReviews[]>([]);

  public reviewOverview$ = new BehaviorSubject<IActivityReviewOverviewResponse>(undefined);

  public totalReviewCount$ = this.reviewOverview$.pipe(
    filter((overview) => !isNil(overview)),
    map((overview) => sumBy(overview?.items, 'count')),
  );

  public canCreateReview$: Observable<boolean>;

  public reviewDivision$: Observable<{ percentage: number; count: number; tooltip: string }[]> = this.reviewOverview$.pipe(
    filter((overview) => !isNil(overview)),
    map((overview) => {
      let counts = [];
      const tooltips = [];
      let i = 5;

      while (i > 0) {
        i -= 1;

        const number = (i + 1) * 20;
        const lowerBound = number - 10 < 20 ? number : number - 10;
        let upperBound = number + 10 > 100 ? 101 : number + 10; // set to 101 to include 100 in the comparison
        const range = [lowerBound, upperBound];
        tooltips.push(
          this.$translateService.instant('review_overview.reviews_with_score_between', {
            minScore: range[0] / 20,
            maxScore: range[1] / 20 > 5 ? Math.round(range[1] / 20) : range[1] / 20,
          }),
        );

        const filtered = overview.items.filter((item) => {
          const parsed = parseFloat(`${item.average_review_score}`);
          return parsed >= range[0] && parsed < range[1];
        });

        const count = sumBy(filtered, 'count');

        counts.push(count ?? 0);
      }

      const total = sum(counts);
      counts = counts.map((count, idx) => ({
        percentage: count ? (count / total) * 100 : 0,
        count,
        tooltip: tooltips[idx],
      }));

      return counts;
    }),
  );

  public endReached$ = new BehaviorSubject(false);

  public reviewsLoading$ = new BehaviorSubject(false);

  public overviewLoading$ = new BehaviorSubject(false);

  public metaData$ = new BehaviorSubject({
    total: 0,
    limit: 0,
    skip: 0,
  });

  public stop$ = new Subject();

  private page = 0;

  constructor(
    private router: Router,
    private $review: ActivityReviewService,
    private session: DoenkidsSessionProvider,
    private $translateService: TranslateService,
  ) {
    this.canCreateReview$ = combineLatest([this.session.getUser$, this.reviews$.pipe(map((reviews) => reviews.slice(0, 5)))]).pipe(
      map(([user, reviews]) => !(reviews as IActivityReviews[]).some((review) => review.created_by_user_id === (user as IAuthenticatedUser).user?.id)),
    );
  }

  ngOnInit(): void {
    this.$review.reviewUpdated$.pipe(takeUntil(this.stop$)).subscribe(() => this.fetchReviewPage(false));
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.id?.currentValue !== changes.id?.previousValue) {
      const reviews = await this.fetchReviews();
      this.reviews$.next(reviews);

      if (this.reviewOverview) {
        this.reviewOverview$.next(this.reviewOverview);
      } else {
        this.fetchOverview();
      }

      this.endReached$.next(false);
    }
  }

  onScroll(event) {
    if (this.preview) {
      return;
    }
    const tracker = event.target as HTMLElement;

    const limit = tracker.scrollHeight - tracker.clientHeight;

    if (tracker.scrollTop === limit) {
      this.endReached();
    }
  }

  viewAll() {
    this.router.navigate([`${this.getUrlSlug()}/review/${this.id}/overview`]);
  }

  newReview() {
    this.router.navigate([`${this.getUrlSlug()}/review/${this.id}/create`]);
  }

  async reviewDeleted(id: number) {
    const filtered = this.reviews$.value.filter((review) => review.id !== id);
    this.fetchOverview();
    this.reviews$.next(filtered);
  }

  endReached() {
    this.fetchReviewPage();
  }

  async fetchReviewPage(increment = true) {
    if (increment) {
      this.page += 1;

      if (this.preview) {
        return;
      }
    }

    const reviews = await this.fetchReviews();

    if (reviews.length === 0) {
      this.endReached$.next(true);
    } else {
      const startIdx = this.page * this.pageSize;
      const endIdx = startIdx + this.pageSize;

      this.reviews$.value.splice(startIdx, endIdx, ...reviews);
      this.reviews$.next(this.reviews$.value);
    }
  }

  async fetchReviews() {
    this.reviewsLoading$.next(true);
    if (!this.id) {
      console.warn('[REVIEW-OVERVIEW]: ', 'invalid ID');
      return [];
    }
    const result = (await this.$review.fetchAll(this.id, this.pageSize, this.page * this.pageSize)) as any;

    this.metaData$.next({
      total: result.total,
      limit: result.limit,
      skip: result.skip,
    });

    this.reviewsLoading$.next(false);

    return (result?.items ?? []) as IActivityReviews[];
  }

  async fetchOverview() {
    if (!this.id) {
      return;
    }

    this.overviewLoading$.next(true);
    const overview = await this.$review.fetchOverview(this.id);
    this.reviewOverview$.next(overview);
    this.overviewLoading$.next(false);
  }

  getUrlSlug() {
    switch (this.type) {
      case 'activity':
        return 'activities';
      default:
        console.error('[REVIEW-OVERVIEW]:', 'unknown type passed');
        return '';
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }
}
