<mat-form-field appearance="outline" class="tag-chip-list">
  <mat-label *ngIf="!tagToEdit">{{ 'settings_overview.tag.new.label' | translate }}</mat-label>
  <mat-label *ngIf="tagToEdit">{{ 'settings_overview.tag.edit.label' | translate }}</mat-label>
  <input [formControl]="tagInputControl" matInput [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)" type="text" [attr.maxlength]="tagMaxLength">
  <mat-chip-grid #chipList>
    <ng-container *ngFor="let tag of (tags$ | async)">
      <mat-chip-option *ngIf="tagToEdit?.id !== tag.id && isTagOfCurrentOU(tag)" [class.new]="isJustCreated(tag)"
        [removable]="true" (removed)="remove(tag)">
        <span class="chip-content">{{ tag.name }}&nbsp;<mat-icon class="chip-icon" (click)="edit(tag)">edit</mat-icon></span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
      <mat-chip-option *ngIf="tagToEdit?.id !== tag.id && !isTagOfCurrentOU(tag)" class="not-editable" [class.new]="isJustCreated(tag)"
        [removable]="false">
        <span class="chip-content">
          {{tag.name}}
          <mat-icon class="chip-icon">lock</mat-icon>
        </span>
      </mat-chip-option>
    </ng-container>
  </mat-chip-grid>
</mat-form-field>
