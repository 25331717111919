import { Injectable } from '@angular/core';
import { IProgramTemplateBundleListResponse } from 'typings/api-activity';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IProgramTemplateBundle } from 'typings/doenkids/doenkids';
import { ProgramTemplateBundleListStore } from './program-template-bundle-list.store';
import { firstValueFrom } from 'rxjs';

/**
 * An Akita service for the program routes in the activity API.
 *
 * https://api-tst-doenkids.springtree.io/activity/docs/#tag/program-template-bundle
 *
 */
@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleListService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: ProgramTemplateBundleListStore,
  ) {}

  async fetchAll({
    activityTypeId,
    archived = false,
    limit = 250,
    nodeOnly = false,
    organizationUnitId,
    programCategoryId,
    skip,
    sortDirection,
    sortField,
    statusId,
    language,
    doNotPersist = false,
  }: {
    activityTypeId?: number,
    archived?: boolean,
    limit: number,
    nodeOnly?: boolean,
    organizationUnitId: number,
    programCategoryId?: number,
    statusId?: number,
    skip: number,
    sortDirection?: string,
    sortField?: string,
    language?: string,
    doNotPersist?: boolean,
  }): Promise<IProgramTemplateBundle[]> {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      '/activity/program-template-bundle', limit, skip, sortField, sortDirection, {
        activityTypeId,
        archived,
        nodeOnly,
        organizationUnitId,
        programCategoryId,
        statusId,
        language,
      },
    )) as IProgramTemplateBundleListResponse;

    if (!doNotPersist) {
      this.store.set(response?.items ?? []);
    }
    return response?.items ?? [];
  }

  reOrder(templateBundleId: number, templateBundleAsReference: number, placement: 'before' | 'after') {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program-template-bundle/${templateBundleId}/reorder/${templateBundleAsReference}?placement=${placement}`));
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
