import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IProgramTemplateBundle } from 'typings/doenkids/doenkids';

export interface ProgramTemplateBundleBaseListState extends EntityState<IProgramTemplateBundle> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-bundle-base-list' })
export class ProgramTemplateBundleBaseListStore extends EntityStore<ProgramTemplateBundleBaseListState, IProgramTemplateBundle> {
  constructor() {
    super([]);
  }
}
