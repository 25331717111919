import { StoreConfig, Store } from '@datorama/akita';
import { IOrganizationUnit } from 'typings/doenkids/doenkids';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-unit' })
export class OrganizationUnitStore extends Store<IOrganizationUnit> {
  constructor() {
    super({});
  }
}
