<div mat-dialog-title>
  <h3>{{ 'dialog.add_media_tags.title' | translate }}</h3>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="!(loading$ | async)">
    <app-doenkids-image [mediaItem]="{ uuid: data.mediaItem.uuid , description: '' }"></app-doenkids-image>
    <div
      *ngIf="(notImplementedTags$ | async).length as suggestionCount"
      class="suggestions-wrapper"
      [innerHTML]="'dialog.add_media_tags.suggestions' | translate: {
        suggestionCount,
        suggestions: (notImplementedTags$ | async).join(', ')
      }"
    ></div>
    <tag-input
      [formControl]="mediaItemTags"
      [onlyFromAutocomplete]="true"
      displayBy="name"
      identifyBy="id"
      [placeholder]="'activity.tags.placeholder' | translate"
      [secondaryPlaceholder]="'activity.tags.add' | translate"
    >
      <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="( tags$ | async )" displayBy="name" identifyBy="id" [keepOpen]="false" [appendToBody]="false">
        <ng-template let-item="item" let-index="index">
          <div class="tag">
            <mat-icon *ngIf="item.organization_unit_id === (currentOUId$ | async)">push_pin</mat-icon>
            <span>
                {{ item.name }}
            </span>
          </div>
        </ng-template>
      </tag-input-dropdown>
    </tag-input>
  </ng-container>
  <ng-container *ngIf="(loading$ | async)">
    <mat-spinner class="spinner"></mat-spinner>
  </ng-container>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end" fxFlexFill>
  <button mat-button color="primary" (click)="onClose()">
    {{ 'generic.close' | translate }}
  </button>
</div>
