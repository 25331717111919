<div class="sliders" fxLayout="column" fxLayoutAlign="center center">
  <span *ngIf="adjustOnChange" [class.danger]="(toAssign$ | async) > 0">
    {{ 'weighted_value_sliders.to_assign' | translate: {
      toAssign: toAssign$ | async | i18n_number: '1.0-2' | async
    } }}
  </span>
  <div class="slider-grid">
    <ng-container *ngFor="let slider of sliders; index as i">
      <div>
        <span>
          {{ slider.name | doenkidsTranslate: translationPrefix }}
        </span>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="center center">
        <mat-chip-set>
          <mat-chip>
            {{ slider.weight | i18n_number: '1.0-2' | async }}
          </mat-chip>
        </mat-chip-set>
        <mat-slider
          #sliderControl
          color="primary"
          (mousedown)="
            sliderClicked(slider.id, $event)
          "
          [step]="10"
          [disabled]="!this.isSelected(slider)"
        ><input matSliderThumb [value]="slider.weight" (valueChange)="sliderChange($any($event), i)" [step]="10" /></mat-slider>
      </div>
      <div fxLayoutAlign="center center">
        <mat-checkbox
          class="example-margin"
          [checked]="this.isSelected(slider)"
          (change)="checkboxChange($event, slider.id, i)"
        ></mat-checkbox>
      </div>
    </ng-container>
  </div>
</div>
