import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PermissionProvider } from 'src/providers/permission.provider';
import { map, takeUntil } from 'rxjs/operators';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import {
  BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject,
} from 'rxjs';
import { NewsItemService } from 'src/api/customer/news-item/news-item.service';
import { NewsfeedProvider } from 'src/providers/newsfeed-provider';
import {
  ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { cloneDeep } from 'lodash';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ChoiceDialogComponent } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { INewsItem, INewsItemDetails } from 'typings/doenkids/doenkids';
import { DoenkidsStaticValuesHelper } from '../static-values/doenkids-static-values-helper';

@Component({
  selector: 'app-newsfeed-item',
  templateUrl: './newsfeed-item.component.html',
  styleUrls: ['./newsfeed-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsfeedItemComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  private window: Window;

  private isBaseNewsItem$ = new BehaviorSubject<boolean>(false);

  private autoReadTimeout: any;

  public read$ = new BehaviorSubject(false);

  public mailtoUrl: string;

  public partOfOrganization$: Observable<boolean>;

  public shouldShowBaseNewsItemCallToAction$: Observable<boolean>;

  @Input() message: INewsItemDetails;

  @Input() admin: boolean;

  constructor(
    public newsfeedProvider: NewsfeedProvider,
    private $newsItem: NewsItemService,
    private router: Router,
    private dialog: MatDialog,
    private session: DoenkidsSessionProvider,
    private permission: PermissionProvider,
    private route: ActivatedRoute,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    @Inject(DOCUMENT) document: Document,
  ) {
    this.window = document.defaultView;

    this.partOfOrganization$ = this.session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
      map((organizationUnit) => {
        // Node path isn't always a number[] so parse every value to number
        //
        const controlPath = organizationUnit?.node_path.map((id) => parseInt(`${id}`, 10));

        return controlPath.includes(this.message?.organization_unit_id);
      }),
    );

    this.shouldShowBaseNewsItemCallToAction$ = combineLatest([
      this.session.isRootOrganization$,
      this.permission.hasNewsItemPermission$,
      this.session.isCurrentOrganizationUnitIsOfTypeCustomer$,
      this.session.isOrganizationUnitPartOfAnDetachedNodeTree$,
      this.isBaseNewsItem$,
      this.session.isReader$,
    ]).pipe(map((values) => {
      const [isRootOrganization, hasNewsItem, isCustomer, isDetached, isBaseNewsItem, isReader] = values;
      return (
        !isReader
        && isCustomer
        && (isRootOrganization || hasNewsItem)
        && isDetached
        && this.message.organization_unit_id === DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER
        && isBaseNewsItem
      );
    }));

    this.route.queryParams.pipe(
      takeUntil(this.stop$),
    ).subscribe((query) => {
      this.isBaseNewsItem$.next(query.baseNewsItem === true || query.baseNewsItem === 'true');
    });
  }

  ngOnInit() {
    this.fetchRead();

    this.autoReadTimeout = setTimeout(() => {
      if (this.read$.value) {
        return;
      }

      this.markAsRead();
    }, 5000);
  }

  ngOnDestroy() {
    if (this.autoReadTimeout) {
      clearTimeout(this.autoReadTimeout);
    }
    this.stop$.next();
  }

  async fetchRead() {
    const result = await this.$newsItem.fetchRead(this.message.id);

    if (result) {
      this.read$.next(result.name === 'TRUE');
    }
  }

  mailTo() {
    const newsItemUrl = `${this.window.location.protocol}//${this.window.location.host}/news-item/${this.message.id}`;
    const subject = this.$translateService.instant(_('newsfeed.item.mail.subject'), { newsItemUrl });
    const body = this.$translateService.instant(_('newsfeed.item.mail.body'), { newsItemUrl });

    this.window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`, '_blank').focus();
  }

  changeReadStatus() {
    if (this.read$.value) {
      this.markAsUnread();
    } else {
      this.markAsRead();
    }
  }

  async markAsUnread() {
    if (this.autoReadTimeout) {
      clearTimeout(this.autoReadTimeout);
    }
    await this.$newsItem.unRead(this.message.id);
    this.$i18nToastProvider.success(_('newsfeed.item.marked_as_unread'));
    this.fetchRead();
  }

  async publishToOwnOrganization() {
    const organization = await firstValueFrom(this.session.getOrganizationUnit$);
    const isRootOrganization = await firstValueFrom(this.session.isRootOrganization$);
    const hasNewsItemPermission = await firstValueFrom(this.permission.hasNewsItemPermission$);

    if (organization?.id) {
      const item = cloneDeep(this.message);

      let title = this.$translateService.instant(_('newsfeed.item.copy.dialog.title'));
      let description = this.$translateService.instant(_('newsfeed.item.copy.dialog.description'));
      let choices = [];
      if (hasNewsItemPermission) {
        choices = [
          { label: this.$translateService.instant(_('newsfeed.item.copy')), value: 'copy' },
          { label: this.$translateService.instant(_('newsfeed.item.copy.with_changes')), value: 'edit' },
        ];
      } else if (isRootOrganization) {
        description = this.$translateService.instant(_('newsfeed.item.copy.dialog.description.takeover'));
      }


      const dialogRef = this.dialog.open(ChoiceDialogComponent, {
        width: '600px',
        minWidth: '420px',
        data: {
          title,
          description,
          selectionOptions: choices,
        },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());

      if (result && result.action === 'confirm') {
        if ((!result.selectedOption && isRootOrganization && !hasNewsItemPermission) || result.selectedOption === 'copy') {
          delete item.news_read;
          delete item.organization_unit_name;
          item.activity_type_id = item.activity_type_id.map((type) => parseInt(`${type}`, 10));
          item.organization_unit_id = organization.id;

          // we can cast like this because above here we already delete news_read and organization_unit_name which are the only
          // different properties between INewsItemDetails and INewsItem
          //
          await this.$newsItem.create(organization.id, item as INewsItem);
          this.$i18nToastProvider.success(_('newsfeed.item.copy.success'), { organization: organization.name || undefined });
        } else if (result.selectedOption === 'edit') {
          this.newsfeedProvider.setPendingNewsItem(item);
          const translatedNewsItemTabLabel = this.$translateService.instant(_('organization_unit_details.news_item.label'));
          this.router.navigate([`organization/${organization.id}/overview`], { queryParams: { tab: translatedNewsItemTabLabel } });
        }
      }
    }
  }

  async markAsRead() {
    this.read$.next(true);

    if (this.autoReadTimeout) {
      clearTimeout(this.autoReadTimeout);
    }

    await this.$newsItem.setRead(this.message.id);
    this.fetchRead();
    this.newsfeedProvider.setItemMarkedAsRead(this.message.id);
    this.$i18nToastProvider.success(_('newsfeed.item.marked_as_read'));
  }
}
