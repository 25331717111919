import { Directive, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UploadFile } from '@angular-ex/uploader';
import { IUploadResponse } from 'typings/custom-app-types';

export interface IUploadMedia {
  uploaded: Observable<IUploadResponse>;
  files: Observable<UploadFile[]>;
  openFile$: Subject<void>;
}

@Directive()
export class UploadableDirective {
  @Input() uploadData: IUploadMedia;
}
