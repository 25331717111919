import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, isNil, snakeCase, uniqBy, values } from 'lodash';
import { BehaviorSubject, Observable, Subject, firstValueFrom, takeUntil } from 'rxjs';
import { JibbieLocationService } from 'src/api/customer/jibbie/location/jibbie-location.service';
import { JibbieOrganizationService } from 'src/api/customer/jibbie/organization/jibbie-organization.service';
import { KonnectGroupService } from 'src/api/customer/konnect/group/konnect-group.service';
import { KonnectLocationService } from 'src/api/customer/konnect/location/konnect-location.service';
import { OrganizationUnitTreeService } from 'src/api/customer/organization-unit-tree/organization-unit-tree.service';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { AddOrganizationDialogComponent, EOrganizationUnitType } from 'src/components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { ApiKeyDialogComponent } from 'src/components/dialogs/api-key-dialog/api-key-dialog.component';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConnectJibbieOuDialogComponent } from 'src/components/dialogs/connect-jibbie-ou-dialog/connect-jibbie-ou-dialog.component';
import { ConnectKonnectOUDialogComponent } from 'src/components/dialogs/connect-konnect-ou-dialog/connect-konnect-ou-dialog.component';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import {
  IJibbieLocationListResponse,
  IJibbieOrganisationListResponse,
  IKonnectGroupListResponse,
  IKonnectLocationListResponse,
  IOrganizationUnitTreeNode,
} from 'typings/api-customer';
import {
  IJibbieLocation,
  IJibbieOrganisation,
  IJibbieOrganizationUnit,
  IKonnectGroup,
  IKonnectLocation,
  IKonnectOrganizationUnit,
  IOrganizationUnit,
} from 'typings/doenkids/doenkids';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { OrganizationUnitTypeNamePipe } from 'src/pipes/organization-unit-type-name';
import { TranslateService } from 'src/app/utils/translate.service';

interface PartialOrganizationUnit {
  id: number;
  name: string;
  type_id: number;
  konnectOrganizationUnit?: PartialOrganizationUnit;
  children?: PartialOrganizationUnit[];
}

@Component({
  selector: 'app-organization-unit-table',
  templateUrl: './organization-unit-table.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./organization-unit-table.component.scss'],
})
export class OrganizationUnitTableComponent implements OnInit, OnChanges, OnDestroy {
  private stop$: Subject<void> = new Subject();

  public MAX_ITEMS_PER_PAGE = 15;

  public currentPage = 0;

  public list$: BehaviorSubject<PartialOrganizationUnit[]> = new BehaviorSubject([]);

  public searchIndex$: BehaviorSubject<PartialOrganizationUnit[]> = new BehaviorSubject([]);

  public isAdmin$: Observable<boolean>;

  @Output()
  public listLoading$: EventEmitter<boolean> = new EventEmitter();

  public metaData$: BehaviorSubject<{ limit: number; skip: number; total: number }> = new BehaviorSubject({
    limit: this.MAX_ITEMS_PER_PAGE,
    skip: 0,
    total: 0,
  });

  displayedColumns: string[] = ['name'];

  @Input() organizationUnitId: number;

  @Input() organizationUnitTypeId: number;

  @Input() editable = false;

  @Input() canAddNewItem = false;

  @Input() jibbieDetails: IJibbieOrganizationUnit;

  public hasOUWritePermissions$: Observable<boolean>;

  public hasKonnectIntegrationPermission$: Observable<boolean>;

  public hasJibbieIntegrationPermission$: Observable<boolean>;

  public editingOU$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public editingOUControl: UntypedFormControl = new UntypedFormControl('');

  private konnectOrganizations: IKonnectLocation[] | IKonnectGroup[] = [];

  private jibbieOrganizations: IJibbieOrganisation[] | IJibbieLocation[] = [];

  public konnectDetails: IKonnectOrganizationUnit;

  protected readonly EOrganizationUnitType = EOrganizationUnitType;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private $permission: PermissionProvider,
    private organizationUnitService: OrganizationUnitService,
    private organizationUnitTreeService: OrganizationUnitTreeService,
    private $session: DoenkidsSessionProvider,
    private konnectLocationService: KonnectLocationService,
    private konnectGroupService: KonnectGroupService,
    private jibbieOrganizationService: JibbieOrganizationService,
    private jibbieLocationService: JibbieLocationService,
    private $baseApi: DoenKidsGenericApiProvider,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
    private $translateService: TranslateService,
    private organizationUnitTypeNamePipe: OrganizationUnitTypeNamePipe,
  ) {
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.hasOUWritePermissions$ = this.$permission.hasOUWritePermissions$.pipe(takeUntil(this.stop$));
    this.hasKonnectIntegrationPermission$ = this.$permission.hasKonnectIntegrationPermission$.pipe(takeUntil(this.stop$));
    this.hasJibbieIntegrationPermission$ = this.$permission.hasJibbieIntegrationPermission$.pipe(takeUntil(this.stop$));
  }

  ngOnInit() {
    this.fetchList();

    if (this.editable) {
      this.displayedColumns = ['name', 'actions'];
    } else {
      this.displayedColumns = ['name'];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.editable) {
      if (changes.editable.currentValue) {
        this.displayedColumns = ['name', 'actions'];
      } else {
        this.displayedColumns = ['name'];
      }
    }

    if (changes.organizationUnitId?.currentValue && !changes.organizationUnitId.firstChange) {
      this.fetchList();
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  /**
   * @param {number} page The page number to switch to
   */
  public async paginate(page: number) {
    const metadata = await firstValueFrom(this.metaData$);
    const newSkip = page * metadata.limit - metadata.limit;

    if (this.currentPage !== page) {
      this.currentPage = page;
    }
    this.metaData$.next({
      limit: this.MAX_ITEMS_PER_PAGE,
      skip: newSkip,
      total: metadata.total,
    });
  }

  public downloadLocationsExcel = async () => {
    const url = `${this.$baseApi.API_END_POINT}/customer/organization-unit/${this.organizationUnitId}/export-locations`;
    const headers = this.$baseApi.getAuthorizationHeader();
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);

    this.$doenkidsFileDownloaderService.addDownload({
      name: `${snakeCase(organizationUnit.name)}_${this.$translateService.instant(_('generic.locations'))}.xlsx`,
      url,
      headers,
    });
  };

  public downloadLocationAndGroupsExcel = async () => {
    const url = `${this.$baseApi.API_END_POINT}/customer/organization-unit/${this.organizationUnitId}/export-location-and-groups`;
    const headers = this.$baseApi.getAuthorizationHeader();
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);

    this.$doenkidsFileDownloaderService.addDownload({
      name: `${snakeCase(organizationUnit.name)}_${this.$translateService.instant(_('generic.locations_and_groups'))}.xlsx`,
      url,
      headers,
    });
  };

  public markKonnectInheritance(nodes: PartialOrganizationUnit[], flatten: boolean) {
    const result: PartialOrganizationUnit[] = [];

    const func = (arr: PartialOrganizationUnit[]) => {
      for (let i = 0; i < arr.length; i++) {
        result.push(arr[i]);

        if (arr[i].children) {
          if (flatten) {
            func(arr[i].children);
          }

          if (!isNil(arr[i].konnectOrganizationUnit) || this.konnectOrganizations.some((organization) => arr[i].id === organization.organization_unit_id)) {
            if (!arr[i].konnectOrganizationUnit) {
              arr[i].konnectOrganizationUnit = arr[i];
            }

            arr[i].children.forEach((child) => {
              child.konnectOrganizationUnit = arr[i].konnectOrganizationUnit;
            });
          }
        }
      }
    };

    func(nodes);
    return uniqBy(result, ({ id }) => id);
  }

  private async fetchList() {
    this.listLoading$.next(true);

    let result: IOrganizationUnitTreeNode[] | PartialOrganizationUnit[] = [];
    let treeResult;

    if (this.jibbieDetails) {
      await this.fetchJibbieOrganizations();
    }
    await this.fetchKonnectOrganizations();

    if (this.organizationUnitTypeId === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION) {
      treeResult = await this.organizationUnitTreeService.fetchLocationDescendants(this.organizationUnitId, true, false);
    } else if (this.organizationUnitTypeId === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_GROUP) {
      treeResult = await this.organizationUnitTreeService.fetchGroupDescendants(this.organizationUnitId, true, false);
    } else {
      //
      result = await this.organizationUnitTreeService.fetchCustomerDescendants(this.organizationUnitId, false);
      result = this.markKonnectInheritance(result, false);

      const currentNode = this.findCurrentOUNode(result as IOrganizationUnitTreeNode[]);

      if (currentNode) {
        // only use the direct children of the selected ou.
        //
        result = values(currentNode.children);

        // filter out any ou's that we don't have write access to
        //
        result = await this.filterOUOnWritePermission(result as IOrganizationUnitTreeNode[]);
      }
    }

    if (treeResult) {
      result = this.markKonnectInheritance(treeResult, true);
    }

    result = result.sort((nodeA, nodeB) => {
      if (nodeA.name.toLowerCase() < nodeB.name.toLowerCase()) {
        return -1;
      }
      if (nodeA.name.toLowerCase() > nodeB.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    this.metaData$.next({
      limit: this.MAX_ITEMS_PER_PAGE,
      skip: this.currentPage * this.MAX_ITEMS_PER_PAGE - this.MAX_ITEMS_PER_PAGE,
      total: result.length,
    });

    this.list$.next(result);
    this.searchIndex$.next(result);
    this.listLoading$.next(false);
  }

  findCurrentOUNode(ous: IOrganizationUnitTreeNode[]) {
    let foundOUNode;

    for (const ou of ous) {
      if (ou.id === this.organizationUnitId) {
        foundOUNode = ou;
        break;
      } else if (ou.children) {
        foundOUNode = this.findCurrentOUNode(values(ou.children));
      }
    }

    return foundOUNode;
  }

  async filterOUOnWritePermission(ous: IOrganizationUnitTreeNode[]) {
    const filteredOUs = [];

    for (const ou of ous) {
      // eslint-disable-next-line no-await-in-loop
      const writePermission = await this.$permission.checkOUWritePermission(ou.id);

      if (writePermission) {
        filteredOUs.push(ou);
      }
    }

    return filteredOUs;
  }

  applyFilter(event: Event) {
    this.currentPage = 0;
    const filterValue = (event.target as HTMLInputElement).value;
    const list = this.searchIndex$.value;
    this.list$.next(filter(list, (node) => node.name.toLowerCase().search(filterValue.toLowerCase()) !== -1));
  }

  showDetails(row: IOrganizationUnit) {
    if (this.organizationUnitTypeId === EOrganizationUnitType.GROUP) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.navigate')),
        description: this.$translateService.instant(_('organization_unit_table.details.show.confirm.dialog.description'), { organizationUnitName: row.name }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        await this.router.navigate([`/organization/${row.id}/overview`]);
      }
    });
  }

  async removeOrganization(organizationUnit: PartialOrganizationUnit, event$: MouseEvent) {
    event$.stopPropagation();

    const organizationUnitTypeName = this.$translateService.instant(this.organizationUnitTypeNamePipe.transform(organizationUnit.type_id));

    if (organizationUnit.type_id === EOrganizationUnitType.GROUP) {
      const hasKonnectIntegrationPermission = await firstValueFrom(this.hasKonnectIntegrationPermission$);
      const isLinkedToKonnect = this.konnectDetails && hasKonnectIntegrationPermission && this.organizationIsLinkedToActiveKonnect(organizationUnit.id);
      if (isLinkedToKonnect) {
        this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          minWidth: '320px',
          data: {
            title: this.$translateService.instant(_('organization_unit_table.organization.remove.impossible.dialog.title')),
            description: this.$translateService.instant(_('organization_unit_table.organization.remove.impossible.dialog.description'), {
              organizationUnitTypeName,
              organizationUnitName: organizationUnit.name,
            }),
            hideCancel: true,
          },
        });

        return;
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('organization_unit_table.organization.remove.confirm.dialog.description'), {
          organizationUnitTypeName,
          organizationUnitName: organizationUnit.name,
        }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        this.listLoading$.next(true);
        await this.organizationUnitService.archive(organizationUnit.id);
        this.fetchList();
      }
    });
  }

  async addOrganizationUnit() {
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
    const dialogRef = this.dialog.open(AddOrganizationDialogComponent, {
      width: '800px',
      height: '800px',
      data: {
        organizationUnitType: this.organizationUnitTypeId,
        parentOU: organizationUnit,
      },
    });

    await firstValueFrom(dialogRef.afterClosed());

    this.fetchList();
  }

  editRow(organizationUnit: PartialOrganizationUnit) {
    this.editingOUControl.setValue(organizationUnit.name);
    this.editingOU$.next(organizationUnit.id);
  }

  async saveRow(organizationUnit: PartialOrganizationUnit) {
    try {
      this.listLoading$.next(true);
      const updatedOrganization: IOrganizationUnit = {
        id: organizationUnit.id,
        name: this.editingOUControl.value,
      };

      await this.organizationUnitService.update(updatedOrganization);

      this.editingOU$.next(null);
      this.editingOUControl.setValue('');
      this.fetchList();
    } catch (e) {
      console.log(e);
    }
  }

  async cancelEditingRow() {
    this.editingOU$.next(null);
    this.editingOUControl.setValue('');
    this.fetchList();
  }

  async fetchKonnectOrganizations() {
    const konnectIntegrationPermission = await firstValueFrom(this.hasKonnectIntegrationPermission$);

    if (!konnectIntegrationPermission) {
      return;
    }

    const fetchedKonnectDetails = await this.organizationUnitService.konnectDetails(this.organizationUnitId);

    if (
      fetchedKonnectDetails.src === 'organisation' ||
      fetchedKonnectDetails.src === 'location' ||
      fetchedKonnectDetails.src === 'parent_location' ||
      fetchedKonnectDetails.src === 'parent_organisation'
    ) {
      this.konnectDetails = fetchedKonnectDetails;
      if (this.organizationUnitTypeId === EOrganizationUnitType.LOCATION) {
        await this.konnectLocationService
          .fetchAll(fetchedKonnectDetails.id, 5000, 0, 'created_at', 'DESC', '')
          .then((konnectLocationListResponse: IKonnectLocationListResponse) => {
            this.konnectOrganizations = konnectLocationListResponse.items;
          });
      } else if (this.organizationUnitTypeId === EOrganizationUnitType.GROUP) {
        await this.konnectGroupService
          .fetchAll(this.organizationUnitId, {
            limit: 5000,
            skip: 0,
            sortField: 'created_at',
            sortDirection: 'DESC',
          })
          .then((konnectGroupListResponse: IKonnectGroupListResponse) => {
            this.konnectOrganizations = konnectGroupListResponse.items;
          });
      }
    } else {
      this.konnectDetails = null;
    }
  }

  async fetchJibbieOrganizations() {
    const jibbieIntegrationPermission = await firstValueFrom(this.hasJibbieIntegrationPermission$);

    if (!jibbieIntegrationPermission) {
      return;
    }

    if (this.jibbieDetails && this.jibbieDetails.src !== 'none') {
      // TODO don't cast jibbie details to any
      if (this.organizationUnitTypeId === EOrganizationUnitType.LOCATION) {
        await this.jibbieLocationService
          .fetchAll((this.jibbieDetails as any).jibbie_id, {
            limit: 5000,
            skip: 0,
            sortField: 'created_at',
            sortDirection: 'DESC',
          })
          .then((jibbieLocationResponse: IJibbieLocationListResponse) => {
            this.jibbieOrganizations = jibbieLocationResponse.items;
          });
      } else if (this.organizationUnitTypeId === EOrganizationUnitType.ORGANIZATION) {
        await this.jibbieOrganizationService
          .fetchAll({
            limit: 5000,
            skip: 0,
            sortField: 'created_at',
            sortDirection: 'DESC',
          })
          .then((jibbieOrganisationListResponse: IJibbieOrganisationListResponse) => {
            this.jibbieOrganizations = jibbieOrganisationListResponse.items;
          });
      }
    } else {
      this.jibbieOrganizations = [];
    }
  }

  organizationIsAlreadyLinkedToKonnect(organizationUnit: PartialOrganizationUnit) {
    return organizationUnit.konnectOrganizationUnit?.id === organizationUnit.id;
  }

  organizationIsLinkedToActiveKonnect(ouId: number) {
    const filteredKonnectOrganizations = (this.konnectOrganizations as any[]).filter(
      (konnectOrganization: IKonnectLocation | IKonnectGroup) => konnectOrganization.organization_unit_id === ouId && konnectOrganization.active,
    );

    return filteredKonnectOrganizations.length > 0;
  }

  organizationIsAlreadyLinkedToJibbie(ouId: number) {
    const filteredJibbieOrganizations = (this.jibbieOrganizations as any[]).filter(
      (jibbieOrganization: IJibbieOrganisation | IJibbieLocation) => jibbieOrganization.organization_unit_id === ouId,
    );

    return filteredJibbieOrganizations.length > 0;
  }

  async showAPIKeyDialog(organization: PartialOrganizationUnit) {
    const dialogRef = this.dialog.open(ApiKeyDialogComponent, {
      width: '500px',
      height: '300px',
      data: {
        organizationUnitId: organization.id,
        organizationUnitName: organization.name,
      },
    });

    await firstValueFrom(dialogRef.afterClosed());
  }

  async connectKonnectOU(organization: PartialOrganizationUnit) {
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
    const dialogRef = this.dialog.open(ConnectKonnectOUDialogComponent, {
      width: '800px',
      height: '800px',
      data: {
        organizationUnitType: this.organizationUnitTypeId,
        organization,
        parentOUId: organizationUnit.id,
        parentOUName: organizationUnit.name,
      },
    });

    await firstValueFrom(dialogRef.afterClosed());

    this.fetchList();
  }

  async unlinkKonnectOU(organization: PartialOrganizationUnit) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('organization_unit_table.unlink_konnect.dialog.title')),
        description: this.$translateService.instant(_('organization_unit_table.unlink_konnect.dialog.description'), {
          organizationUnitName: organization.name,
        }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result === 'confirm') {
      const filteredKonnectOrganizations = (this.konnectOrganizations as any[]).filter(
        (konnectOrganization: IKonnectLocation | IKonnectGroup) => konnectOrganization.organization_unit_id === organization.id,
      );

      if (this.organizationUnitTypeId === EOrganizationUnitType.LOCATION) {
        await this.konnectLocationService.unsetOrganizationUnit(filteredKonnectOrganizations[0].id);
      } else if (this.organizationUnitTypeId === EOrganizationUnitType.GROUP) {
        await this.konnectGroupService.unsetOrganizationUnit(filteredKonnectOrganizations[0].id);
      }

      this.fetchList();
    }
  }

  async connectJibbieOU(organization: PartialOrganizationUnit) {
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
    const dialogRef = this.dialog.open(ConnectJibbieOuDialogComponent, {
      width: '800px',
      height: '800px',
      data: {
        organizationUnitType: this.organizationUnitTypeId,
        organizationUnitName: organization.name,
        organizationUnitId: organization.id,
        parentOUId: organizationUnit.id,
        parentOUName: organizationUnit.name,
      },
    });

    await firstValueFrom(dialogRef.afterClosed());

    this.fetchList();
  }

  async unlinkJibbieOU(organization: PartialOrganizationUnit) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('organization_unit_table.unlink_jibbie.dialog.title')),
        description: this.$translateService.instant(_('organization_unit_table.unlink_jibbie.dialog.description'), { organizationUnitName: organization.name }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result === 'confirm') {
      const filteredJibbieOrganizations = (this.jibbieOrganizations as any[]).filter(
        (jibbieOrganization: IJibbieOrganisation | IJibbieLocation) => jibbieOrganization.organization_unit_id === organization.id,
      );

      if (this.organizationUnitTypeId === EOrganizationUnitType.LOCATION) {
        await this.jibbieLocationService.unsetOrganizationUnit(filteredJibbieOrganizations[0].id);
      } else if (this.organizationUnitTypeId === EOrganizationUnitType.ORGANIZATION) {
        await this.jibbieOrganizationService.unsetOrganizationUnit(filteredJibbieOrganizations[0].id);
      }
      this.fetchJibbieOrganizations();
    }
  }
}
