<div class="page-controls-row" *ngIf="( isLarge$ | async ) === true">
  <ng-container [ngTemplateOutlet]="template"></ng-container>
</div>
<button
  *ngIf="( isLarge$ | async ) === false"
  mat-icon-button
  [matTooltip]="tooltip | translate"
  [matMenuTriggerFor]="pageControls"
>
  <mat-icon>{{ icon }}</mat-icon>
</button>
<mat-menu #pageControls="matMenu" (click)="$event.stopPropagation()">
  <div class="page-controls-menu" (click)="$event.stopPropagation()">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>
</mat-menu>
