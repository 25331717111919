import { Component } from '@angular/core';
import { UploadableDirective } from 'src/directives/uploadable.directive';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

_('generic.unknown');

@Component({
  selector: 'app-upload-progress-bar',
  templateUrl: './upload-progress-bar.component.html',
  styleUrls: ['./upload-progress-bar.component.scss'],
})
export class UploadProgressBarComponent extends UploadableDirective {
}
