<ng-container *ngIf="!(_listLoading$ | async)">
  <ng-container *ngIf="(list$ | async) as list">
    <ng-container *ngIf="list.length > 0 else notFound">
      <table mat-table [dataSource]="list | paginate: {
        id: 'organizations',
        itemsPerPage: MAX_ITEMS_PER_PAGE,
        currentPage: currentPage,
        totalItems: list.length
      }" class="mat-elevation-z8">

        <!-- Position Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>{{ 'user.email.label' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef>{{ 'user.active.label' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.active ? ('generic.yes' | translate) : ('generic.no' | translate) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>{{ 'user.role.label' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.user_role }}
          </td>
        </ng-container>

        <ng-container matColumnDef="last_seen">
          <th mat-header-cell *matHeaderCellDef>{{ 'user.last_seen.label' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.last_seen | i18n_date: 'dd-MM-yyyy HH:mm' | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>{{ 'user.created_at.label' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created_at | i18n_date: 'dd-MM-yyyy HH:mm' | async }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <ng-container *ngIf="metaData$ | async as metadata">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="list.length > MAX_ITEMS_PER_PAGE">
          <pagination-controls
            id="organizations"
            [nextLabel]="'generic.pagination.next' | translate"
            [previousLabel]="'generic.pagination.previous' | translate"
            (pageChange)="paginate($event)"
          ></pagination-controls>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notFound>
  <app-empty-page-message [message]="'organization_users.no_users_found' | translate"></app-empty-page-message>
</ng-template>
