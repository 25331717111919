import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IProgramTemplate } from 'typings/doenkids/doenkids';

export interface ProgramTemplateBundleProgramTemplateListState extends EntityState<IProgramTemplate> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-bundle-list' })
export class ProgramTemplateBundleProgramTemplateListStore extends EntityStore<ProgramTemplateBundleProgramTemplateListState, IProgramTemplate> {
  constructor() {
    super([]);
  }
}
