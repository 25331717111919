import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Observable, Subject, BehaviorSubject, firstValueFrom } from 'rxjs';
import { takeUntil, auditTime, map, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { get, isNil, each, isArray, size, omitBy, isEqual, isEmpty, set } from 'lodash';
import { IActivity, IOrganizationUnitOverview, ISearchActivity, ISection } from 'typings/doenkids/doenkids';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivitySearchService } from 'src/api/search/activity/activity-search.service';
import { ActivitySearchQuery, IActivitySearchMetaData } from 'src/api/search/activity/activity-search.query';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { ActivitySelectionProvider } from 'src/providers/activity-selection.provider';
// eslint-disable-next-line import/no-cycle
import {
  IActivityStatusFilters,
  DEFAULT_FILTER_TYPE,
  DEFAULT_FILTER_STATUS,
  TActivityType,
} from 'src/components/pages/activity-search/activity-status-filters/activity-status-filters.component';
import { NgxMasonryComponent } from 'src/components/layout/ngx-masonry/ngx-masonry.component';
import { fadeInOut } from 'src/animations';
import { IField } from 'src/components/layout/sortable-list/sortable-list.component';
import { IActivitySearchRequest } from 'typings/api-search';
import { ActivityAggregationsProvider } from 'src/providers/activity-aggregations.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { OrganizationUnitActivityService } from 'src/api/customer/organization-unit-activity/organization-unit-activity.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { SectionService } from 'src/api/activity/section/section.service';
import { SectionTypeService } from 'src/api/generic/section-type/section-type.service';
import { EActivityStatusType, SECTION_TYPE_DEFAULT_VALUES } from 'src/components/shared/section/section.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { ISearchEvent } from 'src/components/shared/activity-search-bar/activity-search-bar.component';
import { ChoiceDialogComponent, IChoiceDialogData, IChoiceDialogResult } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { ScrollService } from 'src/app/utils/scroll.service';
import { ActivityTypeQuery } from 'src/api/generic/activity-type/activity-type.query';
import { Location as ALocation } from '@angular/common';

_('activity_search.responsive_toolbar.tooltip');
_('activity_search.tooltip.left');
_('activity_search.tooltip.right');

declare const window;

interface IStoredSearch {
  queryParams: IActivitySearchRequest;
  concat: boolean;
  aggregations: any;
  activityStatusFilterCtrlValue: any;
}

interface IFetchActivities {
  queryParams: IActivitySearchRequest;
  concatenateResults?: boolean;
  fetchAllAndIgnoreSkip?: boolean;
  aggregations?: any;
  statusFilter?: any;
}

interface ISortingOption {
  key: string;
  direction: string;
  displayValue: string;
}

export const sortingOptions: ISortingOption[] = [
  { key: 'created', direction: 'desc', displayValue: _('activity.search.sort.new_to_old') },
  { key: 'created', direction: 'asc', displayValue: _('activity.search.sort.old_to_new') },
  { key: 'alphabetical', direction: 'asc', displayValue: _('activity.search.sort.alphabetic') },
  { key: 'alphabetical', direction: 'desc', displayValue: _('activity.search.sort.alphabetic_reversed') },
  { key: 'review', direction: 'desc', displayValue: _('activity.search.sort.review_descending') },
  { key: 'review', direction: 'asc', displayValue: _('activity.search.sort.review_ascending') },
];

export const ACTIVITY_SEARCH_FIELDS = [
  {
    label: _('activity_search.field.image'),
    field: 'media_uuid',
    width: '10%',
  },
  {
    label: '',
    field: 'replace_activity_id',
    width: '5%',
    disableSort: true,
  },
  {
    label: _('activity_search.field.name'),
    field: 'name',
    width: '35%',
  },
  {
    label: _('activity_search.field.summary'),
    field: 'summary',
    width: '50%',
    hideLessThanMedium: true,
    disableSort: true,
  },
];

@Component({
  selector: 'app-activity-search',
  templateUrl: './activity-search.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./activity-search.component.scss'],
  animations: [fadeInOut],
})
export class ActivitySearchComponent implements OnInit, OnDestroy {
  @ViewChild('regularMasonry') regularMasonry: NgxMasonryComponent;

  private SEARCH_LIMIT = 15;

  public aggregationControl: UntypedFormControl = new UntypedFormControl();

  /** The labels for the aggregationlist */
  public aggregationLabels: BehaviorSubject<{ [index: string]: string }>;

  // Observable that will inform us if an aggregation is selected on the lefthand-side
  //
  public containsAggregations$: Observable<boolean>;

  private fetchActivities$: Subject<IFetchActivities> = new Subject();

  private stop$ = new Subject();

  public currentPage: number;

  public showFilters: boolean;

  public searchResults$: Observable<ISearchActivity[]>;

  public aggregations$: Observable<any>;

  public isSelectingActivties$: Observable<boolean>;

  public selectedActivities$: Observable<(ISearchActivity | IActivity)[]>;

  public isSearching$: Observable<boolean>;

  public activitySearchMetadata$: Observable<IActivitySearchMetaData>;

  private imagesLoaded$ = new Subject();

  public isSmall$: Observable<boolean>;

  public searchSuggestions$: Observable<any>;

  public isAdmin$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public hideAggregations$ = new BehaviorSubject<boolean>(false);

  public toolBarContentOpen = new BehaviorSubject<boolean>(false);

  public selectActivitiesControl: UntypedFormControl;

  public activityStatusFiltersControl: UntypedFormGroup;

  public currentSearchParams: IActivitySearchRequest;

  private statusFilterChanges$: Subject<{ activityStatus: number; showActivities: string | number }> = new Subject();

  public hasSelectedOUWritePermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public hasDisableActivityCreatePermission$: Observable<boolean>;

  public searchResultsDefaultTopPadding = 64;

  public searchResultsDefaultBottomMargin = 16;

  public sortOptionControl = new UntypedFormControl('');

  public fields: IField[] = [];

  /**
   * The configuration object for the pagination of the list.
   */
  public paginationConfig$: Observable<PaginationInstance>;

  // Update the masonry layout
  //
  updateMasonryLayout() {
    if (this.regularMasonry) {
      this.regularMasonry.reloadItems();
      this.regularMasonry.layout();
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private activityService: ActivityService,
    private activityAggregationsProvider: ActivityAggregationsProvider,
    private activitySelectionProvider: ActivitySelectionProvider,
    private sessionProvider: DoenkidsSessionProvider,
    private activitySearchService: ActivitySearchService,
    private activitySearchQuery: ActivitySearchQuery,
    private breakPointsProvider: BreakpointsProvider,
    private organizationUnitActivityService: OrganizationUnitActivityService,
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
    private activatedRoute: ActivatedRoute,
    public sectionService: SectionService,
    private sectionTypeService: SectionTypeService,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    private scrollService: ScrollService,
    private activityTypeQuery: ActivityTypeQuery,
    private $location: ALocation,
  ) {
    this.aggregationLabels = this.activityAggregationsProvider.aggregationLabels$;

    this.containsAggregations$ = this.aggregationControl.valueChanges.pipe(
      takeUntil(this.stop$),
      map((aggregations) => {
        let containsOptions = false;
        each(aggregations, (value) => {
          if (isArray(value) && size(value) > 0) {
            containsOptions = true;
          }
        });
        return containsOptions;
      }),
    );

    this.searchResults$ = this.activitySearchQuery.selectAll().pipe(takeUntil(this.stop$));

    this.aggregations$ = this.activityAggregationsProvider.sortedAggregations$.pipe(takeUntil(this.stop$));

    this.isSelectingActivties$ = this.activitySelectionProvider.isSelecting$.asObservable().pipe(takeUntil(this.stop$));

    this.selectedActivities$ = this.activitySelectionProvider.selectedActivities$.asObservable().pipe(takeUntil(this.stop$));

    this.isSearching$ = this.activitySearchQuery.selectLoading().pipe(takeUntil(this.stop$));

    this.activitySearchMetadata$ = this.activitySearchQuery.getMetadata.pipe(takeUntil(this.stop$));

    this.isSmall$ = this.breakPointsProvider.isSmall$.pipe(takeUntil(this.stop$));

    this.searchSuggestions$ = this.activitySearchQuery.getSuggestions.pipe(takeUntil(this.stop$));

    this.isAdmin$ = this.sessionProvider.isAdmin$.pipe(takeUntil(this.stop$));

    this.isReader$ = this.sessionProvider.isReader$.pipe(takeUntil(this.stop$));

    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$));

    this.hasDisableActivityCreatePermission$ = this.$permission.hasDisableActivityCreatePermission$.asObservable();

    this.paginationConfig$ = this.activitySearchMetadata$.pipe(
      takeUntil(this.stop$),
      map((metaData) => {
        const paginationConfig: PaginationInstance = {
          currentPage: this.currentPage,
          itemsPerPage: metaData.limit,
          totalItems: metaData.total,
        };

        return paginationConfig;
      }),
    );

    this.selectActivitiesControl = new UntypedFormControl(false);
    this.activityStatusFiltersControl = this.formBuilder.group({
      showActivities: [DEFAULT_FILTER_TYPE],
      activityStatus: [DEFAULT_FILTER_STATUS],
    });
  }

  async ngOnInit() {
    const organizationUnit = await firstValueFrom(this.currentOUDetails$);
    const contentLanguage = await firstValueFrom(this.$session.preferredContentLanguage$);
    this.currentSearchParams = {
      query: '',
      limit: this.SEARCH_LIMIT,
      skip: 0,
      nodeOnly: true,
      statusId: 3,
      organizationUnitId: organizationUnit.id,
      archived: false,
      filter: {},
      order: '',
      orderDirection: '',
      language: contentLanguage,
      countryCode: organizationUnit.country_code,
    };

    this.$translateService.onInitialTranslationAndLangOrTranslationChange$.pipe(takeUntil(this.stop$)).subscribe((event) => {
      this.fields = ACTIVITY_SEARCH_FIELDS.map((field) => ({
        ...field,
        label: field.label ? event.translations[field.label] : '',
      }));
    });

    const { programId, periodId, advancedSearch, activityKind, lang, showActivities: showActivitiesParam, activityStatus } = this.route.snapshot.queryParams;

    // Make sure we don't fetch activities unnecessarily
    //
    this.fetchActivities$
      .pipe(
        filter((request) => !isNil(request)),
        takeUntil(this.stop$),
        distinctUntilChanged(isEqual),
      )
      .subscribe((request) => {
        this.dofetchActivities(request.queryParams, request.concatenateResults, request.fetchAllAndIgnoreSkip);
      });

    // When a new organization is selected search again
    //
    this.$session.getOrganizationUnitSwitch$.pipe(takeUntil(this.stop$)).subscribe(async (ou) => {
      this.currentSearchParams.organizationUnitId = ou.id;
      this.currentSearchParams.countryCode = ou.country_code;
      this.currentSearchParams.activityTypeId = isEmpty(ou.activity_type_ids) ?
        this.activityTypeQuery.getActivityTypeByCountryCode(ou.country_code).map((activityType) => activityType.id)
        : ou.activity_type_ids.map((activityTypeId) => parseInt(`${activityTypeId}`, 10));

      if (!ou.languages.includes(this.currentSearchParams.language)) {
        const preferredContentLanguagePerCountry = await firstValueFrom(this.$session.preferredContentLanguagePerCountry$);
        const preferredContentLanguageForCountry = preferredContentLanguagePerCountry.get(ou.country_code) ?? ou.languages[0];
        this.currentSearchParams.language = preferredContentLanguageForCountry;
      }

      this.fetchActivities$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
    });

    if (programId && periodId) {
      this.selectActivitiesControl.setValue(true);
      // toggles 'activiteiten verzamelen' checkbox
      if (!this.activitySelectionProvider.isSelecting$.value) {
        this.activitySelectionProvider.toggleSelecting();
      }
    } else {
      // if we can't determine on the hand of the queryParams if we are selecting activities load the value from the
      // isSelecting behaviorsubject
      this.selectActivitiesControl.setValue(this.activitySelectionProvider.isSelecting$.value);
    }

    // Wait for the a first emission of the activity types of the ou. We need this to actually know in what context we need to search
    //
    this.currentSearchParams.activityTypeId = await firstValueFrom(this.$session.organizationActivityTypeIds$);

    if (lang) {
      this.currentSearchParams.language = lang;
    }

    if (advancedSearch) {
      const showActivities = this.getCurrentActivityType();
      const onlyParentNode = typeof showActivities === 'number';
      const statusId = showActivities === 'Archived' ? undefined : this.getCurrentPublicationStatus();
      const nodeOnly = showActivities === 'OUOnly' || onlyParentNode;
      const archived = showActivities === 'Archived';
      const searchOrganizationUnitId = onlyParentNode ? showActivities : organizationUnit.id;
      const language = await firstValueFrom(this.$session.preferredContentLanguage$);

      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          query: advancedSearch,
          organizationUnitId: searchOrganizationUnitId,
          nodeOnly,
          statusId,
          archived,
          language,
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
    } else if (activityKind) {
      const showActivities = this.getCurrentActivityType();
      const onlyParentNode = typeof showActivities === 'number';
      const statusId = showActivities === 'Archived' ? undefined : this.getCurrentPublicationStatus();
      const nodeOnly = showActivities === 'OUOnly' || onlyParentNode;
      const archived = showActivities === 'Archived';
      const searchOrganizationUnitId = onlyParentNode ? showActivities : organizationUnit.id;
      const language = await firstValueFrom(this.$session.preferredContentLanguage$);

      this.aggregationControl.setValue({
        activity_kind: [activityKind],
      });

      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          organizationUnitId: searchOrganizationUnitId,
          nodeOnly,
          statusId,
          archived,
          language,
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
    } else if (lang) {
      const showActivities = this.getCurrentActivityType();
      const onlyParentNode = typeof showActivities === 'number';
      const statusId = showActivities === 'Archived' ? undefined : this.getCurrentPublicationStatus();
      const nodeOnly = showActivities === 'OUOnly' || onlyParentNode;
      const archived = showActivities === 'Archived';
      const searchOrganizationUnitId = onlyParentNode ? showActivities : organizationUnit.id;

      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          organizationUnitId: searchOrganizationUnitId,
          nodeOnly,
          statusId,
          archived,
          language: lang,
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
    } else if (showActivitiesParam) {
      const parsedShowActivities = parseInt(`${showActivitiesParam}`, 10);
      this.activityStatusFiltersControl?.get('showActivities')?.setValue(parsedShowActivities);
      const onlyParentNode = typeof parsedShowActivities === 'number' && !Number.isNaN(parsedShowActivities);
      const statusId =
        Number.isNaN(parsedShowActivities) && showActivitiesParam === 'Archived'
          ? undefined
          : activityStatus
            ? parseInt(`${activityStatus}`, 10)
            : this.getCurrentPublicationStatus();
      this.activityStatusFiltersControl?.get('activityStatus')?.setValue(statusId);
      const nodeOnly = (Number.isNaN(parsedShowActivities) && showActivitiesParam === 'OUOnly') || onlyParentNode;
      const archived = Number.isNaN(parsedShowActivities) && showActivitiesParam === 'Archived';
      const searchOrganizationUnitId = onlyParentNode ? parsedShowActivities : organizationUnit.id;
      const language = await firstValueFrom(this.$session.preferredContentLanguage$);

      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          organizationUnitId: searchOrganizationUnitId,
          nodeOnly,
          statusId,
          archived,
          language,
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
    } else {
      // Execute a stored search if there is one. if we didn't pass a language from the query params
      //
      //
      await this.storedSearch();
    }

    const chosenSortOption = sortingOptions.find(
      (sortOption) => sortOption.key === this.currentSearchParams.order && sortOption.direction,
      this.currentSearchParams.orderDirection,
    );
    if (chosenSortOption) {
      this.sortOptionControl.setValue(chosenSortOption);
    } else {
      this.sortOptionControl.setValue('');
    }

    // at this point the status filters have been set with initial filters and possible stored values so we can see what the current values
    // are and set the write permission accordingly
    //
    const initialStatusFilterValue = await this.activityStatusFiltersControl.value;
    const initialShowActivitiesValue = initialStatusFilterValue.showActivities;
    const writePermission = await this.checkWritePermissionSelectedOU(initialShowActivitiesValue);

    this.hasSelectedOUWritePermission$.next(writePermission);

    this.setUpSubscriptions();
  }

  private async storedSearch() {
    const searchValuesJSON = window.localStorage.getItem('activitySearch');

    if (isNil(searchValuesJSON)) {
      this.fetchActivities$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
      return;
    }

    const storedSearch: IStoredSearch = JSON.parse(searchValuesJSON);
    await this.setUpStoredSearch(storedSearch);

    this.fetchActivities$.next({
      queryParams: this.currentSearchParams,
      concatenateResults: false,
      fetchAllAndIgnoreSkip: true,
      aggregations: this.getAggregationControlValue(),
      statusFilter: this.activityStatusFiltersControl.value,
    });
  }

  private async setUpStoredSearch(storedSearch: IStoredSearch) {
    const organizationUnitDetails = await firstValueFrom(this.currentOUDetails$);
    const organizationUnitId = organizationUnitDetails.id;

    // when the stored search if for another OU stop with setup as it is not a valid search for this OU
    //
    if (storedSearch.queryParams.organizationUnitId !== organizationUnitId) {
      return;
    }

    // get the status filters from the stored search
    //
    this.activityStatusFiltersControl.setValue(storedSearch.activityStatusFilterCtrlValue);

    // get the aggregation settings from the stored search
    //
    this.aggregationControl.setValue(storedSearch.aggregations);

    this.currentSearchParams = storedSearch.queryParams;

    if (!organizationUnitDetails.languages.includes(storedSearch.queryParams.language)) {
      this.currentSearchParams.language = await firstValueFrom(this.$session.preferredContentLanguage$);
    }
  }

  private setUpSubscriptions() {
    // Listen for updates of the activities
    //
    this.searchResults$.pipe(takeUntil(this.stop$)).subscribe(() => {
      this.updateMasonryLayout();
    });

    // Update masonay when all images are loaded
    //
    this.imagesLoaded$.pipe(takeUntil(this.stop$), auditTime(300)).subscribe(async () => {
      this.updateMasonryLayout();
      const mainScrollContainer = document.getElementById('main-scroll-container');

      this.scrollService.applySavedScroll('activity-search', mainScrollContainer);
    });

    // If the checkbox for collecting activities gets toggled, toggle the status.
    //
    this.selectActivitiesControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((isSelecting) => {
      if (isSelecting !== this.activitySelectionProvider.isSelecting$.value) {
        this.activitySelectionProvider.toggleSelecting();
      }
    });

    this.activitySelectionProvider.isSelecting$.pipe(takeUntil(this.stop$)).subscribe((isSelecting) => {
      if (isSelecting !== this.selectActivitiesControl.value) {
        this.selectActivitiesControl.setValue(isSelecting);
      }
    });

    // Prepare a observable that is true when the aggragations that are selected have changed
    //
    this.aggregationControl.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => {
          // Only compare the selected values, because new aggregations can be added
          // during a text search, this should not interfere with this subscription
          //
          const newA = omitBy(a, isEmpty);
          const newB = omitBy(b, isEmpty);
          return isEqual(newA, newB);
        }),
      )
      .subscribe((newAggregations) => {
        const showActivities = this.getCurrentActivityType();

        this.fetchActivities$.next({
          queryParams: this.currentSearchParams,
          concatenateResults: false,
          fetchAllAndIgnoreSkip: true,
          aggregations: newAggregations,
          statusFilter: showActivities,
        });
      });

    this.statusFilterChanges$.pipe(debounceTime(300), takeUntil(this.stop$)).subscribe(async (statusFilterValue) => {
      const writePermission = await this.checkWritePermissionSelectedOU(statusFilterValue.showActivities);
      if (!isEqual(writePermission, this.hasSelectedOUWritePermission$.value)) {
        this.hasSelectedOUWritePermission$.next(writePermission);
      }

      const showActivities = this.getCurrentActivityType();
      const onlyParentNode = typeof showActivities === 'number';
      const aggregations = this.getAggregationControlValue();
      const statusId = showActivities === 'Archived' ? undefined : this.getCurrentPublicationStatus();
      const nodeOnly = showActivities === 'OUOnly' || onlyParentNode;
      const archived = showActivities === 'Archived';
      const organizationUnitId = onlyParentNode ? showActivities : await firstValueFrom(this.sessionProvider.currentOuId$);

      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          organizationUnitId,
          nodeOnly,
          statusId,
          archived,
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations,
        statusFilter: showActivities,
      });
    });

    this.sortOptionControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((selectedSortOption: ISortingOption) => {
      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          skip: 0,
          order: selectedSortOption?.key ?? '',
          orderDirection: selectedSortOption?.direction ?? '',
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: false,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
    });
  }

  // A (manual) update from the activity filters
  //
  updateStateFilters($event: { activityStatus: any; showActivities: any }) {
    this.statusFilterChanges$.next($event);
  }

  getAggregationControlValue() {
    const aggregations: { [index: string]: [any] } = isNil(this.aggregationControl.value) ? {} : this.aggregationControl.value;

    const dedupedAggregations: { [index: string]: string[] } = {};

    Object.keys(aggregations).forEach((aggregationKey: string) => {
      const aggregationValues: string[] = aggregations[aggregationKey];

      const dedupedAggregationValues: string[] = [];

      aggregationValues.forEach((aggregationValue) => {
        if (!dedupedAggregationValues.includes(aggregationValue)) {
          dedupedAggregationValues.push(aggregationValue);
        }
      });

      dedupedAggregations[aggregationKey] = dedupedAggregationValues;
    });

    return dedupedAggregations;
  }

  /**
   * Opens a dialog and informs the user about the activity they'll create. After confirmation, creates an activity and navigates to the activity details page.
   */
  async createNew() {
    const organizationUnit = await firstValueFrom(this.currentOUDetails$);
    const organizationUnitId = organizationUnit.id;
    const organizationUnitName = organizationUnit.name;

    if (!this.hasSelectedOUWritePermission$.value) {
      this.$i18nToastProvider.error(_('activity_search.create.unauthorized'));

      return;
    }

    let dialogRef;
    const contentLanguageOptions = await firstValueFrom(this.$session.ouLanguages$);

    if (contentLanguageOptions.length > 1) {
      const data: IChoiceDialogData = {
        title: this.$translateService.instant(_('activity_search.create.confirm.dialog.title')),
        preselectedOption: this.currentSearchParams.language,
        description: this.$translateService.instant(_('activity_search.create.confirm.dialog.description.content_language'), {
          organization: organizationUnitName,
        }),
        selectionOptions: contentLanguageOptions.map((contentLanguage) => ({
          label: _(`generic.language.${contentLanguage}`),
          value: contentLanguage,
          labelShouldBeTranslated: true,
        })),
        actions: {
          primaryAction: {
            label: this.$translateService.instant(_('generic.ok')),
            value: 'confirm',
          },
        },
      };

      dialogRef = this.matDialog.open(ChoiceDialogComponent, {
        width: '600px',
        minWidth: '320px',
        data,
      });
    } else {
      dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('activity_search.create.confirm.dialog.title')),
          description: this.$translateService.instant(_('activity_search.create.confirm.dialog.description'), { organization: organizationUnitName }),
        },
      });
    }

    const dialogResult = (await firstValueFrom(dialogRef.afterClosed())) as string | IChoiceDialogResult;

    if ((typeof dialogResult === 'string' && dialogResult !== 'confirm') || (typeof dialogResult !== 'string' && dialogResult?.action !== 'confirm')) {
      return;
    }

    // Status ID 1 means the activity is a concept.
    //
    const statusId = 1;

    // create an IActivity object
    //
    const selectedLanguage = typeof dialogResult !== 'string' ? dialogResult.selectedOption : await firstValueFrom(this.$session.preferredContentLanguage$);
    const countryCode = await firstValueFrom(this.$session.ouCountryCode$);
    const activity: IActivity = { name: '', status_id: statusId, language: selectedLanguage, country_code: countryCode.toUpperCase() };

    // Post the IActivity object to the api
    //
    const createdActivity = (await firstValueFrom(this.activityService.create(organizationUnitId, activity))) as IActivity;

    // when the activity has successfully been created
    // link it to the current organization unit
    // and create material section for it
    //
    if (createdActivity) {
      // number 3 is a fixed number for owner role for the activity
      //
      await this.organizationUnitActivityService.update(organizationUnitId, createdActivity.id, 3);

      // lets fetch all section types to see which ones we need to add on creation
      //
      const allSectionTypes = await this.sectionTypeService.fetchAll(undefined, undefined, undefined, undefined, organizationUnitId);

      // lets sort them on id
      //
      allSectionTypes.sort((sectionTypeA, sectionTypeB) => sectionTypeA.id - sectionTypeB.id);

      // start with an order of 1 so that the items are not randomized in order
      //
      let sectionTypeIndex = 1;
      for (const sectionType of allSectionTypes) {
        // if a section type is required create a section of that type
        //
        if (sectionType.required) {
          const newSection: ISection = {
            type_id: sectionType.id,
            name: sectionType.name,
            activity_id: createdActivity.id,
            order: sectionTypeIndex,
          };

          if (SECTION_TYPE_DEFAULT_VALUES[sectionType.id]) {
            newSection.data = SECTION_TYPE_DEFAULT_VALUES[sectionType.id];
          }
          // eslint-disable-next-line no-await-in-loop
          await this.sectionService.create(newSection);
          // up the sectionTypeIndex by 1 so that the next required field is put behind this one
          //
          sectionTypeIndex += 1;
        }
      }

      this.router.navigate([`/activities/${createdActivity.id}/edit`]);
    } else {
      this.$i18nToastProvider.error(_('activity_search.create_new.failed'));
    }
  }

  public removeFilter($event: { name: string; value: string }) {
    const { name, value } = $event;
    const currentSelection: { [name: string]: string[] } = this.aggregationControl.value;
    if (currentSelection && currentSelection[name] && isArray(currentSelection[name]) && currentSelection[name].includes(value)) {
      currentSelection[name] = currentSelection[name].filter((label) => label !== value);
      this.aggregationControl.setValue(currentSelection);
    }
  }

  public removeAllFilters() {
    const currentSelection: { [name: string]: string[] } = this.aggregationControl.value;
    if (currentSelection) {
      each(currentSelection, (value, key) => {
        set(currentSelection, key, []);
      });
      this.aggregationControl.setValue(currentSelection);
    }
  }

  public trySuggestion(suggestion: string, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.fetchActivities$.next({
      queryParams: {
        ...this.currentSearchParams,
        query: suggestion ?? '',
      },
      concatenateResults: false,
      fetchAllAndIgnoreSkip: true,
      aggregations: this.getAggregationControlValue(),
      statusFilter: this.activityStatusFiltersControl.value,
    });
  }

  public async loadMoreActivities() {
    const currentSearchResultsLength = ((await firstValueFrom(this.searchResults$)) ?? []).length;
    if (currentSearchResultsLength > 0) {
      const offset = this.currentSearchParams.skip + this.currentSearchParams.limit;

      const { total } = await firstValueFrom(this.activitySearchQuery.getMetadata);

      // Check if there are more to fetch
      //
      if (offset < total) {
        this.fetchActivities$.next({
          queryParams: {
            ...this.currentSearchParams,
            skip: offset,
          },
          concatenateResults: true,
          fetchAllAndIgnoreSkip: false,
          aggregations: this.getAggregationControlValue(),
          statusFilter: this.activityStatusFiltersControl.value,
        });
      }
    }
  }

  private getCurrentPublicationStatus() {
    const { activityStatus } = this.activityStatusFiltersControl.value;

    if (activityStatus === 'alles') {
      return null;
    }
    return activityStatus;
  }

  private getCurrentActivityType() {
    const { showActivities } = this.activityStatusFiltersControl.value;
    return showActivities;
  }

  private async dofetchActivities(queryParams: IActivitySearchRequest, concatenateResults = false, fetchAllAndIgnoreSkip = false) {
    if (queryParams.skip === 0) {
      concatenateResults = false;
    }

    const aggregations = this.getAggregationControlValue();
    const activityStatusFilterCtrlValue = get(this.activityStatusFiltersControl, 'value') as IActivityStatusFilters;
    const activityStatus = activityStatusFilterCtrlValue.activityStatus;
    const showActivities = activityStatusFilterCtrlValue.showActivities;
    const onlyParentNode = typeof showActivities === 'number';
    const organizationUnit = await firstValueFrom(this.currentOUDetails$);

    this.currentSearchParams = {
      ...queryParams,
      organizationUnitId: onlyParentNode ? showActivities : organizationUnit.id,
      nodeOnly: ['OUOnly', 'Archived'].includes(showActivities as TActivityType) || ![EActivityStatusType.PUBLISHED, 'All'].includes(activityStatus) || onlyParentNode,
      statusId: showActivities === 'Archived' || activityStatus === 'All' ? undefined : activityStatus as EActivityStatusType,
      archived: showActivities === 'Archived',
      onlyReplaced: showActivities === 'Originals',
      filter: aggregations,
      allStatuses: activityStatus === 'All',
    };

    this.currentSearchParams.activityTypeId = await firstValueFrom(this.$session.organizationActivityTypeIds$);

    // When restoring a search from locale storage
    // we want to do a full search and igore the skip parameter
    //
    if (fetchAllAndIgnoreSkip === true) {
      this.currentSearchParams.limit += this.currentSearchParams.skip;
      this.currentSearchParams.skip = 0;
    }

    // Store the search params to use later.
    //
    const storedSearch: IStoredSearch = {
      queryParams: this.currentSearchParams,
      concat: concatenateResults,
      aggregations,
      activityStatusFilterCtrlValue,
    };
    window.localStorage.setItem('activitySearch', JSON.stringify(storedSearch));
    this.activitySearchService.fetch(this.currentSearchParams, concatenateResults);

    const hasQueryParamsInCurrentRoute = !isEmpty(this.route.snapshot.queryParams);
    if (hasQueryParamsInCurrentRoute) {
      const newUrlPath = this.$location.path().split('?')[0];
      this.$location.replaceState(newUrlPath);
    }
  }

  imageLoaded() {
    this.imagesLoaded$.next(undefined);
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  async activityClicked(clickedActivity: ISearchActivity) {
    const { showActivities } = get(this.activityStatusFiltersControl, 'value');

    if (showActivities === 'Archived') {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, name, status_id, archived } = clickedActivity;
      const unarchive = {
        id,
        name,
        status_id,
        archived,
      } as IActivity;

      try {
        await this.unarchiveActivity(unarchive);
      } catch (e) {
        console.log('unarchiving failed', e);
      }
    }

    const scrollDiv = document.getElementById('main-scroll-container');
    const scrollPosition = scrollDiv?.scrollTop;
    if (this.activitySelectionProvider.isSelecting$.value === false) {
      this.scrollService.setScroll('activity-search', scrollPosition);
      this.router.navigate([`preview/${clickedActivity.id}`], { relativeTo: this.activatedRoute });
    } else {
      this.scrollService.setScroll('activity-search', scrollPosition);
      this.activitySelectionProvider.toggleActivitySelected(clickedActivity);
    }
  }

  private async unarchiveActivity(activity: IActivity) {
    // open the confirmation dialog to make sure they want to unarchive the activity
    //
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity_search.unarchive.confirm.dialog.title')),
        description: this.$translateService.instant(_('activity_search.unarchive.confirm.dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result !== 'confirm') {
      return;
    }

    // when they confirmed they want to unarchive the activity
    //
    // update the activity with the archived value set to false
    //
    try {
      await this.activityService.update(activity.id, { ...activity, archived: false });
    } catch (e) {
      this.$i18nToastProvider.error(_('activity_search.unarchive.failed'));
      throw e;
    }
  }

  public get programSelectActive(): boolean {
    return this.activitySelectionProvider.isSelecting$.value;
  }

  onScrollEnd() {
    this.loadMoreActivities();
  }

  tabChange(tab: MatTabChangeEvent) {
    if (tab.index === 1) {
      setTimeout(() => this.updateMasonryLayout(), 0);
    }
  }

  async toggleAggregations(active?: boolean) {
    active = !isNil(active) ? active : !(await firstValueFrom(this.hideAggregations$));

    this.hideAggregations$.next(active);

    setTimeout(() => {
      this.updateMasonryLayout();
    }, 300);
  }

  public updateToolbarMenuOpen(toolBarMenuIsOpen: boolean) {
    this.toolBarContentOpen.next(toolBarMenuIsOpen);
  }

  doSearch(searchEvent?: ISearchEvent) {
    const currentQuery = this.currentSearchParams?.query ?? '';
    const newQuery = searchEvent.query ?? this.currentSearchParams?.query ?? '';
    const currentLang = this.currentSearchParams?.language ?? '';
    const newLang = searchEvent.language ?? this.currentSearchParams?.language ?? '';

    if (!isEqual(currentQuery, newQuery) || !isEqual(currentLang, newLang)) {
      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          query: newQuery,
          language: newLang,
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
        statusFilter: this.activityStatusFiltersControl.value,
      });
    }
  }

  async checkWritePermissionSelectedOU(selectedOUId: string | number): Promise<boolean> {
    const currentOUId = await firstValueFrom(this.$session.currentOuId$);
    let writePermission: boolean;

    if (typeof selectedOUId === 'number') {
      writePermission = isEqual(currentOUId, selectedOUId);
    } else if (selectedOUId === 'Archived') {
      writePermission = false;
    } else {
      writePermission = await this.$permission.checkOUWritePermission(currentOUId);
    }

    return writePermission;
  }

  activityIsSelected(activity: ISearchActivity) {
    const isSelected = this.activitySelectionProvider.selectedActivities$.value.find((selectedActivity) => selectedActivity.id === activity.id);
    return isSelected;
  }
}
