import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IJibbieOrganisationListResponse } from 'typings/api-customer';
import { IServiceResponse } from 'typings/api-generic';
import { IJibbieOrganisation, IJibbieLocation } from 'typings/doenkids/doenkids';
import { JibbieOrganizationStore } from './jibbie-organization.store';
import { firstValueFrom } from 'rxjs';
import { IListRequest } from 'typings/api-activity';

@Injectable({ providedIn: 'root' })
export class JibbieOrganizationService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: JibbieOrganizationStore) {}

  @transaction()
  async fetchAll(params: IListRequest) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall('/customer/jibbie/organisation', params)) as IJibbieOrganisationListResponse;
    this.store.add(response?.items ?? []);
    return response;
  }

  @transaction()
  async fetch(jibbieOrganisationId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCallWithoutErrorHandler(
      `/customer/jibbie/organisation/${jibbieOrganisationId}`,
    )) as IJibbieOrganisation;
    if (response) {
      this.store.upsert(response.id, response);
    }
    return response;
  }

  async setOrganizationUnit(jibbieOrganisationId: number, organizationUnitId: number) {
    const result = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/jibbie/organisation/${jibbieOrganisationId}/link/${organizationUnitId}`, null)) as IJibbieOrganisation;
    if (result) {
      this.store.upsert(result.id, result);
    }
    return result;
  }

  async unsetOrganizationUnit(jibbieOrganisationId: number) {
    const result = await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/jibbie/organisation/${jibbieOrganisationId}/link`)) as IJibbieOrganisation;
    if (result) {
      this.store.upsert(result.id, result);
    }
    return result;
  }

  async getOrganizationById(organizationUnitId: number) {
    const result = await firstValueFrom(this.$baseApi.genericGetCallWithoutErrorHandler(`/customer/jibbie/organisation/organization-unit/${organizationUnitId}`)) as IJibbieLocation[];
    return result;
  }

  sync() {
    return firstValueFrom(this.$baseApi.genericGetCall('/customer/jibbie/sync')) as Promise<IServiceResponse>;
  }
}
