import { Injectable } from '@angular/core';
import { ActivityKindService } from './activity-kind/activity-kind.service';
import { ActivityReviewScoreTypeService } from './activity-review-score-type/activity-review-score-type.service';
import { ActivityStatusService } from './activity-status/activity-status.service';
import { ActivityTypeService } from './activity-type/activity-type.service';
import { AgeGroupService } from './age-group/age-group.service';
import { AreaOfDevelopmentService } from './area-of-development/area-of-development.service';
import { GroupSizeService } from './group-size/group-size.service';
import { KvsMethodService } from './kvs-method/kvs-method.service';
import { LocationService } from './location/location.service';
import { PeriodSectionTypeService } from './period-section-type/period-section-type.service';
import { SectionTypeService } from './section-type/section-type.service';
import { RangeOfDevelopmentService } from './range-of-development/range-of-development.service';
import { MediaOriginTypeService } from './media-origin-type/media-origin-type.service';

@Injectable({
  providedIn: 'root',
})
export class GenericDataService {

  constructor(
    private $activityKind: ActivityKindService,
    private $activityReviewScoreType: ActivityReviewScoreTypeService,
    private $activityStatus: ActivityStatusService,
    private $activityType: ActivityTypeService,
    private $ageGroup: AgeGroupService,
    private $areaOfDevelopment: AreaOfDevelopmentService,
    private $groupSize: GroupSizeService,
    private $kvsMethod: KvsMethodService,
    private $location: LocationService,
    private $periodSectionType: PeriodSectionTypeService,
    private $sectionType: SectionTypeService,
    private $rangeOfDevelopment: RangeOfDevelopmentService,
    private $mediaOriginType: MediaOriginTypeService,
  ) {
  }

  async initialize() {
    const promises = [
      this.$activityKind.fetchAll(),
      this.$activityReviewScoreType.fetchAll(),
      this.$activityStatus.fetchAll(),
      this.$activityType.fetchAll(),
      this.$ageGroup.fetchAll(),
      this.$areaOfDevelopment.fetchAll(),
      this.$groupSize.fetchAll(),
      this.$kvsMethod.fetchAll(),
      this.$location.fetchAll(),
      this.$periodSectionType.fetchAll(),
      this.$sectionType.fetchAll(),
      this.$rangeOfDevelopment.fetchAll(),
      this.$mediaOriginType.fetchAll(),
    ];

    return Promise.all(promises);
  }
}
