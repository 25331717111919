<div class="user-search-form">
  <mat-icon class="icon-magnifying-glass">search</mat-icon>
  <input [placeholder]="'user_search.search.placeholder' | translate" [formControl]="searchControl" />
  <button
    type="button"
    class="clear"
    *ngIf="searchControl.value"
    (click)="clearSearch()"
    mat-mini-fab
    matTooltip="{{ 'activity_search_bar.clear_query' | translate }}"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
