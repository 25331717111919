import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IKonnectOrganisationDetails } from 'typings/api-customer';

export interface IKonnectOrganizationState extends EntityState<IKonnectOrganisationDetails> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'konnect-organization' })
export class KonnectOrganizationStore extends EntityStore<IKonnectOrganizationState, IKonnectOrganisationDetails> {
  constructor() {
    super({});
  }
}
