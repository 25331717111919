import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { IProgramAttachment } from 'typings/doenkids/doenkids';

export interface IDoenkidsProgramAttachmentState extends EntityState<IProgramAttachment> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-attachment' })
export class ProgramAttachmentStore extends EntityStore<IDoenkidsProgramAttachmentState, IProgramAttachment> {
  constructor() {
    super([]);
  }
}
