import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { PinbordProvider } from 'src/providers/pinbord.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IActivity, IFavoriteFolderDetails, ISearchActivity } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-remove-activity-from-pinbord-folder-dialog',
  templateUrl: './remove-activity-from-pinbord-folder-dialog.component.html',
  styleUrls: ['./remove-activity-from-pinbord-folder-dialog.component.scss'],
})
export class RemoveActivityFromPinbordFolderDialogComponent implements OnInit {
  public activityFolders: IFavoriteFolderDetails[] = [];

  public selectedFolder: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<RemoveActivityFromPinbordFolderDialogComponent>,
    private $pinbord: PinbordProvider,
    private $session: DoenkidsSessionProvider,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      activity: (ISearchActivity | IActivity),
    },
  ) {}

  async ngOnInit() {
    this.activityFolders = (await firstValueFrom(this.$pinbord.currentFolders$)).folders
      .filter((currentFolder) => currentFolder.activities.find((folderActivity) => folderActivity?.id === this.data.activity.id));

    if (this.activityFolders.length === 1) {
      this.selectedFolder.setValue([this.activityFolders[0].id]);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  async onRemove() {
    const folderIds = this.selectedFolder.value;

    if (!folderIds) {
      return;
    }

    for (const folderId of folderIds) {
      // eslint-disable-next-line no-await-in-loop
      await this.$pinbord.removeActivityFromFolder(folderId, this.data.activity);
    }

    const currentOuId = await firstValueFrom(this.$session.currentOuId$);
    this.$pinbord.fetchFolders(currentOuId);

    this.dialogRef.close('success');
  }
}
