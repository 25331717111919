import { Injectable } from '@angular/core';
import { IMedia } from 'typings/doenkids/doenkids';
import { IAssetUrl, TImageVariant } from 'typings/api-media';
import { isNil } from 'lodash';
import { IEntityWithAccessLevel } from 'typings/api-generic';
import { DoenKidsGenericApiProvider } from './generic.provider';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

interface IGetUrlParams {
  program_template_id?: number;
  program_template_bundle_id?: number;
  program_id?: number;
}

@Injectable({ providedIn: 'root' })
export class DoenkidsAssetProvider {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  async fetch(media_id: number): Promise<IEntityWithAccessLevel<IMedia>> {
    const mediaAsset = await firstValueFrom(this.$baseApi.genericGetCall(`/media/asset/${media_id}`)) as IEntityWithAccessLevel<IMedia>;
    return mediaAsset;
  }

  async fetchByUuid(media_uuid: string): Promise<IMedia> {
    const mediaAsset = await firstValueFrom(this.$baseApi.genericGetCall(`/media/asset/uuid/${media_uuid}`)) as IMedia;
    return mediaAsset;
  }

  async getUrl(media_uuid: string, params?: IGetUrlParams): Promise<string> {
    // The resulting url.
    //
    let result = '';

    // The query parameters, if any.
    //
    const queryParams = [];

    // Check if the params are present. If so, prepare the ids.
    //
    if (!isNil(params)) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { program_template_id, program_template_bundle_id, program_id } = params;

      // Fill in either the program template ID or the bundle ID.
      //
      if (!isNil(program_template_id)) {
        queryParams.push(`program_template_id=${program_template_id}`);
      }

      if (!isNil(program_template_bundle_id)) {
        queryParams.push(`program_template_bundle_id=${program_template_bundle_id}`);
      }

      if (!isNil(program_id)) {
        queryParams.push(`program_id=${program_id}`);
      }
    }

    try {
      const signedUrl = await firstValueFrom(this.$baseApi.genericGetCall(`/media/asset/url/${media_uuid}?${queryParams.join('&')}`)) as IAssetUrl;
      result = signedUrl.url;
    } catch (error) {
      console.error('[ASSETS]: Cannot fetch signed url for media uuid', media_uuid);
    }
    return result;
  }

  /**
   *
   * @param media_uuid The UUID of the media asset
   * @param variant The variant you want to fetch. The options are 'small', 'medium', 'large', 'xlarge'
   */
  async getImageUrl({
    media_uuid,
    variant = 'original',
    params,
  }: {
    media_uuid: string,
    variant?: TImageVariant,
    params?: IGetUrlParams,
  }): Promise<string> {
    // First try to see if the image is cached
    //
    const cachedUrl = this.$baseApi.getImageFromCache(media_uuid);
    if (cachedUrl) {
      return cachedUrl;
    }

    let result = '';

    const queryParams = [];
    if (variant) {
      queryParams.push(`variant=${variant}`);
    }
    if (params.program_template_id) {
      queryParams.push(`program_template_id=${params.program_template_id}`);
    }
    if (params.program_template_bundle_id) {
      queryParams.push(`program_template_bundle_id=${params.program_template_bundle_id}`);
    }

    if (params.program_id) {
      queryParams.push(`program_id=${params.program_id}`);
    }

    try {
      const signedUrl = await firstValueFrom(this.$baseApi.genericGetCall(`/media/asset/url/${media_uuid}?${queryParams.join('&')}`)) as IAssetUrl;
      result = signedUrl.url;

      // Add image to cache
      //
      this.$baseApi.addImageToCache(media_uuid, result);
    } catch (error) {
      if (error.status === 451) {
        result = environment.activityBlockedImage;
      } else {
        result = environment.activityImagePlaceholder;
      }
    }
    return result;
  }
}
