<app-doenkids-menu-bar [loading]="isLoading$ | async">
  <div right>
    <button
      *ngIf="((isAdmin$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)) && (hasActiveUsersEnabled$ | async)"
      mat-icon-button
      type="button"
      (click)="goToActiveUsers()"
      [matTooltip]="'organization_unit_details.active_users.tooltip' | translate"
    >
      <mat-icon>supervised_user_circle</mat-icon>
    </button>
    <button
      *ngIf="isAdmin$ | async"
      mat-icon-button
      type="button"
      (click)="goToActivities()"
      [matTooltip]="'organization_unit_details.user_activity.tooltip' | translate"
    >
      <mat-icon>assignment</mat-icon>
    </button>
  </div>
</app-doenkids-menu-bar>
<div class="organization-unit-details-content" (scroll)="onScroll($event)">
  <mat-tab-group #tabs [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="handleTabChange($event)" [class.tab-scrolled]="scrolled$ | async" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab
      [label]="'organization_unit_details.tab.general_info' | translate"
      *ngIf="(isAdmin$ | async) || (isReader$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
    >
      <ng-template matTabContent>
        <form [formGroup]="form">
          <div class="import-buttons">
            <div class="export-buttons">
              <button *ngIf="(isDoenkidsOrganization$ | async) && (isAdmin$ | async)" mat-button color="accent" (click)="syncJibbie()">
                {{ 'organization_unit_details.export.jibbie' | translate }}
                <mat-icon>sync</mat-icon>
              </button>
              <button *ngIf="(isDoenkidsOrganization$ | async) && (isAdmin$ | async)" mat-button color="accent" (click)="exportOUData()">
                {{ 'organization_unit_details.export.excel' | translate }}
                <mat-icon svgIcon="excel"></mat-icon>
              </button>
              <button *ngIf="(isDoenkidsOrganization$ | async) && (isAdmin$ | async)" mat-button color="accent" (click)="exportFinance()">
                {{ 'organization_unit_details.export.finance' | translate }}
                <mat-icon svgIcon="excel"></mat-icon>
              </button>
            </div>
            <div
              class="save-button-container"
              fxLayout="row"
              fxLayoutAlign="start"
              *ngIf="(isAdmin$ | async) || (!(isAdmin$ | async) && !(isReader$ | async) && (hasParentOUWritePermissions$ | async))"
            >
              <button
                mat-button
                class="save-button"
                type="submit"
                [disabled]="!form.dirty || form.invalid"
                (click)="saveDetails()"
                [matTooltip]="'organization_unit_details.save.tooltip' | translate"
              >
                <mat-icon>save</mat-icon>
                {{ 'generic.save_changes' | translate }}
              </button>
            </div>
          </div>
          <div class="tab-content-wrapper">
            <div class="organisation-name" fxLayoutGap="20px" fxLayout.lt-lg="column">
              <div class="organisation-name-details" fxLayout="column" fxLayoutGap="20px">
                <h4 class="permissions-title">{{ 'organization_unit.organization_details.title' | translate }}</h4>
                <mat-form-field [appearance]="inputAppearance">
                  <mat-label>{{ 'organization_unit.name.label' | translate }}</mat-label>
                  <input [readOnly]="readOnly" formControlName="name" matInput />
                </mat-form-field>
                <mat-form-field [appearance]="inputAppearance" *ngIf="(isAdmin$ | async) && (ouCountryCode$ | async)?.toLowerCase() === 'nl'">
                  <mat-label>{{ 'organization_unit.lrk_number.label' | translate }}</mat-label>
                  <input readonly formControlName="lrkIdentifier" matInput />
                  <div matSuffix class="lrk-suffix">
                    <button mat-icon-button (click)="chooseLrkIdentifier($event)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="clearLrkIdentifier($event)"
                      [disabled]="!form.get('lrkIdentifier').value || form.get('lrkIdentifier').value === ''"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </mat-form-field>
              </div>
              <div fxLayout="column">
                <mat-form-field *ngIf="(isAdmin$ | async) && (isOrganizationOfTypeLocation$ | async)" color="primary" class="oko-type-field">
                  <mat-label>{{ 'organization_unit.activity_type.placeholder' | translate }}</mat-label>
                  <mat-select formControlName="activity_type_id">
                    <mat-option *ngFor="let type of possibleActivityTypes" [value]="type.id">
                      {{ type.name }}
                    </mat-option>
                    <mat-option value="IKC" *ngIf="(ouCountryCode$ | async)?.toLowerCase() === 'nl'">{{ 'activity_type.integral_child_center.abbreviation' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div fxLayout="column" fxLayoutGap="20px" class="financial-note-wrapper">
                  <form fxLayout="column" fxLayoutGap="20px" [formGroup]="financialForm" *ngIf="isAdmin$ | async">
                    <mat-form-field [appearance]="inputAppearance">
                      <mat-label>{{ 'organization_unit.billing.start_month.label' | translate }}</mat-label>
                      <mat-select [placeholder]="'organization_unit.billing.start_month.placeholder' | translate" formControlName="billingStartMonth">
                        <mat-option *ngFor="let month of months$ | async" [value]="month.value">
                          {{ month.viewValue | titlecase }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field [appearance]="inputAppearance">
                      <mat-label>{{ 'organization_unit.billing.frequency.label' | translate }}</mat-label>
                      <input formControlName="billingFrequency" matInput />
                    </mat-form-field>
                    <mat-form-field color="primary" class="financial-note-field">
                      <mat-label>{{ 'organization_unit.financial_note.label' | translate }}</mat-label>
                      <textarea formControlName="financialNote" matInput></textarea>
                      <button mat-icon-button matSuffix (click)="editFinancialNote()" *ngIf="financialForm.get('financialNote').disabled">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </mat-form-field>
                  </form>
                  <ng-container *ngIf="(isAdmin$ | async) && (possibleContentLanguages$ | async) as contentLanguages">
                    <mat-form-field [appearance]="inputAppearance">
                      <mat-label>{{ 'content_language.posbbile.label' | translate }}</mat-label>
                      <mat-select multiple formControlName="contentLanguages">
                        <mat-option *ngFor="let contentLanguage of possibleContentLanguages$ | async" [value]="contentLanguage">
                          {{ 'generic.language.' + contentLanguage.toLowerCase() | translate | titlecase }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.get('contentLanguages').hasError('required')">{{ 'generic.language.form.required' | translate }}</mat-error>
                    </mat-form-field>
                  </ng-container>
                  <mat-checkbox
                    *ngIf="(hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async) && (isOrganizationOfTypeLocation$ | async)"
                    formControlName="test_organization"
                    color="primary"
                    class="test-organization-field"
                    [matTooltip]="'organization_unit.is_test.tooltip' | translate"
                  >
                    {{ 'organization_unit.is_test.label' | translate }}
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div class="addresses" *ngIf="isAdmin$ | async">
              <div class="address-column">
                <h4>{{ 'organization_unit_details.delivery_address.heading' | translate }}</h4>
                <mat-form-field [appearance]="inputAppearance">
                  <mat-label>{{ 'organization_unit.address.label' | translate }}</mat-label>
                  <input [readOnly]="readOnly" formControlName="address" matInput [errorStateMatcher]="errorStateMatcher" />
                  <mat-error *ngIf="form.get('address').hasError('required')">{{ 'generic.form.required_field' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [appearance]="inputAppearance">
                  <mat-label>{{ 'organization_unit.postal_code.label' | translate }}</mat-label>
                  <input [readOnly]="readOnly" formControlName="postalcode" matInput [errorStateMatcher]="errorStateMatcher" />
                  <mat-error *ngIf="form.get('postalcode').hasError('required')">{{ 'generic.form.required_field' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [appearance]="inputAppearance">
                  <mat-label>{{ 'organization_unit.city.label' | translate }}</mat-label>
                  <input [readOnly]="readOnly" formControlName="city" matInput [errorStateMatcher]="errorStateMatcher" />
                  <mat-error *ngIf="form.get('city').hasError('required')">{{ 'generic.form.required_field' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field [appearance]="inputAppearance" *ngIf="isAdmin$ | async">
                  <mat-label>{{ 'organization_unit.country.label' | translate }}</mat-label>
                  <mat-select formControlName="country">
                    <mat-select-trigger>
                      {{ getCountryLabel(form.get('country').value) }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let country of localSupportedCountries" [value]="country.countryCode">
                      {{ country.viewValue | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="address-column">
                <h4>{{ 'organization_unit_details.billing_address.heading' | translate }}</h4>
                <mat-form-field [appearance]="inputAppearance">
                  <mat-label>{{ 'organization_unit.address.label' | translate }}</mat-label>
                  <input [readOnly]="readOnly" formControlName="billing_address" matInput />
                </mat-form-field>
                <mat-form-field [appearance]="inputAppearance">
                  <mat-label>{{ 'organization_unit.postal_code.label' | translate }}</mat-label>
                  <input [readOnly]="readOnly" formControlName="billing_postalcode" matInput />
                </mat-form-field>
                <mat-form-field [appearance]="inputAppearance">
                  <mat-label>{{ 'organization_unit.city.label' | translate }}</mat-label>
                  <input [readOnly]="readOnly" formControlName="billing_city" matInput />
                </mat-form-field>
              </div>
            </div>

            <div class="permissions" *ngIf="(isAdmin$ | async) && !(isDoenkidsOrganization$ | async)">
              <h4 class="permissions-title">{{ 'organization_unit.permissions.heading' | translate }}</h4>
              <div class="permissions-wrapper">
                <p class="trickle-down-text" *ngIf="(trickleDownPermissionsText$ | async) as trickleDownPermissionsText">
                  {{ trickleDownPermissionsText }}
                </p>

                <ng-container *ngFor="let permissionCategory of permissionsMap">
                  <div class="category" *ngVar="parentPermissionOrigins$ | async as parentPermissions">
                    <mat-checkbox
                      [checked]="hasAllPermissions(permissionCategory)"
                      [indeterminate]="hasSomePermissions(permissionCategory)"
                      (change)="setCategoryPermissions(permissionCategory, $event.checked)"
                    >
                      <h4 class="category-title">{{ permissionCategory.viewValue | translate }}</h4>
                    </mat-checkbox>
                    <div class="category-permissions">
                      <ng-container *ngFor="let permission of permissionCategory.permissions">
                        <mat-checkbox *ngIf="permission.visible" [formControlName]="permission.formControlName">
                          {{ permission.viewValue | translate }} {{ getPermissionOrigin(permission.formControlName, parentPermissions) }}
                        </mat-checkbox>
                      </ng-container>
                      <ng-container *ngFor="let permission of permissionCategory.inheritingPermissions">
                        <mat-checkbox *ngIf="permission.visible" [formControlName]="permission.formControlName">
                          {{ permission.viewValue | translate }} {{ getPermissionOrigin(permission.formControlName, parentPermissions) }}
                        </mat-checkbox>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>

                <h4>{{ 'organization_unit.other_permissions.heading' | translate }}</h4>
                <ng-container *ngFor="let remainingPermission of remainingPermissions">
                  <mat-checkbox *ngIf="remainingPermission.visible" [formControlName]="remainingPermission.formControlName">
                    {{ remainingPermission.viewValue | translate }}
                  </mat-checkbox>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
        <doenkids-speed-dial
          *ngIf="!readOnly && !(isReader$ | async) && floatingActionButtons.length > 0"
          class="speeddial-container"
          [fabButtons]="floatingActionButtons"
          (fabClick)="speedDialClick($event)"
        ></doenkids-speed-dial>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="isOrganizationOfTypeCoorporate$ | async" [label]="'organization_unt_detail.tab.organizations' | translate">
      <ng-template matTabContent>
        <app-organization-unit-table
          [organizationUnitId]="selectedOrganizationUnitId"
          [organizationUnitTypeId]="EOrganizationUnitType.ORGANIZATION"
          [editable]="isAdmin$ | async"
          [canAddNewItem]="isAdmin$ | async"
          (listLoading$)="setChildListLoading($event)"
        ></app-organization-unit-table>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="isOrganizationOfTypeCustomer$ | async" [label]="'organization_unit_detail.tab.locations' | translate">
      <ng-template matTabContent>
        <app-organization-unit-table
          [organizationUnitId]="selectedOrganizationUnitId"
          [organizationUnitTypeId]="EOrganizationUnitType.LOCATION"
          [editable]="
            (isAdmin$ | async) ||
            (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async) ||
            (hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$ | async)
          "
          [canAddNewItem]="isAdmin$ | async"
          (listLoading$)="setChildListLoading($event)"
          [jibbieDetails]="jibbieDetails"
        ></app-organization-unit-table>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="isOrganizationOfTypeLocation$ | async" [label]="'organization_unit_detail.tab.groups' | translate">
      <ng-template matTabContent>
        <app-organization-unit-table
          [organizationUnitId]="selectedOrganizationUnitId"
          [organizationUnitTypeId]="EOrganizationUnitType.GROUP"
          [editable]="
            (isAdmin$ | async) ||
            (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async) ||
            (hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$ | async)
          "
          [canAddNewItem]="
            (isAdmin$ | async) ||
            (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async) ||
            (hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$ | async)
          "
          (listLoading$)="setChildListLoading($event)"
        ></app-organization-unit-table>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="(hasTemplateOptionsPermission$ | async) && (isAdmin$ | async)" [label]="'organization_unit_detail.tab.template_settings' | translate">
      <ng-template matTabContent>
        <app-pdf-writer-template-settings [organizationUnitId]="selectedOrganizationUnitId"></app-pdf-writer-template-settings>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="(translations$ | async) && (isAdmin$ | async)" [label]="'organization_unit_detail.tab.translations' | translate">
      <ng-template matTabContent>
        <app-translations [translations]="translations$ | async"></app-translations>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="(hasCustomerSymbolsPermission$ | async) && (isAdmin$ | async)" [label]="'organization_unit_detail.tab.customer_symbols' | translate">
      <ng-template matTabContent>
        <app-customer-symbols [organizationUnitId]="selectedOrganizationUnitId"></app-customer-symbols>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="isAdmin$ | async" [label]="'organization_unit_detail.tab.users' | translate">
      <ng-template matTabContent>
        <app-organization-users [organizationUnitId]="selectedOrganizationUnitId" (listLoading$)="setChildListLoading($event)"></app-organization-users>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="
        (hasKonnectIntegrationPermission$ | async) &&
        (isAdmin$ | async) &&
        ((isOrganizationOfTypeCustomer$ | async) || (isOrganizationOfTypeLocation$ | async)) &&
        (canSetKonnectIntegrationSettingsPermission$ | async)
      "
      [label]="'organization_unit_details.tab.konnect' | translate"
    >
      <ng-template matTabContent>
        <app-konnect-form [organizationUnitId]="selectedOrganizationUnitId"></app-konnect-form>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="(hasJibbieIntegrationPermission$ | async) && (isAdmin$ | async) && (isOrganizationOfTypeCustomer$ | async)"
      [label]="'organization_unit_details.tab.jibbie' | translate"
    >
      <ng-template matTabContent>
        <div class="tab-content-wrapper">
          <h2>{{ 'organization_unit.tab.jibbie.heading' | translate }}</h2>
          <mat-slide-toggle
            color="primary"
            (change)="toggleJibbie($event)"
            [(ngModel)]="isLinkedWithJibbie"
            [innerHtml]="
              'organization_unit.jibbie.link_status'
                | translate
                  : {
                      isLinkedWithJibbie: this.isLinkedWithJibbie,
                      organization: jibbieDetails?.name
                    }
            "
          ></mat-slide-toggle>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab
      *ngIf="!(isReader$ | async) && (isOrganizationOfTypeCustomer$ | async) && (hasNewsItemPermission$ | async)"
      [label]="'organization_unit_details.news_item.label' | translate"
      #newsFeedTab
    >
      <ng-template matTabContent>
        <app-organization-unit-manage-newsfeed #manageNewsfeedComponent></app-organization-unit-manage-newsfeed>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<router-outlet></router-outlet>
