import {
  Component, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isNil } from '@datorama/akita';
import {
  each, first as _first, get, isArray, isEqual, size,
} from 'lodash';
import {
  BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter, flatMap, map, takeUntil,
} from 'rxjs/operators';
import { ProgramCategoryListQuery } from 'src/api/activity/program-category-list/program-category-list.query';
import { ProgramCategoryListService } from 'src/api/activity/program-category-list/program-category-list.service';
import { ProgramTemplateBaseListQuery } from 'src/api/activity/program-template-base-list/program-template-base-list.query';
import { ProgramTemplateBaseListService } from 'src/api/activity/program-template-base-list/program-template-base-list.service';
import { ProgramTemplateBundleBaseListQuery } from 'src/api/activity/program-template-bundle-base-list/program-template-bundle-base-list.query';
import { ProgramTemplateBundleBaseListService } from 'src/api/activity/program-template-bundle-base-list/program-template-bundle-base-list.service';
import { ProgramTemplateBundleListQuery } from 'src/api/activity/program-template-bundle-list/program-template-bundle-list.query';
import { ProgramTemplateBundleListService } from 'src/api/activity/program-template-bundle-list/program-template-bundle-list.service';
import { ProgramTemplateBundleService } from 'src/api/activity/program-template-bundle/program-template-bundle.service';
import { ProgramTemplateListQuery } from 'src/api/activity/program-template-list/program-template-list.query';
import { ProgramTemplateListService } from 'src/api/activity/program-template-list/program-template-list.service';
import { ProgramTemplateService } from 'src/api/activity/program-template/program-template.service';
import { CurrentUserService } from 'src/api/customer/auth/auth.service';
import { TemplateSearchQuery } from 'src/api/search/template/template-search.query';
import { ChoiceDialogComponent, IChoiceDialogData, IChoiceDialogResult } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { CreateProgramTemplateBundleDialogComponent } from 'src/components/dialogs/create-program-bundle-dialog/create-program-bundle-dialog.component';
import { PermissionProvider } from 'src/providers/permission.provider';
import { ProgramCreationProvider } from 'src/providers/program-creation.provider';
import { ProgramDateProvider } from 'src/providers/program-date.provider';
import { ProgramTemplateSelectionProvider } from 'src/providers/program-template-selection.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IProgramTemplateSearchRequest } from 'typings/api-search';
import {
  IActivityType,
  IOrganizationUnitOverview,
  IProgramTemplate,
  IProgramTemplateBundle,
  IProgramTemplateProgramTemplateBundle,
  IProgramTemplateStatus,
} from 'typings/doenkids/doenkids.d';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TemplateSearchService } from 'src/api/search/template/template-search.service';
import { Navigation, SwiperOptions } from 'swiper';
import { IProgramCategoryWithOrganizationUnit } from 'typings/api-activity';
import { swiperStyles } from 'src/directives/swiper.directive';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { ISearchEvent } from 'src/components/shared/activity-search-bar/activity-search-bar.component';
import { ActivityTypeQuery } from 'src/api/generic/activity-type/activity-type.query';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';

interface ICategorizedTemplateSet {
  name: string;
  id: number;
  templates: IProgramTemplate[];
  bundles: IProgramTemplateBundle[];
  isOfOwner: boolean;
}

interface IStoredProgramTemplateSearch {
  queryParams: IProgramTemplateSearchRequest;
  concat: boolean;
  aggregations: any;
  activityTypeId: number;
  activityType?: string; // for backwards compatibility. DO NOT SET THIS ANYMORE
  revoked: boolean;
}

export interface IProgramTemplateKiosk {
  title: string;
  programTemplates: IProgramTemplate[];
}

interface IFetchTemplatesParams {
  queryParams: IProgramTemplateSearchRequest;
  concatenateResults?: boolean;
  fetchAllAndIgnoreSkip?: boolean;
  aggregations?: any;
  activityTypeId?: number;
  statusId?: number;
  revoked: boolean;
}

interface IExtendedProgramTemplateStatus extends IProgramTemplateStatus {
  paramToSet?: any;
}

@Component({
  selector: 'app-program-templates',
  templateUrl: './program-templates.component.html',
  styleUrls: ['./program-templates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramTemplatesComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public selectedOUId: number;

  // Indicates we fetch base template and bundles instead of the OU's versions
  //
  public baseOnly$ = new BehaviorSubject(false);

  public onlyTemplates$ = new BehaviorSubject(false);

  public onlyBundles$ = new BehaviorSubject(false);

  public queryLanguage$ = new BehaviorSubject<string>(null);

  public programTemplateSearch$: Observable<IProgramTemplate[]>;

  public revokedProgramTemplates$: BehaviorSubject<IProgramTemplate[]> = new BehaviorSubject<IProgramTemplate[]>([]);

  public revokedProgramTemplateBundles$: BehaviorSubject<IProgramTemplateBundle[]> = new BehaviorSubject<IProgramTemplateBundle[]>([]);

  public showRevokedTemplates = new UntypedFormControl(false);

  public programTemplates$: Observable<IProgramTemplate[]>;

  public templateBundles$: Observable<IProgramTemplateBundle[]>;

  public okoTypes$: Observable<IActivityType[]>;

  public hasProgramTemplateCreatePermission$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public selectedTemplates$: Observable<any>;

  public selectProgramsCtrl: UntypedFormControl;

  public typeOko: UntypedFormControl;

  private programTemplateLoading$: Observable<boolean>;

  private programBaseTemplateLoading$: Observable<boolean>;

  private templateBundlesLoading$: Observable<boolean>;

  private templateBaseBundlesLoading$: Observable<boolean>;

  public loading$: Observable<boolean>;

  public swiperConfig: SwiperOptions = {
    modules: [Navigation],
    a11y: {
      enabled: true,
    },
    slidesPerView: 'auto',
    keyboard: true,
    mousewheel: false,
    navigation: true,
    injectStyles: [swiperStyles],
  };

  private destroy$: Subject<void> = new Subject();

  public programCategories$: Observable<IProgramCategoryWithOrganizationUnit[]>;

  public categorizedProgramTemplates$: Observable<ICategorizedTemplateSet[]>;

  public isAdmin$: Observable<boolean>;

  public currentPublicationStatus = 3;

  public triggerSearchView$: Subject<boolean> = new Subject<boolean>();

  private fetchTemplates$: Subject<IFetchTemplatesParams> = new Subject();

  public searchQueryCtrl = new FormControl<ISearchEvent | null>(null);

  public currentSearchParams: IProgramTemplateSearchRequest;

  public aggregationControl: UntypedFormControl = new UntypedFormControl();

  // Observable that will inform us if an aggregation is selected on the lefthand-side
  //
  public containsAggregations$ = this.aggregationControl.valueChanges.pipe(
    map((aggregations) => {
      let containsOptions = false;
      each(aggregations, (value) => {
        if (isArray(value) && size(value) > 0) {
          containsOptions = true;
        }
      });
      return containsOptions;
    }),
  );

  public aggregations$: Observable<any>;

  private SEARCH_LIMIT = 1000;

  public SEARCH_STORAGE_KEY = 'programTemplateSearch';

  private isSearching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public additionalProgramStatuses: IExtendedProgramTemplateStatus[] = [
    { id: -1, name: 'program_templates.revoked.label',  paramToSet: { revoked: true } },
  ];

  constructor(
    fb: UntypedFormBuilder,
    private $programCreateService: ProgramCreationProvider,
    private $programDateService: ProgramDateProvider,
    private $session: DoenkidsSessionProvider,
    private dialog: MatDialog,
    private programTemplateBundleListQuery: ProgramTemplateBundleListQuery,
    private programTemplateBundleListService: ProgramTemplateBundleListService,
    private programTemplateBundleBaseListQuery: ProgramTemplateBundleBaseListQuery,
    private programTemplateBundleBaseListService: ProgramTemplateBundleBaseListService,
    private programTemplateListQuery: ProgramTemplateListQuery,
    private programTemplateListService: ProgramTemplateListService,
    private programTemplateBaseListQuery: ProgramTemplateBaseListQuery,
    private programTemplateBaseListService: ProgramTemplateBaseListService,
    private programTemplateBundleService: ProgramTemplateBundleService,
    private programCategoryService: ProgramCategoryListService,
    private programCategoryQuery: ProgramCategoryListQuery,
    programSearchQuery: TemplateSearchQuery,
    private programSearchService: TemplateSearchService,
    private router: Router,
    private route: ActivatedRoute,
    private activeRoute: ActivatedRoute,
    private $permissions: PermissionProvider,
    public $programTemplateSelection: ProgramTemplateSelectionProvider,
    public currentUserService: CurrentUserService,
    private $programTemplate: ProgramTemplateService,
    private $programTemplateBundle: ProgramTemplateBundleService,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    private activityTypeQuery: ActivityTypeQuery,
  ) {
    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$));
    this.programTemplateSearch$ = programSearchQuery.selectAll().pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value)),
    );

    this.hasProgramTemplateCreatePermission$ = this.$permissions.hasProgramTemplateCreatePermission$.pipe(takeUntil(this.stop$));

    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permissions.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));

    this.programTemplates$ = combineLatest([this.baseOnly$, this.revokedProgramTemplates$]).pipe(
      flatMap(([baseOnly, revokedProgramTemplates]) => {
        if (baseOnly) {
          if (this.showRevokedTemplates.value) {
            return of(revokedProgramTemplates);
          }
          return this.programTemplateBaseListQuery.selectAll();
        }
        return this.programTemplateListQuery.selectAll();
      }),
    );

    this.templateBundles$ = combineLatest([this.baseOnly$, this.revokedProgramTemplateBundles$]).pipe(
      flatMap(([baseOnly, revokedTemplateBundles]) => {
        if (baseOnly) {
          if (this.showRevokedTemplates?.value) {
            return of(revokedTemplateBundles);
          }
          return this.programTemplateBundleBaseListQuery.selectAll();
        }
        return this.programTemplateBundleListQuery.selectAll();
      }),
    );

    this.selectedTemplates$ = this.$programTemplateSelection.programTemplates$.pipe(
      map((templates) => {
        const selected = {};
        templates.forEach((template) => {
          selected[template.id] = true;
        });
        return selected;
      }),
    );

    this.programTemplateLoading$ = this.programTemplateListQuery.selectLoading().pipe(takeUntil(this.stop$));
    this.programBaseTemplateLoading$ = this.programTemplateBaseListQuery.selectLoading().pipe(takeUntil(this.stop$));
    this.templateBundlesLoading$ = this.programTemplateBundleListQuery.selectLoading().pipe(takeUntil(this.stop$));
    this.templateBaseBundlesLoading$ = this.programTemplateBundleBaseListQuery.selectLoading().pipe(takeUntil(this.stop$));

    this.loading$ = this.baseOnly$.pipe(
      flatMap((baseOnly: boolean) => {
        if (baseOnly) {
          return combineLatest([
            this.programBaseTemplateLoading$,
            this.templateBaseBundlesLoading$,
            this.isSearching$,
          ]).pipe(
            map((loading) => loading.includes(true)),
          );
        }
        return combineLatest([
          this.programTemplateLoading$,
          this.templateBundlesLoading$,
          this.isSearching$,
        ]).pipe(
          map((loading) => loading.includes(true)),
        );
      }),
    );

    this.programCategories$ = this.programCategoryQuery.selectAll().pipe(
      filter((value) => !isNil(value)),
    );

    this.categorizedProgramTemplates$ = combineLatest([
      this.programCategories$,
      this.programTemplates$,
      this.templateBundles$,
      this.$translateService.onInitialTranslationAndLangOrTranslationChange$,
    ]).pipe(
      map(([categories, templates, bundles, langChange]) => {
        const result: ICategorizedTemplateSet[] = [];
        const customerSpecificCategories = categories.map((category) => category.id);

        categories.forEach((category) => {
          const templateList = templates.filter((template) => template.program_category_id === category.id).sort((a, b) => a.order - b.order);
          const bundleList = bundles.filter((bundle) => bundle.program_category_id === category.id).sort((a, b) => a.order - b.order);
          const categoryResult = result.find((item) => item.id === category.id) ?? {
            id: category.id,
            name: category.name,
            templates: [],
            bundles: [],
            isOfOwner: (category as any).organization_unit_id === this.selectedOUId,
          };
          const isTemplateInCategory = size(templateList) > 0;
          const isBundleInCategory = size(bundleList) > 0;

          if (isTemplateInCategory || isBundleInCategory) {
            if (isBundleInCategory) {
              categoryResult.bundles = categoryResult.bundles.concat(bundleList);
            }
            if (isTemplateInCategory) {
              categoryResult.templates = categoryResult.templates.concat(templateList);
            }
            result.push(categoryResult);
          }
        });

        // Add uncategorized templates, if there are any
        //
        const otherTemplates = templates.filter((template) => !customerSpecificCategories.includes(template.program_category_id));
        if (size(otherTemplates)) {
          const uncategorizedTemplatesCategory = {
            name: langChange.translations[_('program_templates.uncategorized_templates.name')],
            id: undefined,
            templates: [],
            bundles: [],
            isOfOwner: false,
          };
          uncategorizedTemplatesCategory.templates.push(...otherTemplates);

          result.push(uncategorizedTemplatesCategory);
        }

        // Add uncategorized bundles, if there are any
        //
        const otherBundles = bundles.filter((bundle) => !customerSpecificCategories.includes(bundle.program_category_id));
        if (size(otherBundles)) {
          const uncategorizedBundlesCategory = {
            name: langChange.translations[_('program_templates.uncategorized_bundles.name')],
            id: undefined,
            templates: [],
            bundles: [],
            isOfOwner: false,
          };
          uncategorizedBundlesCategory.bundles.push(...otherBundles);
          result.push(uncategorizedBundlesCategory);
        }

        return result;
      }),
    );

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));

    this.aggregations$ = programSearchQuery.getAggregations.pipe(
      debounceTime(100),
      filter((aggregations) => !isNil(aggregations)),
      map((aggregations) => aggregations),
    );

    this.okoTypes$ = this.$session.availableActivityTypes$.pipe(takeUntil(this.stop$));

    this.selectProgramsCtrl = fb.control(false);
    this.typeOko = fb.control(0);
    this.showRevokedTemplates = fb.control(false);
    this.activeRoute.data.pipe(
      takeUntil(this.destroy$),
    ).subscribe((state) => {
      this.baseOnly$.next(state.baseOnly);
    });
    this.currentPublicationStatus = 3;
  }

  private async storedSearch() {
    // eslint-disable-next-line no-undef
    const searchValuesJSON = localStorage.getItem(this.SEARCH_STORAGE_KEY);

    if (isNil(searchValuesJSON)) {
      this.fetchTemplates$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.aggregationControl.value,
        activityTypeId: this.typeOko.value,
        statusId: this.currentPublicationStatus,
        revoked: this.showRevokedTemplates.value,
      });
      return;
    }

    const storedSearch: IStoredProgramTemplateSearch = JSON.parse(searchValuesJSON);
    await this.setUpStoredSearch(storedSearch);

    this.fetchTemplates$.next({
      queryParams: this.currentSearchParams,
      concatenateResults: false,
      fetchAllAndIgnoreSkip: true,
      aggregations: this.aggregationControl.value,
      activityTypeId: this.typeOko.value,
      statusId: this.currentPublicationStatus,
      revoked: this.showRevokedTemplates.value,
    });
  }

  private async setUpStoredSearch(storedSearch: IStoredProgramTemplateSearch) {
    // when the stored search if for another OU stop with setup as it is not a valid search for this OU
    //
    if (parseInt(`${storedSearch.queryParams.organizationUnitId}`, 10) !== this.selectedOUId) {
      return;
    }

    const availableOkoTypes = await firstValueFrom(this.okoTypes$);

    const parsedActivityTypeId = parseInt(`${storedSearch.activityTypeId}`, 10);

    const activityTypeStillAvailable = availableOkoTypes.find((okoType) => {
      if (storedSearch.activityType) {
        if (typeof storedSearch.activityType === 'string') {
          return okoType.name.toLowerCase() === storedSearch.activityType.toLowerCase();
        } else if (typeof storedSearch.activityType === 'number') {
          return okoType.id === storedSearch.activityType;
        }
      }
      return okoType.id === parsedActivityTypeId;
    });

    if (activityTypeStillAvailable) {
      // the stored activity type is still available (the possible ones haven't changed)
      // so we set the stored type (this is mostly useful when multiple types are available)
      // If its no longer available we already forced set it to the first option in the ngOnInit
      //
      this.typeOko.setValue(activityTypeStillAvailable.id, { emitEvent: false });
    }

    // get the aggregation settings from the stored search
    //
    this.aggregationControl.setValue(storedSearch.aggregations, { emitEvent: false });

    this.showRevokedTemplates.setValue(storedSearch.revoked ?? false, { emitEvent: false });

    const organizationUnitDetails = await firstValueFrom(this.currentOUDetails$);


    // eslint-disable-next-line prefer-destructuring
    const queryParams: IProgramTemplateSearchRequest = storedSearch.queryParams;

    this.currentSearchParams = queryParams;

    if (!this.queryLanguage$.value) {
      let storedLanguage = storedSearch.queryParams.language ?? '';

      if (!organizationUnitDetails.languages.includes(storedLanguage)) {
        const selectedLanguage = await firstValueFrom(this.$session.preferredContentLanguage$);
        this.currentSearchParams.language = selectedLanguage;
        storedLanguage = selectedLanguage;
      }

      this.searchQueryCtrl.setValue({
        language: storedLanguage,
        query: storedSearch.queryParams.query ?? '',
      }, { emitEvent: false });
    }

    if (storedSearch.queryParams.statusId) {
      this.currentPublicationStatus = storedSearch.queryParams.statusId;
    }
  }

  async doSearch(currentSearchParams?: IProgramTemplateSearchRequest, concatenateResults = false, fetchAllAndIgnoreSkip: boolean = false) {
    if (this.isSearching$.value) {
      return;
    }

    this.isSearching$.next(true);

    const activityTypeId = this.typeOko.value; // always use the currently selected type oko
    const aggregations: { [index: string]: [any] } = isNil(this.aggregationControl.value) ? {} : this.aggregationControl.value;
    const statusId = this.currentPublicationStatus;

    this.currentSearchParams = {
      ...currentSearchParams,
      activityTypeId,
      statusId,
      filter: aggregations,
    };

    // When restoring a search from locale storage
    // we want to do a full search and igore the skip parameter
    //
    if (fetchAllAndIgnoreSkip === true) {
      this.currentSearchParams.limit += this.currentSearchParams.skip;
      this.currentSearchParams.skip = 0;
    }

    await this.saveCurrentSearch();

    await this.programSearchService.fetch(this.currentSearchParams, concatenateResults);

    this.isSearching$.next(false);
  }

  saveCurrentSearch() {
    const aggregations: { [index: string]: [any] } = isNil(this.aggregationControl.value) ? {} : this.aggregationControl.value;
    const activityTypeId = this.typeOko.value; // always use the currently selected type oko
    const revoked = this.showRevokedTemplates.value;

    const storedSearch: IStoredProgramTemplateSearch = {
      queryParams: this.currentSearchParams, concat: false, aggregations, activityTypeId, revoked,
    };

    // eslint-disable-next-line no-undef
    localStorage.setItem(this.SEARCH_STORAGE_KEY, JSON.stringify(storedSearch));
  }

  async fetchBundlesAndPrograms(organizationId: number, activityTypeId: number, statusId?: number) {
    let templatePromise;
    let templateBundlePromise;
    let language = this.currentSearchParams.language ?? this.searchQueryCtrl.value?.language;
    if (this.baseOnly$.value) {
      // Fetch the program catgories for DoenKids
      //
      this.programCategoryService.fetchAll(DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER, {
        language,
      });

      if (!this.showRevokedTemplates.value) {
        // Fetch the program templates
        //
        templatePromise = this.programTemplateBaseListService.fetchAll({
          organizationUnitId: organizationId,
          activityTypeId,
          statusId,
          sortField: 'order',
          sortDirection: 'asc',
          limit: 1000,
          skip: 0,
          language,
        });

        // Fetch the program bundles
        //
        templateBundlePromise = this.programTemplateBundleBaseListService.fetchAll({
          organizationUnitId: organizationId,
          limit: 1000,
          skip: 0,
          sortField: 'order',
          sortDirection: 'ASC',
          activityTypeId,
          statusId,
          language,
        });
      } else {
        templatePromise = this.$programTemplate.fetchRevoked({
          organizationUnitId: organizationId,
          limit: 1000,
          skip: 0,
          language,
        }).then((revokedTemplatesListResponse) => {
          this.revokedProgramTemplates$.next(revokedTemplatesListResponse.items.filter((template) => !template.activity_type_id || template.activity_type_id === activityTypeId));
        });

        templateBundlePromise = this.$programTemplateBundle.fetchRevoked({
          organizationUnitId: organizationId,
          limit: 1000,
          skip: 0,
          language,
        }).then((revokedTemplateBundlesListResponse) => {
          this.revokedProgramTemplateBundles$.next(revokedTemplateBundlesListResponse.items.filter(
            (templateBundle) => !templateBundle.activity_type_id || templateBundle.activity_type_id === activityTypeId,
          ));
        });
        this.programTemplateBaseListService.setLoading(false);
        this.programTemplateBundleBaseListService.setLoading(false);
      }
    } else {
      // Fetch the program catgories for the selected OU
      //
      this.programCategoryService.fetchAll(organizationId, {
        language,
      });

      let statusParams: any = {};
      const usedAdditionalStatus = this.additionalProgramStatuses.find((programStatus) => programStatus.id === statusId);
      if (usedAdditionalStatus) {
        statusParams = usedAdditionalStatus.paramToSet ?? {};
      } else {
        statusParams = {
          statusId,
        };
      }

      // Fetch the program templates
      //
      templatePromise = this.programTemplateListService.fetchAll({
        organizationUnitId: organizationId,
        activityTypeId,
        sortField: 'order',
        sortDirection: 'asc',
        limit: 1000,
        skip: 0,
        language,
        ...statusParams,
      });

      // Fetch the program bundles
      //
      templateBundlePromise = this.programTemplateBundleListService.fetchAll({
        organizationUnitId: organizationId,
        limit: 1000,
        skip: 0,
        sortField: 'order',
        sortDirection: 'ASC',
        activityTypeId,
        language,
        ...statusParams,
      });
    }

    this.searchQueryCtrl.setValue({
      query: '',
      language,
    }, { emitEvent: false });
    this.aggregationControl.setValue({}, { emitEvent: false });
    this.currentSearchParams = {
      ...this.currentSearchParams,
      query: this.searchQueryCtrl.value?.query ?? '',
      language: this.searchQueryCtrl.value?.language ?? '',
      filter: this.aggregationControl.value,
    };

    const searchSave = this.saveCurrentSearch();

    return Promise.all([templatePromise, templateBundlePromise, searchSave]);
  }

  searchEventHappened(searchEvent: ISearchEvent) {
    const currentQuery = this.currentSearchParams?.query ?? '';
    const newQuery = searchEvent.query ?? this.currentSearchParams?.query ?? '';
    const currentLang = this.currentSearchParams?.language ?? '';
    const newLang = searchEvent.language ?? this.currentSearchParams?.language ?? '';

    if (!isEqual(currentQuery, newQuery) || !isEqual(currentLang, newLang)) {
      this.searchQueryCtrl.setValue({
        query: newQuery,
        language: newLang,
      });
    }
  }

  changePublicationStatus(status: IProgramTemplateStatus) {
    this.currentPublicationStatus = status.id;
    this.fetchTemplates$.next({
      queryParams: this.currentSearchParams,
      concatenateResults: false,
      fetchAllAndIgnoreSkip: true,
      aggregations: this.aggregationControl.value,
      activityTypeId: this.typeOko.value,
      statusId: this.currentPublicationStatus,
      revoked: this.showRevokedTemplates.value,
    });
    console.log('[PROGRAM-TEMPLATE]: Change publication status to', status.id);
  }

  async ngOnInit() {
    // Get a snapshot of the current route parameters.
    //
    const { queryParams } = this.route.snapshot;

    this.onlyBundles$.next(queryParams.onlyBundles ?? false);
    this.onlyTemplates$.next(queryParams.onlyTemplates ?? false);
    this.queryLanguage$.next(queryParams.language ?? null);
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    const contentLanguage = this.queryLanguage$.value ?? await firstValueFrom(this.$session.preferredContentLanguage$);

    this.currentSearchParams = {
      query: '',
      limit: this.SEARCH_LIMIT,
      skip: 0,
      organizationUnitId,
      filter: {},
      language: contentLanguage,
    };

    // When a new organization is selected rewrite the current url
    //
    this.$session.currentOuId$.pipe(takeUntil(this.destroy$)).subscribe(async (currentOuId) => {
      const currentRoute = await firstValueFrom(this.route.url);
      const pathSegmentWithCurrentOuId = currentRoute.find((urlSegment) => urlSegment.path.includes(`${currentOuId}`));
      if (!pathSegmentWithCurrentOuId) {
        this.router.navigate([`/organization/${currentOuId}/${this.baseOnly$.value === true ? 'base/' : ''}templates`], { replaceUrl: true, queryParams });
      }
    });

    // Set the initial value of the program template selector.
    //
    this.selectProgramsCtrl.setValue(this.$programTemplateSelection.active);

    // Subscribe to valuechanges in the "voorbeelden bundelen" checkbox.
    //
    this.selectProgramsCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((isActive) => {
      this.$programTemplateSelection.toggleActive(isActive);
    });

    // Make sure we don't fetch activities unnecessarily
    //
    this.fetchTemplates$.pipe(
      filter((request) => !isNil(request)),
      takeUntil(this.stop$),
      debounceTime(300),
      distinctUntilChanged(isEqual),
    ).subscribe((request: IFetchTemplatesParams) => {
      if (isNil(request.queryParams.query) || request.queryParams.query === '') {
        this.triggerSearchView$.next(false);
        this.fetchBundlesAndPrograms(this.selectedOUId, this.typeOko.value, request.statusId ?? this.currentPublicationStatus);
      } else {
        this.triggerSearchView$.next(true);
        this.doSearch(request.queryParams, request.concatenateResults, request.fetchAllAndIgnoreSkip);
        this.programTemplateBundleListService.setLoading(false);
        this.programTemplateListService.setLoading(false);
      }
    });

    // Set up a subscription. Whenever the current customer is changed, new programs and bundles are fetched.
    //
    this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(async (organization) => {
      this.selectedOUId = organization.id;
      this.currentSearchParams = {
        ...this.currentSearchParams,
        organizationUnitId: organization.id,
      };

      if (!organization.languages.includes(this.currentSearchParams.language)) {
        this.currentSearchParams.language = await firstValueFrom(this.$session.preferredContentLanguage$);
      }

      // if the OU has no activity type it probably means if customer no locations were added yet or if a location the type wasn't set
      // if this happens we just lookup what activity types we are having for that country and show all of those (code for this is in activity type query)
      // and we select the first one here just so what we show makes sense
      //
      let activityTypeId = parseInt(`${_first(organization.activity_type_ids)}`, 10);

      if (!Number.isNaN(activityTypeId)) {
        this.typeOko.setValue(activityTypeId, { emitEvent: false });
        this.storedSearch();
      } else {
        const activityTypes = this.activityTypeQuery.getActivityTypeByCountryCode(organization.country_code);
        this.typeOko.setValue(activityTypes[0].id, { emitEvent: false });
        this.fetchBundlesAndPrograms(organization.id, activityTypes[0].id);
      }
    });

    this.searchQueryCtrl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(300)).subscribe((searchEvent) => {
      this.currentSearchParams = {
        ...this.currentSearchParams,
        query: searchEvent.query,
        language: searchEvent.language,
      };
      this.fetchTemplates$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.aggregationControl.value,
        activityTypeId: this.typeOko.value,
        statusId: this.currentPublicationStatus,
        revoked: this.showRevokedTemplates.value,
      });
    });

    this.typeOko.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter((value) => !isNil(value)),
    ).subscribe((newActivityTypeId: number) => {
      this.fetchTemplates$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.aggregationControl.value,
        activityTypeId: newActivityTypeId,
        statusId: this.currentPublicationStatus,
        revoked: this.showRevokedTemplates.value,
      });
    });

    this.aggregationControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(300)).subscribe((newAggregations) => {
      this.fetchTemplates$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: newAggregations,
        activityTypeId: this.typeOko.value,
        statusId: this.currentPublicationStatus,
        revoked: this.showRevokedTemplates.value,
      });
    });

    this.showRevokedTemplates.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((showRevoked) => {
      this.fetchTemplates$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.aggregationControl.value,
        activityTypeId: this.typeOko.value,
        statusId: this.currentPublicationStatus,
        revoked: showRevoked,
      });
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.destroy$.next();

    // Leaving this page resets the program selection for bundle creation.
    //
    this._resetBundleSelection();
  }

  /** Resets the current bundle selection.
 */
  _resetBundleSelection() {
    this.selectProgramsCtrl.setValue(false);
    this.selectProgramsCtrl.updateValueAndValidity();
    this.$programTemplateSelection.clearList();
  }

  async handleProgramTemplateClick(event: Event, programTemplate: IProgramTemplate) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.showRevokedTemplates.value) {

      if (this.$programTemplateSelection.hasTemplateToSwitch()) {
        this.$programTemplateSelection.switchTemplate(programTemplate);
        return;
      }


      if (this.selectProgramsCtrl.value) {
        this.$programTemplateSelection.toggleProgramTemplateSelected(programTemplate);
        return;
      }

      // A date has already been selected, create a program?
      //
      if (!isNil(this.$programDateService.timespan)) {
        const result = await this.$programCreateService.promptProgramFromTemplate(programTemplate);

        // If the bundle wasn't selected for viewing, then the user wants to plan this program on the calendar.
        //
        if (result !== 'view-template') {
          return;
        }
      }
    }

    const queryParams: any = {};

    if (this.baseOnly$.value) {
      queryParams.baseOnly = this.baseOnly$.value;

      if (this.showRevokedTemplates.value) {
        queryParams.isRevoked = this.showRevokedTemplates.value;
      }
    }

    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    this.router.navigate([`/organization/${organizationUnitId}/template/${programTemplate.id}`], {
      queryParams,
    });
  }

  async handleProgramTemplateBundleClick(programTemplateBundle: IProgramTemplateBundle) {
    const { id: programTemplateBundleId } = programTemplateBundle;
    const selectedTemplates = await firstValueFrom(this.$programTemplateSelection.programTemplates$);

    if (!this.showRevokedTemplates.value) {
      // If the program select is active, and there's at least one program selected, prompt the adding dialog.
      //
      if (this.selectProgramsCtrl.value === true && get(selectedTemplates, 'length') > 0) {
        this.promptAddProgramTemplatesToBundle(selectedTemplates, programTemplateBundle);
        return;
      }
    }

    this.openProgramTemplateBundle(programTemplateBundleId);
  }

  /**
   * Prompts the user to add the selected programs to the bundle, or to just view the bundle.
   * @param selectedTemplates The selected program templates.
   * @param programTemplateBundle The bundle that will be viewed or added to.
   */
  private async promptAddProgramTemplatesToBundle(selectedTemplates: IProgramTemplate[], programTemplateBundle: IProgramTemplateBundle) {
    const { id: programTemplateBundleId, name } = programTemplateBundle;

    const data: IChoiceDialogData = {
      title: this.$translateService.instant(_('program_templates.add_to_bundle.dialog.title')),
      description: this.$translateService.instant(
        _('program_templates.add_to_bundle.dialog.description'),
        {
          amountSelected: selectedTemplates.length,
          bundleName: name || undefined,
        },
      ),
      actions: {
        primaryAction: {
          label: this.$translateService.instant(_('program_templates.add_to_bundle.dialog.add.label')),
          value: 'add',
        },
        secondaryAction: {
          label: this.$translateService.instant(_('program_templates.add_to_bundle.dialog.view.label')),
          value: 'view',
        },
      },
    };

    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data,
    });

    const result: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (isNil(result)) {
      return;
    }

    if (result.action) {
      if (result.action === 'add') {
        // Await the addition, then navigate to the program template bundle.
        //
        const programTemplateResults = this.addProgramTemplatesToBundle(selectedTemplates, programTemplateBundle);
        const succeeded = await this.handleAddProgramTemplatesToBundleFailure(programTemplateResults, selectedTemplates);
        if (succeeded) {
          this.openProgramTemplateBundle(programTemplateBundleId);
        }
      } else if (result.action === 'view') {
        this.openProgramTemplateBundle(programTemplateBundleId);
      } else {
        console.error(`[PROGRAM-TEMPLATE]: Unknown action '${result.action}'`);
      }
    }
  }

  /**
   * Adds the given program templates to the program template bundle.
   * @param selectedTemplates The program templates to be added.
   * @param programTemplateBundle The the program template bundle that the program templates will be added to.
   */
  private addProgramTemplatesToBundle(selectedTemplates: IProgramTemplate[], programTemplateBundle: IProgramTemplateBundle) {
    const { id: programTemplateBundleId } = programTemplateBundle;

    // Gather promises for eventual failure feedback.
    //
    const addProgramTemplatePromises: Promise<IProgramTemplateProgramTemplateBundle>[] = [];

    for (const programTemplate of selectedTemplates) {
      // Add the program template to the bundle.
      //
      const programTemplatePromise = this.programTemplateBundleService.addProgramTemplate(programTemplateBundleId, programTemplate.id);
      addProgramTemplatePromises.push(programTemplatePromise);
    }

    // Provide a single promise for all results.
    //
    const result = Promise.all(addProgramTemplatePromises);

    return result;
  }

  /**
   * In case any of the added program templates fail to be added, give failure feedback.
   *
   * @private
   * @param programTemplateResults The promise containing all the results from the API calls.
   * @param selectedTemplates The templates that were supposed to be added.
   * @returns { boolean } Whether the calls succeeded.
   */
  private async handleAddProgramTemplatesToBundleFailure(
    programTemplateResults: Promise<IProgramTemplateProgramTemplateBundle[]>, selectedTemplates: IProgramTemplate[],
  ) {
    const results = await programTemplateResults;

    const failures = [];

    results.forEach((result, index) => {
      const template = selectedTemplates[index];

      if (!isNil(result)) {
        this.$programTemplateSelection.toggleProgramTemplateSelected(template, false);
        return;
      }

      failures.push(template);
    });

    if (failures.length === 0) {
      return true;
    }

    const message = failures.length === selectedTemplates.length
      ? _('program_templates.add_to_bundle.all_failed')
      : _('program_templates.add_to_bundle.some_failed');

    this.$i18nToastProvider.error(message);

    return false;
  }

  private async openProgramTemplateBundle(programTemplateBundleId: number) {
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);

    const queryParams: any = {};

    if (this.baseOnly$.value) {
      queryParams.baseOnly = this.baseOnly$.value;

      if (this.showRevokedTemplates.value) {
        queryParams.isRevoked = this.showRevokedTemplates.value;
      }
    }

    this.router.navigate([`organization/${organizationUnitId}/bundle/${programTemplateBundleId}`], {
      queryParams,
    });

    this._resetBundleSelection();
  }

  async createProgramTemplateBundle() {
    const dialogRef = this.dialog.open(CreateProgramTemplateBundleDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        selectedActivityType: this.typeOko.value,
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (isNil(result)) {
      return;
    }

    const {
      media_uuid: mediaUuid,
      name,
      type_oko: typeOko,
      program_category_id: programCategoryId,
    } = result;

    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    const countryCode = await firstValueFrom(this.$session.ouCountryCode$);

    // Create a new program template.
    //
    const programTemplateBundle = await this.programTemplateBundleService.create({
      name,
      media_uuid: mediaUuid,
      activity_type_id: typeOko,
      program_category_id: programCategoryId,
      country_code: countryCode,
      language: this.currentSearchParams?.language,
    }, organizationUnitId);

    if (isNil(programTemplateBundle)) {
      this.$i18nToastProvider.error(_('program_templates.template_bundle.create.failed'), { templateName: name || undefined });

      return;
    }

    // Add the selected templates to the program template bundle.
    //
    const selectedTemplates = await firstValueFrom(this.$programTemplateSelection.programTemplates$);
    const programTemplateResults = this.addProgramTemplatesToBundle(selectedTemplates, programTemplateBundle);
    const succeeded = await this.handleAddProgramTemplatesToBundleFailure(programTemplateResults, selectedTemplates);

    if (succeeded) {
      this.openProgramTemplateBundle(programTemplateBundle.id);
    }
  }

  goToReOrderCategory(category: ICategorizedTemplateSet) {
    this.router.navigate([`organization/${this.selectedOUId}/template/category/${category.id}/reorder/${this.typeOko.value}`]);
  }
}
