import { INewsItemDetailsListResponse } from 'typings/api-customer.d';
import { NewsItemStore } from './news-item.store';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IServiceResponse } from 'typings/api-generic';
import { INewsItem, INewsItemDetails } from 'typings/doenkids/doenkids';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsItemService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: NewsItemStore) {}

  @transaction()
  async create(organizationUnitId: number, newsItem: INewsItem) {
    const response = (await firstValueFrom(this.$baseApi
      .genericPostCall(
        '/customer/news-item',
        {
          ...newsItem,
          organization_unit_id: organizationUnitId,
        },
        undefined,
        { organizationUnitId },
      ))) as INewsItem;
    this.store.update(response);

    return response;
  }

  @transaction()
  async update(newsItemId: number, newsItem: INewsItem) {
    const response = (await firstValueFrom(this.$baseApi
      .genericPutCall(`/customer/news-item/${newsItemId}`, newsItem))) as INewsItem;
    this.store.update(response);

    return response;
  }

  @transaction()
  async fetch(id: number) {
    const response = (await firstValueFrom(this.$baseApi.genericGetCall(`/customer/news-item/${id}`))) as INewsItemDetails;
    this.store.update(response);
    return response;
  }

  /**
   *
   *
   * @param {number} organizationUnitId
   * @param {('ORGANIZATION' | 'ORGANIZATION_TREE')} newsType
   * @param {boolean} archived
   * @param {boolean} [showAll=false] Whether to show all news items regardless of date, requires write access to organization unit
   * @param {number} [limit]
   * @param {number} [skip]
   * @param {string} [sortField='show_from']
   * @param {string} [sortDirection='DESC']
   * @return {*}  {Promise<{ items: INewsItem[]; total: number }>}
   * @memberof NewsItemService
   */
  @transaction()
  async fetchAll(
    organizationUnitId: number,
    newsType: 'ORGANIZATION' | 'ORGANIZATION_TREE',
    archived: boolean,
    fetchAll = false,
    limit?: number,
    skip?: number,
    sortField = 'show_from',
    sortDirection = 'DESC',
  ): Promise<INewsItemDetailsListResponse> {
    try {
      const result = (await firstValueFrom(this.$baseApi
        .genericListCall('/customer/news-item', limit, skip, sortField, sortDirection, {
          organizationUnitId,
          newsType,
          archived,
          fetchAll,
        }))) as INewsItemDetailsListResponse;

      if (result?.items) {
        return result;
      }
      return { items: [], total: 0, limit, skip: 0, readItems: [] };
    } catch (e) {
      return { items: [], total: 0, limit, skip: 0, readItems: [] };
    }
  }

  @transaction()
  async setRead(id: number): Promise<IServiceResponse> {
    try {
      const result = (await firstValueFrom(this.$baseApi.genericPutCall(`/customer/news-item/read/${id}`))) as IServiceResponse;

      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  @transaction()
  async unRead(id: number): Promise<IServiceResponse> {
    try {
      const result = (await this.$baseApi.genericDeleteCall(`/customer/news-item/read/${id}`).toPromise()) as IServiceResponse;

      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  @transaction()
  async fetchRead(id: number): Promise<IServiceResponse> {
    try {
      const result = (await firstValueFrom(this.$baseApi.genericGetCall(`/customer/news-item/read/${id}`))) as IServiceResponse;

      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  @transaction()
  async fetchUnreadTotal(organizationUnitId: number) {
    try {
      const response = (await firstValueFrom(this.$baseApi.genericGetCall(`/customer/news-item/total/unread/${organizationUnitId}`))) as { count: number };

      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  @transaction()
  async archive(id: number): Promise<IServiceResponse> {
    const result = (await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/news-item/${id}`))) as IServiceResponse;
    // this.store.destroy();
    return result;
  }
}
