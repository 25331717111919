<app-doenkids-menu-bar [loading]="listLoading$ | async">
  <div left>
    <app-user-search [query]="searchQuery" (searched)="doSearch($event)"></app-user-search>
  </div>
</app-doenkids-menu-bar>
<mat-toolbar class="doenkids-toolbar">
  <h2 *ngIf="!(searchInAllUsers$ | async)">
    {{ 'user_list.heading.organization_users' | translate:{ organizationUnitName: currentOUName$ | async } }}
  </h2>
  <h2 *ngIf="(searchInAllUsers$ | async)">{{ 'user_list.heading.all_users' | translate }}</h2>
  <span class="spacer"></span>
  <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-around center">
    <mat-slide-toggle color="primary" (change)="toggleInactiveUsers($event)" [checked]="includeInActiveUsers$ | async">
      {{ 'user_list.active_users_only' | translate }}
    </mat-slide-toggle>
    <mat-slide-toggle color="primary" (change)="toggleSearchAllUsers($event)" [checked]="searchInAllUsers$ | async">
      {{ 'user_list.search_in_organizations' | translate }}
    </mat-slide-toggle>
    <button
      *ngIf="(isAdmin$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
      mat-button
      (click)="downloadUserList()"
      [matTooltip]="'user_list.download.tooltip' | translate"
    >
      <mat-icon svgIcon="excel"></mat-icon>
    </button>
    <button
      *ngIf="(isAdmin$ | async)"
      mat-button
      (click)="deactivateAllUsersOfOrganization()"
      [matTooltip]="'user_list.deactivate-users.tooltip' | translate: { organizationName: currentOUName$ | async }"
      class="deactivate-users-button"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</mat-toolbar>

<app-sortable-list
  class="not-selectable"
  (reload)="getList($event)"
  [fields]="fields$ | async"
  [list$]="list$ | async"
  [metadata]="metaData | async"
  [defaultLimit]="15"
>
  <ng-container *appSortableListFields="let list">
    <tr (click)="goToUserDetails(user)"
      *ngFor="let user of usersList | paginate: ( paginationConfig$ | async)">
      <td>
        <div class="cell-wrapper">{{user.email}}</div>
      </td>
      <td *ngIf="(isAdmin$ | async)">
        <div class="cell-wrapper">
          {{ user.auth0_status === 'UNKNOWN' ? ('generic.unknown' | translate) : ''}}
          {{ user.auth0_status === 'EMAIL_MISMATCH'
            ? ('user_list.auth0_email.label' | translate) + user.auth0_email
            : '' }}
          {{ user.auth0_status === 'OK' ? ('generic.ok' | translate) : ''}}
        </div>
      </td>
      <td *ngIf="(isAdmin$ | async)">
        <div class="cell-wrapper">{{ user.active ? ('generic.yes' | translate) : ('generic.no' | translate) }}</div>
      </td>
      <td *ngIf="(isAdmin$ | async)">
        <div class="cell-wrapper">{{user.user_role}}</div>
      </td>
      <td [fxHide.lt-sm]="true">
        <div class="cell-wrapper">{{ user.last_seen | i18n_date: 'dd-MM-yyyy HH:mm' | async }}</div>
      </td>
      <td [fxHide.lt-md]="true">
        <div class="cell-wrapper">{{ user.auth0_login_count }}</div>
      </td>
      <td [fxHide.lt-md]="true">
        <div class="cell-wrapper">{{ user.created_at | i18n_date: 'dd-MM-yyyy HH:mm' | async }}</div>
      </td>
      <td [fxHide.lt-md]="true">
        <div class="cell-wrapper">{{user.organization_unit }}</div>
      </td>
      <td [fxHide.lt-md]="true">
        <div class="cell-wrapper">{{ getUserLinkedOUString(user) }}</div>
      </td>
      <td class="action-col">
        <button
          *ngIf="!(isAdmin$ | async) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
          mat-button
          [matTooltip]="'user_list.actions.password_reset' | translate"
          [matTooltipPosition]="'above'"
          (click)="$event.stopPropagation();sendPasswordResetEmail(user)"
        >
          <mat-icon>lock_reset</mat-icon>
        </button>
        <button
          *ngIf="isAdmin$ | async"
          mat-button
          (click)="$event.stopPropagation();"
          [matMenuTriggerFor]="menu">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="sendPasswordResetEmail(user)"><mat-icon>lock_reset</mat-icon> {{ 'user_list.actions.password_reset' | translate }}</button>
          <button mat-menu-item (click)="sendVerificationEmail(user)"><mat-icon>email</mat-icon> {{ 'user_list.actions.verification_email' | translate }}</button>
          <button mat-menu-item (click)="blockUser(user)"><mat-icon>lock_person</mat-icon> {{ 'user_list.actions.block_user' | translate }}</button>
          <button mat-menu-item (click)="unblockUser(user)"><mat-icon>lock_open</mat-icon> {{ 'user_list.actions.unblock_user' | translate }}</button>
        </mat-menu>
      </td>
    </tr>
    <tr *ngIf="usersList.length === 0">
      <td [attr.colspan]="(fields$ | async).length">
        <div class="cell-wrapper">{{ 'user_list.no_results' | translate }}</div>
      </td>
    </tr>
  </ng-container>
</app-sortable-list>
<doenkids-speed-dial
  *ngIf="(isAdmin$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
  class="speeddial-container"
  toggleIcon="add"
  [fabButtons]="floatingActionButtons$ | async"
  (fabClick)="speedDialClick($event)"
></doenkids-speed-dial>
