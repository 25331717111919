<div class="symbol-group" fxLayout="column" fxLayoutGap="20px" [formGroup]="symbolsFormGroup">
  <ng-container *ngFor="let activityType of (activityTypes$ | async)">
    <ng-container *ngIf="getSymbolsGroupForId(activityType.id) as section">
      <div mat-subheader>
        {{ activityType.id === 0 ? (activityType.name | translate) : activityType.name }}
      </div>
      <mat-list cdkDropList [cdkDropListData]="section" (cdkDropListDropped)="dropSymbol($event, activityType.id.toString())" class="symbol-list">
        <ng-container *ngFor="let controlForm of section.controls | keyvalue: orderSectionControls">
          <form class="symbol-form" [formGroup]="controlForm.value">
            <div class="symbol-list-item" cdkDrag>
              <ng-container [ngTemplateOutlet]="symbolDetail" [ngTemplateOutletContext]="{ controlForm }"></ng-container>
              <button (click)="toggleEditSymbol(controlForm.value?.get('id')?.value)" mat-icon-button color="secondary" type="button">
                <mat-icon *ngIf="editSymbolId !== controlForm.value?.get('id')?.value" class="mat-24">edit</mat-icon>
                <mat-icon *ngIf="editSymbolId === controlForm.value?.get('id')?.value" class="mat-24">close</mat-icon>
              </button>
              <div *cdkDragPreview>
                <ng-container [ngTemplateOutlet]="symbolDetail" [ngTemplateOutletContext]="{ controlForm }"></ng-container>
              </div>
            </div>
            <ng-container *ngIf="editSymbolId === controlForm.value?.get('id')?.value" [ngTemplateOutlet]="editSymbol" [ngTemplateOutletContext]="{ controlForm }"></ng-container>
          </form>
        </ng-container>
      </mat-list>
    </ng-container>
  </ng-container>
</div>
<button mat-fab class="doenkids-fab" color="primary" (click)="addSymbol()">
  <mat-icon [attr.aria-label]="'customer_symbols.add.label' | translate">add</mat-icon>
</button>

<ng-template #symbolDetail let-controlForm="controlForm" let-sectionKey="sectionKey">
  <div class="symbol-details">
    <app-doenkids-image class="image draggable" [maxWidth]="42" [maxHeight]="42" [mediaItem]="controlForm.value?.get('media_uuid')?.value"></app-doenkids-image>
    <div fxLayout="row">
      {{ controlForm.value?.get('name')?.value }}
      <div class="symbol-conditions-inline">
        <span *ngFor="let id of controlForm.value?.get('condition')?.value?.activity_kind">{{getListItemDescription(id, conditions?.activity_kind)}}</span>
        <span *ngFor="let id of controlForm.value?.get('condition')?.value?.age_group">{{getListItemDescription(id, conditions?.age_group)}}</span>
        <span *ngFor="let id of controlForm.value?.get('condition')?.value?.area_of_development">{{getListItemDescription(id, conditions?.area_of_development)}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editSymbol let-controlForm="controlForm">
  <div fxLayout="row" [formGroup]="controlForm.value">
    <app-asset-upload class="image" [identifier]="'symbol-image-'+ controlForm.value?.get('id')?.value" purpose="symbol" (uploaded)="addSymbolImage($event, controlForm.value)">
        <ng-template let-uploadData="uploadData" let-fileInput="fileInput">
            <div class="image-container">
              <app-doenkids-image class="image" [maxWidth]="100" [maxHeight]="100" [mediaItem]="controlForm.value?.get('media_uuid')?.value"></app-doenkids-image>
            </div>
            <app-select-media [identifier]="'symbol-image-'+ controlForm.value?.get('id')?.value" [uploadData]="uploadData" purpose="symbol" (selected)="addSymbolImage($event, controlForm?.value)"></app-select-media>
        </ng-template>
    </app-asset-upload>
    <div fxLayout="column" class="symbol-form-group">
      <div fxLayout="row" fxLayoutGap="20px" class="side-by-side">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'customer_symbol.name.label' |translate }}</mat-label>
          <input formControlName="name" matInput>
          <mat-error *ngIf="controlForm.value?.get('name')?.invalid">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'customer_symbol.activity_type.label' | translate }}</mat-label>
          <mat-select formControlName="activity_type_id" required>
            <ng-container *ngFor="let type of activityTypes$ | async">
              <mat-option [value]="type.id">
                {{ type.id === 0 ? (type.name | translate) : type.name }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="controlForm.value?.get('activity_type_id')?.invalid">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- BEGIN ACTIVITY KIND -->
      <mat-form-field *ngIf="conditions?.activity_kind" class="symbol-condition activity-kind">
        <mat-label>{{ 'activity.labels.activity_kind' | translate }}</mat-label>
        <mat-chip-grid
          #activityKindList
          [attr.aria-label]="'activity.labels.activity_kind' | translate">
          <mat-chip-row
            *ngFor="let id of controlForm.value?.get('condition')?.value?.activity_kind"
            (removed)="removeCondition(controlForm.value?.get('condition'), 'activity_kind', id)"
            [removable]="true">
            {{getListItemDescription(id, conditions.activity_kind)}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            [placeholder]="'customer_symbols.activity_kind.add.placeholder' | translate"
            #activityKindInput
            [matAutocomplete]="activityKindAutocomplete"
            [matChipInputFor]="activityKindList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           >
        </mat-chip-grid>

        <mat-autocomplete #activityKindAutocomplete="matAutocomplete" (optionSelected)="addCondition(controlForm.value?.get('condition'), 'activity_kind', $event)">
          <mat-option *ngFor="let item of conditions.activity_kind" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!-- END ACTIVITY KIND -->
      <!-- BEGIN AGE GROUP -->
      <mat-form-field *ngIf="conditions?.age_group" class="symbol-condition age-group">
        <mat-label>{{ 'activity.labels.age_group' | translate }}</mat-label>
        <mat-chip-grid
          #ageGroupList
          [attr.aria-label]="'customer_symbol.age_group.label' | translate">
          <mat-chip-row
            *ngFor="let id of controlForm.value?.get('condition')?.value?.age_group"
            (removed)="removeCondition(controlForm.value?.get('condition'), 'age_group', id)"
            [removable]="true">
            {{getListItemDescription(id, conditions.age_group)}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            [placeholder]="'customer_symbol.age_group.add.placeholder' | translate"
            #ageGroupInput
            [matAutocomplete]="ageGroupAutocomplete"
            [matChipInputFor]="ageGroupList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            >
        </mat-chip-grid>

        <mat-autocomplete #ageGroupAutocomplete="matAutocomplete" (optionSelected)="addCondition(controlForm.value?.get('condition'), 'age_group', $event)">
          <mat-option *ngFor="let item of conditions.age_group" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!-- END ACTIVITY KIND -->
      <!-- BEGIN AREA OF DEVELOPMENT -->
      <mat-form-field *ngIf="conditions?.area_of_development" class="symbol-condition area-of-development">
        <mat-label>{{ 'activity.labels.area_of_development' | translate }}</mat-label>
        <mat-chip-grid
          #areaOfDevelopmentList
          [attr.aria-label]="'customer_symbol.area_of_development.label' | translate">
          <mat-chip-row
            *ngFor="let id of controlForm.value?.get('condition')?.value?.area_of_development"
            (removed)="removeCondition(controlForm.value?.get('condition'), 'area_of_development', id)"
            [removable]="true"
          >
            {{getListItemDescription(id, conditions.area_of_development)}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            [placeholder]="'customer_symbol.area_of_development.add.placeholder' | translate"
            #areaOfDevelopmentInput
            [matAutocomplete]="areaOfDevelopmentAutocomplete"
            [matChipInputFor]="areaOfDevelopmentList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        </mat-chip-grid>

        <mat-autocomplete #areaOfDevelopmentAutocomplete="matAutocomplete" (optionSelected)="addCondition(controlForm.value?.get('condition'), 'area_of_development', $event)">
          <mat-option *ngFor="let item of conditions.area_of_development" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!-- END AREA OF DEVELOPMENT  -->
      <div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" class="action-buttons" fxLayoutAlign="end center"  fxLayoutGap="16px">
          <button color="secondary" mat-button type="button" (click)="removeSymbol(controlForm.value?.get('id')?.value)">
            {{ 'generic.remove' | translate | uppercase }}
          </button>
          <button color="primary" mat-button type="button" (click)="saveSymbol(controlForm.value)">
            {{ 'generic.save' | translate | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

