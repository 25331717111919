import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isNil } from 'lodash';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ProgramTemplateBundleService } from 'src/api/activity/program-template-bundle/program-template-bundle.service';
import { TranslateService } from 'src/app/utils/translate.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { IProgramTemplate, IProgramTemplateBundle } from 'typings/doenkids/doenkids';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Router } from '@angular/router';
import { DoenkidsSessionProvider } from './session.provider';

export interface ITemplateSwitchForBundle {
  template: IProgramTemplate,
  bundle: IProgramTemplateBundle,
}

@Injectable({
  providedIn: 'root',
})
export class ProgramTemplateSelectionProvider {
  private _templateToSwitchFromBundle$ = new BehaviorSubject<ITemplateSwitchForBundle>(null);

  private _added$ = new BehaviorSubject<IProgramTemplate>(null);

  public get added$() {
    return this._added$.asObservable();
  }

  private _active = false;

  public get active(): boolean {
    return this._active;
  }

  private _programTemplates: IProgramTemplate[] = [];

  private set programTemplates(programTemplates: IProgramTemplate[]) {
    this._programTemplates = programTemplates;
    this._programTemplates$.next(programTemplates);
  }

  private _programTemplates$ = new BehaviorSubject<IProgramTemplate[]>(this._programTemplates);

  public programTemplates$ = this._programTemplates$.asObservable();

  public templateToSwitchFromBundle$ = this._templateToSwitchFromBundle$.asObservable();

  constructor(
    private programTemplateBundleService: ProgramTemplateBundleService,
    private dialog: MatDialog,
    private $translate: TranslateService,
    private router: Router,
    private $session: DoenkidsSessionProvider,
  ) {}

  /**
   * Toggle the programTemplate selection.
   * @param active Whether the programTemplate selection should be active or not.
   */
  public toggleActive(active = !this._active) {
    this._active = active;
    this._programTemplates$.next(this.active ? this._programTemplates : []);
  }

  public clearList() {
    this.programTemplates = [];
  }

  /**
   * Toggles the selected status for an programTemplate.
   * @param programTemplate The programTemplate that will be toggled.
   * @param selected Guarantees that the programTemplate will be either added if true, or removed if false.
   */
  public toggleProgramTemplateSelected(programTemplate: IProgramTemplate, selected?: boolean) {
    // Find out whether the programTemplate is actually in the list.
    //
    const index = this._programTemplates.find((storedProgramTemplate) => storedProgramTemplate.id === programTemplate.id);
    if (index !== undefined || selected === false) {
      // If the programTemplate is in the list, remove it.
      //
      this.programTemplates = this._programTemplates.filter((storedProgramTemplate) => storedProgramTemplate.id !== programTemplate.id);
    } else if (index === undefined) {
      // If the programTemplate is not present, and "selected" is not false, add the programTemplate.
      //
      this._programTemplates.push(programTemplate);
      this._programTemplates$.next(this._programTemplates);
      this._added$.next(programTemplate);
    }
  }

  public setTemplateToSwitch(template: IProgramTemplate, bundle: IProgramTemplateBundle) {
    if (!template || !bundle) {
      return;
    }
    this._templateToSwitchFromBundle$.next({
      template, bundle,
    });
  }

  public hasTemplateToSwitch() {
    return !isNil(this._templateToSwitchFromBundle$.value);
  }

  public async switchTemplate(replacementTemplate: IProgramTemplate) {
    if (!this._templateToSwitchFromBundle$.value || !replacementTemplate) {
      return;
    }

    const { template, bundle } = this._templateToSwitchFromBundle$.value;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translate.instant(_('program_template_bundle.swap_template.title'), {
          templateName: template.name,
          replacementTemplateName: replacementTemplate.name,
        }),
        description: this.$translate.instant(
          _('program_template_bundle.swap_template.description'),
          {
            templateName: template.name,
            replacementTemplateName: replacementTemplate.name,
          },
        ),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      try {
        await this.programTemplateBundleService.removeProgramTemplate(bundle.id, template.id);
      } catch (e) {
        throw e;
      }

      try {
        await this.programTemplateBundleService.addProgramTemplate(bundle.id, replacementTemplate.id);
      } catch (e) {
        // since we already removed the old template we are gonna put it back
        //
        await this.programTemplateBundleService.addProgramTemplate(bundle.id, template.id);
        throw e;
      }

      const ou = await firstValueFrom(this.$session.getOrganizationUnit$);

      this._templateToSwitchFromBundle$.next(null);
      this.router.navigate([`/organization/${ou.id}/bundle/${bundle.id}`]);
    }
  }
}
