import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IActivityStatus } from 'typings/doenkids/doenkids';
import { IActivityStatusState, ActivityStatusStore } from './activity-status.store';

@Injectable({ providedIn: 'root' })
export class ActivityStatusQuery extends QueryEntity<IActivityStatusState, IActivityStatus> {
  constructor(protected store: ActivityStatusStore) {
    super(store);
  }
}
