import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IAuthenticatedUser } from 'typings/api-customer';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class CurrentUserStore extends Store<IAuthenticatedUser> {
  constructor() {
    super({});
  }
}
