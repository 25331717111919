<app-doenkids-menu-bar [showBackButton]="true"></app-doenkids-menu-bar>
<mat-toolbar class="doenkids-toolbar">
  <h2>{{ 'active_users.title' | translate: {userCount: (metaData$ | async)?.total} }}</h2>
  <span class="spacer"></span>
  <span>{{ 'active_users.last_update' | translate: {lastUpdated: lastUpdated | from_now | async} }}</span>
</mat-toolbar>
<div class="page-content">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'generic.search' | translate }}</mat-label>
    <input matInput [formControl]="searchControl" />
  </mat-form-field>
  <app-sortable-list class="not-selectable" (reload)="getList($event)" [fields]="fields" [list$]="(list$  | async)"
    [metadata]="metaData$ | async" sortField="last_seen" [defaultLimit]="50">
    <ng-container *appSortableListFields="let list">
      <tr
        *ngFor="let event of eventList | paginate: ( paginationConfig$ | async)">
        <td>
          <div class="cell-wrapper"><a [routerLink]="['/organization/' + event.user_organization_unit_id + '/users/' + event.id + '/activity']">{{event.email}}</a></div>
        </td>
        <td [fxHide.lt-md]="true">
          <div class="cell-wrapper">{{ event.last_seen | from_now | async }}</div>
        </td>
        <td [fxHide.lt-md]="true">
          <div class="cell-wrapper">{{ event.auth0_login_count }}</div>
        </td>
        <td [fxHide.lt-md]="true">
          <div class="cell-wrapper"><a [routerLink]="['/organization/' + event.user_organization_unit_id + '/overview']">{{event.organization_unit_name }}</a></div>
        </td>
        <td>
          <div class="cell-wrapper">
            {{ 'active_users.user_event' | translate: {
              name: event.event_name,
              code: event.event_status,
              role: event.user_role,
              organization: event.event_organization_unit
            } }}
          </div>
        </td>
      </tr>
      <tr *ngIf="eventList.length === 0">
        <td [attr.colspan]="fields.length">
          <div class="cell-wrapper">{{ 'active_users.no_users_found' | translate }}</div>
        </td>
      </tr>
    </ng-container>
  </app-sortable-list>
</div>
