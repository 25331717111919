<app-doenkids-menu-bar [showBackButton]="true" [loading]="loading$ | async">
  <div fxLayout="row" fxLayoutAlign="start center" left>
    <button
      *ngIf="(hasWriteAccess$ | async)"
      [disabled]="!isTheOwner"
      mat-icon-button
      type="button"
      (click)="save()"
      [matTooltip]="'program.template_preview.save.tooltip' | translate"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      *ngIf="!(baseOnly$ | async) && !(isReader$ | async)"
      mat-icon-button
      type="button"
      (click)="editProgram()"
      [matTooltip]="'program.template_preview.edit.tooltip' | translate"
      [disabled]="!(hasWriteAccess$ | async) || !isTheOwner"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <app-owner-chip
      *ngIf="programTemplateOwner$ | async as owner"
      [(isTheOwner)]="isTheOwner"
      (isTheOwnerChange)="handleIsTheOwnerChange($event)"
      [owningOuId]="owner.organization_unit_id"
    ></app-owner-chip>
    <button
      *ngIf="!(isReader$ | async) && (isLocation$ | async) && !(baseOnly$ | async)"
      mat-icon-button
      type="button"
      (click)="createProgram()"
      [matTooltip]="'program.template_preview.create.tooltip' | translate"
    >
      <mat-icon>date_range</mat-icon>
    </button>
    <button mat-icon-button type="button" (click)="downloadPDF()" matTooltip="Download PDF" [disabled]="!(programTemplate$ | async)?.media_uuid">
      <mat-icon>get_app</mat-icon>
    </button>
    <button mat-icon-button type="button" (click)="downloadPoster()" matTooltip="Download Poster">
      <mat-icon>photo-album</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$| async) && (baseOnly$ | async) && !(isApproved$ | async) && (canBeApproved$ | async) && !(approveIsTranslate$ | async)"
      mat-icon-button
      type="button"
      (click)="approveTemplate()"
      [matTooltip]="'program.template_preview.approve.tooltip' | translate"
    >
      <mat-icon>done_all</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$| async) && !(hasWriteAccess$ | async) && (isApproved$ | async)"
      color="primary"
      mat-icon-button
      type="button"
      (click)="removeApproval()"
      [matTooltip]="'program.template_preview.approval.revoke.tooltip' | translate"
    >
      <mat-icon>done_all</mat-icon>
    </button>
    <ng-container *ngIf="!(isReader$| async) && (canBeForked$ | async) && !(approveIsTranslate$ | async)">
      <button mat-icon-button type="button" (click)="forkTemplate()" [matTooltip]="'program.template_preview.fork.tooltip' | translate">
        <mat-icon class="icon-btn">call_split</mat-icon>
      </button>
    </ng-container>
    <button
      *ngIf="!(isReader$| async) && (approveIsTranslate$ | async)"
      mat-icon-button
      (click)="forkTemplate(true)"
      [matTooltip]="'program_template_preview.translate.tooltip' | translate"
    >
      <mat-icon>translate</mat-icon>
    </button>
    <ng-container *ngVar="possibleContentLanguages$ | async as contentLanguages">
      <mat-form-field
        *ngIf="!(baseOnly$ | async) && (hasWriteAccess$ | async) && contentLanguages?.length > 1"
        appearance="outline"
        class="compact-select content-language-select"
      >
        <mat-label>{{ 'program_template.content_language.label' | translate }}</mat-label>
        <mat-select [formControl]="contentLanguageControl">
          <mat-select-trigger *ngIf="contentLanguageControl.value">
            <mat-icon [svgIcon]="contentLanguageControl.value.toLowerCase()"></mat-icon>
            {{ 'generic.language.' + contentLanguageControl.value.toLowerCase() | translate }}
          </mat-select-trigger>
          <mat-option *ngFor="let contentLanguage of contentLanguages" [value]="contentLanguage">
            <mat-icon [svgIcon]="contentLanguage.toLowerCase()"></mat-icon>
            {{ 'generic.language.' + contentLanguage.toLowerCase() | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div right>
    <app-program-template-status
      *ngIf="!(baseOnly$ | async) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
      [hasWriteAccess]="(hasWriteAccess$ | async) && isTheOwner"
      [currentStatus]="(programTemplate$ | async)?.program_template_status_id"
      (change)="changePublicationStatus($event)"
    ></app-program-template-status>
    <button
      *ngIf="
        !(isRevoked$ | async) &&
        ((isAdmin$ | async) || ((!(isRootNode$ | async) || (baseOnly$ | async)) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)))
      "
      mat-icon-button
      type="button"
      (click)="excludeProgramTemplate()"
      [matTooltip]="'program.template_preview.exclude.tooltip' | translate"
    >
      <mat-icon>remove_circle</mat-icon>
    </button>
    <button
      *ngIf="hasWriteAccess$ | async"
      mat-icon-button
      type="button"
      (click)="removeProgramTemplate()"
      [disabled]="!isTheOwner"
      [matTooltip]="'program.template_preview.delete.tooltip' | translate"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</app-doenkids-menu-bar>

<app-split-page
  #splitPage
  [leftLabel]="'activity_search.tab.filters' | translate"
  [rightLabel]="'activity_search.tab.results' | translate"
  [activeTab]="0"
  (selectedTabChange)="tabChange($event)"
>
  <ng-template #left>
    <div *ngIf="programTemplate$ | async as programTemplate" fxLayout="column" class="details" [formGroup]="programTemplateForm">
      <div class="main-details" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="flex-start flex-start" fxLayoutGap="16px">
        <app-asset-upload
          *ngIf="hasWriteAccess$ | async"
          [class.full-width]="isSmall$ | async"
          identifier="template-cover"
          purpose="program-template"
          (uploaded)="addCoverImage($event)"
        >
          <ng-template let-uploadData="uploadData">
            <div class="image-container">
              <app-doenkids-image
                [maxHeight]="500"
                [maxWidth]="500"
                [preview]="true"
                [showOriginType]="true"
                [mediaItem]="this.programTemplateForm.get('coverImage').value"
              >
                <app-select-media
                  identifier="template-cover"
                  [uploadData]="uploadData"
                  [removable]="this.programTemplateForm.get('coverImage').value"
                  (selected)="addCoverImage($event)"
                  purpose="program-template"
                >
                </app-select-media>
              </app-doenkids-image>
            </div>
          </ng-template>
        </app-asset-upload>
        <app-doenkids-image
          *ngIf="!(hasWriteAccess$ | async)"
          [maxHeight]="500"
          [maxWidth]="500"
          [mediaItem]="programTemplate.media_uuid"
          [showOriginType]="true"
        ></app-doenkids-image>
        <div class="template-details">
          <div *ngIf="!(hasWriteAccess$ | async)" fxLayout="column">
            <ng-template [ngTemplateOutlet]="okoTypeDisplay"></ng-template>
            <h1 class="title">{{ programTemplate.name }}</h1>
            <h2 *ngIf="getDurationLabels(programTemplate.intended_duration) as intentedDuration">
              {{ intentedDuration | translate }}
            </h2>
            <div class="content" [innerHTML]="programTemplate.content"></div>
            <div class="download-buttons" fxLayout="row" fxLayoutGap="20px">
              <button mat-flat-button color="primary" type="button" (click)="downloadPDF()">{{ 'program_details.pdf.download' | translate }}</button>
              <button mat-flat-button color="primary" type="button" (click)="downloadPoster()">
                {{ 'program.template_preview.poster.download.label' | translate }}
              </button>
              <ng-container *ngIf="(hasProgramExplanationPermission$ | async) && (programExplanationAttachmentList$ | async)?.length > 0">
                <ng-container *ngFor="let attachment of programExplanationAttachmentList$ | async">
                  <button mat-flat-button color="primary" type="button" (click)="downloadAttachment(attachment, $event)">
                    {{
                      'program.template_preview.attachment.download.label'
                        | translate
                          : {
                              filename: attachment.display_name ? attachment.display_name : attachment.filename
                            }
                    }}
                  </button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(programAttachmentList$ | async)?.length > 0">
                <ng-container *ngFor="let attachment of programAttachmentList$ | async; let index = index">
                  <ng-container *ngIf="index < currentTemplateAttachmentLimit">
                    <button mat-flat-button color="primary" type="button" (click)="downloadAttachment(attachment, $event)">
                      {{
                        'program.template_preview.attachment.download.label'
                          | translate
                            : {
                                filename: attachment?.display_name ?? attachment?.filename ?? ''
                              }
                      }}
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(programAttachmentList$ | async)?.length > currentTemplateAttachmentLimit">
                <button mat-flat-button color="primary" type="button" (click)="loadMoreAttachments()">...</button>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="hasWriteAccess$ | async">
            <div class="form-fields">
              <mat-form-field appearance="outline" [class.full-width]="isSmall$ | async">
                <mat-label>{{ 'program.template_preview.name.label' | translate }}</mat-label>
                <input matInput formControlName="name" />
              </mat-form-field>
              <mat-form-field appearance="outline" [class.full-width]="isSmall$ | async">
                <mat-label>{{ 'program.template_preview.category.label' | translate }}</mat-label>
                <mat-select formControlName="category" [placeholder]="'program.template_preview.category.placeholder' | translate">
                  <mat-option *ngFor="let category of programCategories$ | async" [value]="category.id">{{ category.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="(okoTypes$ | async)?.length > 1; else okoTypeDisplay">
                <mat-form-field appearance="outline" [class.full-width]="isSmall$ | async">
                  <mat-label>{{ 'program.template_preview.activity_type.label' | translate }}</mat-label>
                  <mat-select formControlName="typeOko">
                    <mat-option *ngFor="let okoType of okoTypes$ | async" [value]="okoType.id">{{ okoType.name | uppercase }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="template-content">
              <span class="template-content-title">
                {{ 'program.template_preview.content.label' | translate }}
              </span>
              <div class="editing-content">
                <quill-editor
                  bounds="self"
                  theme="bubble"
                  formControlName="content"
                  appOnFocus
                  [placeholder]="'program.template_preview.content.placeholder' | translate"
                ></quill-editor>
              </div>
            </div>
            <div class="template-tags">
              <span class="template-tags-title">
                {{ 'program.template_preview.tags.label' | translate }}
              </span>
              <tag-input
                formControlName="tags"
                [onlyFromAutocomplete]="true"
                displayBy="name"
                identifyBy="id"
                [placeholder]="'program.template_preview.tags.placeholder' | translate"
                [secondaryPlaceholder]="'program.template_preview.tags.secondary_placeholder' | translate"
                [disable]="programTemplateForm.get('tags')?.disabled ?? false"
              >
                <tag-input-dropdown
                  [showDropdownIfEmpty]="true"
                  [autocompleteItems]="allTags$ | async"
                  displayBy="name"
                  identifyBy="id"
                  [keepOpen]="false"
                  [appendToBody]="false"
                >
                  <ng-template let-item="item" let-index="index">
                    <div class="tag">
                      <mat-icon *ngIf="item.organization_unit_id === (currentOUId$ | async)">push_pin</mat-icon>
                      <span>
                        {{ item.name }}
                      </span>
                    </div>
                  </ng-template>
                </tag-input-dropdown>
              </tag-input>
            </div>
          </ng-container>

          <ng-template #okoTypeDisplay>
            <div>
              <span>{{ 'program.template_preview.suitable_for.label' | translate }}</span
              >&nbsp;
              <span>{{ (programActivityType$ | async)?.name }}</span>
            </div>
          </ng-template>
        </div>
      </div>

      <div class="attachments" *ngIf="(hasProgramExplanationPermission$ | async) && (hasWriteAccess$ | async)">
        <h2 class="title">
          <span>{{ 'program.template_preview.explanations.heading' | translate }}</span>
          <button mat-button (click)="addExplanationAttachment()" *ngIf="isTheOwner">
            <mat-icon>note_add</mat-icon>
            {{ 'generic.upload' | translate | lowercase }}
          </button>
        </h2>
        <mat-divider></mat-divider>
        <div class="attachment-container">
          <div
            *ngFor="let attachment of programExplanationAttachmentList$ | async"
            class="attachment"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            (click)="editAttachment(attachment, $event)"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>attachment</mat-icon>&nbsp;&nbsp; <span>{{ attachment.display_name ? attachment.display_name : attachment.filename }}</span
              >&nbsp;
              <span> — ({{ attachment.created_at | i18n_date : 'dd/MM/yyyy' | async }})</span>
            </div>
            <button mat-icon-button type="button" (click)="removeAttachment($event, attachment)" *ngIf="isTheOwner">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="attachments" *ngIf="hasWriteAccess$ | async">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="title">
            {{ 'program.template_preview.attachments.heading' | translate }}
          </h2>
          <button
            class="attachment-upload-button"
            mat-icon-button
            type="button"
            (click)="addAttachment()"
            *ngIf="isTheOwner"
            [matTooltip]="'generic.upload' | translate"
          >
            <mat-icon>note_add</mat-icon>
          </button>
        </div>
        <mat-divider></mat-divider>
        <div class="attachment-container">
          <div
            *ngFor="let attachment of programAttachmentList$ | async"
            class="attachment"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            (click)="previewAttachment(attachment)"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>attachment</mat-icon>&nbsp;&nbsp; <span>{{ attachment.display_name ? attachment.display_name : attachment.filename }}</span
              >&nbsp;
              <span> — ({{ attachment.created_at | i18n_date : 'dd/MM/yyyy' | async }})</span>
            </div>
            <div class="attachment-buttons" fxLayoutAlign="end">
              <button
                mat-icon-button
                type="button"
                *ngIf="isTheOwner"
                (click)="downloadAttachment(attachment, $event)"
                [matTooltip]="'generic.download' | translate"
              >
                <mat-icon>download</mat-icon>
              </button>
              <button
                mat-icon-button
                type="button"
                (click)="editAttachment(attachment, $event)"
                *ngIf="isTheOwner"
                [matTooltip]="'program.template_preview.update_name.tooltip' | translate"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                type="button"
                (click)="removeAttachment($event, attachment)"
                *ngIf="isTheOwner"
                [matTooltip]="'generic.delete' | translate"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="periods" *ngFor="let period of periods$ | async; let isLast = last" fxFlexFill>
        <h2>{{ period.period_type === 'FREEFORM' ? period.name : (period.start_date | i18n_date : 'fullDate' | async | titlecase) }}</h2>
        <h3 *ngIf="period?.subtitle">{{ period.subtitle }}</h3>
        <p *ngIf="period?.summary">{{ period.summary }}</p>
        <ng-container *ngIf="getPeriodSections(period.id) as periodSections">
          <ng-container *ngFor="let periodSection of periodSections">
            <div
              class="period_section"
              *ngIf="periodSection?.data as sectionData"
              [ngSwitch]="periodSection?.type_id"
              class="period-section"
              (click)="navigateToActivity(periodSection)"
              fxFlexFill
            >
              <h4
                class="section_title"
                *ngIf="
                  !titleIsNil(sectionData.title) ||
                  (sectionData.customer_symbol && sectionData.customer_symbol.length > 0 && (hasCustomerSymbolPermission$ | async))
                "
              >
                <span *ngIf="!titleIsNil(sectionData.title)">{{ sectionData.title }}</span>
                <div
                  class="symbol-list"
                  *ngIf="sectionData.customer_symbol && sectionData.customer_symbol.length > 0 && (hasCustomerSymbolPermission$ | async)"
                >
                  <ng-container *ngFor="let symbolId of sectionData.customer_symbol">
                    <ng-container *ngIf="fetchedCustomerSymbols$ | async as symbols">
                      <app-doenkids-image
                        *ngIf="symbols[symbolId] as fetchedSymbol"
                        [maxWidth]="40"
                        [maxHeight]="40"
                        [preview]="false"
                        [mediaItem]="{ uuid: fetchedSymbol.media_uuid, description: '' }"
                      ></app-doenkids-image>
                    </ng-container>
                  </ng-container>
                </div>
              </h4>
              <div fxLayout.lt-md="column" fxLayoutGap="16px" fxFlexFill>
                <app-doenkids-image
                  *ngIf="sectionData.media_uuid"
                  class="wrapped-image"
                  [mediaItem]="sectionData.media_uuid"
                  [maxHeight]="100"
                  [maxWidth]="100"
                >
                </app-doenkids-image>
                <h5 *ngIf="sectionData.subtitle">{{ sectionData.subtitle }}</h5>

                <ng-container *ngIf="periodSection?.type_id === EProgramPeriodSectionType.ACTIVITY">
                  <h6 *ngIf="sectionData.timeslot">{{ sectionData.timeslot }}</h6>
                  <p *ngIf="sectionData.content">{{ sectionData.content }}</p>
                </ng-container>
              </div>

              <ng-container *ngSwitchCase="EProgramPeriodSectionType.ACTIVITY">
                <i *ngIf="sectionData.additional_content">{{ sectionData.additional_content }}</i>
              </ng-container>

              <div *ngSwitchCase="EProgramPeriodSectionType.MEDIA" class="media-container">
                <ng-container *ngFor="let media of sectionData.media">
                  <ng-container *ngIf="media?.uuid">
                    <mat-card appearance="outlined" class="media-item" fxLayout="column">
                      <app-doenkids-image [maxHeight]="150" [maxWidth]="150" [mediaItem]="media?.uuid" class="media-image"> </app-doenkids-image>
                      <i *ngIf="media.description">{{ media.description }}</i>
                    </mat-card>
                  </ng-container>
                </ng-container>
              </div>

              <ng-container *ngSwitchCase="EProgramPeriodSectionType.ACTIVITY_WHAT">
                <div *ngIf="sectionData?.content" [innerHTML]="sectionData.content" class="ql-editor"></div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <hr *ngIf="!isLast" />
      </div>
      <br />
    </div>
  </ng-template>

  <ng-template #right>
    <div class="pdf-container">
      <div class="spinner-container" *ngIf="pdfLoading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <ng2-pdfjs-viewer
        (onDocumentLoad)="onPdfLoadedEvent()"
        [viewerId]="programTemplateId?.toString()"
        [attr.visible]="!(pdfLoading$ | async)"
        #pdfViewer
      ></ng2-pdfjs-viewer>
    </div>
  </ng-template>
</app-split-page>
