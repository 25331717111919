<mat-dialog-content>
  <h3>{{ 'api_key.dialog.title' | translate: {organization: data.organizationUnitName} }}</h3>
  <mat-spinner class="center-horizontal" *ngIf="(loading$ | async)"></mat-spinner>
  <div class="api-management">
    <ng-container *ngIf="!(loading$ | async) && !currentAPIKey">
      <p>{{ 'api_key.dialog.no_key_yet' | translate: {organization: data.organizationUnitName} }}</p>

      <button mat-button (click)="createAPIKey()">
        {{ 'api_key.dialog.key.create' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="!(loading$ | async) && currentAPIKey">
      <p>
        {{ 'api_key.dialog.current_key' | translate }}
        <span class="api-key">
          {{ currentAPIKey.api_key }}
          <mat-icon (click)="copyAPIKey()">file_copy</mat-icon>
        </span>
      </p>
      <p *ngIf="currentAPIKey.last_seen">
        {{ 'api_key.dialog.last_seen' | translate: {lastSeen: currentAPIKey.last_seen  | i18n_date | async} }}</p>
      <p *ngIf="!currentAPIKey.last_seen">
        {{ 'api_key.dialog.not_yet_used' | translate }}
      </p>
      <button mat-button (click)="removeAPIKey()">
        {{ 'api_key.dialog.delete' | translate }}
      </button>
    </ng-container>
  </div>
</mat-dialog-content>
