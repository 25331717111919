import {
  Component, ExistingProvider, forwardRef, HostListener, OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators,
} from '@angular/forms';
import { noop } from 'lodash';
import { ISmallstepsConclusion } from 'typings/section-types';

export const SECTION_SMALLSTEPS_CONCLUSION_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SmallstepsConclusionComponent),
  multi: true,
};

@Component({
  selector: 'app-smallsteps-conclusion',
  templateUrl: './smallsteps-conclusion.component.html',
  styleUrls: ['./smallsteps-conclusion.component.scss'],
  providers: [SECTION_SMALLSTEPS_CONCLUSION_VALUE_ACCESSOR],
})
export class SmallstepsConclusionComponent implements OnInit {
  public form: UntypedFormGroup;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: ISmallstepsConclusion) => void = noop;

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  constructor(
    public fb: UntypedFormBuilder,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      subtitle: [],
      content: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((formValue) => {
      this.onChangeCallback(formValue);
    });
  }

  writeValue(value: ISmallstepsConclusion) {
    if (value) {
      this.form.get('title').setValue(value.title || '');
      this.form.get('subtitle').setValue(value.subtitle || '');
      this.form.get('content').setValue(value.content || '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
