<app-doenkids-menu-bar [showBackButton]="true" [loading]="isLoading$ | async">
  <div left fxFlex fxLayoutAlign="flex-start center">
    <button
      *ngIf="(hasWriteAccess$ | async)"
      mat-icon-button
      type="button"
      (click)="save()"
      [disabled]="!isTheOwner"
      [matTooltip]="'program.template_bundle.template.save.tooltip' | translate"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      mat-icon-button
      type="button"
      (click)="downloadPoster()"
      [matTooltip]="'program.template_bundle.poster.download.tooltip' | translate"
      [disabled]="!(bundle$ | async)?.media_uuid"
    >
      <mat-icon>get_app</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$| async) && (baseOnly$ | async) && !(isApproved$ | async) && !(approveIsTranslate$ | async)"
      mat-icon-button
      type="button"
      (click)="approveBundle()"
      [matTooltip]="'program.template_bundle.approve.tooltip' | translate"
    >
      <mat-icon>done_all</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$| async) && (approveIsTranslate$ | async)"
      mat-icon-button
      (click)="forkBundle(true)"
      [matTooltip]="'program_template_bundle.translate.tooltip' | translate"
    >
      <mat-icon>translate</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$ | async) && !(hasWriteAccess$ | async) && (isApproved$ | async)"
      color="primary"
      mat-icon-button
      type="button"
      (click)="removeApproval()"
      [matTooltip]="'program.template_bundle.approval.remove.tooltip' | translate"
    >
      <mat-icon>done_all</mat-icon>
    </button>
    <app-owner-chip
      *ngIf="bundleOwner$ | async as bundleOwner"
      [(isTheOwner)]="isTheOwner"
      (isTheOwnerChange)="handleIsTheOwnerChange($event)"
      [owningOuId]="bundleOwner.organization_unit_id"
    ></app-owner-chip>
    <ng-container *ngIf="!(isReader$ | async) && (canBeForked$ | async) && !(approveIsTranslate$ | async)">
      <button mat-icon-button type="button" (click)="forkBundle()" [matTooltip]="'program.template_bundle.fork.tooltip' | translate">
        <mat-icon class="icon-btn">call_split</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngVar="possibleContentLanguages$ | async as contentLanguages">
      <mat-form-field
        *ngIf="!(baseOnly$ | async) && (hasWriteAccess$ | async) && contentLanguages?.length > 1"
        appearance="outline"
        class="compact-select content-language-select"
      >
        <mat-label>{{ 'program_template_bundle.content_language.label' | translate }}</mat-label>
        <mat-select [formControl]="contentLanguageControl">
          <mat-select-trigger *ngIf="contentLanguageControl.value">
            <mat-icon [svgIcon]="contentLanguageControl.value.toLowerCase()"></mat-icon>
            {{ 'generic.language.' + contentLanguageControl.value.toLowerCase() | translate }}
          </mat-select-trigger>
          <mat-option *ngFor="let contentLanguage of contentLanguages" [value]="contentLanguage">
            <mat-icon [svgIcon]="contentLanguage.toLowerCase()"></mat-icon>
            {{ 'generic.language.' + contentLanguage.toLowerCase() | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div right class="right-menu-bar-actions">
    <app-program-template-status
      *ngIf="!(baseOnly$ | async) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
      [hasWriteAccess]="(hasWriteAccess$ | async) && isTheOwner"
      [currentStatus]="(bundle$ | async)?.program_template_status_id"
      (change)="changePublicationStatus($event)"
    ></app-program-template-status>
    <button
      *ngIf="
        !(isRevoked$ | async) &&
        ((isAdmin$ | async) || ((!(isRootNode$ | async) || (baseOnly$ | async)) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)))
      "
      mat-icon-button
      type="button"
      (click)="excludeProgramTemplateBundle()"
      [matTooltip]="'program.template_bundle.exclude.tooltip' | translate"
    >
      <mat-icon>remove_circle</mat-icon>
    </button>
    <button mat-icon-button type="button" *ngIf="(hasWriteAccess$ | async)" [disabled]="!isTheOwner" (click)="removeBundle()" [matTooltip]="'program.template_bundle.remove.tooltip' | translate">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</app-doenkids-menu-bar>
<div class="page-container">
  <form [formGroup]="this.programBundleForm">
    <div class="main-details" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="flex-start flex-start" fxLayoutGap="16px" *ngIf="bundle$ | async as bundle">
      <ng-container *ngIf="!(hasWriteAccess$ | async)">
        <div class="bundle-details" fxLayout="column" fxLayout.gt-sm="row">
          <app-doenkids-image [maxHeight]="500" [maxWidth]="500" [mediaItem]="bundle.media_uuid" [showOriginType]="true"></app-doenkids-image>
          <div class="bundle-contents" fxLayout="column">
            <h1>{{ bundle.name }}</h1>
            <div class="content" [innerHTML]="programBundleForm.get('content').value"></div>
            <div class="download-buttons" fxLayout="row" fxLayoutGap="20px">
              <button mat-flat-button color="primary" type="button" (click)="downloadPoster()">
                {{ 'program.template_bundle.poster.download.label' | translate }}
              </button>
              <ng-container *ngIf="(hasProgramExplanationPermission$ | async) && (programTemplateBundleExplanationAttachments$ | async)?.length > 0">
                <ng-container *ngFor="let attachment of programTemplateBundleAttachments$ | async">
                  <button mat-flat-button color="primary" type="button" (click)="downloadAttachment(attachment, $event)">
                    {{
                      'program.template_bundle.attachment.download.label'
                        | translate
                          : {
                              filename: attachment.display_name ? attachment.display_name : attachment.filename
                            }
                    }}
                  </button>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(programTemplateBundleAttachments$ | async)?.length > 0">
                <ng-container *ngFor="let attachment of programTemplateBundleAttachments$ | async; let index = index">
                  <ng-container *ngIf="index < currentTemplateAttachmentLimit">
                    <button mat-flat-button color="primary" type="button" (click)="downloadAttachment(attachment, $event)">
                      {{
                        'program.template_bundle.attachment.download.label'
                          | translate
                            : {
                                filename: attachment.display_name ? attachment.display_name : attachment.filename
                              }
                      }}
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(programTemplateBundleAttachments$ | async)?.length > currentTemplateAttachmentLimit">
                <button mat-flat-button color="primary" type="button" (click)="loadMoreAttachments()">...</button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="bundle-metadata">
          <p>
            <span>{{ 'program.template_bundle.suitable_for.description' | translate }}</span>
            <span *ngFor="let activity_type of okoTypes$ | async; let isLast = last"> {{ activity_type.name }}{{ isLast ? '' : ',&nbsp;' }} </span>
          </p>
          <div *ngIf="programBundleForm.get('program_category').value">
            <span>{{ 'program.template_bundle.category.label' | translate }}</span>
            <ng-container *ngFor="let category of programCategories$ | async">
              <span *ngIf="category.id === programBundleForm.get('program_category').value">{{ category.name }}</span>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="hasWriteAccess$ | async">
        <div class="bundle-details">
          <app-asset-upload [class.full-width]="isSmall$ | async" identifier="template-cover" purpose="program-template" (uploaded)="addCoverImage($event)">
            <ng-template let-uploadData="uploadData">
              <div class="image-container">
                <app-doenkids-image
                  [maxWidth]="500"
                  [maxHeight]="500"
                  [preview]="true"
                  [mediaItem]="this.programBundleForm.get('media_uuid').value"
                  [showOriginType]="true"
                >
                  <app-select-media
                    identifier="template-cover"
                    [uploadData]="uploadData"
                    [removable]="this.programBundleForm.get('media_uuid').value"
                    (selected)="addCoverImage($event)"
                    purpose="program-template"
                  >
                  </app-select-media>
                </app-doenkids-image>
              </div>
            </ng-template>
          </app-asset-upload>
        </div>
        <div class="bundle-contents">
          <div class="form-fields">
            <mat-form-field appearance="outline" [class.full-width]="isSmall$ | async">
              <mat-label>{{ 'program.template_bundle.name.label' | translate }}</mat-label>
              <input type="text" formControlName="name" matInput />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ 'program.template_bundle.category.label' | translate }}</mat-label>
              <mat-select formControlName="program_category">
                <mat-option *ngFor="let category of programCategories$ | async" [value]="category.id">{{ category.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'program.template_bundle.suitable_for.label' | translate }}</mat-label>
                <mat-select formControlName="type_oko">
                  <mat-option *ngFor="let okoType of okoTypes$ | async" [value]="okoType.id">{{ okoType.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="template-content">
            <span class="template-content-title">{{ 'program.template_bundle.content.title' | translate }}</span>
            <div class="editing-content">
              <quill-editor
                bounds="self"
                theme="bubble"
                formControlName="content"
                appOnFocus
                [placeholder]="'program.template_bundle.content.placeholder' | translate"
              ></quill-editor>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>

  <div class="attachments" *ngIf="(hasProgramExplanationPermission$ | async) && (hasWriteAccess$ | async)">
    <h2 class="title">
      <span>{{ 'program.template_bundle.explanations.label' | translate }}</span>
      <button mat-button (click)="addExplanationAttachment()" *ngIf="isTheOwner">
        <mat-icon>note_add</mat-icon>
        {{ 'generic.upload' | translate | lowercase }}
      </button>
    </h2>
    <mat-divider></mat-divider>
    <div class="attachment-container">
      <div
        *ngFor="let attachment of programTemplateBundleExplanationAttachments$ | async"
        class="attachment"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (click)="editAttachment(attachment, $event)"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>attachment</mat-icon>&nbsp;&nbsp; <span>{{ attachment.display_name ? attachment.display_name : attachment.filename }}</span
          >&nbsp;
          <span> — ({{ attachment.created_at | i18n_date : 'dd/MM/yyyy' | async }})</span>
        </div>

        <button mat-icon-button type="button" (click)="removeAttachment($event, attachment)" *ngIf="isTheOwner">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="attachments" *ngIf="hasWriteAccess$ | async">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="title">
        {{ 'program.template_bundle.attachments.label' | translate }}
      </h2>
      <button
        class="attachment-upload-button"
        mat-icon-button
        type="button"
        (click)="addAttachment()"
        *ngIf="isTheOwner"
        [matTooltip]="'generic.upload' | translate"
      >
        <mat-icon>note_add</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <div class="attachment-container">
      <div
        *ngFor="let attachment of programTemplateBundleAttachments$ | async"
        class="attachment"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (click)="previewAttachment(attachment)"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>attachment</mat-icon>&nbsp;&nbsp; <span>{{ attachment.display_name ? attachment.display_name : attachment.filename }}</span
          >&nbsp;
          <span> — ({{ attachment.created_at | i18n_date : 'dd/MM/yyyy' | async }})</span>
        </div>
        <div class="attachment-buttons" fxLayoutAlign="end">
          <button
            mat-icon-button
            type="button"
            *ngIf="isTheOwner"
            (click)="downloadAttachment(attachment, $event)"
            [matTooltip]="'generic.download' | translate"
          >
            <mat-icon>download</mat-icon>
          </button>
          <button
            mat-icon-button
            type="button"
            *ngIf="isTheOwner"
            (click)="editAttachment(attachment, $event)"
            [matTooltip]="'program.template_bundle.preview.update_name.tooltip' | translate"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button type="button" *ngIf="isTheOwner" (click)="removeAttachment($event, attachment)" [matTooltip]="'generic.delete' | translate">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="templatesWithTags as templates; else noTemplates">
    <div class="templates-icons" *ngIf="templates.length > 1 && (hasWriteAccess$ | async) && isTheOwner">
      <button
        *ngIf="!isReOrdering$.value"
        mat-icon-button
        type="button"
        (click)="toggleReOrdering()"
        [matTooltip]="'program.template_bundle.reorder.enable.tooltip' | translate"
      >
        <mat-icon>view_list</mat-icon>
      </button>
      <button
        *ngIf="isReOrdering$.value"
        mat-icon-button
        type="button"
        (click)="toggleReOrdering()"
        [matTooltip]="'program.template_bundle.reorder.disable.tooltip' | translate"
      >
        <mat-icon>view_column</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="!isReOrdering$.value">
      <swiper-container id="templates" class="swiper-container" *ngIf="templates?.length > 0" dkSwiper [config]="config" init="false">
        <swiper-slide *ngFor="let template of templates" class="swiper-slide" (click)="openProgramTemplate(template)">
          <div class="template-image">
            <ng-container *ngIf="template.language !== (bundle$ | async)?.language">
              <div class="switch-template-actions">
                <mat-icon [svgIcon]="template.language.toLowerCase()"></mat-icon>
                <button
                  mat-icon-button
                  type="button"
                  *ngIf="isTheOwner"
                  (click)="replaceTemplate($event, template)"
                  [matTooltip]="'program_template_bundle.swap_template.tooltip' | translate"
                >
                  <mat-icon>swap_horiz</mat-icon>
                </button>
              </div>
            </ng-container>
            <app-doenkids-image [mediaItem]="template.media_uuid" [maxWidth]="200"></app-doenkids-image>
          </div>
          <div class="bundle-template-content">
            <div class="template-content-tags">
              <mat-chip-listbox hideSingleSelectionIndicator>
                <mat-chip-option *ngFor="let templateTag of template.tags">{{ templateTag.name }}</mat-chip-option>

                <button
                  mat-button
                  *ngIf="(hasWriteAccess$ | async) && isTheOwner"
                  class="tag-add-button"
                  color="primary"
                  [matTooltip]="'program.template_bundle.tags.edit.tooltip' | translate"
                  (click)="openTagDialogForProgram($event, template)"
                >
                  <mat-icon
                    [attr.aria-label]="
                      'program.template_bundle.tags.edit.label'
                        | translate
                          : {
                              templateName: template.name
                            }
                    "
                    >push_pin</mat-icon
                  >
                  <span class="tag-add-button-text">
                    {{ 'program.template_bundle.tags.edit.text' | translate }}
                    <mat-icon>arrow_right</mat-icon>
                  </span>
                </button>
              </mat-chip-listbox>
            </div>
            <span class="bundle-template-content-title"
              ><strong>{{ template.name }}</strong></span
            >
            <div [innerHTML]="template.content"></div>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>
    <ng-container *ngIf="isReOrdering$.value">
      <div cdkDropList class="template-list" (cdkDropListDropped)="droppedTemplate($event)">
        <div
          class="category-box"
          [class.draggable]="(isAdmin$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
          *ngFor="let template of templates"
          cdkDrag
          [cdkDragDisabled]="(isAdmin$ | async) ? false : !(hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
        >
          <app-doenkids-image [preview]="true" [mediaItem]="template.media_uuid" [maxWidth]="100"></app-doenkids-image>
          <span>{{ template.name }}</span>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #noTemplates>
    <h4>{{ 'program.template_bundle.no_templates' | translate }}</h4>
  </ng-template>
</div>
