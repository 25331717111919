import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IUploadResponse } from 'typings/custom-app-types';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IMediaItem } from 'typings/section-types';
import { isNil } from 'lodash';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ProgramCategoryListService } from 'src/api/activity/program-category-list/program-category-list.service';
import { ProgramCategoryListQuery } from 'src/api/activity/program-category-list/program-category-list.query';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { IProgramCategory } from 'typings/doenkids/doenkids';
import {
  BrowseOrganizationUnitDialogComponent, ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { IProgramCategoryWithOrganizationUnit } from 'typings/api-activity';
import { TranslateService } from 'src/app/utils/translate.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { EOrganizationUnitType } from '../add-organization-dialog/add-organization-dialog.component';
import { IUserPermission } from 'typings/api-customer';

export interface ICreateProgramTemplate {
  id?: number;
  created_at?: string;
  updated_at?: string;
  archived?: boolean;
  name: string;
  media_uuid: string;
  intended_duration: string;
  program_id: number;
  program_category_id?: number;
  activity_type_id?: number;
  organization_unit_id: number;
}

@Component({
  selector: 'app-create-program-template-dialog',
  templateUrl: './create-program-template-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./create-program-template-dialog.component.scss'],
})
export class CreateProgramTemplateDialogComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  public form: UntypedFormGroup;

  public isAdmin$: Observable<boolean>;

  public programCategories$: Observable<IProgramCategoryWithOrganizationUnit[]>;

  constructor(
    fb: UntypedFormBuilder,
    private $session: DoenkidsSessionProvider,
    public dialogRef: MatDialogRef<CreateProgramTemplateDialogComponent>,
    private programCategoryService: ProgramCategoryListService,
    programCategoryQuery: ProgramCategoryListQuery,
    public dialog: MatDialog,
    private $translateService: TranslateService,
    private $permissions: PermissionProvider,
  ) {
    this.form = fb.group({
      category: [''],
      coverImage: [null, Validators.required],
      duration: ['other'],
      organizationUnit: ['', Validators.required],
    });

    this.programCategories$ = programCategoryQuery.selectAll().pipe(
      filter((value) => !isNil(value)),
      takeUntil(this.stop$),
    );

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
  }

  async ngOnInit() {
    const currentOU = await firstValueFrom(this.$session.getOrganizationUnit$);
    const currentOUCanCreateTemplates = await firstValueFrom(this.$permissions.hasProgramTemplateCreatePermission$);

    if (currentOUCanCreateTemplates) {
      this.form.get('organizationUnit').setValue(currentOU);
    } else {
      const permissions = await firstValueFrom(this.$permissions.listOfUserPermissionsThatCanCreateTemplates$) ?? [];
      const isAdmin = await firstValueFrom(this.isAdmin$);
      if (isAdmin) {
        permissions.push({
          organization_unit_name: 'Doenkids',
          organization_unit_id: 1,
          organization_unit_type_id: EOrganizationUnitType.ORGANIZATION,
        } as IUserPermission);
      }
      const firstOUPermission = permissions[0];
      if (firstOUPermission) {
        this.form.get('organizationUnit').setValue({
          id: firstOUPermission.organization_unit_id,
          name: firstOUPermission.organization_unit_name,
          organization_unit_type_id: firstOUPermission.organization_unit_type_id,
        });
      } else {
        this.form.get('organizationUnit').setValue(null);
      }
    }

    // Fetch the program catgories
    //
    this.programCategoryService.fetchAll(currentOU.id, {});
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async changeOrganization() {
    const selectedOrganization = this.form.get('organizationUnit').value;

    const dialogRef = this.dialog.open(BrowseOrganizationUnitDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant('create_program_template_dialog.organization.change.dialog.title'),
        description: this.$translateService.instant('create_program_template_dialog.organization.change.dialog.description'),
        writableOnly: true,
        initialOrganizationUnitSelection: [selectedOrganization],
      },
    });

    const selection: ISelectedOrganizationUnit[] = await firstValueFrom(dialogRef.afterClosed());
    const result = selection && selection[0];

    if (result?.id) {
      this.form.get('organizationUnit').setValue(result);
    }
  }

  addCoverImage(mediaItem: IMediaItem | IUploadResponse) {
    const mediaUuid = mediaItem.uuid ? mediaItem.uuid : '';
    this.form.get('coverImage').setValue(mediaUuid || null);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm() {
    const mediaUuid = this.form.get('coverImage').value;
    const intendedDuration = this.form.get('duration').value;
    const organizationUnitId = this.form.get('organizationUnit').value?.id;
    const programCategoryId = (this.form.get('category').value as IProgramCategory)?.id;

    if (isNil(mediaUuid) || isNil(intendedDuration)) {
      console.warn('No value selected.');
      return;
    }
    this.dialogRef.close({
      media_uuid: mediaUuid,
      intended_duration: intendedDuration,
      program_category_id: programCategoryId,
      organization_unit_id: organizationUnitId,
    } as ICreateProgramTemplate);
  }
}
