import {
  Component, Inject, OnInit, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { PinbordProvider } from 'src/providers/pinbord.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IActivity, IFavoriteFolderDetails, ISearchActivity } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-add-activity-to-pinbord-folder-dialog',
  templateUrl: './add-activity-to-pinbord-folder-dialog.component.html',
  styleUrls: ['./add-activity-to-pinbord-folder-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddActivityToPinbordFolderDialogComponent implements OnInit {
  public currentFolders: IFavoriteFolderDetails[] = [];

  public selectedFolder: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<AddActivityToPinbordFolderDialogComponent>,
    private $pinbord: PinbordProvider,
    private $session: DoenkidsSessionProvider,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      activities: (ISearchActivity | IActivity)[],
    },
  ) {}

  async ngOnInit() {
    await this.fetchCurrentFolders();
    if (this.currentFolders.length === 1) {
      this.selectedFolder.setValue([this.currentFolders[0].id]);
    } else if (this.data.activities.length === 1) {
      const activityFolderIds = this.currentFolders
        .filter((currentFolder) => currentFolder.activities.find((folderActivity) => folderActivity.id === this.data.activities[0].id))
        .map((activityFolder) => activityFolder.id);
      this.selectedFolder.setValue(activityFolderIds);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  async fetchCurrentFolders() {
    this.currentFolders = (await firstValueFrom(this.$pinbord.currentFolders$)).folders;
  }

  activityIsAlreadyOnFolder(activity: ISearchActivity | IActivity, folderId: number) {
    const activityFolderIds = this.currentFolders
      .filter((currentFolder) => currentFolder.activities.find((folderActivity) => folderActivity?.id === activity.id))
      .map((activityFolder) => activityFolder.id);

    return activityFolderIds.includes(folderId);
  }

  async onAdd() {
    const folderIds = this.selectedFolder.value;

    if (!folderIds) {
      return;
    }

    for (const folderId of folderIds) {
      for (const activity of this.data.activities) {
        if (!this.activityIsAlreadyOnFolder(activity, folderId)) {
          // eslint-disable-next-line no-await-in-loop
          await this.$pinbord.addActivitiesToFolder(folderId, [activity]);
        }
      }
    }

    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
    this.$pinbord.fetchFolders(organizationUnit.id);

    this.dialogRef.close('success');
  }
}
