import { isNil } from 'lodash';
import { PermissionProvider } from 'src/providers/permission.provider';
import { NewsItemService } from 'src/api/customer/news-item/news-item.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IActivityType, INewsItem, INewsItemDetails, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { IMediaItem } from 'typings/section-types';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import {
  BehaviorSubject, Observable, Subject, firstValueFrom,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Blur } from 'ngx-quill';
import {
  takeUntil,
  map,
  debounceTime,
} from 'rxjs/operators';
import { NewsfeedProvider } from 'src/providers/newsfeed-provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { IUploadResponse } from 'typings/custom-app-types';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { ShowErrorsImmediatelyErrorMatcher } from 'src/components/shared/form-validators/error-state-matchers';
import dayjs from 'dayjs';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-organization-unit-manage-newsfeed',
  templateUrl: './organization-unit-manage-newsfeed.component.html',
  styleUrls: ['./organization-unit-manage-newsfeed.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [],
})
export class OrganizationUnitManageNewsfeedComponent implements OnInit, OnDestroy {
  MAX_ITEMS_PER_PAGE = 8;

  public today = new Date();

  public newsItemForm: UntypedFormGroup;

  public editingItem$ = new BehaviorSubject<INewsItemDetails>(null);

  public newsItems$ = new BehaviorSubject<INewsItemDetails[]>([]);

  public activityTypes$: Observable<IActivityType[]>;

  public viewingItem$ = new BehaviorSubject(false);

  public newsItemsLoading$ = new BehaviorSubject(false);

  public endReached$ = new BehaviorSubject(false);

  public archivedSelection = 'published';

  public currentPage = 1;

  public metaData$: BehaviorSubject<{ limit: number, total: number, skip: number }>;

  public contentInvalid$ = new Subject();

  public bounceInTrigger$ = new BehaviorSubject(false);

  public paginationConfig$: Observable<{ id: string, itemsPerPage: number, currentPage: number, totalItems: number }>;

  public isDoenkidsManagement$: Observable<boolean>;

  private currentOuId$: Observable<number>;

  private defaultActivityTypeIds$: Observable<number[]>;

  get showFromMin() {
    const { value } = this.editingItem$;

    // eslint-disable-next-line no-nested-ternary
    return value
      ? (new Date(value.show_from) > this.today ? this.today : this.editingItem$.value.show_from)
      : this.today;
  }

  selectedOrganizationUnit: IOrganizationUnitOverview;

  stop$ = new Subject();

  protected statusFilterOptions: Array<{ label: string, value: string }> = [
    {
      label: _('organization_unit.manage_newsfeed.published'),
      value: 'published',
    },
    {
      label: _('organization_unit.manage_newsfeed.archived'),
      value: 'archived',
    },
  ];

  public errorStateMatcher = new ShowErrorsImmediatelyErrorMatcher();

  constructor(
    public fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private $session: DoenkidsSessionProvider,
    private $newsItem: NewsItemService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionProvider: PermissionProvider,
    private newsFeedProvider: NewsfeedProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.activityTypes$ = this.$session.availableActivityTypes$.pipe(takeUntil(this.stop$));
    this.defaultActivityTypeIds$ = this.activityTypes$.pipe(map((activityTypes) => activityTypes.map((activityType) => activityType.id)));
    this.metaData$ = new BehaviorSubject({ limit: this.MAX_ITEMS_PER_PAGE, total: 0, skip: 0 });
    this.paginationConfig$ = this.metaData$.pipe(
      map((metadata) => ({
        id: 'organizationUnitManageNewsfeed',
        itemsPerPage: metadata.limit,
        currentPage: this.currentPage,
        totalItems: metadata.total,
      })),
    ).pipe(takeUntil(this.stop$));
    this.isDoenkidsManagement$ = this.permissionProvider.isDoenkidsManagement$.pipe(takeUntil(this.stop$));
    this.currentOuId$ = this.$session.currentOuId$.pipe(takeUntil(this.stop$));
  }

  async ngOnInit() {
    const okoTypesDefaultValue = await firstValueFrom(this.defaultActivityTypeIds$);
    const pendingNewsItem = await firstValueFrom(this.newsFeedProvider.pendingNewsItem$);
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
    this.selectedOrganizationUnit = organizationUnit;

    this.newsItemForm = this.fb.group({
      subject: ['', Validators.required],
      content: ['', Validators.required],
      news_type: ['ORGANIZATION_TREE', Validators.required],
      activity_type_id: [okoTypesDefaultValue, Validators.required],
      show_from: [new Date(), Validators.required],
      show_until: [null],
      media_uuid: ['', Validators.required],
      organization_unit_id: [organizationUnit.id],
    });

    if (pendingNewsItem) {
      this.newsItemForm.get('subject').setValue(pendingNewsItem?.subject ?? '');
      this.newsItemForm.get('content').setValue(pendingNewsItem?.content ?? '');
      this.newsItemForm.get('news_type').setValue(pendingNewsItem?.news_type ?? 'ORGANIZATION_TREE');
      this.newsItemForm.get('show_from').setValue(pendingNewsItem?.show_from ?? new Date());
      this.newsItemForm.get('show_until').setValue(pendingNewsItem?.show_until ?? null);
      this.newsItemForm.get('media_uuid').setValue(pendingNewsItem?.media_uuid ?? '');
      this.newsItemForm.markAsDirty();

      const showUntilControl = this.newsItemForm.get('show_until');
      if (dayjs(this.showFromMin).isAfter(this.newsItemForm.get('show_from').value)) {
        this.newsItemForm.get('show_from').setValue(this.today);
        showUntilControl.setValue(null);
      } else if (showUntilControl.value && dayjs(this.today).isAfter(showUntilControl.value)) {
        showUntilControl.setValue(null);
      }

      this.newsFeedProvider.clearPendingNewsItem();
    }

    this.bounceInTrigger$.pipe(debounceTime(800), takeUntil(this.stop$)).subscribe((value) => {
      if (value === true) {
        this.bounceInTrigger$.next(false);
      }
    });

    this.$session.getOrganizationUnitSwitch$.pipe(takeUntil(this.stop$)).subscribe(async (organization) => {
      this.selectedOrganizationUnit = organization;
      this.editingItem$.next(null);
      this.currentPage = 1;

      this.fetchNewsItems(true);
    });

    this.fetchNewsItems();
  }

  async fetchNewsItems(forceSetFirstItem: boolean = false) {
    const organizationId = await firstValueFrom(this.currentOuId$);
    this.newsItemsLoading$.next(true);

    const {
      items: newsItems,
      total,
      limit,
      skip,
    } = await this.$newsItem.fetchAll(
      organizationId,
      'ORGANIZATION',
      this.archivedSelection === 'archived',
      true,
      this.MAX_ITEMS_PER_PAGE,
      (this.currentPage - 1) * this.MAX_ITEMS_PER_PAGE,
      'show_from',
      'DESC',
    );

    this.metaData$.next({ limit, total, skip });

    if (newsItems?.length > 0) {
      this.newsItems$.next(newsItems);

      if (!this.editingItem$.value && (forceSetFirstItem || this.newsItemForm.pristine)) {
        this.editNewsItem(this.newsItems$.value[0]);
      }
    } else {
      if (this.currentPage === 1) {
        // No results
        this.newsItems$.next([]);
        this.newsItemForm.reset();
      }
      this.endReached$.next(true);
    }

    this.newsItemsLoading$.next(false);
  }

  onImageSelected(mediaItem: IMediaItem | IUploadResponse) {
    this.newsItemForm.get('media_uuid').setValue(mediaItem.uuid);
    this.newsItemForm.markAsTouched();
  }

  async editNewsItem(item: INewsItemDetails) {
    if (this.editingItem$.value?.id === item.id) {
      return;
    }

    this.editingItem$.next(item);
    this.newsItemForm.patchValue({
      subject: item.subject,
      content: item.content,
      news_type: item.news_type,
      activity_type_id: item.activity_type_id.map((v) => parseInt(`${v}`, 10)) || [],
      show_from: item.show_from,
      show_until: item.show_until || null,
      media_uuid: item.media_uuid,
      organization_unit_id: item.organization_unit_id,
    });
    this.newsItemForm.markAsUntouched();
  }

  paginate(page: number) {
    this.currentPage = page;
    this.fetchNewsItems();
  }

  isValid(controlName: string) {
    const control = this.newsItemForm.get(controlName);
    return control.valid && control.touched;
  }

  async publish() {
    if (!this.newsItemForm.valid) {
      console.warn('[NEWS-ITEM]: Form is not valid, cannot publish');
      return;
    }

    if (!['ORGANIZATION', 'ORGANIZATION_TREE', 'ALL'].includes(this.newsItemForm.value.news_type)) {
      console.warn('[NEWS-ITEM]: News type is not valid, cannot publish');
      return;
    }

    const editing = !isNil(this.editingItem$.value);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant(_('organization_unit.manage_newsfeed.publish.dialog.title'), { editing }),
        description: this.$translateService.instant(
          _('organization_unit.manage_newsfeed.publish.dialog.description'),
          {
            editing,
            news_type: this.newsItemForm.value.news_type,
            organization: this.selectedOrganizationUnit.name,
          },
        ),
      },
    });

    const result: 'confirm' | undefined = await firstValueFrom(dialogRef.afterClosed());

    if (result) {
      if (editing) {
        // post edited message
        await this.$newsItem.update(this.editingItem$.value.id, this.newsItemForm.value);
        this.editingItem$.next(null);
      } else {
        const organizationId = await firstValueFrom(this.currentOuId$);
        await this.$newsItem.create(organizationId, this.newsItemForm.value);
        // post new message
      }

      this.currentPage = 1;
      this.newsItemForm.reset();
      await this.fetchNewsItems();
      this.$i18nToastProvider.success(_('organization_unit.manage_newsfeed.item_published'));
      this.bounceInTrigger$.next(true);
    }
  }

  async clear() {
    if (this.newsItemForm?.touched) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '600px',
        minWidth: '420px',
        data: {
          title: this.$translateService.instant(_('organization_unit.manage_newsfeed.clear.dialog.title')),
          description: this.$translateService.instant(_('organization_unit.manage_newsfeed.clear.dialog.description')),
        },
      });

      const result: 'confirm' | undefined = await firstValueFrom(dialogRef.afterClosed());

      if (result) {
        this.newItem();
      }
    } else {
      this.newItem();
    }
  }

  async deleteOrRecover() {
    const isArchived = this.archivedSelection === 'archived';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant(
          isArchived
            ? _('organization_unit.manage_newsfeed.recover.dialog.title')
            : _('organization_unit.manage_newsfeed.delete.dialog.title'),
        ),
        description: this.$translateService.instant(
          isArchived
            ? _('organization_unit.manage_newsfeed.recover.dialog.description')
            : _('organization_unit.manage_newsfeed.delete.dialog.description'),
        ),
      },
    });

    const result: 'confirm' | undefined = await firstValueFrom(dialogRef.afterClosed());

    if (result) {
      try {
        if (isArchived) {
          const item = this.newsItems$.value.find((v) => v.id === this.editingItem$.value.id);

          if (item) {
            await this.$newsItem.update(
              this.editingItem$.value.id,
              {
                archived: false,
                subject: item.subject,
                content: item.content,
                show_from: item.show_from,
                news_type: item.news_type,
                organization_unit_id: item.organization_unit_id,
              } as INewsItem,
            );
          } else {
            return;
          }
        } else {
          await this.$newsItem.archive(this.editingItem$.value.id);
        }
        this.newItem();

        this.currentPage = 1;
        this.newsItems$.next([]);
        this.$i18nToastProvider.success(
          isArchived
            ? _('organization_unit.manage_newsfeed.recover.success')
            : _('organization_unit.manage_newsfeed.delete.success'),
        );

        await this.fetchNewsItems();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async newItem() {
    this.newsItemForm.reset();

    if (this.editingItem$.value) {
      this.bounceInTrigger$.next(true);
    }
    this.editingItem$.next(null);

    // Set default values
    const currentOuId = await firstValueFrom(this.currentOuId$);
    const activityTypes = await firstValueFrom(this.defaultActivityTypeIds$);

    this.newsItemForm.patchValue({
      news_type: 'ORGANIZATION_TREE',
      show_from: new Date(),
      activity_type_id: activityTypes,
      organization_unit_id: currentOuId,
    });

    this.newsItemForm.updateValueAndValidity();
  }

  viewItem(newsItemId: number) {
    this.router.navigate([`news-item/${newsItemId}`], {
      relativeTo: this.route,
    });
  }

  dateChange() {
    if (new Date(this.newsItemForm.value.show_from) > new Date(this.newsItemForm.value.show_until)) {
      this.newsItemForm.get('show_until').setValue(null);
    }
  }

  onContentBlur(event: Blur) {
    if (event.editor.getText().length) {
      this.contentInvalid$.next(false);
    } else {
      this.contentInvalid$.next(true);
    }
  }

  // We only want validators for oko types when the news type is set to ORGANIZATION_TREE
  // There is no sense in specifying activity types when showing the news item to a single organization
  //
  async onNewsTypeChange(event: MatRadioChange) {
    const activityTypeIdControl = this.newsItemForm.get('activity_type_id');
    if (event.value === 'ORGANIZATION_TREE') {
      activityTypeIdControl.setValidators([Validators.required]);
    } else {
      activityTypeIdControl.clearValidators();
      const okoTypesDefaultValue = await firstValueFrom(this.defaultActivityTypeIds$);
      activityTypeIdControl.setValue(okoTypesDefaultValue);
    }
    activityTypeIdControl.updateValueAndValidity();
  }

  onContentChange(event) {
    if (event.source === 'user') {
      this.newsItemForm.markAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }
}
