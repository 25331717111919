<app-doenkids-menu-bar [showBackButton]="true"></app-doenkids-menu-bar>
<mat-toolbar class="doenkids-toolbar">
  <h2>{{ 'activity.event_log.title' | translate }}</h2>
  <span class="spacer"></span>
  <span>{{ 'activity.event_log.last_change' | translate: {lastUpdated: lastUpdated |from_now | async} }}</span>
</mat-toolbar>

<app-sortable-list
  class="not-selectable"
  (reload)="getList($event)"
  [fields]="fields$ | async"
  [list$]="(eventList$  | async)?.items"
  [metadata]="(eventList$  | async)"
  sortField="last_seen"
  [defaultLimit]="SEARCH_LIMIT"
>
  <ng-container *appSortableListFields="let list">
    <tr *ngFor="let event of list | paginate: ( paginationConfig$ | async)">
      <td>
        <div class="cell-wrapper">{{event.user_email}}</div>
      </td>
      <td [fxHide.lt-md]="true">
        <div class="cell-wrapper">{{ event.created_at | from_now | async }}</div>
      </td>
      <td [fxHide.lt-md]="true">
        <div class="cell-wrapper"><a [routerLink]="['/organization/' + event.organization_unit_id + '/overview']">{{event.organization_unit_name }}</a></div>
      </td>
      <td>
        <div class="cell-wrapper">{{event.event_type | translate}}</div>
      </td>
      <td>
        <div class="cell-wrapper">{{event.name }}, status code: {{event.response }}</div>
      </td>
    </tr>
    <tr *ngIf="list.length === 0">
      <td [attr.colspan]="(fields$ | async).length">
        <div class="cell-wrapper">{{ 'activity.event_log.no_active_users' | translate }}</div>
      </td>
    </tr>
  </ng-container>
</app-sortable-list>
