import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IJibbieLocationListResponse } from 'typings/api-customer';
import { IJibbieLocation } from 'typings/doenkids/doenkids';
import { JibbieLocationStore } from './jibbie-location.store';
import { firstValueFrom } from 'rxjs';
import { IListRequest } from 'typings/api-activity';

@Injectable({ providedIn: 'root' })
export class JibbieLocationService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: JibbieLocationStore) {}

  @transaction()
  async fetchAll(jibbieOrganisationId: number, requestBody: IListRequest) {
    try {
      let url = '/customer/jibbie/location';
      url = this.$baseApi.appendQueryParam(url, 'jibbie_organisation_id', jibbieOrganisationId);

      const response = await firstValueFrom(this.$baseApi.genericPostCall(url, requestBody)) as IJibbieLocationListResponse;
      this.store.add(response?.items ?? []);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @transaction()
  async fetch(jibbieLocationId: number) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/jibbie/location/${jibbieLocationId}`)) as IJibbieLocation;
      this.store.upsert(response.id, response);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async setOrganizationUnit(jibbieLocationId: number, organizationUnitId: number) {
    try {
      const result = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/jibbie/location/${jibbieLocationId}/link/${organizationUnitId}`, null)) as IJibbieLocation;
      if (result) {
        this.store.upsert(result.id, result);
      }
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getOrganizationById(organizationUnitId: number) {
    try {
      const result = await firstValueFrom(this.$baseApi.genericGetCallWithoutErrorHandler(`/customer/jibbie/location/organization-unit/${organizationUnitId}`)) as IJibbieLocation;
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async unsetOrganizationUnit(jibbieLocationId: number) {
    try {
      const result = await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/jibbie/location/${jibbieLocationId}/link`)) as IJibbieLocation;
      if (result) {
        this.store.upsert(result.id, result);
      }
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
