/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnitBrowseItem } from 'typings/api-customer';

export interface IOrganizationUnitBrowseState extends EntityState<IOrganizationUnitBrowseItem> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-unit-browse' })
export class OrganizationUnitBrowseStore extends EntityStore<IOrganizationUnitBrowseState, IOrganizationUnitBrowseItem> {
  constructor() {
    super();
  }
}
