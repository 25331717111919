<div mat-dialog-title fxLayout="column" fxLayoutAlign="start">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="dialog-title">{{ title }}</h1>
    <button mat-button class="close-icon" [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <p>{{ description }}</p>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{ 'generic.filter' | translate }}</mat-label>
      <input matInput autofocus formControlName="filter">
    </mat-form-field>
  </form>
</div>
<mat-dialog-content>
  <ng-container *ngIf="(loading$ | async)">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>

  <!-- Parent item breadcrumb -->
  <div class="ou-parent">
    <span *ngIf="shouldShowShowAllOption()">
      <button mat-button (click)="showRootView()">
        <mat-icon>reply</mat-icon>
        Toon alles
      </button>
    </span>
    <span *ngFor="let parentPath of parentPath">
      <!-- &raquo; -->
      <button *ngIf="shouldShowPathEntry(parentPath)" mat-button (click)="browseParent(parentPath)">
        <mat-icon>reply</mat-icon>
        {{parentPath.name}}
      </button>
    </span>
  </div>

  <!-- OU item list -->
  <div class="ou-container" *ngFor="let node of (organizationUnitItems$ | async)" [ngClass]="{'selected': isCurrent(node)}">
    <div class="node ou-{{node.id}}" [ngClass]="{'selected': isSelected(node)}">
      <button mat-button (click)="toggleSelected(node)" [disabled]="!isSelectable(node)">
        <span class="ou-selection-button-content">
          <ng-container *ngIf="isSelectable(node)">
            <mat-icon *ngIf="isSelected(node) && data.allowMultipleSelection">check_box</mat-icon>
            <mat-icon *ngIf="!isSelected(node) && data.allowMultipleSelection">check_box_outline_blank</mat-icon>
            <mat-icon *ngIf="isSelected(node) && !data.allowMultipleSelection">check_circle</mat-icon>
            <mat-icon *ngIf="!isSelected(node) && !data.allowMultipleSelection">radio_button_unchecked</mat-icon>
          </ng-container>
          <mat-icon [svgIcon]="'ou-type-' + node.organization_unit_type_id"></mat-icon>
          <!-- Search item breadcrumb -->
          <ng-container *ngIf="node.path.length > 1 && !!currentSearchFilter">
            <span class="ou-name" *ngFor="let path of node.path.slice(1).reverse()">{{path.name}} &raquo; </span>
          </ng-container>
          <span class="ou-name" [innerHTML]="node.name | highlight: currentSearchFilter"></span>
        </span>
      </button>

    </div>
    <div class="browse" *ngIf="!currentSearchFilter">
      <button mat-button (click)="browse(node)" *ngIf="node.child_node_count">
        <span class="browse-button-content">
          <span class="ou-child-count" *ngIf="node.organization_unit_type_id !== EOrganizationUnitType.LOCATION">{{node.child_node_count}}</span>
          <mat-icon>chevron_right</mat-icon>
        </span>
      </button>
    </div>
  </div>
</mat-dialog-content>
<!-- Selected OU chips -->
<mat-dialog-actions>
  <mat-chip-listbox>
    <mat-chip-option class="selected-ou-chip" *ngFor="let node of selectedOrganizationUnits">
      <span class="selected-ou-chip-content">
        <mat-icon [svgIcon]="'ou-type-' + node.organization_unit_type_id"></mat-icon>
        <span>{{node.name}}</span>
      </span>
    </mat-chip-option>
  </mat-chip-listbox>
  <div class="button-wrapper">
    <button mat-button [disabled]="selectedOrganizationUnits.length === 0" (click)="confirm()">
      <span>{{ 'dialog.browse_organization_unit.select' | translate: {selectionCount: selectedOrganizationUnits.length} }}</span>
    </button>
  </div>
</mat-dialog-actions>

