import {
  Component, OnInit, ExistingProvider, forwardRef, HostListener,
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { IActivityTips } from 'typings/section-types';
import { noop } from 'lodash';

export const SECTION_ACTIVITY_TIPS_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ActivityTipsSectionComponent),
  multi: true,
};

@Component({
  selector: 'app-activity-tips-section',
  templateUrl: './activity-tips-section.component.html',
  styleUrls: ['./activity-tips-section.component.scss'],
  providers: [SECTION_ACTIVITY_TIPS_CONTROL_VALUE_ACCESSOR],
})
export class ActivityTipsSectionComponent implements OnInit, ControlValueAccessor {
  public form: UntypedFormGroup;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: IActivityTips) => void = noop;

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  constructor(
    public fb: UntypedFormBuilder,
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      subtitle: [],
      content: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((formValue) => {
      this.onChangeCallback(formValue);
    });
  }

  writeValue(value: IActivityTips) {
    if (value) {
      this.form.get('title').setValue(value.title || '');
      this.form.get('subtitle').setValue(value.subtitle || '');
      this.form.get('content').setValue(value.content || '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
