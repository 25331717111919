import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Location as ALocation } from '@angular/common';
import { filter } from 'rxjs/operators';
import { isNil } from '@datorama/akita';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { IOrganizationUnitActivityRoles } from 'typings/api-activity';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { firstValueFrom } from 'rxjs';

export const isActivityOwner = async (route: ActivatedRouteSnapshot) => {
  const location = inject(ALocation);
  const activityService = inject(ActivityService);
  const router = inject(Router);
  const $session = inject(DoenkidsSessionProvider);

  const organizationUnitId = await firstValueFrom($session.currentOuId$);
  const isAdmin = await firstValueFrom($session.isAdmin$.pipe(filter((value) => !isNil(value))));
  const activityId = parseInt(route.params.id, 10);

  if (!Number.isNaN(activityId)) {
    const activityRoles = await firstValueFrom(activityService.organizationUnits(activityId)) as IOrganizationUnitActivityRoles[];
    const owners = activityRoles.filter((activityRole) => activityRole.organization_unit_role_id === DoenkidsStaticValuesHelper.ACTIVITY_OWNER_ROLE);
    const isOwner = owners.map((owner) => owner.organization_unit_id).includes(organizationUnitId);

    if (!isAdmin && !isOwner && router.navigated) {
      location.back();
    } else if (!isAdmin && !isOwner) {
      router.navigate(['/']);
    }
  }

  return true;
};
