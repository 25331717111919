import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IProgramTemplateWithTags, ProgramTagsService } from 'src/api/activity/program-tags/program-tags.service';
import { TagListQuery } from 'src/api/activity/tag-list/tag-list.query';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { isEqual } from 'lodash';
import { IOrganizationUnitTagAll } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-program-template-tag-dialog',
  templateUrl: './program-template-tag-dialog.component.html',
  styleUrls: ['./program-template-tag-dialog.component.scss'],
})
export class ProgramTemplateTagDialogComponent implements OnInit {
  private stop$ = new Subject<void>();

  // The tags are loaded from the doenkids session provider on ou load
  //
  public allTags$: Observable<IOrganizationUnitTagAll[]>;

  public loading$ = new BehaviorSubject<boolean>(false);

  public templateTags: UntypedFormControl;

  public currentOUId$: Observable<number>;

  constructor(
    public dialogRef: MatDialogRef<ProgramTemplateTagDialogComponent>,
    private $session: DoenkidsSessionProvider,
    tagListQuery: TagListQuery,
    private programTagService: ProgramTagsService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, description: string, programTemplate: IProgramTemplateWithTags },
  ) {
    this.allTags$ = tagListQuery.selectAll();

    this.currentOUId$ = this.$session.currentOuId$.pipe(
      takeUntil(this.stop$),
    );
  }

  ngOnInit(): void {
    this.templateTags = new UntypedFormControl(this.data.programTemplate.tags);
  }

  async closeDialog() {
    this.loading$.next(true);
    await this.saveTags();

    this.dialogRef.close('success');
    this.loading$.next(false);
  }

  async saveTags() {
    const tags = this.templateTags.value;
    const currentTags = this.data.programTemplate.tags;
    const promises = [];

    const addedValues = [];
    const removedValues = [];

    // find the newly added values
    //
    const newlyAddedValues = tags
      .filter((currentFormValue) => !currentTags.find((currentValue) => isEqual(currentValue.id, currentFormValue.id)));
    // for each newly added value in the form to the addedValues array
    //
    newlyAddedValues.forEach((addedValue) => {
      addedValues.push(addedValue.id);
    });

    // filter out the removed values
    //
    const removedCurrentValues = currentTags
      .filter((currentValue) => !tags.find((formValue) => isEqual(formValue.id, currentValue.id)));

    // for each removed value call the add it to the removedValues array
    //
    removedCurrentValues.forEach((removedValue) => {
      removedValues.push(removedValue.id);
    });

    addedValues.forEach((addedValue: number) => {
      promises.push(this.programTagService.update(this.data.programTemplate.program_id, addedValue));
    });

    removedValues.forEach((removedValue: number) => {
      promises.push(this.programTagService.remove(this.data.programTemplate.program_id, removedValue));
    });

    await Promise.all(promises);
  }
}
