import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IProgramTemplateListResponse } from 'typings/api-activity';
import { ProgramTemplateBaseListStore } from './program-template-base-list.state';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramTemplateBaseListService {
  constructor(private store: ProgramTemplateBaseListStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll({
    organizationUnitId,
    limit = 100,
    skip = 0,
    sortField = 'created_at',
    sortDirection = 'desc',
    nodeOnly = false,
    archived = false,
    programCategoryId,
    activityTypeId,
    statusId,
    language,
  }: {
    organizationUnitId: number,
    limit: number,
    skip: number,
    sortField?: string,
    sortDirection?: SortDirection,
    nodeOnly?: boolean,
    archived?: boolean,
    programCategoryId?: number,
    activityTypeId?: number,
    statusId?: number;
    language?: string;
  }) {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      '/activity/program-template/base', limit, skip, sortField, sortDirection,
      {
        organizationUnitId,
        nodeOnly,
        archived,
        programCategoryId,
        activityTypeId,
        statusId,
        language,
      },
    )) as IProgramTemplateListResponse;
    this.store.set(response?.items ?? []);
    return response;
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
