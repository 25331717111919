import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IOpendataLrkSearchRequest } from 'typings/api-search';
import { IOpendataLrk } from 'typings/doenkids/doenkids';
import { SearchResponse } from '../../types';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { OpendataLrkSearchStore } from './opendata-lrk.store';

@Injectable({
  providedIn: 'root',
})
export class OpendataLrkSearchService {
  constructor(private store: OpendataLrkSearchStore, private $baseApi: DoenKidsGenericApiProvider) { }

  @transaction()
  fetch(request: IOpendataLrkSearchRequest, concatenate: boolean) {
    this.$baseApi.genericPostCall('/search/opendata-lrk/search', request).subscribe((response: SearchResponse<IOpendataLrk>) => {
      const hits = (response?.hits?.hits ?? [])?.map((hit) => hit._source);

      if (concatenate) {
        this.store.add(hits);
      } else {
        this.store.set(hits);
      }

      this.store.update({
        limit: request.limit,
        skip: request.skip,
        total: response?.hits?.total ?? 0,
        query: request.query,
      });

      this.store.setAggregations(response?.aggregations ?? {});
    });
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
