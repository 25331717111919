import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IProgramActivityResponse } from 'typings/api-activity';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ProgramActivitiesStore } from './program-activities.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramActivitiesService {
  constructor(private store: ProgramActivitiesStore, private $baseApi: DoenKidsGenericApiProvider) {}

  @transaction()
  async fetch(programId: number) {
    const activities = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program/${programId}/activities`)) as IProgramActivityResponse[];
    this.store.set(activities);
    return activities;
  }
}
