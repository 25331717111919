import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isNil } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class FormHelperProvider {
  public removeControls(form: UntypedFormGroup, exceptKeys?: string[]) {
    if (isNil(form)) {
      return;
    }

    // eslint-disable-next-line no-prototype-builtins
    const keys = Object.keys(form.controls).filter((key) => form.controls.hasOwnProperty(key) && (isNil(exceptKeys) || !exceptKeys.includes(key)));

    // Loop through the current formcontrols, and delete them all.
    //
    keys.forEach((controlName) => {
      form.removeControl(controlName);
    });
  }
}
