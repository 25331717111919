import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgram } from 'typings/doenkids/doenkids';

export interface IProgramWithAccesLevel extends IProgram {
  access_level: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program' })
export class ProgramStore extends Store<IProgramWithAccesLevel> {
  constructor() {
    super({});
  }
}
