import {
  Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewEncapsulation, OnInit, OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isNil, isObject } from 'lodash';
import {
  BehaviorSubject, filter, firstValueFrom, Observable, Subject, takeUntil,
} from 'rxjs';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IOrganizationUnit, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { OrganizationUnitTypeNamePipe } from 'src/pipes/organization-unit-type-name';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-owner-chip',
  templateUrl: './owner-chip.component.html',
  styleUrls: ['./owner-chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OwnerChipComponent implements OnInit, OnChanges, OnDestroy {
  private stop$ = new Subject<void>();

  @Input() owningOuId: number;

  @Input() isTheOwner;

  @Output() isTheOwnerChange = new EventEmitter<boolean>();

  public isAdmin$: Observable<boolean>;

  public owner$ = new BehaviorSubject<IOrganizationUnit>(null);

  public hasOwnerWritePermission$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  constructor(
    private $organizationUnit: OrganizationUnitService,
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
    private dialog: MatDialog,
    private $translateService: TranslateService,
    private organizationUnitTypeNamePipe: OrganizationUnitTypeNamePipe,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.isAdmin$ = this.$session.isAdmin$;

    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.asObservable();

    this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
    ).subscribe((organization) => {
      this.checkOwnership(organization);
    });

    this.owner$.pipe(
      takeUntil(this.stop$),
      filter((owner) => !isNil(owner)),
    ).subscribe(async (owner) => {
      if (owner) {
        const hasWritePermissionsOnOwner = await this.$permission.checkOUWritePermission(owner.id);
        this.hasOwnerWritePermission$.next(hasWritePermissionsOnOwner);
      }
    });
  }

  ngOnInit() {
    this.checkOwnership(this.$session.getCurrentOu());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizationUnitId && !changes.organizationUnitId.firstChange) {
      this.checkOwnership(this.$session.getCurrentOu());
    }

    if (changes.owningOuId && !changes.owningOuId.firstChange) {
      this.checkOwnership(this.$session.getCurrentOu());
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  async checkOwnership(currentOrganization: IOrganizationUnitOverview) {
    if (!this.owningOuId) { return; }

    const owner = await this.$organizationUnit.fetch(this.owningOuId);
    this.owner$.next(owner);

    const newIsOwner = (owner && currentOrganization) ? owner.id === currentOrganization.id : false;

    if (newIsOwner !== this.isTheOwner) {
      this.isTheOwner = newIsOwner;
      this.isTheOwnerChange.emit(this.isTheOwner);
    }
  }

  async switchToOwner() {
    const currentOwner = await firstValueFrom(this.owner$);
    if (!isObject(currentOwner)) {
      return;
    }

    const organizationUnitTypeName = this.$translateService.instant(
      this.organizationUnitTypeNamePipe.transform(currentOwner.organization_unit_type_id),
    );
    if (!this.hasOwnerWritePermission$.value) {
      this.$i18nToastProvider.error(
        _('owner_chip.switch.unauthorized'),
        {
          organizationUnitTypeName,
          organizationUnitName: currentOwner.name,
        },
      );

      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(
          _('owner_chip.switch.dialog.title'),
          {
            organizationUnitTypeName,
            organizationUnitName: currentOwner.name,
          },
        ),
        description: this.$translateService.instant(_('owner_chip.switch.dialog.description'), { organizationUnitTypeName }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      this.$session.selectOrganizationUnit(currentOwner.id);
    }
  }
}
