/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IActivityType } from 'typings/doenkids/doenkids';

export interface IActivityTypeState extends EntityState<IActivityType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-type' })
export class ActivityTypeStore extends EntityStore<IActivityTypeState, IActivityType> {
  constructor() {
    super();
  }
}
