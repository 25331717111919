import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DoenkidsSessionProvider } from './session.provider';
import { EventType, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SupportProvider {
  private _showSupportItems$ = new BehaviorSubject<boolean>(true);

  public showSupportItems$ = this._showSupportItems$.asObservable();

  constructor(private $session: DoenkidsSessionProvider, private router: Router) {
    this.$session.ouCountryCode$.subscribe((countryCode) => {
      this._showSupportItems$.next(countryCode.toUpperCase() === 'NL');
      this.toggleZendeskChatWidget();
    });

    this.router.events.subscribe((event) => {
      if (event.type === EventType.NavigationEnd) {
        this.toggleZendeskChatWidget();
      }
    });
  }

  private toggleZendeskChatWidget() {
    const chatIFrame = document.getElementById('launcher') as HTMLIFrameElement;

    if (this._showSupportItems$.value && chatIFrame?.style && (!chatIFrame?.style?.display || chatIFrame?.style?.display === 'none')) {
      chatIFrame.style.display = 'block';
    } else if (!this._showSupportItems$.value && chatIFrame?.style && chatIFrame?.style?.display !== 'none') {
      chatIFrame.style.display = 'none';
    }
  }
}
