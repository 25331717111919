import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ISectionType } from 'typings/doenkids/doenkids';
import { ISectionTypeState, SectionTypeStore } from './section-type.store';

@Injectable({ providedIn: 'root' })
export class SectionTypeQuery extends QueryEntity<ISectionTypeState, ISectionType> {
  constructor(protected store: SectionTypeStore) {
    super(store);
  }
}
