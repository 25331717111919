import {
  Component, OnInit, HostListener, ExistingProvider, forwardRef,
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, ControlValueAccessor, UntypedFormArray, NG_VALUE_ACCESSOR, Validators,
} from '@angular/forms';
import { noop, isNil } from 'lodash';
import { ISources } from 'typings/section-types';

export const SECTION_SOURCES_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SourcesSectionComponent),
  multi: true,
};

@Component({
  selector: 'app-sources-section',
  templateUrl: './sources-section.component.html',
  styleUrls: ['./sources-section.component.scss'],
  providers: [SECTION_SOURCES_CONTROL_VALUE_ACCESSOR],
})
export class SourcesSectionComponent implements OnInit, ControlValueAccessor {
  public form: UntypedFormGroup;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: ISources) => void = noop;

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  public get sourceFormArray(): UntypedFormArray {
    return this.form.get('source') as UntypedFormArray;
  }

  constructor(public fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      subtitle: [''],
      source: this.fb.array([this.fb.control('', Validators.required)]),
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((formValue) => {
      this.onChangeCallback(formValue);
    });
  }

  writeValue(value: ISources) {
    if (value) {
      this.form.get('title').setValue(value.title || '');
      this.form.get('subtitle').setValue(value.subtitle || '');

      const sourceFormArray = this.form.get('source') as UntypedFormArray;

      value.source = value.source ? value.source : [];

      value.source.forEach((source, index) => {
        const control = sourceFormArray.controls[index];
        if (isNil(control)) {
          sourceFormArray.push(this.fb.control(source));
        }
      });

      sourceFormArray.setValue(value.source || []);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
