<h1 mat-dialog-title class="title">{{ title }}</h1>
<h5 mat-dialog-subtitle class="subtitle"> {{ subtitle }}</h5>
<div class="checkbox-container">
  <mat-progress-spinner
    *ngIf="!initialized"
    color="primary"
    mode="indeterminate"
  ></mat-progress-spinner>
  <form [formGroup]="form" *ngIf="initialized">
    <table mat-table [dataSource]="dataSource" *ngIf="(symbols$ | async) as symbols">
      <!-- Name Column -->
      <ng-container matColumnDef="activity" sticky>
        <th class="activity-name" mat-header-cell *matHeaderCellDef>
          {{ 'activity.name' | translate }}
        </th>
        <td class="activity-name" mat-cell *matCellDef="let element">
          <span *ngIf="element?.activity">{{ element?.activity }}</span>
          <span *ngIf="!element?.activity">-</span>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container *ngFor="let symbol of symbols" [matColumnDef]="'' + symbol.id" >
        <th
          class="checkbox-cell"
          [matTooltip]="symbol.name"
          matTooltipPosition="above"
          mat-header-cell
          *matHeaderCellDef
        >
          <div class="symbol-name">{{ symbol?.name }}</div>
          <app-doenkids-image
            [mediaItem]="symbol.media_uuid"
          ></app-doenkids-image>
        </th>
        <td class="checkbox-cell" mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            [formControlName]="element['' + symbol.id].formId"
            [ngClass]="{'error-checkbox': form.controls[element['' +symbol.id].formId].hasError('tooMany')}"
          ></mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </form>
  <div class="nothing-found" *ngIf="(programActivities$ | async).length === 0">
    {{ 'program_symbols_dialog.no_activities' | translate }}
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">{{ 'generic.close' | translate }}</button>
  <button mat-button (click)="saveSymbols()" [disabled]="form.invalid" color="primary" cdkFocusInitial>
    {{ 'generic.save' | translate }}
  </button>
</div>
