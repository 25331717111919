<form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.title' | translate }}</mat-label>
      <input type="text" formControlName="title" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.subtitle' | translate }}</mat-label>
      <input type="text" formControlName="subtitle" matInput>
    </mat-form-field>
  </div>
  <div formArrayName="term" class="terms">
    <div [formGroupName]="index" class="term-container"  *ngFor="let control of termFormArray.controls; let index = index">
      <mat-form-field appearance="outline" class="section-form-field w-100">
        <mat-label>{{ 'activity.section.glossary.word.label' | translate }}</mat-label>
        <input type="text" formControlName="name" matInput>
        <button
          type="button"
          matSuffix
          mat-icon-button
          [attr.aria-label]="'generic.remove' | translate"
          (click)="termFormArray.removeAt(index);"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</form>
