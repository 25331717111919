import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IUser } from 'typings/doenkids/doenkids';
import { IServiceResponse } from 'typings/api-generic';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitUserService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) { }

  update({ userId }, user: IUser): Promise<IUser> {
    return firstValueFrom(this.$baseApi.genericPutCall(`/customer/user/${userId}`, user)) as Promise<IUser>;
  }

  addUserToOrganization(userId: number, organizationUnitId: number): Promise<IServiceResponse> {
    return firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/user/${userId}`)) as Promise<IServiceResponse>;
  }

  archive(organizationUnitId: number, userId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/organization-unit/${organizationUnitId}/user/${userId}`)) as Promise<IServiceResponse>;
  }

  deactivateUsers(organizationUnitId: number) {
    return firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/deactivate-users`)) as Promise<IServiceResponse>;
  }
}
