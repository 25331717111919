import { ProgramStatusService } from 'src/api/activity/program-status/program-status.service';
import { ProgramStatusQuery } from 'src/api/activity/program-status/program-status.query';
import { IProgramStatus } from 'typings/doenkids/doenkids';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { TranslateService } from '../app/utils/translate.service';

export type ITranslatedProgramStatus = IProgramStatus & { translatedName?: string };

@Injectable({
  providedIn: 'root',
})
export class I18nProgramStatusProvider {
  public programStatuses$: Observable<ITranslatedProgramStatus[]>;

  constructor(
    private readonly programStatusQuery: ProgramStatusQuery,
    private readonly $programStatus: ProgramStatusService,
    private readonly $translateService: TranslateService,
  ) {
    this.$programStatus.fetchAll();

    this.programStatuses$ = combineLatest([
      this.programStatusQuery.selectAll(),
      this.$translateService.onInitialTranslationAndLangOrTranslationChange$,
    ]).pipe(
      map(([statuses, langChange]) => statuses.map((status: IProgramStatus) => {
        let newStatusName = '';

        switch (status.name.toUpperCase()) {
          case 'CONCEPT':
          case 'REVIEW':
          case 'PUBLISHED':
          case 'UNPUBLISHED':
          case 'TEMPLATE':
            newStatusName = langChange.translations[`program.status.${status.name.toLowerCase()}`];
            break;
          default:
            return status;
        }

        return { ...cloneDeep(status), translatedName: newStatusName } as ITranslatedProgramStatus;
      })),
      shareReplay(),
    );
  }
}
