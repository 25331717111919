import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '../app/utils/translate.service';

@Pipe({
  name: 'from_now',
})
export class FromNowPipe implements PipeTransform {
  constructor(private $translate: TranslateService) {
  }

  /**
  * @param value Date, formatted date string or time in seconds from now
  * @param notWithin24HoursFormat Formatting to use when value is not within 24 hours from now
  */
  transform(value: string | Date | number, notWithin24HoursFormat?: string): Observable<string> {
    return this.$translate.onInitialLangAndLangChange$
      .pipe(
        map((event) => {
          if (typeof value === 'number') {
            const compareDate = new Date();
            compareDate.setSeconds(compareDate.getSeconds() + value);

            return dayjs(compareDate).locale(event.lang).fromNow();
          }

          if (notWithin24HoursFormat) {
            const now = dayjs();
            const diff = now.diff(dayjs(value), 'hours');

            if (diff > 24) {
              return dayjs(value).locale(event.lang).format(notWithin24HoursFormat);
            }
          }

          return dayjs(value).locale(event.lang).fromNow();
        }),
      );
  }
}
