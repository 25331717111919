import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserListService } from 'src/api/customer/users-list/user-list.service';
import { IUserDetails } from 'typings/api-customer';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-existing-user-dialog',
  templateUrl: './add-existing-user-dialog.component.html',
  styleUrls: ['./add-existing-user-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddExistingUserDialogComponent implements OnInit {
  private stop$: Subject<void> = new Subject<void>();

  userName: UntypedFormControl;

  filteredUsers$: BehaviorSubject<IUserDetails[]> = new BehaviorSubject<IUserDetails[]>([]);

  constructor(
    private userListService: UserListService,
    public dialogRef: MatDialogRef<AddExistingUserDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.userName = new UntypedFormControl();

    this.userName.valueChanges.pipe(takeUntil(this.stop$)).subscribe((username: string | IUserDetails) => {
      if (typeof username === 'string') {
        this.searchUser(username);
      }
    });
  }

  async searchUser(searchValue: string) {
    const foundUsers = await this.userListService.search({
      search: searchValue,
      limit: 20,
      skip: 0,
      active: true,
    }, false, true);

    this.filteredUsers$.next(foundUsers);
  }

  // Display function for object with a 'name' property
  displayUser(user?: IUserDetails) {
    return user ? user.email : undefined;
  }

  onCloseToConnect(): void {
    this.closeDialog(this.userName.value, true);
  }

  onOkClick() {
    this.closeDialog(this.userName.value);
  }

  closeDialog(user?: IUserDetails | string, chooseOu: boolean = false): void {
    if (typeof user === 'string') {
      user = this.filteredUsers$.value.find((filteredUser) => filteredUser.email === user);
    }
    this.dialogRef.close({
      user,
      chooseOu,
    });
  }
}
