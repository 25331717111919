import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ContentChild,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { SortableListFieldsDirective } from './sortable-list-fields.directive';

export interface IField {
  label: string;
  field: string;
  width: string;
  hideLessThanMedium?: boolean;
  hideLessThanSmall?: boolean;
  hide?: Observable<boolean>;
  disableSort?: boolean;
}

export interface IReloadEvent {
  limit: number;
  skip: number;
  currentPage: number;
  sortField: string;
  sortDirection: string;
  search?: string;
}

@Component({
  selector: 'app-sortable-list',
  templateUrl: './sortable-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sortable-list.component.scss'],
})
export class SortableListComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public id: string;

  @Input()
  public query: string;

  @Input()
  public fields: IField[];

  @Input()
  public list$: any[];

  @Input()
  public listLoading: Observable<boolean>;

  @Input()
  public metadata: any;

  @Input()
  public titleIcon: string;

  @Input()
  public sortField = 'created_at';

  @Input()
  public sortDirection = 'DESC';

  @Input()
  public componentName: string;

  @ContentChild(SortableListFieldsDirective, { read: TemplateRef })
  fieldItemsTemplate;

  @Output() reload: EventEmitter<IReloadEvent> = new EventEmitter();

  @Input() defaultLimit = 20;

  public defaultSkip = 0;

  public selectedPage = 1;

  public _fields = [];

  /**
   * @param {number} page The page number to switch to
   */
  public paginate(page: number) {
    const newSkip = (page * this.metadata.limit) - this.metadata.limit;

    if (this.selectedPage !== page) {
      this.selectedPage = page;

      // Dispatch a new API call
      //
      this.emitReloadEvent(this.metadata.limit, newSkip);
    }
  }

  /**
   * @param {Sort} $event Contains event field and direction information
   */
  public changeSortDirection($event: Sort) {
    const skip = 0;
    this.selectedPage = 1;
    this.sortDirection = $event.direction.toUpperCase();
    this.sortField = $event.active;

    // Dispatch a new API call
    //
    this.emitReloadEvent(this.metadata.limit, skip);
  }

  public emitReloadEvent(limit: number, skip: number) {
    this.reload.emit({
      limit,
      skip,
      sortField: this.sortField,
      sortDirection: this.sortDirection,
      currentPage: this.selectedPage,
    });
  }

  /**
   * Initialize the component and set the selector and dispatcher method
   */
  ngOnInit() {
    if (this.metadata && this.metadata.limit && this.metadata.skip) {
      this.selectedPage = (this.metadata.skip / this.metadata.limit) + 1;
      this.emitReloadEvent(this.metadata.limit, this.metadata.skip);
    } else {
      this.emitReloadEvent(this.defaultLimit, this.defaultSkip);
    }
  }
}
