import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IPeriodListResponse } from 'typings/api-activity';
import { ProgramPeriodListStore } from './program-period-list.state';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramPeriodListService {
  constructor(private store: ProgramPeriodListStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(programId: number, limit: number = 100, skip: number = 0, sortField: string = 'order', sortDirection: SortDirection = 'asc',
    doNotPerist: boolean = false) {
    const response = await firstValueFrom(this.$baseApi.genericListCall('/activity/period', limit, skip, sortField, sortDirection, { programId })) as IPeriodListResponse;

    if (!doNotPerist) {
      this.store.set(response?.items ?? []);
    }

    return response;
  }
}
