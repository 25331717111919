import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IActivityState, ActivityStore, IStoreActivity } from './activity.store';

@Injectable({ providedIn: 'root' })
export class ActivityQuery extends QueryEntity<IActivityState, IStoreActivity> {
  constructor(protected store: ActivityStore) {
    super(store);
  }
}
