<div class="upload-item" *ngFor="let file of ( uploadData?.files | async ); let i = index;">
  <div class="upload-item-content">
    <div class="filename">
      <i class="ionicon ion-ios-copy"></i>
      <span>{{ file.name }}</span>
    </div>
    <div class="progress-content">
      <div class="progress">
        <span class="bar" [style.width]="file?.progress?.data?.percentage + '%'" [class.is-done]="file?.progress?.data?.percentage === 100"></span>
      </div>
    </div>
    <div class="progress-text-content">
      <span class="progress-text" [class.is-done]="file?.progress?.data?.percentage === 100">
        <span>{{ file.progress?.data?.percentage }}% </span>
        <span *ngIf="file?.progress?.data?.percentage !== 100">{{ 'upload_progress_bar.uploading' | translate }}</span>
        <span *ngIf="file?.progress?.data?.percentage === 100">{{ 'upload_progress_bar.ready' | translate }}</span>
      </span>
      <span class="speed-and-eta-text" *ngIf="file?.progress?.data?.percentage !== 0 && file?.progress?.data?.percentage !== 100">
        <span>{{ 'generic.per_second' | translate: { amount: (file.progress?.data?.speed || 0) | bytes } }} </span>
        <span>
          {{ 'upload_progress_bar.eta' | translate: {
            eta: file.progress?.data?.eta
              ? (file.progress?.data?.eta | from_now | async)
              : ('generic.unknown' | translate)
          } }}
        </span>
      </span>
    </div>
  </div>
</div>
