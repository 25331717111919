import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IEventLogDetails } from 'typings/doenkids/doenkids';
import { UserFetchEventsStore, IUserFetchEventsState } from './user-fetch-events.store';

@Injectable({ providedIn: 'root' })
export class UserFetchEventsQuery extends QueryEntity<IUserFetchEventsState, IEventLogDetails> {
  constructor(protected store: UserFetchEventsStore) {
    super(store);
  }

  getMetadata() {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
    }));
  }
}
