<div class="image-wrapper">
  <div class="image" [class.required]="isRequired && (dedupedSelectedUri$ | async) === defaultPlaceHolder">
    <img
      #imgTag
      [class.brighten]="hover"
      [src]="dedupedSelectedUri$ | async"
      [style.max-height.px]="maxHeight"
      [style.max-width.px]="maxWidth"
      (load)="imageLoaded()"
    />
    <div class="image-cover" *ngIf="selectMedia" (mouseover)="onHover(true)" (mouseleave)="onHover(false)" [style.max-height.px]="maxHeight"
    [style.max-width.px]="maxWidth">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="origin-type" *ngIf="(isAdmin$ | async) && showOriginType">
    {{ 'media.origin_type.label' | translate }}
    <mat-select [formControl]="originTypeControl">
      <mat-option [value]="'unknown'">{{ 'media.origin_type.unknown' | translate | titlecase }}</mat-option>
      <mat-option *ngFor="let originType of (originTypeOptions$ | async)" [value]="originType?.name">
        {{ originType?.name | titlecase }}
      </mat-option>
    </mat-select>
  </div>

</div>
