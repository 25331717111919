import {
  Injectable, Injector,
} from '@angular/core';
import {
  ActivatedRouteSnapshot, ParamMap, RouterStateSnapshot, TitleStrategy,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from 'src/app/utils/translate.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { OrganizationUnitTypeNamePipe } from 'src/pipes/organization-unit-type-name';
import { IOrganizationUnitOverview } from 'typings/doenkids/doenkids';

@Injectable({ providedIn: 'root' })
export class I18nTitleStrategy extends TitleStrategy {
  private stop$ = new Subject();

  private titleParameters$ = new BehaviorSubject({});

  constructor(
    private readonly title: Title,
    private readonly injector: Injector,
  ) {
    super();
  }

  public updateTitleParameters(parameters: object): void {
    this.titleParameters$.next({ ...this.titleParameters$.value, ...parameters });
  }

  override getResolvedTitleForRoute(snapshot: ActivatedRouteSnapshot): any {
    this.titleParameters$.next(this.getFromRouteParams(snapshot.paramMap, snapshot.routeConfig?.data?.titleParameters));

    return snapshot.routeConfig?.title || undefined;
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const $translateService = this.injector.get(TranslateService);
    const $session = this.injector.get(DoenkidsSessionProvider);
    const $organizationUnitTypeNamePipe = this.injector.get(OrganizationUnitTypeNamePipe);
    const routeTitle = this.buildTitle(routerState);

    this.stop$.next(true);
    combineLatest([
      this.titleParameters$,
      $session.getOrganizationUnit$.pipe(startWith({} as IOrganizationUnitOverview)),
      $translateService.onInitialTranslationAndLangOrTranslationChange$,
    ])
      .pipe(takeUntil(this.stop$))
      .subscribe(([parameters, organizationUnit]) => {
        if (organizationUnit) {
          parameters = {
            ...parameters,
            organizationUnit: organizationUnit.name ?? '',
            organizationUnitType: organizationUnit.organization_unit_type_id ? $translateService.instant(
              $organizationUnitTypeNamePipe.transform(organizationUnit.organization_unit_type_id),
            ) : '',
          };
        }

        this.title.setTitle(
          $translateService.instant(
            _('routes.base_title'),
            {
              routeTitle: routeTitle ? $translateService.instant(routeTitle, parameters) : undefined,
            },
          ),
        );
      });
  }

  private getFromRouteParams(params: ParamMap, titleParameters: object): object {
    const titleParametersWithPlaceholders = {};
    for (const prop in titleParameters) {
      if (Object.prototype.hasOwnProperty.call(titleParameters, prop)) {
        titleParametersWithPlaceholders[prop] = params.has(titleParameters[prop]) ? params.get(titleParameters[prop]) : '';
      }
    }
    return titleParametersWithPlaceholders;
  }
}
