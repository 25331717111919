/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IMediaOriginType } from 'typings/doenkids/doenkids';

export interface IMediaOriginTypeState extends EntityState<IMediaOriginType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'media-origin-type' })
export class MediaOriginTypeStore extends EntityStore<IMediaOriginTypeState, IMediaOriginType> {
  constructor() {
    super();
  }
}
