<div class="left-side-items" *ngIf="(activityIsHighlighted$ | async) || isOnAPinbord">
  <mat-icon [matTooltip]="'activity.card.highlighted' | translate">star</mat-icon>
</div>

<div class="right-side-items">
  <div class="activity-actions" *ngIf="activityActions$ | async as activityActions">
    <button mat-icon-button *ngIf="activityActions.length > 0" (click)="openActionsMenu($event)"><mat-icon>more_vert</mat-icon></button>
    <div class="invisible-trigger" [matMenuTriggerFor]="menu"></div>
    <mat-menu #menu="matMenu" class="activity-action-menu">
      <button *ngFor="let activityAction of activityActions" (click)="executeActivityAction(activityAction)" mat-menu-item>
        <span>{{ 'activity.card.action.' + activityAction | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>

<mat-card [id]="'activity-' + activity.id" class="activity-card publication-status-{{ activity.status_id }}" @fadeInOut>
  <div class="image-container">
    <app-doenkids-image (loaded)="imageLoaded()" [mediaItem]="{ uuid: activity.media_uuid, description: '' }" [preview]="true"></app-doenkids-image>
  </div>
  <div
    class="activity-copy-indicator"
    *ngIf="(shouldShowActivityIsCopy$ | async)"
  >
    <mat-icon *ngIf="(shouldShowCountryFlag$ | async)" [svgIcon]="activity.language"></mat-icon>
    <mat-chip-set aria-label="Copied activity indicator">
      <mat-chip class="chip-copy">{{ 'generic.is_copy' | translate }}</mat-chip>
    </mat-chip-set>
  </div>
  <div class="text-container">
    <h2 class="activity-title">
      <ng-container *ngIf="(shouldShowCountryFlag$ | async) && !(shouldShowActivityIsCopy$ | async)">
        <mat-icon [svgIcon]="activity.language"></mat-icon>&nbsp;
      </ng-container>
      {{ activity.name !== '' ? activity.name : ('activity.card.new' | translate) }}
    </h2>
    <h3>
      {{ activity.subtitle }}
    </h3>
    <p>{{ activity.summary | truncate: 200 }}</p>
  </div>
  <div *ngIf="activityCreatedByOU || activityCreatedByUser" class="text-disclaimer">
    <span
      *ngIf="activityCreatedByOU"
      [innerHtml]="'activity.card.created_by_organization' | translate: { organization: activityCreatedByOU, user: activityCreatedByUser || undefined }"
    >
    </span>
    <span *ngIf="!activityCreatedByOU" [innerHtml]="'activity.card.created_by_user' | translate: { user: activityCreatedByUser }"></span>
  </div>
</mat-card>
