<div fxFlex fxLayout="column">
  <div class="details">
    <span>{{ review.created_at | i18n_date : 'd MMMM YYYY' | async }}</span>
    •
    <span>{{ review.job_title }}</span>
  </div>
  <div fxFlex fxLayoutAlign="space-between center" class="review-heading">
    <div fxFlex fxLayoutAlign="start center">
      <mat-icon>person</mat-icon>
      <span>{{ 'review.heading' | translate : { isAuthor: isAuthor } }}</span>
    </div>
    <div fxFlex fxLayoutAlign="end center" *ngIf="isAuthor">
      <button mat-icon-button (click)="editReview()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="deleteReview()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>

<app-review-rating [editable]="false" [value]="review.average_review_score"></app-review-rating>

<div class="review-content" fxLayout="column" fxLayoutGap="16px">
  <p>
    {{ review.review }}
  </p>
</div>

<div class="review-content" *ngIf="review.recommendation">
  <strong>{{ 'review_preview.tips.title' | translate }}</strong>
  <p>
    {{ review.recommendation }}
  </p>
</div>

<div class="review-content">
  <div class="review-comment" *ngIf="review.comment && !commentFormControl; else createCommentButton" fxFlex fxLayout="column" fxLayoutGap="8px">
    <span class="comment-title">{{ 'review.doenkids_reaction' | translate }}</span>
    <p>{{ review.comment }}</p>
    <ng-container *ngTemplateOutlet="createCommentButton"></ng-container>
  </div>

  <ng-template #createCommentButton>
    <div fxFlex fxLayoutAlign="end" *ngIf="(isAdmin$ | async) && !commentFormControl">
      <button mat-icon-button (click)="startCommenting()">
        <mat-icon>{{ review.comment ? 'edit' : 'comment' }}</mat-icon>
      </button>
    </div>
  </ng-template>

  <div class="review-comment" fxFlex fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="16px" *ngIf="commentFormControl">
    <ng-container *showSpinner="reviewCommentLoading$ | async">
      <textarea
        [maxlength]="maxChars"
        minlength="1"
        rows="7"
        matInput
        [formControl]="commentFormControl"
        [placeholder]="'review.comment.placeholder' | translate"
      ></textarea>
      <button mat-flat-button [disabled]="!commentFormControl.valid" (click)="saveReviewComment()">
        {{ 'review.comment.send' | translate }}
      </button>
    </ng-container>
  </div>
</div>
