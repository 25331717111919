<form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'activity.material_selection.title.label' | translate }}</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        formControlName="title"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="5"
        [placeholder]="'activity.material_selection.title.placeholder' | translate"
      ></textarea>
    </mat-form-field>
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'activity.material_selection.subtitle.label' | translate }}</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        formControlName="subtitle"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="5"
        [placeholder]="'activity.material_selection.subtitle.placeholder' | translate"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="section-outline on-focus">
    <quill-editor
      bounds="self"
      theme="bubble"
      formControlName="content"
      appOnFocus
      [placeholder]="'activity.material_selection.content.placeholder' | translate"
    ></quill-editor>
  </div>
</form>
