<div class="sidenav-wrapper">
  <mat-sidenav-container class="sidenav-container"  *ngIf="translationsHaveLoaded$ | async">
    <mat-sidenav
      [disableClose]="!(isXSmall$ | async)"
      [class.collapsed]="drawerClosed"
      class="sidenav"
      [attr.role]="(isXSmall$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isXSmall$ | async) ? 'over' : 'side'"
      [opened]="!(isXSmall$ | async)"
    >
      <mat-toolbar>
        <a routerLink="" *ngIf="showLogo">
          <img class="logo" [src]="logoUrl" [alt]="'generic.app_name' | translate" />
        </a>
      </mat-toolbar>
      <app-profile-panel></app-profile-panel>
      <mat-nav-list>
        <ng-container *ngFor="let route of routes$ | async">
          <ng-container *ngIf="route.isEnabled$ | async">
            <mat-list-item
              *ngIf="route.routerLink"
              class="hvr-forward"
              [routerLink]="route.routerLink"
              matTooltipPosition="right"
              [matTooltipShowDelay]="100"
              [matTooltip]="route.name | translate: route.nameParams || {}"
              [matTooltipDisabled]="!drawerClosed"
            >
              <mat-icon matListItemIcon matBadgeColor="accent" [matBadge]="route.badgeValue$ ? (route.badgeValue$ | async) ?? '' : ''">{{ route.icon }}</mat-icon>
              <h4 matListItemTitle>{{ route.name | translate: route.nameParams || {} }}</h4>
            </mat-list-item>
            <a
              *ngIf="!route.routerLink && route.url"
              mat-list-item
              class="hvr-forward navigation-link"
              [href]="route.url"
              target="_blank"
              matTooltipPosition="right"
              [matTooltipShowDelay]="100"
              [matTooltip]="route.name | translate: route.nameParams || {}"
              [matTooltipDisabled]="!drawerClosed"
            >
              <mat-icon matListItemIcon matBadgeColor="accent" [matBadge]="route.badgeValue$ ? (route.badgeValue$ | async) ?? '' : ''">help</mat-icon>
              <h4 matListItemTitle *ngIf="!drawerClosed">{{ route.name | translate: route.nameParams || {} }}</h4>
            </a>
          </ng-container>
        </ng-container>
      </mat-nav-list>
      <div class="bg-overlay"></div>
      <div style="flex: 1"></div>
      <a routerLink="" class="footer-logo" *ngIf="!drawerClosed">
        <img [src]="poweredByLogo" />
      </a>
      <footer (click)="toggleNavigation()" *ngIf="!(isXSmall$ | async)">
        <p class="build-version">{{ buildVersion ?? '' }}</p>
        <button mat-icon-button class="chevron">
          <mat-icon>chevron_left</mat-icon>
        </button>
      </footer>
    </mat-sidenav>
    <mat-sidenav-content id="sidenav-content">
      <router-outlet #o="outlet"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
