import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, startWith } from 'rxjs';
import { DoenkidsSessionProvider } from './session.provider';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { ProgramTemplateService } from 'src/api/activity/program-template/program-template.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityToReviewCountProvider {
  isAdmin$: Observable<boolean>;

  activityNewCount$ = new BehaviorSubject(0);

  programTemplateNewCount$ = new BehaviorSubject(0);

  private refetchSubject$ = new BehaviorSubject(null);

  constructor(private $session: DoenkidsSessionProvider, private $activity: ActivityService, private $programTemplate: ProgramTemplateService) {
    this.isAdmin$ = this.$session.isAdmin$;

    combineLatest([
      this.$session.getOrganizationUnit$,
      this.refetchSubject$.pipe(startWith()),
    ]).subscribe(async ([organizationUnit]) => {
      if (
        ![DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_CUSTOMER, DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_NODE].includes(
          organizationUnit.organization_unit_type_id,
        )
      ) {
        return;
      }

      const [programTemplateNewCount, activityNewCount] = await Promise.all([
        this.$programTemplate.fetchApprovalCount(organizationUnit.id),
        this.$activity.fetchApprovalCount(organizationUnit.id),
      ]);

      this.programTemplateNewCount$.next(programTemplateNewCount?.count ?? 0);
      this.activityNewCount$.next(activityNewCount?.count ?? 0);
    });
  }

  refresh() {
    this.refetchSubject$.next(null);
  }
}
