import { IActivity } from 'typings/doenkids/doenkids';
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-review-activity-description',
  templateUrl: './review-activity-description.component.html',
  styleUrls: ['./review-activity-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewActivityDescriptionComponent {
  @Input() activity: IActivity;
}
