import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ITagListResponse } from 'typings/api-activity';
import { IServiceResponse } from 'typings/api-generic';

@Injectable({ providedIn: 'root' })
export class MediaTagService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
  ) {}

  fetchAll({
    mediaId, limit = 1000, skip = 0, sortField, sortDirection,
  }) {
    return firstValueFrom(this.$baseApi.genericListCall(`/media/media/${mediaId}/tag`, limit, skip, sortField, sortDirection)) as Promise<ITagListResponse>;
  }

  async update(mediaId: number, tagId: number): Promise<IServiceResponse> {
    return firstValueFrom(this.$baseApi.genericPutCall(`/media/media/${mediaId}/tag/${tagId}`, {})) as Promise<IServiceResponse>;
  }

  async delete(mediaId: number, tagId: number): Promise<IServiceResponse> {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/media/media/${mediaId}/tag/${tagId}`)) as Promise<IServiceResponse>;
  }
}
