import { Injectable } from '@angular/core';
import { IPeriod } from 'typings/doenkids/doenkids';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IPeriodListResponse } from 'typings/api-activity';
import { ProgramPeriodListStore } from '../program-period-list/program-period-list.state';
import { ProgramPeriodListQuery } from '../program-period-list/program-period-list.query';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgramPeriodService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private programPeriodListStore: ProgramPeriodListStore,
    private programPeriodQuery: ProgramPeriodListQuery,
  ) {}

  async fetchAll(programId: number, limit: number, skip: number, sortField: string, sortDirection: string) {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      '/activity/period', limit, skip, sortField, sortDirection, { programId },
    )) as IPeriodListResponse;
    return response;
  }

  async create(period: IPeriod) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall(
      '/activity/period',
      period,
    )) as IPeriod;
    return response;
  }

  async fetch(id: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/period/${id}`)) as IPeriod;
    return response;
  }

  async update(period: IPeriod) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/period/${period.id}`, period)) as IPeriod;

    // Change the state
    //
    const originalItem = this.programPeriodQuery.getEntity(period.id);
    if (originalItem) {
      this.programPeriodListStore.upsert(period.id, period);
    }
    return response;
  }

  async archive(id: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/period/${id}`));
  }
}
