import {
  Component, OnInit, Inject, ViewEncapsulation, OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { IActivity } from 'typings/doenkids/doenkids';
import { IPublishActivityRequest } from 'typings/api-publish';
import { PublishActivityService } from 'src/api/publish/activity/activity.service';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { isNil, snakeCase } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { IDialogOption } from '../choice-dialog/choice-dialog.component';

@Component({
  selector: 'app-download-activity-pdf-dialog',
  templateUrl: './download-activity-pdf-dialog.component.html',
  styleUrls: ['./download-activity-pdf-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadActivityPdfDialogComponent implements OnInit, OnDestroy {
  activity: IActivity;

  organizationUnitId: number;

  loading = false;

  pdfOptions: IDialogOption[];

  activityDownloadForm: UntypedFormGroup;

  stop$ = new Subject();

  documentType = new UntypedFormControl('pdf');

  constructor(
    public dialogRef: MatDialogRef<DownloadActivityPdfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { activity: IActivity, organizationUnitId: number },
    private fb: UntypedFormBuilder,
    private $activityPublishService: PublishActivityService,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
    private $baseApi: DoenKidsGenericApiProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.activity = data.activity;
    this.organizationUnitId = data.organizationUnitId;
    this.pdfOptions = [
      {
        label: _('download_activity_pdf_dialog.options.activity'),
        value: 'activity',
      },
      {
        label: _('download_activity_pdf_dialog.options.attachments'),
        value: 'attachments',
      },
      {
        label: _('download_activity_pdf_dialog.options.material'),
        value: 'material',
      },
      {
        label: _('download_activity_pdf_dialog.options.cover'),
        value: 'cover',
      },
    ];

    this.activityDownloadForm = this.fb.group({
      type: [this.pdfOptions[0].value, Validators.required],
    });
  }

  ngOnDestroy() {
    this.stop$.next(undefined);
  }

  ngOnInit() {
    this.activityDownloadForm.valueChanges.pipe(takeUntil(this.stop$)).subscribe((value) => {
      if (value !== 'material') {
        this.documentType.setValue('pdf');
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async confirm() {
    const { type } = this.activityDownloadForm.value;

    this.loading = true;

    const activityPdfRequest: IPublishActivityRequest = {
      activity_id: this.activity.id,
      organization_unit_id: this.organizationUnitId,
      only_attachments: false,
    };

    if (this.documentType.value === 'pdf') {
      switch (type) {
        case 'attachments':
          activityPdfRequest.only_attachments = true;
          break;
        case 'material':
          activityPdfRequest.materiallist_only = true;
          break;
        case 'cover':
          activityPdfRequest.cover_only = true;
          activityPdfRequest.exclude_attachments = true;
          break;
        default: {
          // Do nothing
        }
      }
    }

    if (this.documentType.value === 'excel') {
      try {
      // eslint-disable-next-line no-undef
        const url = `${this.$baseApi.API_END_POINT}/activity/activity/${this.data.activity.id}/materials/export`;
        const headers = this.$baseApi.getAuthorizationHeader();

        this.$doenkidsFileDownloaderService.addDownload({
          name: this.$translateService.instant(
            'download_activity_pdf_dialog.filename.material',
            { activityName: snakeCase(this.data.activity.name) },
          ),
          url,
          headers,
        });

        this.loading = false;
        this.dialogRef.close();
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        let pdf: string | Blob;
        pdf = await this.$activityPublishService.getActivityPdfUrl(activityPdfRequest, this.activity.country_code);
        if (isNil(pdf)) {
          this.$i18nToastProvider.error(_('download_activity_pdf_dialog.unavailable'));
          return;
        }
        if (typeof pdf === 'string') {
          this.$doenkidsFileDownloaderService.addDownload({
            name: `${snakeCase(this.activity.name)}.pdf`,
            url: pdf,
          });
        } else {
          this.$doenkidsFileDownloaderService.addDownload({
            name: `${snakeCase(this.activity.name)}.pdf`,
            blob: pdf,
          });
        }
      } catch (e) {
        this.$i18nToastProvider.error(_('download_activity_pdf_dialog.failed'), { activityName: this.activity.name });
      }
      this.loading = false;
      this.dialogRef.close();
    }
  }
}
