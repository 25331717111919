import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { Location as ALocation } from '@angular/common';
import { firstValueFrom } from 'rxjs';

export const isAdminLevelGuard = async () => {
  const location = inject(ALocation);
  const router = inject(Router);
  const $session = inject(DoenkidsSessionProvider);

  const isAdmin = await firstValueFrom($session.isAdmin$);

  if (!isAdmin && router.navigated) {
    location.back();
  } else if (!isAdmin) {
    router.navigate(['/']);
  }

  return isAdmin;
};

