import { checkAccessToOrganization } from 'src/guards/has-access-to-organization-guard';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { isNil } from '@datorama/akita';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
/**
 * This guard uses the regular hasAccessToOrganization guard, but allows the user to visit the route
 * if they do not have access.
 *
 * When using this guard it is necessary to handle a non-authorized user on the page accordingly.
 *
 * The necessity for this guard presented itself when trying to link to activities and such from within a news message.
 *
 * When linking to an activity for example, the hasAccessToOrganizationGuard will not allow a user from an underlying ou to open it
 * because the URL in the news item contains the organization unit id for a parent OU to which the user does not have access.
 *
 * @export
 */
export const hasAccessToOrganizationOrIsPublicGuard = async (route: ActivatedRouteSnapshot) => {
  const $session = inject(DoenkidsSessionProvider);
  const organizationUnitService = inject(OrganizationUnitService);

  const { params } = route;

  const organizationUnitId = parseInt(params.ou, 10);

  const organization = await organizationUnitService.fetch(organizationUnitId);

  if (isNil(organization)) {
    return false;
  }

  const hasAccessToOrganization = $session.hasWritePermissionForOU(organization.id);

  // If the user has access to this organization, trigger the checkAccessToOrganization
  // To automatically switch to this OU
  if (hasAccessToOrganization) {
    return checkAccessToOrganization(route, $session, organizationUnitService);
  }

  // If the user does not have access to this organization, allow the user to open the page
  // On this page, handle accordingly: check whether the user is allowed to view the data,
  // change the ou-id parameter in the URL, etc.
  return true;
};
