import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgramCategory } from 'typings/doenkids/doenkids';

export interface IProgramCategoryListState extends EntityState<IProgramCategory> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-category-list' })
export class ProgramCategoryListStore extends EntityStore<IProgramCategoryListState, IProgramCategory> {
  constructor() {
    super([]);
  }
}
