<app-split-page
    #splitPage
    [leftLabel]="'template_settings.title' | translate"
    [rightLabel]="'generic.pdf_example' | translate"
    [activeTab]="0"
  >
    <ng-template #left>
      <div class="title-wrapper">
        <div class="title-and-save-button-container">
          <div class="title">
            <h2>{{ 'template_settings.title' | translate }}</h2>
            <mat-slide-toggle *ngIf="templateControls" [formControl]="debugControl">{{ 'template_settings.debug.label' | translate }}</mat-slide-toggle>
          </div>
          <div class="actions" *ngIf="templateControls">
            <button mat-button class="save-button" (click)="saveSettings()" [disabled]="!hasChanges()">
              <mat-icon>save</mat-icon>
              {{ 'generic.save_changes' | translate }}
            </button>
            <button mat-raised-button class="save-button" (click)="resetSettings()" [disabled]="!hasChanges()">
              <mat-icon>delete</mat-icon>
              {{ 'template_settings.delete_draft_settings' | translate }}
            </button>
          </div>
        </div>
        <p *ngIf="templateControls">{{ 'template_settings.description_new' | translate: { activityTypes: (concatednatedActivityTypes$ | async) } }}</p>
        <div class="no-template-settings" *ngIf="!templateControls">
          <p>{{ 'template_settings.no_settings.title' | translate }}</p>
          <button mat-raised-button (click)="createNewSettings()" *ngIf="(isAdmin$ | async) && (isCurrentOrganizationUnitIsOfTypeCustomer$ | async)">
            {{ 'template_settings.no_settings.button' | translate }}
          </button>
        </div>
      </div>
      <mat-accordion *ngIf="templateControls">
        <mat-expansion-panel *ngFor="let form of templateControls.controls | keyvalue: keyValueOrder" [expanded]="(currentActivePanelId$ | async) === form.key" (afterExpand)="handleExpand(form.key)" (afterCollapse)="handleCollapse(form.key)">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ form.key | doenkidsTranslate: 'template_settings' }}</mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <mat-tab-group [(selectedIndex)]="currentActiveTabIndex" (selectedIndexChange)="handleTabChange()">
              <mat-tab *ngIf="form.value.get('activity') as activitySettingsFormGroup" [label]="'template_settings.activity.label' | translate">
                <ng-template matTabContent>
                  <ng-container [ngTemplateOutlet]="activitySettingsFormTemplate" [ngTemplateOutletContext]="{ activitySettingsFormGroup, formKey: form.key }"></ng-container>
                </ng-template>
              </mat-tab>
              <mat-tab *ngIf="form.value.get('program') as programSettingsFormGroup" [label]="'template_settings.program.label' | translate">
                <ng-template matTabContent>
                  <ng-container [ngTemplateOutlet]="programSettingsFormTemplate" [ngTemplateOutletContext]="{ programSettingsFormGroup, formKey: form.key }"></ng-container>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>

    <ng-template #right>
      <div class="pdf-container">
        <div class="spinner-container" *ngIf="(pdfLoading$ | async)">
          <mat-spinner></mat-spinner>
        </div>
        <ng2-pdfjs-viewer
          viewerId="pdf-example"
          (onDocumentLoad)="onPdfLoadedEvent()"
          [attr.visible]="!(pdfLoading$ | async)"
          #pdfViewer
        ></ng2-pdfjs-viewer>
      </div>
    </ng-template>
  </app-split-page>

<ng-template #activitySettingsFormTemplate let-activitySettingsFormGroup="activitySettingsFormGroup" let-formKey="formKey" >
  <div class="actions">
    <ng-container [ngTemplateOutlet]="activityIdDisplay"></ng-container>
    <button mat-button class="save-button" (click)="saveDraftSettings(formKey, 'activity')" [disabled]="!hasDraftChanges(formKey, 'activity') && (activityIdControl.pristine || activityIdControl.invalid)">
      <mat-icon>visibility</mat-icon>
      {{ 'template_settings.preview' | translate }}
    </button>
  </div>
  <mat-tree [dataSource]="activityTemplateDataSource" [treeControl]="activityTemplateTreeControl" class="tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <ng-container *ngIf="node.name === 'header'">
        <section class="header">
          <app-asset-upload
            identifier="headerImage"
            purpose="header"
            (uploaded)="selectImage($event, activitySettingsFormGroup.get('header.image'))"
            >
          <ng-template let-uploadData="uploadData">
            <div fxLayout="row" fxLayoutAlign="center center" >
              <app-doenkids-image
                [maxWidth]="800"
                [maxHeight]="150"
                class="dk-image"
                [mediaItem]="activitySettingsFormGroup.get('header.image').value">
                <app-select-media
                  class="dk-image"
                  [uploadData]="uploadData"
                  identifier="headerImage"
                  [removable]="activitySettingsFormGroup.get('header.image').value"
                  (selected)="selectImage($event, activitySettingsFormGroup.get('header.image'))"
                  purpose="header">
                </app-select-media>
              </app-doenkids-image>
            </div>
          </ng-template>
          </app-asset-upload>
          <div class="individual-row-fields">
            <ng-container [ngTemplateOutlet]="imageFillDisplay" [ngTemplateOutletContext]="{ imageFillControl: activitySettingsFormGroup.get('header.imageFill') }"></ng-container>
            <ng-container [ngTemplateOutlet]="heightDisplay" [ngTemplateOutletContext]="{ heightControl: activitySettingsFormGroup.get('header.height')}"></ng-container>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'footer'">
        <section class="footer">
          <app-asset-upload
            identifier="footerImage"
            purpose="footer"
            (uploaded)="selectImage($event, activitySettingsFormGroup.get('footer.image'))"
            >
          <ng-template let-uploadData="uploadData">
            <div fxLayout="row" fxLayoutAlign="center center" >
              <app-doenkids-image
                [maxWidth]="800"
                [maxHeight]="150"
                class="dk-image"
                [mediaItem]="activitySettingsFormGroup.get('footer.image').value">
                <app-select-media
                  class="dk-image"
                  identifier="footerImage"
                  [uploadData]="uploadData"
                  [removable]="activitySettingsFormGroup.get('footer.image').value"
                  (selected)="selectImage($event, activitySettingsFormGroup.get('footer.image'))"
                  purpose="footer">
                </app-select-media>
              </app-doenkids-image>
            </div>
          </ng-template>
          </app-asset-upload>
          <div class="individual-row-fields">
            <ng-container [ngTemplateOutlet]="imageFillDisplay" [ngTemplateOutletContext]="{ imageFillControl: activitySettingsFormGroup.get('footer.imageFill') }"></ng-container>
            <ng-container [ngTemplateOutlet]="heightDisplay" [ngTemplateOutletContext]="{ heightControl: activitySettingsFormGroup.get('footer.height')}"></ng-container>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'font'">
        <section>
          <div class="fonts">
            <ng-container *ngFor="let fontGroup of activitySettingsFormGroup.get('fonts')?.controls | keyvalue" [ngTemplateOutlet]="fontDisplay" [ngTemplateOutletContext]="{ fontGroup: fontGroup.value, fontTitle: fontGroup.key }"></ng-container>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'pageMargins'">
        <section>
          <ng-container [ngTemplateOutlet]="marginsDisplay" [ngTemplateOutletContext]="{ marginsGroup: activitySettingsFormGroup.get('pageMargins'), label: '' }"></ng-container>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'mediaSection'">
        <section>
          {{ 'template_settings.media_section.description' | translate }}
          <div class="media-section-individual-fields individual-row-fields">
            <mat-form-field class="media-section-size" appearance="outline" floatLabel="always">
              <mat-label>{{ 'template_settings.media_section.size.label' | translate }}</mat-label>
              <input type="number" matInput [formControl]="activitySettingsFormGroup.get('mediaSection.size')">
              <span matSuffix class="media-section-size-suffix">{{ 'template_settings.media_section.size.suffix' | translate }}</span>
            </mat-form-field>
            <ng-container [ngTemplateOutlet]="imageFillDisplay" [ngTemplateOutletContext]="{ imageFillControl: activitySettingsFormGroup.get('mediaSection.imageFill') }"></ng-container>
          </div>
          <ng-container [ngTemplateOutlet]="marginsDisplay" [ngTemplateOutletContext]="{ marginsGroup: activitySettingsFormGroup.get('mediaSection.margins') }"></ng-container>
        </section>
      </ng-container>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{activityTemplateTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <ng-container *ngIf="node.name === 'header'">
          <h3>{{ 'template_setting_options.header.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'footer'">
          <h3>{{ 'template_setting_options.footer.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'font'">
          <h3>{{ 'template_settings.font.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'pageMargins'">
          <h3>{{ 'template_settings.page_margins.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'mediaSection'">
          <h3>{{ 'template_settings.media_section.heading' | translate }}</h3>
        </ng-container>
      </div>
      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div [class.tree-invisible]="!activityTemplateTreeControl.isExpanded(node)"
          role="group">
        <ng-container matTreeNodeOutlet></ng-container>
    </div>
    </mat-nested-tree-node>
  </mat-tree>
</ng-template>

<ng-template #programSettingsFormTemplate let-programSettingsFormGroup="programSettingsFormGroup" let-formKey="formKey">
  <div class="actions">
    <ng-container [ngTemplateOutlet]="programIdDisplay"></ng-container>
    <button mat-button class="save-button" (click)="saveDraftSettings(formKey, 'program')" [disabled]="!hasDraftChanges(formKey, 'program') && (programIdControl.pristine || programIdControl.invalid)">
      <mat-icon>visibility</mat-icon>
      {{ 'template_settings.preview' | translate }}
    </button>
  </div>
  <mat-tree [dataSource]="programTemplateDataSource" [treeControl]="programTemplateTreeControl" class="tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
      <ng-container *ngIf="node.name === 'header'">
        <section class="header">
          <app-asset-upload
            identifier="headerImage"
            purpose="header"
            (uploaded)="selectImage($event, programSettingsFormGroup.get('header.image'))"
            >
          <ng-template let-uploadData="uploadData">
            <div fxLayout="row" fxLayoutAlign="center center" >
              <app-doenkids-image
                [maxWidth]="800"
                [maxHeight]="150"
                class="dk-image"
                [mediaItem]="programSettingsFormGroup.get('header.image').value">
                <app-select-media
                  class="dk-image"
                  [uploadData]="uploadData"
                  identifier="headerImage"
                  [removable]="programSettingsFormGroup.get('header.image').value"
                  (selected)="selectImage($event, programSettingsFormGroup.get('header.image'))"
                  purpose="header">
                </app-select-media>
              </app-doenkids-image>
            </div>
          </ng-template>
          </app-asset-upload>
          <div class="individual-row-fields">
            <ng-container [ngTemplateOutlet]="imageFillDisplay" [ngTemplateOutletContext]="{ imageFillControl: programSettingsFormGroup.get('header.imageFill') }"></ng-container>
            <ng-container [ngTemplateOutlet]="heightDisplay" [ngTemplateOutletContext]="{ heightControl: programSettingsFormGroup.get('header.height')}"></ng-container>
          </div>
          <ng-container [ngTemplateOutlet]="programHeaderSettingsDisplay" [ngTemplateOutletContext]="{ programHeaderSettingsGroup: programSettingsFormGroup.get('header.organizationUnit'), translationPrefix: 'template_settings.organization_unit' }"></ng-container>
          <ng-container [ngTemplateOutlet]="programHeaderSettingsDisplay" [ngTemplateOutletContext]="{ programHeaderSettingsGroup: programSettingsFormGroup.get('header.programName'), translationPrefix: 'template_settings.program_name' }"></ng-container>
          <ng-container [ngTemplateOutlet]="programHeaderSettingsDisplay" [ngTemplateOutletContext]="{ programHeaderSettingsGroup: programSettingsFormGroup.get('header.programDate'), translationPrefix: 'template_settings.program_date' }"></ng-container>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'footer'">
        <section class="footer">
          <app-asset-upload
            identifier="footerImage"
            purpose="footer"
            (uploaded)="selectImage($event, programSettingsFormGroup.get('footer.image'))"
            >
          <ng-template let-uploadData="uploadData">
            <div fxLayout="row" fxLayoutAlign="center center" >
              <app-doenkids-image
                [maxWidth]="800"
                [maxHeight]="150"
                class="dk-image"
                [mediaItem]="programSettingsFormGroup.get( 'footer.image' ).value">
                <app-select-media
                  class="dk-image"
                  identifier="footerImage"
                  [uploadData]="uploadData"
                  [removable]="programSettingsFormGroup.get('footer.image').value"
                  (selected)="selectImage($event, programSettingsFormGroup.get('footer.image'))"
                  purpose="footer">
                </app-select-media>
              </app-doenkids-image>
            </div>
          </ng-template>
          </app-asset-upload>
          <div class="individual-row-fields">
            <ng-container [ngTemplateOutlet]="imageFillDisplay" [ngTemplateOutletContext]="{ imageFillControl: programSettingsFormGroup.get('footer.imageFill') }"></ng-container>
            <ng-container [ngTemplateOutlet]="heightDisplay" [ngTemplateOutletContext]="{ heightControl: programSettingsFormGroup.get('footer.height')}"></ng-container>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'font'">
        <section>
          <div class="fonts">
            <ng-container *ngFor="let fontGroup of programSettingsFormGroup.get('fonts')?.controls | keyvalue" [ngTemplateOutlet]="fontDisplay" [ngTemplateOutletContext]="{ fontGroup: fontGroup.value, fontTitle: fontGroup.key }"></ng-container>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'banners'">
        <section>
          <div class="banner-settings">
            <div class="individual-row-fields">
              <ng-container [ngTemplateOutlet]="imageClipDisplay" [ngTemplateOutletContext]="{ imageClipControl: programSettingsFormGroup.get('banners.imageClip') }"></ng-container>
              <ng-container [ngTemplateOutlet]="slotDisplay" [ngTemplateOutletContext]="{ slotControl: programSettingsFormGroup.get('banners.slot') }"></ng-container>
              <mat-form-field class="banner-size" appearance="outline" floatLabel="always">
                <mat-label>{{ 'template_settings.banners.size.label' | translate }}</mat-label>
                <input type="number" matInput [formControl]="programSettingsFormGroup.get('banners.size')">
                <span matSuffix class="banner-size-suffix">{{ 'template_settings.banners.size.suffix' | translate }}</span>
              </mat-form-field>
              <mat-form-field class="banner-images-per-page" appearance="outline" floatLabel="always">
                <mat-label>{{ 'template_settings.banners.images-per-page.label' | translate }}</mat-label>
                <input type="number" matInput [formControl]="programSettingsFormGroup.get('banners.imagesPerPage')">
              </mat-form-field>
              <ng-container [ngTemplateOutlet]="fixedImagesDisplay" [ngTemplateOutletContext]="{ fixedImagesControl: programSettingsFormGroup.get('banners.fixedImages') }"></ng-container>

              <mat-slide-toggle [formControl]="programSettingsFormGroup.get('banners.justify')">{{ 'template_settings.banners.justify' | translate }}</mat-slide-toggle>
              <mat-slide-toggle [formControl]="programSettingsFormGroup.get('banners.noMargins')">{{ 'template_settings.banners.noMargins' | translate }}</mat-slide-toggle>
              <mat-slide-toggle [formControl]="programSettingsFormGroup.get('banners.imageRepeat')">{{ 'template_settings.banners.image-repeat.label' | translate }}</mat-slide-toggle>
            </div>
            <ng-container [ngTemplateOutlet]="borderGroupDisplay" [ngTemplateOutletContext]="{ borderGroup: programSettingsFormGroup.get('banners.borders') }"></ng-container>
          </div>
          <div class="banner-images">
            <h4>{{ 'template_settings.banners.images.label' | translate }}</h4>
            <ng-container [ngTemplateOutlet]="marginsDisplay" [ngTemplateOutletContext]="{ marginsGroup: programSettingsFormGroup.get('banners.imageMargins') }"></ng-container>
            <div class="images">
              <ng-container *ngFor="let bannerImageKeyValue of programSettingsFormGroup.get('banners.images').controls | keyvalue: bannerImageKeyValueOrder" [ngTemplateOutlet]="bannerImage" [ngTemplateOutletContext]="{ bannerImageControl: bannerImageKeyValue.value, bannerImageKey: bannerImageKeyValue.key, bannerImageGroup: programSettingsFormGroup.get('banners.images') }"></ng-container>
              <div class="add-banner-image-button" (click)="addBannerImageControl(programSettingsFormGroup.get('banners.images'))">
                <mat-icon>add</mat-icon>
                {{ 'template_settings.banners.images.add.label' | translate }}
              </div>
            </div>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'activityImages'">
        <section>
          <mat-slide-toggle [formControl]="programSettingsFormGroup.get('activityImages.enabled')">{{ 'template_settings.activity_images.enabled' | translate }}</mat-slide-toggle>

          <div class="activity-image-settings" *ngIf="programSettingsFormGroup.get('activityImages.enabled').value">
            <div class="individual-fields">
              <ng-container [ngTemplateOutlet]="imageClipDisplay" [ngTemplateOutletContext]="{ imageClipControl: programSettingsFormGroup.get('banners.imageClip') }"></ng-container>
              <ng-container [ngTemplateOutlet]="slotDisplay" [ngTemplateOutletContext]="{ slotControl: programSettingsFormGroup.get('banners.slot') }"></ng-container>
            </div>
            <ng-container [ngTemplateOutlet]="borderGroupDisplay" [ngTemplateOutletContext]="{ borderGroup: programSettingsFormGroup.get('banners.borders') }"></ng-container>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'customerSymbols'">
        <section>
          <mat-slide-toggle [formControl]="programSettingsFormGroup.get('customerSymbols.enabled')">{{ 'template_settings.customer_symbols.enabled' | translate }}</mat-slide-toggle>

          <div class="customer-symbol-settings" *ngIf="programSettingsFormGroup.get('customerSymbols.enabled').value">
            <div class="individual-row-fields">
              <ng-container [ngTemplateOutlet]="slotDisplay" [ngTemplateOutletContext]="{ slotControl: programSettingsFormGroup.get('customerSymbols.slot') }"></ng-container>
              <mat-form-field class="customer-symbol-count" appearance="outline" floatLabel="always">
                <mat-label>{{ 'template_settings.customer_symbols.count.label' | translate }}</mat-label>
                <input type="number" matInput [formControl]="programSettingsFormGroup.get('customerSymbols.count')">
              </mat-form-field>
              <mat-form-field class="customer-symbol-size" appearance="outline" floatLabel="always">
                <mat-label>{{ 'template_settings.customer_symbols.size.label' | translate }}</mat-label>
                <input type="number" matInput [formControl]="programSettingsFormGroup.get('customerSymbols.size')">
                <span matSuffix class="customer-symbol-size-suffix">{{ 'template_settings.customer_symbols.size.suffix' | translate }}</span>
              </mat-form-field>
            </div>
          </div>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'pageMargins'">
        <section>
          <ng-container [ngTemplateOutlet]="marginsDisplay" [ngTemplateOutletContext]="{ marginsGroup: programSettingsFormGroup.get('pageMargins'), label: '' }"></ng-container>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'mediaSection'">
        <section>
          <div class="media-section-individual-fields individual-row-fields">
            <mat-form-field class="media-section-size" appearance="outline" floatLabel="always">
              <mat-label>{{ 'template_settings.media_section.size.label' | translate }}</mat-label>
              <input type="number" matInput [formControl]="programSettingsFormGroup.get('mediaSection.size')">
              <span matSuffix class="media-section-size-suffix">{{ 'template_settings.media_section.size.suffix' | translate }}</span>
            </mat-form-field>
            <ng-container [ngTemplateOutlet]="imageFillDisplay" [ngTemplateOutletContext]="{ imageFillControl: programSettingsFormGroup.get('mediaSection.imageFill') }"></ng-container>
          </div>
          <ng-container [ngTemplateOutlet]="marginsDisplay" [ngTemplateOutletContext]="{ marginsGroup: programSettingsFormGroup.get('mediaSection.margins') }"></ng-container>
        </section>
      </ng-container>
      <ng-container *ngIf="node.name === 'periodTitleUnderline'">
        <section>
          <div class="period-title-underline-individual-fields individual-row-fields">
            <mat-slide-toggle [formControl]="programSettingsFormGroup.get('periodTitleUnderline.fullWidth')">{{ 'template_settings.periodTitleUnderline.fullWidth' | translate }}</mat-slide-toggle>
            <ng-container [ngTemplateOutlet]="colorPickerDisplay" [ngTemplateOutletContext]="{ colorControl: programSettingsFormGroup.get('periodTitleUnderline.color'), label: ('template_settings.periodTitleUnderline.color.label' | translate) }"></ng-container>
          </div>
          </section>
      </ng-container>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{programTemplateTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <ng-container *ngIf="node.name === 'header'">
          <h3>{{ 'template_setting_options.header.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'footer'">
          <h3>{{ 'template_setting_options.footer.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'font'">
          <h3>{{ 'template_settings.font.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'banners'">
          <h3>{{ 'template_settings.banners.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'activityImages'">
          <h3>{{ 'template_settings.activity_images.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'customerSymbols'">
          <h3>{{ 'template_settings.customer_symbols.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'pageMargins'">
          <h3>{{ 'template_settings.page_margins.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'mediaSection'">
          <h3>{{ 'template_settings.media_section.heading' | translate }}</h3>
        </ng-container>
        <ng-container *ngIf="node.name === 'periodTitleUnderline'">
          <h3>{{ 'template_settings.periodTitleUnderline.heading' | translate }}</h3>
        </ng-container>
      </div>
      <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
      <div [class.tree-invisible]="!programTemplateTreeControl.isExpanded(node)"
          role="group">
        <ng-container matTreeNodeOutlet></ng-container>
    </div>
    </mat-nested-tree-node>
  </mat-tree>
</ng-template>

<ng-template #fontDisplay let-fontGroup="fontGroup" let-fontTitle="fontTitle" let-fontExample="fontExample">
  <mat-card class="font-card" appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ fontTitle ? ('template_settings.font.'+ fontTitle +'.label' | translate) : '' }}</mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">
      <div class="font-settings">
        <div class="font-color">
          <span
            class="color-setting"
            [style.background]="fontGroup.get('color')?.value"
            cpPosition="top"
            [cpCancelButton]="true"
            (colorPickerChange)="setColor(fontGroup.get('color'), $event)"
            [colorPicker]="fontGroup.get('color')?.value"
          ></span>
          <span>{{fontGroup.get('color')?.value}}</span>
          <button *ngIf="fontGroup.get('color')?.value" mat-icon-button (click)="clearFontGroup(fontGroup)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <mat-form-field class="font-size" appearance="outline" floatLabel="always">
          <mat-label>{{ 'template_settings.font.size.label' | translate }}</mat-label>
          <input type="number" matInput [formControl]="fontGroup.get('size')">
          <span matTextSuffix>{{ 'template_settings.font.size.suffix' | translate }}</span>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'template_settings.font.family.label' | translate }}</mat-label>
          <mat-select [formControl]="fontGroup.get('family')">
            <mat-option *ngFor="let font of fontList | keyvalue" [value]="font.key">{{ font.key }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always" *ngIf="fontGroup.get('family').value">
          <mat-label>{{ 'template_settings.font.style.label' | translate }}</mat-label>
          <mat-select [formControl]="fontGroup.get('style')">
            <mat-option *ngFor="let fontStyle of getFontFamilyStyles(fontGroup.get('family'))" [value]="fontStyle">{{ fontStyle }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #alignmentDisplay let-alignmentControl="alignmentControl">
  <mat-form-field class="alignment" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.alignment.label' | translate }}</mat-label>
    <mat-select [formControl]="alignmentControl">
      <mat-option value="left">{{ 'template_settings.alignment.left.label' | translate }}</mat-option>
      <mat-option value="center">{{ 'template_settings.alignment.center.label' | translate }}</mat-option>
      <mat-option value="right">{{ 'template_settings.alignment.right.label' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #marginsDisplay let-marginsGroup="marginsGroup" let-label="label">
  <div class="margins">
    <span>{{ isDefined(label) ? label : ('template_settings.margins.label' | translate) }}</span>
    <div class="margins-inputs">
      <mat-form-field class="margin-input" appearance="outline" floatLabel="always" *ngFor="let margin of marginsGroup.controls | keyvalue">
        <mat-label>{{ 'template_settings.margins.'+ margin.key +'.label' | translate }}</mat-label>
        <input type="number" matInput [formControl]="castAsFormControl(margin.value)">
        <span matSuffix class="margin-input-suffix">{{ 'template_settings.margins.suffix' | translate }}</span>
      </mat-form-field>
    </div>
  </div>
</ng-template>

<ng-template #positionDisplay let-positionControl="positionControl">
  <mat-form-field class="position-input" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.position.label' | translate }}</mat-label>
    <mat-select [formControl]="positionControl">
      <mat-option value="bottom">{{ 'template_settings.position.bottom.label' | translate }}</mat-option>
      <mat-option value="top">{{ 'template_settings.position.top.label' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #slotDisplay let-slotControl="slotControl">
  <mat-form-field class="slot-input" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.slot.label' | translate }}</mat-label>
    <mat-select [formControl]="slotControl">
      <mat-option value="left">{{ 'template_settings.slot.left.label' | translate }}</mat-option>
      <mat-option value="right">{{ 'template_settings.slot.right.label' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #borderGroupDisplay let-borderGroup="borderGroup">
  <div class="border-group">
    <h4>{{ 'template_settings.banners.border.label' | translate }}</h4>
    <div class="border-controls">
      <div class="border-control border-top">
        <span>{{ 'template_settings.banners.border.top.label' | translate }}</span>
        <ng-container [ngTemplateOutlet]="borderDisplay" [ngTemplateOutletContext]="{ borderControl: borderGroup.get('top') }"></ng-container>
      </div>
      <div class="border-control border-left">
        <span>{{ 'template_settings.banners.border.left.label' | translate }}</span>
        <ng-container [ngTemplateOutlet]="borderDisplay" [ngTemplateOutletContext]="{ borderControl: borderGroup.get('left') }"></ng-container>
      </div>
      <div class="border-control border-bottom">
        <span>{{ 'template_settings.banners.border.bottom.label' | translate }}</span>
        <ng-container [ngTemplateOutlet]="borderDisplay" [ngTemplateOutletContext]="{ borderControl: borderGroup.get('bottom') }"></ng-container>
      </div>
      <div class="border-control border-right">
        <span>{{ 'template_settings.banners.border.right.label' | translate }}</span>
        <ng-container [ngTemplateOutlet]="borderDisplay" [ngTemplateOutletContext]="{ borderControl: borderGroup.get('right') }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #borderDisplay let-borderControl="borderControl">
  <div class="border-display">
    <div class="border-color">
      <span
        class="color-setting"
        [style.background]="borderControl.get('color')?.value"
        cpPosition="top"
        [cpCancelButton]="true"
        (colorPickerChange)="setColor(borderControl.get('color'), $event)"
        [colorPicker]="borderControl.get('color')?.value"
      ></span>
      <span>{{borderControl.get('color')?.value}}</span>
      <button *ngIf="borderControl.get('color')?.value" mat-icon-button (click)="setColor(borderControl.get('color'), undefined)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-form-field class="border-size" appearance="outline" floatLabel="always">
      <mat-label>{{ 'template_settings.border.size.label' | translate }}</mat-label>
      <input type="number" matInput [formControl]="borderControl.get('size')">
      <span matSuffix class="border-size-suffix">{{ 'template_settings.border.size.suffix' | translate }}</span>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #bannerImage let-bannerImageControl="bannerImageControl" let-bannerImageKey="bannerImageKey" let-bannerImageGroup="bannerImageGroup">
  <div class="banner-image">
    <app-asset-upload
      identifier="headerImage"
      purpose="template"
      (uploaded)="selectImage($event, bannerImageControl)"
      >
    <ng-template let-uploadData="uploadData">
      <div fxLayout="row" fxLayoutAlign="center center" >
        <app-doenkids-image
          [maxWidth]="200"
          [maxHeight]="200"
          class="dk-image"
          [mediaItem]="bannerImageControl.value">
          <app-select-media
            class="dk-image"
            [uploadData]="uploadData"
            identifier="headerImage"
            [removable]="bannerImageControl.value"
            (selected)="selectImage($event, bannerImageControl)"
            (removed)="removeBannerImage(bannerImageGroup, bannerImageKey)"
            purpose="banner">
          </app-select-media>
        </app-doenkids-image>
      </div>
    </ng-template>
    </app-asset-upload>
  </div>
</ng-template>

<ng-template #heightDisplay let-heightControl="heightControl">
  <mat-form-field class="height-input-field" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.height.label' | translate }}</mat-label>
    <input type="number" matInput [formControl]="heightControl">
    <span matSuffix class="height-input-suffix">{{ 'template_settings.height.suffix' | translate }}</span>
  </mat-form-field>
</ng-template>

<ng-template #activityIdDisplay>
  <mat-form-field class="activity-id-field" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.activity_id.label' | translate }}</mat-label>
    <input type="number" matInput [formControl]="activityIdControl">
    <mat-error *ngIf="activityIdControl.hasError('required')">{{ 'generic.form.required_field' | translate }}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #programIdDisplay>
  <mat-form-field class="program-id-field" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.program_id.label' | translate }}</mat-label>
    <input type="number" matInput [formControl]="programIdControl">
    <mat-error *ngIf="programIdControl.hasError('required')">{{ 'generic.form.required_field' | translate }}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #imageClipDisplay let-imageClipControl="imageClipControl">
  <mat-form-field class="slot-input" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.banners.image_clip.label' | translate }}</mat-label>
    <mat-select [formControl]="imageClipControl">
      <mat-option value="none">{{ 'template_settings.banners.image_clip.none.label' | translate }}</mat-option>
      <mat-option value="circle">{{ 'template_settings.banners.image_clip.circle.label' | translate }}</mat-option>
      <mat-option value="rounded-rectangle">{{ 'template_settings.banners.image_clip.rounded_rectangle.label' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #imageFillDisplay let-imageFillControl="imageFillControl">
  <mat-form-field class="image-fill-input" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.image_fill.label' | translate }}</mat-label>
    <mat-select [formControl]="imageFillControl">
      <mat-option value="cover">{{ 'template_settings.image_fill.cover.label' | translate }}</mat-option>
      <mat-option value="fit">{{ 'template_settings.image_fill.fit.label' | translate }}</mat-option>
      <mat-option value="stretch">{{ 'template_settings.image_fill.stretch.label' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #programHeaderSettingsDisplay let-programHeaderSettingsGroup="programHeaderSettingsGroup" let-translationPrefix="translationPrefix">
  <div class="program-header-settings">
    <h4>{{ translationPrefix + '.label' | translate }}</h4>
    <mat-slide-toggle [formControl]="programHeaderSettingsGroup.get('enabled')">{{ translationPrefix + '.enabled' | translate }}</mat-slide-toggle>

    <div class="program-header-settings-inputs" *ngIf="programHeaderSettingsGroup.get('enabled').value">
      <div class="individual-fields">
        <mat-slide-toggle *ngIf="programHeaderSettingsGroup.get('showCustomerName') as customerNameControl" [formControl]="customerNameControl">{{ 'template_settings.show_customer_name' | translate }}</mat-slide-toggle>
        <ng-container [ngTemplateOutlet]="alignmentDisplay" [ngTemplateOutletContext]="{ alignmentControl: programHeaderSettingsGroup.get('align') }"></ng-container>
        <ng-container [ngTemplateOutlet]="positionDisplay" [ngTemplateOutletContext]="{ positionControl: programHeaderSettingsGroup.get('position') }"></ng-container>
        <mat-slide-toggle [formControl]="programHeaderSettingsGroup.get('fixedPosition')">{{ 'template_settings.fixed_position' | translate }}</mat-slide-toggle>
      </div>
      <ng-container [ngTemplateOutlet]="fontDisplay" [ngTemplateOutletContext]="{ fontGroup: programHeaderSettingsGroup.get('font') }"></ng-container>
      <ng-container [ngTemplateOutlet]="marginsDisplay" [ngTemplateOutletContext]="{ marginsGroup: programHeaderSettingsGroup.get('margins') }"></ng-container>
      <ng-container [ngTemplateOutlet]="backgroundShapeDisplay" [ngTemplateOutletContext]="{ backgroundShapeGroup: programHeaderSettingsGroup.get('backgroundShape') }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #backgroundShapeDisplay let-backgroundShapeGroup="backgroundShapeGroup">
  <div class="background-shape-settings">
    <div class="individual-fields">
      <ng-container [ngTemplateOutlet]="backgroundShapeShapeDisplay" [ngTemplateOutletContext]="{ shapeControl: backgroundShapeGroup.get('shape') }"></ng-container>
      <ng-container [ngTemplateOutlet]="colorPickerDisplay" [ngTemplateOutletContext]="{ colorControl: backgroundShapeGroup.get('fillColor'), label: ('template_settings.background_shape.fill_color.label' | translate) }"></ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="marginsDisplay" [ngTemplateOutletContext]="{ marginsGroup: backgroundShapeGroup.get('padding'), label: ('template_settings.background_shape.padding.label' | translate) }"></ng-container>
  </div>
</ng-template>

<ng-template #backgroundShapeShapeDisplay let-shapeControl="shapeControl">
  <mat-form-field class="slot-input" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.background_shape.shape.label' | translate }}</mat-label>
    <mat-select [formControl]="shapeControl">
      <mat-option value="none">{{ 'template_settings.background_shape.shape.none.label' | translate }}</mat-option>
      <mat-option value="circle">{{ 'template_settings.background_shape.shape.circle.label' | translate }}</mat-option>
      <mat-option value="rounded-rectangle">{{ 'template_settings.background_shape.shape.rounded_rectangle.label' | translate }}</mat-option>
      <mat-option value="rectangle">{{ 'template_settings.background_shape.shape.rectangle.label' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #colorPickerDisplay let-colorControl="colorControl" let-label="label">
  <mat-card class="color-picker-card" appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ isDefined(label) ? label : ('template_settings.color_picker.label' | translate) }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="color-picker-inputs">
      <span
        class="color-setting"
        [style.background]="colorControl?.value"
        cpPosition="top"
        [cpCancelButton]="true"
        (colorPickerChange)="setColor(colorControl, $event)"
        [colorPicker]="colorControl?.value"
      ></span>
      <span>{{colorControl?.value}}</span>
      <button *ngIf="colorControl?.value" mat-icon-button (click)="setColor(colorControl, undefined)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #fixedImagesDisplay let-fixedImagesControl="fixedImagesControl">
  <mat-form-field class="slot-input" appearance="outline" floatLabel="always">
    <mat-label>{{ 'template_settings.fixed_images.label' | translate }}</mat-label>
    <mat-select [formControl]="fixedImagesControl">
      <mat-option value="none">{{ 'template_settings.fixed_images.none.label' | translate }}</mat-option>
      <mat-option value="all">{{ 'template_settings.fixed_images.all.label' | translate }}</mat-option>
      <mat-option value="first">{{ 'template_settings.fixed_images.first.label' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
