import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IPeriod } from 'typings/doenkids/doenkids';
import { IProgramPeriodListState, ProgramPeriodListStore } from './program-period-list.state';

@Injectable({ providedIn: 'root' })
export class ProgramPeriodListQuery extends QueryEntity<IProgramPeriodListState, IPeriod> {
  constructor(protected store: ProgramPeriodListStore) {
    super(store);
  }
}
