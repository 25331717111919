import {
  Component, OnDestroy, OnInit, ViewEncapsulation, Inject,
} from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  IOpendataLrk, IOrganizationUnit, IKonnectOrganizationUnit, IOrganizationUnitPermission,
  IOrganizationUnitDetails, IKonnectLocation, IKonnectGroup, IJibbieLocation, IJibbieOrganisation, IOrganizationUnitOverview, IActivityType,
} from 'typings/doenkids/doenkids';
import {
  debounceTime, takeUntil,
} from 'rxjs/operators';
import { OpendataLrkSearchQuery } from 'src/api/search/opendata-lrk/opendata-lrk.query';
import { OpendataLrkSearchService } from 'src/api/search/opendata-lrk/opendata-lrk.service';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { OrganizationUnitDetailsService } from 'src/api/customer/organization-unit-details/organization-unit-details.service';
import { OrganizationUnitPermissionsService } from 'src/api/customer/organization-unit-permissions/organization-unit-permissions.service';
import { DoenkidsSessionProvider, LANGUAGES_FOR_COUNTRIES, SUPPORTED_COUNTRIES } from 'src/providers/session.provider';
import { KonnectLocationService } from 'src/api/customer/konnect/location/konnect-location.service';
import {
  IKonnectLocationListResponse, IKonnectGroupListResponse, IJibbieOrganisationListResponse, IJibbieLocationListResponse,
} from 'typings/api-customer';
import { KonnectGroupService } from 'src/api/customer/konnect/group/konnect-group.service';
import { JibbieLocationService } from 'src/api/customer/jibbie/location/jibbie-location.service';
import { JibbieOrganizationService } from 'src/api/customer/jibbie/organization/jibbie-organization.service';
import { ActivityTypeQuery } from 'src/api/generic/activity-type/activity-type.query';
import { TranslateService } from 'src/app/utils/translate.service';
import { ShowErrorsImmediatelyErrorMatcher } from 'src/components/shared/form-validators/error-state-matchers';
import { isNil } from 'lodash';

export enum EOrganizationUnitType {
  ROOT = 1,
  ORGANIZATION = 2,
  LOCATION = 3,
  GROUP = 4,
}

const COUNTRY_POSTAL_CODE_PATTERN = {
  // en postalcode regex comes from: https://webarchive.nationalarchives.gov.uk/ukgwa/+/http://www.cabinetoffice.gov.uk/media/291370/bs7666-v2-0-xsd-PostCodeType.htm
  // eslint-disable-next-line max-len
  gb: Validators.pattern(/^(GIR 0AA)|((([A-Z-[QVX]][0-9][0-9]?)|(([A-Z-[QVX]][A-Z-[IJZ]][0-9][0-9]?)|(([A-Z-[QVX]][0-9][A-HJKSTUW])|([A-Z-[QVX]][A-Z-[IJZ]][0-9][ABEHMNPRVWXY])))) [0-9][A-Z-[CIKMOV]]{2})$/i),
  nl: Validators.pattern(/^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i),
  be: Validators.pattern(/^[1-9]{1}[0-9]{3}$/i),
};

@Component({
  selector: 'app-add-organization-dialog',
  templateUrl: './add-organization-dialog.component.html',
  styleUrls: ['./add-organization-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddOrganizationDialogComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public selectedTab = 0;

  public loading$: Subject<boolean> = new Subject<boolean>();

  public organizationType: UntypedFormControl;

  private lRK_SEARCH_LIMIT = 50;

  private lrkCurrentPage = 0;

  public lrkSearch: UntypedFormGroup;

  public lrkSearching$: Observable<boolean>;

  public lrkSearchResults$: Observable<IOpendataLrk[]>;

  private konnectCurrentPage = 0;

  private KONNECT_SEARCH_LIMIT = 20;

  public konnectLocationResults$: BehaviorSubject<IKonnectLocation[]> = new BehaviorSubject<IKonnectLocation[]>([]);

  public totalKonnectLocations = 0;

  public konnectGroupResults$: BehaviorSubject<IKonnectGroup[]> = new BehaviorSubject<IKonnectGroup[]>([]);

  public totalKonnectGroups = 0;

  public konnectSearch: UntypedFormGroup;

  public konnectDetails: IKonnectOrganizationUnit;

  private JIBBIE_SEARCH_LIMIT = 20;

  private jibbieCurrentPage = 0;

  public jibbieSearch: UntypedFormGroup;

  public jibbieDetails: IJibbieLocation;

  public jibbieLocationResults$: BehaviorSubject<IJibbieLocation[]> = new BehaviorSubject<IJibbieLocation[]>([]);

  public totalJibbieLocations = 0;

  public jibbieOrganisationResults$: BehaviorSubject<IJibbieOrganisation[]> = new BehaviorSubject<IJibbieOrganisation[]>([]);

  public totalJibbieOrganisations = 0;

  public newOrganization: UntypedFormGroup;

  public possibleActivityTypes: IActivityType[];

  public isAdmin$: Observable<boolean>;

  isOrganizationOfTypeCustomer$: Observable<boolean>;

  readonly EOrganizationUnitType = EOrganizationUnitType;

  public localSupportedCountries = SUPPORTED_COUNTRIES;

  public errorStateMatcher = new ShowErrorsImmediatelyErrorMatcher();

  public possibleContentLanguages$ = new BehaviorSubject<string[]>([]);

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddOrganizationDialogComponent>,
    private opendataLrkQuery: OpendataLrkSearchQuery,
    private opendataLrkService: OpendataLrkSearchService,
    private organizationUnitService: OrganizationUnitService,
    private organizationUnitDetailsService: OrganizationUnitDetailsService,
    private organizationUnitPermissionService: OrganizationUnitPermissionsService,
    private konnectLocationService: KonnectLocationService,
    private konnectGroupService: KonnectGroupService,
    private $session: DoenkidsSessionProvider,
    private jibbieLocationService: JibbieLocationService,
    private jibbieOrganisationService: JibbieOrganizationService,
    private activityTypeQuery: ActivityTypeQuery,
    private $translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      organizationUnitType: number,
      parentOU?: IOrganizationUnitOverview,
      currentValueKonnectIntegration: boolean,
      currentValueJibbieIntegration: boolean,
      currentValueDisableActivityCreate: boolean,
    },
  ) {
    this.lrkSearching$ = this.opendataLrkQuery.selectLoading();
    this.lrkSearchResults$ = this.opendataLrkQuery.selectAll();
    this.isAdmin$ = this.$session.isAdmin$;
    this.isOrganizationOfTypeCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$;
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  async ngOnInit() {
    this.lrkSearch = this.fb.group({
      name: [''],
    });

    const isAdmin = await firstValueFrom(this.isAdmin$);
    if (isAdmin && this.data.organizationUnitType === EOrganizationUnitType.LOCATION) {
      this.lrkSearch.get('name').valueChanges.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((newValue: string) => {
        this.searchLrk(newValue, false);
      });

      this.searchLrk('', false);
    }

    this.konnectSearch = this.fb.group({
      name: [''],
    });

    this.konnectSearch.get('name').valueChanges.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((newValue: string) => {
      this.searchKonnect(newValue, false);
    });

    this.jibbieSearch = this.fb.group({
      name: [''],
    });

    this.jibbieSearch.get('name').valueChanges.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((newValue: string) => {
      this.searchJibbie(newValue, false);
    });

    this.newOrganization = this.fb.group({
      activityType: new UntypedFormControl(''),
      name: ['', Validators.required],
      address: new UntypedFormControl(''),
      postalcode: new UntypedFormControl('', this.data.parentOU ? [COUNTRY_POSTAL_CODE_PATTERN[this.data.parentOU.country_code.toLowerCase()]] : []),
      city: new UntypedFormControl(''),
      country: new UntypedFormControl({
        value: this.data.parentOU?.country_code?.toLowerCase(),
        disabled: !isNil(this.data.parentOU),
      }, [Validators.required]),
      contentLanguages: new UntypedFormControl(this.data.parentOU?.languages ?? [], [Validators.required]),
      phone_number: new UntypedFormControl(''),
      email: new UntypedFormControl(''),
      template_settings: new UntypedFormControl(false),
      customer_symbols: new UntypedFormControl(false),
      i18n_translate: new UntypedFormControl(false),
      activity_copy: new UntypedFormControl(false),
      program_template_create: new UntypedFormControl(false),
      konnect_integration: new UntypedFormControl(this.data.currentValueKonnectIntegration ?? false),
      jibbie_integration: new UntypedFormControl(this.data.currentValueJibbieIntegration ?? false),
      disable_activity_create: new UntypedFormControl(this.data.currentValueDisableActivityCreate ?? false),
    });

    if (this.data.parentOU?.country_code) {
      const validLanguages: string[] = LANGUAGES_FOR_COUNTRIES[this.data.parentOU.country_code.toLowerCase()] ?? [];
      this.possibleContentLanguages$.next(validLanguages);
    }

    this.newOrganization.get('country').valueChanges.pipe(
      takeUntil(this.stop$),
    ).subscribe((country: string) => {
      const validLanguages: string[] = LANGUAGES_FOR_COUNTRIES[country.toLowerCase()] ?? [];
      this.possibleContentLanguages$.next(validLanguages);
      const contentLanguageControl = this.newOrganization.get('contentLanguages');
      const selectedContentLanguages = contentLanguageControl.value.filter((contentLanguage) => validLanguages.find((validLanguage) => validLanguage === contentLanguage));
      contentLanguageControl.setValue(selectedContentLanguages.length === 0 ? validLanguages : selectedContentLanguages);
      const postalCodePattern = COUNTRY_POSTAL_CODE_PATTERN[country.toLowerCase()];
      const postalCodeControl = this.newOrganization.get('postalcode');
      if (postalCodePattern) {
        postalCodeControl.setValidators(postalCodePattern);
      } else {
        postalCodeControl.clearValidators();
      }
    });

    if (this.data.currentValueKonnectIntegration) {
      this.newOrganization.get('konnect_integration').disable();
    }

    if (this.data.currentValueJibbieIntegration) {
      this.newOrganization.get('jibbie_integration').disable();
    }

    if (this.data.currentValueDisableActivityCreate) {
      this.newOrganization.get('disable_activity_create').disable();
    }

    if (this.data.organizationUnitType === EOrganizationUnitType.LOCATION) {
      this.newOrganization.get('activityType').setValidators(Validators.required);
    }

    this.possibleActivityTypes = this.data.parentOU ? this.activityTypeQuery.getActivityTypeByCountryCode(this.data.parentOU.country_code) : [];

    this.newOrganization.updateValueAndValidity();

    if (
      this.data.parentOU?.id
      && (
        this.data.organizationUnitType === EOrganizationUnitType.LOCATION
        || this.data.organizationUnitType === EOrganizationUnitType.GROUP
      )
    ) {
      const fetchedKonnectDetails = await this.organizationUnitService.konnectDetails(this.data.parentOU.id);

      if (fetchedKonnectDetails.src === 'organisation' || fetchedKonnectDetails.src === 'location' || fetchedKonnectDetails.src === 'parent_location') {
        this.konnectDetails = fetchedKonnectDetails;

        this.searchKonnect('', false);
      } else {
        this.konnectDetails = null;
      }
    }

    if (
      this.data.parentOU?.id
      && (
        this.data.organizationUnitType === EOrganizationUnitType.LOCATION
        || this.data.organizationUnitType === EOrganizationUnitType.ORGANIZATION
      )
    ) {
      const fetchedJibbieDetails = await this.organizationUnitService.jibbieDetails(this.data.parentOU.id);

      if (fetchedJibbieDetails.src !== 'none') {
        this.jibbieDetails = {
          jibbie_location_id: '',
          name: '',
          jibbie_organisation_id: 0,
        };

        this.searchJibbie('', false);
      } else {
        this.jibbieDetails = null;
      }
    }
  }

  async createNewOU(name: string, activityTypeId?: number, languages?: string[]) {
    const countryCode = this.data.parentOU?.country_code ?? this.newOrganization.get('country')?.value ?? '';
    languages = languages ?? this.data.parentOU?.languages ?? LANGUAGES_FOR_COUNTRIES[countryCode.toLowerCase()] ?? [];
    const newOU: IOrganizationUnit = {
      name,
      parent_organization_unit_id: this.data.parentOU?.id,
      organization_unit_type_id: this.data.organizationUnitType,
      country_code: countryCode.toUpperCase(),
      languages: languages.map((language) => language.toLowerCase()),
      activity_type_id: this.data.organizationUnitType === EOrganizationUnitType.LOCATION ? activityTypeId : undefined,
    };

    return this.organizationUnitService.create(newOU);
  }

  searchLrk(query: string, concatenate: boolean) {
    const searchRequest = {
      query,
      limit: this.lRK_SEARCH_LIMIT,
      skip: concatenate ? ((this.lrkCurrentPage + 1) * this.lRK_SEARCH_LIMIT) : 0,
    };
    this.opendataLrkService.fetch(searchRequest, concatenate);

    if (concatenate) {
      this.lrkCurrentPage += 1;
    } else {
      this.lrkCurrentPage = 0;
    }
  }

  async linkLRKToOU(location: IOpendataLrk) {
    try {
      this.loading$.next(true);
      const activityTypes = this.activityTypeQuery.getAll();
      const selectedActivityType = activityTypes.find((activityType) => activityType.name.toLowerCase() === (location.type_oko ?? '').toLowerCase());
      const newOU = await this.createNewOU(location.actuele_naam_oko, selectedActivityType?.id);

      const organizationUnitDetails: IOrganizationUnitDetails = {
        organization_unit_id: newOU.id,
        address: location.opvanglocatie_adres,
        postalcode: location.opvanglocatie_postcode,
        city: location.opvanglocatie_woonplaats,
        lrk_identifier: location.lrk_id,
        phone_number: location.contact_telefoon,
        email: location.contact_emailadres,
      };

      await this.setOUDetails(organizationUnitDetails);

      this.closeDialog(newOU.id);
    } catch (e) {
      console.log('error linking LRK record to OU');
    }
  }

  async searchKonnect(name: string, concatenate: boolean) {
    if (this.data.organizationUnitType === EOrganizationUnitType.LOCATION && this.konnectDetails) {
      const skip = concatenate ? ((this.konnectCurrentPage + 1) * this.KONNECT_SEARCH_LIMIT) : 0;
      // through the query the results are gonna appear on the screen
      //
      await this.konnectLocationService.fetchAll(this.konnectDetails.id, this.KONNECT_SEARCH_LIMIT, skip, 'name', 'ASC', name)
        .then((konnectLocationListResponse: IKonnectLocationListResponse) => {
          const filteredResults = konnectLocationListResponse.items.filter((location) => !location.organization_unit_id);
          if (concatenate) {
            const currentKonnectLocationList = this.konnectLocationResults$.value;
            this.konnectLocationResults$.next([...currentKonnectLocationList, ...filteredResults]);
            this.konnectCurrentPage += 1;
          } else {
            this.konnectLocationResults$.next(filteredResults);
            this.konnectCurrentPage = 0;
          }
          this.totalKonnectLocations = konnectLocationListResponse.total;

          // as long as we didn't get all locations yet and we have less locations in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalKonnectLocations > (konnectLocationListResponse.limit + konnectLocationListResponse.skip)
            && this.konnectLocationResults$.value.length < this.KONNECT_SEARCH_LIMIT) {
            this.searchKonnect(name, true);
          }
        });
    } else if (this.data.organizationUnitType === EOrganizationUnitType.GROUP) {
      const skip = concatenate ? ((this.konnectCurrentPage + 1) * this.KONNECT_SEARCH_LIMIT) : 0;
      await this.konnectGroupService.fetchAll(this.data.parentOU.id, {
        limit: this.KONNECT_SEARCH_LIMIT,
        skip,
        sortField: 'name',
        sortDirection: 'ASC',
        search: name,
      })
        .then((konnectGroupListResponse: IKonnectGroupListResponse) => {
          const filteredResults = konnectGroupListResponse.items.filter((group) => !group.organization_unit_id);
          if (concatenate) {
            const currentKonnecGroupList = this.konnectGroupResults$.value;
            this.konnectGroupResults$.next([...currentKonnecGroupList, ...filteredResults]);
            this.konnectCurrentPage += 1;
          } else {
            this.konnectGroupResults$.next(filteredResults);
            this.konnectCurrentPage = 0;
          }

          this.totalKonnectGroups = konnectGroupListResponse.total;

          // as long as we didn't get all groups yet and we have less groups in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalKonnectGroups > (konnectGroupListResponse.limit + konnectGroupListResponse.skip)
            && this.konnectGroupResults$.value.length < this.KONNECT_SEARCH_LIMIT) {
            this.searchKonnect(name, true);
          }
        });
    }
  }

  async searchJibbie(name: string, concatenate: boolean) {
    if (this.data.organizationUnitType === EOrganizationUnitType.LOCATION && this.jibbieDetails) {
      const skip = concatenate ? ((this.jibbieCurrentPage + 1) * this.JIBBIE_SEARCH_LIMIT) : 0;
      // through the query the results are gonna appear on the screen
      //
      await this.jibbieLocationService.fetchAll(this.jibbieDetails.id, {
        limit: this.JIBBIE_SEARCH_LIMIT,
        skip,
        sortField: 'name',
        sortDirection: 'ASC',
        search: name,
      })
        .then((jibbieLocationListResponse: IJibbieLocationListResponse) => {
          const filteredResults = jibbieLocationListResponse.items.filter((location) => !location.organization_unit_id);
          if (concatenate) {
            const currentJibbieLocationList = this.jibbieLocationResults$.value;
            this.jibbieLocationResults$.next([...currentJibbieLocationList, ...filteredResults]);
            this.jibbieCurrentPage += 1;
          } else {
            this.jibbieLocationResults$.next(filteredResults);
            this.jibbieCurrentPage = 0;
          }
          this.totalJibbieLocations = jibbieLocationListResponse.total;

          // as long as we didn't get all locations yet and we have less locations in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalJibbieLocations > (jibbieLocationListResponse.limit + jibbieLocationListResponse.skip)
            && this.jibbieLocationResults$.value.length < this.JIBBIE_SEARCH_LIMIT) {
            this.searchJibbie(name, true);
          }
        });
    } else if (this.data.organizationUnitType === EOrganizationUnitType.ORGANIZATION) {
      const skip = concatenate ? ((this.jibbieCurrentPage + 1) * this.JIBBIE_SEARCH_LIMIT) : 0;
      await this.jibbieOrganisationService.fetchAll({
        limit: this.JIBBIE_SEARCH_LIMIT,
        skip,
        sortField: 'name',
        sortDirection: 'ASC',
        search: name,
      })
        .then((jibbieOrganisationListResponse: IJibbieOrganisationListResponse) => {
          const filteredResults = jibbieOrganisationListResponse.items.filter(
            (group) => !group.organization_unit_id,
          );
          if (concatenate) {
            const currentKonnecGroupList = this.jibbieOrganisationResults$.value;
            this.jibbieOrganisationResults$.next([...currentKonnecGroupList, ...filteredResults]);
            this.jibbieCurrentPage += 1;
          } else {
            this.jibbieOrganisationResults$.next(filteredResults);
            this.jibbieCurrentPage = 0;
          }

          this.totalJibbieOrganisations = jibbieOrganisationListResponse.total;

          // as long as we didn't get all groups yet and we have less groups in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalJibbieOrganisations > (jibbieOrganisationListResponse.limit + jibbieOrganisationListResponse.skip)
            && this.jibbieOrganisationResults$.value.length < this.JIBBIE_SEARCH_LIMIT) {
            this.searchJibbie(name, true);
          }
        });
    }
  }

  async linkKonnectLocationToOrganization(location: IKonnectLocation) {
    try {
      this.loading$.next(true);
      const activityTypes = this.activityTypeQuery.getAll();
      const selectedActivityType = activityTypes.find((activityType) => activityType.name.toLowerCase() === (location.type_oko ?? '').split(',')[0].toLowerCase());
      const newOU = await this.createNewOU(location.name, selectedActivityType?.id);

      const organizationUnitDetails: IOrganizationUnitDetails = {
        organization_unit_id: newOU.id,
        address: `${location.street} ${location.housenumber}`,
        postalcode: location.postalcode,
        city: location.city,
      };

      await this.setOUDetails(organizationUnitDetails);

      // connect konnect location to newly created ou
      //
      await this.konnectLocationService.setOrganizationUnit(location.id, newOU.id);

      this.closeDialog(newOU.id);
    } catch (e) {
      console.log('error linking Konnect location to OU', e);
    }
  }

  async linkKonnectGroupToOrganization(group: IKonnectGroup) {
    try {
      this.loading$.next(true);
      const activityTypes = this.activityTypeQuery.getAll();
      const selectedActivityType = activityTypes.find((activityType) => activityType.name.toLowerCase() === (group.type_oko ?? '').split(',')[0].toLowerCase());
      const newOU = await this.createNewOU(group.name, selectedActivityType?.id);

      const organizationUnitDetails: IOrganizationUnitDetails = {
        organization_unit_id: newOU.id,
        address: '',
        postalcode: '',
        city: '',
      };

      await this.setOUDetails(organizationUnitDetails);

      // connect konnect location to current ou
      //
      await this.konnectGroupService.setOrganizationUnit(group.id, newOU.id);

      this.closeDialog(newOU.id);
    } catch (e) {
      console.log('error linking Konnect group to OU', e);
    }
  }

  async linkJibbieLocationToOrganization(location: IJibbieLocation) {
    try {
      this.loading$.next(true);
      const activityTypes = this.activityTypeQuery.getAll();
      const selectedActivityType = activityTypes.find((activityType) => activityType.name.toLowerCase() === (location.type ?? '').split(',')[0].toLowerCase());
      const newOU = await this.createNewOU(location.name, selectedActivityType?.id);

      const organizationUnitDetails: IOrganizationUnitDetails = {
        organization_unit_id: newOU.id,
        address: `${location.street} ${location.housenumber}`,
        postalcode: location.postalcode,
        city: location.city,
      };

      await this.setOUDetails(organizationUnitDetails);

      // connect jibbie location to newly created ou
      //
      await this.jibbieLocationService.setOrganizationUnit(location.id, newOU.id);

      this.closeDialog(newOU.id);
    } catch (e) {
      console.log('error linking Konnect location to OU', e);
    }
  }

  async linkJibbieOrganisationToOrganization(organisation: IJibbieOrganisation) {
    try {
      this.loading$.next(true);
      const newOU = await this.createNewOU(organisation.name);

      const organizationUnitDetails: IOrganizationUnitDetails = {
        organization_unit_id: newOU.id,
        address: '',
        postalcode: '',
        city: '',
        phone_number: '',
        email: '',
      };

      await this.setOUDetails(organizationUnitDetails);

      // connect jibbie organisation to current ou
      //
      await this.jibbieOrganisationService.setOrganizationUnit(organisation.id, newOU.id);

      this.closeDialog(newOU.id);
    } catch (e) {
      console.log('error linking Konnect group to OU', e);
    }
  }

  async createNewOrganization() {
    // just do an extra check on validity
    //
    this.newOrganization.updateValueAndValidity();

    // only if the entered organization is valid create the ou
    //
    if (this.newOrganization.valid) {
      try {
        this.loading$.next(true);
        const isAdmin = await firstValueFrom(this.isAdmin$);
        // eslint-disable-next-line max-len
        const activityTypeId = this.newOrganization.get('activityType')?.value;
        const newOU = await this.createNewOU(this.newOrganization.get('name').value, activityTypeId, this.newOrganization.get('contentLanguages').value);

        const ouDetails: IOrganizationUnitDetails = {
          organization_unit_id: newOU.id,
          address: this.newOrganization.get('address').value,
          postalcode: this.newOrganization.get('postalcode').value,
          city: this.newOrganization.get('city').value,
          phone_number: this.newOrganization.get('phone_number').value,
          email: this.newOrganization.get('email').value,
        };

        await this.setOUDetails(ouDetails);

        if (isAdmin) {
          const ouPermission: IOrganizationUnitPermission = {
            template_settings: this.newOrganization.get('template_settings').value,
            customer_symbols: this.newOrganization.get('customer_symbols').value,
            organization_unit_id: newOU.id,
            i18n_translate: this.newOrganization.get('i18n_translate').value,
            activity_copy: this.newOrganization.get('activity_copy').value,
            program_template_create: this.newOrganization.get('program_template_create').value,
            konnect_integration: this.newOrganization.get('konnect_integration').value,
            disable_activity_create: this.newOrganization.get('disable_activity_create').value,
          };

          await this.organizationUnitPermissionService.update(ouPermission);
        }
        this.loading$.next(false);
        this.closeDialog(newOU.id);
      } catch (e) {
        console.log('error creating OU', e);
      }
    }
  }

  async setOUDetails(ouDetails: IOrganizationUnitDetails) {
    await this.organizationUnitDetailsService.update(ouDetails);
  }

  closeDialog(newCreatedOUId: number) {
    this.loading$.next(false);
    this.stop$.next();
    this.dialogRef.close(newCreatedOUId);
  }

  scrollEndReached() {
    if (this.selectedTab === 0) {
      this.searchLrk(this.lrkSearch.get('name').value, true);
    } else if (this.selectedTab === 1
      && (this.totalKonnectLocations > this.konnectLocationResults$.value.length || this.totalKonnectGroups > this.konnectGroupResults$.value.length)) {
      this.searchKonnect(this.konnectSearch.get('name').value, true);
    }
  }

  getCountryLabel(countryCode?: string) {
    if (!countryCode) {
      return '';
    }
    const supportedCountryEntry = this.localSupportedCountries.find((supportedCountry) => supportedCountry.countryCode.toLowerCase() === countryCode.toLowerCase());

    // a dot means its not a valid country code that we currently support so just show the country code so they know whats going on
    //
    if (!supportedCountryEntry) {
      return countryCode;
    }

    return this.$translateService.instant(supportedCountryEntry.viewValue);
  }
}
