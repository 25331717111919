<form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.title' | translate }}</mat-label>
      <textarea
      matInput
      cdkTextareaAutosize
      formControlName="title"
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="2"
      [placeholder]="'activity.section.youtube.title.placeholder' | translate"></textarea>
    </mat-form-field>
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.subtitle' | translate }}</mat-label>
      <textarea
      matInput
      cdkTextareaAutosize
      formControlName="subtitle"
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="2"
      cdkAutosizeMaxRows="2"
      [placeholder]="'activity.section.youtube.subtitle.placeholder' | translate"></textarea>
    </mat-form-field>
  </div>
  <div formArrayName="video">
    <mat-form-field *ngFor="let control of videoFormArray?.controls; let index = index" appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'activity.section.youtube.video.label' | translate }}</mat-label>
      <input type="text" [formControlName]="index" matInput>
      <button *ngIf="!form.disabled" matSuffix mat-icon-button [attr.aria-label]="'generic.remove' | translate" (click)="videoFormArray.removeAt(index);">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</form>
