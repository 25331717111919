import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IRangeOfDevelopmentDetails } from 'typings/doenkids/doenkids';
import { IRangeOfDevelopmentState, RangeOfDevelopmentStore } from './range-of-development.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RangeOfDevelopmentQuery extends QueryEntity<IRangeOfDevelopmentState, IRangeOfDevelopmentDetails> {
  constructor(protected store: RangeOfDevelopmentStore) {
    super(store);
  }

  getRangeOfDevelopmentForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((rangeOfDevelopments) => rangeOfDevelopments.filter((rangeOfDevelopment) => rangeOfDevelopment.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }
}
