import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ISection } from 'typings/doenkids/doenkids';
import { ISectionListResponse } from 'typings/api-activity';
import { SectionStore } from './section.store';
import { firstValueFrom } from 'rxjs';
import { IServiceResponse } from 'typings/api-generic';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  constructor(private store: SectionStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(activityId: number, limit: number, skip: number, sortField: string = 'order', sortDirection: string = 'DESC') {
    const fetchedSections = await firstValueFrom(this.$baseApi.genericListCall('/activity/section', limit, skip, sortField, sortDirection, { activityId })) as ISectionListResponse;
    this.store.set(fetchedSections?.items ?? []);

    this.store.setMetadata({ total: fetchedSections?.total, limit: fetchedSections?.limit, skip: fetchedSections?.skip });
  }

  async create(section: ISection) {
    const newSection = await firstValueFrom(this.$baseApi.genericPostCall('/activity/section', section)) as ISection;

    if (newSection) {
      this.store.upsert(newSection.id as number, newSection);
    }

    return newSection;
  }

  async update(section: ISection) {
    const updatedSection = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/section/${section.id}`, section)) as ISection;

    if (updatedSection) {
      this.store.upsert(updatedSection.id, updatedSection);
    }

    return updatedSection;
  }

  async archive(sectionId: number) {
    const response = await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/section/${sectionId}`)) as IServiceResponse;
    this.store.remove(sectionId);
    return response;
  }
}
