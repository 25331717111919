import {
  Component, OnDestroy, OnInit, ViewEncapsulation, Inject,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Observable, firstValueFrom } from 'rxjs';
import { IOrganizationUnitDetails, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import {
  debounceTime, takeUntil,
} from 'rxjs/operators';
import { OpendataLrkSearchQuery } from 'src/api/search/opendata-lrk/opendata-lrk.query';
import { OpendataLrkSearchService } from 'src/api/search/opendata-lrk/opendata-lrk.service';
import { OrganizationUnitDetailsService } from 'src/api/customer/organization-unit-details/organization-unit-details.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IOpendataLrkSearch } from 'typings/api-search';

@Component({
  selector: 'app-update-ou-lrk-identifier-dialog',
  templateUrl: './update-ou-lrk-identifier-dialog.component.html',
  styleUrls: ['./update-ou-lrk-identifier-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateOuLrkIdentifierDialog implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public loading$: Subject<boolean> = new Subject<boolean>();

  private lRK_SEARCH_LIMIT = 50;

  private lrkCurrentPage = 0;

  public lrkSearch: UntypedFormGroup;

  public lrkSearching$: Observable<boolean>;

  public lrkSearchResults$: Observable<IOpendataLrkSearch[]>;

  public isAdmin$: Observable<boolean>;

  isOrganizationOfTypeCustomer$: Observable<boolean>;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UpdateOuLrkIdentifierDialog>,
    private opendataLrkQuery: OpendataLrkSearchQuery,
    private opendataLrkService: OpendataLrkSearchService,
    private $organizationUnitDetail: OrganizationUnitDetailsService,
    private $session: DoenkidsSessionProvider,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentOu: IOrganizationUnitOverview,
    },
  ) {
    this.lrkSearching$ = this.opendataLrkQuery.selectLoading();
    this.lrkSearchResults$ = this.opendataLrkQuery.selectAll();
    this.isAdmin$ = this.$session.isAdmin$;
    this.isOrganizationOfTypeCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$;
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  async ngOnInit() {
    this.lrkSearch = this.fb.group({
      name: [''],
    });

    const isAdmin = await firstValueFrom(this.isAdmin$);
    if (isAdmin) {
      this.lrkSearch.get('name').valueChanges.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((newValue: string) => {
        this.searchLrk(newValue, false);
      });

      this.searchLrk('', false);
    }
  }

  searchLrk(query: string, concatenate: boolean) {
    const searchRequest = {
      query,
      limit: this.lRK_SEARCH_LIMIT,
      skip: concatenate ? ((this.lrkCurrentPage + 1) * this.lRK_SEARCH_LIMIT) : 0,
    };
    this.opendataLrkService.fetch(searchRequest, concatenate);

    if (concatenate) {
      this.lrkCurrentPage += 1;
    } else {
      this.lrkCurrentPage = 0;
    }
  }

  async linkLRKToOU(location: IOpendataLrkSearch) {
    try {
      this.loading$.next(true);

      const organizationUnitDetails: IOrganizationUnitDetails = {
        organization_unit_id: this.data.currentOu.id,
        lrk_identifier: location.lrk_id,
      };

      await this.setOUDetails(organizationUnitDetails);

      this.closeDialog();
    } catch (e) {
      console.log('error linking LRK record to OU');
    }
  }

  async setOUDetails(ouDetails: IOrganizationUnitDetails) {
    await this.$organizationUnitDetail.update(ouDetails);
  }

  closeDialog() {
    this.loading$.next(false);
    this.stop$.next();
    this.dialogRef.close();
  }

  scrollEndReached() {
    this.searchLrk(this.lrkSearch.get('name').value, true);
  }
}
