import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-page-message',
  templateUrl: './empty-page-message.component.html',
  styleUrls: ['./empty-page-message.component.scss'],
})
export class EmptyPageMessageComponent {
  @Input() message;

  @Input() icon;

  @Input() svgIcon;
}
