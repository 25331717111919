/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgramTemplateStatus } from 'typings/doenkids/doenkids';

export interface IProgramTemplateStatusState extends EntityState<IProgramTemplateStatus> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-status' })
export class ProgramTemplateStatusStore extends EntityStore<IProgramTemplateStatusState, IProgramTemplateStatus> {
  constructor() {
    super([]);
  }
}
