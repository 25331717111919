import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IOrganizationUnitListResponse } from 'typings/api-customer';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IOrganizationUnit } from 'typings/doenkids/doenkids';
import { OrganizationUnitListStore } from './organization-unit-list.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitListService {
  constructor(private store: OrganizationUnitListStore, private $baseApi: DoenKidsGenericApiProvider) {
  }

  @transaction()
  async fetchAll({
    limit = 100, skip = 0, sortField, sortDirection,
  }: {
    limit: number;
    skip: number;
    sortField?: string;
    sortDirection?: string;
  }, doNotPersist: boolean = false) {
    const response = await firstValueFrom(this.$baseApi.genericListCall('/customer/organization-unit', limit, skip, sortField, sortDirection)) as IOrganizationUnitListResponse;

    if (!doNotPersist) {
      response?.items?.forEach((ou: IOrganizationUnit) => {
        this.store.upsert(ou.id, ou);
      });
    }

    return response;
  }

  @transaction()
  async fetch(id: number, doNotPersist: boolean = false) {
    const response = await firstValueFrom(this.$baseApi.genericGetCallWithoutErrorHandler(`/customer/organization-unit/${id}`)) as IOrganizationUnit;
    if (!doNotPersist && response) {
      this.store.upsert(response.id, response);
    }
    return response;
  }
}
