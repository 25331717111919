import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ISectionTypeListResponse } from 'typings/api-activity';
import { SectionTypeStore } from './section-type.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SectionTypeService {
  constructor(private store: SectionTypeStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 50, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'DESC', organizationUnitId?: number) {
    const fetchedSectionTypes = await firstValueFrom(this.$baseApi.genericListCall('/activity/section-type', limit, skip, sortField, sortDirection,
      { organizationUnitId })) as ISectionTypeListResponse;

    this.store.set(fetchedSectionTypes?.items ?? []);

    return fetchedSectionTypes?.items ?? [];
  }
}
