<app-doenkids-menu-bar [showBackButton]="true" [loading]="( activityLoading$ | async ) || ( forkingActivity$ | async )">
  <div fxLayout="row" fxLayoutAlign="start center" left>
    <button
      *ngIf="(isOwner$ | async) && (hasOUWritePermission$ | async)"
      mat-icon-button
      (click)="edit()"
      [matTooltip]="'activity.preview.edit.tooltip' | translate"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      *ngIf="(isOwner$ | async) && (hasOUWritePermission$ | async) && (activityDetails$ | async)?.status_id !== EActivityStatusType.CONCEPT"
      mat-icon-button
      (click)="backToDrawingBoard()"
      [matTooltip]="'activity.preview.back_to_concept.tooltip' | translate"
    >
      <mat-icon>replay</mat-icon>
    </button>
    <button
      *ngIf="(isEditor$ | async) && (hasOUWritePermission$ | async) && (activityDetails$ | async)?.status_id === EActivityStatusType.REVIEW"
      mat-icon-button
      (click)="approveActivity()"
      [matTooltip]="'activity.preview.approve.tooltip' | translate"
    >
      <mat-icon>done</mat-icon>
    </button>
    <ng-container *ngIf="!(isOwner$ | async) && (hasActivityCopyPermission$ | async) && (hasOUWritePermission$ | async)">
      <button
        *ngIf="(activityDetails$ | async)?.replace_activity_id === null"
        mat-icon-button
        type="button"
        (click)="forkActivity()"
        [matTooltip]="'activity.preview.copy.create.tooltip' | translate"
      >
        <mat-icon class="icon-btn">call_split</mat-icon>
      </button>
      <button
        *ngIf="(activityDetails$ | async)?.replace_activity_id !== null"
        mat-icon-button
        type="button"
        (click)="unforkActivity()"
        [matTooltip]="'activity.preview.copy.remove.tooltip' | translate"
      >
        <mat-icon class="icon-btn-selected">call_split</mat-icon>
      </button>
      <button
        *ngIf="forkedId && (activityDetails$ | async)?.replace_activity_id === null && (isManagement$ | async)"
        mat-icon-button
        type="button"
        (click)="navigateToForkedActivity()"
        color="primary"
        [matTooltip]="'activity.preview.copy.show.tooltip' | translate"
      >
        <mat-icon class="icon-btn">call_split</mat-icon>
      </button>
    </ng-container>

    <!-- START: Jibbie button section -->
    <ng-container *ngIf="isLinkedWihtJibbie$ | async">
      <button
        *ngIf="isActivityLinkedWithJibbie$ | async"
        [matTooltip]="'activity.preview.jibbie.unlink.tooltip' | translate"
        (click)="unlinkWithJibbie()"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="jibbie-linked"></mat-icon>
      </button>
      <button
        *ngIf="!(isActivityLinkedWithJibbie$ | async)"
        [matTooltip]="'activity.preview.jibbie.link.tooltip' | translate"
        (click)="linkWithJibbie()"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="jibbie-unlinked"></mat-icon>
      </button>
    </ng-container>
    <!-- END: Jibbie button section -->

    <button
      *ngIf="isOwner$ | async"
      [matTooltip]="'activity.preview.review.by_organization.tooltip' | translate"
      (click)="reviewByOrganisation()"
      mat-icon-button
      type="button"
    >
      <mat-icon>preview</mat-icon>
    </button>
    <button
      mat-icon-button
      type="button"
      [disabled]="(pdf$ | async) === ''"
      (click)="downloadPDF()"
      [matTooltip]="'activity.preview.pdf.download.tooltip' | translate"
    >
      <mat-icon>get_app</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$ | async) && (isLocation$ | async) && (activityDetails$ | async)?.status_id === publishedStatus"
      mat-icon-button
      type="button"
      (click)="createProgram()"
      [matTooltip]="'activity.create-program.tooltip' | translate"
    >
      <mat-icon>date_range</mat-icon>
    </button>
    <app-owner-chip *ngIf="activityOwner$ | async as owner" [owningOuId]="owner.organization_unit_id"></app-owner-chip>
    <button
      *ngIf="(activityStatus$ | async) === EActivityStatusType.REVIEW && (activityCopies$ | async)?.length > 0"
      [matTooltip]="'activity.preview.view_copies.tooltip' | translate"
      [matMenuTriggerFor]="activityCopies"
      mat-icon-button
      type="button"
    >
      <mat-icon>account_tree</mat-icon>
    </button>
    <mat-menu class="activity-copy-menu" #activityCopies="matMenu">
      <ng-container *ngIf="activityCopies$ | async as activityCopies">
        <ng-container *ngFor="let activityCopy of activityCopies">
          <span mat-menu-item *ngIf="(activityCopy.owner_organization_unit_id ?? [])[0] && this.activityCopyOuName[activityCopy.owner_organization_unit_id[0]]">
            {{ this.activityCopyOuName[activityCopy.owner_organization_unit_id[0]] }}
          </span>
        </ng-container>
      </ng-container>
    </mat-menu>

    <!-- Activity review source-->
    <ng-container *ngIf="(activityDetails$ | async)?.status_id === EActivityStatusType.REVIEW && (activityCreatedByOU || activityCreatedByUser)">
      <button mat-stroked-button class="status-chip review">
        {{ 'activity.preview.review.created_by' | translate : { activityCreatedByUser, activityCreatedByOU } }}
      </button>
    </ng-container>
  </div>
  <div right>
    <button
      mat-icon-button
      type="button"
      (click)="excludeActivity()"
      *ngIf="
        !forkedId &&
        !(isOwner$ | async) &&
        !(isCurrentOrganizationUnitIsOfTypeLocation$ | async) &&
        !(isCurrentOrganizationUnitIsOfTypeGroup$ | async) &&
        !(isRevokedInNodePath$ | async)
      "
      [matTooltip]="'activity.exclude.tooltip' | translate"
    >
      <mat-icon>remove_circle</mat-icon>
    </button>
    <button
      mat-icon-button
      type="button"
      (click)="restoreActivity()"
      *ngIf="
        !forkedId &&
        !(isOwner$ | async) &&
        !(isCurrentOrganizationUnitIsOfTypeLocation$ | async) &&
        !(isCurrentOrganizationUnitIsOfTypeGroup$ | async) &&
        (isRevokedInNodePath$ | async)
      "
      [matTooltip]="'activity.preview.restore.tooltip' | translate"
    >
      <mat-icon>add_circle</mat-icon>
    </button>

    <button *ngIf="(isOwner$ | async) && !(isReader$ | async)" mat-icon-button type="button" (click)="deleteActivity()" [matTooltip]="'activity.preview.delete.tooltip' | translate">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</app-doenkids-menu-bar>

<app-split-page
  [activeTab]="0"
  [leftLabel]="'activity.preview.tab.details' | translate"
  [rightLabel]="'activity.preview.tab.preview' | translate"
  (selectedTabChange)="renderPdf()"
>
  <ng-template #left>
    <div class="sections-container">
      <div *ngIf="activityDetails$ | async as activity" class="details">
        <div class="main-details" fxLayout="column" fxLayout.gt-md="row-reverse" fxLayoutAlign="space-between space-between">
          <div>
            <app-doenkids-image [maxHeight]="150" [maxWidth]="150" [mediaItem]="(activityDetails$ | async)?.media_uuid"> </app-doenkids-image>
            <div
              *ngIf="reviewOverview$ | async as reviewOverview"
              [matTooltip]="'activity.preview.reviews.view.tooltip' | translate"
              (click)="scrollToReviews()"
              class="clickable"
            >
              <app-review-rating
                *ngIf="reviewOverview.review_score; else noReviews"
                [value]="reviewOverview.review_score"
                [editable]="false"
              ></app-review-rating>
              <ng-template #noReviews>
                {{ 'activity.preview.reviews.none' | translate }}
              </ng-template>
            </div>
          </div>
          <div class="title" fxLayout="column">
            <h1 [style.color]="headingColor">
              {{ activity.name !== '' ? activity.name : ('generic.new' | translate) }}
            </h1>
            <h2 [style.color]="subheadingColor">{{ activity.subtitle }}</h2>
            <p class="summary" *ngIf="activity.summary">
              <i>{{ activity.summary }}</i>
            </p>
          </div>
        </div>
      </div>
      <div *ngFor="let section of mediaSections$ | async">
        <h2 *ngIf="section.data?.title" [style.color]="headingColor">{{ section.data?.title }}</h2>
        <h2 *ngIf="section.data?.subtitle" [style.color]="subheadingColor">{{ section.data?.subtitle }}</h2>

        <!-- Videos -->
        <ng-container *ngFor="let safeUrl of section.data?.video">
          <div class="video-cards animated fadeIn" *ngIf="safeUrl && safeUrl.length > 1">
            <iframe *ngIf="safeUrl" [src]="safeUrl | safe : 'resourceUrl'"></iframe>
          </div>
        </ng-container>
      </div>
      <div *ngFor="let section of notMediaSections$ | async" [ngSwitch]="section.type_id">
        <h2 *ngIf="section.data?.title" [style.color]="headingColor">{{ section.data?.title }}</h2>
        <h2 *ngIf="section.data?.subtitle" [style.color]="subheadingColor">{{ section.data?.subtitle }}</h2>
        <ng-container *ngSwitchDefault>
          <div *ngIf="section.data?.content" [innerHTML]="section.data?.content" class="ql-editor"></div>
        </ng-container>

        <ng-container *ngSwitchCase="ESectionType.GLOSSARY">
          {{ section.data?.terms }}
          <div *ngFor="let term of section.data?.term">
            <b>{{ term.name }}</b
            ><span>{{ term.description }}</span>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="ESectionType.SOURCES">
          <div *ngFor="let source of section.data?.source">
            <a [href]="source" target="_blank">{{ source }}</a>
          </div>
        </ng-container>

        <div *ngSwitchCase="ESectionType.MEDIA" class="media-container">
          <ng-container *ngFor="let media of section.data?.media">
            <ng-container *ngIf="media?.uuid">
              <mat-card appearance="outlined" class="media-item">
                <app-doenkids-image [maxHeight]="150" [maxWidth]="150" [mediaItem]="media?.uuid" class="media-image"> </app-doenkids-image>
                <br />
                <i>{{ media.description }}</i>
              </mat-card>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Attachements in metaData card-->
      <div class="attachments">
        <h2 *ngIf="(activityAttachments$ | async).length > 0">
          {{ 'generic.attachments' | translate }}
        </h2>
        <div class="attachment-container">
          <div *ngFor="let attachment of activityAttachments$ | async" class="attachment">
            <div class="attachment-content-container">
              <mat-icon class="attachment-icon">insert_drive_file</mat-icon>
              <a class="attachment-link" (click)="openAttachment(attachment)">
                <span>{{ attachment['filename'] }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div intersection (appear)="reviewsVisible$.next(true)" class="reviews" *ngIf="activityDetails$ | async" #reviewWrapper>
      <app-review-overview [reviewOverview]="reviewOverview$ | async" [preview]="true" [id]="(activityDetails$ | async)?.id" *ngIf="reviewsVisible$ | async">
      </app-review-overview>
    </div>
  </ng-template>

  <ng-template #right>
    <div class="pdf-container">
      <div class="spinner-container" *ngIf="pdfLoading">
        <mat-spinner></mat-spinner>
      </div>
      <ng2-pdfjs-viewer
        (onDocumentLoad)="onPdfLoadedEvent()"
        [viewerId]="(activityDetails$ | async)?.id?.toString()"
        [attr.visible]="!pdfLoading"
        #pdfViewer
      ></ng2-pdfjs-viewer>
    </div>
  </ng-template>
</app-split-page>
