import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IOrganizationUnitTreeResponse, IOrganizationUnitTreeNode, IOrganizationUnitTreeRequest } from 'typings/api-customer';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { values, isEmpty, omit } from 'lodash';
import { OrganizationUnitTreeStore } from './organization-unit-tree.store';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitTreeService {
  public currentOrganizationUnit: BehaviorSubject<IOrganizationUnitTreeNode> = new BehaviorSubject<IOrganizationUnitTreeNode>(null);

  constructor(
    private store: OrganizationUnitTreeStore,
    private $baseApi: DoenKidsGenericApiProvider,
  ) {}

  @transaction()
  async fetch(ouId?: number, type?: number, params?: IOrganizationUnitTreeRequest) {
    this.store.setLoading(true);
    let url = '/customer/organization-unit/tree';

    url = this.$baseApi.appendQueryParam(url, 'organizationUnitId', ouId);
    url = this.$baseApi.appendQueryParam(url, 'organizationUnitTypeId', type);

    const response = await firstValueFrom(this.$baseApi.genericPostCall(url, params)) as IOrganizationUnitTreeResponse;
    // this.store.set( response?.items ?? [] );
    this.store.setLoading(false);
    return response;
  }

  async fetchDescendants(organizationUnitId: number, organizationUnitTypeId: number, flatten = false, removeChildren = true) {
    let url = '/customer/organization-unit/tree';
    url = this.$baseApi.appendQueryParam(url, 'organizationUnitId', organizationUnitId);
    url = this.$baseApi.appendQueryParam(url, 'organizationUnitTypeId', organizationUnitTypeId);

    const response = await firstValueFrom(this.$baseApi.genericPostCall(url, null)) as IOrganizationUnitTreeResponse;

    if (flatten) {
      const flattened = this.flattenNodes(response.items).filter((item) => item.type_id === organizationUnitTypeId);

      if (removeChildren) {
        return this.removeChildren(flattened);
      }

      return flattened;
    }

    return (response?.items ?? [])?.filter((item) => item.type_id === organizationUnitTypeId);
  }

  fetchCustomerDescendants(organizationUnitId: number, flatten = false, removeChildren = true) {
    return this.fetchDescendants(organizationUnitId, DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_CUSTOMER, flatten, removeChildren);
  }

  fetchLocationDescendants(organizationUnitId: number, flatten = false, removeChildren = true) {
    return this.fetchDescendants(organizationUnitId, DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION, flatten, removeChildren);
  }

  fetchGroupDescendants(organizationUnitId: number, flatten = false, removeChildren = true) {
    return this.fetchDescendants(organizationUnitId, DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_GROUP, flatten, removeChildren);
  }

  setLoading(loading = false) {
    this.store.setLoading(loading);
  }

  removeChildren(nodes: IOrganizationUnitTreeNode[]) {
    return nodes.map((node) => omit(node, 'children'));
  }

  flattenNodes(nodes: IOrganizationUnitTreeNode[]): IOrganizationUnitTreeNode[] {
    let result: IOrganizationUnitTreeNode[] = [];
    if (nodes) {
      nodes.forEach((node) => {
        result.push(node);
        if (node.children && !isEmpty(node.children)) {
          result = result.concat(this.flattenNodes(values(node.children)));
        }
      });
    }
    return result;
  }
}
