<mat-dialog-content class="user-dialog" [formGroup]="form">
  <h2>{{ 'user_dialog.heading' | translate }}</h2>
  <app-user-form formControlName="user">
  </app-user-form>
</mat-dialog-content>
<mat-dialog-actions class="user-dialog-actions">
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <button mat-button color="primary" (click)="onCloseToConnect()">{{ 'generic.save_and_link' | translate }}</button>
  <button mat-button color="primary" (click)="onOkClick()" cdkFocusInitial>{{ 'user_dialog.save' | translate }}</button>
</mat-dialog-actions>
