import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  isNil, get, cloneDeep, uniq,
} from 'lodash';
import * as dayjs from 'dayjs';
import {
  takeUntil,
} from 'rxjs/operators';
import {
  IProgram, IProgramTemplate, IPeriod, ISearchActivity, IActivity, IFavoriteFolderDetails,
} from 'typings/doenkids/doenkids';
import { BehaviorSubject, Subject, Observable, firstValueFrom } from 'rxjs';
import { ProgramService } from 'src/api/activity/program/program.service';
import { ProgramTemplateService } from 'src/api/activity/program-template/program-template.service';
import {
  IChoiceDialogData, ChoiceDialogComponent, IChoiceDialogResult, IDialogOption,
} from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { ProgramPeriodService } from 'src/api/activity/program-period/program-period.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { ActivitySelectionProvider } from './activity-selection.provider';
import { ProgramDateProvider } from './program-date.provider';
import { DoenkidsSessionProvider } from './session.provider';
import { ProgramProvider } from './program.provider';
import { I18nToastProvider } from './i18n-toast.provider';
import { ICreateActivityProgram } from 'typings/api-activity';

_('program_creation.program.create.plan_template');
_('program_creation.program.create.plan_template.description');
_('program_creation.program.create.create_program');
_('program_creation.program.create.create_program.description');
_('program_creation.program.create.create_program_from_pinboard_with_all_activities');
_('program_creation.program.create.create_program_from_pinboard_with_all_activities.description');

_('program_creation.action.create');
_('program_creation.action.other_options');
_('program_creation.action.pick_date');
_('program_creation.action.view_template');

const DIALOG_DIMENSIONS = {
  width: '400px',
  minWidth: '320px',
};

export type TPageName = 'activities' | 'calendar' | 'program-template';

export interface ITempProgram {
  program_template_id: number;
  name: string;
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
}

@Injectable({
  providedIn: 'root',
})
export class ProgramCreationProvider implements OnDestroy {
  private _programTemplate: IProgramTemplate;

  private _programTemplate$: BehaviorSubject<IProgramTemplate> = new BehaviorSubject(undefined);

  public get programTemplate(): IProgramTemplate {
    return this._programTemplate;
  }

  public set programTemplate(programTemplate: IProgramTemplate) {
    this._programTemplate = programTemplate;
    this._programTemplate$.next(this._programTemplate);
  }

  public programTemplate$: Observable<IProgramTemplate> = this._programTemplate$.asObservable();

  private _pinbord: IFavoriteFolderDetails;

  public get pinbord(): IFavoriteFolderDetails {
    return this._pinbord;
  }

  public set pinbord(pinbord: IFavoriteFolderDetails) {
    this._pinbord = pinbord;
  }

  private destroy$ = new Subject<void>();

  public programId: number;

  public periodId: number;

  constructor(
    private $activitySelectionProvider: ActivitySelectionProvider,
    private $programDateProvider: ProgramDateProvider,
    private $session: DoenkidsSessionProvider,
    private programService: ProgramService,
    private programPeriodService: ProgramPeriodService,
    private $programTemplateService: ProgramTemplateService,
    private $i18nToastProvider: I18nToastProvider,
    private matDialog: MatDialog,
    private router: Router,
    private $programProvider: ProgramProvider,
    private $translateService: TranslateService,
  ) {
    this.$activitySelectionProvider
      .noOfSelectedActivities$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedActivityCount) => {
        if (!isNil(this.programTemplate) && selectedActivityCount > 0) {
          this.programTemplate = undefined;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   *  3. Template details:
   *
   *  Click "Plan template"
   *
   *  3.A.  There is no date selected, go to the calendar page with a selected program.
   *  3.B.  There is a date selected. Offer to create a new program with the selected template.
   */
  public async promptProgramFromTemplateDetails(programTemplate: IProgramTemplate) {
    const primaryAction = isNil(this.$programDateProvider.timespan) ? 'pick-date' : 'create';

    const data = await this.getProgramCreationDialog({
      creationType: 'plan-template',
      parameters: { programTemplateName: programTemplate.name },
      primaryAction,
      shouldHaveLanguageOptions: false,
    });

    const dialogRef = this.matDialog.open(ChoiceDialogComponent, { ...DIALOG_DIMENSIONS, data });

    const result = await firstValueFrom(dialogRef.afterClosed());
    const action = result?.action;

    if (!action) {
      return;
    }

    if (action === 'pick-date') {
      this.programTemplate = programTemplate;
      this.$activitySelectionProvider.clearSelectedActivities();
      this.router.navigate(['/calendar']);
    } else if (action === 'plan-template') {
      // Create program here.
      //
      const program = await this._createProgramFromTemplate(programTemplate);

      if (isNil(program)) {
        this.$i18nToastProvider.error(_('program_creation.program.create.failed'));
      } else {
        const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
        this.$programDateProvider.timespan = undefined;
        this.programTemplate = undefined;
        this.router.navigate([`/organization/${organizationUnitId}/program/${program.id}`]);
      }
    }
  }

  /**
  *  2. Templates:
  *
  *    Click a template.
  *
  *    2.A.  There is no date selected, go to the program template.
  *    2.B.  There is a date selected.
  *      Offer to:
  *        - Create a new program with the selected template.
  *        - Go to the clicked template's details page. Proceed to 3.
  */
  public async promptProgramFromTemplate(programTemplate: IProgramTemplate) {
    const data = await this.getProgramCreationDialog({
      creationType: 'plan-template',
      parameters: { programTemplateName: programTemplate.name },
      primaryAction: 'create',
      secondaryAction: 'view-template',
      shouldHaveLanguageOptions: false,
    });

    const dialogRef = this.matDialog.open(ChoiceDialogComponent, { ...DIALOG_DIMENSIONS, data });

    const result = await firstValueFrom(dialogRef.afterClosed());
    const action = result?.action;

    if (!action) {
      return 'no-action';
    }
    if (action === 'view-template') {
      return 'view-template';
    }
    if (action === 'plan-template') {
      this.$activitySelectionProvider.clearSelectedActivities();

      const program = await this._createProgramFromTemplate(programTemplate);

      if (isNil(program)) {
        this.$i18nToastProvider.error(_('program_creation.program.create.failed'));
      } else {
        const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
        this.$programDateProvider.timespan = undefined;
        this.programTemplate = undefined;

        this.router.navigate([`/organization/${organizationUnitId}/program/${program.id}`]);

        return 'planned';
      }
    }

    return 'no-action';
  }

  public async promptProgramFromPinbord(pinbord: IFavoriteFolderDetails) {
    const primaryAction = isNil(this.$programDateProvider.timespan) ? 'pick-date' : 'create';

    const onlyPinboardActivitiesAreSelected = !this
      .$activitySelectionProvider
      .selectedActivities$
      .value
      .find((activity) => !pinbord.activities.find((pinboardActivity) => pinboardActivity.id === activity.id));

    const parameters = {
      activityAmount: this.$activitySelectionProvider.noOfSelectedActivities$.value,
    };
    let creationType = 'create-program';
    if (onlyPinboardActivitiesAreSelected) {
      creationType = 'create-program-from-pinboard-with-all-activities';
      parameters.activityAmount = pinbord.activities.length;
    }

    // if primaryAction is create it will map to an result of creationType. This is done because of translations
    //
    const data = await this.getProgramCreationDialog({
      creationType, parameters, primaryAction, shouldHaveLanguageOptions: !isNil(this.$programDateProvider.timespan),
    });

    const dialogRef = this.matDialog.open(ChoiceDialogComponent, { ...DIALOG_DIMENSIONS, data });

    const result = await firstValueFrom(dialogRef.afterClosed()) as IChoiceDialogResult;
    const action = result?.action;

    if (!action) {
      return false;
    }

    if (action === 'pick-date') {
      this.pinbord = pinbord;
      this.programTemplate = undefined;

      return this.router.navigate(['/calendar']);
    }

    if (action === 'create') {
      const chosenLanguage = result?.selectedOption;
      const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
      const { start, end } = this.$programDateProvider.timespan;

      // Set the from- and to-times to 9AM to 5PM.
      // Use dayjs date formatting to pass the data in a format the backend understands.
      //
      const from = dayjs.tz(start).set('hours', 8).set('minutes', 30).toISOString();
      const to = dayjs.tz(end).set('hours', 18).toISOString();
      let activities: (ISearchActivity | IActivity)[];

      if (this.$activitySelectionProvider.noOfSelectedActivities$.value === 0) {
        activities = pinbord.activities;
      } else {
        activities = this.$activitySelectionProvider.selectedActivities$.value;
      }

      const programParams: ICreateActivityProgram = {
        activityIds: activities.map((activity) => activity.id),
        from,
        to,
        language: chosenLanguage,
        organizationUnitId,
        title: pinbord.name,
      };
      const program = await this.programService.createActivityProgram(programParams);

      this.$programDateProvider.timespan = undefined;
      this.programTemplate = undefined;
      this.$activitySelectionProvider.clearSelectedActivities();

      if (isNil(program)) {
        this.$i18nToastProvider.error(_('program_creation.program.create.failed'));
      } else {
        return this.router.navigate([`/organization/${organizationUnitId}/program/${program.id}`]);
      }
    }

    return false;
  }

  /**
   * Prepares the dialog for the calendar program creation flow.
   *
   * @private
   * @param {number} activityAmount The amount of activities selected. Can contain an undefined value or be 0.
   * @param {IProgramTemplate} programTemplate The selected program template. Can contain an undefined value.
   * @param pinbord
   * @returns {IChoiceDialogData} An object containing all the data for the choice dialog component.
   */
  private async prepareProgramFromCalendarDialog(
    selectedActivities: (ISearchActivity | IActivity)[],
    programTemplate: IProgramTemplate,
    pinbord: IFavoriteFolderDetails,
  ) {
    const activitiesSelected = !isNil(selectedActivities) && selectedActivities.length > 0;
    const templateSelected = !isNil(programTemplate);
    const pinbordSelected = !isNil(pinbord);

    /**
     *    1.A.  There is no example program template or activities selected.
     *          Offer to:
     *            - select a template.
     *            - select activities.
     *            - the creation of an empty program.
     */
    if (!activitiesSelected && !templateSelected && !pinbordSelected) {
      return this.getProgramCreationOptionsDialog(this.prepareDateMessage(false));
    }

    /**
     *    1.B.  There is a program template selected, and no activities selected.
     *          Offer to:
     *            - create the program with the selected example program.
     *            - clear the selection and go to 1A.
     */
    if (!activitiesSelected && templateSelected && !pinbordSelected) {
      // since no primaryAction is passed the create action will translate to plan-template action. This is done because of translations
      return this.getProgramCreationDialog({
        creationType: 'plan-template',
        parameters: { programTemplateName: programTemplate.name },
        primaryAction: 'create',
        shouldHaveLanguageOptions: false,
      });
    }

    /**
     *    1.C.  There are activities selected, and no program template.
     *          Offer to:
     *            - create a new program with the selected activities.
     *            - Clear the selection and go to 1A.
     */
    if (activitiesSelected && !templateSelected && !pinbordSelected) {
      const activityLanguages = uniq(selectedActivities.map((activity) => activity.language));
      const shouldHaveLanguageOptions = activityLanguages.length > 1;
      // The create action will translate to create-program action. This is done because of translations
      return this.getProgramCreationDialog({
        creationType: 'create-program',
        parameters: { activityAmount: selectedActivities.length },
        primaryAction: 'create',
        shouldHaveLanguageOptions,
      });
    }

    if (pinbordSelected && !activitiesSelected && !templateSelected) {
      const activityLanguages = uniq(pinbord.activities.map((activity) => activity.language));
      const shouldHaveLanguageOptions = activityLanguages.length > 1;
      // since no primaryAction is passed the create action will translate to create-program-from-pinboard-with-all-activities action. This is done because of translations
      return this.getProgramCreationDialog({
        creationType: 'create-program-from-pinboard-with-all-activities',
        parameters: { activityAmount: pinbord.activities.length },
        primaryAction: 'create',
        shouldHaveLanguageOptions,
      });
    }

    if (pinbordSelected && activitiesSelected && !templateSelected) {
      const activityLanguages = uniq(selectedActivities.map((activity) => activity.language));
      const shouldHaveLanguageOptions = activityLanguages.length > 1;
      // since no primaryAction is passed the create action will translate to create-program action. This is done because of translations
      return this.getProgramCreationDialog({
        creationType: 'create-program',
        parameters: { activityAmount: selectedActivities.length },
        primaryAction: 'create',
        shouldHaveLanguageOptions,
      });
    }

    if (activitiesSelected && templateSelected && pinbordSelected) {
      // Should be impossible.
      //
      console.warn('Should not have selected activities, a program template and a pinbord selected.');
    }

    return {};
  }

  private async getProgramCreationOptionsDialog(dateMessage: string): Promise<IChoiceDialogData> {
    const createNewEmptyOptions = [];
    const ouLanguages = await firstValueFrom(this.$session.ouLanguages$);

    if (ouLanguages.length > 1) {
      for (const language of ouLanguages) {
        createNewEmptyOptions.push({
          label: this.$translateService.instant(_('program_creation.action.create_empty_language'), {
            programLanguage: this.$translateService.instant(_(`generic.language.${language}`)).toLowerCase(),
          }),
          value: `create-new-empty-${language}`,
        });
      }
    } else {
      createNewEmptyOptions.push({
        label: this.$translateService.instant(_('program_creation.action.create_empty')),
        value: 'create-new-empty',
      });
    }


    const createNewEmptyOptionValues = createNewEmptyOptions.map((option) => option.value);

    return {
      title: this.$translateService.instant(_('program_creation.program.create')),
      description: this.$translateService.instant(_('program_creation.program.create.description'), { dateMessage }),
      selectionOptions: [{
        label: this.$translateService.instant(_('program_creation.action.select_template')),
        value: 'select-template',
      }, {
        label: this.$translateService.instant(_('program_creation.action.select_activities')),
        value: 'select-activities',
      }, ...createNewEmptyOptions],
      checkboxOptions: [
        {
          label: this.$translateService.instant(_('program_creation.make_day_program.label')),
          value: 'make-day-program',
          enableWhen: createNewEmptyOptionValues,
        } as IDialogOption,
      ],
    } as IChoiceDialogData;
  }

  private async getProgramCreationDialog(params: {
    creationType: string;
    parameters: { [key: string]: string | number };
    primaryAction: string;
    secondaryAction?: string;
    shouldHaveLanguageOptions?: boolean;
  }) {
    const actions = {
      primaryAction: {
        label: this.$translateService.instant(`program_creation.action.${params.primaryAction.replace(/-/g, '_')}`),
        value: params.primaryAction === 'create' ? params.creationType : params.primaryAction,
      },
      secondaryAction: undefined,
    };

    if (params.secondaryAction) {
      actions.secondaryAction = {
        label: this.$translateService.instant(`program_creation.action.${params.secondaryAction.replace(/-/g, '_')}`),
        value: params.secondaryAction,
      };
    }

    params.parameters.dateMessage = this.prepareDateMessage(params.primaryAction === 'pick-date');

    const translationKey = params.creationType.replace(/-/g, '_');

    let selectionOptions: IDialogOption[];
    let selectionOptionLabel: string;

    if (params.shouldHaveLanguageOptions) {
      const ouLanguages = await firstValueFrom(this.$session.ouLanguages$);

      if (ouLanguages.length > 1) {
        selectionOptions  = [];
        selectionOptionLabel = this.$translateService.instant(_('program_creation.program.create.select.label'));
        for (const language of ouLanguages) {
          selectionOptions.push({
            label: this.$translateService.instant(`generic.language.${language}`),
            value: language,
          });
        }
      }
    }

    return {
      title: this.$translateService.instant(`program_creation.program.create.${translationKey}`),
      description: this.$translateService.instant(`program_creation.program.create.${translationKey}.description`, params.parameters),
      selectionOptionLabel,
      selectionOptions,
      actions,
    } as IChoiceDialogData;
  }

  /**
   *   1. Calendar:
   *
   *      Select a date.
   *
   *    1.A.  There is no example program, no activities and no program template selected.
   *          Offer to:
   *            - select a template.
   *            - select activities.
   *            - the creation of an empty program.
   *    1.B.  There is a program template selected, and no activities selected.
   *          Offer to:
   *            - create the program with the selected example program.
   *            - clear the selection and go to 1A.
   *    1.C.  There are activities selected, and no program template.
   *          Offer to:
   *            - create a new program with the selected activities.
   *            - Clear the selection and go to 1A.
   *    1.E.  A program template as well as activities have been selected.
   *          CONFUSING! SHOULD BE IMPOSSIBLE!!!
   *          Ways to make sure:
   *            - When selecting activities, clear the program template.
   *            - When selecting the program template, clear the selected activities.
   */
  public async promptProgramFromCalendar(selectedLanguage?: string): Promise<string> {
    const programTemplate = await firstValueFrom(this.programTemplate$);
    const data = await this.prepareProgramFromCalendarDialog(this.$activitySelectionProvider.selectedActivities$.value, programTemplate, this.pinbord);
    const dialogRef = this.matDialog.open(ChoiceDialogComponent, { ...DIALOG_DIMENSIONS, data });
    const result: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    const { start, end } = this.$programDateProvider.timespan;

    // Set the from- and to-times to 9AM to 5PM.
    // Use dayjs date formatting to pass the data in a format the backend understands.
    //
    const from = dayjs.tz(start).set('hours', 8).set('minutes', 30).toISOString();
    const to = dayjs.tz(end).set('hours', 18).toISOString();

    if (isNil(result)) {
      return 'empty-choice';
    }

    const ouLanguages = await firstValueFrom(this.$session.ouLanguages$);

    const possibleActions = [
      'select-template',
      'other-options',
      'select-activities',
      'create-new-empty',
      ...ouLanguages.map((language) => `create-new-empty-${language}`),
      'plan-template',
      'create-program',
      'create-program-from-pinboard-with-all-activities',
    ];

    const action = !isNil(result.selectedOption) && possibleActions.includes(result.selectedOption) ? result.selectedOption : result.action;
    // 1.A.
    //
    if (action === 'select-template') {
      const queryParams: any = {};
      if (selectedLanguage) {
        queryParams.language = selectedLanguage;
      }
      this.router.navigate([`/organization/${organizationUnitId}/templates`], { queryParams });

      return 'navigation';
    }

    if (action === 'other-options') {
      this.clearSelections();
      this.promptProgramFromCalendar();

      return 'cancellation';
    }

    if (action === 'select-activities') {
      const programParams: ICreateActivityProgram = {
        organizationUnitId,
        from,
        to,
        activityIds: [],
        language: selectedLanguage,
      };
      const newProgram = await this.programService.createActivityProgram(programParams);

      const firstPeriodOfProgram = (await this.programPeriodService.fetchAll(newProgram.id, 1, 0, 'created_at', 'desc'))?.items?.[0];

      // Navigate the user to the activities page, in activity gathering mode.
      // passing along the new program id and period id so that we can connect activities to it
      //
      this.$activitySelectionProvider.toggleSelecting();
      this.router.navigate(['/activities'], { queryParams: { programId: newProgram.id, periodId: firstPeriodOfProgram?.id } });

      return 'navigation';
    }

    let program: IProgram;
    let activities = [];
    let programParameters: ICreateActivityProgram;
    const isDayProgram = result.checkedValues['make-day-program'];

    if (action.startsWith('create-new-empty')) {
      let chosenLanguage: string;
      const splitAction = action.split('-');
      const actionLanguage = splitAction[splitAction.length - 1];

      if (ouLanguages.includes(actionLanguage)) {
        chosenLanguage = actionLanguage.toLowerCase();
      } else {
        chosenLanguage = ouLanguages[0];
      }

      programParameters = {
        activityIds: [],
        from,
        to,
        language: chosenLanguage,
        organizationUnitId,
      };
    }

    if (action === 'plan-template') {
      program = await this._createProgramFromTemplate(programTemplate);
    }

    if (action === 'create-program') {
      const activityLanguages = uniq(this.$activitySelectionProvider.selectedActivities$.value.map((activity) => activity.language));
      let chosenLanguage: string;

      if (activityLanguages.length > 1) {
        chosenLanguage = result?.selectedOption;
      } else {
        chosenLanguage = activityLanguages[0];
      }

      programParameters = {
        activityIds: this.$activitySelectionProvider.selectedActivities$.value.map((activity) => activity.id),
        from,
        to,
        language: chosenLanguage,
        organizationUnitId,
        title: this.pinbord?.name,
      };
    }

    if (action === 'create-program-from-pinboard-with-all-activities') {
      const activityLanguages = uniq(this.pinbord.activities.map((activity) => activity.language)) as string[];
      let chosenLanguage: string;

      if (activityLanguages.length > 1) {
        chosenLanguage = result?.selectedOption;
      } else {
        chosenLanguage = activityLanguages[0];
      }

      programParameters = {
        activityIds: this.pinbord.activities.map((activity) => activity.id),
        from,
        to,
        language: chosenLanguage,
        organizationUnitId,
        title: this.pinbord?.name,
      };
    }

    if (!isNil(programParameters)) {
      programParameters.periodType = isDayProgram ? 'DATE' : 'FREEFORM';
      program = await this.programService.createActivityProgram(programParameters);
    } else if (!isNil(program) && activities.length > 0) {
      await this._addActivitiesToProgram(program.id, null, activities);
    }

    if (isNil(program)) {
      this.$i18nToastProvider.error(_('program_creation.program.create.failed'));

      return 'program-create-failure';
    }

    if (isDayProgram) {
      await this.addAdditionalDayPeriodsToProgram(program);
    }

    this.$programDateProvider.timespan = undefined;
    this.clearSelections();

    this.router.navigate([`/organization/${organizationUnitId}/program/${program.id}/`]);

    return 'navigation';
  }

  private clearSelections(): void {
    this.programTemplate = undefined;
    this.pinbord = undefined;
    this.$activitySelectionProvider.clearSelectedActivities();
    if (this.$activitySelectionProvider.isSelecting$.value) {
      this.$activitySelectionProvider.toggleSelecting();
    }
  }

  public async promptAddActivitiesToProgram(programId: number, programName: string) {
    const activityAmount = this.$activitySelectionProvider.noOfSelectedActivities$.value;
    if (activityAmount === 0) {
      return;
    }

    const data: IChoiceDialogData = {
      title: this.$translateService.instant(_('program_creation.program.activities.add.dialog.title')),
      description: this.$translateService.instant(
        _('program_creation.program.activities.add.dialog.description'),
        { activityAmount, programName },
      ),
      actions: {
        primaryAction: {
          label: this.$translateService.instant(_('generic.add')),
          value: 'add',
        },
      },
    };

    const dialogRef = this.matDialog.open(ChoiceDialogComponent, { ...DIALOG_DIMENSIONS, data });

    const result: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (get(result, 'action') === 'add') {
      this._addActivitiesToProgram(programId);
    }
  }

  private async _addActivitiesToProgram(programId: number, periodId?: number, activities?: (ISearchActivity | IActivity)[]) {
    // Create a period to house the selected activities.
    //

    let period: IPeriod;

    if (periodId) {
      period = await this.programPeriodService.fetch(periodId);
    } else {
      period = await this.programPeriodService.create({
        program_id: programId,
        name: '',
      });
    }

    if (!isNil(period)) {
      // Add the activities to the newly created period.
      //
      await this.$activitySelectionProvider.addActivitiesToProgramPeriod(period, activities);
    } else {
      // Couldn't add period to program, therefore, activities cannot be added either.
      //
      this.$i18nToastProvider.error(_('program_creation.program.activities.add.failed'));
    }
  }

  /**
   * Creates a program using the data from the programDateService and the currentCustomerService.
   */
  private async _createProgramFromTemplate(programTemplate: IProgramTemplate) {
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    // Get the start and end values for the program from the date service.
    //
    const { start, end } = this.$programDateProvider.timespan;

    // Set the from- and to-times to 9AM to 5PM.
    // Use dayjs date formatting to pass the data in a format the backend understands.
    //
    const from = dayjs.tz(start).set('hours', 8).set('minutes', 30).toISOString();
    const to = dayjs.tz(end).set('hours', 18).toISOString();
    const countryCode = await firstValueFrom(this.$session.ouCountryCode$);

    const { name, id, language } = programTemplate;
    // Create the new program with the program template.
    //
    const programPromise = this.$programTemplateService.createProgram(
      id,
      organizationUnitId,
      {
        name, from, to, language, country_code: countryCode,
      } as IProgram,
    );

    programPromise.then(() => {
      if (countryCode.toLowerCase() === 'nl') {
        this.$programProvider.fetchUnpublishedProgramCount(organizationUnitId);
      }
    });

    return programPromise;
  }

  private prepareDateMessage(isPickingDate: boolean): string {
    if (isPickingDate) {
      this.$translateService.instant(_('program_creation.date.on_calendar'));
    }

    if (!this.$programDateProvider.timespan) {
      return this.$translateService.instant(_('program_creation.date.without_date'));
    }

    const { end, start } = this.$programDateProvider.timespan;

    const from = start.locale(this.$translateService.currentLang).format('DD-MM-YYYY');
    const to = end.locale(this.$translateService.currentLang).format('DD-MM-YYYY');
    const hourDiff = end.diff(start, 'hours');

    return hourDiff >= 24
      ? this.$translateService.instant(_('program_creation.date.interval'), { from, to })
      : this.$translateService.instant(_('program_creation.date.on_day'), { from });
  }

  /*
  * This is a function to add day periods to a program. It will return the id of the first created period so that we could hang any activities that
  * we want to that period. (as used in flow 1.C)
  */
  private async addAdditionalDayPeriodsToProgram(program: IProgram) {
    const programStartDate = dayjs(program.from).utc();
    const programEndDate = dayjs(program.to).utc();
    const programLengthInHours = programEndDate.diff(programStartDate, 'hours');
    const programPeriods: IPeriod[] = [];

    if (programLengthInHours >= 48) {
      const programLengthInDays = Math.floor(programLengthInHours / 24);
      // the first day is covered by the api route for create activity program so we can start the loop at 1
      // so that we skip the first day
      //
      for (let i = 1; i <= programLengthInDays; i++) {
        const newStartDate = cloneDeep(programStartDate).add(i, 'days').set('hour', 8).set('minutes', 30);
        const newEndDate = cloneDeep(programStartDate).add(i, 'days').set('hour', 18).set('minutes', 0);
        const weekDayNumber = newStartDate.day();

        const isWeekendDay = weekDayNumber === 6 || weekDayNumber === 0; // dayjs marks 6 as Saturday and 0 as Sunday

        if (!isWeekendDay) {
          const newStartDateString = newStartDate.format('YYYY-MM-DDTHH:mm:ssZ');
          const newEndDateString = newEndDate.format('YYYY-MM-DDTHH:mm:ssZ');

          const period: IPeriod = {
            program_id: program.id as number,
            name: '',
            start_date: newStartDateString,
            end_date: newEndDateString,
            period_type: 'DATE',
            order: i + 1,
          };

          programPeriods.push(period);
        }
      }
    }

    if (programPeriods.length > 0) {
      let firstProgramPeriodId: number | null = null;
      const promises: Promise<IPeriod>[] = [];

      for (const period of programPeriods) {
        promises.push(this.programPeriodService.create(period));
      }

      try {
        const createdPeriods = await Promise.all(promises);
        firstProgramPeriodId = createdPeriods?.shift().id;
      } catch (e) {
        this.$i18nToastProvider.error(_('program_creation.period.add.failed'));
      }

      return firstProgramPeriodId;
    }
  }
}
