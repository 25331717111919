import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IJibbieOrganizationUnitActivity } from 'typings/doenkids/doenkids';

@Injectable({ providedIn: 'root' })
export class JibbieActivityService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  async getActivityOrganizationLink(activityId: number, organizationUnitId: number) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericGetCallWithoutErrorHandler(
        `/customer/jibbie/activity/${activityId}/link/${organizationUnitId}`,
      )) as IJibbieOrganizationUnitActivity;
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async linkWithOrganization(activityId: number, organizationUnitId: number) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericPutCall(
        `/customer/jibbie/activity/${activityId}/link/${organizationUnitId}`,
      )) as IJibbieOrganizationUnitActivity;
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async unlinkWithOrganization(activityId: number, organizationUnitId: number) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericDeleteCall(
        `/customer/jibbie/activity/${activityId}/unlink/${organizationUnitId}`,
      )) as IJibbieOrganizationUnitActivity;
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
