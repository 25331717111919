import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IKonnectGroup } from 'typings/doenkids/doenkids';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IKonnectGroupListResponse } from 'typings/api-customer';
import { KonnectGroupStore } from './konnect-group.store';
import { firstValueFrom } from 'rxjs';
import { IListRequest } from 'typings/api-activity';

@Injectable({ providedIn: 'root' })
export class KonnectGroupService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: KonnectGroupStore) {}

  @transaction()
  async fetchAll(organizationUnitId: number, params: IListRequest) {
    let url = '/customer/konnect/group';
    url = this.$baseApi.appendQueryParam(url, 'organization_unit_id', organizationUnitId);

    const response = await firstValueFrom(this.$baseApi.genericPostCall(url, params)) as IKonnectGroupListResponse;
    this.store.add(response?.items ?? []);
    return response;
  }

  @transaction()
  async fetch(groupId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/konnect/group/${groupId}`)) as IKonnectGroup;
    if (response) {
      this.store.update(response);
    }
    return response;
  }

  async setOrganizationUnit(groupId: number, organizationUnitId: number) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/konnect/group/${groupId}/link/${organizationUnitId}`,
      null)) as IKonnectGroup;
    if (response) {
      this.store.upsert(response.id, response);
    }
    return response;
  }

  async unsetOrganizationUnit(groupId: number) {
    const response = await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/konnect/group/${groupId}/link`)) as IKonnectGroup;
    if (response) {
      this.store.upsert(response.id, response);
    }
    return response;
  }
}
