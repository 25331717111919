import { Component, ElementRef, Input, ViewChild, AfterViewInit, Output, EventEmitter, ViewEncapsulation, HostBinding, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'app-review-rating',
  templateUrl: './review-rating.component.html',
  styleUrls: ['./review-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewRatingComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('ratingContainer') ratingContainer: ElementRef;

  @ViewChild('ratingBar') ratingBar: ElementRef;

  @Input() editable = true;

  @Input() value = 0;

  @Input() height = '27px';

  @Output() valueChange = new EventEmitter();

  @HostBinding('style') styleBinding;

  public currentlyRating = this.editable;

  public hovering = false;

  public width = 1;

  public _viewValue = this.value * 20;

  public animating = false;

  private animationDebouncedFunc;

  private animationTimeout;

  ngOnInit(): void {
    this.styleBinding = `--height: ${this.height}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.currentValue !== changes.value.previousValue) {
      this.setRatingValue();
      this.playShineAnimation();
    }
  }

  ngAfterViewInit(): void {
    // Setting a timeout creates a nice animation with the infinite scroll
    setTimeout(() => this.setRatingValue(), 500);
    this.width = this.ratingContainer.nativeElement.getBoundingClientRect().width;
  }

  ratingHover(event: MouseEvent) {
    if (!this.currentlyRating || !this.editable) {
      return;
    }
    this.hovering = true;

    const target = (event.target as HTMLDivElement);
    const rect = target.getBoundingClientRect();

    const x = event.x - rect.x;
    const percentage = (x / this.width) * 100;

    const rounded = Math.max(Math.ceil(percentage / 10) * 10, 20);

    this._viewValue = rounded;
    this.ratingBar.nativeElement.style.width = `${rounded}%`;
  }

  rate(event: MouseEvent) {
    if (!this.editable) {
      return;
    }

    this.currentlyRating = true;
    this.ratingHover(event);

    this.currentlyRating = false;
    this.value = this._viewValue;
    this.valueChange.emit(this.value);

    this.playShineAnimation();
  }

  mouseLeave() {
    this.hovering = false;

    if (!this.editable) {
      return;
    }
    this.setRatingValue();
  }

  setRatingValue() {
    this.valueChange.emit(this.value);

    if (this.ratingBar?.nativeElement) {
      this.ratingBar.nativeElement.style.width = `${this.value}%`;
    }
  }

  // Manually handling the animation logic because angular doesn't support
  // animating pseudo-elements
  //
  playShineAnimation() {
    if (!this.ratingBar?.nativeElement) {
      return;
    }

    this.animationDebouncedFunc?.cancel();
    this.ratingBar.nativeElement.classList.remove('shine');
    clearTimeout(this.animationTimeout);

    this.animationDebouncedFunc = debounce(() => {
      this.ratingBar.nativeElement.classList.add('shine');

      this.animationTimeout = setTimeout(() => {
        this.ratingBar.nativeElement.classList.remove('shine');
      }, 1000);
    });

    this.animationDebouncedFunc();
  }
}
