import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, firstValueFrom, takeUntil } from 'rxjs';
import { TagListQuery } from 'src/api/activity/tag-list/tag-list.query';
import { TagListService } from 'src/api/activity/tag-list/tag-list.service';
import { TagService } from 'src/api/activity/tag/tag.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { IOrganizationUnitTagAll } from 'typings/doenkids/doenkids';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MatChipInputEvent } from '@angular/material/chips';
import { isArray, isNil, isObject } from 'lodash';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  private stop$ = new Subject<void>();

  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public tagToEdit: IOrganizationUnitTagAll | null = null;

  public tagInputControl = new FormControl('');

  // The tags are initially loaded from the doenkids session provider on ou load
  // on add or remove we call the tags again from this component
  //
  public tags$: Observable<IOrganizationUnitTagAll[]>;

  public tagMaxLength = 20;

  public selectedOUId$: BehaviorSubject<number>;

  private isAddingTag$ = new BehaviorSubject<boolean>(false);

  private pageLoadDate = new Date();

  constructor(
    private tagListService: TagListService,
    private tagListQuery: TagListQuery,
    private tagService: TagService,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    private dialog: MatDialog,
    private $session: DoenkidsSessionProvider,
  ) {
    this.tags$ = this.tagListQuery.selectAll().pipe(takeUntil(this.stop$));
    this.selectedOUId$ = new BehaviorSubject<number>(null);
    this.$session.currentOuId$.pipe(takeUntil(this.stop$)).subscribe((selectedOUId) => {
      this.selectedOUId$.next(selectedOUId);
    });
  }

  ngOnInit() {
    this.tagInputControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((newTagValue) => {
      if (!this.isAddingTag$.value && newTagValue === '' && this.tagToEdit !== null) {
        this.tagToEdit = null;
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  _fetchAllTags(organizationUnitId: number) {
    return this.tagListService.fetchAll(organizationUnitId, 1000, 0);
  }

  async add(event: MatChipInputEvent) {
    this.isAddingTag$.next(true);
    const { value } = event;

    if (value) {
      if (this.tagToEdit) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          minWidth: '320px',
          data: {
            title: this.$translateService.instant(_('generic.confirm')),
            description: this.$translateService.instant(_('settings_overview.tag.edit.confirm'), { tagName: this.tagToEdit.name, value }),
          },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result === 'confirm') {
            await this.tagService.update({ ...this.tagToEdit, name: value });
            this.tagInputControl.setValue('');
            this.tagToEdit = null;
            this._fetchAllTags(this.selectedOUId$.value);
          }
        });
      } else {
        const tagNameAlreadyExists = await this._checkTagnameAlreadyExist(value);
        if (tagNameAlreadyExists) {
          this.$i18nToastProvider.error(_('settings_overview.tag.already_exists'));
        } else {
          const response = await this.tagService.create(this.selectedOUId$.value, value);

          if (isNil(response)) {
            console.error('[SETTINGS]: Error while trying to create new tag with name', value);
            return;
          }
          this._fetchAllTags(this.selectedOUId$.value);
        }
      }
    }

    // Reset the input value
    //
    if (this.tagInputControl.value !== '') {
      this.tagInputControl.setValue('');
    }
    this.isAddingTag$.next(false);
  }

  edit(tag: IOrganizationUnitTagAll) {
    this.tagToEdit = tag;
    this.tagInputControl.setValue(tag.name);
  }

  remove(tag: IOrganizationUnitTagAll) {
    if (isObject(tag)) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('generic.confirm')),
          description: this.$translateService.instant(_('settings_overview.tag.remove.confirm.description'), { name: tag.name }),
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === 'confirm') {
          await this.tagService.remove(tag.id);
          this._fetchAllTags(this.selectedOUId$.value);
        }
      });
    }
  }

  isJustCreated(tag: IOrganizationUnitTagAll) {
    if (isObject(tag)) {
      return this.pageLoadDate < new Date(tag.created_at);
    }
    return false;
  }

  isTagOfCurrentOU(tag: IOrganizationUnitTagAll) {
    return tag.organization_unit_id === this.selectedOUId$.value;
  }

  async _checkTagnameAlreadyExist(tagName: string) {
    const tags = await firstValueFrom(this.tags$);
    let existInTagsList = false;
    if (tagName && isArray(tags)) {
      if (!isNil(tags.find((tag: IOrganizationUnitTagAll) => tag.name.toLowerCase() === tagName.toLowerCase()))) {
        existInTagsList = true;
      }
    }
    return existInTagsList;
  }

}
