import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { isNil } from 'lodash';

export interface ITimespan {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

@Injectable({
  providedIn: 'root',
})
export class ProgramDateProvider {
  private _timespan: ITimespan;

  public set timespan(timespan: ITimespan) {
    if (!isNil(timespan)) {
      this._timespan = timespan;
    } else {
      this._timespan = undefined;
    }
  }

  public get timespan(): ITimespan {
    return this._timespan;
  }
}
