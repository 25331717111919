<app-doenkids-menu-bar [showBackButton]="true"></app-doenkids-menu-bar>
<mat-toolbar class="doenkids-toolbar">
  <h2>{{ 'user_activity.heading' | translate }}</h2>
  <span class="spacer"></span>
  <span>{{ 'user_activity.last_change' | translate: {lastUpdated: ((lastUpdatedDisplay$ | async) | from_now | async)} }}</span>
</mat-toolbar>

<app-sortable-list
  class="not-selectable"
  (reload)="getList($event)"
  [fields]="fields$ | async"
  [list$]="list$ | async"
  [metadata]="metaData$ | async"
  sortField="last_seen"
  [defaultLimit]="SEARCH_LIMIT"
>
  <ng-container *appSortableListFields="let list">
    <ng-container *ngIf="!(loading$ | async)">
      <tr *ngFor="let event of list | paginate: ( paginationConfig$ | async)">
        <td [fxHide.lt-md]="true">
          <div class="cell-wrapper">{{ event.created_at | from_now | async }}</div>
        </td>
        <td [fxHide.lt-md]="true">
          <div class="cell-wrapper"><a [routerLink]="['/organization/' + event.organization_unit_id + '/overview']">{{event.organization_unit_name }}</a></div>
        </td>
        <td>
          <div class="cell-wrapper">
            <span class="event-type">{{event.event_type | translate}}</span>
            <div class="event-link cell-wrapper" *ngIf="event.activity_id">
              <a [routerLink]="['/activities/preview/' + event.activity_id]"><mat-icon>filter_none</mat-icon>{{event.activity_id}}</a>
            </div>
            <div class="event-link cell-wrapper" *ngIf="event.program_id">
              <a [routerLink]="['/organization/' + event.organization_unit_id + '/program/' + event.program_id]"><mat-icon>calendar_today</mat-icon>{{event.program_id}}</a>
            </div>
          </div>
        </td>
        <td>
          <div class="cell-wrapper">{{event.name}}, status code: {{event.response}}</div>
        </td>
      </tr>
      <tr *ngIf="list.length === 0">
        <td [attr.colspan]="(fields$ | async).length">
          <div class="cell-wrapper">{{ 'user_activity.no_results' | translate }}</div>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="(loading$ | async)">
      <tr>
        <td [attr.colspan]="(fields$ | async).length">
          <div class="cell-wrapper">{{ 'user_activity.loading' | translate }}</div>
        </td>
      </tr>
    </ng-container>
  </ng-container>
</app-sortable-list>
