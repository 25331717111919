import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { AssetService } from 'src/api/media/asset/asset.service';
import { TPurpose } from 'src/components/shared/asset-upload/asset-upload.component';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface IReplaceImageDialogData {
  identifier: string;
  purpose?: TPurpose;
  currentImageUuid: string;
}

@Component({
  selector: 'app-replace-image-dialog-component',
  templateUrl: './replace-image-dialog-component.component.html',
  styleUrls: ['./replace-image-dialog-component.component.scss'],
})
export class ReplaceImageDialogComponentComponent implements OnDestroy {
  private stop$ = new Subject<void>();

  public newImage$ = new BehaviorSubject<File>(null);

  public isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<ReplaceImageDialogComponentComponent>,
    public matDialog: MatDialog,
    private $asset: AssetService,
    private $i18nToastProvider: I18nToastProvider,
    @Inject(MAT_DIALOG_DATA) public data: IReplaceImageDialogData,
  ) {
    this.isLoading$.pipe(takeUntil(this.stop$)).subscribe((isLoading) => {
      dialogRef.disableClose = isLoading;
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  onClose() {
    this.dialogRef.close();
  }

  async onConfirm() {
    try {
      this.isLoading$.next(true);
      const newMediaItem = await this.$asset.replace(this.data.currentImageUuid, this.newImage$.value);
      this.$i18nToastProvider.success(_('image.replace.dialog.success'));
      this.isLoading$.next(false);
      this.dialogRef.close(newMediaItem);
    } catch (e) {
      this.$i18nToastProvider.error(_('image.replace.dialog.failure'));
      this.isLoading$.next(false);
    }
  }

  setNewImage(uploadedImage: File) {
    this.newImage$.next(uploadedImage);
  }
}
