import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { IAttachment } from 'typings/doenkids/doenkids';

export interface IDoenkidsAttachmentState extends EntityState<IAttachment> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'attachment' })
export class AttachmentStore extends EntityStore<IDoenkidsAttachmentState, IAttachment> {
  constructor() {
    super([]);
  }
}
