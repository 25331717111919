<div class="save-button-container" fxLayout="reverse-row" fxLayoutAlign="space-between center">
  <button
    *ngIf="(hasKonnectDetails$ | async)"
    mat-button
    (click)="syncLocationsAndGroups()"
    [disabled]="(konnectIsSyncing$ | async)"
  >
    <mat-icon>sync</mat-icon>
    {{ 'konnect_form.synchronize' | translate }}
  </button>
  <button
    mat-button
    class="save-button"
    type="submit"
    [disabled]="konnectOrganizationform.invalid"
    (click)="saveDetails()"
    [matTooltip]="'konnect_form.save.tooltip' | translate"
  >
    <mat-icon>save</mat-icon>
    {{ 'konnect_form.save' | translate }}
  </button>
</div>
<div class=wrapper>
  <h2>{{ 'konnect_form.title' | translate }}</h2>
  <p>{{ 'konnect_form.description' | translate }}</p>
</div>
<form [formGroup]="konnectOrganizationform">
  <div class="konnect-form">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'konnect.endpoint.label' | translate }}</mat-label>
      <input formControlName="endpoint" matInput [placeholder]="'konnect.endpoint.placeholder' | translate">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'konnect.customer_key.label' | translate }}</mat-label>
      <input formControlName="customerKey" matInput>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'konnect.username.label' | translate }}</mat-label>
      <input formControlName="username" matInput [placeholder]="usernamePlaceholder">
      <mat-hint>{{usernamePlaceholder}}</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ 'konnect.credentials.label' | translate }}</mat-label>
      <input type="password" formControlName="credentials" matInput [placeholder]="passwordPlaceholder">
      <mat-hint>{{passwordPlaceholder}}</mat-hint>
    </mat-form-field>
  </div>
</form>
<app-connecting-animation *ngIf="(konnectIsSyncing$ | async)"></app-connecting-animation>
