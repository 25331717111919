import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, EventType, Router } from '@angular/router';
import { filter, map, withLatestFrom } from 'rxjs';
import { EventLogService } from 'src/api/customer/event-log/event-log.service';
import { IUserEventPayload } from 'typings/api-customer';
import { DoenkidsSessionProvider } from './session.provider';

@Injectable({ providedIn: 'root' })
export class NavigationEventsProvider {
  constructor(private router: Router, private $eventLogService: EventLogService, private activatedRoute: ActivatedRoute, private location: Location, private session: DoenkidsSessionProvider) {

  }

  public trackNavigationEvents() {
    this.router.events.pipe(
      filter(event => event.type === EventType.NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        // Get current route
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      withLatestFrom(this.session.getOrganizationUnit$),
    ).subscribe(([route, organizationUnit]) => {
      const { data, params: { id } } = route.snapshot;

      let eventType: IUserEventPayload['eventType']  = 'NAVIGATE';

      switch (data.state) {
        case 'activity-preview':
          eventType = 'ACTIVITY_VIEW';
          break;
        case 'program-details':
          eventType = 'PROGRAM_VIEW';
          break;
        case 'program-template-details':
          eventType = 'PROGRAM_TEMPLATE_VIEW';
          break;
        case 'program-template-bundle-details':
          eventType = 'PROGRAM_TEMPLATE_BUNDLE_VIEW';
          break;
      }

      this.$eventLogService.userEvent({
        eventType,
        subjectId: id,
        path: this.router.url,
        pageName: data.state,
        organizationUnitId: organizationUnit.id,
      });
    });
  }
}
