<form [formGroup]="form">
  <mat-accordion class="mat-primary">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'activity.details.section_title' | translate }}</mat-panel-title>
        <mat-panel-description>
          {{ 'activity.details.section_description' | translate: { title: form.get('name').value } }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'activity.title.label' | translate }}</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            formControlName="name"
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="3"
            [placeholder]="'activity.title.placeholder' | translate"
          ></textarea>
          <mat-error *ngIf="form.get('name').hasError('required')">{{ 'generic.form.required_field' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ 'activity.subtitle.label' | translate }}</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            formControlName="subtitle"
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="3"
            [placeholder]="'activity.subtitle.placeholder' | translate"
          ></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="column" class="tags-section">
        <mat-label class="title-with-info">
          {{ 'activity.tags.label' | translate }}
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'tags')">info</mat-icon>
        </mat-label>
        <tag-input
          formControlName="tags"
          [onlyFromAutocomplete]="true"
          displayBy="name"
          identifyBy="id"
          [placeholder]="'activity.tags.placeholder' | translate"
          secondaryPlaceholder="{{ 'activity.tags.add' | translate }}"
        >
          <tag-input-dropdown
            [showDropdownIfEmpty]="true"
            [autocompleteItems]="allTags$ | async"
            displayBy="name"
            identifyBy="id"
            [keepOpen]="false"
            [appendToBody]="false"
          >
            <ng-template let-item="item" let-index="index">
              <div class="tag">
                <mat-icon *ngIf="item.organization_unit_id === (currentOUId$ | async)">push_pin</mat-icon>
                <span>
                  {{ item.name }}
                </span>
              </div>
            </ng-template>
          </tag-input-dropdown>
        </tag-input>
      </div>

      <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'activity.duration.label' | translate }}</mat-label>
              <mat-select placeholder="{{ 'activity.duration.placeholder' | translate }}" formControlName="duration">
                <mat-option *ngFor="let minutes of [10, 15, 20, 30, 45, 60, 90, 120]" [value]="minutes">
                  {{ 'generic.no_of_minutes' | translate: { minutes: this.minutes } }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-icon class="info-icon" (click)="openInfoWindow($event, 'duration')">info</mat-icon>
          </div>
          <div fxLayout="row">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>{{ 'activity.preparation_time.label' | translate }}</mat-label>
              <mat-select [placeholder]="'activity.preparation_time.placeholder' | translate" formControlName="preparation">
                <mat-option [value]="0">{{ 'generic.not_applicable' | translate }}</mat-option>
                <mat-option *ngFor="let minutes of [5, 10, 15, 20, 30, 45, 60, 90, 120]" [value]="minutes">
                  {{ 'generic.no_of_minutes' | translate: { minutes: this.minutes } }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-icon class="info-icon" (click)="openInfoWindow($event, 'preparation_time')">info</mat-icon>
          </div>
        </div>

        <div fxFlex="50" [fxFlex.lt-lg]="100">
          <mat-label>{{ 'activity.activity_type.label' | translate }}</mat-label>
          <mat-selection-list class="activity-type" [compareWith]="compareWith" formControlName="activityTypes" role="listbox">
            <ng-container *ngIf="(availableActivityTypes$ | async)?.length > 0">
              <ng-container *ngFor="let activityType of availableActivityTypes$ | async">
                <mat-list-option [value]="activityType">
                  <span matListItemTitle class="option-label">{{ activityType.name }}</span>
                </mat-list-option>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="(availableActivityTypes$ | async)?.length === 0">
              <ng-container *ngFor="let type of allActivityTypes$ | async">
                <mat-list-option [value]="type">
                  <span matListItemTitle class="option-label">{{ type.name }}</span>
                </mat-list-option>
              </ng-container>
            </ng-container>
          </mat-selection-list>
        </div>
      </div>
      <div class="summary-location-section" fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
        <div fxLayout="row" fxFlex="50" [fxFlex.lt-lg]="100">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'activity.summary.label' | translate }}</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              formControlName="summary"
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="5"
              [placeholder]="'activity.summary.placeholder' | translate"
            >
            </textarea>
            <mat-error *ngIf="form.get('summary').hasError('required')">
              {{ 'generic.form.required_field' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'summary')">info</mat-icon>
        </div>
        <div fxFlex="40" [fxFlex.lt-lg]="100">
          <mat-label>{{ 'activity.location.label' | translate }}</mat-label>
          <mat-selection-list [compareWith]="compareWith" class="activity-location" formControlName="location" role="listbox">
            <ng-container *ngFor="let location of allLocations$ | async">
              <mat-list-option [value]="location">
                <span matListItemTitle class="option-label">{{ location.name | doenkidsTranslate: 'activity.location' }}</span>
              </mat-list-option>
            </ng-container>
          </mat-selection-list>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- Cover image
    -->
    <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)">
      <mat-expansion-panel-header>
        <mat-panel-title class="title-with-info">
          {{ 'activity.cover_image.title' | translate }}
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'cover_image')">info</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-asset-upload identifier="cover" purpose="activity" (uploaded)="addFeaturedImage($event)" [disabled]="(allowedToEdit$ | async) === false">
          <ng-template let-uploadData="uploadData">
            <div class="image-container">
              <app-doenkids-image [maxWidth]="200" [maxHeight]="400" [preview]="true" [showOriginType]="true" [mediaItem]="coverImage$ | async" [canReplaceImage]="isAdmin$ | async"></app-doenkids-image>
            </div>
            <app-select-media
              [mediaItemUuid]="(coverImage$ | async)?.uuid"
              [uploadData]="uploadData"
              identifier="cover"
              purpose="activity"
              (selected)="updateFeaturedImage($event)"
              [disabled]="(allowedToEdit$ | async) === false"
            ></app-select-media>
          </ng-template>
        </app-asset-upload>
      </ng-template>
    </mat-expansion-panel>

    <!-- Age groups
    -->
    <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'activity.age_groups.title' | translate }}
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'age_groups')">info</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'activity.age_groups.selection_count' | translate: { selectionCount: this.form.get('agegroups').value.length } }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div fxFlex fxLayout="column">
          <app-weighted-value-sliders
            #ageGroup
            translationPrefix="activity.age_group"
            [adjustOnChange]="false"
            (saveRequested)="saveAgeGroups()"
            formControlName="agegroups"
            [options]="availableAgeGroups$ | async"
          >
          </app-weighted-value-sliders>
          <button [disabled]="!form.get('agegroups').valid" (click)="saveAgeGroups()" color="primary" mat-button type="button">
            {{ 'generic.save' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <!-- Group sizes
    -->
    <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'activity.group_size.title' | translate }}</mat-panel-title>
        <mat-panel-description>
          {{ 'activity.group_size.selection_count' | translate: { selectionCount: this.form.get('groupsizes').value.length } }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="group-sizes">
          <mat-selection-list class="group-size-list" [compareWith]="compareWith" #groupSize formControlName="groupsizes">
            <mat-list-option *ngFor="let groupSize of allGroupSizes$ | async" [value]="groupSize">
              {{ groupSize.name | doenkidsTranslate: 'activity.group_size' }}
            </mat-list-option>
          </mat-selection-list>
          <button (click)="saveGroupSizes()" color="primary" mat-button type="button">{{ 'generic.save' | translate }}</button>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <!-- Activity kinds
    -->
    <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'activity.activity_kind.title' | translate }}
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'activity_kind')">info</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'activity.activity_kind.selection_count' | translate: { selectionCount: this.form.get('activitykinds').value.length } }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div fxFlex fxLayout="column">
          <app-weighted-value-sliders
            #activityKind
            formControlName="activitykinds"
            translationPrefix="activity.activity_kind"
            [adjustOnChange]="true"
            [options]="allActivityKinds$ | async"
          >
          </app-weighted-value-sliders>
          <button [disabled]="!form.get('activitykinds').valid" (click)="saveActivityKinds()" color="primary" mat-button type="button">
            {{ 'generic.save' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <!-- Areas of development
    -->
    <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'activity.area_of_development.label' | translate }}
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'area_of_development')">info</mat-icon>
        </mat-panel-title>
        <mat-panel-description>
          {{ 'activity.area_of_development.selection_count' | translate: { selectionCount: this.form.get('developmentareas').value.length } }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div fxFlex fxLayout="column">
          <app-weighted-value-sliders
            #developmentArea
            formControlName="developmentareas"
            translationPrefix="activity.area_of_development"
            [options]="allAreaOfDevelopment$ | async"
          >
          </app-weighted-value-sliders>
          <button [disabled]="!form.get('developmentareas').valid" (click)="saveAreaOfDevelopment()" color="primary" mat-button type="button">
            {{ 'generic.save' | translate }}
          </button>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <ng-container *ngIf="(ageGroupsSelected$ | async) && (availableRanges$ | async); let availableRanges">
      <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'activity.development_areas.title' | translate }}</mat-panel-title>
          <mat-panel-description>
            {{ 'activity.development_ranges.selection_count' | translate: { selectionCount: this.form.get('developmentranges').value.length } }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="errors" *ngIf="!(ageGroupsSelected$ | async) || !(this.form.get('developmentareas').value.length > 0)">
            <div class="error" *ngIf="!(ageGroupsSelected$ | async)">
              {{ 'activity.age_groups.error.none_selected' | translate }}
            </div>
            <div class="error" *ngIf="!(this.form.get('developmentareas').value.length > 0)">
              {{ 'activity.development_areas.error.none_selected' | translate }}
            </div>
            <div class="error">{{ 'activity.save_changes_first' | translate }}</div>
          </div>
          <div fxFlex fxLayout="column" *ngIf="(ageGroupsSelected$ | async) && availableRanges.length > 0">
            <mat-selection-list fxFlex fxLayout="column" #developmentRange [compareWith]="compareWith" formControlName="developmentranges">
              <ng-container *ngFor="let range of availableRanges; let index = index">
                <h3 *ngIf="index === 0 || availableRanges[index - 1]?.area_of_development !== range.area_of_development">
                  {{ range.area_of_development | doenkidsTranslate: 'activity.area_of_development' }}
                </h3>
                <mat-list-option [value]="range">
                  {{ range.name | doenkidsTranslate: 'activity.range_of_development' }}
                </mat-list-option>
              </ng-container>
            </mat-selection-list>
            <button (click)="saveRangeOfDevelopment(); saveAreaOfDevelopment()" color="primary" mat-button type="button">
              {{ 'generic.save' | translate }}
            </button>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="(kvsMethodsToShow$ | async)?.length > 0">
      <mat-expansion-panel class="kvs-methods" [expanded]="step === 7" (opened)="setStep(7)">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'activity.kvs.title' | translate }}</mat-panel-title>
          <mat-panel-description *ngIf="getActiveKvsControlCount() as activityObservationPointsCount">
            {{ 'activity.kvs.selection_count' | translate: { selectionCount: activityObservationPointsCount } }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <ng-container *ngIf="getActiveKvsControlCount() > 0">
            <h4 class="subheading-1">{{ 'activity.kvs.linked' | translate }}</h4>
            <mat-list role="list" class="selected-kvs-method-list">
              <ng-container *ngFor="let activeKvsGroup of getActiveKvsNames() | keyvalue">
                <div mat-subheader>{{ activeKvsGroup.key }}</div>
                <ng-container *ngFor="let activeKvsPoint of activeKvsGroup.value">
                  <mat-list-item (click)="goToKvsPoint(activeKvsPoint?.code)" class="kvs-selected-item">
                    <mat-icon matListItemIcon>checked</mat-icon>
                    <p matListItemTitle>{{ activeKvsPoint?.title }}</p>
                  </mat-list-item>
                  <mat-divider></mat-divider>
                </ng-container>
              </ng-container>
            </mat-list>
          </ng-container>
          <h4 class="subheading-1">{{ 'activity.kvs.add' | translate }}</h4>
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node; when: !hasChild" [matTreeNodePadding] [matTreeNodePaddingIndent]="20">
              <div class="slider-div {{ node.code }}" *ngIf="getKvsControl(node.code) as kvsControl">
                <span class="observation-point-title">
                  <ng-container *ngIf="node.instructions">
                    <mat-icon class="info-icon" (click)="openInfoWindow($event, 'kvs', { title: node.name, description: node.instructions })">info</mat-icon>
                  </ng-container>
                  <span>{{ node.name }}</span>
                </span>
                <div class="slider">
                  <mat-chip-listbox>
                    <mat-chip-option>
                      {{ kvsControl.value | i18n_number: '1.0-2' | async }}
                    </mat-chip-option>
                  </mat-chip-listbox>
                  <mat-slider #sliderControl color="primary" (mousedown)="sliderClicked(kvsControl.disabled, node.code)" [step]="10"
                    ><input matSliderThumb [formControl]="kvsControl"
                  /></mat-slider>
                </div>
                <mat-checkbox [checked]="kvsControl.enabled" (change)="kvsMethodToggle(node.code)"></mat-checkbox>
              </div>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" [matTreeNodePadding] [matTreeNodePaddingIndent]="20">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
              </button>
              <span *ngIf="node.disabled" matTreeNodeToggle class="kvs-name">{{ node.name }}</span>
            </mat-tree-node>
          </mat-tree>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button (click)="saveKvsMethods()" color="primary" mat-button type="button">
              {{ 'generic.save' | translate }}
            </button>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel [expanded]="step === 8" (opened)="setStep(8)">
      <mat-expansion-panel-header>
        <mat-panel-title class="title-with-info">
          {{ 'activity.attachments.title' | translate }}
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'attachments')">info</mat-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-asset-upload identifier="attachment" purpose="attachment" (uploaded)="addAttachment($event)" [disabled]="(allowedToEdit$ | async) === false">
          <ng-template let-uploadData="uploadData">
            <div class="attachment-container" cdkDropList (cdkDropListDropped)="dropAttachment($event)">
              <div *ngFor="let attachment of activityAttachments$ | async" class="attachment" cdkDrag>
                <app-drag-handle cdkDragHandle></app-drag-handle>
                <div class="attachment-content-container">
                  <mat-icon class="attachment-icon">insert_drive_file</mat-icon>
                  <a href="javascript:void(0)" (click)="openAttachment(attachment)">
                    <span>{{ attachment['filename'] }}</span>
                  </a>
                </div>
                <button mat-icon-button type="button" (click)="removeAttachment(attachment)">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>

            <app-select-media
              [uploadData]="uploadData"
              identifier="attachment"
              purpose="attachment"
              [disabled]="(allowedToEdit$ | async) === false"
            ></app-select-media>
          </ng-template>
        </app-asset-upload>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</form>
