import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';
import { Saver, SAVER } from './file-save.provider';

export interface DownloadQueueItem {
  url?: string;
  method?: 'GET' | 'POST';
  blob?: Blob;
  headers?: HttpHeaders;
  name: string;
  id: string;
  requestBody?: any;
}

export interface Download {
  content: Blob | null;
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

@Injectable({ providedIn: 'root' })
export class DoenkidsFileDownloader {
  public downloadQueue$: BehaviorSubject<DownloadQueueItem[]> = new BehaviorSubject([]);

  public getDownloadQueue$() {
    return this.downloadQueue$;
  }

  public removeDownload(id: string) {
    const currentQueue = this.downloadQueue$.value ?? [];
    this.downloadQueue$.next(currentQueue.filter((item) => item.id !== id));
  }

  public addDownload({
    name, url, blob, headers, method = 'GET', requestBody,
  }: { name: string, url?: string, requestBody?: any, blob?: Blob, headers?: HttpHeaders, method?: 'GET' | 'POST'}) {
    const currentQueue = this.downloadQueue$.value ?? [];
    const newDownloadQueueItem: DownloadQueueItem = {
      name,
      url,
      blob,
      headers,
      method,
      id: uuidv4(),
      requestBody,
    };

    currentQueue.unshift(newDownloadQueueItem);
    this.downloadQueue$.next(currentQueue);
  }

  public isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  public isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress
    );
  }

  public downloadFile(downloadItem: DownloadQueueItem): Observable<Download> {
    let request;

    if (downloadItem.method === 'GET') {
      request = this.http.get(downloadItem.url, {
        headers: downloadItem.headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      });
    } else if (downloadItem.method === 'POST') {
      request = this.http.post(downloadItem.url, downloadItem.requestBody ?? '', {
        headers: downloadItem.headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      });
    }

    return request.pipe(this.download((blob) => this.save(blob, downloadItem.name)));
  }

  public downloadBlob(blob: Blob, filename: string): Observable<Download> {
    this.save(blob, filename);
    return of({
      progress: 100,
      state: 'DONE',
      content: blob,
    });
  }

  private download(saver?: (b: Blob) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    return (source: Observable<HttpEvent<Blob>>) => source.pipe(
      scan(
        (download: Download, event): Download => {
          if (this.isHttpProgressEvent(event)) {
            return {
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : download.progress,
              state: 'IN_PROGRESS',
              content: null,
            };
          }
          if (this.isHttpResponse(event)) {
            if (saver) {
              saver(event.body);
            }
            return {
              progress: 100,
              state: 'DONE',
              content: event.body,
            };
          }
          return download;
        },
        { state: 'PENDING', progress: 0, content: null },
      ),
      distinctUntilChanged((a, b) => a.state === b.state
        && a.progress === b.progress
        && a.content === b.content),
    );
  }

  constructor(
    private http: HttpClient,
    @Inject(SAVER) private save: Saver,
  ) {}
}
