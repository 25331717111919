<h3 mat-dialog-title>
  {{ 'dialog.add_organization.title' | translate: {
    organizationUnitType: data.organizationUnitType | ou_type_name | translate,
    parentOrganizationName: data.parentOU?.name || undefined
  } }}
</h3>
<mat-dialog-content class="add-organization-dialog-content" scrollEnd (endReached)="scrollEndReached()">
  <mat-spinner class="center-horizontal" *ngIf="loading$ | async"></mat-spinner>
  <mat-tab-group
    [(selectedIndex)]="selectedTab"
    *ngIf="!(loading$ | async) && (data.organizationUnitType === EOrganizationUnitType.LOCATION || konnectDetails || jibbieDetails)"
    mat-stretch-tabs="false"
    mat-align-tabs="start"
  >
    <mat-tab *ngIf="(isAdmin$ | async) && data.organizationUnitType === EOrganizationUnitType.LOCATION" [label]="'dialog.add_organization.lrk_location.label' | translate">
      <form [formGroup]="lrkSearch" text-center>
        <mat-form-field class="org-location" appearance="outline">
          <mat-label>{{ 'dialog.add_organization.lrk_location.label' | translate }}</mat-label>
          <input formControlName="name" matInput (keydown.enter)="$event.preventDefault()" />
        </mat-form-field>

        <div class="lrk-search-results">
          <mat-card class="result-card" appearance="outlined" *ngFor="let organization of lrkSearchResults$ | async">
            <mat-card-header>
              <mat-icon mat-card-avatar svgIcon="doenkids-location"></mat-icon>
              <mat-card-title>{{ organization.actuele_naam_oko }}</mat-card-title>
              <mat-card-subtitle>
                <strong>{{ organization.lrk_id }} </strong>
                <span *ngIf="organization.correspondentie_adres">{{ organization.correspondentie_adres }}, </span>
                <span *ngIf="organization.correspondentie_postcode">{{ organization.correspondentie_postcode }}, </span>
                <span *ngIf="organization.correspondentie_woonplaats">{{ organization.correspondentie_woonplaats }}</span>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p>
                <span *ngIf="organization.naam_houder || organization.status">
                  {{
                    'dialog.add_organization.lrk_location.details'
                      | translate: { owner: organization.naam_houder || undefined, status: organization.status || undefined }
                  }}
                </span>
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button (click)="linkLRKToOU(organization)" mat-button>{{ 'generic.link' | translate | uppercase }}</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </form>
    </mat-tab>
    <mat-tab
      *ngIf="konnectDetails"
      [label]="
        'dialog.add_organization.tab.konnect'
          | translate
            : {
                organizationUnitType: data.organizationUnitType | ou_type_name | translate
              }
      "
    >
      <form [formGroup]="konnectSearch" text-center>
        <mat-form-field class="organization-name" appearance="outline">
          <mat-label>
            {{
              'dialog.add_organization.konnect.label'
                | translate
                  : {
                      organizationUnitType: data.organizationUnitType | ou_type_name | translate
                    }
            }}
          </mat-label>
          <input formControlName="name" matInput />
        </mat-form-field>

        <div class="konnect-search-results">
          <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.LOCATION && konnectDetails">
            <ng-container *ngFor="let konnectLocation of konnectLocationResults$ | async">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-icon mat-card-avatar svgIcon="doenkids-location"></mat-icon>
                  <mat-card-title
                    >{{ konnectLocation.name }} <span class="konnect-title-suffix">({{ konnectLocation.type_oko }})</span></mat-card-title
                  >
                  <mat-card-subtitle>
                    <strong>{{ konnectLocation.konnect_id }} </strong>
                  </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p>
                    <span *ngIf="konnectLocation.street">{{ konnectLocation.street }}</span
                    >&nbsp;
                    <span *ngIf="konnectLocation.housenumber">{{ konnectLocation.housenumber }}</span>
                  </p>
                  <p>
                    <span *ngIf="konnectLocation.postalcode">{{ konnectLocation.postalcode }}</span
                    >&nbsp;
                    <span *ngIf="konnectLocation.city">{{ konnectLocation.city }}</span>
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <button (click)="linkKonnectLocationToOrganization(konnectLocation)" mat-button>
                    {{ 'generic.link' | translate | uppercase }}
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.GROUP">
            <ng-container *ngFor="let konnectGroup of konnectGroupResults$ | async">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-icon mat-card-avatar svgIcon="doenkids-group"></mat-icon>
                  <mat-card-title
                    >{{ konnectGroup.name }} <span class="konnect-title-suffix">({{ konnectGroup.type_oko }})</span></mat-card-title
                  >
                  <mat-card-subtitle>
                    <strong>{{ konnectGroup.konnect_id }} </strong>
                  </mat-card-subtitle>
                </mat-card-header>
                <mat-card-actions>
                  <button (click)="linkKonnectGroupToOrganization(konnectGroup)" mat-button>
                    {{ 'generic.link' | translate | uppercase }}
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </ng-container>
        </div>
      </form>
    </mat-tab>
    <mat-tab
      *ngIf="jibbieDetails"
      [label]="
        'dialog.add_organization.tab.jibbie'
          | translate
            : {
                organizationUnitType: data.organizationUnitType | ou_type_name | translate
              }
      "
    >
      <form [formGroup]="jibbieSearch" text-center>
        <mat-form-field class="organization-name" appearance="outline">
          <mat-label>
            {{
              'dialog.add_organization.jibbie.label'
                | translate
                  : {
                      organizationUnitType: data.organizationUnitType | ou_type_name | translate
                    }
            }}
          </mat-label>
          <input formControlName="name" matInput />
        </mat-form-field>

        <div class="jibbie-search-results">
          <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.LOCATION && jibbieDetails">
            <ng-container *ngFor="let jibbieLocation of jibbieLocationResults$ | async">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-icon mat-card-avatar svgIcon="doenkids-location"></mat-icon>
                  <mat-card-title
                    >{{ jibbieLocation.name }} <span class="jibbie-title-suffix">({{ jibbieLocation.type }})</span></mat-card-title
                  >
                  <mat-card-subtitle>
                    <strong>{{ jibbieLocation.external_id }} </strong>
                  </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                  <p>
                    <span *ngIf="jibbieLocation.street">{{ jibbieLocation.street }}</span
                    >&nbsp;
                    <span *ngIf="jibbieLocation.housenumber">{{ jibbieLocation.housenumber }}</span>
                  </p>
                  <p>
                    <span *ngIf="jibbieLocation.postalcode">{{ jibbieLocation.postalcode }}</span
                    >&nbsp;
                    <span *ngIf="jibbieLocation.city">{{ jibbieLocation.city }}</span>
                  </p>
                </mat-card-content>
                <mat-card-actions>
                  <button (click)="linkJibbieLocationToOrganization(jibbieLocation)" mat-button>
                    {{ 'generic.link' | translate | uppercase }}
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.ORGANIZATION">
            <ng-container *ngFor="let jibbieOrganisation of jibbieOrganisationResults$ | async">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-icon mat-card-avatar svgIcon="doenkids-group"></mat-icon>
                  <mat-card-title>{{ jibbieOrganisation.name }}</mat-card-title>
                  <mat-card-subtitle>
                    <strong>{{ jibbieOrganisation.slug }} </strong>
                  </mat-card-subtitle>
                </mat-card-header>
                <mat-card-actions>
                  <button (click)="linkJibbieOrganisationToOrganization(jibbieOrganisation)" mat-button>
                    {{ 'generic.link' | translate | uppercase }}
                  </button>
                </mat-card-actions>
              </mat-card>
            </ng-container>
          </ng-container>
        </div>
      </form>
    </mat-tab>
    <mat-tab
      [label]="
        'dialog.add_organization.tab.create'
          | translate
            : {
                organizationUnitType: data.organizationUnitType | ou_type_name | translate
              }
      "
    >
      <ng-container *ngTemplateOutlet="newOrganizationTemplate"></ng-container>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="!(loading$ | async) && data.organizationUnitType !== EOrganizationUnitType.LOCATION && !konnectDetails && !jibbieDetails">
    <ng-container *ngTemplateOutlet="newOrganizationTemplate"></ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="save-button-container">
  <button (click)="createNewOrganization()" [disabled]="!newOrganization?.valid" mat-button>
    {{ 'generic.save' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #newOrganizationTemplate>
  <form *ngIf="newOrganization" [formGroup]="newOrganization">
    <div class="org-name" fxLayout="row" fxLayoutGap="16px">
      <mat-form-field appearance="outline" fxFlex="{{ data.organizationUnitType === EOrganizationUnitType.LOCATION ? '50%' : '100%' }}">
        <mat-label>{{ 'organization.name.label' | translate }}</mat-label>
        <input type="text" formControlName="name" matInput required [errorStateMatcher]="errorStateMatcher" />
        <mat-error *ngIf="newOrganization.get('name').hasError('required')">
          {{ 'generic.form.required_field' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="select" appearance="outline" fxFlex="50%" *ngIf="data.organizationUnitType === EOrganizationUnitType.LOCATION">
        <mat-label>{{ 'organization.location_type.label' | translate }}</mat-label>
        <mat-select placeholder="Type" formControlName="activityType" [errorStateMatcher]="errorStateMatcher">
          <mat-option *ngFor="let type of possibleActivityTypes" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="newOrganization.get('activityType').hasError('required')">
          {{ 'generic.form.required_field' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <ng-container *ngIf="isAdmin$ | async">
      <div class="org-address" fxLayout="row" fxLayoutGap="16px">
        <mat-form-field appearance="outline" fxFlex="50%">
          <mat-label>{{ 'organization.address.label' | translate }}</mat-label>
          <input type="text" formControlName="address" matInput [errorStateMatcher]="errorStateMatcher"/>
          <mat-error *ngIf="newOrganization.get('address').hasError('required')">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50%">
          <mat-label>{{ 'organization.postal_code.label' | translate }}</mat-label>
          <input type="text" formControlName="postalcode" matInput [errorStateMatcher]="errorStateMatcher"/>
          <mat-error *ngIf="newOrganization.get('postalcode').hasError('required')">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
          <mat-error *ngIf="newOrganization.get('postalcode').hasError('pattern')">
            {{ 'generic.form.invalid_postal_code' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="org-city" fxLayout="row" fxLayoutGap="16px">
        <mat-form-field appearance="outline" fxFlex="50%">
          <mat-label>{{ 'organization.city.label' | translate }}</mat-label>
          <input type="text" formControlName="city" matInput [errorStateMatcher]="errorStateMatcher"/>
          <mat-error *ngIf="newOrganization.get('city').hasError('required')">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50%">
          <mat-label>{{ 'organization.phone_number.label' | translate }}</mat-label>
          <input type="text" formControlName="phone_number" matInput [errorStateMatcher]="errorStateMatcher"/>
          <mat-error *ngIf="newOrganization.get('phone_number').hasError('required')">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="16px">
        <mat-form-field appearance="outline" [fxFlex]="data.parentOU ? '100%' : '50%'">
          <mat-label>{{ 'organization.email.label' | translate }}</mat-label>
          <input type="text" formControlName="email" matInput [errorStateMatcher]="errorStateMatcher" />
          <mat-error *ngIf="newOrganization.get('email').hasError('required')">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50%" *ngIf="!data.parentOU">
          <mat-label>{{ 'organization_unit.country.label' | translate }}</mat-label>
          <mat-select formControlName="country" required [errorStateMatcher]="errorStateMatcher">
            <mat-select-trigger>
              {{ getCountryLabel(newOrganization.get('country').value) }}
            </mat-select-trigger>
            <mat-option *ngFor="let country of localSupportedCountries" [value]="country.countryCode">
              {{ country.viewValue | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newOrganization.get('country').hasError('required')">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="org-content" fxLayout="row" fxLayoutGap="16px" *ngIf="data.parentOU">
        <mat-form-field appearance="outline" fxFlex="50%">
          <mat-label>{{ 'organization_unit.country.label' | translate }}</mat-label>
          <mat-select formControlName="country" required [errorStateMatcher]="errorStateMatcher">
            <mat-select-trigger>
              {{ getCountryLabel(newOrganization.get('country').value) }}
            </mat-select-trigger>
            <mat-option *ngFor="let country of localSupportedCountries" [value]="country.countryCode">
              {{ country.viewValue | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newOrganization.get('country').hasError('required')">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="50%" *ngIf="data.parentOU">
          <mat-label>{{ 'content_language.posbbile.label' | translate }}</mat-label>
          <mat-select multiple formControlName="contentLanguages" [errorStateMatcher]="errorStateMatcher">
            <mat-option *ngFor="let contentLanguage of possibleContentLanguages$ | async" [value]="contentLanguage">
              {{ 'generic.language.' + contentLanguage.toLowerCase() | translate | titlecase }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="newOrganization.get('contentLanguages').hasError('required')">{{ 'generic.language.form.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <div class="checkbox-wrapper" fxLayout="column" fxLayoutGap="16px" *ngIf="isAdmin$ | async">
      <mat-checkbox formControlName="template_settings">
        {{ 'dialog.add_organization.template_settings' | translate }}
      </mat-checkbox>
      <mat-checkbox formControlName="customer_symbols">
        {{ 'dialog.add_organization.customer_symbols' | translate }}
      </mat-checkbox>
      <mat-checkbox formControlName="i18n_translate">
        {{ 'dialog.add_organization.i18n_translate' | translate }}
      </mat-checkbox>
      <mat-checkbox formControlName="activity_copy">
        {{ 'dialog.add_organization.activity_copy' | translate }}
      </mat-checkbox>
      <mat-checkbox formControlName="program_template_create">
        {{ 'dialog.add_organization.program_template_create' | translate }}
      </mat-checkbox>
      <mat-checkbox formControlName="disable_activity_create">
        {{ 'dialog.add_organization.disable_activity_create' | translate }}
      </mat-checkbox>
      <mat-checkbox *ngIf="isOrganizationOfTypeCustomer$ | async" formControlName="konnect_integration">
        {{ 'dialog.add_organization.konnect_integration' | translate }}
      </mat-checkbox>
    </div>
  </form>
</ng-template>
