import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActivityReviewService } from 'src/api/activity/activity-review/activity-review.service';
import { PaginationInstance } from 'ngx-pagination';
import { map, takeUntil } from 'rxjs/operators';
import { IActivityReviews } from 'typings/doenkids/doenkids.d';
import { DoenkidsSessionProvider } from '../../../providers/session.provider';

_('review_approval.no_reviews');

interface IApprovalReviewScore {
  score: number;
  score_id: number;
  type: string;
  type_id: number;
}

export enum EReviewStatus {
  NEW = 1,
  UPDATED = 4,
  APPROVED = 2,
  DENIED = 3,
}

export interface IApprovalReview extends IActivityReviews {
  organization_unit_name: string;
  review_scores: IApprovalReviewScore[];
  average_review_score: number;
  activity_media_uuid: string;
  activity_name: string;
}
@Component({
  selector: 'app-review-approval',
  templateUrl: './review-approval.component.html',
  styleUrls: ['./review-approval.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewApprovalComponent implements OnInit, OnDestroy {
  MAX_ITEMS_PER_PAGE = 10;

  public reviews$ = new BehaviorSubject<IApprovalReview[]>([]);

  public selectedReview$ = new BehaviorSubject<IApprovalReview>(null);

  public reviewStatusOptions = [
    { id: EReviewStatus.NEW, value: 'NEW' },
    { id: EReviewStatus.UPDATED, value: 'UPDATED' },
    { id: EReviewStatus.APPROVED, value: 'APPROVED' },
    { id: EReviewStatus.DENIED, value: 'DENIED' },
  ];

  public selectedStatusOption = 'NEW';

  public reviewsLoading$ = new BehaviorSubject(false);

  public metaData$ = new BehaviorSubject({
    total: 0,
    limit: 0,
    skip: 0,
  });

  public currentPage = 1;

  /**
   * The configuration object for the pagination of the list.
   */
  public paginationConfig$: Observable<PaginationInstance> = this.metaData$.pipe(
    map((metaData) => {
      const paginationConfig: PaginationInstance = {
        id: 'reviewApproval',
        currentPage: (metaData.total > metaData.limit) && metaData.skip !== 0 ? (Math.ceil(metaData.skip / metaData.limit) + 1) : 1,
        itemsPerPage: metaData.limit,
        totalItems: metaData.total,
      };
      return paginationConfig;
    }),
  );

  private stop$ = new Subject();

  constructor(
    private $review: ActivityReviewService,
    private session: DoenkidsSessionProvider,
  ) { }

  async ngOnInit() {
    this.session.currentOuId$.pipe(takeUntil(this.stop$)).subscribe((organizationUnitId) => {
      this.fetchReviews(organizationUnitId);
    });
  }

  async fetchReviews(organizationUnitId?: number) {
    this.reviewsLoading$.next(true);

    organizationUnitId = organizationUnitId ?? await firstValueFrom(this.session.currentOuId$);

    const result = await this.$review.fetchByStatus(this.selectedStatusOption, organizationUnitId, this.MAX_ITEMS_PER_PAGE, (this.currentPage - 1) * this.MAX_ITEMS_PER_PAGE) as any;

    if (result) {
      const reviews = result.items?.map((review) => ({
        ...review,
      }));

      this.reviews$.next(reviews ?? []);

      this.metaData$.next({
        total: result.total,
        limit: result.limit,
        skip: result.skip,
      });
    }
    this.reviewsLoading$.next(false);
  }

  paginateReviews(page: number) {
    this.currentPage = page;
    this.fetchReviews();
  }

  async selectReview(review) {
    this.selectedReview$.next(review);
  }

  selectionChange() {
    this.fetchReviews();
    this.selectedReview$.next(null);
  }

  statusChange() {
    this.fetchReviews();
    this.selectedReview$.next(undefined);
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }
}
