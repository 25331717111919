<div fxLayout="column" class="mat-elevation-z3 toolbar-wrapper">
  <mat-toolbar class="doenkids-main-toolbar ">
    <div class="toolbar-container" fxLayout="row" fxFlex>
      <div class="left-side">
        <ng-container *ngIf="isLoggedIn$ | async">
          <button
            type="button"
            [attr.aria-label]="'doenkids_menu_bar.sidenav_toggle.label' | translate"
            mat-icon-button
            (click)="toggleDrawer()"
            *ngIf="isXSmall$ | async"
            class="sidenav-button">
            <mat-icon [attr.aria-label]="'doenkids_menu_bar.sidenav_toggle.icon_label' | translate">menu</mat-icon>
          </button>
          <button
            type="button"
            [attr.aria-label]="'doenkids_menu_bar.go_back.label' | translate"
            mat-icon-button
            (click)="navigateBack()"
            *ngIf="showBackButton"
            class="back-button">
            <mat-icon [attr.aria-label]="'doenkids_menu_bar.go_back.icon_label' | translate">arrow_back</mat-icon>
          </button>

          <ng-content select="[left]"></ng-content>
        </ng-container>
      </div>
      <div class="right-side" fxLayout="row" fxLayoutAlign="space-around center" >
        <ng-container *ngIf="isLoggedIn$ | async">
          <ng-content select="[right]" ></ng-content>
        </ng-container>
        <button
          *ngIf="isLoggedIn$ | async"
          mat-icon-button
          type="button"
          (click)="logout()"
          [matTooltip]="'doenkids_menu_bar.logout' | translate"
        >
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div class="progress-container" [class.visibility]="loading">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
</div>


