<h1 mat-dialog-title>{{ 'archive_media_item_dialog.title' | translate }}</h1>
<div mat-dialog-content>
  <mat-progress-bar *ngIf="!loaded" mode="indeterminate"></mat-progress-bar>
  <p *ngIf="usage?.length === 0 && notAllowedToViewAmount === 0">{{ 'archive_media_item_dialog.item.removeable' | translate }}</p>
  <p *ngIf="usage?.length === 0 && notAllowedToViewAmount !== 0">
    {{ 'archive_media_item_dialog.item.in_use.unauthorized' | translate: { usageCount: notAllowedToViewAmount} }}
  </p>
  <ng-container *ngIf="usage?.length > 0">
    <p>{{ 'archive_media_item_dialog.item.in_use' | translate }}</p>
    <mat-selection-list #shoes [multiple]="false">
      <mat-list-option *ngFor="let usedBy of usage" [value]="usedBy" (click)="navigateTo(usedBy)">
        <mat-icon *ngIf="usedBy.src === 'activity'" matListItemIcon>filter_none</mat-icon>
        <mat-icon *ngIf="usedBy.src === 'program'" matListItemIcon>calendar_today</mat-icon>
        <mat-icon *ngIf="['attachment', 'template_attachment', 'template_bundle_attachment'].includes(usedBy.src)" matListItemIcon>attach_file</mat-icon>
        <mat-icon *ngIf="usedBy.src === 'user_media'" matListItemIcon>account_circle</mat-icon>
        <mat-icon *ngIf="usedBy.src === 'jibbie_activity'" matListItemIcon svgIcon="jibbie-linked"></mat-icon>
        <div matListItemLine>{{usedBy.src_name}}</div>
        <div matListItemLine class="ownership">{{ownershipDescription(usedBy)}}</div>
      </mat-list-option>

    </mat-selection-list>
    <p *ngIf="notAllowedToViewAmount > 0">
      {{ 'archive_media_item_dialog.item.in_use.additional_unauthorized' | translate: {usageCount: notAllowedToViewAmount} }}
    </p>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <span class="spacer"></span>
  <button mat-button [disabled]="archivingInProgress || !loaded || usage?.length > 0" (click)="confirm()" color="primary" cdkFocusInitial>
    {{ 'generic.archive' | translate | uppercase  }}
  </button>
  <mat-progress-spinner
    diameter="30"
    *ngIf="archivingInProgress"
    color="primary"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>

