/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ILocation } from 'typings/doenkids/doenkids';

export interface ILocationState extends EntityState<ILocation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'location' })
export class LocationStore extends EntityStore<ILocationState, ILocation> {
  constructor() {
    super();
  }
}
