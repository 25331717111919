import {
  Component, OnInit, Inject, OnDestroy, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { IKonnectOrganizationUnit, IKonnectLocation, IKonnectGroup } from 'typings/doenkids/doenkids';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { KonnectLocationService } from 'src/api/customer/konnect/location/konnect-location.service';
import { KonnectGroupService } from 'src/api/customer/konnect/group/konnect-group.service';
import { IOrganizationUnitTreeNode, IKonnectLocationListResponse, IKonnectGroupListResponse } from 'typings/api-customer';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { KonnectLocationQuery } from 'src/api/customer/konnect/location/konnect-location.query';
import { KonnectGroupQuery } from 'src/api/customer/konnect/group/konnect-group.query';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { EOrganizationUnitType } from '../add-organization-dialog/add-organization-dialog.component';

@Component({
  selector: 'app-connect-konnect-ou-dialog',
  templateUrl: './connect-konnect-ou-dialog.component.html',
  styleUrls: ['./connect-konnect-ou-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConnectKonnectOUDialogComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  public loading$ = new Subject<boolean>();

  public konnectSearch: UntypedFormGroup;

  private KONNECT_SEARCH_LIMIT = 5;

  private konnectCurrentPage = 0;

  public konnectDetails: IKonnectOrganizationUnit;

  public konnectLocationResults$: BehaviorSubject<IKonnectLocation[]> = new BehaviorSubject<IKonnectLocation[]>([]);

  public konnectLocationSearching$: Observable<boolean>;

  private totalKonnectLocations = 0;

  public konnectGroupResults$: BehaviorSubject<IKonnectGroup[]> = new BehaviorSubject<IKonnectGroup[]>([]);

  public konnectGroupSearching$: Observable<boolean>;

  private totalKonnectGroups = 0;

  protected readonly EOrganizationUnitType = EOrganizationUnitType;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ConnectKonnectOUDialogComponent>,
    private organizationUnitService: OrganizationUnitService,
    private konnectLocationQuery: KonnectLocationQuery,
    private konnectLocationService: KonnectLocationService,
    private konnectGroupQuery: KonnectGroupQuery,
    private konnectGroupService: KonnectGroupService,
    @Inject(MAT_DIALOG_DATA) public data: {
      organizationUnitType: number,
      organization: IOrganizationUnitTreeNode,
      parentOUId: number,
      parentOUName: string,
    },
  ) {
    this.konnectLocationSearching$ = this.konnectLocationQuery.selectLoading();
    this.konnectGroupSearching$ = this.konnectGroupQuery.selectLoading();
  }

  async ngOnInit() {
    this.konnectSearch = this.fb.group({
      name: [''],
    });

    this.konnectSearch.get('name').valueChanges.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((newValue: string) => {
      this.searchKonnect(newValue, false);
    });

    if (
      this.data.parentOUId
      && (this.data.organizationUnitType === EOrganizationUnitType.LOCATION || this.data.organizationUnitType === EOrganizationUnitType.GROUP)
    ) {
      const fetchedKonnectDetails = await this.organizationUnitService.konnectDetails(this.data.parentOUId);

      if (fetchedKonnectDetails.src === 'organisation' || fetchedKonnectDetails.src === 'parent_organisation'
        || fetchedKonnectDetails.src === 'location' || fetchedKonnectDetails.src === 'parent_location') {
        this.konnectDetails = fetchedKonnectDetails;

        this.searchKonnect('', false);
      } else {
        this.konnectDetails = null;
      }
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async searchKonnect(name: string, concatenate: boolean) {
    if (this.data.organizationUnitType === EOrganizationUnitType.LOCATION && this.konnectDetails) {
      const skip = concatenate ? ((this.konnectCurrentPage + 1) * this.KONNECT_SEARCH_LIMIT) : 0;
      // through the query the results are gonna appear on the screen
      //
      await this.konnectLocationService.fetchAll(this.konnectDetails.id, this.KONNECT_SEARCH_LIMIT, skip, 'name', 'ASC', name)
        .then((konnectLocationListResponse: IKonnectLocationListResponse) => {
          const filteredResults = konnectLocationListResponse.items.filter((location) => !location.organization_unit_id);
          console.log('filtered results', filteredResults, concatenate);
          if (concatenate) {
            const currentKonnectLocationList = this.konnectLocationResults$.value;
            this.konnectLocationResults$.next([...currentKonnectLocationList, ...filteredResults]);
            this.konnectCurrentPage += 1;
          } else {
            this.konnectLocationResults$.next(filteredResults);
            this.konnectCurrentPage = 0;
          }
          this.totalKonnectLocations = konnectLocationListResponse.total;

          // as long as we didn't get all locations yet and we have less locations in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalKonnectLocations > (konnectLocationListResponse.limit + konnectLocationListResponse.skip)
            && this.konnectLocationResults$.value.length < this.KONNECT_SEARCH_LIMIT) {
            this.searchKonnect(name, true);
          }
        });
    } else if (this.data.organizationUnitType === EOrganizationUnitType.GROUP) {
      const skip = concatenate ? ((this.konnectCurrentPage + 1) * this.KONNECT_SEARCH_LIMIT) : 0;
      await this.konnectGroupService.fetchAll(this.data.parentOUId, {
        limit: this.KONNECT_SEARCH_LIMIT,
        skip,
        sortField: 'name',
        sortDirection: 'ASC',
        search: name,
      })
        .then((konnectGroupListResponse: IKonnectGroupListResponse) => {
          const filteredResults = konnectGroupListResponse.items.filter(
            (group) => !group.organization_unit_id || group.organization_unit_id !== this.data.organization.id,
          );
          if (concatenate) {
            const currentKonnecGroupList = this.konnectGroupResults$.value;
            this.konnectGroupResults$.next([...currentKonnecGroupList, ...filteredResults]);
            this.konnectCurrentPage += 1;
          } else {
            this.konnectGroupResults$.next(filteredResults);
            this.konnectCurrentPage = 0;
          }

          this.totalKonnectGroups = konnectGroupListResponse.total;

          // as long as we didn't get all groups yet and we have less groups in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalKonnectGroups > (konnectGroupListResponse.limit + konnectGroupListResponse.skip)
            && this.konnectGroupResults$.value.length < this.KONNECT_SEARCH_LIMIT) {
            this.searchKonnect(name, true);
          }
        });
    }
  }

  async linkKonnectLocationToOrganization(location: IKonnectLocation) {
    try {
      this.loading$.next(true);

      // connect konnect location to current ou
      //
      await this.konnectLocationService.setOrganizationUnit(location.id, this.data.organization.id);

      this.closeDialog();
    } catch (e) {
      console.log('error linking Konnect location to OU', e);
    }
  }

  async linkKonnectGroupToOrganization(group: IKonnectGroup) {
    try {
      this.loading$.next(true);

      // connect konnect location to current ou
      //
      await this.konnectGroupService.setOrganizationUnit(group.id, this.data.organization.id);

      this.closeDialog();
    } catch (e) {
      console.log('error linking Konnect group to OU', e);
    }
  }

  getOUTypeName(ouType: EOrganizationUnitType): string {
    switch (ouType) {
      case EOrganizationUnitType.ORGANIZATION:
        return _('organization_unit.organization');
      case EOrganizationUnitType.LOCATION:
        return _('organization_unit.location');
      case EOrganizationUnitType.GROUP:
        return _('organization_unit.group');
      default:
        return _('organization_unit.root');
    }
  }

  closeDialog() {
    this.loading$.next(false);
    this.konnectLocationResults$.next([]);
    this.konnectGroupResults$.next([]);
    this.dialogRef.close();
  }

  scrollEndReached() {
    this.searchKonnect(this.konnectSearch.get('name').value, true);
  }
}
