import { EntityStore, EntityState, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IEventLogDetails } from 'typings/doenkids/doenkids';

export interface IUserFetchEventsState extends EntityState<IEventLogDetails> {
  limit: number;
  skip: number;
  total: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-fetch-events' })
export class UserFetchEventsStore extends EntityStore<IUserFetchEventsState> {
  constructor() {
    super([]);
  }

  setMetadata({ total, skip, limit }) {
    this.update({ total, skip, limit });
  }
}
