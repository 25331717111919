/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgram } from 'typings/doenkids/doenkids';

export interface IProgramListState extends EntityState<IProgram> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-list' })
export class ProgramListStore extends EntityStore<IProgramListState, IProgram> {
  constructor() {
    super();
  }
}
