import { Injectable } from '@angular/core';
import { IProgramAttachment } from 'typings/doenkids/doenkids';
import { IProgramAttachmentListResponse, IProgramAttachmentMediaListResponse } from 'typings/api-media';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ProgramAttachmentStore } from './program-attachment.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgramAttachmentService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: ProgramAttachmentStore,
  ) {}

  fetchAll({
    programId, limit = 100, skip = 0, sortField = 'created_at', sortDirection = 'DESC',
  }) {
    const optionalParams: any = {};
    if (programId) {
      optionalParams.programId = programId;
    }

    return firstValueFrom(this.$baseApi.genericListCall('/media/program/attachment', limit, skip, sortField, sortDirection, optionalParams)) as Promise<IProgramAttachmentListResponse>;
  }

  fetchAttachmentsWithMedia({
    programId, mimeType = null, limit = 100, skip = 0, sortField = 'created_at', sortDirection = 'DESC',
  }) {
    const optionalParams: any = {};

    if (mimeType) {
      optionalParams.mimeType = mimeType;
    }

    return firstValueFrom(this.$baseApi.genericListCall(`/media/program/attachment/${programId}`, limit, skip, sortField, sortDirection, optionalParams,
    )) as Promise<IProgramAttachmentMediaListResponse>;
  }

  async update(programId, attachmentId): Promise<IProgramAttachment> {
    const updatedAttachment = await firstValueFrom(this.$baseApi.genericPutCall(`/media/program/attachment/${programId}/attachment/${attachmentId}`, {})) as IProgramAttachment;
    if (updatedAttachment) {
      this.store.upsert(updatedAttachment.id, updatedAttachment);
    }
    return updatedAttachment;
  }

  async delete(programId, attachmentId): Promise<void> {
    await firstValueFrom(this.$baseApi.genericDeleteCall(`/media/program/attachment/${programId}/attachment/${attachmentId}`));
    this.store.remove(attachmentId);
  }

  async updateDisplayName(programId, attachmentId, name) {
    const updatedAttachment = await firstValueFrom(this.$baseApi
      .genericPutCall(`/media/program/attachment/${programId}/attachment/${attachmentId}/display-name?name=${name}`, {})) as IProgramAttachment;
    if (updatedAttachment) {
      this.store.upsert(updatedAttachment.id, updatedAttachment);
    }
    return updatedAttachment;
  }
}
