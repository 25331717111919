/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IPeriodSection } from 'typings/doenkids/doenkids';

export interface IProgramSectionState extends EntityState<IPeriodSection> {
  limit: number;
  skip: number;
  total: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-section' })
export class ProgramPeriodSectionStore extends EntityStore<IProgramSectionState, IPeriodSection> {
  constructor() {
    super();
  }

  setMetadata({
    total, skip, limit,
  }) {
    this.update({
      total, skip, limit,
    });
  }
}
