<h3 mat-dialog-title>{{ 'generic.remove' | translate }}</h3>
<mat-dialog-content>
  <ng-container *ngIf="activityFolders.length === 1">
    <span [innerHtml]="'pinboard.folder.activity.remove_single' | translate: {folderName: activityFolders[0].name}"></span>
  </ng-container>
  <ng-container *ngIf="activityFolders.length > 1">
    <div class="activity">
      <div class="image-container">
        <app-doenkids-image [mediaItem]="{ uuid: data.activity.media_uuid , description: '' }"></app-doenkids-image>
      </div>
      <div class="text-container">
        <h2 class="activity-title">
          {{ data.activity.name !== '' ? data.activity.name :  ('generic.new' | translate) }}
        </h2>
        <h3>
          {{ data.activity.subtitle }}
        </h3>
      </div>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'pinboard.folder.activity.remove.select.label' | translate }}</mat-label>
      <mat-select [formControl]="selectedFolder" multiple>
        <ng-container *ngFor="let folder of activityFolders">
          <mat-option [value]="folder.id">
            {{ folder.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" fxFlexFill>
  <button mat-button color="primary" (click)="onClose()">
    {{ 'generic.close' | translate }}
  </button>
  <button mat-button color="primary" (click)="onRemove()" [disabled]="!selectedFolder.valid">
    {{ 'generic.remove' | translate }}
  </button>
</mat-dialog-actions>
