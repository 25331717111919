import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IKonnectLocation } from 'typings/doenkids/doenkids';

export interface IKonnectLocationState extends EntityState<IKonnectLocation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'konnect-location' })
export class KonnectLocationStore extends EntityStore<IKonnectLocationState, IKonnectLocation> {
  constructor() {
    super({});
  }
}
