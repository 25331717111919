<form [formGroup]="form" (ngSubmit)="add()">
  <mat-form-field appearance="fill" class="program-category-chip-list">
    <mat-label>{{ 'program_category.add.label' | translate }}</mat-label>
    <input matInput formControlName="categoryName" type="text" [attr.maxlength]="categoryMaxLength">
  </mat-form-field>
  <div formArrayName="categories">
    <div cdkDropList class="category-list" (cdkDropListDropped)="drop($event)">

      <div
      *ngFor="let category of categories; let i = index"
        [formGroupName]="i"
        [ngClass]="{
          'category-box': true,
          'draggable': (isAdmin$ | async),
          'owner': selectedOUId === category.organization_unit_id
        }"
        cdkDrag
        [cdkDragDisabled]="(isAdmin$ | async) ? false : !(hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async) || (selectedOUId !== category.organization_unit_id)"
      >

        <ng-container *ngIf="i === inlineEditingIndex">
          <mat-form-field appearance="outline" class="program-category-inline-edit">
            <input matInput autofocus [formControl]="editingCategoryControl" type="text" [attr.maxlength]="categoryMaxLength">
          </mat-form-field>
          <button mat-icon-button (click)="update(category)" type="button" [attr.aria-label]="'program_category.update.label' | translate">
            <mat-icon>save</mat-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="i !== inlineEditingIndex">
          <span>
            {{category.name}}
            <ng-container *ngIf="selectedOUId !== category.organization_unit_id">
              &nbsp;<span class="category-owner">{{category.organization_unit_name}}</span>
            </ng-container>
          </span>
          <div class="category-btn-container" *ngIf="selectedOUId === category.organization_unit_id">
            <button (click)="setEditingIndex(i)" type="button" mat-icon-button [attr.aria-label]="'program_category.edit.label' | translate">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="removeCategory(category)" type="button" [attr.aria-label]="'program_category.remove.label' | translate">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</form>
