import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IActivityRecommendationsList } from 'typings/api-activity';

@Injectable({ providedIn: 'root' })
export class RecommendationsService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(organizationUnitId: number, type?: 'all' | 'creative' | 'food' | 'game' | 'media' | 'mostPlanned' | 'music' | 'nature' | 'newest' | 'sport' | 'technique') {
    let url = '/activity/recommendation';

    url = this.$baseApi.appendQueryParam(url, 'organizationUnitId', organizationUnitId);
    url = this.$baseApi.appendQueryParam(url, 'type', type);

    const response = await firstValueFrom(this.$baseApi.genericGetCall(url)) as IActivityRecommendationsList;
    return response;
  }
}
