/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgramStatus } from 'typings/doenkids/doenkids';

export interface IProgramStatusState extends EntityState<IProgramStatus> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-status' })
export class ProgramStatusStore extends EntityStore<IProgramStatusState, IProgramStatus> {
  constructor() {
    super();
  }
}
