import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { SearchResponse } from '../../types';
import { get } from 'lodash';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IProgramTemplateSearchRequest } from 'typings/api-search';
import { ISearchActivity, ISearchProgramTemplate } from 'typings/doenkids/doenkids';
import { TemplateSearchStore } from './template-search.store';
import { firstValueFrom } from 'rxjs';

export interface IBaseTemplateSearchResponse {
  results: ISearchActivity[];
  aggregations: any;
  suggestions: any[];
}

@Injectable({
  providedIn: 'root',
})
export class TemplateSearchService {
  constructor(private store: TemplateSearchStore, private $baseApi: DoenKidsGenericApiProvider) { }

  @transaction()
  async fetch(request: IProgramTemplateSearchRequest, concatenate: boolean) {
    const searchResponse = (await firstValueFrom(this.$baseApi.genericPostCall('/search/program-template/search', request))) as SearchResponse<ISearchProgramTemplate>;
    const hits = (searchResponse?.hits?.hits ?? [])?.map((hit) => hit._source);

    if (concatenate) {
      this.store.add(hits);
    } else {
      this.store.set(hits);
    }

    this.store.update({
      limit: request.limit,
      skip: request.skip,
      total: searchResponse?.hits?.total ?? 0,
      query: request.query,
      took: searchResponse?.took ?? 0,
    });

    this.store.setAggregations(searchResponse?.aggregations ?? {});
    this.store.setSuggestions(get(searchResponse, 'suggest.simple_phrase', []));
  }
}
