import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IUserActive } from 'typings/doenkids/doenkids';
import { UserFetchAllActiveStore, IUserFetchAllActiveState } from './user-fetch-all-active.store';

@Injectable({ providedIn: 'root' })
export class UserFetchAllActiveQuery extends QueryEntity<IUserFetchAllActiveState, IUserActive> {
  constructor(protected store: UserFetchAllActiveStore) {
    super(store);
  }

  getMetadata() {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
    }));
  }
}
