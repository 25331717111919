import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '../app-sentry-config';

const fallbackIsHandledKey = 'fallbackIsHandled';
export const fallbackIsHandledParam = { [fallbackIsHandledKey]: true };

@Injectable({ providedIn: 'root' })
export class LoggingMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private sentryErrorHandler: SentryErrorHandler) {}

  handle(params: MissingTranslationHandlerParams) {
    if (!params.interpolateParams?.hasOwnProperty(fallbackIsHandledKey)) {
      this.sentryErrorHandler.handleWarning(`[MISSING TRANSLATION][${params.translateService.currentLang}][${params.key}]`);
    }
    return params.key;
  }
}
