import { Injectable } from '@angular/core';
import { IProgramTemplateBundle, IProgramTemplateProgramTemplateBundle, IOrganizationUnitProgramTemplateBundle } from 'typings/doenkids/doenkids';
import { IServiceResponse, IEntityWithAccessLevel } from 'typings/api-generic';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { omit } from 'lodash';
import { IProgramTemplateBundleListResponse, IProgramTemplateBundleOwnershipResponse } from 'typings/api-activity';
import { ProgramTemplateBundleStore } from './program-template-bundle.store';
import { Observable, firstValueFrom } from 'rxjs';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';

/**
 * An Akita service for the program routes in the activity API.
 *
 * https://api-tst-doenkids.springtree.io/activity/docs/#tag/program-template-bundle
 *
 */
@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: ProgramTemplateBundleStore,
  ) {}

  async create(programTemplate: IProgramTemplateBundle, organization_unit_id: number) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall(
      '/activity/program-template-bundle', programTemplate, null, { organization_unit_id },
    )) as IProgramTemplateBundle;
    this.store.update(response);
    return response;
  }

  async fetch(programTemplateBundleId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program-template-bundle/${programTemplateBundleId}`)) as IEntityWithAccessLevel<IProgramTemplateBundle>;
    this.store.update({
      ...response?.data,
      access_level: response?.access_level,
    });
    return response?.data;
  }

  async fetchRevoked(params: {
    organizationUnitId: number,
    limit: number,
    skip: number,
    sortDirection?: string,
    sortField?: string,
    language?: string,
  }) {
    let url = `/activity/program-template-bundle/organization-unit/${params.organizationUnitId}/approved`;

    url = this.$baseApi.appendQueryParam(url, 'organization_unit_role_id', DoenkidsStaticValuesHelper.PROGRAM_REVOKED_ROLE);

    const response = (await firstValueFrom(this.$baseApi.genericListCall(url, params.limit, params.skip, params.sortField, params.sortDirection,
      { language: params.language }))) as IProgramTemplateBundleListResponse;

    return response;
  }

  async update(programTemplateBundle: IProgramTemplateBundle): Promise<IProgramTemplateBundle> {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program-template-bundle/${programTemplateBundle.id}`, omit(programTemplateBundle, 'access_level'),
    )) as IProgramTemplateBundle;
    this.store.update(response);
    return response;
  }

  approvalFetch(organization_unit_id: number, program_template_bundle_id: number) {
    return firstValueFrom(this.$baseApi.genericGetCall(
      `/activity/program-template-bundle/organization-unit/${organization_unit_id}/approved/${program_template_bundle_id}`,
    )) as Promise<IOrganizationUnitProgramTemplateBundle>;
  }

  approve(organization_unit_id: number, program_template_bundle_id: number) {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program-template-bundle/organization-unit/${organization_unit_id}/approved/${program_template_bundle_id}`,
    )) as Promise<IServiceResponse>;
  }

  approvalRemove(organization_unit_id: number, program_template_bundle_id: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(
      `/activity/program-template-bundle/organization-unit/${organization_unit_id}/approved/${program_template_bundle_id}`,
    )) as Promise<IServiceResponse>;
  }

  archive(programTemplateBundleId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/program-template-bundle/${programTemplateBundleId}`)) as Promise<IServiceResponse>;
  }

  async fetchOwner(programTemplateBundleId: number) {
    const { items } = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program-template-bundle/${programTemplateBundleId}/owner`)) as IProgramTemplateBundleOwnershipResponse;
    return items;
  }

  addProgramTemplate(programTemplateBundleId: number, programTemplateId: number) {
    // eslint-disable-next-line max-len
    return firstValueFrom(this.$baseApi.genericPutCall(`/activity/program-template-bundle/${programTemplateBundleId}/program-template/${programTemplateId}`)) as Promise<IProgramTemplateProgramTemplateBundle>;
  }

  removeProgramTemplate(programTemplateBundleId: number, programTemplateId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/program-template-bundle/${programTemplateBundleId}/program-template/${programTemplateId}`)) as Promise<IServiceResponse>;
  }

  async copy(organizationUnitId: number, programTemplateBundleId: number, statusId: number = 1, archived: boolean = false, language?: string) {
    let url = `/activity/program-template-bundle/${programTemplateBundleId}/organization-unit/${organizationUnitId}`;

    url = this.$baseApi.appendQueryParam(url, 'statusId', statusId);
    url = this.$baseApi.appendQueryParam(url, 'archived', archived);
    url = this.$baseApi.appendQueryParam(url, 'language', language);

    const copiedProgramTemplateBundle = await firstValueFrom(this.$baseApi.genericPutCall(url)) as IProgramTemplateBundle;
    return copiedProgramTemplateBundle;
  }

  reOrder(programTemplateBundleId: number, programTemplateId: number, targetProgramTemplateId: number, placement: 'before' | 'after') {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program-template-bundle/${programTemplateBundleId}/program-template/${programTemplateId}/reorder/${targetProgramTemplateId}?placement=${placement}`,
    ));
  }

  relatedBundles(bundleId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc') {
    return this.$baseApi.genericListCall(`/activity/related-program-template-bundle/${bundleId}`, limit, skip, sortField, sortDirection) as Observable<IProgramTemplateBundleListResponse>;
  }

  addRelatedProgramTemplateBundle(fromBundleId: number, toBundleId: number) {
    return this.$baseApi.genericPutCall(`/activity/related-program-template-bundle/${fromBundleId}/program-template-bundle/${toBundleId}`);
  }

  removeRelatedProgramTemplate(fromBundleId: number, toBundleId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/related-program-template-bundle/${fromBundleId}/program-template-bundle/${toBundleId}`);
  }
}
