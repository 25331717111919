/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgramTemplateSelectionProvider } from 'src/providers/program-template-selection.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ProgramCategoryListQuery } from 'src/api/activity/program-category-list/program-category-list.query';
import { IUploadResponse } from 'typings/custom-app-types';
import { IMediaItem } from 'typings/section-types';
import { Observable, firstValueFrom } from 'rxjs';
import { Navigation, SwiperOptions } from 'swiper';
import { IProgramCategoryWithOrganizationUnit } from 'typings/api-activity';
import { swiperStyles } from 'src/directives/swiper.directive';
import { IActivityType } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-create-program-bundle-dialog',
  templateUrl: './create-program-bundle-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./create-program-bundle-dialog.component.scss'],
})
export class CreateProgramTemplateBundleDialogComponent implements OnInit {
  public createBundleForm: UntypedFormGroup;

  public isAdmin$: Observable<boolean>;

  public okoTypes$: Observable<IActivityType[]>;

  public productCategories$: Observable<IProgramCategoryWithOrganizationUnit[]>;

  public swiperConfig: SwiperOptions = {
    modules: [Navigation],
    a11y: {
      enabled: true,
    },
    slidesPerView: 'auto',
    keyboard: true,
    mousewheel: false,
    navigation: true,
    injectStyles: [swiperStyles],
  };

  constructor(
    fb: UntypedFormBuilder,
    productCategoryQuery: ProgramCategoryListQuery,
    public $programTemplateSelection: ProgramTemplateSelectionProvider,
    public $session: DoenkidsSessionProvider,
    public dialogRef: MatDialogRef<CreateProgramTemplateBundleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      selectedActivityType: number,
    },
  ) {
    this.productCategories$ = productCategoryQuery.selectAll();
    this.isAdmin$ = $session.isAdmin$;
    this.okoTypes$ = $session.availableActivityTypes$;
    this.createBundleForm = fb.group({
      name: ['', Validators.required],
      type_oko: [data.selectedActivityType, Validators.required],
      programCategory: ['', Validators.required],
      mediaUuid: ['', Validators.required],
    });
  }

  async ngOnInit() {
    if (!this.data.selectedActivityType) {
      const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
      this.createBundleForm.controls.type_oko.setValue(organizationUnit.activity_type ?? organizationUnit.activity_types[0]);
    }
  }

  addCoverImage(mediaItem: IUploadResponse | IMediaItem) {
    const media_uuid = mediaItem.uuid ? mediaItem.uuid : '';
    this.createBundleForm.get('mediaUuid').setValue(media_uuid);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm() {
    const media_uuid = this.createBundleForm.get('mediaUuid').value;
    const name = this.createBundleForm.get('name').value;
    const type_oko = this.createBundleForm.get('type_oko').value === '' ? null : this.createBundleForm.get('type_oko').value;
    const program_category_id = this.createBundleForm.get('programCategory').value;

    this.dialogRef.close({
      media_uuid, name, type_oko, program_category_id,
    });
  }
}
