import {
  Directive, Input, TemplateRef, ViewContainerRef,
} from '@angular/core';


class NgVarContext<T = unknown> {
  $implicit: T = null!;

  ngVar: T = null!;
}

/**
 * If you find yourself in a situation where you want to define a template variable but wrapping your code in an if-statement
 * is something you don't feel like doing, this directive can help.
 *
 * You can use this directive to define a local variable just like you would with *ngIf
 *
 * Example:
 *
 * <ng-container *ngVar="(list$ | async) as list">
 *   <h1>{{ list.title }}</h1>
 *   <h2>{{ list.subtitle }}</h2>
 *   <div *ngFor="let item in list.items">
 *     ...
 *   </div>
 * </ng-container>
 */
@Directive({
  selector: '[ngVar]',
})
export class VarDirective<T = unknown> {
  @Input()
  set ngVar(context: T) {
    // eslint-disable-next-line no-multi-assign
    this._context.$implicit = this._context.ngVar = context;

    if (!this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef, this._context);
      this.hasView = true;
    }
  }

  private _context: NgVarContext<T> = new NgVarContext<T>();

  private hasView = false;

  /**
   * Asserts the correct type of the context for the template that `NgVar` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgVar` structural directive renders its template with a specific context type.
   */
  static ngTemplateContextGuard<T>(dir: VarDirective<T>, ctx: any): ctx is NgVarContext<Exclude<T, false | 0 | '' | null | undefined>> {
    return true;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private vcRef: ViewContainerRef,
  ) {}
}
