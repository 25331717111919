import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IUserActive } from 'typings/doenkids/doenkids';
import { UserFetchAllActiveStore } from './user-fetch-all-active.store';
import { firstValueFrom } from 'rxjs';
import { IListRequest } from 'typings/api-activity';
import { IUserActiveListResponse } from 'typings/api-customer';

@Injectable({
  providedIn: 'root',
})
export class UserFetchAllActiveService {
  @transaction()
  async fetch(
    requestBody: IListRequest,
    organizationUnitId?: number,
  ) {
    let url = '/customer/user/active';
    url = this.$baseApi.appendQueryParam(url, 'organization_unit_id', organizationUnitId);

    const response = await firstValueFrom(this.$baseApi.genericPostCall(
      url, requestBody,
    )) as IUserActiveListResponse;

    const hits = (response?.items ?? []) as IUserActive[];

    this.store.update({
      limit: requestBody.limit,
      skip: requestBody.skip,
      total: response?.total ?? 0,
    });

    this.store.set(hits);

    return hits;
  }

  constructor(
    private store: UserFetchAllActiveStore,
    private $baseApi: DoenKidsGenericApiProvider,
  ) { }
}
