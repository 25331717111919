import { Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import supportedBrowsers from './supportedBrowsers';

@Component({
  selector: 'app-browser-not-supported',
  templateUrl: './browser-not-supported.component.html',
  styleUrls: ['./browser-not-supported.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BrowserNotSupportedComponent {

  public isSupported$ = new BehaviorSubject<boolean>(true);

  constructor() {
    const isSupported = supportedBrowsers.test(navigator.userAgent);
    this.isSupported$.next(isSupported);
  }
}
