<form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.title' | translate }}</mat-label>
      <input type="text" formControlName="title" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.subtitle' | translate }}</mat-label>
      <input type="text" formControlName="subtitle" matInput>
    </mat-form-field>
  </div>
  <div class="section-outline on-focus">
    <quill-editor
      bounds="self"
      theme="bubble"
      formControlName="content"
      [placeholder]="'generic.content.placeholder' | translate"
    ></quill-editor>
  </div>
</form>
