import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IOrganizationUnitActivityRoles, IOrganizationUnitActivityAssignment } from 'typings/api-activity';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { get } from 'lodash';
import {
  BrowseOrganizationUnitDialogComponent, ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from 'src/app/utils/translate.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

interface IActivityOwner {
  id: number;
  name: string;
  type: number;
  typeDisplay?: string;
}

@Component({
  selector: 'app-publish-activity-dialog',
  templateUrl: './publish-activity-dialog.component.html',
  styleUrls: ['./publish-activity-dialog.component.scss'],
})
export class PublishActivityDialogComponent {
  public selectedOwner: IActivityOwner;

  public originalOwner: IActivityOwner;

  private availableRoles: IOrganizationUnitActivityRoles[] = [];

  constructor(
    public dialogRef: MatDialogRef<PublishActivityDialogComponent>,
    public matDialog: MatDialog,
    public activityService: ActivityService,
    private readonly $translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { roles: IOrganizationUnitActivityRoles[] },
  ) {
    this.availableRoles = this.data?.roles ?? [];
    const ownerRole = this.data?.roles?.find((role) => role.organization_unit_role_id === DoenkidsStaticValuesHelper.ACTIVITY_OWNER_ROLE);
    if (ownerRole) {
      this.originalOwner = {
        id: ownerRole.organization_unit_id,
        name: ownerRole.name,
        type: ownerRole.organization_unit_type_id,
      };
      this.changeOwner({
        id: ownerRole.organization_unit_id,
        name: ownerRole.name,
        type: ownerRole.organization_unit_type_id,
      });
    }
  }

  changeOwner(owner: IActivityOwner) {
    this.selectedOwner = owner;
  }

  async selectOther() {
    const dialogRef = this.matDialog.open(BrowseOrganizationUnitDialogComponent, {
      data: {
        title: this.$translateService.instant(_('publish_activity_dialog.other.select.dialog.title')),
        description: this.$translateService.instant(_('publish_activity_dialog.other.select.dialog.description')),
        writeableOnly: true,
      },
    });

    const selection: ISelectedOrganizationUnit[] = await firstValueFrom(dialogRef.afterClosed());
    const result = selection && selection[0];
    if (result) {
      this.changeOwner({
        id: result.id,
        name: result.name,
        type: result.organization_unit_type_id,
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async onConfirm() {
    if (this.selectedOwner.id !== this.originalOwner.id) {
      const assignments: IOrganizationUnitActivityAssignment[] = this.availableRoles.map((role) => ({
        activity_id: role.activity_id,
        organization_unit_id: role.organization_unit_id,
        organization_unit_role_id: role.organization_unit_role_id,
      }));

      const originalAssignment = assignments.find((assignment) => assignment.organization_unit_id === this.originalOwner.id);
      const targetAssignment = assignments.find((assignment) => assignment.organization_unit_id === this.selectedOwner.id);

      // Make sure the original assignment remains the OWNER
      //
      if (originalAssignment) {
        originalAssignment.organization_unit_role_id = DoenkidsStaticValuesHelper.ACTIVITY_OWNER_ROLE;
      }

      // Change or set the target assignment to READER
      //
      if (targetAssignment) {
        targetAssignment.organization_unit_role_id = DoenkidsStaticValuesHelper.ACTIVITY_READER_ROLE;
      } else {
        assignments.push({
          activity_id: originalAssignment.activity_id,
          organization_unit_id: this.selectedOwner.id,
          organization_unit_role_id: DoenkidsStaticValuesHelper.ACTIVITY_READER_ROLE,
        });
      }

      await firstValueFrom(this.activityService.updateOrganizationUnits(get(assignments, '0.activity_id'), { assignment: assignments }));
    }
    this.dialogRef.close('confirm');
  }
}
