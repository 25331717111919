import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IKonnectOrganisationDetails, ICreateKonnectOrganisation } from 'typings/api-customer';
import { IServiceResponse } from 'typings/api-generic';
import { KonnectOrganizationStore } from './konnect-organization.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KonnectOrganizationService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: KonnectOrganizationStore) {}

  @transaction()
  async create(konnectOrganization: ICreateKonnectOrganisation) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall('/customer/konnect/organisation', konnectOrganization)) as IKonnectOrganisationDetails;
    if (response) {
      this.store.upsert(response.id, response);
    }

    return response;
  }

  @transaction()
  async fetch(id: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/konnect/organisation/${id}`)) as IKonnectOrganisationDetails;
    if (response) {
      this.store.update(response);
    }
    return response;
  }

  async update(konnectOrganizationId: number, konnectOrganization: ICreateKonnectOrganisation) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/konnect/organisation/${konnectOrganizationId}`,
      konnectOrganization)) as IKonnectOrganisationDetails;
    if (response) {
      this.store.upsert(response.id, response);
    }
    return response;
  }

  async archive(konnectOrganizationId: number) {
    const result = await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/konnect/organisation/${konnectOrganizationId}`)) as IServiceResponse;
    this.store.remove(konnectOrganizationId);
    return result;
  }

  async setOrganizationUnit(konnectOrganizationId: number, organizationUnitId: number) {
    const result = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/konnect/organisation/${konnectOrganizationId}/link/${organizationUnitId}`, null)) as IKonnectOrganisationDetails;
    if (result) {
      this.store.upsert(result.id, result);
    }
    return result;
  }

  async unsetOrganizationUnit(konnectOrganizationId: number) {
    const result = await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/konnect/organisation/${konnectOrganizationId}/link`)) as IKonnectOrganisationDetails;
    if (result) {
      this.store.upsert(result.id, result);
    }
    return result;
  }

  sync(konnectOrganizationId: number) {
    return firstValueFrom(this.$baseApi.genericGetCall(`/customer/konnect/sync/${konnectOrganizationId}`)) as Promise<IServiceResponse>;
  }
}
