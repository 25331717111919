import { Injectable } from '@angular/core';
import { IServiceResponse } from 'typings/api-generic';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { firstValueFrom } from 'rxjs';
import { IOrganizationUnitTagAll } from 'typings/doenkids/doenkids';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  async create(organizationUnitId: number, tagName: string) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall('/activity/tag/', { name: tagName }, null, { organizationUnitId })) as IServiceResponse;
    return response;
  }

  update(tag: IOrganizationUnitTagAll) {
    return firstValueFrom(this.$baseApi.genericPutCall(`/activity/tag/${tag.id}`, { id: tag.id, name: tag.name }));
  }

  async remove(tagId: number) {
    const response = await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/tag/${tagId}`)) as IServiceResponse;
    return response;
  }
}
