<app-doenkids-menu-bar> </app-doenkids-menu-bar>

<div class="newsfeed-container" fxLayout="column">
  <div fxLayoutAlign="start center" class="news-header">
    <h1>{{ 'base_news_items.heading' | translate }}</h1>
  </div>
  <p class="description">{{ 'base_news_items.description' | translate }}</p>

  <div class="newsfeed-wrapper">
    <app-newsfeed [organizationUnitId]="1" [isBaseNewsItems]="true" [baseNewsItems]="newsItems$ | async" #newsFeed></app-newsfeed>
  </div>
</div>
<router-outlet></router-outlet>
