<app-upload-progress-bar [uploadData]="uploadData"></app-upload-progress-bar>
<div fxLayout="row wrap inline" fxLayoutAlign="center center" fxLayoutGap="10px" fxLayout.lt-md="column">
  <button mat-button type="button" (click)="openImageSearch()" [disabled]="disabled" *ngIf="shouldShowSearchOption()">
    <mat-icon>search</mat-icon> {{ 'generic.select' | translate }}
  </button>
  <button mat-button type="button" [disabled]="disabled" (click)="openFile()">
    <mat-icon>note_add</mat-icon>&nbsp;
    {{ 'generic.upload_for_purpose' | translate: {purpose: ('select_media.purpose.' + purpose.replace('-', '_')) | translate} }}
  </button>
  <button mat-button type="button" [disabled]="disabled" *ngIf="(isAdmin$ | async) && (mediaItemUuid && mediaItemUuid !== '')" (click)="replaceImage()">
    <mat-icon>sync</mat-icon>&nbsp;
    {{ 'generic.upload_for_replacement' | translate: {purpose: ('select_media.purpose.' + purpose.replace('-', '_')) | translate | lowercase} }}
  </button>
  <button [disabled]="disabled" *ngIf="removable" mat-button type="button" (click)="remove()">
    <mat-icon>close</mat-icon> {{ 'generic.remove' | translate }}
  </button>
</div>
