export const environment = {
  production: true,
  enabledLanguages: ['nl-NL', 'nl-BE', 'en-GB'],
  testEnabledLanguages: ['nl-NL', 'nl-BE', 'en-GB'],
  defaultLanguage: 'nl-NL',
  angularLanguageLocaleMap: { 'nl-NL': 'nl', 'nl-BE': 'nl-BE', 'en-GB': 'en-GB' },
  activityImagePlaceholder: './assets/images/activities/activity-placeholder.png',
  avatarPlaceholder: './assets/images/activities/activity-placeholder.png',
  activity404Image: './assets/images/activities/activity-404.jpg',
  activityBlockedImage: './assets/images/activities/activity-blocked.jpg',
  poweredByLogo: './assets/images/logo-kidskonnect.png',
  showLogoAboveNavigation: true,
  auth0Tennant: {
    baseUrl: 'https://cdn.eu.auth0.com',
    clientId: 'aCfYEr6rEUYBzmOhTkm1E7Rop17kCx8g',
    domain: 'login.doenkids.nl',
    audience: 'https://api.doenkids.nl',
    title: 'DoenKids',
    logoUrl: './assets/images/logo-doenkids.png',
    themeColor: '#dd1571',
  },
};
