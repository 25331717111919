<h2 mat-dialog-title>{{ 'review_activity_dialog.title' | translate }}</h2>
<mat-dialog-content>
  <ng-container *ngIf="selectedOwner?.id !== 1 && !(activitySource$ | async)">
    <p [innerHtml]="'review_activity_dialog.review_description' | translate: {ownerName: selectedOwner.name }"></p>
    <p>
      <span [innerHtml]="'review_activity_dialog.reviewer.select.description.0' | translate"></span>
      <a class="inline-pointer" (click)="selectOther()">
        {{ 'review_activity_dialog.reviewer.select.description.1' | translate }}
      </a>
      <span
        [innerHtml]="'review_activity_dialog.reviewer.select.description.2' | translate: {
          ownerName: selectedOwner?.name
        }"
      ></span>
    </p>
  </ng-container>
  <ng-container *ngIf="selectedOwner?.id !== 1 && (activitySource$ | async)">
    <p>{{ 'review_activity_dialog.review.higher_level.impossible.is_copy' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="selectedOwner?.id === 1">
    <p>{{ 'review_activity_dialog.review.higher_level.impossible.doenkids' | translate }}</p>
  </ng-container>

  <ng-container *ngVar="(activityCopies$ | async) as activityCopies">
    <mat-action-list *ngIf="activityCopies?.length > 0">
      <div mat-subheader>
        {{ 'review_activity_dialog.activity_copies.label' | translate }}
      </div>
      <button mat-list-item class="list-button" (click)="goToActivity(copy)" *ngFor="let copy of activityCopies">
        <mat-icon matListItemIcon>content_copy</mat-icon>
        <div matListItemTitle>{{ getOrganizationName(copy) }}</div>
      </button>
      <ng-container *ngIf="(activitySource$ | async) as source">
        <mat-divider></mat-divider>
        <div mat-subheader >Bron</div>
        <button mat-list-item class="list-button" (click)="goToActivity(source)">
          <mat-icon matListItemIcon>source</mat-icon>
          <div matListItemTitle>{{ source.name }} ({{ getOrganizationName(source) }})</div>
        </button>
      </ng-container>
    </mat-action-list>
  </ng-container>

  <p *ngIf="!(activitySource$ | async)" [innerHtml]="'review_activity_dialog.description' | translate"></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <span class="spacer"></span>
  <button
    mat-button
    color="primary"
    [disabled]="!selectedOwner?.id || selectedOwner?.id === 1 || (activitySource$ | async) !== null || (isReviewing$ | async)"
    (click)="onConfirm()"
  >
    {{ 'generic.ok' | translate }}
  </button>
</mat-dialog-actions>
