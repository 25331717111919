import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IKonnectGroup } from 'typings/doenkids/doenkids';

export interface IKonnectGroupState extends EntityState<IKonnectGroup> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'konnect-group' })
export class KonnectGroupStore extends EntityStore<IKonnectGroupState, IKonnectGroup> {
  constructor() {
    super({});
  }
}
