import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IUserDetails } from 'typings/api-customer';
import { IUserListState, UserListStore } from './user-list.store';

@Injectable({ providedIn: 'root' })
export class UserListQuery extends QueryEntity<IUserListState, IUserDetails> {
  constructor(protected store: UserListStore) {
    super(store);
  }

  getMetadata() {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
      query: value.query,
    }));
  }
}
