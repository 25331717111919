import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IUserEventPayload } from 'typings/api-customer';

@Injectable({ providedIn: 'root' })
export class EventLogService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  async userEvent(event: IUserEventPayload) {
    try {
      return await firstValueFrom(this.$baseApi.genericPostCall('/customer/event-log', event));
    } catch (error) {
      return null;
    }
  }
}
