import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProgramTemplate } from 'typings/doenkids/doenkids';
import { IProgramTemplateBaseListState, ProgramTemplateBaseListStore } from './program-template-base-list.state';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateBaseListQuery extends QueryEntity<IProgramTemplateBaseListState, IProgramTemplate> {
  constructor(protected store: ProgramTemplateBaseListStore) {
    super(store);
  }
}
