<h1 mat-dialog-title>{{ 'period_activity_selection_dialog.title' | translate }}</h1>
<div mat-dialog-content>{{ 'period_activity_selection_dialog.description' | translate }}</div>
<div mat-dialog-actions>
  <button mat-button color="dark" (click)="closeModal()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button color="accent" (click)="closeModal('create')">
    {{ 'generic.create' | translate }}</button>
  <button mat-button color="primary" (click)="closeModal('select')">
    {{ 'generic.select' | translate }}
  </button>
</div>
