
import {
  Component, OnInit, HostListener, ExistingProvider, forwardRef, OnDestroy,
} from '@angular/core';
import {
  Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup,
} from '@angular/forms';
import { noop } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { IMaterialListCategory, IMaterialListUnit } from 'typings/doenkids/doenkids';
import { IActivityMaterial } from 'typings/section-types';

export const SECTION_ACTIVITY_MATERIAL_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ActivityMaterialSectionComponent),
  multi: true,
};

interface IActivityMaterialSectionForm {
  title: FormControl<string>;
  subtitle?: FormControl<string>;
  content?: FormControl<string>;
}

@Component({
  selector: 'app-activity-material-section',
  templateUrl: './activity-material-section.component.html',
  styleUrls: ['./activity-material-section.component.scss'],
  providers: [SECTION_ACTIVITY_MATERIAL_CONTROL_VALUE_ACCESSOR],
})
export class ActivityMaterialSectionComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private stop$ = new Subject<void>();

  private countryCodeChange$ = new Subject<void>();

  public form: FormGroup<IActivityMaterialSectionForm>;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: IActivityMaterial) => void = noop;

  public activityCategories$: Observable<IMaterialListCategory[]>;

  public activityUnits$: Observable<IMaterialListUnit[]>;

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  constructor() {
    this.form = new FormGroup<IActivityMaterialSectionForm>({
      title: new FormControl('', Validators.required),
      subtitle: new FormControl(),
      content: new FormControl(''),
    });
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((formValue: IActivityMaterial) => {
      this.onChangeCallback(formValue);
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  writeValue(value: IActivityMaterial) {
    if (value) {
      this.form.get('title').setValue(value.title || '');
      this.form.get('subtitle').setValue(value.subtitle || '');
      this.form.get('content').setValue(value.content || '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
