import {
  Component, OnInit, OnDestroy, Output, EventEmitter, Input, SimpleChanges, OnChanges,
} from '@angular/core';
import { takeUntil,
} from 'rxjs/operators';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { IUserDetails } from 'typings/api-customer';
import { OrganizationUnitUsersListService } from 'src/api/customer/organization-unit-users-list/organization-unit-users-list.service';

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss'],
})
export class OrganizationUsersComponent implements OnInit, OnChanges, OnDestroy {
  private stop$: Subject<void> = new Subject();

  public MAX_ITEMS_PER_PAGE = 15;

  public currentPage: number;

  public type = '';

  public list$: BehaviorSubject<IUserDetails[]> = new BehaviorSubject([]);

  public _listLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public isAdmin$: Observable<boolean>;

  @Output()
  public listLoading$: EventEmitter<boolean> = new EventEmitter();

  public metaData$: BehaviorSubject<{ limit: number, skip: number, total: number }> = new BehaviorSubject({ limit: this.MAX_ITEMS_PER_PAGE, skip: 0, total: 0 });

  displayedColumns: string[] = ['email', 'active', 'role', 'last_seen', 'created_at'];

  @Input() organizationUnitId: number;

  public hasOUWritePermissions$: Observable<boolean>;

  constructor(
    $permission: PermissionProvider,
    private organizationUnitUsersListService: OrganizationUnitUsersListService,
    $session: DoenkidsSessionProvider,
  ) {
    this.isAdmin$ = $session.isAdmin$;
    this.hasOUWritePermissions$ = $permission.hasOUWritePermissions$;
  }

  async ngOnInit() {
    this.fetchList();

    this._listLoading$.pipe(takeUntil(this.stop$)).subscribe((loading) => {
      this.listLoading$.emit(loading);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizationUnitId && changes.organizationUnitId?.firstChange) {
      this.fetchList();
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  /**
   * @param {number} page The page number to switch to
   */
  public async paginate(page: number) {
    const metadata = await firstValueFrom(this.metaData$);
    const newSkip = (page * metadata.limit) - metadata.limit;

    if (this.currentPage !== page) {
      this.currentPage = page;
    }
    this.metaData$.next({
      limit: this.MAX_ITEMS_PER_PAGE,
      skip: newSkip,
      total: metadata.total,
    });
  }

  private async fetchList() {
    this.currentPage = 0;
    this._listLoading$.next(true);

    let result: IUserDetails[] = await this.organizationUnitUsersListService.fetchAll(this.organizationUnitId, {
      limit: 5000,
      skip: 0,
      sortField: 'created_at',
      sortDirection: 'DESC',
      search: '',
      active: true,
      nodeOnly: true,
    });

    result = this.filterOnLinkedOU(result);

    this.metaData$.next({
      limit: this.MAX_ITEMS_PER_PAGE,
      skip: 0,
      total: result?.length ?? 0,
    });

    this.list$.next(result);
    this._listLoading$.next(false);
  }

  filterOnLinkedOU(users: IUserDetails[]) {
    const filteredUsers: IUserDetails[] = [];

    for (const user of users) {
      const parsedLinkedOrganizationUnitIds = (user.linked_organization_unit_ids ?? []).map((ouId) => parseInt(`${ouId}`, 10));
      if (parsedLinkedOrganizationUnitIds?.includes(this.organizationUnitId)) {
        filteredUsers.push(user);
      }
    }

    return filteredUsers;
  }
}
