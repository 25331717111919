import { Injectable, Inject } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams, HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Subject, of } from 'rxjs';
import {
  get, isObject, isNil, isUndefined,
} from 'lodash';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DoenKidsAuth0Provider } from './auth0.provider';
import { ERROR_TOAST_CONFIG } from './i18n-toast.provider';

type IHttpResponseTypes = 'arraybuffer' | 'blob' | 'json' | 'text';

/**
 * The list of locations this app should
 */
export const TEST_LOCATIONS = [
  'doenkids-admin-test.firebaseapp.com',
  'doenkids-admin-test.web.app',
  'blossombeinspired-admin-test.web.app',
  'blossombeinspired-admin-test.firebaseapp.com',
  'localhost',
];

const API_END_POINTS = {
  testing: {
    basePath: 'https://api-tst-doenkids.springtree.io',
  },
  production: {
    basePath: 'https://api-prod-doenkids.springtree.io',
  },
};

@Injectable()
export class DoenKidsGenericApiProvider {
  public API_END_POINT: string;

  private loginRequiredSubject$: Subject<any> = new Subject<any>();

  /**
   * Image cache to minimize the number of HTTP GET calls for media items
   */
  private IMAGE_CACHE = {} as { [ uuid: string ]: { ts: number; url: string; } };
  // private currentUserSubject$: BehaviorSubject<IUser> = new BehaviorSubject(null);

  getAuthorizationHeader() {
    const token = this.$auth0Provider.token();

    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }

  private getErrorDescription(error: any): string {
    const message = get(error, 'error.message');
    if (message && message === 'JWT validation failed: TIME_CONSTRAINT_FAILURE') {
      return '';
    }

    if (message) {
      return message;
    }

    return JSON.stringify(error, null, ' ');
  }

  /**
   * On error show the error message. On 401, go to the login page
   * @param error
   */
  genericErrorHandler(error) {
    console.error('[DOENKIDS] Error:', error);

    if (error instanceof HttpErrorResponse && error.status === 401) {
      this.loginRequiredSubject$.next(true);
    } else {
      const errorDescription = this.getErrorDescription(error);
      if (errorDescription) {
        this.$toastProvider.open(errorDescription, null, ERROR_TOAST_CONFIG);
      }
    }
  }

  isLoginRequired() {
    return this.loginRequiredSubject$.asObservable();
  }

  // selectCurrentUser() {
  //   return this.currentUserSubject$.asObservable();
  // }

  genericListCall(url: string, limit: number, skip: number, sortField: string = 'created_at', sortDirection: string = 'DESC',
    optionalParams?: { [ key: string ]: string | number | boolean }) {
    let params = new HttpParams()
      .set('limit', `${limit}`)
      .set('skip', `${skip}`)
      .set('sortField', sortField)
      .set('sortDirection', sortDirection);

    // Add optional parameters
    //
    if (optionalParams) {
      Object.keys(optionalParams).forEach((key) => {
        if (!isNil(optionalParams[key]) && !isUndefined(optionalParams[key]) && (typeof optionalParams[key] === 'string' ? optionalParams[key] !== '' : true)) {
          params = params.append(key, `${optionalParams[key]}`);
        }
      });
    }

    return this.$http.get(this.API_END_POINT + url, {
      headers: this.getAuthorizationHeader(),
      params,
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        return EMPTY;
      }),
    );
  }

  /**
   * Any changes here should also be added to genericPostCallWithoutErrorHandler
   * @param url
   * @param data
   * @param headers
   * @param optionalParams
   */
  genericPostCall(url: string, data: any, headers?: Map<string, any>, optionalParams?: { [ key: string ]: string | number | boolean }) {
    const requestHeaders = this.getAuthorizationHeader();

    let params = new HttpParams();

    // Add optional parameters
    //
    if (optionalParams) {
      Object.keys(optionalParams).forEach((key) => {
        if (optionalParams[key]) {
          params = params.append(key, `${optionalParams[key]}`);
        }
      });
    }

    if (headers) {
      Array.from(headers.keys()).forEach((headerKey) => {
        requestHeaders.append(headerKey, headers.get(headerKey));
      });
    }

    return this.$http.post(this.API_END_POINT + url, data, {
      headers: requestHeaders,
      params,
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        return EMPTY;
      }),
    );
  }

  /**
   * Any changes here should also be added to genericPostCall
   * @param url
   * @param data
   * @param headers
   * @param optionalParams
   */
  genericPostCallWithoutErrorHandler(url: string, data: any, headers?: Map<string, any>, optionalParams?: { [ key: string ]: string | number | boolean }) {
    const requestHeaders = this.getAuthorizationHeader();

    let params = new HttpParams();

    // Add optional parameters
    //
    if (optionalParams) {
      Object.keys(optionalParams).forEach((key) => {
        if (optionalParams[key]) {
          params = params.append(key, `${optionalParams[key]}`);
        }
      });
    }

    if (headers) {
      Array.from(headers.keys()).forEach((headerKey) => {
        requestHeaders.append(headerKey, headers.get(headerKey));
      });
    }

    return this.$http.post(this.API_END_POINT + url, data, {
      headers: requestHeaders,
      params,
    });
  }

  uploadFile(url: string, file: File) {
    const headers = this.getAuthorizationHeader();
    headers.set('Accept', 'multipart/form-data');

    const data = new FormData();

    data.append('file', file);

    return this.$http.post(url, data, {
      headers,
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        return EMPTY;
      }),
    );
  }

  uploadImage(url: string, body: any, optionalParams?) {
    return this.$http.post(this.API_END_POINT + url, body, {
      headers: this.getAuthorizationHeader(),
      responseType: 'blob',
      params: optionalParams || {},
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        return EMPTY;
      }),
    );
  }

  getExcelCall(url) {
    return this.$http.get(this.API_END_POINT + url, {
      headers: this.getAuthorizationHeader(),
      responseType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as any,
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        throw error as any;
      }),
    );
  }

  genericGetCall(url: string, type: IHttpResponseTypes = 'json') {
    return this.$http.get(this.API_END_POINT + url, {
      headers: this.getAuthorizationHeader(),
      responseType: type as 'json',
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        return EMPTY;
      }),
    );
  }

  genericGetCallWithoutErrorHandler(url: string, type: IHttpResponseTypes = 'json') {
    return this.$http.get(this.API_END_POINT + url, {
      headers: this.getAuthorizationHeader(),
      responseType: type as 'json',
    });
  }

  genericPutCall(url: string, data?: any, optionalParams?: { [ key: string ]: string | number }) {
    let params = new HttpParams();

    // Add optional parameters
    //
    if (optionalParams) {
      Object.keys(optionalParams).forEach((key) => {
        if ((typeof optionalParams[key] === 'string' && optionalParams[key] !== '')
            || (typeof optionalParams[key] === 'number' && !Number.isNaN(optionalParams[key]))) {
          params = params.append(key, `${optionalParams[key]}`);
        }
      });
    }

    return this.$http.put(this.API_END_POINT + url, data, {
      headers: this.getAuthorizationHeader(),
      params,
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        return EMPTY;
      }),
    );
  }

  genericDeleteCall(url: string) {
    return this.$http.delete(this.API_END_POINT + url, {
      headers: this.getAuthorizationHeader(),
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.genericErrorHandler(error);
        return EMPTY;
      }),
    );
  }

  getBuildJson() {
    if (environment.production && !window.location.hostname.includes('localhost')) {
      return this.$http.get('./build.json', { responseType: 'json' });
    }
    // Can't use root files when in local host, return dummy object
    return of({ version: '0.0.0' });
  }

  /**
   * Get from cache method
   * Tries to see if the URL is still valid and returns the URL from cache
   * URL's have a lifespan of 5 minutes (300000 milliseconds)
   * When we take network latancy (1s) into account we calcluate with 299000 milliseconds
   */
  getImageFromCache(uuid: string): string | null {
    let result = null;
    const cacheEntry = this.IMAGE_CACHE[uuid];
    if (isObject(cacheEntry)) {
      if (Date.now() - cacheEntry.ts < 2999000) {
        result = cacheEntry.url;
      }
    }
    return result;
  }

  /**
   * Add image Url to cache
   */
  addImageToCache(uuid: string, url: string) {
    this.IMAGE_CACHE[uuid] = {
      ts: Date.now(),
      url,
    };
  }

  /**
   * @param value to copy
   * @author Sangram Nandkhile
   * @see https://stackoverflow.com/a/49121680/4047409
   */
  public copyToClipboard(value: string) {
    const copyElement = this.document.createElement('textarea');
    copyElement.style.position = 'fixed';
    copyElement.style.left = '0';
    copyElement.style.top = '0';
    copyElement.style.opacity = '0';
    copyElement.value = value;
    this.document.body.appendChild(copyElement);
    copyElement.focus();
    copyElement.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(copyElement);
  }

  appendQueryParam(url: string, paramKey: string, paramValue: any) {
    if (!isUndefined(paramValue) && ((typeof paramValue === 'string' && paramValue !== '') || (typeof paramValue === 'number' && !isNaN(paramValue)) || (typeof paramValue === 'boolean'))) {
      // if we don't have a question mark in the url it means we don't have query params yet
      // so we need to add the first one
      //
      if (!url.includes('?')) {
        return `${url}?${paramKey}=${paramValue}`;
      }

      return `${url}&${paramKey}=${paramValue}`;
    }

    return url;
  }

  constructor(
    private $http: HttpClient,
    private $auth0Provider: DoenKidsAuth0Provider,
    private $toastProvider: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.resolveEndPoint();
  }

  resolveEndPoint() {
    const myWindow = (window as Window);
    if (TEST_LOCATIONS.indexOf(myWindow.location.hostname) !== -1) {
      this.API_END_POINT = API_END_POINTS.testing.basePath;
    } else {
      this.API_END_POINT = API_END_POINTS.production.basePath;
    }
  }
}
