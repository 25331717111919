import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-period-activity-section-dialog',
  templateUrl: './period-activity-section-dialog.component.html',
  styleUrls: ['./period-activity-section-dialog.component.scss'],
})
export class PeriodActivitySectionDialogComponent {
  constructor(public dialogRef: MatDialogRef<PeriodActivitySectionDialogComponent>) { }

  closeModal(message?: string) {
    this.dialogRef.close(message);
  }
}
