import {
  ComponentRef,
  Directive,
  DoCheck,
  Input,

  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { NoResultsComponent } from '../components/shared/no-results/no-results.component';

/**
 * This directive is useful when you want to make it known to the user that there are no results.
 * If you pass a truthy value into the directive, it will not render the content within it but show
 * a disclaimer instead.
 * By default it will say "Geen resultaten gevonden"
 */
@Directive({
  selector: '[noContent]',
})
export class NoContentDirective implements DoCheck {
  private _componentRef: ComponentRef<NoResultsComponent>;

  private _title: string;

  @Input() set noContent(isLoading: boolean) {
    this.viewContainer.clear();

    if (isLoading) {
      this._componentRef = this.viewContainer.createComponent(NoResultsComponent);
    } else {
      this._componentRef = undefined;
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  @Input() set noContentTitle(title: string) {
    this._title = title;
  }

  ngDoCheck(): void {
    if (this._componentRef) {
      this._componentRef.instance.title = this._title;
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}
}
