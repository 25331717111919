<form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.title' | translate }}</mat-label>
      <input type="text" formControlName="title" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.subtitle' | translate }}</mat-label>
      <input type="text" formControlName="subtitle" matInput>
    </mat-form-field>
  </div>
  <div formArrayName="media" fxLayout="row wrap" fxLayoutGap="18px">
    <div *ngFor="let media of mediaFormArray?.controls; let index = index" appearance="outline" class="media-item section-form-field w-100" [formGroupName]="index">
      <div class="image-section-container w-100">
        <app-doenkids-image [maxWidth]="250" [maxHeight]="400" [preview]="true" [mediaItem]="media.value" [canReplaceImage]="isAdmin$ | async"></app-doenkids-image>
        <button *ngIf="!form.disabled" type="button" class="close-button" matSuffix mat-icon-button aria-label="Remove" (click)="mediaFormArray.removeAt(index);">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="image-input-fields">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ 'generic.description' | translate }}</mat-label>
          <input type="text" formControlName="description" matInput>
        </mat-form-field>
        <mat-form-field *ngIf="media.get('originType')" appearance="outline" class="w-100">
          <mat-label>{{ 'media.origin_type.label' | translate }}</mat-label>
          <mat-select formControlName="originType">
            <mat-option [value]="'unknown'">{{ 'media.origin_type.unknown' | translate | titlecase }}</mat-option>
            <mat-option *ngFor="let originType of (originTypeOptions$ | async)" [value]="originType?.id">
              {{ originType?.name | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button type="button" *ngIf="(isAdmin$ | async) && media" (click)="replaceImage(media)">
          <mat-icon>sync</mat-icon>&nbsp;
          {{ 'generic.upload_for_replacement' | translate: {purpose: (purpose ? ('select_media.purpose.' + purpose.replace('-', '_')) : '') | translate | lowercase} }}
        </button>
      </div>
    </div>
  </div>
</form>
