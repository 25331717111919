import { Injectable } from '@angular/core';
import {
  BehaviorSubject, Subject,
} from 'rxjs';
import { IActivity, ISearchActivity } from 'typings/doenkids/doenkids';
import { FavoriteFolderService } from 'src/api/activity/favorite-folder/favorite-folder.service';
import { IStoreFavoriteFolderDetails } from 'src/api/activity/favorite-folder/favorite-folder.store';
import { FavoriteFolderQuery } from 'src/api/activity/favorite-folder/favorite-folder.query';
import { takeUntil } from 'rxjs/operators';
import { DoenkidsSessionProvider } from './session.provider';

export const PINBORD_FOLDER_LIMIT = 9999;
@Injectable({
  providedIn: 'root',
})
export class PinbordProvider {
  public currentFolders$: BehaviorSubject<IStoreFavoriteFolderDetails> = new BehaviorSubject<IStoreFavoriteFolderDetails>({
    organizationUnitId: 0,
    folders: [],
    totalNoOfFolders: 0,
  });

  private FOLDER_LIMIT = PINBORD_FOLDER_LIMIT;

  private ouChange$ = new Subject<void>();

  constructor(
    private $session: DoenkidsSessionProvider,
    private $favoriteFolder: FavoriteFolderService,
    private favoriteFolderQuery: FavoriteFolderQuery,
  ) {
    this.$session.currentOuId$.subscribe((ouId) => {
      this.ouChange$.next();
      this.favoriteFolderQuery.selectEntity(ouId).pipe(takeUntil(this.ouChange$)).subscribe((ouFavoriteFolders) => {
        this.currentFolders$.next(ouFavoriteFolders);
      });
      this.fetchFolders(ouId);
    });
  }

  public async fetchFolders(organizationUnitId: number) {
    await this.$favoriteFolder.fetchAll(organizationUnitId, this.FOLDER_LIMIT, 0, 'created_at', 'desc', true);
  }

  public async fetchMoreFolders(organizationUnitId: number) {
    const currentNoOfFolders = this.favoriteFolderQuery.getEntity(organizationUnitId).folders.length;
    await this.$favoriteFolder.fetchAll(organizationUnitId, this.FOLDER_LIMIT, currentNoOfFolders);
  }

  public async addFolder(organizationUnitId: number, name: string) {
    return this.$favoriteFolder.create(organizationUnitId, name);
  }

  public async updateFolder(organizationUnitId: number, folderId: number, name: string) {
    return this.$favoriteFolder.update(organizationUnitId, folderId, name);
  }

  async removeFolder(organizationUnitId: number, folderId: number) {
    await this.$favoriteFolder.archive(organizationUnitId, folderId);
  }

  public async addActivitiesToFolder(folderId: number, activities: (ISearchActivity | IActivity)[]) {
    for (const activity of activities) {
      // eslint-disable-next-line no-await-in-loop
      await this.$favoriteFolder.addActivityToFolder(folderId, activity);
    }
  }

  public removeActivityFromFolder(folderId: number, activity: ISearchActivity | IActivity) {
    return this.$favoriteFolder.removeActivityFromFolder(folderId, activity);
  }
}
