import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDialogComponent {
  public form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    fb: UntypedFormBuilder,
  ) {
    this.form = fb.group({
      user: [],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCloseToConnect(): void {
    this.onClose(this.form.get('user').value, true);
  }

  onOkClick() {
    this.onClose(this.form.get('user').value);
  }

  onClose(user?: any, chooseOu: boolean = false): void {
    this.dialogRef.close({
      user,
      chooseOu,
    });
  }
}
