import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IAgeGroupDetailsListResponse } from 'typings/api-activity';
import { AgeGroupStore } from './age-group.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgeGroupService {
  constructor(private store: AgeGroupStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const ageGroupListRepsonse = await firstValueFrom(this.$baseApi.genericListCall('/activity/age-group', limit, skip, sortField, sortDirection)) as IAgeGroupDetailsListResponse;
    this.store.set(ageGroupListRepsonse?.items ?? []);
    return ageGroupListRepsonse?.items ?? [];
  }
}
