import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ICustomerSymbol } from 'typings/doenkids/doenkids';
import { OrganizationUnitSymbolListStore, IOrganizationUnitSymbolListState } from './organization-unit-symbol-list.store';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitSymbolListQuery extends QueryEntity<IOrganizationUnitSymbolListState, ICustomerSymbol> {
  constructor(protected store: OrganizationUnitSymbolListStore) {
    super(store);
  }
}
