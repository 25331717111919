import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IKvsMethodListResponse, IKvsObservationPointListResponse } from 'typings/api-activity';
import { IKvsAreaOfDevelopmentSubsection, IKvsMethodWithObservationPoints, KvsMethodStore } from './kvs-method.store';
import { firstValueFrom } from 'rxjs';
import { isEqual, max, min } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class KvsMethodService {
  constructor(private store: KvsMethodStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const kvsMethodListResponse = await firstValueFrom(this.$baseApi.genericListCall('/activity/kvs-method', limit, skip, sortField, sortDirection)) as IKvsMethodListResponse;

    const parsedMethodPromises: Promise<IKvsMethodWithObservationPoints>[] = [];
    for (const method of kvsMethodListResponse?.items ?? []) {
      parsedMethodPromises.push(new Promise<IKvsMethodWithObservationPoints>(async (resolve) => {
        const observationPoints = await this.fetchAllObservationPoints(method.code);
        let parsedMethod: IKvsMethodWithObservationPoints = {
          ...method,
          subsections: [],
          min_age_months: 0,
          max_age_months: 144,
        };

        for (const observationPoint of observationPoints) {
          const subsection = parsedMethod.subsections.find((parsedSubsection) =>
            isEqual(parsedSubsection.code, observationPoint.area_of_development_code) || isEqual(parsedSubsection.name, observationPoint.area_of_development));

          if (subsection) {
            if (observationPoint.area_of_development_discipline) {
              const disciplineSubsection = subsection.subsections.find((parsedSubsection) => parsedSubsection.name === observationPoint.area_of_development_discipline);

              if (disciplineSubsection) {
                if (!disciplineSubsection.observationPoints) {
                  disciplineSubsection.observationPoints = [];
                }
                disciplineSubsection.observationPoints.push(observationPoint);
              } else {
                if (!subsection.subsections) {
                  subsection.subsections = [];
                }
                subsection.subsections.push({
                  name: observationPoint.area_of_development_discipline,
                  min_age_months: observationPoint.min_age_months,
                  max_age_months: observationPoint.max_age_months,
                  observationPoints: [observationPoint],
                });
              }
            } else {
              if (!subsection.observationPoints) {
                subsection.observationPoints = [];
              }
              subsection.observationPoints.push(observationPoint);
            }
          } else {
            const newSubsection: IKvsAreaOfDevelopmentSubsection = {
              code: observationPoint.area_of_development_code,
              name: observationPoint.area_of_development,
              min_age_months: observationPoint.min_age_months,
              max_age_months: observationPoint.max_age_months,
            };
            if (observationPoint.area_of_development_discipline) {
              newSubsection.subsections = [{
                name: observationPoint.area_of_development_discipline,
                min_age_months: observationPoint.min_age_months,
                max_age_months: observationPoint.max_age_months,
                observationPoints: [observationPoint],
              }];
            } else {
              newSubsection.observationPoints = [observationPoint];
            }
            parsedMethod.subsections.push(newSubsection);
          }
        }

        resolve(parsedMethod);
      }));
    }

    const parsedMethods = await Promise.all(parsedMethodPromises);

    for (const parsedMethod of parsedMethods) {
      for (const subsection of parsedMethod.subsections) {
        if (subsection.subsections) {
          for (const disciplineSubsection of subsection.subsections) {
            disciplineSubsection.min_age_months = min(disciplineSubsection.observationPoints.flatMap((disciplineObservationPoint) => disciplineObservationPoint.min_age_months));
            disciplineSubsection.max_age_months = max(disciplineSubsection.observationPoints.flatMap((disciplineObservationPoint) => disciplineObservationPoint.max_age_months));
          }
        }

        subsection.min_age_months = min([
          ...(subsection.observationPoints ?? []).flatMap((subsectionObservationPoint) => subsectionObservationPoint.min_age_months),
          ...(subsection.subsections ?? []).flatMap((disciplineSubsection) => disciplineSubsection.min_age_months),
        ]);
        subsection.max_age_months = max([
          ...(subsection.observationPoints ?? []).flatMap((subsectionObservationPoint) => subsectionObservationPoint.max_age_months),
          ...(subsection.subsections ?? []).flatMap((disciplineSubsection) => disciplineSubsection.max_age_months),
        ]);
      }

      parsedMethod.min_age_months = min(parsedMethod.subsections.flatMap((subsection) => subsection.min_age_months));
      parsedMethod.max_age_months = min(parsedMethod.subsections.flatMap((subsection) => subsection.max_age_months));
    }

    this.store.set(parsedMethods);

    return kvsMethodListResponse?.items ?? [];
  }

  async fetchAllObservationPoints(methodCode: string, limit: number = 1000, skip: number = 0,
    sortField: string = 'code', sortDirection: SortDirection = 'desc') {
    const observationPoints = await firstValueFrom(this.$baseApi.genericListCall('/activity/kvs-observation-point', limit, skip, sortField, sortDirection,
      { methodCode })) as IKvsObservationPointListResponse;

    return observationPoints.items;
  }
}
