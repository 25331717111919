import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IServiceResponse } from 'typings/api-generic';
import { IActivityListResponse } from 'typings/api-activity';
import { SortDirection } from '@angular/material/sort';
import { ActivityHighlightStore } from './activity-highlight.store';
import { ActivityHighlightQuery } from './activity-highlight.query';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityHighLightService {
  constructor(private store: ActivityHighlightStore, private query: ActivityHighlightQuery, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(params: {
    organizationUnitId: number,
    nodeOnly?: boolean,
    limit?: number,
    skip?: number,
    sortField?: string,
    sortDirection?: SortDirection,
    activityTypeId?: number,
  }) {
    try {
      let url = '/activity/activity/highlight';

      url = this.$baseApi.appendQueryParam(url, 'organizationUnitId', params.organizationUnitId);
      url = this.$baseApi.appendQueryParam(url, 'nodeOnly', params.nodeOnly);
      url = this.$baseApi.appendQueryParam(url, 'limit', params.limit);
      url = this.$baseApi.appendQueryParam(url, 'skip', params.skip);
      url = this.$baseApi.appendQueryParam(url, 'sortField', params.sortField);
      url = this.$baseApi.appendQueryParam(url, 'sortDirection', params.sortDirection?.toUpperCase());
      url = this.$baseApi.appendQueryParam(url, 'activityTypeId', params.activityTypeId);

      const fetchedActivities = await firstValueFrom(this.$baseApi.genericGetCall(url)) as IActivityListResponse;

      this.store.set(fetchedActivities?.items ?? []);

      return fetchedActivities;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  update(organizationUnitId: number, activityId: number) {
    return firstValueFrom(this.$baseApi.genericPutCall(`/activity/activity/highlight/${organizationUnitId}/activity/${activityId}`)) as unknown as IServiceResponse;
  }

  remove(organizationUnitId: number, activityId: number) {
    // eslint-disable-next-line max-len
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/activity/highlight/${organizationUnitId}/activity/${activityId}`)) as unknown as IServiceResponse;
  }
}
