import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { DoenkidsFileDownloader, Download, DownloadQueueItem } from 'src/providers/download-files.provider';

@Component({
  selector: 'app-download-tray-item',
  templateUrl: './download-tray-item.component.html',
  styleUrls: ['./download-tray-item.component.scss'],
})
export class DownloadTrayItemComponent implements OnInit {
  @Input() item: DownloadQueueItem;

  download$: Observable<Download>;

  downloadResult: Download;

  constructor(
    private $fileDownloads: DoenkidsFileDownloader,
  ) {}

  ngOnInit() {
    if (this.item.url) {
      this.download$ = this.$fileDownloads.downloadFile(this.item).pipe(
        tap((download) => {
          this.downloadResult = download;
        }),
        finalize(() => this.finalizeDownload(this.item)),
      );
    } else if (this.item.blob) {
      this.download$ = this.$fileDownloads.downloadBlob(this.item.blob, this.item.name).pipe(
        finalize(() => this.finalizeDownload(this.item)),
      );
    }
  }

  public finalizeDownload(item: DownloadQueueItem) {
    setTimeout(() => this.$fileDownloads.removeDownload(item.id), 5000);
  }
}
