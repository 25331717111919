<h1 mat-dialog-title *ngIf="title">{{title}}</h1>
<div mat-dialog-content>
  <p *ngIf="description" [innerHTML]="description"></p>
  <b *ngIf="selectionOptions?.length === 1">{{ selectionOptions[0].labelShouldBeTranslated ? (selectionOptions[0].label | translate: selectionOptions[0].labelParams ) : selectionOptions[0].label }}</b>
  <mat-form-field appearance="outline" *ngIf="selectionOptions?.length >= 2">
    <mat-label *ngIf="data.selectionOptionLabel">
      {{ (data.selectionOptionLabelShouldBeTranslated ? (data.selectionOptionLabel | translate) : data.selectionOptionLabel) }}
    </mat-label>
    <mat-select [formControl]="optionControl" [placeholder]="('choice_dialog.select.placeholder' | translate)">
      <ng-container *ngFor="let option of data.selectionOptions">
        <mat-option *ngIf="!( checkboxGroup.get(option.disableWhen)?.value )" [value]="option.value">
          {{ option.labelShouldBeTranslated ? (option.label | translate: option.labelParams) : option.label }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <form fxLayout="column" fxFlexFill [formGroup]="checkboxGroup">
    <div fxLayout="row" *ngFor="let checkboxOption of checkboxOptions">
      <mat-checkbox
        *ngIf="shouldShowCheckboxOption(checkboxOption, optionControl.value)"
        color="primary"
        [formControlName]="checkboxOption.value"
      >
        <p>{{ checkboxOption.labelShouldBeTranslated ? (checkboxOption.label | translate: checkboxOption.labelParams) : checkboxOption.label }}</p>
      </mat-checkbox>
    </div>
  </form>
</div>
<div mat-dialog-actions [ngSwitch]="selectionOptions?.length">
  <button *ngIf="!hideCancel" mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <button *ngIf="actions.primaryAction; let action" mat-button color="primary" (click)="confirm( action.value )" [disabled]="action.disableWhen && ( action.disableWhen === optionControl.value || checkboxGroup.get(action.disableWhen)?.value )" cdkFocusInitial>{{ action.labelShouldBeTranslated ? (action.label | translate: action.labelParams) : action.label }}</button>
  <button *ngIf="actions.secondaryAction; let action" mat-button color="accent" (click)="confirm( action.value )" [disabled]="action.disableWhen && ( action.disableWhen === optionControl.value || checkboxGroup.get(action.disableWhen)?.value )">{{ action.labelShouldBeTranslated ? (action.label | translate: action.labelParams) : action.label }}</button>
</div>
