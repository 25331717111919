import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IJibbieLocation } from 'typings/doenkids/doenkids';

export interface IJibbieLocationState extends EntityState<IJibbieLocation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'jibbie-location' })
export class JibbieLocationStore extends EntityStore<IJibbieLocationState, IJibbieLocation> {
  constructor() {
    super({});
  }
}
