import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  OnInit,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus } from '@angular-ex/uploader';
import { IUploadMedia } from 'src/directives/uploadable.directive';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { DoenKidsAuth0Provider } from 'src/providers/auth0.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IUploadResponse } from 'typings/custom-app-types';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';

export enum EUploadType {
  asset,
  LRK,
  CustomerData,
}

// eslint-disable-next-line max-len
export type TPurpose =
  | 'activity'
  | 'font'
  | 'header'
  | 'footer'
  | 'avatar'
  | 'attachment'
  | 'program-attachment'
  | 'template'
  | 'program-template'
  | 'lrk'
  | 'symbol'
  | 'period'
  | 'pinbord_folder'
  | 'program-explanation'
  | 'banner'
  | 'replacement';

@Component({
  selector: 'app-asset-upload',
  templateUrl: './asset-upload.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./asset-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit, OnChanges, OnDestroy {
  private stop$ = new Subject<void>();

  // A template reference is required for the template outlet to work.
  //
  @ContentChild(TemplateRef) template: TemplateRef<{ uploadData: IUploadMedia }>;

  @ViewChild('file')
    fileInput: ElementRef;

  @Input()
    identifier = 'file';

  @Input()
    uploadType: EUploadType = EUploadType.asset;

  @Input()
    purpose: TPurpose = 'activity';

  @Input()
    disabled = false;

  @Input()
    isReplacement = false;

  @Output()
    uploaded = new EventEmitter<IUploadResponse>();

  @Output()
    replacementFile = new EventEmitter<File>();

  uploadInput = new EventEmitter<UploadInput>();

  allowedContentTypes: string[] = [];

  allowedExtensionTypes: string[] = [];

  maxFileSize?: number;

  options: UploaderOptions;

  formData: FormData;

  /* Input events, we use this to emit data to @angular-ex/uploader
   */
  humanizeBytes: Function;

  dragOver: boolean;

  public progress: number;

  /* local uploading files array
   */
  _files: UploadFile[];

  rejectedUpload: UploadOutput;

  files$ = new EventEmitter<UploadFile[]>();

  public get files(): UploadFile[] {
    return this._files;
  }

  /* Emit from files$ every time the local files array is edited.
   */
  public set files(files: UploadFile[]) {
    this._files = files;
    this.files$.emit(this._files);
  }

  private openFile$ = new Subject<void>();

  public uploadData: IUploadMedia = {
    uploaded: this.uploaded.asObservable(),
    files: this.files$.asObservable(),
    openFile$: this.openFile$,
  };

  constructor(
    private $genericAPI: DoenKidsGenericApiProvider,
    private $auth0Provider: DoenKidsAuth0Provider,
    private $session: DoenkidsSessionProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    // Receive files through input here.
    //
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.setAllowedContentTypes();

    this.openFile$.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (this.fileInput) {
        this.fileInput.nativeElement.click();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.purpose && !changes.purpose.firstChange) {
      this.setAllowedContentTypes();
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  private setAllowedContentTypes() {
    if (this.purpose === 'font') {
      // If the purpose is fonts, only ttf files are allowed.
      /** font/ttf',
      'application/x-font-ttf',
      'application/octet-stream',
      'application/x-font-truetype',
      'application/font-sfnt',
      'font/*',
      'application/ttf',
      'application/font-ttf',
      'font/truetype',
      'font/woff',
      'application/font-woff */
      //
      this.allowedExtensionTypes.push('ttf');
      // don't set allowed content types for font as the uploader has issues recognizing the font type on windows machines
      //
      this.allowedContentTypes = undefined;
    } else if (this.purpose === 'lrk') {
      this.allowedContentTypes.push('text/csv');
      this.allowedExtensionTypes.push('csv');
    } else if (this.purpose === 'attachment') {
      this.allowedContentTypes.push('application/pdf');
      this.allowedExtensionTypes.push('pdf');
      this.allowedContentTypes.push('application/vnd.ms-excel');
      this.allowedContentTypes.push('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    } else if (this.purpose !== 'program-attachment' && this.purpose !== 'program-explanation') {
      // If the purpose of an upload is not as an attachment, it must be as an image.
      //
      this.allowedContentTypes.push('image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif');
      this.allowedExtensionTypes.push('png', 'jpg', 'jpeg', 'bmp', 'gif');
    } else {
      // All content types are allowed if the purpose is for the media to be an anything other then defined above.
      this.allowedContentTypes = undefined;
    }

    if (this.purpose === 'symbol') {
      this.maxFileSize = 1000000; // this is 1MB in bytes as it should be in bytes according to docs on npmjs page.
    } else {
      this.maxFileSize = undefined;
    }

    this.options = { concurrency: 1, allowedContentTypes: this.allowedContentTypes, maxFileSize: this.maxFileSize };
  }

  async onUploadOutput(output: UploadOutput) {
    if (this.isReplacement) {
      const nativeFile = output.nativeFile ?? output.file?.nativeFile;
      if (nativeFile) {
        this.replacementFile.emit(nativeFile);
      }
    } else {
      const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
      if (output.type === 'allAddedToQueue') {
        const token = this.$auth0Provider.token();

        let url = this.$genericAPI.API_END_POINT;
        let fieldName;
        let uploadUrl = `/media/asset?purpose=${this.purpose}`;

        switch (this.uploadType) {
          case EUploadType.asset:
            // Optinally set the customer Id
            //
            if (organizationUnit) {
              uploadUrl += `&organizationUnitId=${organizationUnit.id}`;
            }

            url += uploadUrl;
            break;
          case EUploadType.LRK:
            url += '/customer/opendata-lrk';
            fieldName = 'csv';
            break;
          case EUploadType.CustomerData:
            url += `/customer/group/${organizationUnit.id}/import`;
            break;
          default:
            break;
        }

        if (
          this.purpose !== 'font' ||
          (this.purpose === 'font' &&
            this.files.length > 0 &&
            this.files.every((uploadedFile) => {
              const splitFileName = uploadedFile.name.split('.');
              return this.allowedExtensionTypes.includes(splitFileName[splitFileName.length - 1]);
            }))
        ) {
          // Upload file here.
          //
          const event: UploadInput = {
            type: 'uploadAll',
            fieldName,
            headers: {
              Accept: 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
            url,
            method: 'POST',
            file: output.file,
          };
          this.uploadInput.emit(event);
        } else if (this.rejectedUpload) {
          this.showUploadErrorMessage(this.rejectedUpload);
          this.rejectedUpload = undefined;
        }
      } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
        const splitFileName = output.file.name.split('.');
        if (this.purpose !== 'font' || (this.purpose === 'font' && this.allowedExtensionTypes.includes(splitFileName[splitFileName.length - 1]))) {
          this.files.push(output.file);
        } else {
          this.rejectedUpload = output;
          this.removeFile(output.file?.id);
        }
      } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
        const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
        this.files[index] = output.file;
      } else if (output.type === 'removed') {
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
      } else if (output.type === 'dragOver') {
        this.dragOver = true;
      } else if (output.type === 'dragOut') {
        this.dragOver = false;
      } else if (output.type === 'drop') {
        this.dragOver = false;
      } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
        this.showUploadErrorMessage(output);
      } else if (output.type === 'done') {
        const response: IUploadResponse = output?.file?.response;
        if (response) {
          this.uploaded.emit({
            filename: response.filename,
            id: response.id,
            uuid: response.uuid,
            code: response.code,
            message: response.message,
          } as IUploadResponse);
        }
      }

      this.files = this.files.filter((file) => file.progress.status !== UploadStatus.Done);
    }

  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  showUploadErrorMessage(output: UploadOutput) {
    const messageParams = {
      name: output?.file?.name ?? undefined,
      extension: output?.file?.name.match(/^.*\.(?<extension>.+)$/)?.groups?.extension ?? '',
      allowedExtensions: undefined,
      allowedExtensionCount: this.allowedExtensionTypes.length,
    };
    // Find out if the rejected file has a content type that isn't allowed.
    //
    if (output?.file && this.allowedExtensionTypes && !this.allowedExtensionTypes.some((type) => output.file.name.search(type) !== -1)) {
      // Append allowed filetypes.
      //
      for (let i = 0; i < this.allowedExtensionTypes.length; i++) {
        if (i !== this.allowedExtensionTypes.length - 1) {
          // Append a comma if there's more content types to be appended.
          //
          messageParams.allowedExtensions += `${this.allowedExtensionTypes[i]}, `;
        } else {
          // Append the final content type with proper grammar.
          //
          messageParams.allowedExtensions +=
            this.allowedExtensionTypes.length !== 1 ? `${this.$translateService.instant('generic.and')} ${this.allowedExtensionTypes[i]}` : '';
        }
      }
    }

    this.$i18nToastProvider.error(_('asset_upload.file_rejected'), messageParams);
  }
}
