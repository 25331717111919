import {
  Component, OnInit, OnDestroy, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';
import { Observable, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { IUser } from 'typings/doenkids/doenkids';
import { OrganizationUnitUsersListQuery } from 'src/api/customer/organization-unit-users-list/organization-unit-users-list.query';
import {
  takeUntil, filter, map,
} from 'rxjs/operators';
import { OrganizationUnitUsersListService } from 'src/api/customer/organization-unit-users-list/organization-unit-users-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IMediaItem } from 'typings/section-types';
import { OrganizationUnitUserService } from 'src/api/customer/organization-unit-user/organization-unit-user.service';
import { IField } from 'src/components/layout/sortable-list/sortable-list.component';
import { IUserDetails } from 'typings/api-customer';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  BrowseOrganizationUnitDialogComponent, ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { IUploadResponse } from 'typings/custom-app-types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '../../../app/utils/translate.service';
import { I18nTitleStrategy } from '../../../app/utils/i18n-title-strategy';
import { I18nToastProvider } from '../../../providers/i18n-toast.provider';
import { environment } from 'src/environments/environment';

const FIELDS = [
  {
    label: _('user_details.fields.location_name'),
    field: 'location_name',
    width: '30%',
    disableSort: true,
  }, {
    label: _('user_details.fields.activity_type'),
    field: 'type_oko',
    width: '20%',
    disableSort: true,
  }, {
    label: _('user_details.fields.path'),
    field: 'path',
    width: '40%',
    disableSort: true,
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    disableSort: true,
  },
];

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject();

  public isAdmin$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public canEditUsers$: Observable<boolean>;

  public form: UntypedFormGroup;

  private userId: number;

  private ouId: number;

  public nodePathName: string;

  public mediaItem$: Subject<string> = new Subject();

  public fields$: Observable<IField[]>;

  public user$: Observable<IUserDetails>;

  public metaData = new Subject();

  public auth0StatusIsEmailMismatch$: Observable<boolean>;

  public avatarPlaceholder = environment.avatarPlaceholder;

  constructor(
    fb: UntypedFormBuilder,
    private $session: DoenkidsSessionProvider,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private organizationUnitUserService: OrganizationUnitUserService,
    private organizationUnitUsersListQuery: OrganizationUnitUsersListQuery,
    private organizationUnitUsersListService: OrganizationUnitUsersListService,
    private $permissions: PermissionProvider,
    private $translateService: TranslateService,
    private $i18nTitleStrategy: I18nTitleStrategy,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));

    this.canEditUsers$ = combineLatest([
      this.isAdmin$,
      this.$permissions.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$,
    ]).pipe(
      takeUntil(this.stop$),
      map((roles) => roles.includes(true)),
    );

    this.form = fb.group({
      user: [],
    });

    this.fields$ = this.$translateService
      .onInitialTranslationAndLangOrTranslationChange$
      .pipe(
        takeUntil(this.stop$),
        map((langChange) => FIELDS
          .map((field) => ({
            ...field,
            label: field.label === '' ? '' : langChange.translations[field.label],
          }))),
      );
  }

  goToActivities() {
    this.router.navigate([`/organization/${this.ouId}/users/${this.userId}/activity`]);
  }

  async changeOrganization() {
    const dialogRef = this.dialog.open(BrowseOrganizationUnitDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant(_('user_details.user.move.dialog.title')),
        description: this.$translateService.instant(_('user_details.user.move.dialog.description')),
      },
    });

    const selection: ISelectedOrganizationUnit[] = await firstValueFrom(dialogRef.afterClosed());
    const result = selection && selection[0];

    if (result && result.id) {
      // Get the current user.
      //
      const user = await firstValueFrom(this.user$);
      user.organization_unit_id = result.id;
      // Update the user
      //
      await this.organizationUnitUserService.update({ userId: user.id }, user as IUser);
      await this.organizationUnitUserService.addUserToOrganization(user.id, result.id);
      this.organizationUnitUsersListService.fetch(user.id);
      this.router.navigate([`/organization/${result.id}/users/${user.id}`]);
    }
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    this.userId = +params.id; // (+) converts string 'id' to a number
    this.ouId = +params.ou;

    if (isNil(this.userId)) {
      this.$i18nToastProvider.error(_('user_detail.incorrect_id'));
    }

    this.user$ = this.organizationUnitUsersListQuery.selectEntity(this.userId)
      .pipe(takeUntil(this.stop$), filter((values) => !isNil(values)));

    this.auth0StatusIsEmailMismatch$ = this.user$.pipe(
      takeUntil(this.stop$),
      map((user) => (user as any).auth0_status === 'EMAIL_MISMATCH'),
    );

    this.user$.pipe(
      takeUntil(this.stop$),
    ).subscribe((user: IUserDetails) => {
      // Destructure the customer to contain only the needed form elements.
      //
      const {
        email, active, user_role: userRole, media_uuid: mediaUuid,
      } = user;

      this.$i18nTitleStrategy.updateTitleParameters({ user: email });

      this.mediaItem$.next(mediaUuid);

      const total = user?.linked_organization_units?.length ?? 0;
      this.metaData.next({
        limit: total,
        total,
        skip: 0,
      });

      // Patch the customer control.
      //
      this.form.get('user').patchValue({
        email,
        active,
        user_role: userRole,
        media_uuid: mediaUuid,
        id: this.userId,
      });
    });

    this.canEditUsers$.pipe(takeUntil(this.stop$)).subscribe((canEditUsers: boolean) => {
      if (!canEditUsers) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    });

    await this.organizationUnitUsersListService.fetch(this.userId);
  }

  async saveDetails() {
    // Get the user data from the form.
    //
    const formUser = this.form.get('user').value as IUser;

    // Get the current user.
    //
    const user = await firstValueFrom(this.user$);

    // Partial because no e-mail (not allowed to update email)
    //
    const payload: Partial<IUser> = {
      id: user.id,
      active: formUser.active,
      user_role: formUser.user_role,
      media_uuid: formUser.media_uuid,
      email: formUser.email,
    };
    if (payload.media_uuid) {
      this.mediaItem$.next(payload.media_uuid);
    }
    // Update the user
    //
    this.organizationUnitUserService.update({ userId: user.id }, payload as IUser);
  }

  async linkWithOrganization() {
    const alreadyLinkedOus = (await firstValueFrom(this.user$))?.linked_organization_units ?? [];

    const dialogRef = this.dialog.open(BrowseOrganizationUnitDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant(_('user_details.user.link_to_organization.dialog.title')),
        description: this.$translateService.instant(_('user_details.user.link_to_organization.dialog.description')),
        allowMultipleSelection: true,
        initialOrganizationUnitSelection: [],
        disabledOrganizationUnitIds: alreadyLinkedOus.map((ou) => ou.id),
        writeableOnly: true,
      },
    });

    const chosenOus: ISelectedOrganizationUnit[] = await firstValueFrom(dialogRef.afterClosed());

    if (!isNil(chosenOus) && chosenOus.length > 0) {
      const ouNames = [];
      for (const chosenOu of chosenOus) {
        if (!alreadyLinkedOus.find((linkedOu) => linkedOu.id === chosenOu.id)) {
          // eslint-disable-next-line no-await-in-loop
          await this.organizationUnitUserService.addUserToOrganization(this.userId, chosenOu.id);
        }
        ouNames.push(chosenOu.name);
      }

      this.$i18nToastProvider.success(_('user_details.linked_to_organization'), { organizationUnitNames: ouNames.join(', ') });
      this.organizationUnitUsersListService.fetch(this.userId);
    }
  }

  // #region COVER IMAGE =========================
  /**
   * Selects an exiting image to the activity. Replaces the current image if one is present.
   * @param selectedImage an image that was selected
   */
  async updateFeaturedImage(selectedImage: IMediaItem) {
    const user = this.form.get('user').value;
    user.media_uuid = selectedImage.uuid;
    this.form.get('user').setValue(user);
    this.mediaItem$.next(selectedImage.uuid);
    this.saveDetails();
  }

  /**
   * Adds an image to the activity. Replaces the current image if one is present.
   * @param uploadedImage an image that was uploaded to the Doenkids media API.
   */
  async addFeaturedImage(uploadedImage: IUploadResponse) {
    const user = this.form.get('user').value;
    user.media_uuid = uploadedImage.uuid;
    this.form.get('user').setValue(user);
    this.mediaItem$.next(uploadedImage.uuid);
    this.saveDetails();
  }

  //
  // #endregion
  // =============================================/

  async removeLocation(locationId: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('user_details.remove_from_location.confirm.dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result !== 'confirm') {
      return;
    }
    const user = await firstValueFrom(this.user$);
    await this.organizationUnitUserService.archive(locationId, user.id);
    await this.organizationUnitUsersListService.fetch(user.id);
  }

  getOrganizationNodePath(nodePath: string[]) {
    let path = '';
    if (!isNil(nodePath)) {
      path = nodePath.toString().replace(/,/g, ' > ');
    }
    return path;
  }

  async syncAuth0Email() {
    await this.organizationUnitUsersListService.auth0EmailSet(this.userId);
    await this.organizationUnitUsersListService.fetch(this.userId);
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }
}
