import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProgramStatus } from 'typings/doenkids/doenkids';
import { IProgramStatusState, ProgramStatusStore } from './program-status.store';

@Injectable({ providedIn: 'root' })
export class ProgramStatusQuery extends QueryEntity<IProgramStatusState, IProgramStatus> {
  constructor(protected store: ProgramStatusStore) {
    super(store);
  }
}
