<div class="page-container" *ngIf="(isSmall$ | async) === false">
  <div
    class="page-content left"
    [style.width]="leftWidth + '%'"
    [class.full-side]="hideRightSide"
    [class.hide-side]="hideLeftSide"
    *ngIf="scrollEventsLeft === true"
    #leftTab
    scrollEnd
    (endReached)="scrollEndReachedLeft(leftTab)"
    [parentSelectorToListenForScroll]="parentSelectorToListenForLeftScroll"
  >
    <ng-container [ngTemplateOutlet]="leftTemplate"></ng-container>
  </div>
  <div
    class="page-content left"
    [style.width]="leftWidth + '%'"
    [class.full-side]="hideRightSide"
    [class.hide-side]="hideLeftSide"
    #leftTab
    *ngIf="scrollEventsLeft !== true"
  >
    <ng-container [ngTemplateOutlet]="leftTemplate"></ng-container>
  </div>
  <div
    id="main-scroll-container"
    class="page-content right"
    [style.width]="100 - leftWidth + '%'"
    [class.hide-side]="hideRightSide"
    [class.full-side]="hideLeftSide"
    *ngIf="scrollEventsRight === true"
    #rightTab
    scrollEnd
    (scroll)="onScroll($event)"
    (scrollTop)="scrollTopUpdatedRight($event)"
    (endReached)="scrollEndReachedRight(rightTab)"
    [parentSelectorToListenForScroll]="parentSelectorToListenForRightScroll"
  >
    <ng-container [ngTemplateOutlet]="rightTemplate"></ng-container>
  </div>
  <div
    id="main-scroll-container"
    class="page-content right"
    [style.width]="100 - leftWidth + '%'"
    [class.hide-side]="hideRightSide"
    [class.full-side]="hideLeftSide"
    #rightTab
    *ngIf="scrollEventsRight !== true"
  >
    <ng-container [ngTemplateOutlet]="rightTemplate"></ng-container>
  </div>
</div>
<mat-tab-group *ngIf="(isSmall$ | async) === true" (selectedTabChange)="selectedTabChangeEvent($event)" mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab [label]="leftLabel" *ngIf="!hideLeftTab">
    <div
      class="tab-content-container"
      *ngIf="scrollEventsLeft === true"
      #leftTab
      scrollEnd
      (endReached)="scrollEndReachedLeft(leftTab)"
      [parentSelectorToListenForScroll]="parentSelectorToListenForLeftScroll"
    >
      <ng-container [ngTemplateOutlet]="leftTemplate"></ng-container>
    </div>
    <div class="tab-content-container" *ngIf="scrollEventsLeft !== true">
      <ng-container [ngTemplateOutlet]="leftTemplate"></ng-container>
    </div>
  </mat-tab>
  <mat-tab [label]="rightLabel" *ngIf="!hideRightTab">
    <div
      id="main-scroll-container"
      class="tab-content-container"
      *ngIf="scrollEventsRight === true"
      #rightTab
      (scroll)="onScroll($event)"
      scrollEnd
      (scrollTop)="scrollTopUpdatedRight($event)"
      (endReached)="scrollEndReachedRight(rightTab)"
      [parentSelectorToListenForScroll]="parentSelectorToListenForRightScroll"
    >
      <ng-container [ngTemplateOutlet]="rightTemplate"></ng-container>
    </div>
    <div class="tab-content-container" *ngIf="scrollEventsRight !== true">
      <ng-container [ngTemplateOutlet]="rightTemplate"></ng-container>
    </div>
  </mat-tab>
</mat-tab-group>
