/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnitTagAll } from 'typings/doenkids/doenkids';

export interface ITagListState extends EntityState<IOrganizationUnitTagAll> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tag-list' })
export class TagListStore extends EntityStore<ITagListState, IOrganizationUnitTagAll> {
  constructor() {
    super();
  }
}
