<app-doenkids-menu-bar [showBackButton]="true" [loading]="loading$ | async">
  <div left class="left">
    <app-activity-search-bar
      *ngIf="!showRevokedTemplates.value"
      [query]="currentSearchParams?.query"
      [contentLanguage]="currentSearchParams?.language"
      (searched)="searchEventHappened($event)"
      [showContentLanguageOption]="!(baseOnly$ | async)"
    ></app-activity-search-bar>
  </div>

  <div right>
    <span class="new-folder-checkbox" *ngIf="!showRevokedTemplates.value">
      <mat-checkbox
        *ngIf="(isAdmin$ | async) || ((hasProgramTemplateCreatePermission$ | async) && !(baseOnly$ | async))"
        [formControl]="selectProgramsCtrl"
        color="primary"
      >
        {{ 'program_templates.bundle.create.label' | translate }}
      </mat-checkbox>
      <button
        *ngIf="selectProgramsCtrl.value === true"
        [disabled]="($programTemplateSelection.programTemplates$ | async)?.length < 2"
        mat-icon-button
        type="button"
        (click)="createProgramTemplateBundle()"
        [matTooltip]="'program_templates.bundle.create.label' | translate"
      >
        <mat-icon>create_new_folder</mat-icon>
      </button>
    </span>
    <mat-form-field appearance="outline" class="oko-selector" *ngIf="(okoTypes$ | async)?.length > 0">
      <mat-select [formControl]="typeOko">
        <mat-option *ngFor="let okoType of okoTypes$ | async" [value]="okoType.id">{{ okoType.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="revoked-selector" *ngIf="(baseOnly$ | async) && searchQueryCtrl.value?.query === ''">
      <mat-select [formControl]="showRevokedTemplates">
        <mat-option [value]="false">
          {{ 'program_templates.revoked.to_review.label' | translate }}
        </mat-option>
        <mat-option [value]="true">
          {{ 'program_templates.revoked.label' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-program-template-status
      *ngIf="!(baseOnly$ | async) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
      [currentStatus]="currentPublicationStatus"
      (change)="changePublicationStatus($event)"
      [additionalStatuses]="additionalProgramStatuses"
    ></app-program-template-status>
  </div>
</app-doenkids-menu-bar>

<div class="main-content" [ngClass]="{ triggeredSearch: (triggerSearchView$ | async) }">
  <ng-container *ngIf="triggerSearchView$ | async">
    <div class="search-results">
      <div class="category-title">
        <h2>{{ 'program_templates.search_result.heading' | translate }}</h2>
      </div>
      <div class="horizontal-container">
        <h3 *ngIf="(programTemplateSearch$ | async).length === 0">{{ 'program_templates.no_results' | translate }}</h3>
        <div *ngFor="let template of programTemplateSearch$ | async" (click)="handleProgramTemplateClick($event, template)" class="search-card">
          <app-doenkids-image
            [preview]="true"
            [class.selected]="(selectedTemplates$ | async) && (selectedTemplates$ | async)[template.id]"
            [mediaItem]="template.media_uuid"
            [maxWidth]="200"
          ></app-doenkids-image>
          <img class="checked" src="./assets/images/selected-icon.png" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!(triggerSearchView$ | async)">
    <div class="template-overview" fxLayout="column" *ngFor="let category of categorizedProgramTemplates$ | async">
      <div class="category-title" *ngIf="category.templates.length > 0 || (category.bundles.length > 0 && !(onlyTemplates$ | async))">
        <h2>{{ category.name }}</h2>
        <button
          *ngIf="
            !showRevokedTemplates.value &&
            category.id &&
            (category.templates.length > 1 || category.bundles.length > 1) &&
            ((isAdmin$ | async) || category.isOfOwner)
          "
          mat-icon-button
          type="button"
          (click)="goToReOrderCategory(category)"
          [matTooltip]="'program_templates.category.reorder.tooltip' | translate"
        >
          <mat-icon>view_list</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="category.templates.length > 0">
        <div class="vertical-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="50%">
          <swiper-container class="swiper-container" fxFlex="auto" dkSwiper [config]="swiperConfig" init="false">
            <swiper-slide *ngFor="let item of category.templates" (click)="handleProgramTemplateClick($event, item)" class="swiper-slide">
              <app-doenkids-image
                [preview]="true"
                [class.selected]="(selectedTemplates$ | async) && (selectedTemplates$ | async)[item.id]"
                [mediaItem]="item.media_uuid"
                [maxWidth]="200"
              ></app-doenkids-image>
              <img class="checked" src="./assets/images/selected-icon.png" />
            </swiper-slide>
          </swiper-container>
        </div>
      </ng-container>

      <ng-container *ngIf="category.bundles.length > 0 && !(onlyTemplates$ | async)">
        <div class="vertical-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="50%">
          <swiper-container class="swiper-container" fxFlex="auto" dkSwiper [config]="swiperConfig" init="false">
            <swiper-slide *ngFor="let item of category.bundles" (click)="handleProgramTemplateBundleClick(item)" class="swiper-slide paper">
              <app-doenkids-image
                [preview]="true"
                [class.selected]="(selectedTemplates$ | async) && (selectedTemplates$ | async)[item.id]"
                [mediaItem]="item.media_uuid"
                [maxWidth]="200"
              ></app-doenkids-image>
              <img class="checked" src="./assets/images/selected-icon.png" />
            </swiper-slide>
          </swiper-container>
        </div>
      </ng-container>
    </div>
    <app-empty-page-message
      *ngIf="(categorizedProgramTemplates$ | async)?.length === 0"
      [message]="'program_templates.no_templates' | translate"
      icon="file_copy"
    ></app-empty-page-message>
  </ng-container>
</div>
