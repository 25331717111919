/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IActivityKind } from 'typings/doenkids/doenkids';

export interface IActivityKindState extends EntityState<IActivityKind> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-kind' })
export class ActivityKindStore extends EntityStore<IActivityKindState, IActivityKind> {
  constructor() {
    super();
  }
}
