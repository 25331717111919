<form [formGroup]="form">
  <div class="period-activity-section">
    <app-asset-upload
      *ngIf="( disableActivityImage$ | async ) !== true"
      class="image-uploader"
      purpose="activity"
      [identifier]="'periodActivity' + sectionId"
      (uploaded)="mediaUploaded( $event )">
      <ng-template let-uploadData="uploadData">
        <div class="image-container">
          <app-doenkids-image [maxWidth]="200" [maxHeight]="200" [preview]="true" [mediaItem]="form.get('media_uuid')?.value"></app-doenkids-image>
        </div>
        <app-select-media *ngIf="!form.disabled" [uploadData]="uploadData" [removable]="true" [identifier]="'periodActivity' + sectionId" [mediaItemUuid]="form.get('media_uuid')?.value" purpose="activity" (selected)="mediaSelected( $event )"></app-select-media>
      </ng-template>
    </app-asset-upload>
    <ng-container *ngIf="(periodSymbols$ | async) as periodSymbols">
      <mat-chip-listbox class="period-symbols" *ngIf="periodSymbols.length > 0">
        <mat-chip-option *ngFor="let symbol of periodSymbols" [removable]="true" (removed)="removeSymbol(symbol)" [class.outdated]="symbol.media_uuid === undefined" [matTooltip]="symbol.media_uuid === undefined ? ('period_activity_section.symbols.tooltip' | translate) : ''">
          <div class="period-symbol">
            <app-doenkids-image *ngIf="symbol" matListItemAvatar
              [maxWidth]="25" [maxHeight]="25" [preview]="false" [mediaItem]="{ 'uuid': symbol.media_uuid, 'description': '' }"></app-doenkids-image>
            <span matListItemTitle>{{symbol?.name}}</span>
          </div>
          <mat-icon matChipRemove *ngIf="!form.disabled">close</mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </ng-container>
    <ng-container *ngIf="(symbols$ | async).length > 0">
      <ng-container *ngIf="(symbols$ | async) as symbols">
        <button class="customer-symbols" *ngIf="!form.disabled" mat-button [matMenuTriggerFor]="menu">{{ 'period_activity_section.symbols.add' | translate }}</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="addSymbol(symbol)" *ngFor="let symbol of symbols">
            <div class="period-symbol">
              <app-doenkids-image
                [maxWidth]="30" [maxHeight]="30" [preview]="false" [mediaItem]="{ 'uuid': symbol.media_uuid, 'description': '' }"></app-doenkids-image>
              <span>{{symbol.name}}</span>
            </div>
          </button>
          <button mat-menu-item *ngIf="symbols.length === 0">
            {{ 'period_activity_section.symbols.none' | translate }}
          </button>
        </mat-menu>
      </ng-container>
    </ng-container>
    <div class="form-fields-wrapper">
      <mat-form-field appearance="outline" class="input-name">
        <mat-label>{{ 'period_activity_section.timeslot.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="timeslot">
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-name">
        <mat-label>{{ 'period_activity_section.title.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="title">
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-description">
        <mat-label>{{ 'period_activity_section.content.label' | translate }}</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5" formControlName="content">
        </textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" class="input-description">
        <mat-label>{{ 'period_activity_section.additional_content.label' | translate }}</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5" formControlName="additional_content">
        </textarea>
      </mat-form-field>
      <ng-content></ng-content>
    </div>
  </div>
</form>
