import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IJibbieOrganisation } from 'typings/doenkids/doenkids';

export interface IJibbieOrganizationState extends EntityState<IJibbieOrganisation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'jibbie-organization' })
export class JibbieOrganizationStore extends EntityStore<IJibbieOrganizationState, IJibbieOrganisation> {
  constructor() {
    super({});
  }
}
