import {
  Component, OnInit, Inject, ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganizationUnitApiKeyService } from 'src/api/customer/organization-unit-api-key/organization-unit-api-key.service';
import { IOrganizationUnitApiKey } from 'typings/doenkids/doenkids';
import { BehaviorSubject } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

@Component({
  selector: 'app-api-key-dialog',
  templateUrl: './api-key-dialog.component.html',
  styleUrls: ['./api-key-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApiKeyDialogComponent implements OnInit {
  public currentAPIKey: IOrganizationUnitApiKey;

  public hasAPIKey: boolean;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private organizationUnitApiKeyService: OrganizationUnitApiKeyService,
    private baseAPI: DoenKidsGenericApiProvider,
    public dialogRef: MatDialogRef<ApiKeyDialogComponent>,
    private $i18nToastProvider: I18nToastProvider,
    @Inject(MAT_DIALOG_DATA) public data: { organizationUnitId: number, organizationUnitName: string },
  ) { }

  async ngOnInit() {
    this.loading$.next(true);
    if (this.data.organizationUnitId && !isNaN(this.data.organizationUnitId)) {
      const fetchedAPIKey = await this.organizationUnitApiKeyService.fetchAPIKey(this.data.organizationUnitId);

      if (fetchedAPIKey) {
        this.currentAPIKey = fetchedAPIKey;
      }
    }
    this.loading$.next(false);
  }

  async createAPIKey() {
    this.loading$.next(true);
    const createdAPIKey = await this.organizationUnitApiKeyService.create(this.data.organizationUnitId);

    this.currentAPIKey = createdAPIKey;
    this.loading$.next(false);
  }

  async removeAPIKey() {
    this.loading$.next(true);
    try {
      await this.organizationUnitApiKeyService.remove(this.data.organizationUnitId, this.currentAPIKey.id);
      this.currentAPIKey = null;
    } catch (e) {
      console.log('error removing api key', e);
    }
    this.loading$.next(false);
  }

  copyAPIKey() {
    this.baseAPI.copyToClipboard(this.currentAPIKey.api_key);
    this.$i18nToastProvider.success(_('api_key.dialog.copied_to_clipboard'));
  }
}
