import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CurrentUserStore } from './auth.store';
import { IAuthenticatedUser } from 'typings/api-customer';

@Injectable({ providedIn: 'root' })
export class CurrentUserQuery extends Query<IAuthenticatedUser> {
  constructor(protected store: CurrentUserStore) {
    super(store);
  }
}
