import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IOrganizationUnitBrowseRequest, IOrganizationUnitBrowseResponse } from 'typings/api-customer';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { OrganizationUnitBrowseStore } from './organization-unit-browse.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitBrowseService {
  constructor(
    private store: OrganizationUnitBrowseStore,
    private $baseApi: DoenKidsGenericApiProvider,
  ) {}

  @transaction()
  async fetch(requestBody: IOrganizationUnitBrowseRequest, organizationUnitId?: number,
  ) {
    this.store.setLoading(true);

    let url = '/customer/organization-unit/browse';
    url = this.$baseApi.appendQueryParam(url, 'organizationUnitId', organizationUnitId);

    const response = await firstValueFrom(this.$baseApi.genericPostCall(url, requestBody)) as IOrganizationUnitBrowseResponse;
    this.store.setLoading(false);
    return response?.items || [];
  }
}
