import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';

/**
 * Dialog Overview
 */
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent {
  title: string;

  description: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, description: string, hideCancel?: boolean },
  ) {}

  get descriptionContainsHTML() {
    return this.data.description?.includes('>')
      && this.data.description?.includes('<');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close('confirm');
  }
}
