import {
  Component, ContentChild, TemplateRef, Input, Output, EventEmitter, ViewChild, OnDestroy, AfterViewInit, NgZone,
} from '@angular/core';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-responsive-toolbar-content',
  templateUrl: './responsive-toolbar-content.component.html',
  styleUrls: ['./responsive-toolbar-content.component.scss'],
})
export class ResponsiveToolbarContentComponent implements OnDestroy, AfterViewInit {
  private stop$: Subject<void> = new Subject<void>();

  private menuIsClosed$: Subject<boolean> = new Subject<boolean>();

  @ContentChild('content') template: TemplateRef<any>;

  @Input() icon = 'more_vert';

  @Input() tooltip: string = _('responsive_toolbar.tooltip');

  @Input() isOpen: boolean;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Output() toolBarMenuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isLarge$: Observable<boolean>;

  private firstRenderDone = false;

  constructor(
    private $breakpoints: BreakpointsProvider,
    private zone: NgZone,
  ) {
    this.isLarge$ = this.$breakpoints.isLarge$;
  }

  ngAfterViewInit() {
    this.isLarge$.pipe(takeUntil(this.stop$)).subscribe(async (isLarge) => {
      if (!isLarge) {
        await firstValueFrom(this.zone.onStable);

        if (this.trigger) {
          this.trigger.menuOpened.pipe(takeUntil(this.menuIsClosed$)).subscribe(() => {
            this.toolBarMenuOpen.emit(true);
          });

          this.trigger.menuClosed.pipe(takeUntil(this.menuIsClosed$)).subscribe(async () => {
            await firstValueFrom(this.zone.onStable);
            this.toolBarMenuOpen.emit(false);
          });

          if (!this.firstRenderDone && this.isOpen && !this.trigger.menuOpen) {
            this.trigger.openMenu();
            this.toolBarMenuOpen.emit(true);
          }
        }
      } else {
        this.menuIsClosed$.next(undefined);
      }

      this.firstRenderDone = true;
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }
}
