import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

_('organization.translations.nl_NL.label');

export interface IOrganizationTranslations {
  [key: string]: ITranslationForLanguage;
}

export interface ITranslationForLanguage {
  [label: string]: string;
}

export interface IParsedTranslation {
  label: string;
  [language: string]: string;
}

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss'],
})
export class TranslationsComponent implements OnInit {
  @Input('translations') translations: IOrganizationTranslations;

  public displayedColumns: string[] = ['label'];

  public languagesToShow: string[] = [];

  private translationMap = new Map<string, IParsedTranslation>();

  public parsedTranslations: IParsedTranslation[] = [];

  ngOnInit() {
    this.parseTranslations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.translations && !changes.translations.firstChange) {
      this.parseTranslations();
    }
  }

  parseTranslations() {
    this.languagesToShow = Object.keys(this.translations);

    this.displayedColumns = ['label', ...this.languagesToShow];

    for (const language of this.languagesToShow) {
      for (const translationKey of Object.keys(this.translations[language])) {
        if (this.translationMap.has(translationKey)) {
          const translation = this.translationMap.get(translationKey);
          translation[language] = this.translations[language][translationKey];
        } else {
          const translation = { label: translationKey };
          translation[language] = this.translations[language][translationKey];
          this.translationMap.set(translationKey, translation);
        }
      }
    }

    this.parsedTranslations = Array.from(this.translationMap.values());
  }
}
