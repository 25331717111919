/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ISectionType } from 'typings/doenkids/doenkids';

export interface ISectionTypeState extends EntityState<ISectionType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'section-type' })
export class SectionTypeStore extends EntityStore<ISectionTypeState, ISectionType> {
  constructor() {
    super();
  }
}
