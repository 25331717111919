import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IGroupSize } from 'typings/doenkids/doenkids';
import { IGroupSizeState, GroupSizeStore } from './group-size.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupSizeQuery extends QueryEntity<IGroupSizeState, IGroupSize> {
  constructor(protected store: GroupSizeStore) {
    super(store);
  }

  getGroupSizesForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((groupSizes) => groupSizes.filter((groupSize) => groupSize.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }
}
