import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { isNil, last } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NewsItemService } from 'src/api/customer/news-item/news-item.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { Navigation, Pagination, SwiperOptions } from 'swiper';
import { BreakpointsProvider } from '../../../providers/breakpoints.provider';
import { NewsfeedProvider } from '../../../providers/newsfeed-provider';
import { swiperStyles } from 'src/directives/swiper.directive';
import { INewsItemDetails, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';

interface INewsItemSlide {
  items: INewsItemDetails[];
}

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsfeedComponent implements OnInit, OnDestroy, OnChanges {
  MAX_ITEMS_PER_PAGE = 50;

  NO_OF_ITEMS_PER_SLIDE = 2;

  @ViewChild('swiper') swiperElement: ElementRef;

  @Input() organizationUnitId: number;

  @Input() hideReadItems = false;

  @Input() isBaseNewsItems = false;

  @Input() baseNewsItems: INewsItemDetails[] = [];

  public end$ = new BehaviorSubject(false);

  public hideReadItems$ = new BehaviorSubject(this.hideReadItems);

  public readItems$ = new BehaviorSubject<number[]>([]);

  public newsItems$ = new BehaviorSubject<INewsItemDetails[]>(this.baseNewsItems);

  // Filter by unread news items if needed
  //
  public filteredItems$ = combineLatest([this.newsItems$, this.readItems$, this.hideReadItems$]).pipe(
    map(([newsItems, readItems, hideReadItems]) => {
      return newsItems.filter((item) => (hideReadItems ? !readItems.includes(item.id) : true));
    }),
  );

  // Sort news items into columns and assign read status
  //
  public newsItemSlides$ = this.filteredItems$.pipe(
    map((filteredItems) => {
      const slides: INewsItemSlide[] = [];

      for (let i = 0; i < filteredItems.length; i += 1) {
        const filteredItem = filteredItems[i];
        const isFirstInNewSlide = (i % this.NO_OF_ITEMS_PER_SLIDE) === 0;

        if (isFirstInNewSlide) {
          slides.push({
            items: [filteredItem],
          });
        } else {
          const lastSlide = last(slides);
          lastSlide.items.push(filteredItem);
        }
      }

      return slides;
    }),
  );

  public newsItemsLoading$ = new BehaviorSubject(false);

  private organizationUnit$: Observable<IOrganizationUnitOverview>;

  public stop$ = new Subject<void>();

  public page = 0;

  public index = 0;

  public width;

  public dynamicConfig$ = new BehaviorSubject<Partial<SwiperOptions>>({ slidesPerView: 3.3, loop: false, spaceBetween: 32 });

  public swiperConfig$: Observable<SwiperOptions> = this.dynamicConfig$.pipe(
    map((value) => ({
      modules: [Navigation, Pagination],
      spaceBetween: 32,
      navigation: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        dynamicBullets: true,
        dynamicMainBullets: 3,
        clickable: true,
      },
      observer: true,
      direction: 'horizontal',
      scrollbar: false,
      ...value,
      injectStyles: [swiperStyles],
    })),
  );

  public trackByIndex = (index) => index;

  public trackById = (index, value) => value.id;

  constructor(
    private $newsItem: NewsItemService,
    public newsfeedProvider: NewsfeedProvider,
    private $session: DoenkidsSessionProvider,
    private router: Router,
    private breakpointsProvider: BreakpointsProvider,
  ) {
    this.organizationUnit$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$));
  }

  ngOnInit() {
    this.organizationUnit$.subscribe(async (organization) => {
      if (!this.isBaseNewsItems) {
        this.end$.next(false);
        this.page = 0;
        this.newsItems$.next([]);

        if (organization?.id) {
          this.fetchNewsItems();
        }
      }
    });

    combineLatest([
      this.breakpointsProvider.isXSmall$,
      this.breakpointsProvider.isSmall$,
      this.breakpointsProvider.isMedium$,
      this.breakpointsProvider.isLarge$,
      this.breakpointsProvider.isXLarge$,
    ])
      .pipe(takeUntil(this.stop$))
      .subscribe((values) => {
        let spv = 3;
        let spaceBetween = 32;
        switch (true) {
          case values[0]:
            spv = 1.2;
            spaceBetween = 16;
            break;
          case values[1]:
            spv = 2.2;
            spaceBetween = 16;
            break;
          case values[2]:
            spv = 3.2;
            spaceBetween = 16;
            break;
          case values[3]:
            spv = 4.3;
            spaceBetween = 32;
            break;
          case values[4]:
            spv = 4.3;
            spaceBetween = 32;
            break;
          default:
        }
        this.dynamicConfig$.next({ slidesPerView: spv, spaceBetween });
      });

    // Update read status when a news item is marked as read
    this.newsfeedProvider.itemMarkedAsRead$
      .pipe(
        filter((newsItemId) => !isNil(newsItemId)),
        takeUntil(this.stop$),
      )
      .subscribe((newsItemId) => {
        const currentReadItems = this.readItems$.value;

        if (!currentReadItems.includes(newsItemId)) {
          this.readItems$.next([...currentReadItems, newsItemId]);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const [key, change] of Object.entries(changes)) {
      switch (key) {
        case 'hideReadItems': {
          this.hideReadItems$.next(change.currentValue);
          break;
        }
        case 'baseNewsItems': {
          if (this.isBaseNewsItems) {
            this.newsItems$.next(change.currentValue);
          }
          break;
        }
        default:
      }
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  async fetchNewsItems() {
    const organization = await firstValueFrom(this.organizationUnit$);

    if (!organization?.id || this.end$.value) {
      return;
    }

    this.newsItemsLoading$.next(true);

    const result = await this.$newsItem.fetchAll(
      this.organizationUnitId ?? organization.id,
      'ORGANIZATION_TREE',
      false,
      false,
      this.MAX_ITEMS_PER_PAGE,
      this.page * this.MAX_ITEMS_PER_PAGE,
    );

    this.readItems$.next(result.readItems);

    this.newsItems$.next(result.items);

    if (result.items?.length > 0 && result.items?.length < this.MAX_ITEMS_PER_PAGE) {
      this.end$.next(true);
    } else if (this.page > 0) {
      this.end$.next(true);
    } else {
      this.newsItems$.next([]);
    }

    this.newsItemsLoading$.next(false);
  }

  endReached() {
    const { index } = this;
    this.page += 1;
    if (!this.isBaseNewsItems) {
      this.fetchNewsItems();
    }
    setTimeout(() => {
      this.index = index;
    });
  }

  viewItem(newsItemId: number) {
    const queryParams: any = {};

    if (this.isBaseNewsItems) {
      queryParams.baseNewsItem = true;
    }

    this.router.navigate([`/news-item/${newsItemId}`], { queryParams });
  }
}
