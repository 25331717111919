import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { combineLatest, firstValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetService } from 'src/api/media/asset/asset.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IUserPermission } from 'typings/api-customer';
import { IMediaUsedByList } from 'typings/api-media';
import { IMediaUsedBy, ISearchMedia } from 'typings/doenkids/doenkids';
import { TranslateService } from 'src/app/utils/translate.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from '../../../providers/i18n-toast.provider';

export interface IArchiveMediaItemDialogInput {
  item: ISearchMedia;
}

@Component({
  selector: 'app-archive-media-item-dialog',
  templateUrl: './archive-media-item-dialog.component.html',
  styleUrls: ['./archive-media-item-dialog.component.scss'],
})
export class ArchiveMediaItemDialogComponent implements OnInit, OnDestroy {
  public item: ISearchMedia | string;

  public usage: IMediaUsedBy[];

  public notAllowedToViewAmount = 0;

  public loaded = false;

  public archivingInProgress = false;

  public canBeRemoved = false;

  public permissions: IUserPermission[];

  private stop$ = new Subject();

  private isAdmin: boolean;

  constructor(
    public dialogRef: MatDialogRef<ArchiveMediaItemDialogComponent>,
    private router: Router,
    private $session: DoenkidsSessionProvider,
    private $i18nToastProvider: I18nToastProvider,
    @Inject(MAT_DIALOG_DATA) public data: IArchiveMediaItemDialogInput,
    private $assetService: AssetService,
    private $translateService: TranslateService,
  ) {
    this.item = data.item;
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  ownershipDescription(usedBy: IMediaUsedBy) {
    this.canBeRemoved = false;
    // Only organizations with role_id 3 (owner)
    //
    const organizationsThatOwnsThisMedia = usedBy.organization_unit.filter((ou) => ou.role_id === 3).map((ou) => ou.id);
    const myPermissionForOrganisatonThatOwnsThisMedia = this.permissions.find(
      (permission) => organizationsThatOwnsThisMedia.includes(permission.organization_unit_id),
    );
    if (!myPermissionForOrganisatonThatOwnsThisMedia && !this.isAdmin) {
      return this.$translateService.instant(
        'archive_media_item_dialog.item.ownership.unauthorized',
        { usageCount: usedBy.organization_unit.length },
      );
    }

    if (usedBy.organization_unit.length > 1) {
      return this.$translateService.instant(
        'archive_media_item_dialog.item.ownership.multiple',
        {
          additionalUsageCount: usedBy.organization_unit.length - 1,
          firstOrganizationUnitName: usedBy.organization_unit[0].name,
        },
      );
    }

    this.canBeRemoved = true;

    return this.$translateService.instant(
      'archive_media_item_dialog.item.ownership.single',
      { organizationUnitName: usedBy.organization_unit[0].name },
    );
  }

  async navigateTo(usedBy: IMediaUsedBy) {
    const ouId = await firstValueFrom(this.$session.currentOuId$);
    if (usedBy) {
      switch (usedBy.src) {
        case 'activity':
        case 'jibbie_activity':
          this.router.navigate([`/activities/preview/${usedBy.src_id}`]);
          break;
        case 'program':
          this.router.navigate([`/organization/${ouId}/program/${usedBy.src_id}`]);
          break;
        default:
          console.warn('Unknown media usage tyle', usedBy);
          this.$i18nToastProvider.error(_('archive_media_item_dialog.unknown_media_usage_type'));
          break;
      }
    }
    this.dialogRef.close(false);
  }

  /**
   * Fetch usage information for this media item
   */
  ngOnInit() {
    combineLatest([this.$session.userPermissions$, this.$session.isAdmin$]).pipe(
      takeUntil(this.stop$),
    ).subscribe(([permissions, isAdmin]) => {
      this.isAdmin = isAdmin;
      this.permissions = permissions;
      this.fetchItemUsageList();
    });
  }

  async fetchItemUsageList() {
    this.loaded = false;

    let list;
    const notAllowedList = [];
    const allowedList = [];
    if (typeof this.item === 'string') {
      list = await firstValueFrom(this.$assetService.getUsageByUuid(this.item)) as IMediaUsedByList;
    } else {
      list = await firstValueFrom(this.$assetService.getUsageById(this.item.id)) as IMediaUsedByList;
    }

    if (list?.items?.length > 0) {
      // Only organizations with role_id 3 (owner)
      //
      list.items.forEach((program) => {
        const organizationsThatOwnsThisMedia: number[] = program.organization_unit.filter((ou) => ou.role_id === 3).map((ou) => ou.id);
        const myPermissionForOrganisatonThatOwnsThisMedia = this.permissions.find(
          (permission) => organizationsThatOwnsThisMedia.includes(permission.organization_unit_id),
        );
        if (!myPermissionForOrganisatonThatOwnsThisMedia && !this.isAdmin) {
          notAllowedList.push(program);
        } else {
          allowedList.push(program);
        }
      });

      this.usage = allowedList;
      if (notAllowedList.length > 0) {
        this.notAllowedToViewAmount = notAllowedList.length;
      } else {
        this.notAllowedToViewAmount = 0;
      }
    } else {
      this.usage = [];
    }

    this.loaded = true;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  async confirm() {
    this.archivingInProgress = true;
    if (typeof this.item === 'string') {
      const asset = await this.$assetService.getByUuid(this.item) as any;
      await firstValueFrom(this.$assetService.archive(asset.id));
    } else {
      await firstValueFrom(this.$assetService.archive((this.item as ISearchMedia).id));
      console.log('[MEDIA-ITEM]: archived', this.item);
    }
    this.archivingInProgress = false;
    this.dialogRef.close(true);
  }
}
