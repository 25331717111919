import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { INewsItem } from 'typings/doenkids/doenkids';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'news-item' })
export class NewsItemStore extends Store<INewsItem> {
  constructor() {
    super({});
  }
}
