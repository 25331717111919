import { TImageVariant } from 'typings/api-media';
import {
  Component,
  Input,
  EventEmitter,
  OnChanges,
  Output,
  OnDestroy,
  ContentChild,
  ElementRef,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IMediaItem } from 'typings/section-types';
import { isEqual, isUndefined } from 'lodash';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, firstValueFrom, takeUntil, tap } from 'rxjs';
import { SelectMediaComponent } from 'src/components/shared/select-media/select-media.component';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { AssetService } from 'src/api/media/asset/asset.service';
import { IMedia, IMediaOriginType } from 'typings/doenkids/doenkids';
import { MediaOriginTypeQuery } from 'src/api/generic/media-origin-type/media-origin-type.query';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-doenkids-image',
  templateUrl: './doenkids-image.component.html',
  styleUrls: ['./doenkids-image.component.scss'],
})
export class DoenkidsImageComponent implements OnChanges, OnDestroy {
  @Input()
    preview = false;

  @Input()
    previewVariant: TImageVariant = 'small';

  @Input()
    maxWidth: number;

  @Input()
    maxHeight: number;

  @Input()
    mediaItem: IMediaItem | string | File;

  @Input()
    showOriginType: boolean = false;

  @Input()
    canReplaceImage: boolean = false;

  @Input()
    isRequired: boolean = false;

  @Input()
    defaultPlaceHolder = environment.activityImagePlaceholder;

  @Output()
    loaded = new EventEmitter();

  @ContentChild(SelectMediaComponent, { read: ElementRef })
    selectMedia;

  @ViewChild('imgTag') imgTag: ElementRef;

  private destroyed$ = new Subject();

  private selectedURI$ = new BehaviorSubject(this.defaultPlaceHolder);

  public dedupedSelectedUri$: Observable<string>;

  public hover: boolean;

  public isAdmin$: Observable<boolean>;

  private media: IMedia;

  public originTypeControl = new FormControl('unknown');

  public originTypeOptions$: Observable<IMediaOriginType[]>;

  constructor(
    private genericBaseApi$: DoenKidsGenericApiProvider,
    private $asset: AssetService,
    private $mediaOriginType: MediaOriginTypeQuery,
    $session: DoenkidsSessionProvider,
  ) {
    this.isAdmin$ = $session.isAdmin$;
    this.originTypeOptions$ = this.$mediaOriginType.selectAll();
  }

  async ngOnInit() {
    const isAdmin = await firstValueFrom(this.isAdmin$);

    this.setDedupedUri();

    this.selectedURI$.next(this.determineUrl());
    if (isAdmin && this.showOriginType) {
      this.fetchMedia();

      this.originTypeControl.valueChanges.pipe(takeUntil(this.destroyed$), distinctUntilChanged(isEqual)).subscribe((originType) => {
        if (this.media) {
          let originTypeId = null;
          if (originType !== 'unknown') {
            const mediaOriginType = this.$mediaOriginType.getTypeForName(originType);

            if (mediaOriginType) {
              originTypeId = mediaOriginType.id;
            }
          }

          if (originTypeId !== this.media.media_origin_type_id) {
            const newMedia = { ...this.media, media_origin_type_id: originTypeId } as IMedia;
            firstValueFrom(this.$asset.update(newMedia));
            this.media = newMedia;
          }
        }
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.mediaItem && !changes.mediaItem.firstChange && (this.canReplaceImage || !isEqual(changes.mediaItem.previousValue, changes.mediaItem.currentValue))) {
      this.selectedURI$.next(this.determineUrl());
      const isAdmin = await firstValueFrom(this.isAdmin$);
      if (isAdmin && this.showOriginType) {
        this.fetchMedia();
      }
    }

    if (changes.canReplaceImage) {
      this.setDedupedUri();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(undefined);
  }

  public imageLoaded() {
    this.loaded.emit();
  }

  setDedupedUri() {
    if (this.canReplaceImage) {
      this.dedupedSelectedUri$ = this.selectedURI$.pipe(
        tap((uri) => {
          if (this.imgTag) {
            const element = (this.imgTag.nativeElement as HTMLImageElement);
            element.src = '';
            element.src = uri;
          }
        }),
      );
    } else {
      this.dedupedSelectedUri$ = this.selectedURI$.pipe(distinctUntilChanged(isEqual));
    }
  }

  private isMediaItem(mediaItem: IMediaItem | File) {
    const hasUuid = !isUndefined((mediaItem as IMediaItem).uuid);
    const hasDescription = !isUndefined((mediaItem as IMediaItem).description);
    const hasCaption = !isUndefined((mediaItem as IMediaItem).caption);

    return hasUuid || hasDescription || hasCaption;
  }

  getMediaUuid() {
    if (typeof this.mediaItem === 'string' && !this.mediaItem.startsWith('http')) {
      return this.mediaItem;
    } else if (this.mediaItem && !(typeof this.mediaItem === 'string') && this.isMediaItem(this.mediaItem)) {
      return (this.mediaItem as IMediaItem).uuid;
    }
    return null;
  }

  async fetchMedia() {
    const uuid = this.getMediaUuid();
    if (uuid) {
      this.media = await firstValueFrom(this.$asset.getByUuid(uuid));
    } else {
      this.media = null;
    }

    const originType = this.getOriginType();
    this.originTypeControl.setValue(originType);
  }

  getOriginType() {
    if (this.media) {
      return this.$mediaOriginType.getTypeForId(this.media.media_origin_type_id)?.name ?? 'unknown';
    } else if (typeof this.mediaItem === 'string' && this.mediaItem.startsWith('http')) {
      return 'eigen';
    }

    return 'unknown';
  }

  determineUrl() {
    const searchParams = new URLSearchParams();
    if (this.preview) {
      searchParams.set('variant', this.previewVariant);
    }
    const mediaUuid = this.getMediaUuid();
    if (mediaUuid) {
      return `${this.genericBaseApi$.API_END_POINT}/media/image/uuid/${mediaUuid}?${searchParams.toString()}`;
    } else if (this.mediaItem && typeof this.mediaItem === 'string') {
      return this.mediaItem;
    } else if (this.mediaItem && (typeof this.mediaItem !== 'string') && !this.isMediaItem(this.mediaItem)) {
      return URL.createObjectURL(this.mediaItem as File);
    }
    return this.defaultPlaceHolder;
  }

  onHover(hovering = false) {
    this.hover = hovering;
  }
}
