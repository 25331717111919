import {
  Component, OnInit, HostListener, ExistingProvider, forwardRef,
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, Validators, ControlValueAccessor, UntypedFormArray, NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { noop, isNil } from 'lodash';
import { IRequiredMaterial } from 'typings/section-types';

export const SECTION_REQUIRED_MATERIAL_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => RequiredMaterialSectionComponent),
  multi: true,
};
@Component({
  selector: 'app-required-material-section',
  templateUrl: './required-material-section.component.html',
  styleUrls: ['./required-material-section.component.scss'],
  providers: [SECTION_REQUIRED_MATERIAL_CONTROL_VALUE_ACCESSOR],
})
export class RequiredMaterialSectionComponent implements OnInit, ControlValueAccessor {
  public form: UntypedFormGroup;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: IRequiredMaterial) => void = noop;

  public get materialFormArray(): UntypedFormArray {
    return this.form.get('material') as UntypedFormArray;
  }

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  constructor(public fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      subtitle: ['', Validators.required],
      material: this.fb.array([this.fb.group({
        name: ['', Validators.required],
        description: [],
        image: [],
      })]),
    });
    this.form.valueChanges.subscribe((formValue) => {
      this.onChangeCallback(formValue);
    });
  }

  writeValue(value: IRequiredMaterial) {
    // We only patch the form if there's a value present.
    //
    if (value) {
      // Set the title and subtitle.
      //
      this.form.get('title').setValue(value.title || '');
      this.form.get('subtitle').setValue(value.subtitle || '');

      // Get the formArray that contains the materialItem formGroups.
      //
      const materialFormArray = this.form.get('material') as UntypedFormArray;

      // Prepare the array
      //
      value.material = value.material ? value.material : [];

      // Loop through all formGroups in the formArray.
      //
      value.material.forEach((materialGroup, index) => {
        // Get the formGroup from the array.
        //
        const group = materialFormArray.controls[index];

        // If this data has no formcontrols, add a formgroup with the data as the initial value.
        //
        if (isNil(group)) {
          materialFormArray.push(this.fb.group(materialGroup));
        }
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
