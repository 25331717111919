<mat-form-field appearance="outline" class="search-field">
  <mat-icon matIconPrefix class="icon-magnifying-glass">search</mat-icon>
  <ng-container *ngVar="possibleContentLanguages$ | async as contentLanguages">
    <mat-select
      (click)="handleInputClick($event)"
      matPrefix
      class="content-language-select"
      hideSingleSelectionIndicator
      [formControl]="contentLanguageControl"
      *ngIf="showContentLanguageOption && contentLanguages?.length > 1"
      panelWidth="56px"
    >
      <mat-select-trigger *ngIf="contentLanguageControl.value">
        <mat-icon [svgIcon]="contentLanguageControl.value.toLowerCase()"></mat-icon>
      </mat-select-trigger>
      <mat-option *ngFor="let contentLanguage of contentLanguages" [value]="contentLanguage">
        <mat-icon [svgIcon]="contentLanguage.toLowerCase()"></mat-icon>
      </mat-option>
    </mat-select>
  </ng-container>

  <input [placeholder]="'generic.search' | translate" [formControl]="searchControl" matInput (click)="handleInputClick($event)" />

  <button *ngIf="searchControl.value" class="clear" mat-mini-fab (click)="clearSearch()" [matTooltip]="'activity_search_bar.clear_query' | translate">
    <mat-icon> close </mat-icon>
  </button>
</mat-form-field>
