import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IProgramTemplateBundle } from 'typings/doenkids/doenkids';

export interface ProgramTemplateBundleListState extends EntityState<IProgramTemplateBundle> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-bundle-list' })
export class ProgramTemplateBundleListStore extends EntityStore<ProgramTemplateBundleListState, IProgramTemplateBundle> {
  constructor() {
    super([]);
  }
}
