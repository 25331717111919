import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IActivity, IFavoriteFolderDetails, ISearchActivity } from 'typings/doenkids/doenkids';
import { IFavoriteFolderDetailsListResponse } from 'typings/api-activity';
import { cloneDeep } from 'lodash';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { firstValueFrom } from 'rxjs';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { FavoriteFolderStore } from './favorite-folder.store';
import { FavoriteFolderQuery } from './favorite-folder.query';

@Injectable({
  providedIn: 'root',
})
export class FavoriteFolderService {
  constructor(
    private store: FavoriteFolderStore,
    private query: FavoriteFolderQuery,
    private $baseApi: DoenKidsGenericApiProvider,
    private $i18nToastProvider: I18nToastProvider,
  ) {}

  async fetchAll(organizationUnitId: number, limit: number = 50, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'DESC', resetStore: boolean = false) {
    try {
      const fetchedFolders = await firstValueFrom(this.$baseApi.genericListCall('/activity/favorite-folder', limit, skip, sortField, sortDirection, {
        organizationUnitId,
      })) as IFavoriteFolderDetailsListResponse;

      if (!resetStore && this.query.hasEntity(organizationUnitId)) {
        const currentValue = this.query.getEntity(organizationUnitId);
        const notReplacedFolders = currentValue.folders.filter((currentFolder) => !(fetchedFolders?.items ?? []).find((fetchedFolder) => fetchedFolder.id === currentFolder.id));
        this.store.upsert(organizationUnitId, { organizationUnitId, folders: [...notReplacedFolders, ...fetchedFolders?.items ?? []], totalNoOfFolders: fetchedFolders?.total });
      } else {
        this.store.upsert(organizationUnitId, { organizationUnitId, folders: fetchedFolders?.items ?? [], totalNoOfFolders: fetchedFolders?.total });
      }

      return fetchedFolders;
    } catch (e) {
      this.$i18nToastProvider.error(_('favorite_folder.retrieve.failed'), { organizationUnitId });

      return null;
    }
  }

  async create(organizationUnitId: number, name: string) {
    try {
      const createdFolder = await firstValueFrom(this.$baseApi.genericPostCall('/activity/favorite-folder', { organization_unit_id: organizationUnitId, name })) as IFavoriteFolderDetails;

      if (createdFolder) {
        const currentValue = this.query.getEntity(organizationUnitId);
        this.store.upsert(organizationUnitId, {
          ...currentValue,
          folders: [...currentValue.folders, createdFolder],
          totalNoOfFolders: (currentValue.totalNoOfFolders + 1),
        });
      }

      return createdFolder;
    } catch (e) {
      this.$i18nToastProvider.error(_('favorite_folder.create.failed'), { name, organizationUnitId });

      return null;
    }
  }

  async update(organizationUnitId: number, folderId: number, name: string) {
    const updatedFolder = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/favorite-folder/${folderId}`, {
      organization_unit_id: organizationUnitId,
      name,
    })) as IFavoriteFolderDetails;
    if (updatedFolder) {
      const currentStoreEntity = cloneDeep(this.query.getEntity(organizationUnitId));
      const folderToUpdateIndex = currentStoreEntity.folders.findIndex((currentFolder) => currentFolder.id === folderId);

      if (folderToUpdateIndex > -1) {
        currentStoreEntity.folders[folderToUpdateIndex] = updatedFolder;
        this.store.upsert(organizationUnitId, currentStoreEntity);
      }
    }

    return updatedFolder;
  }

  async archive(organizationUnitId: number, folderId: number) {
    await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/favorite-folder/${folderId}`));

    const currentStoreEntity = cloneDeep(this.query.getEntity(organizationUnitId));

    const folderToRemoveIndex = currentStoreEntity.folders.findIndex((currentFolder) => currentFolder.id === folderId);

    if (folderToRemoveIndex > 1) {
      currentStoreEntity.folders.splice(folderToRemoveIndex, 1);
    }

    this.store.upsert(organizationUnitId, currentStoreEntity);
  }

  fetchAllActivitiesOfFolder(folderId: number, limit: number = 50, skip: number = 0, sortField: string = 'created_at', sortDirection: 'DESC') {
    return this.$baseApi.genericListCall(`/activity/favorite-activity-folder/${folderId}`, limit, skip, sortField, sortDirection);
  }

  addActivityToFolder(folderId: number, activity: ISearchActivity|IActivity) {
    try {
      return firstValueFrom(this.$baseApi.genericPostCall('/activity/favorite-activity-folder', {
        favorite_folder_id: folderId,
        activity_id: activity.id,
      }));
    } catch (e) {
      this.$i18nToastProvider.error(_('favorite_folder.activities.add.failed'), { activityName: activity.name, folderId });

      return null;
    }
  }

  removeActivityFromFolder(folderId: number, activity: ISearchActivity|IActivity) {
    try {
      return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/favorite-activity-folder/${activity.id}/favorite_folder/${folderId}`));
    } catch (e) {
      this.$i18nToastProvider.error(_('favorite_folder.activities.remove.failed'), { activityName: activity.name, folderId });

      return null;
    }
  }
}
