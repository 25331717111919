import {
  Directive, AfterViewInit, OnDestroy, Output, EventEmitter, Host, ElementRef,
} from '@angular/core';
import { defer } from 'lodash';

@Directive({ selector: '[intersection]' })
export class IntersectionDirective implements AfterViewInit, OnDestroy {
  @Output() appear = new EventEmitter();

  @Output() hide = new EventEmitter();

  private _observer: IntersectionObserver;

  constructor(@Host() private _elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const options = { root: null, rootMargin: '0px', threshold: 0.0 };
    this._observer = new IntersectionObserver(this._callback, options);
    this._observer.observe(this._elementRef.nativeElement);
  }

  ngOnDestroy() { this._observer.disconnect(); }

  private _callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.appear.emit();
      } else {
        this.hide.emit();
      }
    });
  };
}
