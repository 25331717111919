<div class="panel" class="flex-wrap panel" [style.display]="(viewingItem$ | async) ? 'none !important' : 'flex'">
  <div fxFlex="25%" fxFlex.lg="40%" fxFLex.md="50%" fx fxFlex.sm="100%" fxFlex.xs="100%" class="newsfeed">
    <div fxLayoutAlign="space-between center" class="flex-wrap">
      <h2>{{ 'organization_unit.manage_newsfeed.title' | translate }}</h2>
      <button mat-icon-button (click)="clear()" [matTooltip]="'organization_unit.manage_newsfeed.add.tooltip' | translate">
        <mat-icon> add </mat-icon>
      </button>
    </div>

    <mat-form-field appearance="outline" class="news-status">
      <mat-select [(value)]="archivedSelection" (selectionChange)="fetchNewsItems(); currentPage = 1">
        <mat-option *ngFor="let item of statusFilterOptions" [value]="item.value">{{ item.label | translate }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="news-item-list-wrapper">
      <ng-container *ngIf="!(newsItemsLoading$ | async); else spinner">
        <ng-container *ngIf="newsItems$ | async as newsItems">
          <card-list [class.archive]="archivedSelection === 'archived'">
            <card-list-item
              #trigger
              *ngFor="let item of newsItems | paginate: (paginationConfig$ | async)"
              [selected]="(editingItem$ | async)?.id === item.id"
              (click)="editNewsItem(item)"
              [mediaUuid]="item.media_uuid"
              class="news-item-card"
            >
              <span>{{ item.subject }} </span>
              <span>{{ item.show_from | i18n_date | async }} • {{ item.organization_unit_name }} </span>
              <span [innerHTML]="item.content | htmlToPlaintext"></span>

              <div class="list-item-stats" fxLayoutAlign="center center">
                <span
                  [matTooltip]="'organization_unit.manage_newsfeed.read_count.tooltip' | translate: { readCount: item.news_read }"
                  fxLayoutAlign="center center"
                >
                  <mat-icon>visibility</mat-icon>&nbsp; {{ item.news_read }}</span
                >
              </div>
            </card-list-item>
          </card-list>
        </ng-container>
      </ng-container>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="(paginationConfig$ | async).totalItems > MAX_ITEMS_PER_PAGE">
      <pagination-controls
        id="organizationUnitManageNewsfeed"
        [nextLabel]="'generic.pagination.next' | translate"
        [previousLabel]="'generic.pagination.previous' | translate"
        (pageChange)="paginate($event)"
      ></pagination-controls>
    </div>
  </div>

  <div fxFlex="75%" fxFlex.lg="60%" fxFLex.md="50%" fxFlex.sm="100%" fxFlex.xs="100%" class="form-wrapper" fxLayout="column">
    <div class="message-form" [class]="(bounceInTrigger$ | async) ? 'bounce' : ''" *ngVar="editingItem$ | async as editingItem">
      <div fxLayoutAlign="space-between center">
        <h2>
          {{ editingItem ? ('organization_unit.manage_newsfeed.edit.title' | translate) : ('organization_unit.manage_newsfeed.publish.title' | translate) }}
        </h2>
        <div *ngIf="editingItem">
          <button
            mat-icon-button
            (click)="deleteOrRecover()"
            [matTooltip]="
              archivedSelection === 'archived'
                ? ('organization_unit.manage_newsfeed.recover.tooltip' | translate)
                : ('organization_unit.manage_newsfeed.delete.tooltip' | translate)
            "
            [color]="archivedSelection === 'archived' ? 'accent' : 'warn'"
          >
            <mat-icon> {{ archivedSelection === 'archived' ? 'restore_from_trash' : 'delete' }} </mat-icon>
          </button>
          <button mat-icon-button (click)="viewItem(editingItem.id)" [matTooltip]="'organization_unit.manage_newsfeed.preview.tooltip' | translate">
            <mat-icon>preview</mat-icon>
          </button>
        </div>
      </div>
      <form [formGroup]="newsItemForm" fxLayoutGap="16px" fxLayout="column" *ngIf="newsItemForm">
        <mat-form-field class="title-input">
          <mat-label>{{ 'newsfeed.item.title.label' | translate }}</mat-label>
          <input
            autocomplete="off"
            autocapitalize="sentences"
            (input)="this.newsItemForm.markAsTouched()"
            [required]="true"
            formControlName="subject"
            matInput
            [errorStateMatcher]="errorStateMatcher"
          />
        </mat-form-field>

        <app-asset-upload identifier="upload" (uploaded)="onImageSelected($event)">
          <ng-template let-uploadData="uploadData" let-fileInput="fileInput">
            <div class="image-container">
              <app-doenkids-image [mediaItem]="newsItemForm.get('media_uuid').value" [preview]="false" [isRequired]="true"></app-doenkids-image>
            </div>

            <div fxLayoutAlign="center center" class="media-select-buttons">
              <app-select-media [uploadData]="uploadData" (selected)="onImageSelected($event)" identifier="upload"></app-select-media>
            </div>
            <span class="tip">{{ 'newsfeed.item.image.description' | translate }}</span>
          </ng-template>
        </app-asset-upload>

        <hr />

        <div class="content-input">
          <quill-editor
            theme="snow"
            formControlName="content"
            bounds="self"
            [required]="true"
            [class.invalid]="contentInvalid$ | async"
            appOnFocus
            [placeholder]="'newsfeed.item.content.placeholder' | translate"
            (onBlur)="onContentBlur($event)"
            (onContentChanged)="onContentChange($event)"
          >
          </quill-editor>
        </div>

        <hr />

        <div class="radio-buttons">
          <h4>{{ 'newsfeed.item.visibility.title' | translate }}</h4>
          <mat-radio-group
            [attr.aria-label]="'newsfeed.item.visibility.label' | translate"
            fxLayoutAlign="space-between"
            class="flex-wrap"
            fxLayout="column"
            formControlName="news_type"
            (change)="onNewsTypeChange($event)"
          >
            <mat-radio-button value="ORGANIZATION"> {{ 'newsfeed.item.visibility.organization' | translate }}</mat-radio-button>
            <mat-radio-button value="ORGANIZATION_TREE">
              {{ 'newsfeed.item.visibility.organization_tree' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <hr />

        <ng-container *ngIf="activityTypes$ | async as activityTypes">
          <div *ngIf="newsItemForm.get('news_type').value === 'ORGANIZATION_TREE' && activityTypes.length > 1">
            <h4>{{ 'newsfeed.item.visibility.organization_tree.locations.title' | translate }}</h4>
            <mat-selection-list
              formControlName="activity_type_id"
              (selectionChange)="newsItemForm.markAsTouched()"
              [class.oko-invalid]="newsItemForm.get('activity_type_id').invalid"
            >
              <mat-list-option *ngFor="let option of activityTypes" [value]="option.id"> {{ option.name }} </mat-list-option>
            </mat-selection-list>

            <hr />
          </div>
        </ng-container>

        <div class="date-area" fxLayout="column">
          <h4>{{ 'newsfeed.item.visibility.period.title' | translate }}</h4>
          <div fxFlex fxLayoutGap="16px">
            <mat-form-field appearance="fill" fxFlex="50%">
              <mat-label>{{ 'newsfeed.item.visibility.from.label' | translate }}</mat-label>
              <input
                autocomplete="off"
                [min]="showFromMin"
                formControlName="show_from"
                matInput
                [matDatepicker]="fromPicker"
                [required]="true"
                (dateChange)="dateChange()"
                [errorStateMatcher]="errorStateMatcher"
              />
              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50%">
              <mat-label>{{ 'newsfeed.item.visibility.to.label' | translate }}</mat-label>
              <input
                autocomplete="off"
                [min]="this.newsItemForm.value.show_from"
                formControlName="show_until"
                matInput
                [matDatepicker]="toPicker"
                (dateChange)="dateChange()"
                [errorStateMatcher]="errorStateMatcher"
              />
              <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
              <mat-datepicker #toPicker></mat-datepicker>
              <button
                *ngIf="this.newsItemForm.value.show_until"
                matSuffix
                mat-icon-button
                [attr.aria-label]="'newsfeed.item.visibility.to.clear.label' | translate"
                (click)="newsItemForm.get('show_until').setValue(null)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <span
          *ngIf="this.newsItemForm.value.show_until"
          [innerHtml]="
            'newsfeed.item.visibility.period.from_to.description'
              | translate
                : {
                    showFrom: this.newsItemForm.value.show_from | i18n_date: 'EEE. d LLLL' | async,
                    showTo: this.newsItemForm.value.show_until | i18n_date: 'EEE. d LLLL' | async
                  }
          "
        ></span>
        <span
          *ngIf="!this.newsItemForm.value.show_until"
          [innerHtml]="
            'newsfeed.item.visibility.period.indefinite_from.description'
              | translate
                : {
                    showFrom: this.newsItemForm.value.show_from | i18n_date: 'EEE. d LLLL' | async
                  }
          "
        ></span>
        <div fxLayoutAlign="space-between center" class="buttons">
          <button mat-stroked-button (click)="clear()">{{ 'generic.cancel' | translate }}</button>
          <button color="primary" class="publish-button" mat-flat-button (click)="publish()" [disabled]="!newsItemForm.valid || !newsItemForm.touched">
            <mat-icon>publish</mat-icon>
            {{
              editingItem ? ('organization_unit.manage_newsfeed.publish_changes' | translate) : ('organization_unit.manage_newsfeed.publish_item' | translate)
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #spinner>
  <div fxFill fxLayoutAlign="center center" class="spinner">
    <mat-spinner diameter="48"></mat-spinner>
  </div>
</ng-template>
