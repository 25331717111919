import { DOCUMENT } from '@angular/common';
import {
  Directive,
  HostListener,
  ElementRef,
  EventEmitter,
  Output,
  Inject,
  AfterViewInit,
  Input,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[scrollEnd]',
})
export class ScrollEndDirective implements AfterViewInit, OnDestroy {
  top;

  offSetHeight;

  scrollHeight;

  @Input()
    parentSelectorToListenForScroll?: string;

  private SCROLL_MARGIN = 10; // in pixels

  private eventListener = (event: Event) => {
    this.handleScrollEvent(event.target as HTMLElement);
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private eleRef: ElementRef,
  ) { }

  ngAfterViewInit() {
    if (this.parentSelectorToListenForScroll) {
      this.document.querySelector(this.parentSelectorToListenForScroll)?.addEventListener('scroll', this.eventListener);
    }
  }

  ngOnDestroy() {
    if (this.parentSelectorToListenForScroll) {
      this.document.querySelector(this.parentSelectorToListenForScroll)?.removeEventListener('scroll', this.eventListener);
    }
  }

  @Output() endReached = new EventEmitter();

  @Output() scrollTop = new EventEmitter();

  @HostListener('scroll') onScroll() {
    if (!this.parentSelectorToListenForScroll) {
      this.handleScrollEvent(this.eleRef.nativeElement);
    }
  }

  handleScrollEvent(element: HTMLElement) {
    this.top = element.scrollTop;
    this.scrollTop.emit(this.top);

    // Get the Height of the element.this property normally include padding and margin
    //
    this.offSetHeight = element.offsetHeight;

    // ScrollHeight is a measurement of the height of an element's content including
    //
    this.scrollHeight = element.scrollHeight;

    // Check the scroll at bottom or top
    //
    if (this.top > (this.scrollHeight - this.offSetHeight - this.SCROLL_MARGIN)) {
      this.endReached.emit('reached');
    }
  }

  /**
   * This is used by split-page.component.ts
   * @param scrollTop
   */
  scroll(scrollTop: number) {
    this.eleRef.nativeElement.scrollTop = scrollTop;
  }
}
