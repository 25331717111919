import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';

_('generic.error.download');
_('generic.error.summary.activity');
_('generic.error.summary.program_periods');

export const TOAST_CONFIG = {
  duration: 4000,
};

export const ERROR_TOAST_CONFIG = {
  ...TOAST_CONFIG,
  panelClass: 'error',
};

export const SUCCESS_TOAST_CONFIG = {
  ...TOAST_CONFIG,
  panelClass: 'success',
};

@Injectable()
export class I18nToastProvider {
  constructor(
    private $toastCtrl: MatSnackBar,
    private $translateService: TranslateService,
  ) {
  }

  failureSummary(subject, failedCount: number, totalCount: number, parameters: object = {}): void {
    this.error(
      `generic.error.summary.${subject}`,
      {
        ...parameters,
        failedCount,
        totalCount,
        allFailed: failedCount === totalCount,
      },
    );
  }

  error(message: string, interpolateParams?: Object): void {
    this.showToast(ERROR_TOAST_CONFIG, message, interpolateParams);
  }

  success(message: string, interpolateParams?: Object): void {
    this.showToast(SUCCESS_TOAST_CONFIG, message, interpolateParams);
  }

  message(message: string, interpolateParams?: Object): void {
    this.showToast(TOAST_CONFIG, message, interpolateParams);
  }

  showToast(config: MatSnackBarConfig, message: string, interpolateParams?: Object): void {
    this.$toastCtrl.open(
      this.$translateService.instant(message, interpolateParams),
      null,
      config,
    );
  }
}
