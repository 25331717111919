import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnitOverview } from 'typings/doenkids/doenkids';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-unit-details' })
export class OrganizationUnitDetailsStore extends Store<IOrganizationUnitOverview> {
  constructor() {
    super({});
  }
}
