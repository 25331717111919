<div ngFileDrop
  class="drop-container"
  [class.drag-over]="dragOver"
  [options]="options"
  (uploadOutput)="onUploadOutput($event)"
  [uploadInput]="uploadInput"
  >
    <input type="file" #file [name]="identifier" [id]="identifier" ngFileSelect multiple [disabled]="disabled"
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    class="file-upload">
    <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ uploadData }"></ng-container>
</div>
