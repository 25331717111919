import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IAvailableFontOptions } from 'typings/api-publish';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAvailableFontList() {
    const availableFonts = await firstValueFrom(this.$baseApi.genericGetCall('/publish/fonts/available-fonts')) as IAvailableFontOptions;

    return availableFonts;
  }
}
