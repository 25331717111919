import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IUserDetails } from 'typings/api-customer';

export interface IUserListState extends EntityState<IUserDetails> {
  limit: number;
  skip: number;
  total: number;
  query?: string;
  aggregations: any;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-search' })
export class UserListStore extends EntityStore<IUserListState, IUserDetails> {
  constructor() {
    super([]);
  }

  setMetadata({
    total, skip, limit, query, took,
  }) {
    this.update({
      total, skip, limit, query, took,
    });
  }

  setAggregations(aggregations: any) {
    this.update({ aggregations });
  }
}
