<h1 mat-dialog-title>
  {{ 'create_program_template_dialog.title' | translate }}
</h1>
<form [formGroup]="form">
  <div mat-dialog-content>
    <p>{{ 'create_program_template_dialog.content.label' | translate }}</p>
    <app-asset-upload
      identifier="template-cover"
      purpose="program-template"
      (uploaded)="addCoverImage($event)">
      <ng-template let-uploadData="uploadData">
        <div class="image-container">
          <app-doenkids-image
            [maxWidth]="150"
            [maxHeight]="150"
            [preview]="true"
            [mediaItem]="form.get('coverImage').value">
            <app-select-media
              identifier="template-cover"
              [uploadData]="uploadData"
              [removable]="form.get('coverImage').value"
              (selected)="addCoverImage($event)"
              purpose="program-template">
            </app-select-media>
          </app-doenkids-image>
        </div>
      </ng-template>
    </app-asset-upload>
    <div class="form-fields">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'create_program_template_dialog.organization.label' | translate }}</mat-label>
        <input matInput [value]="form.get('organizationUnit').value?.name ?? ''" readonly>
        <mat-icon matSuffix (click)="changeOrganization()"
        [matTooltip]="'create_program_template_dialog.organization.tooltip' | translate">edit</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'create_program_template_dialog.category.label' | translate }}</mat-label>
        <mat-select
          formControlName="category"
          [placeholder]="'create_program_template_dialog.category.placeholder' | translate"
        >
          <mat-option *ngFor="let option of ( programCategories$ | async )" [value]="option">
            {{ option?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
    <button mat-button (click)="confirm()" color="primary" cdkFocusInitial [disabled]="!form.valid">
      {{ 'generic.ok' | translate | uppercase }}
    </button>
  </div>
</form>
