<app-doenkids-menu-bar class="transparent" [loading]="listLoading$ | async">
  <div left fxLayout="row" fxHide.lt-sm="true" fxLayoutAlign="center center">
    <button mat-raised-button (click)="today()">
      {{ 'calendar.today' | translate }}
    </button>
    <button
      (click)="previous()"
      mat-icon-button
      matTooltipPosition="below"
      [matTooltipShowDelay]="100"
      [matTooltip]="'calendar.previous_month' | translate"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button
      (click)="next()"
      mat-icon-button
      matTooltipPosition="below"
      [matTooltipShowDelay]="100"
      [matTooltip]="'calendar.next_month' | translate"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
    <span class="title">
      {{ currentViewTitle }}
    </span>
    <button
      (click)="toggleDownloadSelection()"
      class="download"
      [class.selecting]="selecting"
      mat-icon-button
      matTooltipPosition="below"
      [matTooltipShowDelay]="100"
      [matTooltip]="'calendar.programs.select.tooltip' | translate"
    ><mat-icon>get_app</mat-icon></button>
    <button
      *ngIf="selecting"
      mat-button
      (click)="downloadPrograms()"
      [disabled]="selectedPrograms.length === 0"
      color="primary"
    >
      {{ 'calendar.programs.download' | translate: {programCount: selectedPrograms.length} }}
    </button>
    <button
      *ngIf="selecting"
      mat-button
      (click)="makeBooklet()"
      [disabled]="selectedPrograms.length === 0"
      color="primary"
    >
      {{ 'calendar.programs.booklet.create' | translate: {programCount: selectedPrograms.length} }}
    </button>
  </div>
  <div
    class="view-options-container"
    right
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    <button fxShow.lt-sm="true" fxHide="true" mat-raised-button (click)="today()">
      {{ 'calendar.today' | translate }}
    </button>
    <mat-slide-toggle [formControl]="showWeekend" color="primary">{{ 'calendar.weekend' | translate }}</mat-slide-toggle>
    <mat-form-field *ngIf="calendarTypeToDisplay" appearance="outline">
      <mat-select [(value)]="calendarTypeToDisplay">
        <mat-option
          *ngFor="let calendarType of calendarTypes$ | async"
          (click)="changeView(calendarType.view)"
          [value]="calendarType.value"
        >
          {{ calendarType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="program-status" appearance="outline">
      <mat-select [value]="(currentProgramStatus$ | async)" (selectionChange)="changePublicationStatus($event)" [multiple]="true" [disabled]="(isReader$ | async)">
        <mat-option *ngFor="let status of (programStatuses$ | async)" [value]="status.id">
          {{ status.translatedName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</app-doenkids-menu-bar>

<div class="page-container animated fadeIn" *ngVar="(error$ | async) as error">
  <ng-container *ngVar="(unpublishedProgramCount$ | async) as unpublishedProgramCount">
    <div class="unpublished_programs" *ngIf="unpublishedProgramCount?.count > 0">
      {{ 'calendar.unpublished_programs' | translate: { unpublishedCount: unpublishedProgramCount.count, endDate: getFormattedDate(unpublishedProgramCount.end) } }}
    </div>
  </ng-container>
  <div class="search-bar" *ngIf="!error">
    <mat-form-field appearance="outline" >
      <mat-label>{{ 'calendar.programs.search.label' | translate }}</mat-label>
      <mat-select
        (click)="handleFlagInputClick($event)"
        matPrefix
        class="content-language-select"
        hideSingleSelectionIndicator
        [formControl]="contentLanguageControl"
        *ngIf="possibleContentLanguages?.length > 1"
        panelWidth="56px"
      >
        <mat-select-trigger *ngIf="contentLanguageControl.value">
          <mat-icon [svgIcon]="contentLanguageControl.value.toLowerCase()"></mat-icon>
        </mat-select-trigger>
        <mat-option *ngFor="let contentLanguage of possibleContentLanguages" [value]="contentLanguage">
          <mat-icon [svgIcon]="contentLanguage.toLowerCase()"></mat-icon>
        </mat-option>
      </mat-select>
      <input
        type="text"
        [placeholder]="'calendar.programs.search.placeholder' | translate"
        [attr.aria-label]="'calendar.programs.search.label' | translate"
        matInput
        [formControl]="programSearchInput"
        [matAutocomplete]="auto"
      >
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectProgram($event)" [displayWith]="getProgramName">
        <mat-option *ngFor="let program of programSearchResults$ | async" [value]="program">
          <div fxFlex>
            <span fxFlex="400px" style="max-width: 400px; overflow: hidden; text-overflow: ellipsis">
              {{program.name}}
            </span>
            <span>
              {{ program.from | i18n_date: 'dd MMM YYYY' | async }} - {{ program.to | i18n_date: 'dd MMM YYYY' | async }}
            </span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <span class="error" *ngIf="error">
    {{ 'calendar.programs.create.only_locations' | translate }}
  </span>
  <div [class.editing-disabled]="error">
    <full-calendar #doenkidsCalendar class="calendar" [options]="calendarOptions$ | async"></full-calendar>
  </div>
  <div class="legenda">
    <ul>
      <ng-container *ngFor="let status of programStatuses$ | async">
        <li *ngIf="(currentProgramStatus$ | async).includes(status.id)">
          <span class="legenda-item status-{{status.id}}"></span>
          {{ status.translatedName }}
        </li>
      </ng-container>

    </ul>
    {{ 'calendar.disclaimer' | translate }}
  </div>
</div>
