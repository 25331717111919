<app-doenkids-menu-bar [loading]="isSearching$ | async">
  <div left>
    <app-activity-search-bar [query]="currentSearchParams?.query" [contentLanguage]="currentSearchParams?.language" (searched)="doSearch($event)"></app-activity-search-bar>
  </div>
  <div right>
    <form class="form-wrapper">
      <mat-checkbox *ngIf="!(isReader$ | async)" class="top-checkbox" [formControl]="selectActivitiesControl"> {{ 'activity_search.collect_activities' | translate }}</mat-checkbox>
      <app-responsive-toolbar-content
        class="status-filter-container"
        icon="filter_list"
        [isOpen]="toolBarContentOpen | async"
        tooltip="activity_search.responsive_toolbar.tooltip"
        (toolBarMenuOpen)="updateToolbarMenuOpen($event)"
      >
        <ng-template #content>
          <app-activity-status-filters
            (change)="updateStateFilters($event)"
            [activityStatusFilterForm]="activityStatusFiltersControl"
            [sortOptionControl]="sortOptionControl"
          >
          </app-activity-status-filters>
        </ng-template>
      </app-responsive-toolbar-content>
    </form>
  </div>
</app-doenkids-menu-bar>
<div class="page-container" #pageContainer>
  <app-split-page
    #splitPage
    [leftLabel]="'activity_search.tab.filters' | translate"
    [rightLabel]="'activity_search.tab.results' | translate"
    [activeTab]="1"
    [leftWidth]="23"
    [scrollEventsRight]="true"
    [hide]="hideAggregations$ | async"
    sideToHide="left"
    whatToHide="side"
    (scrollEndRight)="onScrollEnd()"
    (selectedTabChange)="tabChange($event)"
  >
    <ng-template #left>
      <div class="aggregations" *ngIf="aggregations$ | async">
        <app-search-aggregations
          [aggregationLabels]="aggregationLabels | async"
          [aggregationData]="aggregations$ | async"
          [formControl]="aggregationControl">
        </app-search-aggregations>
      </div>
    </ng-template>

    <ng-template #right>
      <div class="results-panel">
        <div class="results-header" #resultsHeader>
          <div class="results-header-wrapper" [class.hasFilters]="containsAggregations$ | async">
            <mat-toolbar class="mat-toolbar-doenkids">
              <button
                mat-icon-button
                [matTooltip]="'activity_search.tooltip.left' | translate"
                (click)="toggleAggregations()"
                *ngIf="!(isSmall$ | async) && !(hideAggregations$ | async)"
              >
                <mat-icon>chevron_left</mat-icon>
              </button>
              <button
                mat-icon-button
                [matTooltip]="'activity_search.tooltip.right' | translate"
                (click)="toggleAggregations()"
                *ngIf="!(isSmall$ | async) && hideAggregations$ | async"
              >
                <mat-icon>chevron_right</mat-icon>
              </button>
              <div class="result-amount" *ngIf="activitySearchMetadata$ | async; let metaData">
                <ng-container *ngIf="metaData?.total > 0">
                  <span
                    [innerHTML]="'activity_search.search_metadata' | translate: {
                    resultCount: metaData?.total,
                  }"
                  >
                  </span>
                </ng-container>
                <span class="did-you-mean" *ngFor="let suggestion of searchSuggestions$ | async">
                  <ng-container *ngIf="suggestion.options && suggestion.options.length > 0">
                    <span>{{ 'activity_search.suggestions' | translate }}&nbsp;</span
                    ><a href="#" (click)="trySuggestion(option.text, $event)" *ngFor="let option of suggestion.options" [innerHTML]="option.highlighted"></a>
                    <span>?</span>
                  </ng-container>
                </span>
              </div>
              <div class="spacer"></div>
            </mat-toolbar>
            <ng-container *ngIf="containsAggregations$ | async">
              <mat-toolbar-row class="mat-toolbar-doenkids mat-toolbar-chips">
                <app-search-chips
                  class="search-chips"
                  [aggregationLabels]="aggregationLabels | async"
                  (onRemoveAll)="removeAllFilters()"
                  (onRemove)="removeFilter($event)"
                  [aggregationData]="aggregations$ | async"
                  [selection]="aggregationControl.value"
                >
                </app-search-chips>
              </mat-toolbar-row>
            </ng-container>
          </div>
        </div>

        <div class="search-results">
          <ng-container *ngIf="searchResults$ | async as results">
            <div class="body-container animated fadeIn">
              <ngx-masonry
                #regularMasonry
                *ngIf="results.length > 0"
                [options]="{ gutter: 25, transitionDuration: '0s', fitWidth: true, horizontalOrder: true }"
              >
                <ngxMasonryItem class="masonry-item" *ngFor="let activity of results">
                  <app-activity-card
                    (loaded)="imageLoaded()"
                    (click)="activityClicked(activity)"
                    [class.selected]="(isSelectingActivties$ | async) && activityIsSelected(activity)"
                    [class.archived]="activityStatusFiltersControl.value?.showActivities === 'Archived'"
                    [activity]="activity"
                  >
                  </app-activity-card>
                </ngxMasonryItem>
              </ngx-masonry>
            </div>
            <div *ngIf="results.length === 0">{{ 'activity_search.no_results' | translate }}</div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </app-split-page>
</div>
<button
  mat-fab
  class="doenkids-fab"
  color="primary"
  [disabled]="!(hasSelectedOUWritePermission$ | async)"
  *ngIf="!(hasDisableActivityCreatePermission$ | async) && !(isReader$ | async)"
  (click)="createNew()"
>
  <mat-icon [aria-label]="'activity.create_new' | translate">add</mat-icon>
</button>
<app-selected-activities *ngIf="(isSelectingActivties$ | async) && !(isReader$ | async)"></app-selected-activities>
<router-outlet></router-outlet>
