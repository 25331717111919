import { Injectable } from '@angular/core';
import { IProgramTemplateBundleListResponse } from 'typings/api-activity';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IProgramTemplateBundle } from 'typings/doenkids/doenkids';
import { ProgramTemplateBundleBaseListStore } from './program-template-bundle-base-list.store';
import { firstValueFrom } from 'rxjs';

/**
 * An Akita service for the program routes in the activity API.
 *
 * https://api-tst-doenkids.springtree.io/activity/docs/#tag/program-template-bundle
 *
 */
@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleBaseListService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: ProgramTemplateBundleBaseListStore,
  ) {}

  async fetchAll({
    activityTypeId,
    archived = false,
    limit = 250,
    nodeOnly = false,
    organizationUnitId,
    programCategoryId,
    statusId,
    skip,
    sortDirection,
    sortField,
    language,
  }: {
    activityTypeId?: number,
    archived?: boolean,
    limit: number,
    nodeOnly?: boolean,
    organizationUnitId: number,
    programCategoryId?: number,
    statusId?: number,
    skip: number,
    sortDirection?: string,
    sortField?: string,
    language?: string,
  }): Promise<IProgramTemplateBundle[]> {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      '/activity/program-template-bundle/base', limit, skip, sortField, sortDirection, {
        activityTypeId,
        archived,
        nodeOnly,
        organizationUnitId,
        programCategoryId,
        statusId,
        language,
      },
    )) as IProgramTemplateBundleListResponse;
    this.store.set(response?.items ?? []);
    return response?.items ?? [];
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
