import { BehaviorSubject, Subject, mergeMap, takeUntil } from 'rxjs';
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-continue */
import {
  Component, Input, forwardRef, HostListener, OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl,
} from '@angular/forms';
import { isNil, get, isEmpty } from 'lodash';
import { IRangeOfDevelopmentDetails } from 'typings/doenkids/doenkids';
import { RangeOfDevelopmentQuery } from 'src/api/generic/range-of-development/range-of-development.query';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';

@Component({
  selector: 'app-search-aggregations',
  templateUrl: './search-aggregations.component.html',
  styleUrls: ['./search-aggregations.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => SearchAggregationsComponent),
    multi: true,
  }],
})
export class SearchAggregationsComponent implements ControlValueAccessor, OnDestroy {
  private stop$ = new Subject<void>();

  @Input() set aggregationData(aggregations: { [index: string]: string }) {
    this._aggregationData = aggregations;
    this.setAggregationLength(aggregations);
  }

  get aggregationData() {
    return this._aggregationData;
  }

  @Input()
  public translationPrefix = 'activity';

  private _aggregationData: any;

  public aggregationLength = 0;

  public showAllTags$ = new BehaviorSubject(false);

  @Input() set aggregationLabels(labels: { [index: string]: string }) {
    this._aggregationLabels = labels;

    if (isNil(labels)) {
      return;
    }

    this.setAggregationKeys(labels);
    this.prepareForm();
  }

  get aggregationLabels(): { [index: string]: string } {
    return this._aggregationLabels;
  }

  private _aggregationLabels: { [index: string]: string };

  public aggregationKeys = [];

  public form: UntypedFormGroup;

  private onTouchedCallback: Function;

  private initValue: any;

  private localRangeOfDevelopments: IRangeOfDevelopmentDetails[];

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  constructor(
    fb: UntypedFormBuilder,
    $session: DoenkidsSessionProvider,
    rangeOfDevelopmentQuery: RangeOfDevelopmentQuery,
  ) {
    this.form = fb.group({});

    $session.ouCountryCode$.pipe(
      takeUntil(this.stop$),
      mergeMap((countryCode) => rangeOfDevelopmentQuery.getRangeOfDevelopmentForCountryCode(countryCode)),
    ).subscribe((rangesOfDevelopment) => {
      this.localRangeOfDevelopments = rangesOfDevelopment;
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  writeValue(value: any): void {
    this.initValue = value;
    // we check here if the controls have already been set. if not don't set them because it will give an error in the console
    // and has not use
    //
    if (Object.keys(this.form.controls).length > 0 && !isEmpty(value)) {
      for (const propertyKey in value) {
        if (value.hasOwnProperty(propertyKey)) {
          const formEntry = this.form.get(propertyKey);
          formEntry?.setValue(value[propertyKey]);
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.stop$)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  /** Sets the aggregation keys for the template to loop over.
   * @private
   * @param {{ [index: string]: string }} labels The labels passed to this component.
   */
  private setAggregationKeys(labels: { [index: string]: string }) {
    // Get the keys for the aggregations based off the labels passed to the component.
    //
    this.aggregationKeys = Object.keys(labels).filter((key) => !this.aggregationKeys.hasOwnProperty(key));
  }

  /** Makes sure a form control is prepared for every available key, and cleans up old controls if needed.
  */
  private prepareForm() {
    // Get all aggregations already attached to the form.
    //
    const formControlKeys = Object.keys(this.form.controls).filter((key) => this.form.controls.hasOwnProperty(key));

    if (!isNil(this._aggregationLabels)) {
      for (const controlKey of formControlKeys) {
        // If there's a matching aggregation for this formControl, don't do anything (continue to next loop).
        //
        if (this.aggregationKeys.includes(controlKey)) {
          continue;
        }

        // Otherwise, there's a control present for a non-existent aggregation option. Remove the control to keep things tidy.
        //
        this.form.removeControl(controlKey);
      }
    }

    for (const aggregation of this.aggregationKeys) {
      // If the control already exists, don't add a new one (continue to next loop).
      //
      if (formControlKeys.includes(aggregation)) {
        continue;
      }

      // Add a control for the new aggregation.
      //
      this.form.addControl(aggregation, new UntypedFormControl([]));
    }

    // have we gotten an value from writeValue? set the value here just in case it isn't set yet in writeValue
    //
    if (!isEmpty(this.initValue)) {
      this.form.setValue(this.initValue);
    }
  }

  private setAggregationLength(aggregations) {
    if (isNil(aggregations)) {
      this.aggregationLength = 0;
      return;
    }
    let length = 0;

    // Get the aggregation keys to loop through.
    //
    const aggregationKeys = Object.keys(aggregations).filter((key) => !this.aggregationKeys.hasOwnProperty(key));

    // Count all the aggregations that have results.
    //
    aggregationKeys.forEach((key) => (get(aggregations, `${key}.buckets.length`) > 0 ? length++ : length));

    // Set the length. If it is 0, the page will be empty and a message has to be shown.
    //
    this.aggregationLength = length;
  }

  showMoreTags() {
    this.showAllTags$.next(true);
  }

  getRangeOfDevelopmentLabel(optionKey: string) {
    const rangeOfDevelopment = (this.localRangeOfDevelopments ?? []).find((localRangeOfDevelopment) => localRangeOfDevelopment.name.toLowerCase() === optionKey.toLowerCase());

    if (rangeOfDevelopment) {
      return `${rangeOfDevelopment.name} (${rangeOfDevelopment.area_of_development})`;
    }
    return optionKey;
  }
}
