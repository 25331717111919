import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ISectionTypeListResponse } from 'typings/api-activity';
import { ProgramSectionTypeStore } from './program-section-type.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramSectionTypeService {
  constructor(private store: ProgramSectionTypeStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 50, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'DESC') {
    const fetchedSectionTypes = await firstValueFrom(this.$baseApi.genericListCall('/activity/period-section-type', limit, skip, sortField, sortDirection)) as ISectionTypeListResponse;

    this.store.set(fetchedSectionTypes?.items ?? []);

    return fetchedSectionTypes.items;
  }
}
