import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProgramTemplateBundle } from 'typings/doenkids/doenkids';
import {
  ProgramTemplateBundleBaseListState, ProgramTemplateBundleBaseListStore,
} from './program-template-bundle-base-list.store';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleBaseListQuery extends QueryEntity<ProgramTemplateBundleBaseListState, IProgramTemplateBundle> {
  constructor(protected store: ProgramTemplateBundleBaseListStore) {
    super(store);
  }
}
