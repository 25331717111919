/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IAgeGroupDetails } from 'typings/doenkids/doenkids';

export interface IAgeGroupState extends EntityState<IAgeGroupDetails> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'age-group' })
export class AgeGroupStore extends EntityStore<IAgeGroupState, IAgeGroupDetails> {
  constructor() {
    super();
  }
}
