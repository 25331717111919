import {
  Component, OnInit, Input, OnDestroy, ViewEncapsulation, SimpleChanges, OnChanges,
} from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { OrganizationUnitSymbolListService } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.service';
import { OrganizationUnitSymbolListQuery } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.query';
import {
  IActivityType,
  IAgeGroupDetails,
  IAreaOfDevelopment,
  ICustomerSymbol,
  IOrganizationUnitOverview,
} from 'typings/doenkids/doenkids';
import {
  UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, AbstractControl, FormGroup,
} from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { IMediaItem } from 'typings/section-types';
import { OrganizationUnitCustomerSymbolService } from 'src/api/customer/organization-unit-customer-symbol/organization-unit-customer-symbol.service';
import { IChoiceDialogData, ChoiceDialogComponent, IChoiceDialogResult } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  cloneDeep,
  get,
  isNil,
  omit,
  set,
  sortBy,
  uniq,
} from 'lodash';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import {
  AddOrganizationCustomerSymbolDialogComponent,
} from 'src/components/dialogs/add-organization-customer-symbol-dialog/add-organization-customer-symbol-dialog.component';
import { PermissionProvider } from 'src/providers/permission.provider';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IUploadResponse } from 'typings/custom-app-types';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { KeyValue } from '@angular/common';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { IWeightedActivityKind } from 'typings/api-activity';

export const ALL_TYPE_CUSTOMER_SYMBOL_GROUP: IActivityType = {
  id: 0,
  name: _('customer_symbols.all_types'),
};

@Component({
  selector: 'app-customer-symbols',
  templateUrl: './customer-symbols.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./customer-symbols.component.scss'],
})
export class CustomerSymbolsComponent implements OnInit, OnChanges, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  @Input()
    organizationUnitId: number;

  public currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public symbolsFormGroup = new UntypedFormGroup({});

  public editSymbolId: number;

  public activityTypes$: Observable<IActivityType[]>;

  public conditions: {
    activity_kind: IWeightedActivityKind[] | undefined;
    age_group: IAgeGroupDetails[] | undefined;
    area_of_development: IAreaOfDevelopment[] | undefined;
  };

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private matDialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private organizationUnitSymbolListQuery: OrganizationUnitSymbolListQuery,
    private organizationUnitSymbolListService: OrganizationUnitSymbolListService,
    private organizationUnitCustomerSymbolService: OrganizationUnitCustomerSymbolService,
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
    );
    this.activityTypes$ = this.$session.availableActivityTypes$.pipe(
      takeUntil(this.stop$),
      map((activityTypes) => {
        const activityTypesClone = cloneDeep(activityTypes);
        activityTypesClone.unshift(ALL_TYPE_CUSTOMER_SYMBOL_GROUP);
        return activityTypesClone;
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.fetchCustomerSymbols();

    this.organizationUnitSymbolListQuery.selectAll()
      .pipe(
        takeUntil(this.stop$),
      ).subscribe({
        next: (symbols) => {
          this.editSymbolId = undefined;
          this.setSymbolForms(symbols);
        },
      });

    this.conditions = await this.organizationUnitSymbolListService.getSymbolConditionOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizationUnitId && !changes.organizationUnitId.firstChange) {
      this.fetchCustomerSymbols();
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  getListItemDescription(id: number, list: any) {
    let name = '';
    if (list) {
      const result = list.find((item) => item.id === id);
      name = result.name;
    }
    return name;
  }

  /**
   * Remove a symbol from condition
   * @param form The for that contains the condition
   * @param conditionName The condition to remove the symbol out of
   * @param symbolId The Id of the symbol to remove
   */
  removeCondition(form: UntypedFormControl, conditionName: string, symbolId: number) {
    if (form) {
      const value = form.value as any;
      let currentValues = get(value, conditionName, []);
      currentValues = currentValues.filter((id) => id !== symbolId);
      set(value, conditionName, currentValues);
      form.patchValue(value);
    }
  }

  /**
   * Add a symbol condition
   * @param form The for that contains the condition
   * @param conditionName The condition to add the symbol to
   * @param event The event with symbol value
   */
  addCondition(form: UntypedFormControl, conditionName: string, event: MatAutocompleteSelectedEvent) {
    if (form) {
      const value = form.value as any;
      const currentValues = get(value, conditionName, []);
      currentValues.push(event.option.value.id);
      set(value, conditionName, uniq(currentValues));
      form.patchValue(value);
    }
  }

  setSymbolForms(symbols: ICustomerSymbol[]) {
    this.symbolsFormGroup = this.formBuilder.group({});

    const symbolsSplitByActivityTypeId: { [key: string]: ICustomerSymbol[] } = {};

    symbols.forEach((symbol) => {
      const mappedSymbolTypeGroupName = symbol.activity_type_id ? `${symbol.activity_type_id}` : '0';

      if (!symbolsSplitByActivityTypeId[mappedSymbolTypeGroupName]) {
        symbolsSplitByActivityTypeId[mappedSymbolTypeGroupName] = [];
      }
      symbolsSplitByActivityTypeId[mappedSymbolTypeGroupName].push(symbol);
    });

    for (let mappedSymbols of Object.values(symbolsSplitByActivityTypeId)) {
      const symbolOrderIsNotSetYet = mappedSymbols.every((symbol) => isNil(symbol.display_order) || symbol.display_order === 0);

      if (symbolOrderIsNotSetYet) {
        mappedSymbols = sortBy(mappedSymbols, 'id');
      }

      mappedSymbols.forEach((symbol, index) => {
        const mappedSymbolTypeGroupName = symbol.activity_type_id ? `${symbol.activity_type_id}` : '0';

        let typeGroup = this.symbolsFormGroup.get(mappedSymbolTypeGroupName) as UntypedFormGroup;

        if (!typeGroup) {
          typeGroup = this.formBuilder.group({});
          this.symbolsFormGroup.addControl(mappedSymbolTypeGroupName, typeGroup);
        }

        typeGroup.addControl(symbol.id.toString(), new UntypedFormGroup({
          id: new UntypedFormControl(symbol.id),
          name: new UntypedFormControl(symbol.name, [Validators.required]),
          media_uuid: new UntypedFormControl(symbol.media_uuid, [Validators.required]),
          activity_type_id: new UntypedFormControl(symbol.activity_type_id ?? 0),
          display_order: new UntypedFormControl(symbolOrderIsNotSetYet ? index : symbol.display_order),
          original_display_order: new UntypedFormControl(symbol.display_order),
          condition: new UntypedFormControl({
            activity_kind: get(symbol, 'condition.activity_kind', []),
            age_group: get(symbol, 'condition.age_group', []),
            area_of_development: get(symbol, 'condition.area_of_development', []),
          }),
        }));
      });
    }
  }

  async toggleEditSymbol(symbolId: number) {
    if (this.editSymbolId === symbolId) {
      this.editSymbolId = undefined;
    } else {
      this.editSymbolId = symbolId;
    }
  }

  fetchCustomerSymbols() {
    if (this.$permission.hasCustomerSymbolsPermission$.value) {
      this.organizationUnitSymbolListService.reset();
      this.organizationUnitSymbolListService.fetchAll(this.organizationUnitId);
    }
  }

  addSymbolImage($event: IMediaItem | IUploadResponse, symbolGroup: UntypedFormGroup) {
    symbolGroup.get('media_uuid').setValue($event.uuid);
  }

  async addSymbol() {
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
    const dialogRef = this.matDialog.open(AddOrganizationCustomerSymbolDialogComponent, {
      width: '550px',
      data: {
        organizationUnitId: organizationUnit.id,
        organizationUnitName: organizationUnit.name,
        currentSymbols: this.symbolsFormGroup.value,
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'success') {
      this.fetchCustomerSymbols();
    }
  }

  async saveSymbol(symbolGroup: UntypedFormGroup, params: {
    showToast?: boolean,
    fetchDataAfterwards?: boolean
  } = { showToast: true, fetchDataAfterwards: true }) {
    const symbol: ICustomerSymbol = symbolGroup.value;

    if (symbol.activity_type_id === 0) {
      symbol.activity_type_id = null;
    }

    await this.organizationUnitSymbolListService.update(omit(symbol, ['original_display_order']) as ICustomerSymbol);

    if (params.showToast) {
      this.$i18nToastProvider.success(_('customer_symbols.symbol.saved'));
    }
    if (params.fetchDataAfterwards) {
      this.fetchCustomerSymbols();
    }
  }

  async removeSymbol(symbolId: number) {
    const data: IChoiceDialogData = {
      title: this.$translateService.instant(_('generic.remove')),
      description: this.$translateService.instant(_('customer_symbols.symbol.remove.dialog.description')),
      actions: {
        primaryAction: {
          label: this.$translateService.instant(_('generic.yes')),
          value: 'archive',
        },
      },
    };

    const dialogRef = this.matDialog.open(ChoiceDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data,
    });

    const result: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (isNil(result)) {
      return;
    }

    await this.organizationUnitCustomerSymbolService.archive(this.organizationUnitId, symbolId);
    this.fetchCustomerSymbols();
    this.$i18nToastProvider.success(_('customer_symbols.symbol.removed'));
  }

  dropSymbol(event: CdkDragDrop<AbstractControl<any, any>>, section: string) {
    const { previousIndex, currentIndex } = event;
    const sectionControl = this.symbolsFormGroup.get(section);
    const allItems = (cloneDeep(Object.values(sectionControl.value)) as ICustomerSymbol[]).sort((symbolA, symbolB) => symbolA.display_order - symbolB.display_order);
    const symbolToMove = cloneDeep((allItems[previousIndex])) as ICustomerSymbol;
    const symbolToReplace = cloneDeep((allItems[currentIndex])) as ICustomerSymbol;
    const placement = currentIndex > previousIndex ? 'after' : 'before';

    this.organizationUnitSymbolListService.reorder(symbolToMove.id, symbolToReplace.id, this.organizationUnitId, placement).then(() => {
      this.fetchCustomerSymbols();
    });
  }

  orderSectionControls(controlA: KeyValue<string, FormGroup>, controlB: KeyValue<string, FormGroup>) {
    return (controlA.value.get('display_order')?.value ?? 0) - (controlB.value.get('display_order')?.value ?? 0);
  }

  getSymbolsGroupForId(activityTypeId: number) {
    return this.symbolsFormGroup.get(`${activityTypeId}`) as FormGroup<{ [key: string]: FormGroup }>;
  }
}
