import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { ICustomerSymbolListResponse } from 'typings/api-customer';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ICustomerSymbol } from 'typings/doenkids/doenkids';
import { IEntityWithAccessLevel } from 'typings/api-generic';
import { OrganizationUnitSymbolListQuery } from './organization-unit-symbol-list.query';
import { OrganizationUnitSymbolListStore } from './organization-unit-symbol-list.store';
import { firstValueFrom } from 'rxjs';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ActivityKindQuery } from 'src/api/generic/activity-kind/activity-kind.query';
import { AgeGroupQuery } from 'src/api/generic/age-group/age-group.query';
import { AreaOfDevelopmentQuery } from 'src/api/generic/area-of-development/area-of-development.query';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitSymbolListService {
  constructor(
    private store: OrganizationUnitSymbolListStore,
    private query: OrganizationUnitSymbolListQuery,
    private $baseApi: DoenKidsGenericApiProvider,
    private $activityKind: ActivityKindQuery,
    private $ageGroup: AgeGroupQuery,
    private $areaOfDevelopment: AreaOfDevelopmentQuery,
    private $session: DoenkidsSessionProvider,
  ) {}

  @transaction()
  async fetchAll(organizationUnitId, limit = 100, skip = 0, sortField?: string, sortDirection?: string, archived = false) {
    const response = await firstValueFrom(this.$baseApi.genericListCall('/customer/symbol', limit, skip, sortField, sortDirection, { organizationUnitId, archived })) as ICustomerSymbolListResponse;
    if (response?.items) {
      this.store.set(response.items);
      return response.items;
    }

    return [];
  }

  async fetch(customerSymbolId: number, doNotPersist: boolean = false) {
    if (this.query.hasEntity(customerSymbolId)) {
      return this.query.getEntity(customerSymbolId);
    }

    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/symbol/${customerSymbolId}`)) as IEntityWithAccessLevel<ICustomerSymbol>;

    if (response?.data) {
      if (!doNotPersist) {
        this.store.upsert(response.data.id, response.data);
      }
      return response.data;
    }

    return null;
  }

  async create(organizationUnitId: number, symbol: ICustomerSymbol) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall(`/customer/symbol?organization_unit_id=${organizationUnitId}`, symbol)) as ICustomerSymbol;

    if (response) {
      this.store.upsert(response.id, response);
    }

    return response;
  }

  async update(symbol: ICustomerSymbol) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/symbol/${symbol.id}`, symbol)) as ICustomerSymbol;

    if (response) {
      this.store.upsert(response.id, response);
    }

    return response;
  }

  async archive(symbolId: number) {
    this.store.remove(symbolId);
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/symbol/${symbolId}`));
  }

  async getSymbolConditionOptions() {
    const countryCode = await firstValueFrom(this.$session.ouCountryCode$);
    const activityKindList = await firstValueFrom(this.$activityKind.getActivityKindForCountryCode(countryCode));
    const ageGroupList = await firstValueFrom(this.$ageGroup.getAgeGroupsForCountryCode(countryCode));
    const areaOfDevelopmentList = await firstValueFrom(this.$areaOfDevelopment.getAreaOfDevelopmentForCountryCode(countryCode));
    return {
      activity_kind: activityKindList ?? [],
      age_group: (ageGroupList ?? []),
      area_of_development: areaOfDevelopmentList ?? [],
    };
  }

  async reorder(symbolIdToMove: number, targetSymbolId: number, ouId: number, placement: 'before' | 'after') {
    let url = `/customer/symbol/${symbolIdToMove}/reorder/${targetSymbolId}`;

    url = this.$baseApi.appendQueryParam(url, 'organization_unit_id', ouId);
    url = this.$baseApi.appendQueryParam(url, 'placement', placement);

    return await firstValueFrom(this.$baseApi.genericPutCall(url, {})) as ICustomerSymbol;
  }

  reset() {
    this.store.reset();
  }
}
