import {
  Component, OnInit, Inject, ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isNil } from '@datorama/akita';
import { get, isArray } from 'lodash';

export interface IDialogOption {
  label: string;
  value: string;
  labelShouldBeTranslated?: boolean;
  labelParams?: any;
  disableWhen?: string;
  enableWhen?: string | string[];
}

export interface IDialogActions {
  primaryAction: IDialogOption;
  secondaryAction?: IDialogOption;
}

export interface IChoiceDialogData {
  title: string;
  description: string;
  actions?: IDialogActions;
  selectionOptionLabel?: string;
  selectionOptionLabelShouldBeTranslated?: boolean;
  preselectedOption?: string;
  selectionOptions?: IDialogOption[];
  checkboxOptions?: IDialogOption[];
  hideCancel?: boolean;
}

export interface IChoiceDialogResult {
  action: string;
  selectedOption?: string;
  checkedValues?: { [index: string]: boolean };
}

@Component({
  selector: 'app-choice-dialog',
  templateUrl: './choice-dialog.component.html',
  styleUrls: ['./choice-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChoiceDialogComponent implements OnInit, IChoiceDialogData {
  title: string;

  description: string;

  selectionOptions?: IDialogOption[];

  actions: IDialogActions = {
    primaryAction: {
      label: 'OK',
      value: 'confirm',
    },
  };

  checkboxOptions?: IDialogOption[];

  hideCancel?: boolean;

  optionControl: UntypedFormControl;

  checkboxGroup: UntypedFormGroup;

  disabledControls: { [index: string]: string } = {};

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IChoiceDialogData,
  ) {
    this.optionControl = fb.control(undefined);
    this.checkboxGroup = fb.group({
    });
  }

  ngOnInit() {
    if (isNil(this.data)) {
      console.warn('No data passed to dialog, returning.');
      return this.dialogRef.close();
    }

    Object.assign(this, this.data);

    if (!isNil(this.selectionOptions) && get(this.selectionOptions, 'length') > 0) {
      if (this.data.preselectedOption) {
        this.optionControl.setValue(this.data.preselectedOption);
      } else {
        this.optionControl.setValue(this.selectionOptions[0].value);
      }

    }

    if (!isNil(this.checkboxOptions) && this.checkboxOptions.length > 0) {
      for (const option of this.checkboxOptions) {
        this.checkboxGroup.addControl(option.value, this.fb.control(false));
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm(action: string) {
    if (isNil(action)) {
      console.warn('No value selected.');
    }

    const selectedOption = this.optionControl.value;
    const checkedValues = this.checkboxGroup.value;

    const result: IChoiceDialogResult = {
      action,
      selectedOption,
      checkedValues,
    };

    this.dialogRef.close(result);
  }

  shouldShowCheckboxOption(checkboxOption: IDialogOption, value: string) {
    return checkboxOption?.enableWhen === undefined ||
      ((!isArray(checkboxOption.enableWhen) && value === checkboxOption?.enableWhen)
        || (isArray(checkboxOption.enableWhen) && checkboxOption.enableWhen.includes(value)));
  }
}
