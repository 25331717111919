import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IKonnectLocation } from 'typings/doenkids/doenkids';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IKonnectOrganisationDetails, IKonnectLocationListResponse } from 'typings/api-customer';
import { KonnectLocationStore } from './konnect-location.store';
import { firstValueFrom } from 'rxjs';
import { IListRequest } from 'typings/api-activity';

@Injectable({ providedIn: 'root' })
export class KonnectLocationService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: KonnectLocationStore) {}

  @transaction()
  async fetchAll(konnectOrganizationId: number, limit: number, skip: number, sortField: string, sortDirection: string, name?: string) {
    let url = '/customer/konnect/location';
    url = this.$baseApi.appendQueryParam(url, 'konnect_organisation_id', konnectOrganizationId);

    const response = await firstValueFrom(this.$baseApi.genericPostCall(url, {
      limit,
      skip,
      sortField,
      sortDirection,
      search: name,
    } as IListRequest)) as IKonnectLocationListResponse;
    this.store.set(response?.items ?? []);
    return response;
  }

  @transaction()
  async fetch(id: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/konnect/location/${id}`)) as IKonnectOrganisationDetails;
    if (response) {
      this.store.update(response);
    }
    return response;
  }

  async setOrganizationUnit(locationId: number, organizationUnitId: number) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/konnect/location/${locationId}/link/${organizationUnitId}`,
      null)) as IKonnectLocation;
    if (response) {
      this.store.upsert(response.id, response);
    }
    return response;
  }

  async unsetOrganizationUnit(locationId: number) {
    const response = await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/konnect/location/${locationId}/link`)) as IKonnectLocation;
    if (response) {
      this.store.upsert(response.id, response);
    }
    return response;
  }
}
