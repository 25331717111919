import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, firstValueFrom, combineLatest, from } from 'rxjs';
import { CurrentUserQuery } from 'src/api/customer/auth/auth.query';
import {
  isEmpty, isNil, first, get,
} from 'lodash';
import { IAuthenticatedUser, IOrganizationUnitBrowseItem, IUserPermission } from 'typings/api-customer';
import {
  filter, map, mergeMap,
} from 'rxjs/operators';
import { OrganizationUnitDetailsService } from 'src/api/customer/organization-unit-details/organization-unit-details.service';
import { Router } from '@angular/router';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { IActivityType, IOrganizationUnitOverview, IOrganizationUnitUserPermission } from 'typings/doenkids/doenkids';
import { TagListService } from 'src/api/activity/tag-list/tag-list.service';
import { OrganizationUnitBrowseService } from 'src/api/customer/organization-unit-browse/organization-unit-browse.service';
import { DoenKidsAuth0Provider } from './auth0.provider';
import { DoenKidsGenericApiProvider } from './generic.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MatDialog } from '@angular/material/dialog';
import { ChoiceDialogComponent, IChoiceDialogData, IChoiceDialogResult, IDialogOption } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivityTypeQuery } from 'src/api/generic/activity-type/activity-type.query';
import { GenericDataService } from 'src/api/generic/generic-data.service';

export interface ISupportedCountryEntry {
  countryCode: string;
  viewValue: string;
}

export interface IDoenKidsSession {
  mediaUuid: string | undefined;
  emailAddress: string;
  details: IOrganizationUnitOverview;
}

export const SELECTED_ORGANZATION_UNIT_STORAGE_KEY = 'currentOrganizationUnitId';
const PREFERRED_CONTENT_LANGUAGE_PER_COUNTRY_STORAGE_KEY = 'preferredContentLanguagePerCountry';

export const SUPPORTED_COUNTRIES: ISupportedCountryEntry[] = [
  { countryCode: 'gb', viewValue: _('country.en') },
  { countryCode: 'nl', viewValue: _('country.nl') },
  { countryCode: 'be', viewValue: _('country.be') },
];

export const LANGUAGES_FOR_COUNTRIES = {
  gb: ['en'],
  nl: ['nl'],
  be: ['nl', 'fr'],
};

// This stream will hook into the Auth0 feed and return the currently logged-in user
// DO NOT EXPORT THE BEHAVIORSUBJECT ITSELF. This is because we don't want outside components to be able to set this
// if you need the value use either the observable that is defined right after or use the session providers getCurrentUser method
//
// eslint-disable-next-line @typescript-eslint/naming-convention
const _getUser$ = new BehaviorSubject<IAuthenticatedUser | undefined>(undefined);

export const getUser$ = _getUser$.pipe(
  filter((user) => !isNil(user)),
);

// This stream will return the currently selected organization unit
// DO NOT EXPORT THE BEHAVIORSUBJECT ITSELF. This is because we don't want outside components to be able to set this
// if you need the value use either the observable that is defined right after or use the session providers getCurrentOu method
//
// eslint-disable-next-line @typescript-eslint/naming-convention
const _getOrganizationUnit$ = new BehaviorSubject<IOrganizationUnitOverview | undefined>(undefined);

export const getOrganizationUnit$: Observable<IOrganizationUnitOverview> = _getOrganizationUnit$.pipe(
  filter((organization) => !isNil(organization)),
);

@Injectable({
  providedIn: 'root',
})
export class DoenkidsSessionProvider {
  // This stream will return the current session
  //
  getSession$: BehaviorSubject<IDoenKidsSession | undefined>;

  getOrganizationUnit$ = getOrganizationUnit$;

  getUser$ = getUser$;

  writeableRootOuView$: BehaviorSubject<IOrganizationUnitBrowseItem[]>;

  // This stream will only emit when an organization switch has happended
  //
  getOrganizationUnitSwitch$: Subject<IOrganizationUnitOverview>;

  // This stream will emit true if this OU is located in a detached node (root is not DoenKids)
  //
  isOrganizationUnitPartOfAnDetachedNodeTree$: Observable<boolean>;

  // This stream will emit the currently selected customer node, even if we selected a child node
  //
  getCurrentCustomerOrganizationUnitId$: Observable<number>;

  // Based IAuthenticatedUser response check if you are an administrator
  //
  isAdmin$: Observable<boolean>;

  // Based on IAuthenticatedUser response check if you have the reader role
  //
  isReader$: Observable<boolean>;

  // Based on IAuthenticatedUser, see if there is a user on the session
  //
  isLoggedInSuccessfully$: Observable<boolean>;

  isRootOrganization$: Observable<boolean>;

  isCurrentOrganizationDoenKids$: Observable<boolean>;

  // Is the current OU of type location?
  //
  isCurrentOrganizationUnitIsOfTypeLocation$: Observable<boolean>;

  // Is the current OU of type customer?
  //
  isCurrentOrganizationUnitIsOfTypeCustomer$: Observable<boolean>;

  // Is the current OU of type group?
  //
  isCurrentOrganizationUnitIsOfTypeGroup$: Observable<boolean>;

  // Is the current OU a root node?
  //
  isCurrentOrganizationUnitARootNode$: Observable<boolean>;

  organizationActivityTypeIds$: Observable<number[]>;

  // The available OKO types
  //
  availableActivityTypes$: Observable<IActivityType[]>;

  userPermissions$: Observable<IUserPermission[]>;

  hasTranslations$: Observable<boolean>;

  public logout() {
    this.$auth.logout();
  }

  public get storedOrganizationUnitId() {
    const storedItem = localStorage.getItem(SELECTED_ORGANZATION_UNIT_STORAGE_KEY);
    if (storedItem) {
      return parseInt(storedItem, 10);
    }
    return undefined;
  }

  public set storedOrganizationUnitId(id: number) {
    localStorage.setItem(SELECTED_ORGANZATION_UNIT_STORAGE_KEY, `${id}`);
  }

  public async selectOrganizationUnit(organizationUnitId: number) {
    let organizationUnitDetails;
    try {
      organizationUnitDetails = await this.$organizationUnitDetailsService.fetch(organizationUnitId);
    } catch (error) {
      console.error(`[DOENKIDS]: Cannot select organizaton ${organizationUnitId} because the user doesn't have access to it`);
      this.storedOrganizationUnitId = 1; // Default to Doenkids
      organizationUnitDetails = await this.$organizationUnitDetailsService.fetch(1);
    }

    // Check if we need to emit an organization unit switch
    //
    const currentOrganizationUnit = _getOrganizationUnit$.getValue();
    const currentUser = await this.$currentUserQuery.getValue();
    _getOrganizationUnit$.next(organizationUnitDetails);
    this.tagListService.fetchAll(organizationUnitDetails.id, 5000, 0);
    this.getSession$.next({
      mediaUuid: currentUser?.user?.media_uuid,
      emailAddress: currentUser?.user?.email,
      details: organizationUnitDetails,
    });

    if (currentOrganizationUnit && organizationUnitId && currentOrganizationUnit.id !== organizationUnitId) {
      this.getOrganizationUnitSwitch$.next(organizationUnitDetails);
    }

    this.storedOrganizationUnitId = organizationUnitDetails.id;
  }

  public ouCountryCode$: Observable<string>;

  public ouLanguages$: Observable<string[]>;

  private _preferredContentLanguagePerCountry$ = new BehaviorSubject<Map<string, string>>(new Map<string, string>([]));

  public preferredContentLanguagePerCountry$ = this._preferredContentLanguagePerCountry$.asObservable();

  private _preferredContentLanguage$ = new BehaviorSubject<string>('');

  public preferredContentLanguage$ = this._preferredContentLanguage$.asObservable().pipe(
    filter((contentLanguage) => !isNil(contentLanguage) && contentLanguage !== ''),
  );

  public currentOuId$: Observable<number>;

  constructor(
    private $auth: DoenKidsAuth0Provider,
    private router: Router,
    private $baseApi: DoenKidsGenericApiProvider,
    private organizationUnitService: OrganizationUnitService,
    private $organizationUnitDetailsService: OrganizationUnitDetailsService,
    private $currentUserQuery: CurrentUserQuery,
    private tagListService: TagListService,
    private organizationUnitBrowseService: OrganizationUnitBrowseService,
    private dialog: MatDialog,
    private $ngxTranslateService: TranslateService,
    private activityTypeQuery: ActivityTypeQuery,
    private $genericData: GenericDataService,
  ) {
    this.currentOuId$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization?.id),
    );

    this.getSession$ = new BehaviorSubject(undefined);

    this.writeableRootOuView$ = new BehaviorSubject<IOrganizationUnitBrowseItem[]>([]);

    this.getOrganizationUnitSwitch$ = new Subject<IOrganizationUnitOverview>();

    this.isOrganizationUnitPartOfAnDetachedNodeTree$ = this.getOrganizationUnit$.pipe(
      map((organization) => first(organization?.node_path) !== 1),
    );

    this.isAdmin$ = this.getUser$.pipe(
      map((response) => response?.user.user_role === 'administrator' ?? false),
    );

    this.isReader$ = this.getUser$.pipe(
      map((response) => response?.user.user_role === 'reader' ?? false),
    );

    this.isLoggedInSuccessfully$ = this.getUser$.pipe(
      map((response) => !isNil(response?.user) ?? false),
    );

    this.getCurrentCustomerOrganizationUnitId$ = this.getOrganizationUnit$.pipe(
      mergeMap(async (organization) => {
        if (organization.organization_unit_type_id !== DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_CUSTOMER) {
          if (organization.node_path && organization.node_path.length) {
            const reversed = organization.node_path.reverse();
            for (let index = 0; index < reversed.length; index++) {
              // eslint-disable-next-line no-await-in-loop
              const parentOrganization = await this.organizationUnitService.fetch(reversed[index]);
              if (parentOrganization.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_CUSTOMER) {
                return parentOrganization.id;
              }
            }
          }
        }

        return organization.id;
      }),
    );

    this.isRootOrganization$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization.parent_organization_unit_id === null && organization.id !== 1),
    );

    this.isCurrentOrganizationDoenKids$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization.id === DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER),
    );

    this.isCurrentOrganizationUnitIsOfTypeLocation$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization?.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION),
    );

    this.isCurrentOrganizationUnitIsOfTypeCustomer$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization?.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_CUSTOMER),
    );

    this.isCurrentOrganizationUnitIsOfTypeGroup$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization?.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_GROUP),
    );

    this.isCurrentOrganizationUnitARootNode$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization?.parent_organization_unit_id === null),
    );

    this.organizationActivityTypeIds$ = this.getOrganizationUnit$.pipe(
      map((organization) => {
        if (isEmpty(organization.activity_type_ids)) {
          // if no activity types are set return all of them
          // for locations if nothing is set they don't have the oko type set in the details page.
          // for customers it might be because there are no locations/groups underneath the customer which causes it to be empty
          // and then we also wanna show everything
          //
          return this.activityTypeQuery.getActivityTypeByCountryCode(organization.country_code).map((activityType) => activityType.id);
        }

        return organization.activity_type_ids.map((activityTypeId) => parseInt(`${activityTypeId}`, 10));
      }),
    );

    this.userPermissions$ = this.getUser$.pipe(
      map((response) => response?.permission),
    );

    this.hasTranslations$ = this.getOrganizationUnit$.pipe(
      map((organization) => !isEmpty(get(organization, 'i18n_translation'))),
    );

    this.$baseApi.isLoginRequired().subscribe(() => {
      this.router.navigate(['login']);
    });

    this.$currentUserQuery.select().subscribe(async (currentUser) => {
      if (!isEmpty(currentUser)) {
        _getUser$.next(currentUser);
        console.log('[DOENKIDS-SESSION]: Current user', currentUser);

        await this.$genericData.initialize();

        this.getWritableRootOUView().then((rootOuView) => {
          this.writeableRootOuView$.next(rootOuView);
        });
        // All new user session follow a 4 step plan
        // STEP 1: retrieve the organization unit nodes
        //
        let selectedOrganizationUnitId: number;
        const storedId = this.storedOrganizationUnitId;
        if (storedId) {
          const hasWritePermissionForStoredOu = await this.hasWritePermissionForOU(storedId);

          // if the current user has write access to the stored ou load it but otherwise load the details of the default ou
          //
          if (hasWritePermissionForStoredOu) {
            selectedOrganizationUnitId = storedId;
          } else {
            selectedOrganizationUnitId = currentUser.defaultOrganizationUnit.id;
          }
        } else {
          selectedOrganizationUnitId = currentUser.defaultOrganizationUnit.id;
        }

        // STEP 2: Select the top of the hierarchy
        //
        if (selectedOrganizationUnitId) {
          // STEP 3: Select the details of this organization unit
          //
          this.selectOrganizationUnit(selectedOrganizationUnitId);
        }
      }
    });

    this.ouCountryCode$ = this.getOrganizationUnit$.pipe(
      map((organization) => organization.country_code),
    );

    this.ouLanguages$ = this.getOrganizationUnit$.pipe(
      map((organization) => isEmpty(organization.languages) ? ['nl'] : organization.languages),
    );

    this.availableActivityTypes$ = combineLatest([this.ouCountryCode$, this.organizationActivityTypeIds$]).pipe(
      mergeMap(([countryCode, activityTypeIds]) => this.activityTypeQuery.getActivityTypeByCountryCodeAndIdStream(countryCode, activityTypeIds)),
    );

    combineLatest([this.ouCountryCode$, this.ouLanguages$]).pipe(
      mergeMap(([countryCode, languages]) => {
        return from(new Promise<[string, string]>(async (resolve) => {
          try {
            const preferredContentLanguagePerCountry = this._preferredContentLanguagePerCountry$.value;
            const currentSelectedContentLanguage = this._preferredContentLanguage$.value;

            if (languages.includes(currentSelectedContentLanguage)) {
              resolve([countryCode, currentSelectedContentLanguage]);
            } else {
              const preferredContentLanguageForCountry = preferredContentLanguagePerCountry.get(countryCode) ?? this.$ngxTranslateService.currentLang.split('-')[0].toLowerCase();

              if (preferredContentLanguageForCountry && languages.includes(preferredContentLanguageForCountry)) {
                resolve([countryCode, preferredContentLanguageForCountry]);
              } else if (preferredContentLanguageForCountry && !languages.includes(preferredContentLanguageForCountry) && languages.length > 1) {
                const data: IChoiceDialogData = {
                  title: this.$ngxTranslateService.instant(_('content_language.dialog.title')),
                  description: this.$ngxTranslateService.instant(
                    _('content_language.dialog.description'),
                    { country: this.$ngxTranslateService.instant(`country.${countryCode.toLowerCase()}`) },
                  ),
                  selectionOptions: languages.map((language) => ({
                    value: language,
                    label: `generic.language.${language.replace('-', '_')}`,
                    labelShouldBeTranslated: true,
                  } as IDialogOption)),
                  actions: {
                    primaryAction: {
                      label: _('generic.confirm'),
                      value: 'confirm',
                      labelShouldBeTranslated: true,
                    } as IDialogOption,
                  },
                  hideCancel: true,
                };

                const dialogRef = this.dialog.open(ChoiceDialogComponent, {
                  width: '400px',
                  minWidth: '320px',
                  data,
                });

                const result: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());

                if (languages.includes(result?.selectedOption)) {
                  resolve([countryCode, result?.selectedOption]);
                } else {
                  resolve([countryCode, languages[0]]);
                }
              } else {
                resolve([countryCode, languages[0]]);
              }
            }
          } catch (e) {
            resolve([countryCode, languages[0]]);
          }
        }));
      }),
      mergeMap((values) => {
        const countryCode = values[0];
        const contentLanguage = values[1];

        return from(this.setPreferredContentLanguage(contentLanguage, countryCode, false));
      }),
    ).subscribe((contentLanguage) => {
      this._preferredContentLanguage$.next(contentLanguage);
    });

    const storageItem = localStorage.getItem(PREFERRED_CONTENT_LANGUAGE_PER_COUNTRY_STORAGE_KEY);
    const storedPreferredContentLanguageForCountry = storageItem ? JSON.parse(storageItem) : {};
    const mappedPreferredContentLanguageForCountry = new Map<string, string>(Object.entries(storedPreferredContentLanguageForCountry));
    this._preferredContentLanguagePerCountry$.next(mappedPreferredContentLanguageForCountry);
  }

  // we put this here as if we would put this in the permission provider (where it should) we get a cyclic dependency error
  async hasWritePermissionForOU(ouId: number) {
    const isAdmin = await firstValueFrom(this.isAdmin$);
    let hasWritePermission: boolean;

    if (!isAdmin) {
      const currentPermissions = await firstValueFrom(this.userPermissions$);

      // this filter should only give 1 row back
      //
      const OUPermissions = currentPermissions.filter((permission: IOrganizationUnitUserPermission) => permission.organization_unit_id === ouId);
      hasWritePermission = !isNil(OUPermissions.find((ouPermission) => ouPermission.permission === 'WRITE'));
    } else {
      hasWritePermission = true;
    }

    return hasWritePermission;
  }

  async getWritableRootOUView() {
    const rootOuView = await this.organizationUnitBrowseService.fetch({
      mode: 'userWriteable',
    });

    return rootOuView;
  }

  async setPreferredContentLanguage(contentLanguage: string, countryCode?: string, setContentLanguage: boolean = true) {
    const currentPossibleLanguages = await firstValueFrom(this.ouLanguages$);
    countryCode = countryCode ?? await firstValueFrom(this.ouCountryCode$);

    if (currentPossibleLanguages.includes(contentLanguage)) {
      const storedItem = localStorage.getItem(PREFERRED_CONTENT_LANGUAGE_PER_COUNTRY_STORAGE_KEY);
      const storedPreferredContentLanguageForCountry: { [key: string]: string } = storedItem ? JSON.parse(storedItem) : {};
      const currentStoredPreferredContentLanguage = storedPreferredContentLanguageForCountry[countryCode];

      if (!currentStoredPreferredContentLanguage || currentStoredPreferredContentLanguage !== contentLanguage) {
        storedPreferredContentLanguageForCountry[countryCode] = contentLanguage;
        localStorage.setItem(PREFERRED_CONTENT_LANGUAGE_PER_COUNTRY_STORAGE_KEY, JSON.stringify(storedPreferredContentLanguageForCountry));
        this._preferredContentLanguagePerCountry$.next(new Map<string, string>(Object.entries(storedPreferredContentLanguageForCountry)));
      }

      if (setContentLanguage) {
        this._preferredContentLanguage$.next(contentLanguage);
      }
      return contentLanguage;
    }
  }

  public getCurrentUser() {
    return _getUser$.value;
  }

  public getCurrentOu() {
    return _getOrganizationUnit$.value;
  }
}
