import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { isNil, cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { IRenderProgramSettings } from 'typings/api-publish';
import { DoenkidsSessionProvider } from './session.provider';
import { IExtendedOrganizationUnitRenderSettings, OrganizationUnitRenderSettingsService } from 'src/api/customer/organization-unit-render-settings/organization-unit-render-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DoenkidsTemplateProvider {
  private _templateSettings$ = new BehaviorSubject<IExtendedOrganizationUnitRenderSettings | null>(null);

  private _programSettings$ = new BehaviorSubject<IRenderProgramSettings | null>(null);

  public programSettings$ = this._programSettings$.pipe(filter((value) => !isNil(value)));

  constructor(
    private $session: DoenkidsSessionProvider,
    private $renderSettings: OrganizationUnitRenderSettingsService,
  ) {
    this.$session.getOrganizationUnit$.subscribe(async (ouDetails) => {
      const templateSettings = await this.getTemplateSettings(ouDetails.id, cloneDeep(ouDetails.node_path));
      this._templateSettings$.next(templateSettings);

      this._programSettings$.next({
        ...(templateSettings?.settings?.program ?? {}),
        ...(templateSettings?.overrides?.[ouDetails.activity_type?.toUpperCase()]?.program ?? {}),
      });
    });
  }

  private async getTemplateSettings(ouId: number, nodePath: number[]) {
    let templateSettings: IExtendedOrganizationUnitRenderSettings;
    try {
      templateSettings = await this.$renderSettings.fetch(ouId);
    } catch (e) {
      // we didn't find settings for the ou so we look at the parent to see if we get some settings from there
      const directParent = nodePath.pop();
      if (!isNil(directParent)) {
        templateSettings = await this.getTemplateSettings(directParent, nodePath);
      }
    }

    return templateSettings;
  }

  public programTemplateImagesFirstIsFixed$ = this.programSettings$.pipe(
    map((programSettings) => programSettings?.banners?.fixedImages === 'first'),
  );

  public programTemplateImagesDisabled$ = this.programSettings$.pipe(
    map((programSettings) => programSettings?.banners?.fixedImages === 'all'),
  );

  public programTemplateImages$ = this.programSettings$.pipe(
    map((programSettings) => programSettings?.banners?.images ?? []),
    map((templateImages) => templateImages.map((templateImage) => templateImage.url ?? templateImage.uuid)),
  );

  public programTemplateImagesCount$ = this.programSettings$.pipe(
    map((programSettings) => programSettings?.banners?.images?.length ?? 0),
  );

  public programTemplateMaxSymbolCount$ = this.programSettings$.pipe(
    map((programSettings) => programSettings?.customerSymbols?.count ?? 2),
  );

  public programDisableActivityImage$ = this.programSettings$.pipe(
    map((programSettings) => !programSettings?.activityImages?.enabled),
  );
}
