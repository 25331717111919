import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import {
  BehaviorSubject,
  interval,
  Observable,
  Subject,
} from 'rxjs';
import {
  map, mergeMap, takeUntil,
} from 'rxjs/operators';
import { IField, IReloadEvent } from 'src/components/layout/sortable-list/sortable-list.component';
import { UserFetchEventsService } from 'src/api/customer/user-fetch-events/user-fetch-events.service';
import { UserFetchEventsQuery } from 'src/api/customer/user-fetch-events/user-fetch-events.query';
import { ActivatedRoute } from '@angular/router';
import { OrganizationUnitFetchEventsService } from 'src/api/customer/organization-unit-fetch-events/organization-unit-fetch-events.service';
import { OrganizationUnitFetchEventsQuery } from 'src/api/customer/organization-unit-fetch-events/organization-unit-fetch-events.query';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '../../../app/utils/translate.service';

const FIELDS = [
  {
    label: _('user_activity.fields.when'),
    field: 'created_at',
    width: '15%',
    hideLessThanMedium: true,
  },
  {
    label: _('user_activity.fields.for'),
    field: 'organization_unit_id',
    width: '20%',
    hideLessThanMedium: true,
  },
  {
    label: _('user_activity.fields.action'),
    field: 'event_type',
    width: '15%',
  },
  {
    label: _('user_activity.fields.what'),
    field: 'name',
    width: '50%',
  },
];

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./user-activity.component.scss'],
})
export class UserActivityComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject();

  private timerStop$: Subject<void> = new Subject();

  private scopedOnlyForOrganization$ = new BehaviorSubject(false);

  public loading$ = new BehaviorSubject(false);

  public SEARCH_LIMIT = 25;

  private lastReloadEvent: IReloadEvent;

  public lastUpdated = new Date();

  public lastUpdatedDisplay$ = new BehaviorSubject<Date>(new Date());

  public ouId: number;

  public userId: number;

  public list$ = this.scopedOnlyForOrganization$.pipe(
    mergeMap((onlyForOrganization) => {
      if (onlyForOrganization) {
        return this.organizationEventsQuery.selectAll();
      }
      return this.userFetchEventsQuery.selectAll();
    }),
  );

  public metaData$ = this.scopedOnlyForOrganization$.pipe(
    mergeMap((onlyForOrganization) => {
      if (onlyForOrganization) {
        return this.organizationEventsQuery.getMetadata();
      }
      return this.userFetchEventsQuery.getMetadata();
    }),
  );

  protected fields$: Observable<IField[]>;

  /**
   * The configuration object for the pagination of the list.
   */
  public paginationConfig$: Observable<PaginationInstance> = this.metaData$.pipe(
    map((metaData) => {
      const paginationConfig: PaginationInstance = {
        currentPage: (metaData.total > metaData.limit) && metaData.skip !== 0 ? (Math.ceil(metaData.skip / metaData.limit) + 1) : 1,
        itemsPerPage: metaData.limit,
        totalItems: metaData.total,
      };
      return paginationConfig;
    }),
  );

  constructor(
    private $userFetchEvents: UserFetchEventsService,
    private userFetchEventsQuery: UserFetchEventsQuery,
    private $organizationUnitEvents: OrganizationUnitFetchEventsService,
    private organizationEventsQuery: OrganizationUnitFetchEventsQuery,
    private route: ActivatedRoute,
    private $translateService: TranslateService,
  ) {
    this.fields$ = this.$translateService
      .onInitialTranslationAndLangOrTranslationChange$
      .pipe(map((langChange) => FIELDS.map((field) => ({ ...field, label: langChange.translations[field.label] }))));
  }

  async ngOnInit() {
    const { params } = this.route.snapshot;

    this.userId = +params.id;
    this.ouId = params.ou;
    this.lastReloadEvent = {
      currentPage: 1,
      limit: this.SEARCH_LIMIT,
      skip: 0,
      sortDirection: 'DESC',
      sortField: 'last_seen',
    };

    // we await these fetches so that the interval only gets started after the first fetch is done
    if (this.userId) {
      this.scopedOnlyForOrganization$.next(false);
      // await this.$userFetchEvents.fetch(this.userId, this.SEARCH_LIMIT);
    } else {
      this.scopedOnlyForOrganization$.next(true);
      // await this.$organizationUnitEvents.fetch(this.ouId, this.SEARCH_LIMIT);
    }

    interval(1000).pipe(
      takeUntil(this.stop$),
    ).subscribe(() => {
      this.setLastUpdatedDisplay();
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.timerStop$.next();
  }

  public setLastUpdatedDisplay() {
    this.lastUpdatedDisplay$.next(this.lastUpdated);
  }

  public async getList($event: IReloadEvent, showLoading: boolean = true) {
    this.timerStop$.next();
    if (showLoading) {
      this.loading$.next(true);
    }

    this.lastReloadEvent = {
      currentPage: $event.currentPage,
      limit: $event.limit,
      skip: $event.skip,
      sortDirection: $event.sortDirection,
      sortField: $event.sortField,
    };

    if (this.scopedOnlyForOrganization$.value) {
      await this.$organizationUnitEvents.fetch(
        this.ouId,
        $event.limit,
        $event.skip,
      );
    } else {
      await this.$userFetchEvents.fetch(
        this.userId,
        $event.limit,
        $event.skip,
      );
    }

    if (showLoading) {
      this.loading$.next(false);
    }
    this.initiateTimer();

    this.lastUpdated = new Date();
  }

  initiateTimer() {
    interval(5000).pipe(
      takeUntil(this.timerStop$),
    ).subscribe(() => {
      this.getList(this.lastReloadEvent, false);
    });
  }
}
