import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnitProgramRoles } from 'typings/api-activity';
import { ProgramOrganizationUnitStore, IProgramOrganizationUnitState } from './program-organization-unit.store';

@Injectable({ providedIn: 'root' })
export class ProgramOrganizationUnitQuery extends QueryEntity<IProgramOrganizationUnitState, IOrganizationUnitProgramRoles> {
  constructor(protected store: ProgramOrganizationUnitStore) {
    super(store);
  }
}
