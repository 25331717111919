<div mat-dialog-content fxLayout="column">
  <ng-container *ngIf="data.currentAttachment || tempMediaItem">
    <ng-container *ngIf="data.currentAttachment ? data.currentAttachment : tempMediaItem as mediaItem">
      <div class="attachment" fxLayout="row" fxLayoutAlign="space-between center">
        <div (click)="openAttachment(mediaItem)" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>attachment</mat-icon>&nbsp;&nbsp;
          <p class="attachment-title">{{ mediaItem.filename }}</p>
          <ng-container *ngIf="mediaItem.created_at">
            &nbsp;
            <p class="attachment-title-created-at">({{ mediaItem.created_at | i18n_date : 'dd/MM/yyyy' | async }})</p>
          </ng-container>
        </div>
        <div *ngIf="tempMediaItem">
          <button mat-icon-button type="button" (click)="removeTempMediaItem()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'program_template.attachment.name.label' | translate }}</mat-label>
    <input matInput [formControl]="attachmentDisplayName" [errorStateMatcher]="errorStateMatcher" />
    <mat-error *ngIf="attachmentDisplayName.hasError('required')">
      {{ 'program_template.attachment.name.required' | translate }}
    </mat-error>
  </mat-form-field>

  <ng-container *ngIf="!data.currentAttachment && !tempMediaItem">
    <app-asset-upload [identifier]="purpose" [purpose]="purpose" (uploaded)="addAttachment($event)">
      <ng-template let-uploadData="uploadData">
        <app-select-media style="display: inline" [uploadData]="uploadData" [identifier]="purpose" [purpose]="purpose"> </app-select-media>
      </ng-template>
    </app-asset-upload>
  </ng-container>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-button (click)="closeDialog()">{{ 'generic.cancel' | translate }}</button>
  <button mat-button color="primary" (click)="saveAttachment()" [disabled]="!attachmentDisplayName.valid" cdkFocusInitial>
    {{ 'program.template.attachment_dialog.update_name.tooltip' | translate }}
  </button>
</div>
