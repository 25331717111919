import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IOrganizationUnitPermission } from 'typings/doenkids/doenkids';
import { IOrganizationUnitPermissionOverview } from 'typings/api-customer';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitPermissionsService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  update(ouPermission: IOrganizationUnitPermission): Promise<IOrganizationUnitPermission> {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/customer/organization-unit/${ouPermission.organization_unit_id}/permission`, ouPermission,
    )) as Promise<IOrganizationUnitPermission>;
  }

  get(organizationUnitId: number): Promise<IOrganizationUnitPermission> {
    return firstValueFrom(this.$baseApi.genericGetCall(
      `/customer/organization-unit/${organizationUnitId}/permission`,
    )) as Promise<IOrganizationUnitPermission>;
  }

  getOverview(organizationUnitId: number): Promise<IOrganizationUnitPermissionOverview> {
    return firstValueFrom(this.$baseApi.genericGetCall(
      `/customer/organization-unit/${organizationUnitId}/permission-overview`,
    )) as Promise<IOrganizationUnitPermissionOverview>;
  }
}
