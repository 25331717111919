import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IJibbieLocation } from 'typings/doenkids/doenkids';
import { IJibbieLocationState, JibbieLocationStore } from './jibbie-location.store';

@Injectable({ providedIn: 'root' })
export class JibbieLocationQuery extends QueryEntity<IJibbieLocationState, IJibbieLocation> {
  constructor(protected store: JibbieLocationStore) {
    super(store);
  }
}
