/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  ISearchActivity, IPeriod, IPeriodSection, IActivity,
} from 'typings/doenkids/doenkids';
import {
  size, max,
} from 'lodash';
import { ProgramPeriodSectionService } from 'src/api/activity/program-period-section/program-section.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DoenkidsSessionProvider } from './session.provider';
import { EProgramPeriodSectionType } from '../components/shared/section/section.component';
import { I18nToastProvider } from './i18n-toast.provider';

@Injectable({
  providedIn: 'root',
})
export class ActivitySelectionProvider {
  public selectedActivities$: BehaviorSubject<(ISearchActivity | IActivity)[]> = new BehaviorSubject([]);

  public noOfSelectedActivities$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public isSelecting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private programPeriodSectionService: ProgramPeriodSectionService,
    private $i18nToastProvider: I18nToastProvider,
    private $session: DoenkidsSessionProvider,
  ) {
    this.isSelecting$.subscribe((isSelecting: boolean) => {
      // when we stop selecting activities we clear the array of selected activities
      //
      if (!isSelecting) {
        this.clearSelectedActivities();
      }
    });

    this.selectedActivities$.subscribe((selectedActivities) => {
      const noOfActivities = size(selectedActivities);
      this.noOfSelectedActivities$.next(noOfActivities);
    });
  }

  toggleActivitySelected(activity: ISearchActivity) {
    const currentActivity = this.selectedActivities$.value.find((selectedActivity) => selectedActivity.id === activity.id);
    if (currentActivity) {
      this.removeActivity(activity);
    } else {
      this.addActivity(activity);
    }
  }

  addActivity(activity: ISearchActivity) {
    const currentActivities = this.selectedActivities$.value || [];
    currentActivities.push(activity);
    this.selectedActivities$.next(currentActivities);
  }

  removeActivity(activityToBeRemoved: ISearchActivity) {
    const currentActivities = this.selectedActivities$.value || [];
    const filteredActivities = currentActivities.filter((activity) => activity.id !== activityToBeRemoved.id);
    this.selectedActivities$.next(filteredActivities);
  }

  clearSelectedActivities() {
    this.selectedActivities$.next([]);
  }

  toggleSelecting() {
    this.isSelecting$.next(!this.isSelecting$.value);
  }

  async addActivitiesToProgramPeriod(period: IPeriod, activities?: (ISearchActivity | IActivity)[]) {
    // Get all the selected activities, stored in the same service used by the activity search dialog.
    //
    const activitiesToAdd = activities ?? await firstValueFrom(this.selectedActivities$);
    const period_id = period.id;
    const newPeriodSections: IPeriodSection[] = [];
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    const periodSections = await this.programPeriodSectionService.fetchAllByPeriodId(period_id, 5000, 0);
    let highestPeriodSectionOrder = max(periodSections.items.map((periodSection) => periodSection.order)) ?? 0;
    const failedActivities: (ISearchActivity | IActivity)[] = [];
    const successfullActivities: (ISearchActivity | IActivity)[] = [];

    // Go through all selected activities, and attach them to the current period.
    //
    for (let i = 0; i < activitiesToAdd.length; i += 1) {
      const activity = activitiesToAdd[i];

      try {
        // Get the relevant properties for the IPeriodSection from the IActivity.
        //
        const {
          id: activity_id, media_uuid, name: title, summary: content, subtitle,
        } = activity;

        // Create a new period activity section with the activity data, initially leaving the timeslot blank.
        //

        highestPeriodSectionOrder += 1; // we always add one to the highest order so we are always appending

        // eslint-disable-next-line no-await-in-loop
        const response = await this.programPeriodSectionService.create({
          name: 'activity',
          type_id: EProgramPeriodSectionType.ACTIVITY,
          period_id,
          order: highestPeriodSectionOrder,
          data: {
            activity_id,
            media_uuid,
            content,
            title,
            subtitle,
          },
        }, organizationUnitId);

        successfullActivities.push(activity);
        newPeriodSections.push(response);
      } catch (e) {
        failedActivities.push(activity);
      }
    }

    successfullActivities.forEach((activity) => {
      this.toggleActivitySelected(activity);
    });

    this.handleAddedActivitiesErrors(failedActivities, period_id);

    return newPeriodSections;
  }

  private handleAddedActivitiesErrors(failedResults, periodId?: number) {
    // If there's failed results, show a warning toast and log a warning.
    //
    if (failedResults.length > 0) {
      // Prepare a descriptive log message.
      //
      let errorMessage = `Failed to add the following activities to period ${periodId}: `;

      // Add ids for each failed activity.
      //
      failedResults.forEach((activity, index) => {
        errorMessage += `${activity.id}`;
        // If this is the last activity to be added, add a dot or comma.
        //
        errorMessage += (index === (failedResults.length - 1)) ? '.' : ', ';
      });

      // Log the warning;
      //
      console.warn(errorMessage);

      this.$i18nToastProvider.error(_('activity_selection.add_to_program.failed'), { failedCount: failedResults.length });
    }
  }
}
