/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IFavoriteFolderDetails } from 'typings/doenkids/doenkids';

export interface IStoreFavoriteFolderDetails {
  organizationUnitId: number;
  folders: IFavoriteFolderDetails[];
  totalNoOfFolders: number;
}

export interface IFavoriteFolderState extends EntityState<IStoreFavoriteFolderDetails> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'favorite-folder', idKey: 'organizationUnitId' })
export class FavoriteFolderStore extends EntityStore<IFavoriteFolderState, IStoreFavoriteFolderDetails> {
  constructor() {
    super();
  }
}
