import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnloadProvider {
  public canUnload = true;

  _unloaded$ = new Subject<void>();

  public unloaded$ = this._unloaded$.asObservable();

  unload() {
    this._unloaded$.next();
  }
}
