import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IKonnectGroup } from 'typings/doenkids/doenkids';
import { KonnectGroupStore, IKonnectGroupState } from './konnect-group.store';

@Injectable({ providedIn: 'root' })
export class KonnectGroupQuery extends QueryEntity<IKonnectGroupState, IKonnectGroup> {
  constructor(protected store: KonnectGroupStore) {
    super(store);
  }
}
