import {
  Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy, Input,
} from '@angular/core';
import { IMediaItem } from 'typings/section-types';
import {
  ISearchActivity, IProgram, IPeriod, IFavoriteFolderDetails, IActivity,
} from 'typings/doenkids/doenkids';
import { bounceUp, slideUpDown } from 'src/animations';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import {
  takeUntil, filter,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivitySelectionProvider } from 'src/providers/activity-selection.provider';
import { ProgramService } from 'src/api/activity/program/program.service';
import { MatDialog } from '@angular/material/dialog';
import { ChoiceDialogComponent, IChoiceDialogData, IChoiceDialogResult } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ProgramPeriodService } from 'src/api/activity/program-period/program-period.service';
import { isNil, uniq } from 'lodash';
import {
  AddActivityToPinbordFolderDialogComponent,
} from 'src/components/dialogs/add-activity-to-pinbord-folder-dialog/add-activity-to-pinbord-folder-dialog.component';
import { ProgramCreationProvider } from 'src/providers/program-creation.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

@Component({
  selector: 'app-selected-activities',
  templateUrl: './selected-activities.component.html',
  styleUrls: ['./selected-activities.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [bounceUp, slideUpDown],
})
export class SelectedActivitiesComponent implements OnInit, OnDestroy {
  @Input()
  public pinbord: IFavoriteFolderDetails;

  private stop$ = new Subject<void>();

  public expanded = false;

  public addToExistingProgram: boolean;

  public programDetails: IProgram;

  public periodId: number;

  @ViewChild('activityLines') public activityLinesRef: ElementRef;

  /** The currently selected activities for the program.
   */
  public selectedActivities$: Observable<(ISearchActivity | IActivity)[]>;

  public noOfSelectedActivities$: Observable<number>;

  public isOrganizationOfTypeLocation$: Observable<boolean>;

  public isAddingActivitiesToProgram$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private dialog: MatDialog,
    private activitySelectionProvider: ActivitySelectionProvider,
    private $programCreation: ProgramCreationProvider,
    private programService: ProgramService,
    private periodService: ProgramPeriodService,
    private $session: DoenkidsSessionProvider,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private router: Router,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.selectedActivities$ = this.activitySelectionProvider.selectedActivities$.pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value)),
    );

    this.noOfSelectedActivities$ = this.activitySelectionProvider.noOfSelectedActivities$.asObservable().pipe(
      takeUntil(this.stop$),
    );
    this.isOrganizationOfTypeLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$;
  }

  async ngOnInit() {
    this.selectedActivities$.pipe(takeUntil(this.stop$)).subscribe(() => {
      // Wait until the newest activity was added.
      //
      setTimeout(() => {
        const { children } = this.activityLinesRef.nativeElement;
        if (this.expanded) {
          // Scroll to the newly added activity.
          //
          this.activityLinesRef.nativeElement.scrollTop = children[children.length - 1].offsetTop;
        }
      }, 0);
    });

    const { queryParams } = this.route.snapshot;
    this.addToExistingProgram = (queryParams.programId && queryParams.periodId);

    if (this.addToExistingProgram) {
      this.programDetails = await this.programService.fetch(queryParams.programId);
    }

    if (queryParams.periodId) {
      this.periodId = parseInt(queryParams.periodId, 10);
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  public getMediaItem(activity: ISearchActivity): IMediaItem {
    const mediaItem: IMediaItem = {
      caption: '',
      description: '',
      uuid: '',
    };
    // only set the uuid if we actually have an activity.
    // otherwise we just show a blank image
    //
    if (activity) {
      mediaItem.uuid = activity.media_uuid;
    }
    return mediaItem;
  }

  /** Toggles the expanded state for the container.
   */
  public toggleContainer() {
    this.expanded = !this.expanded;
  }

  /** The activity to remove.
   * @param activityId The activity to be removed from the program selection.
   */
  public removeActivity(activity: ISearchActivity) {
    this.activitySelectionProvider.toggleActivitySelected(activity);
  }

  async createProgram() {
    if (this.addToExistingProgram) {
      await this.addActivitiesToExistingProgram();
    } else {
      await this.createNewProgramWithActivties();
    }
  }

  async createNewProgramWithActivties() {
    const noOfSelectedActivities = await firstValueFrom(this.noOfSelectedActivities$);
    const data: IChoiceDialogData = {
      title: this.$translateService.instant(_('selected_activities.now_program.dialog.title')),
      description: this.$translateService.instant(
        'selected_activities.new_program.dialog.description',
        {
          activityCount: noOfSelectedActivities,
          pinBoardName: this.pinbord?.name || undefined,
        },
      ),
      actions: {
        primaryAction: {
          label: this.$translateService.instant(_('selected_activities.new_program.dialog.create.label')),
          value: 'create',
        },
      },
    };

    const dialogRef = this.matDialog.open(ChoiceDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data,
    });

    const dialogResult: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (dialogResult?.action === 'create') {
      this.$programCreation.pinbord = this.pinbord;
      this.router.navigate(['/calendar']);
    }
  }

  async addActivitiesToExistingProgram() {
    const noOfSelectedActivities = await firstValueFrom(this.noOfSelectedActivities$);
    const selectedActivitiesLanguages = uniq((await firstValueFrom(this.selectedActivities$))?.map((activity) => activity.language));
    const hasSelectedActivitiesOfDifferentLanguageThenProgram = selectedActivitiesLanguages.length > 1 || selectedActivitiesLanguages[0] !== this.programDetails.language;

    const descriptionTexts: string[] = [_('selected_activities.add_to_existing_program.dialog.description')];
    const descriptionParams: any = {
      activityCount: noOfSelectedActivities,
      programName: this.programDetails.name,
    };

    if (hasSelectedActivitiesOfDifferentLanguageThenProgram) {
      descriptionTexts.push(_('selected_activities.add_to_existing_program.dialog.mismatching_languages'));
    }

    const data: IChoiceDialogData = {
      title: this.$translateService.instant(_('selected_activities.add_to_existing_program.dialog.title')),
      description: this.$translateService.instant(
        descriptionTexts,
        descriptionParams,
      ),
      actions: {
        primaryAction: {
          label: this.$translateService.instant(_('selected_activities.add_to_existing_program.dialog.add.label')),
          value: 'add',
        },
      },
    };

    const dialogRef = this.matDialog.open(ChoiceDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data,
    });

    const dialogResult: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (dialogResult?.action === 'add') {
      await this._addActivitiesToProgram(this.programDetails.id, this.periodId);
      const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
      this.activitySelectionProvider.toggleSelecting();
      this.router.navigate([`organization/${organizationUnitId}/program/${this.programDetails.id}`], {
        queryParams: { periodId: this.periodId, periodSections: true },
      });
    }
  }

  private async _addActivitiesToProgram(programId: number, periodId?: number) {
    this.isAddingActivitiesToProgram$.next(true);
    // Create a period to house the selected activities.
    //
    let period: IPeriod;

    if (periodId) {
      period = await this.periodService.fetch(periodId);
    } else {
      period = await this.periodService.create({
        program_id: programId,
        name: '',
      });
    }

    if (!isNil(period)) {
      // Add the activities to the newly created period.
      //
      const addedActivitiesToProgram = await this.activitySelectionProvider.addActivitiesToProgramPeriod(period);
      this.isAddingActivitiesToProgram$.next(false);
      return addedActivitiesToProgram;
    }
    // Couldn't add period to program, therefore, activities cannot be added either.
    //
    this.$i18nToastProvider.error(_('selected_activities.add_to_program.failed'));
    this.isAddingActivitiesToProgram$.next(false);

    return Promise.resolve();
  }

  public async addToPinbordFolder() {
    const activities = this.activitySelectionProvider.selectedActivities$.value;

    const dialogRef = this.dialog.open(AddActivityToPinbordFolderDialogComponent, {
      data: {
        activities,
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'success') {
      this.activitySelectionProvider.clearSelectedActivities();
      this.activitySelectionProvider.toggleSelecting();
    }
  }
}
