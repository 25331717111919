export function getRootUrlAndQueryParamsFromUrl(url: string) {
  let rootUrl = '';
  const queryParams: any = {};
  if (url.indexOf('?') > -1) {
    const splitUrl = url.split('?');
    // eslint-disable-next-line prefer-destructuring
    rootUrl = splitUrl[0];

    const queryParamFullMatches: string[] = splitUrl[1].match(/([^&=?]+)=?([^&]*)/g);

    queryParamFullMatches.forEach((fullMatch: string) => {
      const splitFullMatch = fullMatch.split('=');
      queryParams[splitFullMatch[0].trim()] = splitFullMatch[1].trim();
    });
  } else {
    rootUrl = url;
  }

  return { rootUrl, queryParams };
}
