import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { isEmpty, isNaN as _isNaN, isNil, max, unset, isEqual, cloneDeep, isArray, isObject, omit } from 'lodash';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, map, takeUntil } from 'rxjs';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import {
  IAvailableFontOptions,
  IBorders,
  IFont,
  IMargins,
  IPublishActivityRequest,
  IPublishProgramRequest,
  IRenderActivitySettings,
  IRenderFontSettings,
  IRenderHeaderTextSettings,
  IRenderImage,
  IRenderProgramSettings,
  IRenderSettingOverrides,
  IRenderSettings,
} from 'typings/api-publish';
import { IActivityType } from 'typings/doenkids/doenkids';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IExtendedOrganizationUnitRenderSettings, OrganizationUnitRenderSettingsService } from 'src/api/customer/organization-unit-render-settings/organization-unit-render-settings.service';
import { TemplateService } from 'src/api/publish/template/template.service';
import { KeyValue } from '@angular/common';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { PublishActivityService } from 'src/api/publish/activity/activity.service';
import { PublishProgramService } from 'src/api/publish/program/program.service';
import { TranslateService } from 'src/app/utils/translate.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';

_('template_settings.general');
_('template_settings.bso');
_('template_settings.kdv');
_('template_settings.vgo');
_('template_settings.dvo');
_('template_settings.ibo');
_('template_settings.asc');
_('template_settings.hbc');
_('template_settings.ccc');
_('template_settings.activity.label');
_('template_settings.program.label');
_('template_settings.font.titleFont.label');
_('template_settings.font.footerFont.label');
_('template_settings.font.headerFont.label');
_('template_settings.font.strongFont.label');
_('template_settings.font.defaultFont.label');
_('template_settings.font.emphasisFont.label');
_('template_settings.font.subtitleFont.label');
_('template_settings.font.mainTitleFont.label');
_('template_settings.font.mainSubtitleFont.label');
_('template_settings.font.summaryFont.label');
_('template_settings.alignment.left.label');
_('template_settings.alignment.center.label');
_('template_settings.alignment.right.label');
_('template_settings.margins.bottom.label');
_('template_settings.margins.left.label');
_('template_settings.margins.top.label');
_('template_settings.margins.right.label');
_('template_settings.program_name.label');
_('template_settings.program_name.enabled');
_('template_settings.program_date.label');
_('template_settings.program_date.enabled');
_('template_settings.organization_unit.label');
_('template_settings.organization_unit.enabled');

type TFontTypes = keyof IRenderFontSettings;
type TTemplateFontTypes = Record<TFontTypes, undefined>;

interface ICustomColorFont { font: Partial<IFont>, completeWith: TFontTypes }
interface IColorFont {
  blue: ICustomColorFont;
}
const customDefaultFonts: IColorFont = {
  blue: { font: { color: '#0000FF' }, completeWith: 'defaultFont' },
};
type TColorFontType = keyof IColorFont;

const definedFontType: TTemplateFontTypes = {
  defaultFont: undefined,
  emphasisFont: undefined,
  strongFont: undefined,
  mainTitleFont: undefined,
  mainSubtitleFont: undefined,
  titleFont: undefined,
  subtitleFont: undefined,
  headerFont: undefined,
  footerFont: undefined,
  timeSlotFont: undefined,
  additionalContentFont: undefined,
  summaryFont: undefined,
  introductionDefaultFont: undefined,
  introductionEmphasisFont: undefined,
  introductionStrongFont: undefined,
  linkFont: undefined,
  captionFont: undefined,
};

const optionalDefaultFontTypes: Partial<Record<TFontTypes, TFontTypes | TColorFontType>> = {
  summaryFont: 'defaultFont',
  timeSlotFont: 'titleFont',
  additionalContentFont: 'defaultFont',
  introductionDefaultFont: 'defaultFont',
  introductionEmphasisFont:'emphasisFont',
  introductionStrongFont: 'strongFont',
  captionFont: 'defaultFont',
  linkFont: 'blue',
};

const templateFontTypes = Object.keys(definedFontType) as TFontTypes[];

interface INode {
  name: string;
  children?: INode[];
}

const DEFAULT_ACTIVITY_ID = 13778;
const DEFAULT_PROGRAM_ID = 108560;

const PROGRAM_TEMPLATE_TREE_DATA: INode[] = [
  { name: 'header', children: [{ name: 'header' }] },
  { name: 'footer', children: [{ name: 'footer' }] },
  { name: 'font', children: [{ name: 'font' }] },
  { name: 'mediaSection', children: [{ name: 'mediaSection' }] },
  { name: 'pageMargins', children: [{ name: 'pageMargins' }] },
  { name: 'banners', children: [{ name: 'banners' }] },
  { name: 'activityImages', children: [{ name: 'activityImages' }] },
  { name: 'customerSymbols', children: [{ name: 'customerSymbols' }] },
  { name: 'periodTitleUnderline', children: [{ name: 'periodTitleUnderline' }] },
];

const ACTIVITY_TEMPLATE_TREE_DATA: INode[] = [
  { name: 'header', children: [{ name: 'header' }] },
  { name: 'footer', children: [{ name: 'footer' }] },
  { name: 'font', children: [{ name: 'font' }] },
  { name: 'mediaSection', children: [{ name: 'mediaSection' }] },
  { name: 'pageMargins', children: [{ name: 'pageMargins' }] },
];

@Component({
  selector: 'app-pdf-writer-template-settings',
  templateUrl: './pdf-writer-template-settings.component.html',
  styleUrls: ['./pdf-writer-template-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PdfWriterTemplateSettingsComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  @Input() organizationUnitId: number;

  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;

  public pdf$: BehaviorSubject<string | Blob | null> = new BehaviorSubject<string>(null);

  public pdfLoading$ = new BehaviorSubject<boolean>(false);

  /** The last time a PDF Render was requested */
  private lastPdfRender: number;

  public fontList: IAvailableFontOptions;

  public currentActivePanelId$ = new BehaviorSubject<string>(null);

  public currentActiveTabIndex = 0;

  public activityTypes$: Observable<IActivityType[]>;

  public templateControls: FormGroup;

  public currentOuName$: Observable<string>;

  public currentOuId$: Observable<number>;

  public programTemplateTreeControl = new NestedTreeControl<INode>(node => node.children);

  public programTemplateDataSource = new MatTreeNestedDataSource<INode>();

  public activityTemplateTreeControl = new NestedTreeControl<INode>(node => node.children);

  public activityTemplateDataSource = new MatTreeNestedDataSource<INode>();

  public currentSettings$ = new BehaviorSubject<IExtendedOrganizationUnitRenderSettings>(null);

  public debugControl = new FormControl(false);

  public activityIdControl = new FormControl(DEFAULT_ACTIVITY_ID, [Validators.required]);

  public programIdControl = new FormControl(DEFAULT_PROGRAM_ID, [Validators.required]);

  public concatednatedActivityTypes$: Observable<string>;

  public hasNoSettings$ = new BehaviorSubject<boolean>(false);

  public isAdmin$: Observable<boolean>;

  public isCurrentOrganizationUnitIsOfTypeCustomer$: Observable<boolean>;

  constructor(
    private $session: DoenkidsSessionProvider,
    private $i18nToastProvider: I18nToastProvider,
    private $renderSettings: OrganizationUnitRenderSettingsService,
    private $template: TemplateService,
    private fb: FormBuilder,
    private $publishActivity: PublishActivityService,
    private $publishProgram: PublishProgramService,
    private $translate: TranslateService,
    private dialog: MatDialog,
  ) {
    this.activityTypes$ = this.$session.availableActivityTypes$.pipe(takeUntil(this.stop$));

    this.currentOuName$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$), map((ouDetails) => ouDetails.name));

    this.currentOuId$ = this.$session.currentOuId$.pipe(takeUntil(this.stop$));

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));

    this.isCurrentOrganizationUnitIsOfTypeCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));

    this.templateControls = new FormGroup({});

    this.concatednatedActivityTypes$ = combineLatest([this.activityTypes$, this.$translate.onInitialLangAndLangChange$])
      .pipe(
        takeUntil(this.stop$),
        map(([activityTypes]) => {
          const names = activityTypes.map((activityType) => activityType.name);
          const lastName = names.splice(-1, 1);
          return `${names.join(', ')} ${this.$translate.instant(_('generic.and'))} ${lastName}`;
        }));

    this.programTemplateDataSource.data = PROGRAM_TEMPLATE_TREE_DATA;
    this.activityTemplateDataSource.data = ACTIVITY_TEMPLATE_TREE_DATA;
  }

  ngOnInit() {
    this.pdf$
      .pipe(
        takeUntil(this.stop$),
      )
      .subscribe((pdf) => {
        if (this.pdfViewer) {
          if (typeof pdf === 'string') {
            this.pdfViewer.pdfSrc = encodeURIComponent(pdf);
          } else if (pdf) {
            this.pdfViewer.pdfSrc = pdf;
          } else {
            this.pdfViewer.pdfSrc = null;
          }
          this.pdfViewer.refresh();
        }
      });

    this.currentActivePanelId$.pipe(takeUntil(this.stop$)).subscribe((panel) => {
      if (panel) {
        this.renderPdf();
      } else if (this.pdf$.value) {
        this.pdf$.next(null);
      }
    });

    this.debugControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe(() => {
      const activePanel = this.currentActivePanelId$.value;
      if (activePanel) {
        this.renderPdf();
      }
    });

    this.getFontList();

    this.getCurrentSettings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizationUnitId && !changes.organizationUnitId.firstChange) {
      this.getCurrentSettings();
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async getCurrentSettings(currentSettings?: IExtendedOrganizationUnitRenderSettings) {
    let settings: IExtendedOrganizationUnitRenderSettings;

    try {
      settings = currentSettings ?? await this.$renderSettings.fetch(this.organizationUnitId);
      this.hasNoSettings$.next(false);
      this.currentSettings$.next(settings);
    } catch (e) {
      this.currentSettings$.next(null);
      this.hasNoSettings$.next(true);
      this.templateControls = null;
      return;
    }

    let order = 0;
    const generalForm = this.createGeneralForm(settings ?? ({} as IExtendedOrganizationUnitRenderSettings), settings.draft_settings);

    generalForm.addControl('order', new FormControl(order));
    order += 1;

    this.templateControls = new FormGroup({
      'general': generalForm,
    });

    const activityTypes = await firstValueFrom(this.activityTypes$);

    for (const activityType of activityTypes) {
      const activityTypeForm = this.createActivityTypeForm((settings.overrides ?? {})[activityType.name] ?? {}, (settings.draft_overrides ?? {})[activityType.name]);
      activityTypeForm.addControl('order', new FormControl(order));
      order += 1;
      this.templateControls.addControl(activityType.name.toUpperCase(), activityTypeForm);
    }

    if (this.currentActivePanelId$.value) {
      this.renderPdf();
    } else {
      // the currently selected panel does not exist anymore so we just clear it and let the user select again what they want to see
      //
      this.currentActivePanelId$.next(null);
    }
  }

  createGeneralForm(ouSettings: IExtendedOrganizationUnitRenderSettings, overrides?: Partial<IRenderSettings>) {
    const activityForm = this.createActivitySettingsForm(ouSettings.settings ?? {}, { useDefaults: true, overrides });
    const programForm = this.createProgramSettingsForm(ouSettings.settings ?? {}, { useDefaults: true, overrides });
    const templateOptionGroup = new FormGroup<any>({
      activity: activityForm,
      program: programForm,
    });

    return templateOptionGroup;
  }

  createActivityTypeForm(activityTypeSettings: Partial<IRenderSettings>, overrides?: Partial<IRenderSettings>) {
    const activityForm = this.createActivitySettingsForm(activityTypeSettings, { useDefaults: false, overrides });
    const programForm = this.createProgramSettingsForm(activityTypeSettings, { useDefaults: false, overrides });
    const templateOptionGroup = new FormGroup<any>({
      activity: activityForm,
      program: programForm,
    });

    return templateOptionGroup;
  }

  createActivitySettingsForm(
    renderSettings: Partial<IRenderSettings>,
    extras: {
      useDefaults?: boolean,
      overrides?: Partial<IRenderSettings>
    } = { useDefaults: true },
  ) {
    const templateOptionGroup = this.fb.group({});

    const settings = this.mergeDeep<IRenderActivitySettings>({}, ((renderSettings ?? {}).activity ?? {}), ((extras.overrides ?? {}).activity ?? {}));
    const useDefaults = extras.useDefaults;
    const defaultMainFonts = renderSettings?.fonts;

    templateOptionGroup.addControl('header', new FormGroup({
      image: new FormControl(settings.header?.image?.uuid ?? settings.header?.image?.url),
      imageFill: new FormControl(settings?.header?.imageFill ?? (useDefaults ? 'cover' : undefined)),
      height: new FormControl(settings.header?.height ?? (useDefaults ? 30 : undefined)),
    }));

    templateOptionGroup.addControl('footer', new FormGroup({
      image: new FormControl(settings.footer?.image?.uuid ?? settings.footer?.image?.url),
      imageFill: new FormControl(settings?.footer?.imageFill ?? (useDefaults ? 'cover' : undefined)),
      height: new FormControl(settings.footer?.height ?? (useDefaults ? 27 : undefined)),
    }));

    templateOptionGroup.addControl('mediaSection', new FormGroup({
      margins: this.createMarginGroups(settings.mediaSection?.margins, (useDefaults ? { left: 1, right: 1, top: 1, bottom: 1 } : undefined)),
      size: new FormControl(settings.mediaSection?.size ?? (useDefaults ? 50 : undefined)),
      imageFill: new FormControl(settings.mediaSection?.imageFill),
    }));

    templateOptionGroup.addControl('pageMargins', this.createMarginGroups(settings.pageMargins, (useDefaults ? { left: 5, right: 5, top: 0, bottom: 0 } : undefined)));

    const fontGroups = this.createFontGroups(renderSettings, 'activity', { addDefaults: extras.useDefaults, fontOverrides: extras.overrides?.activity?.fonts, defaultMainFonts });

    const mappedFontGroups = new FormGroup({});
    for (const fontGroup of fontGroups) {
      mappedFontGroups.addControl(fontGroup.key, fontGroup.group);
    }

    templateOptionGroup.addControl('fonts', mappedFontGroups);

    return templateOptionGroup;
  }

  createProgramSettingsForm(
    renderSettings: Partial<IRenderSettings>,
    extras: {
      useDefaults?: boolean,
      overrides?: Partial<IRenderSettings>
    } = { useDefaults: true },
  ) {
    const templateOptionGroup = new FormGroup({});

    const settings = this.mergeDeep<IRenderProgramSettings>({}, ((renderSettings ?? {}).program ?? {}), ((extras.overrides ?? {}).program ?? {}));
    const useDefaults = extras.useDefaults ?? true;

    const defaultFont = renderSettings?.fonts?.defaultFont;

    const headerOrganizationUnitGroup = this.createProgramHeaderSettingsGroup(settings.header?.organizationUnit, defaultFont, useDefaults);
    headerOrganizationUnitGroup.addControl('showCustomerName', new FormControl(settings.header?.organizationUnit?.showCustomerName ?? (useDefaults ? false : undefined)));

    templateOptionGroup.addControl('header', new FormGroup({
      image: new FormControl(settings.header?.image?.uuid ?? settings.header?.image?.url),
      imageFill: new FormControl(settings.header?.imageFill ?? (useDefaults ? 'cover' : undefined)),
      height: new FormControl(settings.header?.height ?? (useDefaults ? 30 : undefined)),
      organizationUnit: headerOrganizationUnitGroup,
      programName: this.createProgramHeaderSettingsGroup(settings.header?.programName, defaultFont, useDefaults),
      programDate: this.createProgramHeaderSettingsGroup(settings.header?.programDate, defaultFont, useDefaults),
    }));

    templateOptionGroup.addControl('footer', new FormGroup({
      image: new FormControl(settings.footer?.image?.uuid ?? settings.footer?.image?.url),
      imageFill: new FormControl(settings.footer?.imageFill ?? (useDefaults ? 'cover' : undefined)),
      height: new FormControl(settings.footer?.height ?? (useDefaults ? 27 : undefined)),
    }));

    templateOptionGroup.addControl('activityImages', new FormGroup({
      enabled: new FormControl(settings.activityImages?.enabled ?? (useDefaults ? false : undefined)),
      borders: this.createBorderGroups(settings.activityImages?.borders),
      imageClip: new FormControl(settings.activityImages?.imageClip ?? (useDefaults ? 'none' : undefined)),
      slot: new FormControl(settings.activityImages?.slot ?? (useDefaults ? 'left' : undefined)),
    }));

    templateOptionGroup.addControl('customerSymbols', new FormGroup({
      enabled: new FormControl(settings.customerSymbols?.enabled ?? (useDefaults ? false : undefined)),
      slot: new FormControl(settings.customerSymbols?.slot ?? (useDefaults ? 'left' : undefined)),
      count: new FormControl(settings.customerSymbols?.count ?? (useDefaults ? 2 : undefined)),
      size: new FormControl(settings.customerSymbols?.size ?? (useDefaults ? 10 : undefined)),
    }));

    templateOptionGroup.addControl('mediaSection', new FormGroup({
      margins: this.createMarginGroups(settings.mediaSection?.margins, (useDefaults ? { top: 1, left: 1, bottom: 1, right: 1 } : undefined)),
      size: new FormControl(settings.mediaSection?.size ?? (useDefaults ? 50 : undefined)),
      imageFill: new FormControl(settings.mediaSection?.imageFill),
    }));

    templateOptionGroup.addControl('pageMargins', this.createMarginGroups(settings.pageMargins, (useDefaults ? { left: 5, right: 5, top: 0, bottom: 0 } : undefined)));

    const fontGroups = this.createFontGroups(renderSettings, 'program', { addDefaults: useDefaults, fontOverrides: extras.overrides?.program?.fonts, defaultMainFonts: renderSettings.fonts });
    const mappedFontGroups = new FormGroup({});
    for (const fontGroup of fontGroups) {
      mappedFontGroups.addControl(fontGroup.key, fontGroup.group);
    }

    templateOptionGroup.addControl('fonts', mappedFontGroups);

    // banners
    const bannerGroup = new FormGroup({
      borders: this.createBorderGroups(settings.banners?.borders),
      imageClip: new FormControl(settings.banners?.imageClip ?? (useDefaults ? 'none' : undefined)),
      imageMargins: this.createMarginGroups(settings.banners?.imageMargins, (useDefaults ? { top: 0, left: 0, right: 0, bottom: 4 } : undefined)),
      images: new FormGroup({}),
      slot: new FormControl(settings.banners?.slot ?? (useDefaults ? 'left' : undefined)),
      size: new FormControl(settings.banners?.size ?? (useDefaults ? 45 : undefined)),
      imagesPerPage: new FormControl(settings.banners?.imagesPerPage ?? (useDefaults ? 5 : undefined)),
      justify: new FormControl(settings.banners?.justify ?? (useDefaults ? false : undefined)),
      noMargins: new FormControl(settings.banners?.noMargins ?? (useDefaults ? false : undefined)),
      imageRepeat: new FormControl(settings.banners?.imageRepeat ?? (useDefaults ? false : undefined)),
      fixedImages: new FormControl(settings.banners?.fixedImages ?? (useDefaults ? 'none' : undefined)),
    });

    let imageIndex = 0;
    for (const bannerImage of settings.banners?.images ?? []) {
      if (bannerImage.uuid || bannerImage.url) {
        (bannerGroup.get('images') as FormGroup).addControl(`${imageIndex}`, new FormControl(bannerImage.uuid ?? bannerImage.url));
        imageIndex += 1;
      }
    }

    templateOptionGroup.addControl('banners', bannerGroup);

    const periodTitleUnderlineGroup = new FormGroup({
      color: new FormControl(settings.periodTitleUnderline?.color ?? (useDefaults ? '' : undefined)),
      fullWidth: new FormControl(settings.periodTitleUnderline?.fullWidth ?? (useDefaults ? false : undefined)),
    });

    templateOptionGroup.addControl('periodTitleUnderline', periodTitleUnderlineGroup);

    return templateOptionGroup;
  }

  createFontGroups(
    renderSettings: Partial<IRenderSettings>,
    settingsType: 'activity' | 'program',
    extras: { addDefaults: boolean, fontOverrides?: Partial<IRenderFontSettings>, defaultMainFonts?: IRenderFontSettings } = { addDefaults: true }) {
    const fontGroups: { key: string, group: FormGroup }[] = [];
    const settings = renderSettings[settingsType] ?? {};

    for (const fontKey of templateFontTypes) {
      let defaultSettings = ((renderSettings.fonts ?? {})[fontKey] ?? {}) as IFont;

      const defaultMainFontKey = optionalDefaultFontTypes[fontKey];
      let defaultMainFont: IFont;
      if (Object.keys(customDefaultFonts).includes(defaultMainFontKey)) {
        const customDefaultFont = customDefaultFonts[defaultMainFontKey] as ICustomColorFont;
        const defaultFont = (extras.defaultMainFonts?.[(customDefaultFont.completeWith ?? 'defaultFont')] ?? {}) as IFont;
        defaultMainFont = {
          ...defaultFont,
          ...customDefaultFont.font,
        };
      } else if (defaultMainFontKey) {
        defaultMainFont = (extras.defaultMainFonts?.[defaultMainFontKey] ?? {}) as IFont;
      }
      if (Object.keys(optionalDefaultFontTypes).includes(fontKey) && isEmpty(defaultSettings) && !isEmpty(defaultMainFont)) {
        defaultSettings = defaultMainFont;
      }
      const fontSettings: IFont = ((settings.fonts ?? {})[fontKey] ?? {}) as IFont;
      const newFontGroup = this.createSingularFontGroup(fontSettings, extras.addDefaults ? defaultSettings : undefined);
      fontGroups.push({ key: fontKey, group: newFontGroup });
    }

    const renderSettingsFonts = Object.keys((renderSettings.fonts ?? {})) as TFontTypes[];
    for (const fontKey of renderSettingsFonts) {
      if (!templateFontTypes.includes(fontKey)) {
        const defaultSettings: IFont = (renderSettings.fonts[fontKey] ?? {}) as IFont;
        const fontSettings: IFont = ((settings.fonts ?? {})[fontKey] ?? {}) as IFont;

        let newFontGroup = this.createSingularFontGroup(fontSettings, extras.addDefaults ? defaultSettings : undefined);

        fontGroups.push({ key: fontKey, group: newFontGroup });
      }
    }

    if (extras.fontOverrides) {
      for (const fontKey of Object.keys(extras.fontOverrides)) {
        const currentGroup = fontGroups.find((fontGroup) => fontGroup.key === fontKey);
        if (currentGroup) {
          currentGroup.group.patchValue(extras.fontOverrides[fontKey]);
        } else {
          fontGroups.push({ key: fontKey, group: this.createSingularFontGroup(extras.fontOverrides[fontKey]) });
        }
      }
    }

    return fontGroups;
  }

  createSingularFontGroup(fontObj: IFont, defaultValue?: IFont) {
    const newFontGroup = new FormGroup<any>({
      color: new FormControl(defaultValue?.color ?? ''),
      family: new FormControl(defaultValue?.family ?? ''),
      size: new FormControl(defaultValue?.size ?? ''),
      style: new FormControl(defaultValue?.style ?? ''),
      weight: new FormControl(defaultValue?.weight ?? ''),
    });

    for (const fontKey of Object.keys((fontObj ?? {}))) {
      const control = newFontGroup.get(fontKey);
      const value = fontObj[fontKey];
      if (control && value) {
        control.setValue(value);
      } else if (value) {
        newFontGroup.addControl(fontKey, new FormControl(value));
      }
    }

    return newFontGroup;
  }

  createBorderGroups(borders?: IBorders) {
    return new FormGroup({
      bottom: new FormGroup({
        color: new FormControl(borders?.bottom?.color ?? ''),
        size: new FormControl(borders?.bottom?.size ?? ''),
      }),
      left: new FormGroup({
        color: new FormControl(borders?.left?.color ?? ''),
        size: new FormControl(borders?.left?.size ?? ''),
      }),
      right: new FormGroup({
        color: new FormControl(borders?.right?.color ?? ''),
        size: new FormControl(borders?.right?.size ?? ''),
      }),
      top: new FormGroup({
        color: new FormControl(borders?.top?.color ?? ''),
        size: new FormControl(borders?.top?.size ?? ''),
      }),
    });
  }

  createMarginGroups(margins?: IMargins, defaultMargins?: IMargins) {
    return new FormGroup({
      bottom: new FormControl(margins?.bottom ?? defaultMargins?.bottom ?? ''),
      left: new FormControl(margins?.left ?? defaultMargins?.left ?? ''),
      right: new FormControl(margins?.right ?? defaultMargins?.right ?? ''),
      top: new FormControl(margins?.top ?? defaultMargins?.top ?? ''),
    });
  }

  createProgramHeaderSettingsGroup(headerTextSettings: IRenderHeaderTextSettings, defaultFont?: IFont, useDefaults: boolean = true) {
    return new FormGroup<any>({
      enabled: new FormControl(headerTextSettings?.enabled ?? (useDefaults ? false : undefined)),
      align: new FormControl(headerTextSettings?.align ?? ''),
      font: this.createSingularFontGroup(headerTextSettings?.font, (useDefaults ? defaultFont : undefined)),
      margins: this.createMarginGroups(headerTextSettings?.margins, (useDefaults ? { top: 0, left: 0, right: 0, bottom: 0 } : undefined)),
      fixedPosition: new FormControl(headerTextSettings?.fixedPosition ?? (useDefaults ? false : undefined)),
      position: new FormControl(headerTextSettings?.position),
      text: new FormControl(headerTextSettings?.text),
      backgroundShape: new FormGroup({
        fillColor: new FormControl(headerTextSettings?.backgroundShape?.fillColor),
        padding: this.createMarginGroups(headerTextSettings?.backgroundShape?.padding, (useDefaults ? { top: 1, bottom: 1, left: 1, right: 1 } : undefined)),
        shape: new FormControl(headerTextSettings?.backgroundShape?.shape ?? (useDefaults ? 'none' : undefined)),
      }),
    });
  }

  async getFontList() {
    this.fontList = await this.$template.fetchAvailableFontList();
  }

  /** Renders the pdf using the publish API.
   */
  async renderPdf() {
    const activePanel = this.currentActivePanelId$.value;
    const activeTabIndex = this.currentActiveTabIndex;
    if (!activePanel) {
      this.pdf$.next(null);
      return;
    }

    this.pdfLoading$.next(true);

    this.activityIdControl.disable({ emitEvent: false });
    this.programIdControl.disable({ emitEvent: false });

    const thisRenderStartedAt = new Date().getTime();
    this.lastPdfRender = thisRenderStartedAt;

    let draftSettings: IRenderSettings;
    let currentSettings: IRenderSettings;

    if (activePanel !== 'general') {
      draftSettings = this.parseRenderSettingsOverrides(activePanel, true);
      currentSettings = this.mergeDeep<IRenderSettings>({}, this.currentSettings$.value.settings, (this.currentSettings$.value.overrides ?? {})[activePanel] ?? {});
    } else {
      draftSettings = this.parseRenderSettings();
      currentSettings = this.currentSettings$.value.settings;
    }

    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = null;
    }

    if (activeTabIndex === 0 && !this.activityIdControl.value) {
      this.$i18nToastProvider.error(_('template_settings.no_activity_selected'));
      return;
    }

    if (activeTabIndex === 1 && !this.programIdControl.value) {
      this.$i18nToastProvider.error(_('template_settings.no_program_selected'));
      return;
    }

    let ouId: number;

    try {
      ouId = await firstValueFrom(this.currentOuId$);
    } catch (e) {
      // this happens when we already destroyed the component but are still trying to render.
      // if the component is already destroyed we don't wanna render the pdf anymore so we just return
      //
      return;
    }

    let pdf: string | Blob;
    const renderSettings = this.mergeDeep<IRenderSettings>({}, currentSettings, draftSettings);
    try {
      if (activeTabIndex === 0) {
        const activityPdfRequest: IPublishActivityRequest = {
          activity_id: this.activityIdControl.value,
          organization_unit_id: ouId,
          render_settings: renderSettings,
        };

        pdf = await this.$publishActivity.fetchActivityPdf(activityPdfRequest, undefined, {
          forceUpdate: true,
          debug: this.debugControl.value,
        });
      } else if (activeTabIndex === 1) {
        const programPdfRequest: IPublishProgramRequest = {
          program_id: this.programIdControl.value,
          organization_unit_id: ouId,
          render_settings: renderSettings,
        };

        pdf = await this.$publishProgram.fetchProgramPdf(programPdfRequest, {
          forceUpdate: true,
          debug: this.debugControl.value,
        });
      }
    } catch (e) {
      if (thisRenderStartedAt === this.lastPdfRender) {
        this.$i18nToastProvider.error(_('template_settings.pdf.download.failed'));
        this.pdfLoading$.next(false);
        this.activityIdControl.enable({ emitEvent: false });
        this.programIdControl.enable({ emitEvent: false });
      }
    }

    // Only render the last time the PDF was requested to render to prevent 'flickering'
    //
    if (pdf && thisRenderStartedAt === this.lastPdfRender) {
      this.pdf$.next(pdf);
    }
  }

  onPdfLoadedEvent() {
    this.pdfLoading$.next(false);
    this.pdfViewer?.PDFViewerApplication?.preferences?.set('externalLinkTarget', 2);
    this.activityIdControl.enable({ emitEvent: false });
    this.programIdControl.enable({ emitEvent: false });
    this.activityIdControl.markAsPristine();
    this.programIdControl.markAsPristine();
  }

  handleExpand(id: string) {
    if (this.currentActivePanelId$.value !== id) {
      this.currentActivePanelId$.next(id);
    }
  }

  handleCollapse(id: string) {
    if (this.currentActivePanelId$.value === id) {
      this.currentActivePanelId$.next(null);
    }
  }

  handleTabChange() {
    this.renderPdf();
  }

  selectImage($event: { uuid: string }, formControl: AbstractControl) {
    if ($event && $event.uuid) {
      formControl.setValue($event.uuid);
    } else {
      formControl.setValue(null);
    }

    formControl.markAsDirty();
  }

  /**
   * Cleanup and concatenate the template before submitting
   */
  cleanupSettings(settings: IRenderActivitySettings | IRenderProgramSettings | IRenderFontSettings, currentSettings?: IRenderActivitySettings | IRenderProgramSettings | IRenderFontSettings) {
    for (const settingsKey of Object.keys(settings)) {
      const property = settings[settingsKey];
      const currentSetting = (currentSettings ?? {})[settingsKey];
      if (!isNil(property) && typeof property === 'object') {
        settings[settingsKey] = this.cleanupSettings(property, isArray(property) ? undefined : currentSetting);
        if (!isArray(property) && isEmpty(settings[settingsKey])) {
          unset(settings, settingsKey);
        } else if (isArray(property)) {
          settings[settingsKey] = settings[settingsKey].filter((entry) => !isNil(entry));
          if (isEqual(settings[settingsKey], currentSetting)) {
            unset(settings, settingsKey);
          }
        }
      } else {
        const propertyType = typeof property;
        let isEmptyProperty = false;
        let currentSettingIsEmpty = true;

        switch (propertyType) {
          case 'number':
            isEmptyProperty = _isNaN(property);
            currentSettingIsEmpty = _isNaN(currentSetting);
            break;
          case 'boolean':
            isEmptyProperty = isNil(property);
            currentSettingIsEmpty = isNil(currentSetting);
            break;
          default:
            isEmptyProperty = isEmpty(property);
            currentSettingIsEmpty = isEmpty(currentSetting);
            break;
        }

        if (isEmptyProperty || (!currentSettingIsEmpty && isEqual(currentSetting, property))) {
          unset(settings, settingsKey);
        }
      }
    }

    return settings;
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  mergeDeep<T>(target, ...sources): T {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (isArray(source[key])) {
            Object.assign(target, { [key]: source[key] });
            continue;
          }
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  mergeFonts(activityFonts: IRenderFontSettings, programFonts: IRenderFontSettings, defaultFonts: IRenderFontSettings) {
    const fonts = {} as IRenderFontSettings;

    for (const fontKey of Object.keys(activityFonts)) {
      if (Object.keys(optionalDefaultFontTypes).includes(fontKey as TFontTypes)) {
        const activityFont = activityFonts[fontKey];
        const programFont = programFonts[fontKey];
        const defaultFont = defaultFonts[optionalDefaultFontTypes[fontKey]];

        if (!isNil(defaultFont) && isNil(defaultFont.weight)) {
          defaultFont.weight = '';
        }

        if (!isEmpty(defaultFont) && isEqual(activityFont, defaultFont)) {
          unset(activityFonts, fontKey);
        }

        if (!isEmpty(defaultFont) && isEqual(programFont, defaultFont)) {
          unset(programFonts, fontKey);
        }
      }

      const activityFont = activityFonts[fontKey];

      if (isEqual(activityFont, programFonts[fontKey])) {
        fonts[fontKey] = cloneDeep(activityFont);
        unset(activityFonts, fontKey);
        unset(programFonts, fontKey);
      }
    }

    this.cleanupSettings(fonts);
    this.cleanupSettings(activityFonts);
    this.cleanupSettings(programFonts);

    return fonts;
  }

  parseRenderSettings() {
    const currentSettings = this.currentSettings$.value?.settings;
    const defaultFonts = this.currentSettings$.value?.settings?.fonts;
    const generalValues = this.templateControls.get('general');

    const activityValues: IRenderActivitySettings = generalValues.get('activity').getRawValue();
    const programValues: IRenderProgramSettings = generalValues.get('program').getRawValue();

    const genericFonts = this.mergeFonts(activityValues.fonts, programValues.fonts, defaultFonts);

    const activitySettings = this.createActivitySettings(activityValues, currentSettings?.activity);
    const programSettings = this.createProgramSettings(programValues, currentSettings?.program, currentSettings.fonts);

    return {
      fonts: genericFonts,
      activity: activitySettings,
      program: programSettings,
    } as IRenderSettings;
  }

  parseRenderSettingsOverrides(activityType: string, mergeGenericSettings: boolean = false) {
    const overrideFormGroup = this.templateControls.get(activityType);
    const currentSettings = (this.currentSettings$.value?.overrides ?? {})[activityType];
    const defaultFonts = this.currentSettings$.value?.settings?.fonts;
    const activityValues: IRenderActivitySettings = overrideFormGroup.get('activity').getRawValue();
    const programValues: IRenderProgramSettings = overrideFormGroup.get('program').getRawValue();

    const overrideFonts = this.mergeFonts(activityValues.fonts, programValues.fonts, defaultFonts);
    const overrideActivitySettings = this.createActivitySettings(activityValues, currentSettings?.activity);
    const overrideProgramSettings = this.createProgramSettings(programValues, currentSettings?.program);

    if (!mergeGenericSettings) {
      return {
        fonts: overrideFonts,
        activity: overrideActivitySettings,
        program: overrideProgramSettings,
      } as IRenderSettings;
    }

    const genericSettings = this.parseRenderSettings();

    return this.cleanupSettings({
      fonts: {
        ...genericSettings.fonts,
        ...overrideFonts,
      },
      activity: {
        ...genericSettings.activity,
        ...overrideActivitySettings,
      },
      program: {
        ...genericSettings.program,
        ...overrideProgramSettings,
      },
    } as IRenderSettings) as IRenderSettings;
  }

  createActivitySettings(activitySettings: IRenderActivitySettings, currentSettings?: IRenderActivitySettings) {
    const activityHeaderImage = activitySettings.header?.image;
    const activityFooterImage = activitySettings.footer?.image;

    if (typeof activityHeaderImage === 'string') {
      const castedImage = activityHeaderImage as string;
      if (!activitySettings.header) {
        activitySettings.header = {};
      }
      if (castedImage.startsWith('http')) {
        activitySettings.header.image = { url: activityHeaderImage };
      } else {
        activitySettings.header.image = { uuid: activityHeaderImage };
      }
    }

    if (typeof activityFooterImage === 'string') {
      const castedImage = activityFooterImage as string;
      if (!activitySettings.footer) {
        activitySettings.footer = {};
      }
      if (castedImage.startsWith('http')) {
        activitySettings.footer.image = { url: activityFooterImage };
      } else {
        activitySettings.footer.image = { uuid: activityFooterImage };
      }
    }

    this.cleanupSettings(activitySettings, currentSettings);

    return activitySettings;
  }

  createProgramSettings(programSettings: IRenderProgramSettings, currentSettings?: IRenderProgramSettings, defaultFonts?: IRenderFontSettings) {
    const programHeaderImage = programSettings.header.image;
    const programFooterImage = programSettings.footer.image;
    const defaultFont = defaultFonts?.defaultFont;
    const defaultMargins = { top: 0, left: 0, bottom: 0, right: 0 } as IMargins;

    if (typeof programHeaderImage === 'string') {
      const castedImage = programHeaderImage as string;
      if (castedImage.startsWith('http')) {
        programSettings.header.image = { url: programHeaderImage };
      } else {
        programSettings.header.image = { uuid: programHeaderImage };
      }
    }

    if (typeof programFooterImage === 'string') {
      const castedImage = programFooterImage as string;
      if (castedImage.startsWith('http')) {
        programSettings.footer.image = { url: programFooterImage };
      } else {
        programSettings.footer.image = { uuid: programFooterImage };
      }
    }

    let parsedBannerImages: IRenderImage[] = [];
    for (let bannerImageKey of (Object.keys(programSettings.banners.images) ?? [])) {
      let bannerImage = programSettings.banners.images[bannerImageKey];
      if (typeof bannerImage === 'string') {
        const castedBannerImage = bannerImage as string;
        if (castedBannerImage.startsWith('http')) {
          parsedBannerImages.push({ url: bannerImage });
        } else {
          parsedBannerImages.push({ uuid: bannerImage });
        }
      }
    }

    if (parsedBannerImages.length > 0) {
      if (!programSettings.banners) {
        programSettings.banners = {};
      }
      programSettings.banners.images = parsedBannerImages;
    } else {
      unset(programSettings, 'banners.images');

      if (isEmpty([programSettings.banners])) {
        unset(programSettings, 'banners');
      }
    }

    const periodTitleUnderlineColor = programSettings.periodTitleUnderline?.color;
    if ((isNil(periodTitleUnderlineColor) || periodTitleUnderlineColor === '')) {
      unset(programSettings, 'periodTitleUnderline.fullWidth');
    }


    this.cleanupSettings(programSettings, currentSettings);

    if (isEqual(programSettings?.header?.organizationUnit?.font, defaultFont)) {
      unset(programSettings, 'header.organizationUnit.font');
    }

    if (isEqual(programSettings?.header?.organizationUnit?.margins, defaultMargins)) {
      unset(programSettings, 'header.organizationUnit.margins');
    }

    if (isEqual(programSettings?.header?.programName?.font, defaultFont)) {
      unset(programSettings, 'header.programName.font');
    }

    if (isEqual(programSettings?.header?.programName?.margins, defaultMargins)) {
      unset(programSettings, 'header.programName.margins');
    }

    if (isEqual(programSettings?.header?.programDate?.font, defaultFont)) {
      unset(programSettings, 'header.programDate.font');
    }

    if (isEqual(programSettings?.header?.programDate?.margins, defaultMargins)) {
      unset(programSettings, 'header.programDate.margins');
    }

    return programSettings;
  }

  hasChanges() {
    return !isEmpty(this.currentSettings$.value?.draft_settings) || !isEmpty(this.currentSettings$.value?.draft_overrides);
  }

  async saveSettings() {
    const currentSettings = this.currentSettings$.value;

    if (!isEmpty(currentSettings.draft_settings) || !isEmpty(currentSettings.draft_overrides)) {
      const newSettings = cloneDeep(currentSettings);

      newSettings.settings = this.mergeDeep<IRenderSettings>({}, currentSettings.settings, currentSettings.draft_settings ?? {});
      newSettings.overrides = this.mergeDeep<IRenderSettingOverrides>({}, currentSettings.overrides, currentSettings.draft_overrides ?? {});
      newSettings.draft_settings = null;
      newSettings.draft_overrides = null;

      if (!isEqual(this.currentSettings$.value, newSettings)) {
        const ouId = await firstValueFrom(this.currentOuId$);
        try {
          await this.$renderSettings.update(ouId, newSettings);
          this.currentSettings$.next(newSettings);
        } catch (e) {
          this.$i18nToastProvider.error(_('template_settings.save.failed'));
        }
      } else {
        this.$i18nToastProvider.message(_('template_settings.no_settings_changed'));
      }
    }

  }

  async saveDraftSettings(formKey: string, subsection: 'activity' | 'program') {
    const group = this.templateControls.get(`${formKey}.${subsection}`);

    if (group.dirty) {
      const newSettings = cloneDeep(this.currentSettings$.value);

      if (formKey !== 'general') {
        const overrides = this.parseRenderSettingsOverrides(formKey);

        if (!newSettings.draft_overrides) {
          newSettings.draft_overrides = {} as IRenderSettingOverrides;
        }
        if (!newSettings.draft_overrides[formKey]) {
          newSettings.draft_overrides[formKey] = {} as Partial<IRenderSettings>;
        }

        if (overrides.fonts) {
          newSettings.draft_overrides[formKey].fonts = overrides.fonts;
        }

        if (subsection === 'activity') {
          newSettings.draft_overrides[formKey].activity = overrides.activity;
        } else if (subsection === 'program') {
          newSettings.draft_overrides[formKey].program = overrides.program;
        }

        this.cleanupSettings(newSettings.draft_overrides);
      } else {
        const updatedSettings = this.parseRenderSettings();

        if (!newSettings.draft_settings) {
          newSettings.draft_settings = {
            fonts: updatedSettings.fonts,
          } as IRenderSettings;
        }

        if (subsection === 'activity') {
          newSettings.draft_settings.activity = updatedSettings.activity;
        } else if (subsection === 'program') {
          newSettings.draft_settings.program = updatedSettings.program;
        }

        this.cleanupSettings(newSettings.draft_settings);
      }

      if (isEmpty(newSettings.draft_settings) || isEqual(newSettings.draft_settings, this.currentSettings$.value.settings)) {
        newSettings.draft_settings = null;
      }

      if (isEmpty(newSettings.draft_overrides) || isEqual(newSettings.draft_overrides, this.currentSettings$.value.overrides)) {
        newSettings.draft_overrides = null;
      }

      if (!isEqual(newSettings.draft_settings, this.currentSettings$.value.draft_settings) || !isEqual(newSettings.draft_overrides, this.currentSettings$.value.draft_overrides)) {
        const ouId = await firstValueFrom(this.currentOuId$);
        try {
          await this.$renderSettings.update(ouId, newSettings);
          this.currentSettings$.next(newSettings);
          this.renderPdf();
        } catch (e) {
          this.$i18nToastProvider.error(_('template_settings.save.failed'));
        }
      } else if ((subsection === 'activity' && this.activityIdControl.dirty) || (subsection === 'program' && this.programIdControl.dirty)) {
        this.renderPdf();
      } else {
        this.$i18nToastProvider.message(_('template_settings.no_changes_to_preview'));
      }
    } else if ((subsection === 'activity' && this.activityIdControl.dirty) || (subsection === 'program' && this.programIdControl.dirty)) {
      this.renderPdf();
    }
  }

  keyValueOrder(a: KeyValue<string, AbstractControl<any, any>>, b: KeyValue<string, AbstractControl<any, any>>): number {
    return a.value?.get('order')?.value < b.value?.get('order')?.value ? -1 : b.value?.get('order')?.value < a.value?.get('order')?.value ? 1 : 0;
  }

  getFontFamilyStyles(familyControl: FormControl) {
    const family = familyControl.value;
    return (this.fontList ?? {})[family]?.styles ?? [];
  }

  castAsFormControl(control: any) {
    return control as FormControl;
  }

  convertPtToPx(pt: number) {
    return (pt ?? 0) / 72 * 96;
  }

  addBannerImageControl(imageGroup: FormGroup) {
    const greatestImageKey = max(Object.keys(imageGroup.controls).map((imageControlKey) => parseInt(imageControlKey, 10)));
    imageGroup.addControl(`${greatestImageKey + 1}`, new FormControl());
  }

  removeBannerImage(imageGroup: FormGroup, key: string) {
    imageGroup.removeControl(key);
    imageGroup.markAsDirty();
  }

  bannerImageKeyValueOrder(a: KeyValue<string, AbstractControl<any, any>>, b: KeyValue<string, AbstractControl<any, any>>) {
    return parseInt(a.key) < parseInt(b.key) ? -1 : parseInt(b.key) < parseInt(a.key) ? 1 : 0;
  }

  hasChild = (index: number, node: INode) => !!node.children && node.children.length > 0;

  isDefined = (value) => !isNil(value);

  hasDraftChanges(formKey: string, subsection: 'activity' | 'program') {
    const group = this.templateControls.get(`${formKey}.${subsection}`);
    let currentSettings: IRenderActivitySettings | IRenderProgramSettings;
    if (formKey === 'general') {
      currentSettings = (this.currentSettings$.value?.settings ?? {})[subsection];
    } else {
      currentSettings = ((this.currentSettings$.value?.overrides ?? {})[formKey] ?? {})[subsection];
    }

    const cleanedupGroupValue = this.cleanupSettings(group.value);

    return group.dirty && !isEqual(currentSettings, cleanedupGroupValue);
  }

  setColor(control: FormControl, value: string) {
    control.setValue(value);
    control.markAsDirty();
  }

  async resetSettings() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translate.instant(_('template_settings.unsaved_changes_dialog.title')),
        description: this.$translate.instant(_('template_settings.unsaved_changes_dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const currentSettings = cloneDeep(this.currentSettings$.value);
      currentSettings.draft_settings = null;
      currentSettings.draft_overrides = null;
      const ouId = await firstValueFrom(this.currentOuId$);
      await this.$renderSettings.update(ouId, currentSettings);
      this.getCurrentSettings(currentSettings);
      this.renderPdf();
    }
  }

  clearFontGroup(fontGroup: FormGroup) {
    const currentDefaultFont = this.currentSettings$.value?.settings?.fonts?.defaultFont;
    fontGroup.get('color').setValue(currentDefaultFont?.color ?? '');
    fontGroup.get('color').markAsDirty();
    fontGroup.get('family').setValue(currentDefaultFont?.family ?? '');
    fontGroup.get('family').markAsDirty();
    fontGroup.get('size').setValue(currentDefaultFont?.size ?? '');
    fontGroup.get('size').markAsDirty();
    fontGroup.get('style').setValue(currentDefaultFont?.style ?? '');
    fontGroup.get('style').markAsDirty();
    fontGroup.get('weight').setValue(currentDefaultFont?.weight ?? '');
    fontGroup.get('weight').markAsDirty();
    fontGroup.markAsDirty();
  }

  async createNewSettings() {
    const currentOuId = await firstValueFrom(this.currentOuId$);
    const parentOus = this.$session.getCurrentOu().node_path.slice().reverse().filter((ouId) => `${ouId}` !== `${currentOuId}`);
    let parentRenderSettings: IExtendedOrganizationUnitRenderSettings;

    for (const parentOuId of parentOus) {
      try {
        parentRenderSettings = await this.$renderSettings.fetch(parentOuId);
      } catch (e) {
        // the parent did not have settings so we just go on to the parent above that
      }

      if (parentRenderSettings) {
        break;
      }
    }

    if (!parentRenderSettings) {
      // we didn't find any settings in the parent ous so we just gonna default to the DK settings
      //
      try {
        parentRenderSettings = await this.$renderSettings.fetch(DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER);
      } catch (e) {
        // Whoops DK didn't have any settings. that's not good
      }
    }

    if (!parentRenderSettings) {
      this.$i18nToastProvider.error(_('template_settings.no_settings.create.error'));
    } else {
      parentRenderSettings.organization_unit_id = currentOuId;
      const newSettings = await this.$renderSettings.update(currentOuId, omit(parentRenderSettings, ['id', 'created_at', 'updated_at']));
      this.getCurrentSettings(newSettings);
    }
  }
}
