import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IPeriodSectionType } from 'typings/doenkids/doenkids';

export interface IPeriodSectionTypeState extends EntityState<IPeriodSectionType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'period-secton-type' })
export class PeriodSectionTypeStore extends EntityStore<IPeriodSectionTypeState, IPeriodSectionType> {
  constructor() {
    super([]);
  }
}
