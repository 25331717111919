import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DoenkidsSessionProvider, IDoenKidsSession } from 'src/providers/session.provider';
import { isNil } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import {
  BrowseOrganizationUnitDialogComponent, ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';

@Component({
  selector: 'app-organization-unit-selector',
  templateUrl: './organization-unit-selector.component.html',
  styleUrls: ['./organization-unit-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationUnitSelectorComponent {
  @Input() extended: boolean;

  public session$: BehaviorSubject<IDoenKidsSession>;

  public name$: Observable<string>;

  public parent$: Observable<string>;

  constructor(
    private dialog: MatDialog,
    public $doenkidsSession: DoenkidsSessionProvider,
    private $translateService: TranslateService,
  ) {
    this.session$ = this.$doenkidsSession.getSession$;

    this.name$ = this.session$?.pipe(
      filter((session) => !isNil(session)),
      map((session) => {
        if (session?.details?.name) {
          return session?.details?.name;
        }
        return '';
      }),
    );

    this.parent$ = this.session$?.pipe(
      filter((session) => !isNil(session)),
      map((session) => {
        if (session?.details?.name && session?.details?.parent_organization_unit_id !== DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER) {
          return session?.details?.parent_organization_unit_name;
        }
        return '';
      }),
    );
  }

  async selectOrganizationUnit() {
    const isReader = await firstValueFrom(this.$doenkidsSession.isReader$);
    const dialogRef = this.dialog.open(BrowseOrganizationUnitDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant(_('organization_unit_selector.select.dialog.title')),
        description: this.$translateService.instant(_('organization_unit_selector.select.dialog.description')),
        writeableOnly: isReader ? false : true,
      },
    });

    const selection = await firstValueFrom(dialogRef.afterClosed()) as ISelectedOrganizationUnit[];
    const result = selection && selection[0];

    if (result) {
      this.$doenkidsSession.selectOrganizationUnit(result.id);
    }
  }
}
