/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ISection } from 'typings/doenkids/doenkids';

export interface ISectionState extends EntityState<ISection> {
  limit: number;
  skip: number;
  total: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'section' })
export class SectionStore extends EntityStore<ISectionState, ISection> {
  constructor() {
    super();
  }

  setMetadata({
    total, skip, limit,
  }) {
    this.update({
      total, skip, limit,
    });
  }
}
