import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { Observable, firstValueFrom } from 'rxjs';
import { IEntityWithAccessLevel } from 'typings/api-generic';
import { IMedia } from 'typings/doenkids/doenkids';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private $http: HttpClient) {}

  fetch(mediaId: number) {
    return this.$baseApi.genericGetCall(`/media/asset/${mediaId}`) as Observable<IEntityWithAccessLevel<IMedia>>;
  }

  archive(mediaId: number) {
    return this.$baseApi.genericDeleteCall(`/media/asset/${mediaId}`);
  }

  getUrl(mediaUuid: string) {
    return this.$baseApi.genericGetCall(`/media/asset/url/${mediaUuid}`);
  }

  getByUuid(mediaUuid: string) {
    return this.$baseApi.genericGetCall(`/media/asset/uuid/${mediaUuid}`) as Observable<IMedia>;
  }

  getUsageById(mediaId: number) {
    return this.$baseApi.genericGetCall(`/media/asset/${mediaId}/usage`);
  }

  getUsageByUuid(uuid: string) {
    return this.$baseApi.genericGetCall(`/media/asset/uuid/${uuid}/usage`);
  }

  update(mediaItem: IMedia) {
    return this.$baseApi.genericPutCall(`/media/asset/${mediaItem.id}`, mediaItem) as Observable<IMedia>;
  }

  replace(mediaUuid: string, file: File) {
    const url = `${this.$baseApi.API_END_POINT}/media/asset/uuid/${mediaUuid}/replace`;

    return firstValueFrom(this.$baseApi.uploadFile(url, file) as Observable<IMedia>);
  }
}
