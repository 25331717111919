import { Injectable } from '@angular/core';
import { IAttachment } from 'typings/doenkids/doenkids';
import { IAttachmentListResponse } from 'typings/api-media';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { AttachmentStore } from './attachment.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: AttachmentStore,
  ) {}

  fetchAll({
    activityId, limit = 100, skip = 0, sortField, sortDirection,
  }) {
    return firstValueFrom(this.$baseApi.genericListCall(`/media/attachment/${activityId}`, limit, skip, sortField, sortDirection)) as Promise<IAttachmentListResponse>;
  }

  async update(activityId, attachmentId): Promise<IAttachment> {
    const updatedAttachment = await firstValueFrom(this.$baseApi.genericPutCall(`/media/attachment/${activityId}/attachment/${attachmentId}`, {})) as IAttachment;
    if (updatedAttachment) {
      this.store.upsert(updatedAttachment.id, updatedAttachment);
    }
    return updatedAttachment;
  }

  async delete(activityId, attachmentId): Promise<void> {
    await firstValueFrom(this.$baseApi.genericDeleteCall(`/media/attachment/${activityId}/attachment/${attachmentId}`));
    this.store.remove(attachmentId);
  }

  reorder(activityId: number, mediaId: number, order: number) {
    let url = `/media/attachment/${activityId}/attachment/${mediaId}/order`;

    url = this.$baseApi.appendQueryParam(url, 'order', order);

    return firstValueFrom(this.$baseApi.genericPutCall(url));
  }
}
