import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IPeriod } from 'typings/doenkids/doenkids';

export interface IProgramPeriodListState extends EntityState<IPeriod> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-period-list' })
export class ProgramPeriodListStore extends EntityStore<IProgramPeriodListState, IPeriod> {
  constructor() {
    super([]);
  }
}
