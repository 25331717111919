import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IGroupSizeListResponse } from 'typings/api-activity';
import { GroupSizeStore } from './group-size.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupSizeService {
  constructor(private store: GroupSizeStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const groupSizeListRepsonse = await firstValueFrom(this.$baseApi.genericListCall('/activity/group-size', limit, skip, sortField, sortDirection)) as IGroupSizeListResponse;

    this.store.set(groupSizeListRepsonse?.items ?? []);
  }
}
