import {
  Directive, Inject, ElementRef, forwardRef, OnDestroy, AfterViewInit, PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgxMasonryComponent } from './ngx-masonry.component';

interface MutationWindow extends Window {
  MutationObserver: any;
  WebKitMutationObserver: any;
}

declare let window: MutationWindow;

@Directive({
  selector: '[ngxMasonryItem], ngxMasonryItem',
})
export class NgxMasonryDirective implements OnDestroy, AfterViewInit {
  constructor(
    private _element: ElementRef,
    @Inject(forwardRef(() => NgxMasonryComponent))
    private _parent: NgxMasonryComponent,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._parent.add(this._element.nativeElement);
      this.watchForHtmlChanges();
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this._parent.remove(this._element.nativeElement);
    }
  }

  /** When HTML in brick changes dynamically, observe that and change layout */
  private watchForHtmlChanges(): void {
    // eslint-disable-next-line no-global-assign
    MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

    if (MutationObserver) {
      const observer = new MutationObserver(() => {
        this._parent.layout();
      });

      // define what element should be observed by the observer
      // and what types of mutations trigger the callback
      observer.observe(this._element.nativeElement, {
        subtree: true,
        childList: true,
      });
    }
  }
}
