<ng-container *ngIf="aggregationData as aggregations">
  <mat-chip-set>
    <div *ngIf="hasData$ | async" class="mat-chip-header">{{ 'search_chips.selected_filters' | translate }}</div>
    <div class="chip-set-container">
      <ng-container *ngIf="aggregationKeys.length > 0 && aggregationData">
        <ng-container *ngFor="let aggregationKey of aggregationKeys">
          <ng-container *ngFor="let option of aggregationData[aggregationKey]?.buckets">
            <mat-chip *ngIf="option.key && selection[aggregationKey]?.includes(option.key)" [removable]="true" (click)="remove(option.key, aggregationKey)">
              <span>
                {{ option.key | doenkidsTranslate: translationPrefix + '.' + aggregationKey }}
                {{ aggregationKey === 'duration' ? ('search_chips.minutes_suffix' | translate) : '' }}
              </span>
            </mat-chip>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="reset-filters-button-container">
      <ng-container *ngIf="hasData$ | async">
        <button class="reset-filters-button" (click)="removeAllFilters()">
          <mat-chip class="reset-filters-chip">{{ 'search_chips.clear_filters' | translate }} </mat-chip>
        </button>
      </ng-container>
    </div>
  </mat-chip-set>
</ng-container>
