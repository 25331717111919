<form [formGroup]="activityStatusFilterForm" class="form-wrapper">
  <div [class.row-layout]="isLarge$ | async" class="filters">
    <mat-form-field fxFlexFill.lt-lg="true" class="activity-status" appearance="outline" *ngIf="displayStatusFilters$ | async">
      <mat-select required formControlName="activityStatus" [panelWidth]="280">
        <mat-option *ngFor="let showActivityStatus of (activityStatusesToShow$ | async)" [value]="showActivityStatus.value">
          {{ showActivityStatus.displayValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlexFill.lt-lg="true">
      <mat-select required formControlName="showActivities" [panelWidth]="220">
        <mat-option *ngFor="let option of organizationFilterOptions$ | async" [value]="option.value">
          {{ option.displayValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlexFill.lt-lg="true" class="activity-sort" appearance="outline">
      <mat-select [formControl]="sortOptionControl" [placeholder]="'activity_status_filters.sorting.placeholder' | translate">
        <mat-option [value]="">{{ 'activity_status_filters.sorting.none' | translate }}</mat-option>
        <mat-option *ngFor="let sortOption of localSortingOptions" [value]="sortOption">
          {{ sortOption.displayValue | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
