import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IActivityStatusListResponse } from 'typings/api-activity';
import { ProgramStatusStore } from './program-status.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramStatusService {
  constructor(private store: ProgramStatusStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 10, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const programStatusResponse = await firstValueFrom(this.$baseApi.genericListCall('/activity/program-status', limit, skip, sortField, sortDirection)) as IActivityStatusListResponse;

    this.store.set(programStatusResponse?.items ?? []);
  }
}
