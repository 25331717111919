import { FullCalendarElement } from '@fullcalendar/web-component';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IUnpublishedProgramCount, ProgramProvider } from 'src/providers/program.provider';
import {
  Component, OnInit, ViewChild, ViewEncapsulation, OnDestroy, ElementRef, ChangeDetectorRef,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { IOrganizationUnitOverview, IProgram, ISearchActivity } from 'typings/doenkids/doenkids';
import { isNil } from '@datorama/akita';
import {
  map,
  takeUntil,
  distinctUntilChanged,
  debounceTime,
  startWith,
  pairwise,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  Observable, Subject, combineLatest, BehaviorSubject, firstValueFrom,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { ProgramListService } from 'src/api/activity/program-list/program-list.service';
import { ProgramListQuery } from 'src/api/activity/program-list/program-list.query';
import { ActivitySelectionProvider } from 'src/providers/activity-selection.provider';
import { ProgramDateProvider } from 'src/providers/program-date.provider';
import { ProgramCreationProvider } from 'src/providers/program-creation.provider';
import { DownloadProvider } from 'src/providers/download.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ProgramService } from 'src/api/activity/program/program.service';
import { ProgramStatusService } from 'src/api/activity/program-status/program-status.service';
import { MatSelectChange } from '@angular/material/select';
import { CreateProgramBookletComponent, IAtivityReviewOutput } from 'src/components/dialogs/create-program-booklet/create-program-booklet.component';
import { PermissionProvider } from 'src/providers/permission.provider';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import { IActivity } from 'typings/period-section-types';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { EOrganizationUnitType } from 'src/components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { I18nProgramStatusProvider, ITranslatedProgramStatus } from 'src/providers/i18n-program-status.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { isEmpty } from 'lodash';
import { EProgramStatus } from '../program-details/program-details.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DoenKidsCalendarComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  public selecting = false;

  public selectedPrograms = [];

  private initialized$ = new BehaviorSubject<boolean>(false);

  // references the #calendar in the template
  @ViewChild('doenkidsCalendar') calendarComponent: ElementRef<FullCalendarElement>;

  public isAdmin$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public showWeekend: UntypedFormControl;

  public calendarEvents$ = new BehaviorSubject<EventInput[]>([]);

  public calendarTypeToDisplay: 'Lijst' | 'Maand' = 'Maand';

  public selectedActivities$: Observable<(ISearchActivity | IActivity)[]>;

  public programs$: BehaviorSubject<IProgram[]> = new BehaviorSubject<IProgram[]>([]);

  private defaultSelectedProgramStatuses: EProgramStatus[] = [EProgramStatus.CONCEPT, EProgramStatus.REVIEW, EProgramStatus.PUBLISHED];

  public currentProgramStatus$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  public headerOptions = {
    left: '',
    center: '',
    right: '',
  };

  protected calendarTypes$: Observable<{ value: string, view: string, name: string }[]>;

  public listLoading$: Observable<boolean>;

  public programSearchResults$ = new BehaviorSubject<IProgram[]>([]);

  public programStatuses$: Observable<ITranslatedProgramStatus[]>;

  private currentOU$: Observable<IOrganizationUnitOverview>;

  public calendarOptions$: Observable<CalendarOptions>;

  // These are the type of organization units there are
  // 1: Node
  // 2: Customer
  // 3: Location
  // 4: Group
  public error$: Observable<boolean>;

  public isLocation$: Observable<boolean>;

  public isHandset$: Observable<boolean>;

  protected currentViewTitle: string;

  public programSearchInput = new UntypedFormControl();

  public next() {
    this.calendarComponent?.nativeElement.getApi()?.next();
    this.rerender();
  }

  public today() {
    this.calendarComponent?.nativeElement.getApi()?.today();
    this.rerender();
  }

  public previous() {
    this.calendarComponent?.nativeElement.getApi()?.prev();
    this.rerender();
  }

  public possibleContentLanguages: string[] = [];

  public contentLanguageControl = new FormControl('');

  public unpublishedProgramCount$?: Observable<IUnpublishedProgramCount>;

  private fetchCalendarItemsController?: AbortController;

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointProvider: BreakpointsProvider,
    private programListService: ProgramListService,
    programListQuery: ProgramListQuery,
    private programStatusService: ProgramStatusService,
    private matDialog: MatDialog,
    private $programDateProvider: ProgramDateProvider,
    private $programCreate: ProgramCreationProvider,
    private $programList: ProgramListService,
    private $activitySelection: ActivitySelectionProvider,
    private $downloadService: DownloadProvider,
    private $session: DoenkidsSessionProvider,
    private programProvider: ProgramProvider,
    private $permission: PermissionProvider,
    private $programService: ProgramService,
    private $translateService: TranslateService,
    private $i18nProgramStatusProvider: I18nProgramStatusProvider,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.destroy$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.destroy$));

    this.showWeekend = new UntypedFormControl(false);
    this.$session.ouLanguages$.pipe(takeUntil(this.destroy$)).subscribe((ouLanguages) => {
      this.possibleContentLanguages = ouLanguages;
    });

    this.selectedActivities$ = this.$activitySelection.selectedActivities$.pipe(takeUntil(this.destroy$));

    this.listLoading$ = combineLatest(
      [programListQuery.selectLoading(), this.$downloadService.programsDownloading$],
    ).pipe(
      map((loading) => loading.includes(true)),
      takeUntil(this.destroy$),
    );

    this.currentOU$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.destroy$),
    );

    this.error$ = this.currentOU$.pipe(
      map((organizationUnit) => {
        if (isNil(organizationUnit) || organizationUnit.organization_unit_type_id !== EOrganizationUnitType.LOCATION) {
          return true;
        }
        return false;
      }),
    );

    this.isLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.destroy$));
    this.isHandset$ = this.breakpointProvider.isHandset$.pipe(takeUntil(this.destroy$));

    this.listLoading$ = combineLatest([
      programListQuery.selectLoading(),
      this.$downloadService.programsDownloading$,
    ]).pipe(
      takeUntil(this.destroy$),
      map((loading) => loading.includes(true)),
    );

    this.programStatuses$ = this.$i18nProgramStatusProvider.programStatuses$.pipe(takeUntil(this.destroy$));

    this.calendarOptions$ = this.$translateService
      .onInitialTranslationAndLangOrTranslationChange$
      .pipe(
        takeUntil(this.destroy$),
        map((langChange) => ({
          buttonText: {
            today: langChange.translations[_('calendar.today')],
            month: langChange.translations[_('calendar.month')],
            week: langChange.translations[_('calendar.week')],
            day: langChange.translations[_('calendar.day')],
            list: langChange.translations[_('calendar.list')],
          },
          defaultAllDay: false,
          editable: false,
          eventClick: this.eventClick.bind(this),
          datesSet: (dateSetArg) => {
            this.currentViewTitle = dateSetArg.view.title;
            this.cd.detectChanges();
          },
          eventDrop: this.updateEvent.bind(this),
          eventDurationEditable: true,
          eventResize: this.updateEvent.bind(this),
          headerToolbar: this.headerOptions,
          noEventsContent: langChange.translations[_('calendar.no_events')],
          height: 800,
          initialView: 'dayGridMonth',
          locale: langChange.lang,
          select: this.selectPeriod.bind(this),
          selectable: false,
          viewDidMount: this.viewRender.bind(this),
          plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
          weekends: false,
          weekNumbers: true,
          eventContent: (arg) => {
            let flagString = '';
            const language = arg.event.extendedProps.language;

            if (this.possibleContentLanguages.length > 1 && language) {
              flagString = `<img src="assets/icons/languages/${language}.svg" />`;
            }
            return { html: `${flagString} <span>${arg.event.title}</span>`.trim() };
          },
        } as CalendarOptions)),
      );

    this.calendarTypes$ = this.$translateService
      .onInitialTranslationAndLangOrTranslationChange$
      .pipe(
        takeUntil(this.destroy$),
        map((langChange) => [
          {
            value: 'Lijst',
            view: 'listMonth',
            name: langChange.translations[_('calendar.list')],
          },
          {
            value: 'Maand',
            view: 'dayGridMonth',
            name: langChange.translations[_('calendar.month')],
          },
        ]),
      );

    combineLatest([this.$permission.canSeeUnpublishedProgramCountPermission$, this.$session.ouCountryCode$]).pipe(
      takeUntil(this.destroy$),
    ).subscribe(([canSeeUnpublishedProgramCount, ouCountryCode]) => {
      if (ouCountryCode.toLowerCase() === 'nl' && canSeeUnpublishedProgramCount) {
        this.unpublishedProgramCount$ = this.programProvider.unpublishedProgramCount$.asObservable();
      } else {
        this.unpublishedProgramCount$ = undefined;
      }
    });
  }

  async ngOnInit() {
    const { queryParams } = this.route.snapshot;
    const isReader = await firstValueFrom(this.isReader$);

    if (isReader) {
      this.defaultSelectedProgramStatuses = [EProgramStatus.PUBLISHED];
    }

    this.currentProgramStatus$.next(this.defaultSelectedProgramStatuses);

    if (queryParams.status) {
      const passedStatuses = (queryParams.status as string).split(',');
      let parsedPassedStatuses = passedStatuses.map((passedStatus) => +passedStatus).filter((passedStatus) => {
        return isReader ? passedStatus === EProgramStatus.PUBLISHED : passedStatus;
      });
      if (isEmpty(parsedPassedStatuses)) {
        parsedPassedStatuses = this.defaultSelectedProgramStatuses;
      }
      this.currentProgramStatus$.next(parsedPassedStatuses);
    }

    const contentLanguage = queryParams.language ?? await firstValueFrom(this.$session.preferredContentLanguage$);
    this.contentLanguageControl.setValue(contentLanguage);

    combineLatest([this.currentOU$, this.contentLanguageControl.valueChanges.pipe(startWith(this.contentLanguageControl.value)), this.error$, this.isReader$]).pipe(
      takeUntil(this.destroy$),
    ).subscribe(([organizationUnit, language, isNotLocation, userIsReader]) => {
      // Fetch the list of all statuses
      //
      this.programStatusService.fetchAll();

      if (isNotLocation) {
        this.currentProgramStatus$.next(userIsReader ? [EProgramStatus.PUBLISHED] : [EProgramStatus.REVIEW]);
        this.changeView('listYear');
      } else {
        this.currentProgramStatus$.next(this.defaultSelectedProgramStatuses);
        this.changeView('dayGridMonth');
      }
      this.fetchCalendarItems(organizationUnit, this.currentProgramStatus$.value, !isNotLocation, language);
    });

    // yes this is weird but for some reason on change detection the value is emptied
    // this we set back the previous value
    //
    this.contentLanguageControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(this.contentLanguageControl.value),
      pairwise(),
    ).subscribe(([previous, current]) => {
      if (isEmpty(current) && !isEmpty(previous)) {
        this.contentLanguageControl.setValue(previous);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  async fetchCalendarItems(organizationUnit: IOrganizationUnitOverview, statusIds?: number[] | undefined, nodeOnly?: boolean, language?: string) {
    if (this.fetchCalendarItemsController && !this.fetchCalendarItemsController.signal.aborted) {
      this.fetchCalendarItemsController.abort();
    }
    const newAbortController = new AbortController();
    this.fetchCalendarItemsController = newAbortController;

    // The current customer/location/group changed. Refetch the list.
    //
    // Status: 1: Concept, 2: Review, 3: Published
    let newPrograms: IProgram[] = [];
    language = !isEmpty(language) ? language : await firstValueFrom(this.$session.preferredContentLanguage$);

    if (!newAbortController.signal.aborted) {
      if (!statusIds) {
        const newProgramResponse = await this.programListService.fetchAll({
          limit: 50000,
          skip: 0,
          nodeOnly,
          organizationUnitId: organizationUnit.id,
          language,
        });
        newPrograms = newProgramResponse?.items ?? [];
      } else {
        for (const statusId of statusIds) {
          // eslint-disable-next-line no-await-in-loop
          const newProgramResponse = await this.programListService.fetchAll({
            limit: 50000,
            skip: 0,
            statusId,
            nodeOnly,
            organizationUnitId: organizationUnit.id,
            language,
          }, true);
          newPrograms.push(...(newProgramResponse?.items ?? []));
        }
      }

      if (!newAbortController.signal.aborted) {
        if (!isNil(this.calendarComponent) && !isNil(newPrograms)) {
          // Replace them with
          //
          const calendarData = await this.getData(newPrograms);
          if (!newAbortController.signal.aborted) {
            this.calendarEvents$.next(calendarData);
            this.programs$.next(newPrograms);
          }
        }
      }
    }
  }

  async changePublicationStatus($event: MatSelectChange) {
    const organizationUnit = await firstValueFrom(this.currentOU$);
    const isLocation = await firstValueFrom(this.isLocation$);
    this.currentProgramStatus$.next($event.value);
    this.fetchCalendarItems(organizationUnit, $event.value, isLocation, this.contentLanguageControl.value);
  }

  async searchPrograms(search: string) {
    const { id: organizationUnitId } = await firstValueFrom(this.currentOU$);

    const result = await this.$programList.fetchAll({
      search,
      organizationUnitId,
    });

    return result;
  }

  selectProgram(event: MatAutocompleteSelectedEvent) {
    const program = event.option.value as IProgram;
    this.calendarComponent.nativeElement.getApi().gotoDate(program.from);
  }

  getProgramName(program: IProgram) {
    return program?.name ?? '';
  }

  // this function is called when rendering the calender on the screen
  //
  async viewRender() {
    const programs = await firstValueFrom(this.programs$);
    this.calendarEvents$.next(await this.getData(programs));

    const api = this.calendarComponent?.nativeElement.getApi();

    const { queryParams } = this.route.snapshot;

    if (queryParams.date) {
      const parsedQueryParamDate = dayjs(queryParams.date);
      const firstDayOfParsedQueryParamDate = parsedQueryParamDate.startOf('month').format('YYYY-MM-DD');

      api.gotoDate(firstDayOfParsedQueryParamDate);
    }

    // Change weekend option
    //
    this.showWeekend.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async (showWeekend) => {
      const initialized = await firstValueFrom(this.initialized$);
      if (!initialized) {
        return;
      }
      api.setOption('weekends', showWeekend);
    });

    // Make calendar editable
    //
    combineLatest([this.isAdmin$, this.error$]).pipe(
      takeUntil(this.destroy$),
    ).subscribe(([isAdmin, error]) => {
      if (isAdmin || !error) {
        api.setOption('editable', true);
        api.setOption('selectable', true);
      }
    });

    // Render events
    //
    this.calendarEvents$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((events) => {
      api.setOption('events', events);
    });

    this.programSearchInput.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(500),
      distinctUntilChanged((x, y) => x === y),
    ).subscribe(async (query) => {
      const result = await this.searchPrograms(query);

      if (result?.items) {
        this.programSearchResults$.next(result.items);
      }
    });

    this.initialized$.next(true);
  }

  // this function is called when a period is selected and a new program should be created
  //
  async selectPeriod(event) {
    const isReader = await firstValueFrom(this.$session.isReader$);

    if (isReader) {
      // when you are a reader you can't select a period and create a new program so we return right now
      //
      return;
    }

    const isLocation = await firstValueFrom(this.isLocation$);

    if (isLocation) {
      const start = dayjs(event.start);
      const end = dayjs(event.end);
      this.$programDateProvider.timespan = { start, end };
      const result = await this.$programCreate.promptProgramFromCalendar(this.contentLanguageControl.value);

      if (result === 'refresh') {
        const organizationUnit = await firstValueFrom(this.currentOU$);
        // refetch all calendar items as there might be new ones to show
        this.fetchCalendarItems(organizationUnit, this.currentProgramStatus$.value, isLocation, this.contentLanguageControl.value);
      }
    } else {
      this.$i18nToastProvider.error(_('calendar.only_for_locations'));
    }
  }

  async getData(programs: IProgram[]) {
    if (isNil(programs)) {
      this.$i18nToastProvider.error(_('calendar.no_programs'));

      return [];
    }

    // Map data to the calendar item structure.
    //
    return programs.map((program, index) => {
      let { to } = program;
      to = dayjs(to).add(1, 'day').toISOString();

      const className = [`color${index % 5}`, `status-${program.program_status_id}`];

      if (this.selectedPrograms.includes(program.id)) {
        className.push('selected');
      }

      return {
        id: `${program.id}`,
        title: program.name,
        start: program.from,
        end: to,
        editable: true,
        allDay: true,
        className,
        language: program.language,
      } as EventInput;
    });
  }

  async downloadPrograms() {
    const amountSelected = this.selectedPrograms.length;

    if (amountSelected === 0) {
      return;
    }

    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('calendar.programs.download.confirm.description'), { amountSelected }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result !== 'confirm') {
      return;
    }

    const loadedPrograms = await firstValueFrom(this.programs$);
    const programs = this.selectedPrograms.map((programId) => loadedPrograms.find((program) => program.id === programId));

    // Disable selection.
    //
    this.selecting = false;
    this.selectedPrograms = [];

    // Re render calendar to show unselected programs
    //
    this.rerender();

    // Download program PDFs
    //
    await this.$downloadService.downloadProgramPdfs(programs);
  }

  async makeBooklet() {
    const amountSelected = this.selectedPrograms.length;

    if (amountSelected === 0) {
      return;
    }

    const loadedPrograms = await firstValueFrom(this.programs$);
    const programs = this.selectedPrograms.map((programId) => loadedPrograms.find((program) => program.id === programId));

    const dialogRef = this.matDialog.open(CreateProgramBookletComponent, {
      width: '600px',
      minWidth: '320px',
      disableClose: true,
      data: {
        title: this.$translateService.instant(_('calendar.program_booklet.create.confirm.title')),
        description: this.$translateService.instant(_('calendar.program_booklet.create.confirm.descripton'), { amountSelected }),
        programs,
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed()) as IAtivityReviewOutput;

    if (result?.status !== 'confirm') {
      return;
    }

    // Disable selection.
    //
    this.selecting = false;
    this.selectedPrograms = [];

    // Re render calendar to show unselected programs
    //
    this.rerender();
  }

  changeView(view: string) {
    const api = this.calendarComponent?.nativeElement.getApi();
    if (api) {
      api.changeView(view);
    }
  }

  toggleProgram(programId: number) {
    // Figure out whether the program was selected already.
    //
    const foundProgramId = this.selectedPrograms.find((selectedProgramId) => selectedProgramId === programId);

    // If the program was found, remove it. Otherwise, add it to the list.
    //
    if (!isNil(foundProgramId)) {
      this.selectedPrograms = this.selectedPrograms.filter((selectedProgramId) => selectedProgramId !== programId);
      this.rerender();
    } else {
      this.selectedPrograms.push(programId);
      this.rerender();
    }
  }

  toggleDownloadSelection(selecting?) {
    this.selecting = isNil(selecting) ? !this.selecting : selecting;

    if (!this.selecting) {
      this.selectedPrograms = [];
      this.rerender();
    }
  }

  async rerender() {
    const programs = await firstValueFrom(this.programs$);
    this.calendarEvents$.next(await this.getData(programs));
  }

  // This function is used when clicking on a program/activity in the calendar
  //
  public eventClick($event: { event: EventInput }) {
    const { event } = $event;
    const programId = parseInt(event?.id, 10);
    const programName = event?.title;

    if (this.selecting) {
      this.toggleProgram(programId);
      return;
    }

    if (isNil(programId)) {
      this.$i18nToastProvider.error(_('calendar.program.view.failed'));

      return;
    }

    firstValueFrom(this.$activitySelection.selectedActivities$).then(async (selectedActivities) => {
      if (selectedActivities.length > 0) {
        await this.$programCreate.promptAddActivitiesToProgram(programId, programName);
      }
      const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
      this.router.navigate([`/organization/${organizationUnitId}/program/${event.id}`]);
    });
  }

  async updateEvent(model: { event: EventInput }) {
    const { id, start, end } = model.event;
    const idNumber = parseInt(id as string, 10);
    const currentProgram = await firstValueFrom(this.programs$.pipe(
      map((items) => items.find((program) => program.id === idNumber)),
    ));

    this.$programService.update({
      ...currentProgram,
      from: dayjs.tz(start as Date).set('hours', 8).set('minutes', 30).toDate().toISOString(),
      to: dayjs.tz(end as Date).subtract(1, 'day').set('hours', 18).toDate().toISOString(),
    });
  }

  handleFlagInputClick($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  getFormattedDate(date: Date) {
    return this.$translateService.getDayjsLocaleInstance(date).format('DD-MM-YYYY');
  }
}
