import {
  Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, ViewEncapsulation,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { ActivitySearchService } from 'src/api/search/activity/activity-search.service';
import { get } from 'lodash';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Observable, Subject, firstValueFrom } from 'rxjs';

export interface ISearchEvent {
  language: string;
  query: string;
}

@Component({
  selector: 'app-activity-search-bar',
  templateUrl: './activity-search-bar.component.html',
  styleUrls: ['./activity-search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivitySearchBarComponent implements OnInit, OnDestroy, OnChanges {
  private stop$: Subject<void> = new Subject<void>();

  @Input() contentLanguage?: string;

  @Input() query: string;

  @Input() showContentLanguageOption: boolean = true;

  @Output() searched = new EventEmitter<ISearchEvent>();

  public searchControl: UntypedFormControl;

  public contentLanguageControl = new FormControl('');

  public possibleContentLanguages$: Observable<string[]>;

  constructor(
    public activitySearchService: ActivitySearchService,
    public sessionProvider: DoenkidsSessionProvider,
  ) {
    this.searchControl = new UntypedFormControl('');

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.stop$)).subscribe((newQuery: string) => {
      this.doSearch(newQuery);
      this.query = newQuery;
    });

    this.possibleContentLanguages$ = this.sessionProvider.ouLanguages$.pipe(takeUntil(this.stop$));
  }

  async ngOnInit() {
    // if we already have query (for example from a stored search) set this value to the search control
    //
    if (this.query !== '') {
      this.searchControl.setValue(this.query);
    }

    const selectedContentLanguage = this.contentLanguage ?? await firstValueFrom(this.sessionProvider.preferredContentLanguage$);
    this.contentLanguageControl.setValue(selectedContentLanguage);

    this.contentLanguageControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((language) => {
      this.searched.emit({
        language,
        query: this.searchControl.value,
      });
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentQuery = get(changes, 'query.currentValue');

    if (this.searchControl.value !== currentQuery) {
      this.searchControl.setValue(currentQuery);
    }

    if (changes.contentLanguage && !changes.contentLanguage.firstChange && changes.contentLanguage.currentValue) {
      this.contentLanguageControl.setValue(changes.contentLanguage.currentValue);
    }
  }

  doSearch(query: string) {
    this.searched.emit({
      language: this.contentLanguageControl.value,
      query,
    });
  }

  public clearSearch() {
    this.searchControl.setValue('');
    this.doSearch('');
  }

  handleInputClick($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
