/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ISearchActivity } from 'typings/doenkids/doenkids';

export interface IActivitySearchState extends EntityState<ISearchActivity> {
  limit: number;
  skip: number;
  total: number;
  query?: string;
  aggregations: any;
  suggest: any;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-search' })
export class ActivitySearchStore extends EntityStore<IActivitySearchState, ISearchActivity> {
  constructor() {
    super();
  }

  setMetadata({
    total, skip, limit, query, took,
  }) {
    this.update({
      total, skip, limit, query, took,
    });
  }

  setAggregations(aggregations: any) {
    this.update({ aggregations });
  }

  setSuggestions(suggest: any) {
    this.update({ suggest });
  }
}
