import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IEntityWithAccessLevel } from 'typings/api-generic';
import { ICustomerSymbol } from 'typings/doenkids/doenkids';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomerSymbolService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  fetch(customerSymbolId: number) {
    return firstValueFrom(this.$baseApi.genericGetCall(`/customer/symbol/${customerSymbolId}`)) as Promise<IEntityWithAccessLevel<ICustomerSymbol>>;
  }
}
