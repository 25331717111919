<mat-form-field
  class="program-status-selector"
  appearance="outline"
  [matTooltip]="
    ((hasOUWritePermissions$ | async) && (hasWriteAccess === null || hasWriteAccess === undefined)) ||
    (hasWriteAccess !== null && hasWriteAccess !== undefined && hasWriteAccess)
      ? ('program_template_status.status.change.tooltip' | translate)
      : ('program_template_status.status.change.unauthorized.tooltip' | translate)
  "
>
  <mat-select [formControl]="programTemplateStatus">
    <ng-container *ngFor="let status of allStatuses$ | async">
      <mat-option
        [class.disabled]="(hasOUWritePermissions$ | async) === false || (hasWriteAccess !== null && hasWriteAccess !== undefined && !hasWriteAccess)"
        *ngIf="status.id !== EProgramStatus.TEMPLATE"
        (click)="changePublicationStatus(status)"
        [value]="status.id"
        [disabled]="(hasOUWritePermissions$ | async) === false || (hasWriteAccess !== null && hasWriteAccess !== undefined && !hasWriteAccess)"
      >
        {{ 'program.status.' + getProgramStatusName(status.id) | translate }}
      </mat-option>
    </ng-container>
    <ng-container *ngFor="let additionalStatus of additionalStatuses">
      <mat-option
        [class.disabled]="(hasOUWritePermissions$ | async) === false || (hasWriteAccess !== null && hasWriteAccess !== undefined && !hasWriteAccess)"
        *ngIf="additionalStatus.id !== EProgramStatus.TEMPLATE"
        (click)="changePublicationStatus(additionalStatus)"
        [value]="additionalStatus.id"
        [disabled]="(hasOUWritePermissions$ | async) === false || (hasWriteAccess !== null && hasWriteAccess !== undefined && !hasWriteAccess)"
      >
        {{ additionalStatus.name | translate }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
