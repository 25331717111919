import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { PermissionProvider } from 'src/providers/permission.provider';

@Component({
  selector: 'app-settings-overview',
  templateUrl: './settings-overview.component.html',
  styleUrls: ['./settings-overview.component.scss'],
})
export class SettingsOverviewComponent implements OnDestroy {
  private stop$ = new Subject<void>();

  public isAdmin$: Observable<boolean>;

  public isCustomer$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  constructor(
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
  ) {
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }
}
