import { Component, HostBinding, Input, OnChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'card-list-item',
  templateUrl: './card-list-item.component.html',
  styleUrls: ['./card-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardListItemComponent implements OnChanges {
  @Input() mediaUuid: string;

  @Input() selected = false;

  @HostBinding('class') class;

  ngOnChanges(): void {
    this.class = this.selected ? 'selected' : '';
  }
}
