import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IMediaOriginTypeResponse } from 'typings/api-activity';
import { MediaOriginTypeStore } from './media-origin-type.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaOriginTypeService {
  constructor(private store: MediaOriginTypeStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const originTypeListRepsonse = await firstValueFrom(this.$baseApi.genericListCall('/media/media-origin-type', limit, skip, sortField, sortDirection)) as IMediaOriginTypeResponse;

    this.store.set(originTypeListRepsonse?.items ?? []);
  }
}
