/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-undef */
import { init, captureMessage, captureException, setTags, setUser } from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';

import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { getOrganizationUnit$, getUser$ } from 'src/providers/session.provider';
import { isNil } from '@datorama/akita';
import { filter, map } from 'rxjs/operators';
import { keys } from 'lodash';
import { firstValueFrom } from 'rxjs';

interface SentryUser {
  id: string;
  email: string;
  organization_unit_id: string;
}

interface SentryTags {
  [key: string]: string | number;
  ou_activity_type: string;
  ou_id: string;
  ou_type: number;
  ou_name: string;
  parent_ou_name: string;
  parent_ou_id: string;
}

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  constructor(private genericAPI: DoenKidsGenericApiProvider) {
    this.init();
    this.getUser();
    this.getSelectedOrganization();
  }

  async init() {
    const buildJson: any = await firstValueFrom(this.genericAPI.getBuildJson());
    const release = `doenkids-admin-portal@${buildJson.version}-${buildJson.buildNr}`;
    let targetEnvironment = 'production';

    if (window.location.hostname !== 'online.doenkids.nl') {
      targetEnvironment = 'test';
    }

    console.log(`[SENTRY]: Error logging for <${release}> is ${environment.production ? 'enabled' : 'disabled'}`);
    init({
      dsn: 'https://bedb7445908649fb905b552058d700bd@o392734.ingest.sentry.io/5255237',
      enabled: environment.production,
      release,
      environment: targetEnvironment,
      integrations: [
        new RewriteFrames(),
      ],
    });
  }

  getSelectedOrganization() {
    getOrganizationUnit$.subscribe((organization) => {
      // We want to delete keys that are undefined or null
      //
      const cleanSentryTags: Partial<SentryTags> = {};
      const {
        activity_types, id, organization_unit_type_id, name, parent_organization_unit_name, parent_organization_unit_id,
      } = organization;

      const tags: SentryTags = {
        ou_activity_type: (activity_types ?? []).join(','),
        ou_id: `${id}`,
        ou_type: organization_unit_type_id,
        ou_name: name,
        parent_ou_name: parent_organization_unit_name,
        parent_ou_id: `${parent_organization_unit_id}`,
      };

      keys(tags).forEach((key) => {
        if (!isNil(tags[key])) {
          cleanSentryTags[key] = tags[key];
        }
      });

      try {
        setTags(cleanSentryTags);
      } catch (error) {
        console.error('error setting tags', cleanSentryTags);
      }
    });
  }

  getUser() {
    getUser$.pipe(
      map((auth) => auth.user),
      filter((user) => !isNil(user)),
    ).subscribe((user) => {
      const { id, email, organization_unit_id } = user;

      const sentryUser: SentryUser = {
        id: `${id}`,
        email,
        organization_unit_id: `${organization_unit_id}`,
      };

      setUser(sentryUser);
    });
  }

  handleError(error) {
    console.error(error);
    captureException(error.originalError ?? error);
  }

  handleWarning(error: string) {
    console.warn(error);
    captureMessage(error, 'warning');
  }
}
