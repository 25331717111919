import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-drag-handle',
  templateUrl: './drag-handle.component.html',
  styleUrls: ['./drag-handle.component.scss'],
})
export class DragHandleComponent implements OnInit {
  public set disabled(disabled) {
    this._disabled = disabled;
    this.zone.detectChanges();
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  private _disabled = false;

  constructor(private zone: ChangeDetectorRef) { }

  ngOnInit() {
  }
}
