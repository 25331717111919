import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IOrganizationUnit, IKonnectOrganizationUnit, IJibbieOrganizationUnit } from 'typings/doenkids/doenkids';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IServiceResponse } from 'typings/api-generic';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { OrganizationUnitStore } from './organization-unit.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: OrganizationUnitStore,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
  ) {}

  @transaction()
  async create(organization: IOrganizationUnit) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall('/customer/organization-unit', organization)) as IOrganizationUnit;
    this.store.update(response);

    return response;
  }

  @transaction()
  async fetch(id: number, doNotPersist: boolean = false) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/organization-unit/${id}`)) as IOrganizationUnit;
    if (!doNotPersist) {
      this.store.update(response);
    }
    return response;
  }

  async update(organization: IOrganizationUnit) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organization.id}`, organization)) as IOrganizationUnit;
    this.store.update(response);
  }

  async archive(id: number) {
    const result = await firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/organization-unit/${id}`)) as IServiceResponse;
    this.store.reset();
    return result;
  }

  konnectDetails(id: number) {
    return firstValueFrom(this.$baseApi.genericGetCall(`/customer/organization-unit/${id}/konnect`)) as Promise<IKonnectOrganizationUnit>;
  }

  jibbieDetails(id: number) {
    return firstValueFrom(this.$baseApi.genericGetCall(`/customer/organization-unit/${id}/jibbie`)) as Promise<IJibbieOrganizationUnit>;
  }

  exportOUDetails(id: number, filename: string = 'organisatie_export') {
    let url = `${this.$baseApi.API_END_POINT}/customer/organization-unit/${id}/export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    const fullFileName = filename.endsWith('.xlsx') ? filename : `${filename}.xlsx`;

    url = this.$baseApi.appendQueryParam(url, 'filename', fullFileName);

    this.$doenkidsFileDownloaderService.addDownload({ name: fullFileName, url, headers });
  }

  /**
   * Create a financial XLS export for the selected organization unit
   * @param organisationUnitId number
   * @param filename string. Defaults to 'finance_export'
   */
  exportFinance(organisationUnitId: number, filename: string = 'finance_export') {
    let url = `${this.$baseApi.API_END_POINT}/customer/organization-unit/${organisationUnitId}/financial-export`;
    const headers = this.$baseApi.getAuthorizationHeader();
    const fullFileName = filename.endsWith('.xlsx') ? filename : `${filename}.xlsx`;
    url = this.$baseApi.appendQueryParam(url, 'filename', fullFileName);

    this.$doenkidsFileDownloaderService.addDownload({ name: fullFileName, url, headers });
  }
}
