import { IActivityReviewScoreType } from 'typings/doenkids/doenkids.d';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IActivityScoreTypeState, ActivityScoreTypeStore } from './activity-review-score-type.store';

@Injectable({ providedIn: 'root' })
export class ActivityScoreTypeQuery extends QueryEntity<IActivityScoreTypeState, IActivityReviewScoreType> {
  constructor(protected store: ActivityScoreTypeStore) {
    super(store);
  }
}
