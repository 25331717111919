import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IUserDetails } from 'typings/api-customer';

export interface OrganizationUnitUsersListState extends EntityState<IUserDetails> {
  total?: number;
  limit?: number;
  skip?: number;
  query?: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'organization-unit-users-list',
  idKey: 'id',
})
export class OrganizationUnitUsersListStore extends EntityStore<OrganizationUnitUsersListState, IUserDetails> {
  constructor() {
    super([]);
  }
}
