import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IPeriodSection } from 'typings/doenkids/doenkids';
import { IProgramSectionState, ProgramPeriodSectionStore } from './program-section.store';

@Injectable({ providedIn: 'root' })
export class ProgramPeriodSectionQuery extends QueryEntity<IProgramSectionState, IPeriodSection> {
  constructor(protected store: ProgramPeriodSectionStore) {
    super(store);
  }

  get getMetadata() {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
    }));
  }
}
