<app-doenkids-menu-bar></app-doenkids-menu-bar>
<div class="main-content">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab [label]="'settings_overview.tab.tags' | translate" *ngIf="(isCustomer$ | async) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)">
      <app-tags></app-tags>
    </mat-tab>
    <mat-tab [label]="'settings_overview.tab.program_category' | translate" *ngIf="(isCustomer$ | async) && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)">
      <app-program-category-list></app-program-category-list>
    </mat-tab>
    <mat-tab [label]="'settings_overview.tab.preferences' | translate">
      <app-user-preferences></app-user-preferences>
    </mat-tab>
  </mat-tab-group>
</div>
