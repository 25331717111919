import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IAreaOfDevelopment } from 'typings/doenkids/doenkids';
import { AreaOfDevelopmentStore, IAreaOfDevelopmentState } from './area-of-development.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AreaOfDevelopmentQuery extends QueryEntity<IAreaOfDevelopmentState, IAreaOfDevelopment> {
  constructor(protected store: AreaOfDevelopmentStore) {
    super(store);
  }

  getAreaOfDevelopmentForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((areaOfDevelopments) => areaOfDevelopments.filter((areaOfDevelopment) => areaOfDevelopment.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }
}
