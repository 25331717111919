import { Component, ViewEncapsulation, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const unreadNewsItemLocalStorageKey = 'UNREAD_ITEMS_ONLY';

_('home.content.intro_gb');
_('home.content.intro_be');
_('home.content.intro_nl');
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, OnDestroy {
  public countryCode$: Observable<string>;

  constructor($session: DoenkidsSessionProvider) {
    this.countryCode$ = $session.ouCountryCode$.pipe(takeUntil(this.stop$));
  }

  public updatedAt: Date;

  get unreadItemsOnly(): boolean {
    try {
      return JSON.parse(localStorage.getItem(unreadNewsItemLocalStorageKey));
    } catch (e) {
      this.unreadItemsOnly = false;
      return false;
    }
  }

  set unreadItemsOnly(value: boolean) {
    localStorage.setItem(unreadNewsItemLocalStorageKey, JSON.stringify(value));
  }

  public unreadItemsOnlyControl = new FormControl<Boolean>(this.unreadItemsOnly);

  private stop$ = new Subject();

  ngOnInit() {
    this.unreadItemsOnlyControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((value) => {
      this.unreadItemsOnly = value.valueOf();
    });
  }

  doneLoading() {
    this.updatedAt = new Date();
  }

  ngOnDestroy() {
    this.stop$.next(undefined);
  }
}
