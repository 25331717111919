import { Injectable } from '@angular/core';
import { IPeriodSection, IProgram, IProgramSummary } from 'typings/doenkids/doenkids.d';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IEntityWithAccessLevel } from 'typings/api-generic';
import { omit } from 'lodash';
import { Observable, firstValueFrom } from 'rxjs';
import { IEventLogListResponse } from 'typings/api-customer';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { IActivityProgramExportBody, ICreateActivityProgram, IProgramActivityStatus, IProgramListResponse, IProgramSummaryPeriod, IProgramSummaryPeriodSection } from 'typings/api-activity';
import { ProgramStore, IProgramWithAccesLevel } from './program.store';

export function convertIProgramSummaryPeriodSectionToIPeriodSection(sectionData: Partial<IProgramSummaryPeriodSection>, periodId: number, data?: any, columns?: number) {
  const convertedSection = {
    id: sectionData.id,
    name: sectionData.name,
    data: data ?? {
      title: sectionData.title,
      subtitle: sectionData.subtitle,
      content: sectionData.content,
      additional_content: sectionData.additional_content,
      timeslot: sectionData.timeslot,
      activity_id: sectionData.activity_id,
      media_uuid: sectionData.media_uuid,
      customer_symbol: sectionData.customer_symbol,
    },
    order: sectionData.order,
    type_id: sectionData.type_id,
    period_id: periodId,
  } as IPeriodSection;

  if (columns) {
    convertedSection.columns = columns;
  }

  return convertedSection;
}

export interface IExtendedProgramSummary extends IProgramSummary {
  period?: IProgramSummaryPeriod[];
}

@Injectable({ providedIn: 'root' })
export class ProgramService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: ProgramStore,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
  ) { }

  async create(organizationUnitId: number, program: IProgram) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall(
      `/activity/program?organizationUnitId=${organizationUnitId}`, omit(program, 'access_level'),
    )) as IProgram;
    this.store.update(response);
    return response;
  }

  async createActivityProgram(programParams: ICreateActivityProgram) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall(
      '/activity/program/activity', programParams,
    )) as IProgram;
    this.store.update(response);
    return response;
  }

  async fetch(id: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program/${id}`)) as IEntityWithAccessLevel<IProgramWithAccesLevel>;
    this.store.update({
      ...response?.data,
      access_level: response?.access_level,
    });
    return response.data;
  }

  async fetchSummary(programId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program/${programId}/summary`)) as IEntityWithAccessLevel<IExtendedProgramSummary>;
    return response;
  }

  async update(program: IProgram) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/program/${program.id}`, omit(program, 'access_level'))) as IProgramWithAccesLevel;
    this.store.update(response);
    return response;
  }

  organizationUnits(id: number) {
    return this.$baseApi.genericGetCall(`/activity/program/${id}/organization-unit`);
  }

  async archive(id: number) {
    this.store.reset();
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/program/${id}`));
  }

  copy(program: IProgram, organizationUnitId: number) {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program/${program.id}/organization-unit/${organizationUnitId}`, omit(program, 'access_level'),
    )) as Promise<IProgramWithAccesLevel>;
  }

  eventLog(programId: number, limit: number = 20, skip: number = 0) {
    return this.$baseApi.genericGetCall(`/activity/program/${programId}/event-log?limit=${limit}&skip=${skip}`) as Observable<IEventLogListResponse>;
  }

  async countProgramStatus(organizationUnitId: number, programStatusIds: number[], start: Date, end: Date) {
    try {
      const url = '/activity/program/count/program-status';
      const body = {
        organizationUnitId,
        programStatusIds,
        start: start.toISOString(),
        end: end.toISOString(),
      };
      return await firstValueFrom(this.$baseApi.genericPostCall(url, body)) as { count: number };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  programExport(organizationUnitId: number, requestBody: IActivityProgramExportBody) {
    const url = `${this.$baseApi.API_END_POINT}/activity/program/organization/${organizationUnitId}/export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    const fullFileName = requestBody.filename.endsWith('.xlsx') ? requestBody.filename : `${requestBody.filename}.xlsx`;
    requestBody.filename = fullFileName;

    this.$doenkidsFileDownloaderService.addDownload({
      name: fullFileName,
      url,
      headers,
      method: 'POST',
      requestBody,
    });
  }

  programActivityExport(organizationUnitId: number, requestBody: IActivityProgramExportBody) {
    const url = `${this.$baseApi.API_END_POINT}/activity/program/activity/organization/${organizationUnitId}/export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    const fullFileName = requestBody.filename.endsWith('.xlsx') ? requestBody.filename : `${requestBody.filename}.xlsx`;
    requestBody.filename = fullFileName;

    this.$doenkidsFileDownloaderService.addDownload({
      name: fullFileName,
      url,
      headers,
      method: 'POST',
      requestBody,
    });
  }

  locationWithoutProgramExport(ouId: number, requestBody: IActivityProgramExportBody) {
    const url = `${this.$baseApi.API_END_POINT}/activity/program/organization/${ouId}/unplanned/export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    const fullFileName = requestBody.filename.endsWith('.xlsx') ? requestBody.filename : `${requestBody.filename}.xlsx`;
    requestBody.filename = fullFileName;

    this.$doenkidsFileDownloaderService.addDownload({
      name: fullFileName,
      url,
      headers,
      method: 'POST',
      requestBody,
    });
  }

  relatedPrograms(programId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc') {
    return this.$baseApi.genericListCall(`/activity/related-program/${programId}`, limit, skip, sortField, sortDirection) as Observable<IProgramListResponse>;
  }

  addRelatedProgram(fromProgramId: number, toProgramId: number) {
    return this.$baseApi.genericPutCall(`/activity/related-program/${fromProgramId}/program/${toProgramId}`);
  }

  removeRelatedProgram(fromProgramId: number, toProgramId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/related-program/${fromProgramId}/program/${toProgramId}`);
  }

  getProgramActivityStatus(programId: number, organizationUnitId: number) {
    return firstValueFrom(this.$baseApi.genericGetCall(`/activity/program/organization/${organizationUnitId}/program/${programId}`)) as Promise<IProgramActivityStatus[]>;
  }
}
