import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IMediaOriginType } from 'typings/doenkids/doenkids';
import { MediaOriginTypeStore, IMediaOriginTypeState } from './media-origin-type.store';

@Injectable({ providedIn: 'root' })
export class MediaOriginTypeQuery extends QueryEntity<IMediaOriginTypeState, IMediaOriginType> {
  constructor(protected store: MediaOriginTypeStore) {
    super(store);
  }

  getTypeForId(id: number) {
    return this.getEntity(id);
  }

  getTypeForName(name: string) {
    return this.getAll().find((mediaOriginType) => mediaOriginType.name === name);
  }
}
