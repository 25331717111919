<app-doenkids-menu-bar> </app-doenkids-menu-bar>

<div class="page-container">
  <div class="message">
    <h1 class="title">{{ 'home.title' | translate }}</h1>

    <h2 class="subtitle">{{ 'home.subtitle' | translate }}</h2>

    <div class="content" *ngIf="(countryCode$ | async)?.toLowerCase() as countryCode" [innerHTML]="'home.content.intro_' + countryCode | translate"></div>
  </div>

  <div class="newsfeed-container" fxLayout="column">
    <div fxLayoutAlign="space-between center fxFill" class="news-header">
      <h3>{{ 'home.news_items.title' | translate }}</h3>
      <mat-slide-toggle [formControl]="unreadItemsOnlyControl">{{ 'home.news_items.unread_only_toggle' | translate }}</mat-slide-toggle>
    </div>

    <div class="newsfeed-wrapper">
      <app-newsfeed [hideReadItems]="unreadItemsOnly" (doneLoading)="doneLoading()" #newsFeed></app-newsfeed>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
