<div fxLayout="row" fxLayoutAlign="space-between start">
  <h2 mat-dialog-title>{{ title }}</h2>
  <button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<section class="checkbox-section">
  <mat-radio-group [formControl]="documentType">
    <mat-radio-button class="checkbox" value="pdf" [checked]="true">
      {{ 'generic.pdf' | translate | uppercase }}
    </mat-radio-button>
    <mat-radio-button *ngIf="programDownloadType.value === 'material'" class="checkbox" value="excel">{{ 'generic.excel' | translate | titlecase }}</mat-radio-button>
  </mat-radio-group>
</section>

<div *ngIf="!loading" mat-dialog-content>
  <p>{{ description }}</p>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'download_program_pdf_dialog.type.label' | translate}}</mat-label>
    <mat-select [formControl]="programDownloadType">
      <mat-option *ngFor="let typeOption of downloadOptions" [value]="typeOption.value">
        {{ typeOption.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="getCheckboxOptionsOfDownloadOption() as checkboxOptions">
    <div fxLayout="row wrap" *ngFor="let checkboxOption of checkboxOptions">
      <mat-checkbox color="primary" [formControl]="checkboxOption.control">
        <p>{{ checkboxOption.label | translate }}</p>
      </mat-checkbox>
    </div>
  </ng-container>
  <ng-container *ngIf="getSelectOptionsOfDownloadOption() as selectOptions">
    <mat-form-field appearance="outline">
      <mat-label>{{ selectOptions.label | translate }}</mat-label>
      <mat-select [formControl]="selectOptions.control" [multiple]="selectOptions.multipleSelection">
        <mat-option *ngIf="(selectOptions.options.length === 0) && selectOptions.allOptionsLabel" value="">
          {{ selectOptions.allOptionsLabel | translate }}
        </mat-option>
        <mat-option *ngFor="let selectOption of selectOptions.options" [value]="selectOption.id">
          {{ selectOption.name || selectOption.display_name || selectOption.filename }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error *ngIf="selectOptions.control.hasError('invalid') || selectOptions.control.hasError('required')">
      {{ selectOptions.invalidLabel | translate }}
    </mat-error>
    <mat-checkbox color="primary" *ngIf="selectOptions.addSelectAllOption && selectOptions.options.length !== 0" [formControl]="selectAllSelectOptionsControl">
      <span>{{ 'download_program_pdf_dialog.deselect_all' | translate }}</span>
    </mat-checkbox>
  </ng-container>
</div>
<div *ngIf="loading" mat-dialog-content>
  <p>{{ 'download_program_pdf_dialog.loading' | translate }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button [disabled]="loading" (click)="onNoClick()">
    {{ 'generic.cancel' | translate | uppercase }}
  </button>
  <div fxLayout="row">
    <button
      mat-button
      color="primary"
      (click)="confirm()"
      [disabled]="loading || hasNoActivitiesSelected()"
      cdkFocusInitial
    >
      {{ 'generic.ok' | translate }}
    </button>
    <mat-progress-spinner
      diameter="30"
      *ngIf="loading"
      color="primary"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</div>
