import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { isNil, intersection, size } from 'lodash';
import { filter } from 'rxjs/operators';
import { IUserPermission } from 'typings/api-customer';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { OrganizationUnitPermissionsService } from 'src/api/customer/organization-unit-permissions/organization-unit-permissions.service';
import { IOrganizationUnitPermission } from 'typings/doenkids/doenkids';
import { DoenkidsSessionProvider } from './session.provider';

@Injectable({
  providedIn: 'root',
})
export class PermissionProvider {
  public isDoenkidsManagement$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasOUWritePermissions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasParentOUWritePermissions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasCustomerSymbolsPermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasTemplateOptionsPermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasTranslationPermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasActivityCopyPermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasProgramTemplateCreatePermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasProgramTemplateCreatePermissionInOrganizationTree$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasKonnectIntegrationPermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public canSetKonnectIntegrationSettingsPermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public hasDisableActivityCreatePermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public listOfUserPermissionsThatCanCreateTemplates$: BehaviorSubject<IUserPermission[]> = new BehaviorSubject([]);

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasJibbieIntegrationPermission$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasActiveUsersEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasAnalyticsPermission$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasNewsItemPermission$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasCustomRecommendationPermission$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public hasProgramExplanationPermission$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public canSeeUnpublishedProgramCountPermission$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public manageReviewsPermission$ = new BehaviorSubject(false);

  constructor(private $session: DoenkidsSessionProvider, private organizationUnitPermissionService: OrganizationUnitPermissionsService) {
    // listen to get user for when the user changed and if the role of the user changes
    //
    combineLatest([$session.getUser$, $session.isAdmin$]).subscribe(([authenticatedUser, isAdmin]) => {
      if (!isNil(authenticatedUser) && !isNil(isAdmin)) {
        const isDoenkidsManagement = authenticatedUser.rootOrganizationUnit.id === DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER && isAdmin;

        this.isDoenkidsManagement$.next(isDoenkidsManagement);
      } else {
        this.isDoenkidsManagement$.next(false);
      }
    });

    // listen to changes in the OU and permissions to see which permissions the ou has
    //
    combineLatest([this.$session.getOrganizationUnit$, this.$session.userPermissions$])
      .pipe(filter(([organization, userPermissions]) => !isNil(organization) && !isNil(userPermissions)))
      .subscribe(async ([organization, userPermissions = []]) => {
        const isAdmin = await firstValueFrom(this.$session.isAdmin$);

        this.hasOUWritePermissions$.next(await this.checkOUWritePermission(organization.id, userPermissions, isAdmin));

        // if we have a parent ou check the write permission for it
        // if we don't have a parent we are a root node and you implicitly have the permission
        //
        if (organization.parent_organization_unit_id) {
          this.hasParentOUWritePermissions$.next(await this.checkOUWritePermission(organization.parent_organization_unit_id, userPermissions, isAdmin));
        } else {
          this.hasParentOUWritePermissions$.next(true);
        }

        // We need to map the node_path as the typing is wrong. It is a string[] instead of a number[]
        //
        const allOuIds = organization.node_path.map((value) => parseInt(value.toString(), 10));
        const allOuWithWritePermissions = userPermissions.filter((role) => role.permission === 'WRITE');
        const allCustomerOuWithWritePermissions = allOuWithWritePermissions.filter(
          (role) => role.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_CUSTOMER,
        );
        const allCustomerOuIdsWithWritePermissions = allCustomerOuWithWritePermissions.map((role) => role.organization_unit_id);
        const allLocationOuWithWritePermissions = allOuWithWritePermissions.filter(
          (role) => role.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION,
        );
        const allLocationOuIdsWithWritePermissions = allLocationOuWithWritePermissions.map((role) => role.organization_unit_id);

        if (!isAdmin) {
          this.hasCustomerSymbolsPermission$.next(this.checkOUCustomerSymbolPermission(organization.id, userPermissions, isAdmin));
          this.hasTemplateOptionsPermission$.next(this.checkOUTemplateOptionsPermission(organization.id, userPermissions, isAdmin));
          this.hasTranslationPermission$.next(this.checkOUTranslatePermission(organization.id, userPermissions, isAdmin));
          this.hasActivityCopyPermission$.next(this.checkOUActivityCopyPermission(organization.id, userPermissions, isAdmin));
          this.hasProgramTemplateCreatePermission$.next(this.checkOUProgramTemplateCreatePermission(organization.id, userPermissions, isAdmin));

          // Any trickle down permission we add below this line as we will need to check all parents for the permission
          //
          this.hasProgramTemplateCreatePermissionInOrganizationTree$.next(
            this.checkOUProgramTemplateCreatePermissionInOrganizationTree(allOuIds, userPermissions, isAdmin),
          );

          // Only admin's can set Konnect settings
          //
          this.canSetKonnectIntegrationSettingsPermission$.next(false);
          this.hasKonnectIntegrationPermission$.next(this.checkOUKonnectIntegrationPermission(allOuIds, userPermissions, isAdmin));
          this.hasDisableActivityCreatePermission$.next(this.checkOUDisableActivityCreatePermission(allOuIds, userPermissions, isAdmin));
          this.listOfUserPermissionsThatCanCreateTemplates$.next(
            userPermissions.filter((role) => role.program_template_create === true && role.permission === 'WRITE'),
          );
          this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.next(size(intersection(allCustomerOuIdsWithWritePermissions, allOuIds)) > 0);
          this.hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$.next(size(intersection(allLocationOuIdsWithWritePermissions, allOuIds)) > 0);
          this.hasJibbieIntegrationPermission$.next(this.checkOUJibbieIntegrationPermission(allOuIds, userPermissions, isAdmin));
          this.hasActiveUsersEnabled$.next(this.checkActiveUsersEnabled(allOuIds, userPermissions, isAdmin));
          this.hasAnalyticsPermission$.next(this.checkAnalyticsPermission(allOuIds, userPermissions, isAdmin));
          this.hasNewsItemPermission$.next(this.checkNewsItemPermission(allOuIds, userPermissions, isAdmin));
          this.hasCustomRecommendationPermission$.next(this.checkCustomRecommendationsPermission(allOuIds, userPermissions, isAdmin));
          this.hasProgramExplanationPermission$.next(this.checkProgramExplanationPermission(allOuIds, userPermissions, isAdmin));
          this.canSeeUnpublishedProgramCountPermission$.next(this.checkUnpublishedProgramCountPermission(allOuIds, userPermissions, isAdmin));
          this.manageReviewsPermission$.next(this.checkManageReviewsPermission(organization.id, userPermissions, isAdmin));
        } else {
          const permissionOverview = await this.organizationUnitPermissionService.getOverview(organization.id);
          this.hasCustomerSymbolsPermission$.next(this.checkOUCustomerSymbolPermission(organization.id, permissionOverview.appliedPermissions, isAdmin));
          this.hasTemplateOptionsPermission$.next(this.checkOUTemplateOptionsPermission(organization.id, permissionOverview.appliedPermissions, isAdmin));
          this.hasTranslationPermission$.next(this.checkOUTranslatePermission(organization.id, permissionOverview.appliedPermissions, isAdmin));
          this.hasActivityCopyPermission$.next(this.checkOUActivityCopyPermission(organization.id, permissionOverview.appliedPermissions, isAdmin));
          this.hasProgramTemplateCreatePermission$.next(
            this.checkOUProgramTemplateCreatePermission(organization.id, permissionOverview.appliedPermissions, isAdmin),
          );
          // Any trickle down permission we add below this line as we will need to check all parents for the permission
          //
          this.hasProgramTemplateCreatePermissionInOrganizationTree$.next(
            this.checkOUProgramTemplateCreatePermissionInOrganizationTree([], permissionOverview.appliedPermissions, isAdmin),
          );
          this.hasKonnectIntegrationPermission$.next(this.checkOUKonnectIntegrationPermission([], permissionOverview.appliedPermissions, isAdmin));
          this.canSetKonnectIntegrationSettingsPermission$.next(permissionOverview?.permissions?.konnect_integration);
          this.hasDisableActivityCreatePermission$.next(this.checkOUDisableActivityCreatePermission([], permissionOverview.appliedPermissions, isAdmin));
          this.listOfUserPermissionsThatCanCreateTemplates$.next([]);
          this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.next(true);
          this.hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$.next(true);
          this.hasJibbieIntegrationPermission$.next(this.checkOUJibbieIntegrationPermission([], permissionOverview.appliedPermissions, isAdmin));
          this.hasActiveUsersEnabled$.next(organization.id === 1 ? true : this.checkActiveUsersEnabled([], permissionOverview.appliedPermissions, isAdmin));
          this.hasAnalyticsPermission$.next(
            organization.id === 1 ? true : this.checkAnalyticsPermission([], permissionOverview.appliedPermissions, isAdmin),
          );
          this.hasNewsItemPermission$.next(
            organization.id === 1 ? true : this.checkNewsItemPermission([], permissionOverview.appliedPermissions, isAdmin),
          );
          this.hasCustomRecommendationPermission$.next(this.checkCustomRecommendationsPermission([], permissionOverview.appliedPermissions, isAdmin));
          this.hasProgramExplanationPermission$.next(this.checkProgramExplanationPermission([], permissionOverview.appliedPermissions, isAdmin));
          this.canSeeUnpublishedProgramCountPermission$.next(this.checkUnpublishedProgramCountPermission([], permissionOverview.appliedPermissions, isAdmin));
          this.manageReviewsPermission$.next(organization.id === 1 ? true : this.checkManageReviewsPermission(organization.id, permissionOverview.appliedPermissions, isAdmin));
        }
      });
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkNewsItemPermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin?: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions = currentPermissions as IOrganizationUnitPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasNewsItemPermission = OUPermissions[0]?.news_item_create === true;

        allOUPermissions.push(ouHasNewsItemPermission);
      });
    } else {
      const ouHasNewsItemPermission = (currentPermissions as IOrganizationUnitPermission)?.news_item_create === true;

      allOUPermissions.push(ouHasNewsItemPermission);
    }

    return allOUPermissions.includes(true);
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkAnalyticsPermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin?: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions = currentPermissions as IOrganizationUnitPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasAnalyticsPermission = OUPermissions[0]?.analytics === true;

        allOUPermissions.push(ouHasAnalyticsPermission);
      });
    } else {
      const ouHasAnalyticsPermission = (currentPermissions as IOrganizationUnitPermission)?.analytics === true;

      allOUPermissions.push(ouHasAnalyticsPermission);
    }

    return allOUPermissions.includes(true);
  }

  checkManageReviewsPermission(ouId: number, currentPermissions?: IUserPermission[] | IOrganizationUnitPermission, isAdmin?: boolean) {
    let hasManageReviewsPermission = false;
    if (!isAdmin) {
      // this filter should only give 1 row back
      //
      const OUPermissions = (currentPermissions as IUserPermission[]).filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
      // TODO: remove type cast to any when typings are synced
      //
      hasManageReviewsPermission = OUPermissions[0]?.permission === 'WRITE' && (OUPermissions[0] as any)?.manage_reviews === true;
    } else {
      hasManageReviewsPermission = (currentPermissions as IOrganizationUnitPermission)?.manage_reviews === true;
    }

    return hasManageReviewsPermission;
  }

  async checkOUWritePermission(ouId: number, currentPermissions?: IUserPermission[], isAdmin?: boolean) {
    let hasWritePermission: boolean;

    if (isNil(currentPermissions)) {
      currentPermissions = await firstValueFrom(this.$session.userPermissions$
        .pipe(filter((value) => !isNil(value))));
    }

    if (isNil(isAdmin)) {
      isAdmin = await firstValueFrom(this.$session.isAdmin$
        .pipe(
          filter((value) => !isNil(value))));
    }

    if (!isAdmin) {
      // this filter should only give 1 row back
      //
      const OUPermissions = currentPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
      hasWritePermission = !isNil(OUPermissions.find((ouPermission) => ouPermission.permission === 'WRITE'));
    } else {
      hasWritePermission = true;
    }

    return hasWritePermission;
  }

  checkActiveUsersEnabled(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions: IUserPermission[] = currentPermissions as IUserPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        // TODO not cast the permission as any but as typings are not synced it will have to do for now
        //
        const ouHasEanbleActiveUsersPermission = (OUPermissions[0] as any)?.enable_active_users === true;

        allOUPermissions.push(ouHasEanbleActiveUsersPermission);
      });
    } else {
      const ouHasActiveUsersEnabled = (currentPermissions as IOrganizationUnitPermission)?.enable_active_users === true;
      allOUPermissions.push(ouHasActiveUsersEnabled);
    }
    return allOUPermissions.includes(true);
  }

  checkOUCustomerSymbolPermission(ouId: number, currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    let hasCustomerSymbolPermission: boolean;

    if (!isAdmin) {
      // this filter should only give 1 row back
      //
      const OUPermissions = (currentPermissions as IUserPermission[]).filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
      hasCustomerSymbolPermission = OUPermissions[0]?.customer_symbols === true;
    } else {
      hasCustomerSymbolPermission = (currentPermissions as IOrganizationUnitPermission).customer_symbols === true;
    }

    return hasCustomerSymbolPermission;
  }

  checkOUTemplateOptionsPermission(ouId: number, currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    let hasTemplateOptionsPermission: boolean;

    if (!isAdmin) {
      // this filter should only give 1 row back
      //
      const OUPermissions = (currentPermissions as IUserPermission[]).filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
      hasTemplateOptionsPermission = OUPermissions[0]?.template_settings === true;
    } else {
      hasTemplateOptionsPermission = (currentPermissions as IOrganizationUnitPermission).template_settings === true;
    }

    return hasTemplateOptionsPermission;
  }

  checkOUTranslatePermission(ouId: number, currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    let hasTranslatePermission: boolean;

    if (!isAdmin) {
      // this filter should only give 1 row back
      //
      const OUPermissions = (currentPermissions as IUserPermission[]).filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
      hasTranslatePermission = OUPermissions[0]?.i18n_translate === true;
    } else {
      hasTranslatePermission = (currentPermissions as IOrganizationUnitPermission).i18n_translate === true;
    }

    return hasTranslatePermission;
  }

  checkOUActivityCopyPermission(ouId: number, currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    let activityCopyPermission = false;

    if (!isAdmin) {
      // this filter should only give 1 row back
      //
      const OUPermissions = (currentPermissions as IUserPermission[]).filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
      activityCopyPermission = OUPermissions[0]?.activity_copy === true;
    } else {
      const ouHasActivityCopyPermission = (currentPermissions as IOrganizationUnitPermission)?.activity_copy === true;
      activityCopyPermission = ouHasActivityCopyPermission;
    }

    return activityCopyPermission;
  }

  checkOUProgramTemplateCreatePermission(ouId: number, currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    let programCreatePermission = false;
    if (!isAdmin) {
      // this filter should only give 1 row back
      //
      const OUPermissions = (currentPermissions as IUserPermission[]).filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
      programCreatePermission = !isNil(OUPermissions.find((ouPermission) => ouPermission.permission === 'WRITE' && ouPermission.program_template_create === true));
    } else {
      programCreatePermission = (currentPermissions as IOrganizationUnitPermission)?.program_template_create === true;
    }

    return programCreatePermission;
  }

  checkOUProgramTemplateCreatePermissionInOrganizationTree(
    ouIds: number[],
    currentPermissions: IUserPermission[] | IOrganizationUnitPermission,
    isAdmin: boolean,
  ) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions: IUserPermission[] = currentPermissions as IUserPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasProgramTemplatePermission = !isNil(OUPermissions.find((ouPermission) => ouPermission.permission === 'WRITE' && ouPermission.program_template_create === true));

        allOUPermissions.push(ouHasProgramTemplatePermission);
      });
    } else {
      const ouHasProgramTemplateCreatePermission = (currentPermissions as IOrganizationUnitPermission)?.program_template_create === true;

      allOUPermissions.push(ouHasProgramTemplateCreatePermission);
    }

    return allOUPermissions.includes(true);
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkOUKonnectIntegrationPermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions: IUserPermission[] = currentPermissions as IUserPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasKonnectIntegrationPermission = OUPermissions[0]?.konnect_integration === true;

        allOUPermissions.push(ouHasKonnectIntegrationPermission);
      });
    } else {
      const ouHasKonnectIntegrationPermission = (currentPermissions as IOrganizationUnitPermission)?.konnect_integration === true;

      allOUPermissions.push(ouHasKonnectIntegrationPermission);
    }

    return allOUPermissions.includes(true);
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkOUDisableActivityCreatePermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions: IUserPermission[] = currentPermissions as IUserPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasDisableActivityCreatePermission = OUPermissions[0]?.disable_activity_create === true;

        allOUPermissions.push(ouHasDisableActivityCreatePermission);
      });
    } else {
      const ouHasDisableActivityCreatePermission = (currentPermissions as IOrganizationUnitPermission)?.disable_activity_create === true;

      allOUPermissions.push(ouHasDisableActivityCreatePermission);
    }

    return allOUPermissions.includes(true);
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkOUJibbieIntegrationPermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions: IUserPermission[] = currentPermissions as IUserPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasJibieIntegrationPermission = (OUPermissions[0])?.jibbie_integration === true;

        allOUPermissions.push(ouHasJibieIntegrationPermission);
      });
    } else {
      const ouHasJibieIntegrationPermission = (currentPermissions as IOrganizationUnitPermission)?.jibbie_integration === true;

      allOUPermissions.push(ouHasJibieIntegrationPermission);
    }

    return allOUPermissions.includes(true);
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkCustomRecommendationsPermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin?: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions = currentPermissions as IOrganizationUnitPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasNewsItemPermission = OUPermissions[0]?.custom_recommendations === true;

        allOUPermissions.push(ouHasNewsItemPermission);
      });
    } else {
      const ouHasNewsItemPermission = (currentPermissions as IOrganizationUnitPermission)?.custom_recommendations === true;

      allOUPermissions.push(ouHasNewsItemPermission);
    }

    return allOUPermissions.includes(true);
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkProgramExplanationPermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin?: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions = currentPermissions as IOrganizationUnitPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasProgramExplanationPermission = OUPermissions[0]?.can_add_program_explanations === true;

        allOUPermissions.push(ouHasProgramExplanationPermission);
      });
    } else {
      const ouHasProgramExplanationPermission = (currentPermissions as IOrganizationUnitPermission)?.can_add_program_explanations === true;

      allOUPermissions.push(ouHasProgramExplanationPermission);
    }

    return allOUPermissions.includes(true);
  }

  /**
   * Because this is a trickle down permission a parent can have the permission and implicitly that would also give the child the permission
   * Therefore we check all ou's of the node path for a specific ou and if any one of them has the permission we return a truthy value.
   * or if you are a admin you obviously also have the permission
   */
  checkUnpublishedProgramCountPermission(ouIds: number[], currentPermissions: IUserPermission[] | IOrganizationUnitPermission, isAdmin?: boolean) {
    const allOUPermissions = [];

    if (!isAdmin) {
      const userPermissions = currentPermissions as IOrganizationUnitPermission[];
      ouIds.forEach((ouId: number) => {
        // this filter should only give 1 row back
        //
        const OUPermissions = userPermissions.filter((permission: IUserPermission) => permission.organization_unit_id === ouId);
        const ouHasUnpublishedProgramCountPermission = OUPermissions[0]?.can_see_unpublished_program_count === true;

        allOUPermissions.push(ouHasUnpublishedProgramCountPermission);
      });
    } else {
      const ouHasUnpublishedProgramCountPermission = (currentPermissions as IOrganizationUnitPermission)?.can_see_unpublished_program_count === true;

      allOUPermissions.push(ouHasUnpublishedProgramCountPermission);
    }

    return allOUPermissions.includes(true);
  }
}
