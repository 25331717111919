import { Injectable } from '@angular/core';
import { IProgramTemplateBundleAttachmentListResponse, IProgramTemplateBundleAttachmentMediaListResponse } from 'typings/api-media';
import { IServiceResponse } from 'typings/api-generic';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ProgramTemplateBundleAttachmentStore } from './program-template-bundle-attachment.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleAttachmentService {
  // Even though they're not used anymore, keep the store and query imports here. Otherwise, the decorators will NOT work.
  //
  constructor(
    private store: ProgramTemplateBundleAttachmentStore,
    private $baseApi: DoenKidsGenericApiProvider,
  ) {}

  async fetchAll({
    programTemplateBundleId,
    limit = 100,
    skip = 0,
    sortField,
    sortDirection,
  }: {
    programTemplateBundleId: number,
    limit?: number,
    skip?: number,
    sortField?: string,
    sortDirection?: string,
  }) {
    const response = await firstValueFrom(this.$baseApi.genericListCall('/media/program-template/attachment', limit, skip, sortField, sortDirection,
      { programTemplateBundleId: programTemplateBundleId })) as IProgramTemplateBundleAttachmentListResponse;
    this.store.set(response?.items ?? []);
    return response?.items ?? [];
  }

  async fetchAttachmentsWithMedia({
    programTemplateBundleId,
    limit = 100,
    skip = 0,
    sortField,
    sortDirection,
  }: {
    programTemplateBundleId: number,
    limit?: number,
    skip?: number,
    sortField?: string,
    sortDirection?: string,
  }) {
    const response = await firstValueFrom(this.$baseApi.genericListCall(`/media/program-template-bundle/attachment/${programTemplateBundleId}`, limit, skip, sortField, sortDirection,
    )) as IProgramTemplateBundleAttachmentMediaListResponse;
    return response?.items ?? [];
  }

  update(programTemplateId, attachmentId, name): Promise<IServiceResponse> {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/media/program-template-bundle/attachment/${programTemplateId}/attachment/${attachmentId}?name=${name}`, {},
    )) as Promise<IServiceResponse>;
  }

  delete(programTemplateId, attachmentId): Promise<IServiceResponse> {
    return firstValueFrom(this.$baseApi.genericDeleteCall(
      `/media/program-template-bundle/attachment/${programTemplateId}/attachment/${attachmentId}`,
    )) as Promise<IServiceResponse>;
  }

  updateDisplayName(programTemplateId, attachmentId, name) {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/media/program-template-bundle/attachment/${programTemplateId}/attachment/${attachmentId}/display-name?name=${name}`, {},
    )) as Promise<IServiceResponse>;
  }
}
