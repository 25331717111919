<ng-container *ngIf="!isTheOwner && (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)">
  <ng-container *ngIf="owner$ | async as owner">
    <ng-container *ngIf="hasOwnerWritePermission$ | async">
      <button
        mat-stroked-button
        (click)="switchToOwner()"
        [matTooltip]="
          'owner_chip.switch.tooltip'
            | translate
              : {
                  organizationUnitType: owner.organization_unit_type_id | ou_type_name | translate
                }
        "
        class="status-chip customer-chip"
      >
        <mat-icon>vpn_key</mat-icon>&nbsp;{{ owner.name }}
      </button>
    </ng-container>
    <ng-container *ngIf="!(hasOwnerWritePermission$ | async)">
      <mat-chip class="status-chip customer-chip"> <mat-icon>vpn_key</mat-icon> {{ owner.name }} </mat-chip>
    </ng-container>
  </ng-container>
</ng-container>
