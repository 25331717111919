import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnit } from 'typings/doenkids/doenkids';
import { OrganizationUnitListStore, IOrganizationUnitListState } from './organization-unit-list.store';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitListQuery extends QueryEntity<IOrganizationUnitListState, IOrganizationUnit> {
  constructor(protected store: OrganizationUnitListStore) {
    super(store);
  }
}
