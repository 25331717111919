<div fxFlex fxLayoutAlign="space-between center">
  <h1>{{ 'review_overview.title' | translate }}</h1>
  <mat-chip-listbox *ngIf="(canCreateReview$ | async) && !(reviewsLoading$ | async)">
    <mat-chip-option (click)="newReview()">{{ 'review_overview.write_review' | translate }}</mat-chip-option>
  </mat-chip-listbox>
</div>
<div fxFlex fxLayoutAlign="space-between center" class="review-summary" *ngVar="(reviewOverview$ | async) as overview">
  <ng-container *showSpinner="!overview && overviewLoading$ | async">
    <ng-container *ngIf="overview">
      <div fxLayout="column" fxLayoutAlign="space-between start" class="summary-rating">
        <h1>{{ (overview.review_score / 20) | i18n_number: '1.1-1' | async }}</h1>
        <app-review-rating [value]="overview.review_score" [editable]="false"></app-review-rating>
        <span *ngIf="( totalReviewCount$ | async ) as totalReviewCount">
          {{ 'review_overview.review_count' | translate: { totalReviewCount } }}
        </span>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between start" class="summary-lines">
        <div class="rating-line" fxFlex fxLayoutGap="8px" fxLayoutAlign="space-between center" *ngFor="let division of reviewDivision$ | async; index as i"
          [matTooltip]="division.tooltip">
          <app-review-rating height="15px" [editable]="false" [value]="(5 - i) * 20"></app-review-rating>
          <div class="rating-bar">
            <span [style.--order]="i" class="rating" [style.width.%]="division.percentage"></span>
          </div>
          <span>{{division.count}}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<div [class.full-overview]="!preview" (scroll)="onScroll($event)">
  <ng-container *ngIf="(reviews$ | async) as reviews">
    <ng-container *showSpinner="reviewsLoading$ | async">
      <ng-container *noContent="!(reviews?.length > 0); title: 'review_overview.no_content' | translate">
        <app-review (delete)="reviewDeleted($event)" [style.--order]="(i + 1) % 5" *ngFor="let review of (reviews$ | async); index as i" [review]="review"></app-review>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<button *ngIf="preview && (metaData$ | async).total > pageSize" mat-flat-button color="primary" (click)="viewAll()">
  {{ 'review_overview.view_all' | translate }}
</button>
