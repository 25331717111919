import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { ProgramTemplateStatusQuery } from 'src/api/activity/program-template-status/program-template-status.query';
import { ProgramTemplateStatusService } from 'src/api/activity/program-template-status/program-template-status.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { IProgramTemplateStatus } from 'typings/doenkids/doenkids';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { EProgramStatus, getProgramStatusName } from '../../pages/program-details/program-details.component';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-program-template-status',
  templateUrl: './program-template-status.component.html',
  styleUrls: ['./program-template-status.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramTemplateStatusComponent implements OnInit {
  @Input() currentStatus: number;

  @Input() additionalStatuses?: IProgramTemplateStatus[];

  @Input() hasWriteAccess: boolean;

  @Output() change: EventEmitter<IProgramTemplateStatus> = new EventEmitter<IProgramTemplateStatus>();

  public allStatuses$: Observable<IProgramTemplateStatus[]>;

  public hasOUWritePermissions$: Observable<boolean>;

  protected readonly EProgramStatus = EProgramStatus;

  public programTemplateStatus: UntypedFormControl = new UntypedFormControl('', []);

  changePublicationStatus(status: IProgramTemplateStatus) {
    if (isNil(this.hasWriteAccess) || this.hasWriteAccess) {
      this.change.emit(status);
    }
  }

  constructor(
    programTemplateStatusQuery: ProgramTemplateStatusQuery,
    private programTemplateStatusService: ProgramTemplateStatusService,
    $permission: PermissionProvider,
  ) {
    this.allStatuses$ = programTemplateStatusQuery.selectAll();

    this.hasOUWritePermissions$ = $permission.hasOUWritePermissions$;
  }

  ngOnInit() {
    this.programTemplateStatusService.fetchAll();

    if (!isNil(this.hasWriteAccess) && !this.hasWriteAccess) {
      this.programTemplateStatus.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentStatus) {
      this.programTemplateStatus.setValue(this.currentStatus);
    }

    if (changes.hasWriteAccess) {
      const currentHasWriteAccess = changes.hasWriteAccess.currentValue;
      if (!isNil(currentHasWriteAccess) && !currentHasWriteAccess) {
        this.programTemplateStatus.disable();
      } else if (isNil(currentHasWriteAccess) || currentHasWriteAccess) {
        this.programTemplateStatus.enable();
      }
    }
  }

  protected getProgramStatusName(programStatusId: EProgramStatus): string {
    return getProgramStatusName(programStatusId);
  }
}
