import {
  ChangeDetectorRef, OnDestroy, Pipe, PipeTransform,
} from '@angular/core';
import { snakeCase } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '../app/utils/translate.service';
import { fallbackIsHandledParam } from 'src/app/utils/logging-missing-translation-handler';

@Pipe({
  name: 'doenkidsTranslate',
  pure: false,
})
export class DoenKidsTranslatePipe implements PipeTransform, OnDestroy {
  private stop$ = new Subject<void>();

  private storedInitialValue: string;

  private storedInitialPrefix: string;

  private storedTransformedTranslation: string;

  constructor(
    private $translate: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    this.$translate.onLangOrTranslationChange$.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (this.storedInitialValue && this.storedInitialPrefix) {
        this.parseTranslation(this.storedInitialValue, this.storedInitialPrefix);
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  parseTranslation(value: string, prefix: string) {
    this.storedInitialValue = value;
    this.storedInitialPrefix = prefix;
    const translationKey = `${this.storedInitialPrefix}.${snakeCase(this.storedInitialValue)}`;
    try {
      const translation = this.$translate.instant(translationKey, fallbackIsHandledParam);
      if (translation !== value && translation !== translationKey) {
        this.storedTransformedTranslation = translation;
      } else {
        this.storedTransformedTranslation = this.storedInitialValue;
      }
    } catch (e) {
      // this catches the missing translations error.
      // if the item is not known to us just show the original value
      // so that the user at least sees something. If it's wrong it will probably be reported
      // and we can add it to the translation files
      //
      this.storedTransformedTranslation = this.storedInitialValue;
    }
    this.cd.markForCheck();
  }

  transform(value: string, prefix: string) {
    if (value == null) {
      return '';
    }

    if (this.storedInitialValue !== value || this.storedInitialPrefix !== prefix) {
      this.parseTranslation(value, prefix);
    }

    return this.storedTransformedTranslation;
  }
}
