<app-doenkids-menu-bar [loading]="isSearching$ | async" [backUrl]="backUrl$ | async">
  <div left>
    <app-activity-search-bar [query]="currentSearchParams?.query" (searched)="doSearch($event)"></app-activity-search-bar>
  </div>
</app-doenkids-menu-bar>
<div class="page-container" #pageContainer>
  <app-split-page
    #splitPage
    [leftLabel]="'activity_search.tab.filters' | translate"
    [rightLabel]="'activity_search.tab.results' | translate"
    [activeTab]="1"
    [leftWidth]="30"
    [scrollEventsRight]="true"
    [hide]="hideAggregations$ | async"
    sideToHide="left"
    whatToHide="side"
    (scrollEndRight)="onScrollEnd()"
    (selectedTabChange)="tabChange($event)"
  >
    <ng-template #left>
      <div class="aggregations" *ngIf="baseAggregations$ | async">
        <app-search-aggregations
          [aggregationLabels]="aggregationLabels | async"
          [aggregationData]="baseAggregations$ | async"
          [formControl]="aggregationControl"
        >
        </app-search-aggregations>
      </div>
    </ng-template>
    <ng-template #right>
      <div class="results-panel">
        <div class="results-header" #resultsHeader>
          <div class="results-header-wrapper" [class.hasFilters]="containsAggregations$ | async">
            <mat-toolbar class="mat-toolbar-doenkids">
              <button mat-icon-button (click)="toggleAggregations()" *ngIf="!(isSmall$ | async)">
                <mat-icon *ngIf="!(hideAggregations$ | async)">chevron_left</mat-icon>
                <mat-icon *ngIf="hideAggregations$ | async">chevron_right</mat-icon>
              </button>
              <div class="result-amount" *ngIf="baseActivitySearchMetadata$ | async as metaData">
                <ng-container *ngIf="metaData?.total > 0">
                  <span
                    [innerHTML]="'activity_search.search_metadata' | translate: {
                      resultCount: metaData?.total,
                    }"
                  >
                  </span>
                </ng-container>
                <span class="did-you-mean" *ngFor="let suggestion of baseSearchSuggestions$ | async">
                  <ng-container *ngIf="suggestion.options && suggestion.options.length > 0">
                    <span>{{ 'activity_search.suggestions' | translate }}&nbsp;</span>
                    <a *ngFor="let option of suggestion.options" href="#" (click)="trySuggestion(option.text, $event)" [innerHTML]="option.highlighted"></a>
                    <span>?</span>
                  </ng-container>
                </span>
              </div>
              <div class="spacer"></div>
            </mat-toolbar>
            <ng-container *ngIf="containsAggregations$ | async">
              <mat-toolbar class="mat-toolbar-doenkids mat-toolbar-chips">
                <app-search-chips
                  [aggregationLabels]="aggregationLabels | async"
                  (onRemoveAll)="removeAllFilters()"
                  (onRemove)="removeFilter($event)"
                  [aggregationData]="baseAggregations$ | async"
                  [selection]="aggregationControl.value"
                >
                </app-search-chips>
              </mat-toolbar>
            </ng-container>
          </div>
        </div>

        <div class="search-results">
          <ng-container *ngIf="baseSearchResults$ | async as results">
            <div class="body-container animated fadeIn">
              <ngx-masonry *ngIf="results.length > 0" [options]="{ gutter: 25, transitionDuration: '0s', fitWidth: true, horizontalOrder: true }">
                <ngxMasonryItem class="masonry-item" *ngFor="let activity of results">
                  <app-activity-card (loaded)="imageLoaded()" (click)="activityClicked(activity)" [activity]="activity"> </app-activity-card>
                </ngxMasonryItem>
              </ngx-masonry>
            </div>
            <div *ngIf="(baseSearchResults$ | async).length === 0">
              {{ 'activity_search.no_results' | translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </app-split-page>
</div>
<router-outlet></router-outlet>
