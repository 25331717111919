import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ILocation } from 'typings/doenkids/doenkids';
import { LocationStore, ILocationState } from './location.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocationQuery extends QueryEntity<ILocationState, ILocation> {
  constructor(protected store: LocationStore) {
    super(store);
  }

  getLocationForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((locations) => locations.filter((location) => location.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }
}
