import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ISearchActivity } from 'typings/doenkids/doenkids';
import { IBaseActivitySearchState, BaseActivitySearchStore } from './base-activity-search.store';
import { Observable } from 'rxjs';

export interface IBaseActivitySearchMetadata {
  total: number;
  skip: number;
  limit: number;
  query: string;
  aggregations: any;
  took: any;
  suggest: any;
}
@Injectable({ providedIn: 'root' })
export class BaseActivitySearchQuery extends QueryEntity<IBaseActivitySearchState, ISearchActivity> {
  constructor(protected store: BaseActivitySearchStore) {
    super(store);
  }

  get getAggregations() {
    return this.select((value) => value.aggregations);
  }

  get getSuggestions() {
    return this.select((value) => value.suggest);
  }

  get getMetadata(): Observable<IBaseActivitySearchMetadata> {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
      query: value.query,
      aggregations: value.aggregations,
      took: value.took,
      suggest: value.suggest,
    }));
  }
}
