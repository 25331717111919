import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IPeriodSectionTypeListResponse } from 'typings/api-activity';
import { PeriodSectionTypeStore } from './period-section-type.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PeriodSectionTypeService {
  constructor(private store: PeriodSectionTypeStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection = 'desc') {
    const response = await firstValueFrom(this.$baseApi.genericListCall('/activity/period-section-type', limit, skip, sortField, sortDirection)) as IPeriodSectionTypeListResponse;
    this.store.update(response?.items ?? []);
    return response;
  }
}
