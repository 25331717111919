import {
  Component, OnInit, ExistingProvider, forwardRef, HostListener, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef,
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, NG_VALUE_ACCESSOR, UntypedFormArray, FormControl, FormGroup, AbstractControl,
} from '@angular/forms';
import {
  noop, isNil, size, get, isEqual,
} from 'lodash';
import { IMedia, IMediaItem } from 'typings/section-types';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { UploadableDirective } from 'src/directives/uploadable.directive';
import { IUploadResponse } from 'typings/custom-app-types';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { AssetService } from 'src/api/media/asset/asset.service';
import { MediaOriginTypeQuery } from 'src/api/generic/media-origin-type/media-origin-type.query';
import { IMedia as ICompleteMedia, IMediaOriginType } from 'typings/doenkids/doenkids';
import { MatDialog } from '@angular/material/dialog';
import { IReplaceImageDialogData, ReplaceImageDialogComponentComponent } from 'src/components/dialogs/replace-image-dialog-component/replace-image-dialog-component.component';

export const SECTION_IMAGE_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => ImageSectionComponent),
  multi: true,
};

@Component({
  selector: 'app-image-section',
  templateUrl: './image-section.component.html',
  styleUrls: ['./image-section.component.scss'],
  providers: [SECTION_IMAGE_CONTROL_VALUE_ACCESSOR],
})
export class ImageSectionComponent extends UploadableDirective implements OnInit, OnDestroy {
  public stop$ = new Subject<void>();

  @Input()
  public purpose?: string;

  @Input()
  public identifier: string;

  @Output()
  public changedSection = new EventEmitter<void>();

  public form: UntypedFormGroup;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: IMedia) => void = noop;

  public get mediaFormArray(): UntypedFormArray {
    return this.form.get('media') as UntypedFormArray;
  }

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  public isAdmin$: Observable<boolean>;

  public originTypeOptions$: Observable<IMediaOriginType[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private $asset: AssetService,
    private $mediaOriginType: MediaOriginTypeQuery,
    private matDialog: MatDialog,
    $session: DoenkidsSessionProvider,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.form = this.fb.group({
      title: [''],
      subtitle: [''],
      media: this.fb.array([]),
    });
    this.isAdmin$ = $session.isAdmin$;
    this.originTypeOptions$ = this.$mediaOriginType.selectAll();
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((formValue) => {
      this.onChangeCallback(formValue);
    });

    this.uploadData.uploaded.pipe(
      filter((imageItem) => !isNil(imageItem)),
      takeUntil(this.stop$),
    ).subscribe((imageItem) => {
      this.addImageItem(imageItem);
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  addImageItem(imageItem: IUploadResponse) {
    const mediaFormArray = this.form.get('media') as UntypedFormArray;

    const newGroup = this.fb.group({
      description: [get(imageItem, 'description', '')],
      caption: [get(imageItem, 'caption', '')],
      uuid: [get(imageItem, 'uuid', '')],
    });

    this.addOriginTypeControl(newGroup);

    mediaFormArray.push(newGroup);
  }

  async writeValue(value: IMedia) {
    if (value) {
      this.form.get('title').setValue(value.title || '');
      this.form.get('subtitle').setValue(value.subtitle || '');

      const mediaFormArray = this.form.get('media') as UntypedFormArray;

      for (let index = 0; index < value.media.length; index += 1) {
        const media = value.media[index];
        const control = mediaFormArray.controls[index];
        if (isNil(control) && size(media.uuid)) {
          const newGroup = this.fb.group({
            description: [get(media, 'description', '')],
            caption: [get(media, 'caption', '')],
            uuid: [get(media, 'uuid', '')],
          });

          await this.addOriginTypeControl(newGroup);

          mediaFormArray.push(newGroup);
        }
      }
    }
  }

  removeMedia(index) {
    this.mediaFormArray.removeAt(index);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  async addOriginTypeControl(group: FormGroup) {
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const uuid = group.get('uuid')?.value;

    if (isAdmin && uuid) {
      const media = await firstValueFrom(this.$asset.getByUuid(uuid));
      const mediaControl = new FormControl(media);
      const mediaType = this.$mediaOriginType.getTypeForId(media.media_origin_type_id);
      const typeControl =  new FormControl(mediaType?.id ?? 'unknown');
      group.addControl('originType', typeControl);
      group.addControl('media', mediaControl);

      this.setupOriginTypeListener(typeControl, group);
    }
  }

  setupOriginTypeListener(control: FormControl, group: FormGroup) {
    control.valueChanges.pipe(takeUntil(this.stop$), distinctUntilChanged(isEqual)).subscribe((originType) => {
      const mediaItemControl = group.get('media');
      const mediaItem = mediaItemControl?.value as ICompleteMedia;

      if (mediaItem) {
        let originTypeId = null;
        if (originType !== 'unknown') {
          const mediaOriginType = this.$mediaOriginType.getTypeForId(originType);

          if (mediaOriginType) {
            originTypeId = mediaOriginType.id;
          }
        }

        if (originTypeId !== mediaItem.media_origin_type_id) {
          const newMedia = { ...mediaItem, media_origin_type_id: originTypeId } as ICompleteMedia;
          firstValueFrom(this.$asset.update(newMedia));
          mediaItemControl.setValue(newMedia, { emitEvent: false });
        }
      }
    });
  }

  async replaceImage(mediaControl: AbstractControl) {
    const dialogRef = this.matDialog.open(ReplaceImageDialogComponentComponent, {
      maxHeight: '95vh',
      maxWidth: '600px',
      panelClass: 'image-replace-modal',
      data: {
        currentImageUuid:( mediaControl.value as IMediaItem)?.uuid,
        purpose: this.purpose,
        identifier: this.identifier,
      } as IReplaceImageDialogData,
    });

    const result: ICompleteMedia = await firstValueFrom(dialogRef.afterClosed());

    if (result) {
      mediaControl?.get('uuid')?.setValue(result.uuid);
      mediaControl.markAsDirty();
      this.changedSection.emit();
      this.cd.detectChanges();
    }
  }
}
