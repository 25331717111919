import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IActivityKind } from 'typings/doenkids/doenkids';
import { IActivityKindState, ActivityKindStore } from './activity-kind.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivityKindQuery extends QueryEntity<IActivityKindState, IActivityKind> {
  constructor(protected store: ActivityKindStore) {
    super(store);
  }

  getActivityKindForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((activityKinds) => activityKinds.filter((activityKind) => activityKind.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }
}
