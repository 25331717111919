/* eslint-disable @typescript-eslint/no-unused-vars */
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Error when invalid control is dirty, touched, or submitted.
 */
export class ShowErrorsImmediatelyErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid);
  }
}
