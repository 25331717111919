import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProgramTemplateBundle } from 'typings/doenkids/doenkids';
import {
  ProgramTemplateBundleListState, ProgramTemplateBundleListStore,
} from './program-template-bundle-list.store';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleListQuery extends QueryEntity<ProgramTemplateBundleListState, IProgramTemplateBundle> {
  constructor(protected store: ProgramTemplateBundleListStore) {
    super(store);
  }
}
