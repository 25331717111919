/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ISectionType } from 'typings/doenkids/doenkids';

export interface IProgramSectionTypeState extends EntityState<ISectionType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'period-section-type' })
export class ProgramSectionTypeStore extends EntityStore<IProgramSectionTypeState, ISectionType> {
  constructor() {
    super();
  }
}
