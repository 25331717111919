<form [formGroup]="periodSectionsForm">
  <mat-toolbar class="activities-toolbar">
    <mat-menu #addSection="matMenu">
      <ng-container *ngFor="let type of periodSectionTypes$ | async">
        <button
          (click)="appendSection(type)"
          type="button"
          mat-menu-item
        >
          <span>{{ 'program.period.section_type.' + getNameForProgramPeriodSectionType(type.id) | translate }}</span>
        </button>
      </ng-container>
    </mat-menu>
    <div fxLayout="row wrap" fxLayoutAlign="left flex-start" *ngIf="!readOnly">
      <button mat-icon-button type="button" [matMenuTriggerFor]="addSection" [matTooltip]="'program.period_sections.activity.add.tooltip' | translate">
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'program.period_sections.activity.select.tooltip' | translate"
        [class.selecting]="selectingSections"
        (click)="toggleSelection()"
      >
        <mat-icon>compare_arrows</mat-icon>
      </button>
      <button *ngIf="selectingSections && (periods$ | async).length > 1" mat-button [disabled]="selectedSectionsLength === 0" (click)="promptSectionMove()">
        {{ 'generic.move' | translate }}
      </button>
      <button *ngIf="selectingSections" mat-button [disabled]="selectedSectionsLength === 0" (click)="promptSectionCopy()">
        {{ 'generic.copy' | translate }}
      </button>
      <button *ngIf="selectingSections" mat-button [disabled]="selectedSectionsLength === 0" (click)="promptSectionRemove()">
        {{ 'generic.remove' | translate }}
      </button>
      <button mat-icon-button (click)="setPanelBackToPDF()" [matTooltip]="'program.period_sections.back_to_pdf.tooltip' | translate">
        <mat-icon>visibility</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div *ngIf="periodSectionsToShow$ | async as periodSections" class="period-sections-container">
    <app-panel-container
      cdkDropList
      [cdkDropListData]="periodSections"
      (cdkDropListDropped)="dropPeriodSection($event)"
      class="period-sections"
      appExpansionPanelController
    >
      <ng-template let-periodActivityIndex="index">
        <ng-container *ngIf="periodSections[periodActivityIndex] as section">
          <ng-container *ngIf="periodSectionsForm.get('' + section.id)">
            <mat-expansion-panel
              cdkDrag
              [class.first-section]="periodActivityIndex === 0"
              [class.page-break]="section.type_id === EProgramPeriodSectionType.PAGE_END"
              class="period-section-panel"
              [class.selected]="isSelectedSection(section.id)"
              (click)="panelSelected(section.id)"
              [disabled]="selectingSections"
              [expanded]="isOpenedSection(section.id)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <app-drag-handle cdkDragHandle></app-drag-handle>
                  <span class="panel-title">
                    <mat-icon class="archived-icon" *ngIf="getActivityIsExcluded(section)" [matTooltip]="'program_details.activity.excluded.tooltip' | translate">do_not_disturb_on</mat-icon>
                    <mat-icon class="archived-icon" *ngIf="!getActivityIsExcluded(section) && section.activity_archived" [matTooltip]="'program_details.activity.archived.tooltip' | translate">delete</mat-icon>
                    <mat-icon *ngIf="programLanguage && section.language && programLanguage.toLowerCase() !== section.language.toLowerCase()" [svgIcon]="section.language.toLowerCase()"></mat-icon>
                    {{ 'program.period.section_type.' + getNameForProgramPeriodSectionType(section?.type_id) | translate }}
                    <ng-container *ngIf="section?.type_id === EProgramPeriodSectionType.PAGE_END">
                      ............................................................
                    </ng-container>
                  </span>
                </mat-panel-title>
                <mat-panel-description>
                  <ng-container *ngIf="section?.type_id === EProgramPeriodSectionType.ACTIVITY">
                    {{ (section.title !== '' ? section.title : ('generic.new' | translate)) }}
                  </ng-container>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <!-- If this section is an image section, we'll need to be able to drag and drop images all throughout the section. -->
                <app-asset-upload [identifier]="section.id.toString()" *ngIf="section?.type_id === EProgramPeriodSectionType.MEDIA">
                  <!-- Declare a context variable uploadData, and pass it the "uploadData" from the app-asset-upload component -->
                  <ng-template let-uploadData="uploadData">
                    <!-- Then pass this uploadData to the section, which pass it to the image section. -->
                    <app-section
                      (archived)="removeSection(section)"
                      (saved)="saveSection(section.id)"
                      [section]="section"
                      [formControlName]="section.id"
                      [uploadData]="uploadData"
                      [readOnly]="readOnly"
                      [sectionContainerType]="ESectionContainerType.PROGRAM_PERIOD"
                    ></app-section>
                  </ng-template>
                </app-asset-upload>

                <app-section
                  *ngIf="section?.type_id !== EProgramPeriodSectionType.MEDIA && section?.type_id !== EProgramPeriodSectionType.PAGE_END"
                  (saved)="saveSection(section.id)"
                  (archived)="removeSection(section)"
                  [section]="section"
                  [formControlName]="section.id"
                  [readOnly]="readOnly"
                  [sectionContainerType]="ESectionContainerType.PROGRAM_PERIOD"
                ></app-section>
                <div
                  class="button-container"
                  *ngIf="section?.type_id === EProgramPeriodSectionType.PAGE_END || section?.type_id === EProgramPeriodSectionType.ACTIVITY"
                >
                  <button
                    *ngIf="section?.type_id === EProgramPeriodSectionType.PAGE_END && !readOnly"
                    (click)="removeSection(section)"
                    mat-button
                    type="button"
                  >
                    {{ 'program.period_sections.page_end.remove' | translate }}
                  </button>
                  <button
                    *ngIf="section?.type_id === EProgramPeriodSectionType.ACTIVITY && section?.activity_id"
                    (click)="openActivity(section)"
                    mat-button
                    type="button"
                  >
                    {{ 'program.period_sections.activity.view' | translate }}
                  </button>
                </div>
              </ng-template>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
      </ng-template>
    </app-panel-container>
  </div>
</form>
