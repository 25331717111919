import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IActivitySearchRequest } from 'typings/api-search';
import { ISearchActivity } from 'typings/doenkids/doenkids';
import { SearchResponse } from '../../types';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { get } from 'lodash';
import { ActivitySearchStore } from './activity-search.store';
import { firstValueFrom } from 'rxjs';

export interface ICustomActivitySearchResponse {
  results: ISearchActivity[];
  limit: number;
  skip: number;
  total: number;
  query: string;
  took: number;
  aggregations: any;
  suggestions: any[];
}

@Injectable({
  providedIn: 'root',
})
export class ActivitySearchService {
  constructor(private store: ActivitySearchStore, private $baseApi: DoenKidsGenericApiProvider) { }

  @transaction()
  async fetch(request: IActivitySearchRequest, concatenate: boolean, doNotPersist: boolean = false) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall('/search/activity/search', request)) as SearchResponse<ISearchActivity>;
    const hits = (response?.hits?.hits ?? [])?.map((hit) => hit._source);

    if (!doNotPersist) {
      if (concatenate) {
        this.store.add(hits);
      } else {
        this.store.set(hits);
      }

      this.store.update({
        limit: request.limit,
        skip: request.skip,
        total: response?.hits?.total ?? 0,
        query: request.query,
        took: response?.took ?? 0,
      });

      this.store.setAggregations(response ? { language: request.language, countryCode: request.countryCode, ...response.aggregations } : {});
      this.store.setSuggestions(response ? get(response, 'suggest.simple_phrase') : []);
    }

    // eslint-disable-next-line consistent-return
    return {
      results: hits,
      limit: request.limit,
      skip: request.skip,
      total: response?.hits?.total ?? 0,
      query: request.query,
      took: response?.took ?? 0,
      aggregations: response?.aggregations ?? {},
      suggestions: get(response, 'suggest.simple_phrase', []),
    } as ICustomActivitySearchResponse;
  }
}
