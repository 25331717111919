import {
  Component, Inject, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TagListQuery } from 'src/api/activity/tag-list/tag-list.query';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IOrganizationUnitTagAll, ISearchMedia } from 'typings/doenkids/doenkids';
import { UntypedFormControl } from '@angular/forms';
import { MediaTagService } from 'src/api/media/media-tag/media-tag.service';

@Component({
  selector: 'app-add-media-tags-dialog',
  templateUrl: './add-media-tags-dialog.component.html',
  styleUrls: ['./add-media-tags-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddMediaTagsDialogComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public notImplementedTags$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public mediaItemTags: UntypedFormControl = new UntypedFormControl([]);

  public initialMediaItemTags: IOrganizationUnitTagAll[] = [];

  // The tags are loaded from the doenkids session provider on ou load
  //
  public tags$: Observable<IOrganizationUnitTagAll[]>;

  public currentOUId$: Observable<number>;

  public currentTags$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private $session: DoenkidsSessionProvider,
    private tagListQuery: TagListQuery,
    private mediaTagService: MediaTagService,
    private dialogRef: MatDialogRef<AddMediaTagsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mediaItem: ISearchMedia
    },
  ) {
    this.tags$ = this.tagListQuery.selectAll();
    this.currentOUId$ = this.$session.currentOuId$;
  }

  async ngOnInit() {
    const allNonMediaTags: string[] = [];

    if (this.data.mediaItem.activity_media_tags) {
      allNonMediaTags.push(...this.data.mediaItem.activity_media_tags);
    }

    if (this.data.mediaItem.activity_section_media_tags) {
      allNonMediaTags.push(...this.data.mediaItem.activity_section_media_tags);
    }

    this.notImplementedTags$.next(allNonMediaTags);

    const mediaItemTags = (await this.mediaTagService.fetchAll({
      mediaId: this.data.mediaItem.id as number,
      sortField: 'created_at',
      sortDirection: 'ASC',
    })).items;

    if (mediaItemTags) {
      this.initialMediaItemTags = mediaItemTags;
      this.mediaItemTags.setValue(mediaItemTags);
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async onClose() {
    this.loading$.next(true);
    const currentItemTags: IOrganizationUnitTagAll[] = this.mediaItemTags.value;

    const addedTags = currentItemTags.filter((currentItemTag: IOrganizationUnitTagAll) => !this.initialMediaItemTags.includes(currentItemTag));
    const removedTags = this.initialMediaItemTags.filter((initialMediaItemTag: IOrganizationUnitTagAll) => !currentItemTags.includes(initialMediaItemTag));

    const updatePromises = [];
    if (addedTags.length > 0) {
      addedTags.forEach((addedTag: IOrganizationUnitTagAll) => {
        updatePromises.push(this.mediaTagService.update(this.data.mediaItem.id, addedTag.id));
      });
    }

    if (removedTags.length > 0) {
      removedTags.forEach((removedTag: IOrganizationUnitTagAll) => {
        updatePromises.push(this.mediaTagService.delete(this.data.mediaItem.id, removedTag.id));
      });
    }

    await Promise.all(updatePromises);

    this.loading$.next(false);
    this.dialogRef.close({ action: 'confirm' });
  }
}
