import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IServiceResponse } from 'typings/api-generic';
import { SortDirection } from '@angular/material/sort';
import { ITagListResponse } from 'typings/api-activity';
import { IOrganizationUnitTagAll, IProgramTemplate } from 'typings/doenkids/doenkids.d';
import { firstValueFrom } from 'rxjs';

export interface IProgramTemplateWithTags extends IProgramTemplate {
  tags: IOrganizationUnitTagAll[],
}

@Injectable({ providedIn: 'root' })
export class ProgramTagsService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(
    programId: number,
    limit: number = 1000,
    skip: number = 0,
    sortField: string = 'created_at',
    sortDirection: SortDirection = 'desc',
  ) {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      `/activity/program/${programId}/tag`,
      limit,
      skip,
      sortField,
      sortDirection.toUpperCase(),
    )) as ITagListResponse;
    return response.items;
  }

  async update(programId: number, tagId: number) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program/${programId}/tag/${tagId}`,
    )) as IServiceResponse;
    return response;
  }

  remove(programId: number, tagId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/program/${programId}/tag/${tagId}`)) as Promise<IServiceResponse>;
  }
}
