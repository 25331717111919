/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IActivity } from 'typings/doenkids/doenkids';

export interface IActivityHighlightState extends EntityState<IActivity> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity-highlight' })
export class ActivityHighlightStore extends EntityStore<IActivityHighlightState, IActivity> {
  constructor() {
    super();
  }
}
