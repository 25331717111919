import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '../app/utils/translate.service';

@Pipe({
  name: 'i18n_number',
})
export class I18nNumberPipe implements PipeTransform {
  constructor(private $translate: TranslateService) {}

  transform(value: number, digitsInfo?: string): Observable<string> {
    return this.$translate.onInitialLangAndLangChange$
      .pipe(map((event) => formatNumber(value, event.lang, digitsInfo)));
  }
}
