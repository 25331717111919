import {
  Component, Inject, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Subject, BehaviorSubject, Observable, combineLatest, firstValueFrom,
} from 'rxjs';
import {
  debounceTime, takeUntil, filter, map,
} from 'rxjs/operators';
import { IOrganizationUnitBrowseItem, IOrganizationUnitBrowsePath, IOrganizationUnitBrowseRequest } from 'typings/api-customer';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IOrganizationUnitUserPermission } from 'typings/doenkids/doenkids';
import { isNil } from '@datorama/akita';
import { OrganizationUnitBrowseService } from 'src/api/customer/organization-unit-browse/organization-unit-browse.service';
import { OrganizationUnitBrowseQuery } from 'src/api/customer/organization-unit-browse/organization-unit-browse.query';
import { TranslateService } from 'src/app/utils/translate.service';
import { EOrganizationUnitType } from '../add-organization-dialog/add-organization-dialog.component';

export interface ISelectedOrganizationUnit {
  id: number;
  name: string;
  organization_unit_type_id: EOrganizationUnitType;
}

/**
 * Browse organization unit dialog which presents a list of organization units.
 * It can be used for multiple purposes:
 *
 * - new current OU selection
 * - multiple OU selection
 * - ???
 * - profit!
 *
 */
@Component({
  selector: 'app-browse-organization-unit-dialog',
  templateUrl: 'browse-organization-unit-dialog.component.html',
  styleUrls: ['browse-organization-unit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BrowseOrganizationUnitDialogComponent implements OnInit, OnDestroy {
  private stop$ = new Subject();

  public initializing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public fetching$: Observable<boolean>;

  public loading$: Observable<boolean>;

  public form: UntypedFormGroup;

  public title: string;

  public description: string;

  /**
   * The current search query is stored here for highlighting purposes
   *
   * @type {string}
   */
  public currentSearchFilter: string = '';

  /**
   * This is the list of current organization unit items in the list
   *
   */
  public organizationUnitItems$ = new BehaviorSubject<IOrganizationUnitBrowseItem[]>([]);

  /**
   * The organization unit permissions for the current user
   *
   * @private
   * @type {IOrganizationUnitUserPermission[]}
   */
  private permissionArray: IOrganizationUnitUserPermission[];

  /**
   * Indicates the current user is an administrator which we need to know for permissions check
   * Admins will have an empty permissionsArray
   *
   * @private
   * @type {boolean}
   */
  private isAdmin: boolean;

  private isReader: boolean;

  /**
   * This is the id of the organization unit we are listing its items (children) for
   * This is not the selected organization unit id which will be one or more of its children
   *
   * @type {(number | undefined)}
   */
  public browsingOrganizationUnitId: number | undefined;

  /**
   * The current organization unit is the one being highlighted or is being focused on.
   * It can be the only selected organization unit or part of a multi-selection
   *
   * @type {(number | undefined)}
   */
  public currentOrganizationUnitId: number | undefined;

  /**
   * The collection of selected organization unit identifiers
   *
   * @type {number[]}
   */
  public selectedOrganizationUnits: ISelectedOrganizationUnit[] = [];

  /**
   * The path back up the browsing tree
   *
   * @type {IOrganizationUnitBrowsePath[]}
   */
  public parentPath: IOrganizationUnitBrowsePath[] = [];

  /**
   * By default you can select customers and locations
   *
   * @type {number[]}
   */
  public selectableOrganizationUnitTypeIds: number[] = [
    EOrganizationUnitType.ORGANIZATION,
    EOrganizationUnitType.LOCATION,
  ];

  /**
   * List of specific organization unit IDs that the user is not allowed to select
   *
   * @type {number[]}
   */
  public disabledOrganizationUnitIds: number[] = [];

  private rootOuView$: BehaviorSubject<IOrganizationUnitBrowseItem[]>;

  readonly EOrganizationUnitType = EOrganizationUnitType;

  constructor(
    public dialogRef: MatDialogRef<BrowseOrganizationUnitDialogComponent>,
    private organizationUnitBrowseService: OrganizationUnitBrowseService,
    private organizationUnitBrowseQuery: OrganizationUnitBrowseQuery,
    private $session: DoenkidsSessionProvider,
    private fb: UntypedFormBuilder,
    private $translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      description: string,
      initialBrowsingOrganizationUnitId?: number,
      initialOrganizationUnitSelection?: ISelectedOrganizationUnit[],
      disabledOrganizationUnitIds: number[]
      allowMultipleSelection?: boolean,
      selectableOrganizationUnitTypeIds?: number[],
      writeableOnly?: boolean,
      addNewRootNodeOption?: boolean,
      maxAllowedOusSelected?: number,
    },
  ) {
    this.fetching$ = this.organizationUnitBrowseQuery.selectLoading();

    this.loading$ = combineLatest([this.initializing$, this.fetching$]).pipe(takeUntil(this.stop$)).pipe(
      map(([initializing, fetching]) => initializing || fetching),
    );

    this.form = this.fb.group({ filter: '' });

    this.rootOuView$ = this.$session.writeableRootOuView$;

    this.title = data?.title;
    this.description = data?.description;
    if (!isNil(data.selectableOrganizationUnitTypeIds) && !isNil(data.selectableOrganizationUnitTypeIds[0])) {
      this.selectableOrganizationUnitTypeIds = data.selectableOrganizationUnitTypeIds;
    }
    if (data.initialOrganizationUnitSelection) {
      this.selectedOrganizationUnits = data.initialOrganizationUnitSelection;
    }
    if (data.disabledOrganizationUnitIds) {
      this.disabledOrganizationUnitIds = data.disabledOrganizationUnitIds;
    }
    this.browsingOrganizationUnitId = data.initialBrowsingOrganizationUnitId;
    this.reset();
  }

  /**
   * Helper method to determine if the current browse list item is selectable based
   * on its type and writeable status
   *
   * @param {IOrganizationUnitBrowseItem} node
   * @return {*} boolean
   */
  isSelectable(node: IOrganizationUnitBrowseItem): boolean {
    if (node.id === -1 && this.data.addNewRootNodeOption) {
      return true;
    }

    if (this.disabledOrganizationUnitIds.includes(node.id)) {
      return false;
    }

    const allowedType = this.selectableOrganizationUnitTypeIds.indexOf(node.organization_unit_type_id) !== -1;
    let writeable = true;

    if (this.data.writeableOnly || this.isReader) {
      writeable = this.isConnectedToOuOrParent(node);
    }
    const maxAllowedNotYetReached = this.data.allowMultipleSelection && !isNil(this.data.maxAllowedOusSelected) ? this.selectedOrganizationUnits.length < this.data.maxAllowedOusSelected : true;
    const isAlreadySelected = !isNil(this.selectedOrganizationUnits.find((selectedOU) => selectedOU.id === node.id));

    return allowedType && writeable && (isAlreadySelected || maxAllowedNotYetReached);
  }

  /**
   * Helper method that check if the browse list item is currently selected
   *
   * @param {IOrganizationUnitBrowseItem} node
   * @return {*} boolean
   */
  isSelected(node: IOrganizationUnitBrowseItem): boolean {
    return this.selectedOrganizationUnits.some((selected) => selected.id === node.id);
  }

  /**
   * Helper method to check if the browse list item is the currently focused/highlighted item
   *
   * @param {IOrganizationUnitBrowseItem} node
   * @return {*}  {boolean}
   */
  isCurrent(node: IOrganizationUnitBrowseItem): boolean {
    return this.currentOrganizationUnitId === node.id;
  }

  /**
   * Adds or removes the provided browse item from the selected list.
   * Takes into account if multiple selection is allowed
   *
   * @param {IOrganizationUnitBrowseItem} node
   */
  toggleSelected(node: IOrganizationUnitBrowseItem) {
    if (!this.isSelectable(node)) {
      return;
    }

    // Always set the highlight
    //
    this.currentOrganizationUnitId = node.id;

    const isSelected = this.isSelected(node);
    if (isSelected) {
      // Remove from selection
      //
      this.selectedOrganizationUnits = this.selectedOrganizationUnits.filter((selected) => selected.id !== node.id);
    } else {
      const selectedNode = {
        id: node.id,
        name: node.name,
        organization_unit_type_id: node.organization_unit_type_id,
      };
      if (this.data.allowMultipleSelection) {
        this.selectedOrganizationUnits.push(selectedNode);
      } else {
        this.selectedOrganizationUnits = [selectedNode];
      }
    }
  }

  /**
   * Goes into the selected browse item and fetches the list for it
   *
   * @param {IOrganizationUnitBrowseItem} node
   */
  browse(node: IOrganizationUnitBrowseItem) {
    this.browsingOrganizationUnitId = node.id;
    this.filterChanged('');
  }

  /**
   * Returns to the parent browse item
   *
   * @param {IOrganizationUnitBrowsePath} node
   */
  browseParent(node: IOrganizationUnitBrowsePath) {
    this.browsingOrganizationUnitId = node.parent_organization_unit_id;
    this.filterChanged('');
  }

  /**
   * Closes the dialog and returns the current organization unit selection(s)
   *
   */
  confirm() {
    this.dialogRef.close(this.selectedOrganizationUnits);
  }

  /**
   * This method will return the current view to the initial organization unit
   *
   */
  reset() {
    this.browsingOrganizationUnitId = this.data?.initialBrowsingOrganizationUnitId;
    this.selectedOrganizationUnits = [...(this.data?.initialOrganizationUnitSelection || [])];
    this.currentOrganizationUnitId = this.selectedOrganizationUnits[0]?.id;
  }

  /**
   * Helper method to scroll the organization unit item list to the target item.
   * Relies of the organization unit class name set in the template
   *
   * @param {number} ouId
   */
  scrollToOrganizationUnit(ouId: number) {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      const element = document.querySelector(`.ou-${ouId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }, 500);
  }

  showRootView() {
    this.browsingOrganizationUnitId = undefined;
    this.filterChanged('');
  }

  /**
   * Checks if the current user is allowed to make changes to the organization unit item
   *
   * @param {IOrganizationUnitBrowseItem} node The OU browse node to check
   * @return {*} boolean
   */
  hasWritePermission(node: IOrganizationUnitBrowseItem | IOrganizationUnitBrowsePath): boolean {
    if (this.isAdmin) {
      return true;
    }

    const userOrganizationUnitPermissions = this.permissionArray.filter((permission: IOrganizationUnitUserPermission) => permission.organization_unit_id === node.id);
    const hasWritePermission =  !isNil(userOrganizationUnitPermissions.find((ouPermission) => ouPermission.permission === 'WRITE'));
    return hasWritePermission;
  }

  isConnectedToOuOrParent(node: IOrganizationUnitBrowseItem | IOrganizationUnitBrowsePath): boolean {
    if (this.isAdmin) {
      return true;
    }

    const userOrganizationUnitPermissions = this.permissionArray
      .filter((permission: IOrganizationUnitUserPermission) => permission.organization_unit_id === node.id && ['LINKED', 'CHILD'].includes(permission.relation));
    return userOrganizationUnitPermissions.length > 0;
  }

  shouldShowPathEntry(node: IOrganizationUnitBrowsePath) {
    let writePermissionChecksOut = true;
    if (this.data.writeableOnly || this.isReader) {
      writePermissionChecksOut = this.isConnectedToOuOrParent(node);
    }
    const isRootViewNode = this.rootOuView$.value.find((rootOu) => rootOu.id === node.id);

    return writePermissionChecksOut && !isRootViewNode;
  }

  shouldShowShowAllOption() {
    let shouldShow = false;
    if (this.parentPath.length > 1) {
      shouldShow = true;
    } else if (this.parentPath.length === 1) {
      const isRootViewNode = this.rootOuView$.value.find((rootOu) => rootOu.id === this.parentPath[0].id);
      shouldShow = !isNil(isRootViewNode);
    }

    return shouldShow;
  }

  /**
   * When the search filter changes this will fetch the matching organization unit
   * If the filter is empty the list will reset to the current organization unit
   *
   * @param {string} filterText
   */
  async filterChanged(filterText: string) {
    this.currentSearchFilter = filterText;
    if (filterText) {
      // console.log('[OU-BROWSE]: Search query', filterText);
      const organizationUnitItems = await this.organizationUnitBrowseService.fetch({
        search: encodeURIComponent(filterText),
      });
      this.parentPath = [];
      this.organizationUnitItems$.next(organizationUnitItems);
      // console.log('[OU-BROWSE]: Search result nodes', organizationUnitItems);
    } else {
      await this.loadOrganizationUnitItems(this.data.writeableOnly || this.isReader);
    }

    this.scrollToOrganizationUnit(this.currentOrganizationUnitId);
  }

  /**
   * During initialization we will setup the search form listener
   * and resolve any initial organization unit selection.
   * Default behavior is to use this component to change the current session organization unit
   *
   */
  async ngOnInit() {
    // Listen for changes on the search filter and fetch as needed
    //
    this.form.get('filter').valueChanges.pipe(
      takeUntil(this.stop$),
      debounceTime(500),
    ).subscribe((value) => this.filterChanged(value));

    this.initializing$.next(true);

    // Fetch the current users permissions for write level checks
    //
    this.permissionArray = await firstValueFrom(this.$session.userPermissions$.pipe(filter((value) => !isNil(value))));
    this.isAdmin = await firstValueFrom(this.$session.isAdmin$.pipe(filter((value) => !isNil(value))));
    this.isReader = await firstValueFrom(this.$session.isReader$.pipe(filter((value) => !isNil(value))));

    // If no initial organization unit to browse was provided go to the
    // parent of the currently selected organization unit
    // Also if no current or selected organization unit was provided we will select
    // the current organization unit itself
    //
    const selectedOrganizationInSession = await firstValueFrom(this.$session.getOrganizationUnit$);
    if (selectedOrganizationInSession) {
      if (!this.browsingOrganizationUnitId) {
        this.browsingOrganizationUnitId = selectedOrganizationInSession.parent_organization_unit_id;
      }
      if (!this.currentOrganizationUnitId) {
        this.currentOrganizationUnitId = selectedOrganizationInSession.id;
      }
      // Only select the current organization if no initial organization unit was provided
      //
      if (!this.selectedOrganizationUnits.length && !this.data.initialOrganizationUnitSelection) {
        this.selectedOrganizationUnits.push({
          id: selectedOrganizationInSession.id,
          name: selectedOrganizationInSession.name,
          organization_unit_type_id: selectedOrganizationInSession.organization_unit_type_id,
        });
      }
    }

    await this.loadOrganizationUnitItems(false);

    if (this.currentOrganizationUnitId) {
      this.scrollToOrganizationUnit(this.currentOrganizationUnitId);
    }

    this.initializing$.next(false);
  }

  private async loadOrganizationUnitItems(writeOnly: boolean): Promise<void> {
    const requestBody: IOrganizationUnitBrowseRequest = {};
    if (writeOnly) {
      requestBody.mode = 'userWriteable';
    }
    const organizationUnitItems = await this.organizationUnitBrowseService.fetch(requestBody, this.browsingOrganizationUnitId);

    if (!this.browsingOrganizationUnitId && this.data.addNewRootNodeOption) {
      organizationUnitItems.unshift({
        id: -1,
        name: this.$translateService.instant('dialog.browse_organization_unit.new_root_node'),
        organization_unit_type_id: EOrganizationUnitType.ROOT,
        path: [],
        child_node_count: 0,
      });
    }

    this.organizationUnitItems$.next(organizationUnitItems);
    const firstItemNotRootNodeOption = organizationUnitItems.find((ouItem) => ouItem.id > -1);
    const filteredPath = (firstItemNotRootNodeOption?.path ?? []).filter((ouPathItem) => ouPathItem.parent_organization_unit_id !== this.browsingOrganizationUnitId).reverse();
    this.parentPath = filteredPath;
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }
}
