import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { ITagListResponse } from 'typings/api-activity';
import { TagListStore } from './tag-list.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TagListService {
  constructor(private store: TagListStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(
    organizationUnitId: number,
    limit: number = 100,
    skip: number = 0,
    sortField: string = 'name',
    sortDirection: SortDirection = 'desc',
    doNotPersist: boolean = false,
  ) {
    const activityTagListResponse = await firstValueFrom(this.$baseApi.genericListCall('/activity/tag', limit, skip, sortField, sortDirection, { organizationUnitId })) as ITagListResponse;

    if (!doNotPersist) {
      this.store.set(activityTagListResponse?.items ?? []);
    }

    return activityTagListResponse?.items ?? [];
  }
}
