import { BehaviorSubject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activity-review-overview',
  templateUrl: './activity-review-overview.component.html',
  styleUrls: ['./activity-review-overview.component.scss']
})
export class ActivityReviewOverviewComponent implements OnInit {
  public id$ = new BehaviorSubject(null);

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const { id } = this.route.snapshot.params;
    this.id$.next(id);
  }
}
