import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IOrganizationUnitDetails, IOrganizationUnitFinancialNote, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { OrganizationUnitDetailsStore } from './organization-unit-details.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitDetailsService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: OrganizationUnitDetailsStore) {}

  @transaction()
  async fetch(id: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/organization-unit/${id}/overview`)) as IOrganizationUnitOverview;

    // Number arrays come back as string so we need to parse them here
    //
    this.store.update(response);
    return response;
  }

  @transaction()
  async update(organizationUnitDetails: IOrganizationUnitDetails) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitDetails.organization_unit_id}/details`,
      organizationUnitDetails)) as IOrganizationUnitOverview;

    return response;
  }

  @transaction()
  async fetchFinancialNote(organizationUnitId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/organization-unit/${organizationUnitId}/financial-note`));

    return response;
  }

  async saveFinancialNote(organizationUnitId: number, data: Partial<IOrganizationUnitFinancialNote>) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/financial-note`, data));

    return response;
  }
}
