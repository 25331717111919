import { Injectable } from '@angular/core';
import {
  BehaviorSubject, combineLatest, firstValueFrom, from, Observable, pipe,
} from 'rxjs';
import { ActivitySearchQuery } from 'src/api/search/activity/activity-search.query';
import { cloneDeep, isEqual, isNil, snakeCase } from 'lodash';
import {
  filter, mergeMap, distinctUntilChanged,
} from 'rxjs/operators';
import { BaseActivitySearchQuery } from 'src/api/search/base-activity/base-activity-search.query';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DoenkidsSessionProvider } from './session.provider';
import { EOrganizationUnitType } from '../components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { TranslateService } from '../app/utils/translate.service';
import { AgeGroupQuery } from 'src/api/generic/age-group/age-group.query';

// these should be the names of all age groups snake cased
//
_('activity.age_group.4_6_jaar');
_('activity.age_group.6_8_jaar');
_('activity.age_group.8_12_jaar');
_('activity.age_group.baby_tot_1_jaar');
_('activity.age_group.dreumes_1_tot_2_jaar');
_('activity.age_group.peuter_2_tot_4_jaar');
_('activity.age_group.baby');
_('activity.age_group.peuter');
_('activity.age_group.kleuter');

export interface ISearchAggregationBucket {
  key: string;
  doc_count: number;
}

export interface ISearchAggregation {
  dock_count_error_upper_bound: number;
  sum_other_doc_count: number;
  buckets: ISearchAggregationBucket[];
}

export interface ISearchAggregations {
  [key: string]: string | ISearchAggregation;
}

const BASE_AGGREGATION_GROUP_LABELS = {
  age_group: _('activity.aggregation_labels.age_group'),
  group_size: _('activity.aggregation_labels.group_size'),
  area_of_development: _('activity.aggregation_labels.area_of_development'),
  range_of_development: _('activity.aggregation_labels.range_of_development'),
  location: _('activity.aggregation_labels.location'),
  duration: _('activity.aggregation_labels.duration'),
  tags: _('activity.aggregation_labels.tags'),
};

const ORGANIZATION_AGGREGATION_GROUP_LABELS = {
  activity_kind: _('activity.aggregation_labels.activity_kind'),
  activity_type: _('activity.aggregation_labels.activity_type'),
  ...BASE_AGGREGATION_GROUP_LABELS,
};

const LOCATION_AGGREGATION_GROUP_LABELS = {
  activity_kind: _('activity.aggregation_labels.activity_kind'),
  ...BASE_AGGREGATION_GROUP_LABELS,
};

const DURATION_BUCKETS = [5, 10, 15, 20, 30, 45, 60, 90, 120];

@Injectable({
  providedIn: 'root',
})
export class ActivityAggregationsProvider {
  // The tags are loaded from the doenkids session provider on ou load
  //
  public sortedAggregations$: Observable<any>;

  public sortedBaseAggregations$: Observable<any>;

  public aggregationLabels$: BehaviorSubject<{ [index: string]: string }> = new BehaviorSubject<{ [index: string]: string }>({});

  public baseAggregationLabels$: BehaviorSubject<{ [index: string]: string }> = (new BehaviorSubject<{ [index: string]: string }>({}));

  constructor(
    private $session: DoenkidsSessionProvider,
    private activitySearchQuery: ActivitySearchQuery,
    private baseActivitySearchQuery: BaseActivitySearchQuery,
    private $translate: TranslateService,
    private ageGroupQuery: AgeGroupQuery,
  ) {
    this.sortedAggregations$ = this.activitySearchQuery.getAggregations.pipe(
      this.getAggregationPipeOperations(),
    );

    this.sortedBaseAggregations$ = this.baseActivitySearchQuery.getAggregations.pipe(this.getAggregationPipeOperations());

    combineLatest([
      $session.getOrganizationUnit$,
      this.$translate.onInitialTranslationAndLangOrTranslationChange$,
    ]).subscribe(([newOrganizationUnitDetails, langChange]) => {
      let useLocationLabels = false;
      if (newOrganizationUnitDetails.organization_unit_type_id === EOrganizationUnitType.LOCATION) {
        useLocationLabels = true;
      } else if (newOrganizationUnitDetails.organization_unit_type_id === EOrganizationUnitType.ORGANIZATION) {
        useLocationLabels = false;
      }
      const translatedAggregationLabels = {};
      const translatedLocationAggregationLabels = {};
      for (const key in ORGANIZATION_AGGREGATION_GROUP_LABELS) {
        if (Object.prototype.hasOwnProperty.call(ORGANIZATION_AGGREGATION_GROUP_LABELS, key)) {
          const translation = langChange.translations[ORGANIZATION_AGGREGATION_GROUP_LABELS[key]];
          translatedAggregationLabels[key] = translation;
          if (useLocationLabels && Object.prototype.hasOwnProperty.call(LOCATION_AGGREGATION_GROUP_LABELS, key)) {
            translatedLocationAggregationLabels[key] = translation;
          }
        }
      }

      this.aggregationLabels$.next(useLocationLabels ? translatedLocationAggregationLabels : translatedAggregationLabels);
      this.baseAggregationLabels$.next(translatedAggregationLabels);
    });
  }

  private sortBucket(aggregations: any, aggregationKey: string, bucketKeys: string[] | number[]) {
    const bucketGroup = aggregations[aggregationKey];
    if (!isNil(bucketGroup)) {
      let { buckets } = bucketGroup;
      if (!isNil(buckets) && buckets.length > 0) {
        buckets = this.sortIfPresent(buckets, bucketKeys);
      }
      aggregations[aggregationKey].buckets = buckets;
    }
  }

  /** Takes an array containing items with keys, and sorts them according to the second array of keys. */
  private sortIfPresent(buckets: any[], keys: string[] | number[]) {
    const sortedArray = [];

    // Loop over every sortkey in order, and find the relevant "bucket" item to be sorted.
    //
    for (const key of keys) {
      const bucket = buckets.find((item) => item.key === key);

      // It's possible the item isn't available. In that case, don't sort.
      //
      if (!isNil(bucket)) {
        sortedArray.push(bucket);
      }
    }

    return sortedArray;
  }

  private sortAlphabetically(aggregations: any, aggregationKey: string, translations: any) {
    const bucketGroup = aggregations[aggregationKey];

    if (!isNil(bucketGroup)) {
      const { buckets } = bucketGroup;
      const translatedBuckets: { translatedKey: string, bucket: any }[] = [];
      if (!isNil(buckets) && buckets.length > 0) {
        for (const bucket of buckets) {
          const escapedKey = bucket.key.replace(/[,:;]\s/gm, '_').replace(/\s&\s/gm, ' en ').replace(/\s/gm, '_').toLowerCase();
          const aggregationCompleteKey = `activity.aggregation.${aggregationKey}.${escapedKey}`;
          const normalCompleteKey = `activity.${aggregationKey}.${escapedKey}`;
          const translatedKey = translations[aggregationCompleteKey] ?? translations[normalCompleteKey] ?? aggregationCompleteKey;

          if (aggregationCompleteKey !== translatedKey && normalCompleteKey !== translatedKey) {
            translatedBuckets.push({ translatedKey, bucket });
          } else {
            translatedBuckets.push( { translatedKey: bucket.key, bucket });
          }
        }

        translatedBuckets.sort((a, b) => a.translatedKey.localeCompare(b.translatedKey));
      }
      aggregations[aggregationKey].buckets = translatedBuckets.map((translatedBucket) => translatedBucket.bucket);
    }
  }

  private getAggregationPipeOperations() {
    return pipe(
      filter((aggregations) => !isNil(aggregations)),
      distinctUntilChanged(isEqual),
      mergeMap((aggregations: ISearchAggregations) => {
        return from(new Promise(async (resolve) => {
          const languageToUse = aggregations.language && aggregations.countryCode ? `${aggregations.language}-${(aggregations.countryCode as string).toUpperCase()}` : this.$translate.currentLang;
          const translations = await this.$translate.getBaseTranslations(languageToUse);
          const ageGroups = await firstValueFrom(this.ageGroupQuery.selectAll());

          // eslint-disable-next-line @typescript-eslint/dot-notation
          const ageGroupBuckets = ((aggregations['age_group'] as ISearchAggregation)?.buckets ?? []).map((ageGroupBucket) => {
            const bucketKey = ageGroupBucket.key.toLowerCase();
            const orderOfBucket = ageGroups.find((ageGroup) => ageGroup.name.toLowerCase() === bucketKey)?.order ?? 0;
            const translationKey = `activity.age_group.${snakeCase(bucketKey)}`;
            const translatedLabel = translations[translationKey] ?? translationKey;

            return {
              label: translatedLabel === translationKey ? ageGroupBucket.key : translatedLabel,
              order: orderOfBucket,
            };
          }).sort((a, b) => a.order - b.order).map((ageGroupBucket) => ageGroupBucket.label);

          const aggregationsClone = cloneDeep(aggregations);
          this.sortAlphabetically(aggregationsClone, 'activity_kind', translations);
          this.sortBucket(
            aggregationsClone,
            'age_group',
            ageGroupBuckets,
          );
          this.sortBucket(aggregationsClone, 'duration', DURATION_BUCKETS);
          resolve(aggregationsClone);
        }));
      }),
    );
  }
}
