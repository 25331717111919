/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Component, ExistingProvider, forwardRef, OnInit, OnDestroy, HostListener,
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, Validators, NG_VALIDATORS, NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, ValidationErrors,
} from '@angular/forms';
import { IUser } from 'typings/doenkids/doenkids';
import { Observable, Subject, combineLatest } from 'rxjs';
import { noop, isNil } from 'lodash';
import { takeUntil, map } from 'rxjs/operators';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';

export const USER_FORM_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UserFormComponent),
  multi: true,
};

export const USER_FORM_CONTROL_VALIDATOR: ExistingProvider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => UserFormComponent),
  multi: true,
};

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  providers: [USER_FORM_CONTROL_VALUE_ACCESSOR, USER_FORM_CONTROL_VALIDATOR],
})
export class UserFormComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  private stop$ = new Subject<void>();

  public form: UntypedFormGroup;

  public editingAnExistingUser$: Observable<boolean>;

  public title: string;

  public isAdmin$: Observable<boolean>;

  public isManagement$: Observable<boolean>;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val) => void = noop;

  @HostListener('blur') on() {
    this.onTouchedCallback();
  }

  constructor(
    fb: UntypedFormBuilder,
    private $permission: PermissionProvider,
    private $session: DoenkidsSessionProvider,
  ) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      active: [false],
      user_role: ['user', Validators.required],
      media_uuid: [''],
      id: [],
    });

    this.editingAnExistingUser$ = this.form.valueChanges.pipe(
      map((form) => !isNil(form.id)),
    );

    this.isAdmin$ = this.$session.isAdmin$;

    this.isManagement$ = combineLatest([
      this.isAdmin$,
      this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$,
    ]).pipe(
      map((roles) => roles.includes(true)),
    );
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.stop$)).subscribe((formValue) => {
      this.onChangeCallback(formValue);
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  writeValue(value: IUser) {
    if (value) {
      for (const propertyKey in value) {
        const formEntry = this.form.get(propertyKey);
        formEntry?.setValue(value[propertyKey]);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(): ValidationErrors {
    return this.form.errors;
  }
}
