import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramTemplateAttachmentService } from 'src/api/media/program-template-attachment/program-template-attachment.service';
import { ProgramTemplateBundleAttachmentService } from 'src/api/media/program-template-bundle-attachment/program-template-bundle-attachment.service';
import { TPurpose } from 'src/components/shared/asset-upload/asset-upload.component';
import { ShowErrorsImmediatelyErrorMatcher } from 'src/components/shared/form-validators/error-state-matchers';
import { DoenkidsAssetProvider } from 'src/providers/assets.provider';
import { IMedia, IProgramTemplateAttachmentMedia, IProgramTemplateBundleAttachmentMedia } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-program-template-attachment-dialog',
  templateUrl: './program-template-attachment-dialog.component.html',
  styleUrls: ['./program-template-attachment-dialog.component.scss'],
})
export class ProgramTemplateAttachmentDialogComponent implements OnInit {
  public attachmentDisplayName: UntypedFormControl;

  public errorStateMatcher = new ShowErrorsImmediatelyErrorMatcher();

  public tempMediaItem: IMedia;

  public purpose: TPurpose = 'program-attachment';

  constructor(
    fb: UntypedFormBuilder,
    private assetService: DoenkidsAssetProvider,
    private programTemplateAttachementService: ProgramTemplateAttachmentService,
    private programTemplateBundleAttachmentService: ProgramTemplateBundleAttachmentService,
    private dialogRef: MatDialogRef<ProgramTemplateAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      programTemplateId?: number;
      programTemplateBundleId?: number;
      currentAttachment?: IProgramTemplateAttachmentMedia | IProgramTemplateBundleAttachmentMedia;
      purpose?: TPurpose;
    },
  ) {
    this.attachmentDisplayName = fb.control(data.currentAttachment?.display_name ?? '', [Validators.required]);

    if (this.data?.purpose) {
      this.purpose = this.data.purpose;
    }
  }

  ngOnInit(): void {}

  addAttachment(selectedAttachment: any) {
    this.tempMediaItem = selectedAttachment;
  }

  removeTempMediaItem() {
    this.tempMediaItem = null;
  }

  async openAttachment(attachment: IProgramTemplateAttachmentMedia | IProgramTemplateBundleAttachmentMedia | IMedia) {
    let assetServiceParams;
    if (this.data.programTemplateId) {
      assetServiceParams = { program_template_id: this.data.programTemplateId };
    } else if (this.data.programTemplateBundleId) {
      assetServiceParams = { program_template_bundle_id: this.data.programTemplateBundleId };
    }

    if (attachment) {
      // For iOS Safari, we first need to open the window in a *synchronous* function. Async window.open calls do NOT work.
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      //
      // I removed the window.open calls, and replaced them with html anchor element clicks.
      //
      // eslint-disable-next-line no-undef
      const a = document.createElement('a');

      this.assetService.getUrl(attachment.uuid, assetServiceParams).then((mediaAssetUrl) => {
        a.href = mediaAssetUrl;
        a.target = '_blank';
        a.download = attachment.filename;
        a.click();
      });
    }
  }

  async saveAttachment() {
    try {
      if (this.data.programTemplateId) {
        if (this.data.currentAttachment && this.attachmentDisplayName.value !== this.data.currentAttachment.display_name) {
          await this.programTemplateAttachementService.updateDisplayName(
            this.data.programTemplateId,
            this.data.currentAttachment.media_id,
            this.attachmentDisplayName.value,
          );
        } else if (this.tempMediaItem) {
          await this.programTemplateAttachementService.update(this.data.programTemplateId, this.tempMediaItem.id, this.attachmentDisplayName.value);
        }
      } else if (this.data.programTemplateBundleId) {
        if (this.data.currentAttachment && this.attachmentDisplayName.value !== this.data.currentAttachment.display_name) {
          await this.programTemplateBundleAttachmentService.updateDisplayName(
            this.data.programTemplateBundleId,
            this.data.currentAttachment.media_id,
            this.attachmentDisplayName.value,
          );
        } else if (this.tempMediaItem) {
          await this.programTemplateBundleAttachmentService.update(this.data.programTemplateBundleId, this.tempMediaItem.id, this.attachmentDisplayName.value);
        }
      }

      this.closeDialog();
    } catch (e) {
      console.error(e);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
