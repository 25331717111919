
<form [formGroup]="form">
  <ng-container *ngIf="sectionContainerType === ESectionContainerType.ACTIVITY" [ngSwitch]="section?.type_id">
    <app-activity-tips-section *ngSwitchCase="ESectionType.ACTIVITY_TIPS" formControlName="data"></app-activity-tips-section>
    <app-activity-variations-section *ngSwitchCase="ESectionType.ACTIVITY_VARIATIONS" formControlName="data"></app-activity-variations-section>
    <app-activity-what-section *ngSwitchCase="ESectionType.ACTIVITY_WHAT" formControlName="data"></app-activity-what-section>
    <app-glossary-section *ngSwitchCase="ESectionType.GLOSSARY" formControlName="data" ></app-glossary-section>
    <app-required-material-section *ngSwitchCase="ESectionType.REQUIRED_MATERIAL" formControlName="data"></app-required-material-section>
    <app-sources-section *ngSwitchCase="ESectionType.SOURCES" formControlName="data"></app-sources-section>
    <app-youtube-section *ngSwitchCase="ESectionType.YOUTUBE" formControlName="data"></app-youtube-section>
    <ng-container *ngSwitchCase="ESectionType.MEDIA">
      <app-image-section  formControlName="data" [uploadData]="uploadData" [identifier]="section.id.toString()" purpose="activity" (changedSection)="save(true)"></app-image-section>
      <app-select-media [uploadData]="uploadData" (selected)="addImage($event)" [identifier]="section.id.toString()" [disabled]="( allowedToEdit$ | async ) === false"></app-select-media>
    </ng-container>
    <app-activity-material-section *ngSwitchCase="ESectionType.MATERIAL" formControlName="data"></app-activity-material-section>
    <app-smallsteps-preparation *ngSwitchCase="ESectionType.SMALLSTEPS_PREPARATION" formControlName="data"></app-smallsteps-preparation>
    <app-smallsteps-introduction *ngSwitchCase="ESectionType.SMALLSTEPS_INTRODUCTION" formControlName="data"></app-smallsteps-introduction>
    <app-smallsteps-instruction *ngSwitchCase="ESectionType.SMALLSTEPS_INSTRUCTION" formControlName="data"></app-smallsteps-instruction>
    <app-smallsteps-conclusion *ngSwitchCase="ESectionType.SMALLSTEPS_CONCLUSION" formControlName="data"></app-smallsteps-conclusion>
  </ng-container>

  <ng-container *ngIf="sectionContainerType === ESectionContainerType.PROGRAM_PERIOD" [ngSwitch]="section?.type_id">
    <app-period-activity-section
      *ngSwitchCase="EProgramPeriodSectionType.ACTIVITY"
      formControlName="data"
      (selected)="addActivityImage($event)"
      (uploaded)="addActivityImage($event)"
      [sectionId]="section.id"
    ></app-period-activity-section>
    <ng-container *ngSwitchCase="EProgramPeriodSectionType.MEDIA">
      <app-image-section  formControlName="data" [uploadData]="uploadData" [purpose]="mediaPurpose" [identifier]="section.id.toString()" (changedSection)="save(true)"></app-image-section>
      <app-select-media *ngIf="!readOnly" [uploadData]="uploadData" (selected)="addImage($event)" [identifier]="section.id.toString()" [purpose]="mediaPurpose"></app-select-media>
    </ng-container>
    <app-activity-what-section *ngSwitchCase="EProgramPeriodSectionType.ACTIVITY_WHAT" formControlName="data"></app-activity-what-section>
  </ng-container>

  <div
    *ngIf="
        (sectionContainerType !== ESectionContainerType.PROGRAM_PERIOD && section?.type_id !== ESectionType.PAGE_END)
    "
    class="columns-selector"
  >
    <mat-radio-group formControlName="columns">
      <mat-radio-button *ngFor="let columnCount of [1, 2, 3, 4]" [value]="columnCount">
        {{ 'section.column_count' | translate: {columnCount} }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</form>
<div class="activity-button-section">
  <button
    *ngIf="sectionContainerType === ESectionContainerType.ACTIVITY && sectionTypesWithUserConfigurableInputCount.includes(section?.type_id)"
    (click)="addControl()"
    mat-button
    type="button"
  >
    {{ 'section.' + getNameForSectionType(section.type_id) + '.add_input' | translate }}
  </button>
  <button
    *ngIf="canRemoveLastSectionOfType && !readOnly"
    (click)="remove()"
    mat-button
    type="button"
    [disabled]="sectionContainerType === ESectionContainerType.ACTIVITY && ( allowedToEdit$ | async ) === false"
  >
    {{ 'section.remove' | translate: {sectionDisplayName} }}
  </button>
  <button
    *ngIf="( section?.type_id !== ESectionType.PAGE_END ) && !readOnly"
    (click)="save()"
    mat-button
    type="button"
    [disabled]="sectionContainerType === ESectionContainerType.ACTIVITY && ( allowedToEdit$ | async ) === false"
  >
    {{ 'generic.save' | translate }}
  </button>
</div>
