import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IMediaSearchRequest } from 'typings/api-search';
import { IMedia } from 'typings/doenkids/doenkids';
import { SearchResponse } from '../../types';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { MediaSearchStore } from './media-search.store';

@Injectable({
  providedIn: 'root',
})
export class MediaSearchService {
  constructor(private store: MediaSearchStore, private $baseApi: DoenKidsGenericApiProvider) { }

  @transaction()
  fetch(request: IMediaSearchRequest, concatenate: boolean) {
    this.$baseApi.genericPostCall('/search/media/search', request).subscribe((response: SearchResponse<IMedia>) => {
      const hits = (response?.hits?.hits ?? [])?.map((hit) => hit._source);

      if (concatenate) {
        this.store.add(hits);
      } else {
        this.store.set(hits);
      }

      this.store.update({
        limit: request.limit,
        skip: request.skip,
        total: response?.hits?.total ?? 0,
        query: request.query,
        took: response?.took ?? 0,
      });

      this.store.setAggregations(response?.aggregations ?? {});
    });
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
