<div fxLayout="row" fxLayoutAlign="space-between start">
  <h2 mat-dialog-title>{{title}}</h2>
  <button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>
<ng-container *ngIf="!downloading">
  <mat-dialog-content>
    <p>{{description}}</p>
    <app-asset-upload class="image" identifier="booklet-image" purpose="program-template" (uploaded)="addCoverImage($event)">
      <ng-template let-uploadData="uploadData">
        <div class="image-container">
          <app-doenkids-image class="image" [maxWidth]="100" [maxHeight]="100" [mediaItem]="newBookletForm.get('media_uuid').value"></app-doenkids-image>
        </div>
        <app-select-media identifier="booklet-image" [uploadData]="uploadData" purpose="program-template" (selected)="addCoverImage($event)"></app-select-media>
      </ng-template>
    </app-asset-upload>
    <mat-list>
      <div mat-subheader>{{ 'dialog.create_program_booklet.programs' | translate }}</div>
      <mat-list-item *ngFor="let program of programs">
        <mat-icon matListItemIcon>insert_drive_file</mat-icon>
        <div matListItemTitle>{{program.name}}</div>
        <div matListItemLine>
          {{ 'generic.period' | translate: {
            from: program.from | i18n_date | async,
            to: program.to | i18n_date | async
          } }}
        </div>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
</ng-container>
<ng-container *ngIf="downloading">
  <mat-dialog-content>
    <p>{{ 'dialog.create_program_booklet.content' | translate }}
  </mat-dialog-content>
</ng-container>

<mat-dialog-actions>
  <button mat-button (click)="onCancel()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <span class="spacer"></span>
  <button  mat-button color="primary" [disabled]="newBookletForm.invalid || downloading" (click)="onConfirm()">
    {{ 'generic.download' | translate | uppercase }}
  </button>
  <mat-spinner *ngIf="downloading" [diameter]="24"></mat-spinner>
</mat-dialog-actions>
