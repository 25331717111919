import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IJibbieOrganisation } from 'typings/doenkids/doenkids';
import { IJibbieOrganizationState, JibbieOrganizationStore } from './jibbie-organization.store';

@Injectable({ providedIn: 'root' })
export class JibbieOrganizationQuery extends QueryEntity<IJibbieOrganizationState, IJibbieOrganisation> {
  constructor(protected store: JibbieOrganizationStore) {
    super(store);
  }
}
