import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IProgramTemplateBundle } from 'typings/doenkids/doenkids';

export interface IProgramTemplateBundleWithAccesLevel extends IProgramTemplateBundle {
  access_level: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-bundle' })
export class ProgramTemplateBundleStore extends Store<IProgramTemplateBundleWithAccesLevel> {
  constructor() {
    super({});
  }
}
