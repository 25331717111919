import { Pipe, PipeTransform } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { EOrganizationUnitType } from '../components/dialogs/add-organization-dialog/add-organization-dialog.component';

@Pipe({
  name: 'ou_type_name',
})
export class OrganizationUnitTypeNamePipe implements PipeTransform {
  transform(value: EOrganizationUnitType): string {
    switch (value) {
      case EOrganizationUnitType.ORGANIZATION:
        return _('organization_unit.organization');
      case EOrganizationUnitType.LOCATION:
        return _('organization_unit.location');
      case EOrganizationUnitType.GROUP:
        return _('organization_unit.group');
      case EOrganizationUnitType.ROOT:
        return _('organization_unit.root');
      default:
        return _('organization_unit.unknown');
    }
  }
}
