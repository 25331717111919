import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IProgramTemplateListResponse } from 'typings/api-activity';
import { ProgramTemplateListStore } from './program-template-list.state';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramTemplateListService {
  constructor(private store: ProgramTemplateListStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll({
    organizationUnitId,
    limit = 100,
    skip = 0,
    sortField = 'created_at',
    sortDirection = 'desc',
    nodeOnly = false,
    archived = false,
    programCategoryId,
    statusId,
    activityTypeId,
    language,
    doNotPersist = false,
    revoked,
  }: {
    organizationUnitId: number,
    limit: number,
    skip: number,
    sortField?: string,
    sortDirection?: SortDirection,
    nodeOnly?: boolean,
    archived?: boolean,
    programCategoryId?: number,
    statusId?: number,
    activityTypeId?: number,
    language?: string,
    doNotPersist?: boolean,
    revoked?: boolean,
  }) {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      '/activity/program-template', limit, skip, sortField, sortDirection,
      {
        organizationUnitId,
        nodeOnly,
        archived,
        programCategoryId,
        activityTypeId,
        statusId,
        language,
        revoked,
      },
    )) as IProgramTemplateListResponse;

    if (!doNotPersist) {
      this.store.set(response?.items ?? []);
    }
    return response;
  }

  reOrder(templateId: number, templateAsReference: number, placement: 'before' | 'after') {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program-template/${templateId}/reorder/${templateAsReference}?placement=${placement}`));
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
