import {
  Component, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PaginationInstance } from 'ngx-pagination';
import { Subject, Observable, interval } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UserFetchAllActiveQuery } from 'src/api/customer/user-fetch-all-active/user-fetch-all-active.query';
import { UserFetchAllActiveService } from 'src/api/customer/user-fetch-all-active/user-fetch-all-active.service';
import { IReloadEvent, IField } from 'src/components/layout/sortable-list/sortable-list.component';
import { IUserActive } from 'typings/doenkids/doenkids';
import { TranslateService } from '../../../app/utils/translate.service';

const FIELDS = [
  {
    label: _('active_users.field.who'),
    field: 'email',
    width: '20%',
  },
  {
    label: _('active_users.field.when'),
    field: 'last_seen',
    width: '20%',
    hideLessThanMedium: true,
  },
  {
    label: _('active_users.field.login_count'),
    field: 'auth0_login_count',
    width: '5%',
    hideLessThanMedium: true,
  },
  {
    label: _('active_users.field.for'),
    field: 'organization_unit_id',
    width: '15%',
    hideLessThanMedium: true,
  },
  {
    label: _('active_users.field.what'),
    field: 'user_role',
    width: '40%',
  },
];

@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveUsersComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject();

  public eventList: IUserActive[];

  public currentPage: number;

  public SEARCH_LIMIT = 50;

  private lastReloadEvent: IReloadEvent;

  public list$: Observable<IUserActive[]>;

  public metaData$: Observable<{ total: number, limit: number, skip: number }>;

  public selectedOrganisationUnitId: number | undefined;

  public lastUpdated = new Date();

  public searchControl: UntypedFormControl;

  public fields: IField[] = [];

  /**
   * The configuration object for the pagination of the list.
   */
  public paginationConfig$: Observable<PaginationInstance>;

  constructor(
    private $userActive: UserFetchAllActiveService,
    private $translateService: TranslateService,
    private userActiveQuery: UserFetchAllActiveQuery,
    private route: ActivatedRoute,
  ) {
    this.searchControl = new UntypedFormControl('');
    this.list$ = this.userActiveQuery.selectAll();
    this.metaData$ = this.userActiveQuery.getMetadata();

    this.paginationConfig$ = this.metaData$.pipe(
      map((metaData) => {
        const paginationConfig: PaginationInstance = {
          currentPage: (metaData.total > metaData.limit) && metaData.skip !== 0 ? (Math.ceil(metaData.skip / metaData.limit) + 1) : 1,
          itemsPerPage: metaData.limit,
          totalItems: metaData.total,
        };
        return paginationConfig;
      }),
    );
  }

  ngOnInit() {
    const { params } = this.route.snapshot;
    this.selectedOrganisationUnitId = +params.ou;

    this.$userActive.fetch({
      limit: 100,
      skip: 0,
      sortField: 'last_seen',
      sortDirection: 'desc',
    });
    this.list$.pipe(
      takeUntil(this.stop$),
    ).subscribe((list) => {
      this.eventList = list;
    });

    interval(5000).pipe(
      takeUntil(this.stop$),
    ).subscribe(() => {
      this.getList(this.lastReloadEvent);
    });

    this.$translateService.onInitialTranslationAndLangOrTranslationChange$
      .pipe(takeUntil(this.stop$))
      .subscribe((translationEvent) => {
        this.fields = FIELDS.map((field) => ({ ...field, label: translationEvent.translations[field.label] }));
      });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  public async getList($event: IReloadEvent) {
    this.lastReloadEvent = {
      currentPage: this.currentPage,
      limit: $event.limit,
      skip: $event.skip,
      sortDirection: $event.sortDirection,
      sortField: $event.sortField,
    };

    this.$userActive.fetch({
      limit: $event.limit,
      skip: $event.skip,
      sortField: $event.sortField,
      sortDirection: $event.sortDirection,
      search: this.searchControl.value,
    }, !Number.isNaN(this.selectedOrganisationUnitId) ? this.selectedOrganisationUnitId : undefined);

    this.lastUpdated = new Date();
  }
}
