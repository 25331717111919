import { ProgramService } from 'src/api/activity/program/program.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as dayjs from 'dayjs';

export interface IUnpublishedProgramCount {
  count: number;
  start: Date;
  end: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ProgramProvider {
  constructor(
    private $program: ProgramService,
  ) {}

  unpublishedProgramCount$ = new BehaviorSubject<IUnpublishedProgramCount>({
    count: 0,
    start: dayjs().toDate(),
    end: dayjs().toDate(),
  });

  async fetchUnpublishedProgramCount(organizationUnitId: number) {
    const start = dayjs().toDate();
    const end = dayjs().endOf('week').add(2, 'weeks').toDate();
    const result = await this.$program.countProgramStatus(
      organizationUnitId,
      [1, 2],
      start,
      end,
    );
    const count = (result?.count) ?? 0;

    this.unpublishedProgramCount$.next({
      count,
      start,
      end,
    });
    return count;
  }

  setUnpublishedCount(count: number) {
    this.unpublishedProgramCount$.next({
      count,
      start: dayjs().toDate(),
      end: dayjs().toDate(),
    });
  }
}
