import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitCustomerSymbolService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  update(organizationUnitId: number, customerSymbolId: number) {
    return firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/customer-symbol/${customerSymbolId}`));
  }

  archive(organizationUnitId: number, customerSymbolId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/organization-unit/${organizationUnitId}/customer-symbol/${customerSymbolId}`));
  }
}
