import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionProvider } from 'src/providers/permission.provider';
import { combineLatest, firstValueFrom } from 'rxjs';
import { Location as ALocation } from '@angular/common';

export const canAccessInsightsGuard = async () => {
  const location = inject(ALocation);
  const $permission = inject(PermissionProvider);
  const router = inject(Router);

  const permissions = await firstValueFrom(combineLatest([$permission.hasAnalyticsPermission$, $permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$]));

  const hasPermission = !permissions.includes(false);

  if (!hasPermission && router.navigated) {
    location.back();
  } else if (!hasPermission) {
    router.navigate(['/']);
  }

  return hasPermission;
};
