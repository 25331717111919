<div fxLayout="row" fxLayoutAlign="space-between start">
  <h2 mat-dialog-title>{{ 'generic.download' | translate }}</h2>
  <button mat-button class="close-icon" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>

<section class="checkbox-section">
  <mat-radio-group [formControl]="documentType">
    <mat-radio-button class="checkbox" value="pdf" [checked]="true">
      {{ 'generic.pdf' | translate | uppercase }}
    </mat-radio-button>
    <mat-radio-button *ngIf="activityDownloadForm.get('type').value === 'material'" class="checkbox" value="excel">{{ 'generic.excel' | translate | titlecase }}</mat-radio-button>
  </mat-radio-group>
</section>

<div *ngIf="!loading" mat-dialog-content [formGroup]="activityDownloadForm">
  <p>{{ 'download_activity_pdf_dialog.description' | translate: {activityName: activity?.name} }}</p>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'download_activity_pdf_dialog.type.label' | translate }}</mat-label>
    <mat-select formControlName="type">
      <mat-option
        *ngFor="let typeOption of pdfOptions"
        [value]="typeOption.value"
      >
        {{ typeOption.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="loading" mat-dialog-content>
  <p>{{ 'download_activity_pdf_dialog.loading' | translate }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button [disabled]="loading" (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <div fxLayout="row">
    <button
      mat-button
      color="primary"
      (click)="confirm()"
      [disabled]="activityDownloadForm.invalid || loading"
      cdkFocusInitial
    >
      {{ 'generic.ok' | translate }}
    </button>
    <mat-progress-spinner diameter="30" *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
