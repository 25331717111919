<mat-toolbar class="doenkids-toolbar" *ngIf="title">
  <mat-icon *ngIf="titleIcon" class="title-icon">{{titleIcon}}</mat-icon>
  <h2 class="display-3">{{title}}</h2>
  <div *ngIf="query" class="message">
    {{ 'sortable_list.result_count' | translate: {resultCount: list$?.length, query: query} }}
  </div>
  <span fxFlex="1 1 auto"></span>
  <ng-content select="[header]"></ng-content>
</mat-toolbar>
<mat-spinner class="loading-spinner" [diameter]="50" *ngIf="( listLoading | async )"></mat-spinner>
<div class="mat-content animated fadeIn">
  <mat-card appearance="outlined" class="doenkids-filterable-list" *ngIf="!( listLoading | async ) && list$ && fields">
    <mat-card-content style="overflow: auto;">
      <ng-content></ng-content>

      <table matSort (matSortChange)="changeSortDirection($event)">
        <colgroup>
          <ng-container *ngFor="let field of fields">
            <col
              *ngIf="!(field?.hide) || !( field?.hide | async )"
              [fxHide.lt-md]="field.hideLessThanMedium"
              [fxHide.lt-sm]="field.hideLessThanSmall || field.hideLessThanMedium"
              [width]="field.width">
          </ng-container>
        </colgroup>
        <thead>
          <tr>
            <ng-container *ngFor="let field of fields">
              <th
                *ngIf="!(field?.hide) || !( field?.hide | async )"
                [fxHide.lt-md]="field.hideLessThanMedium"
                [fxHide.lt-sm]="field.hideLessThanSmall || field.hideLessThanMedium"
                style="display:table-cell"
                [mat-sort-header]="field.field"
                [disabled]="field.disableSort">
                {{field.label}}
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngTemplateOutlet="fieldItemsTemplate; context: {$implicit: list$}"></ng-container>
        </tbody>
      </table>
    </mat-card-content>
    <mat-card-actions *ngIf="list$.length < metadata?.total">
      <pagination-controls
        [id]="id"
        [nextLabel]="'sortable_list.pagination.next' | translate"
        [previousLabel]="'sortable_list.pagination.previous' | translate "
        (pageChange)="paginate($event)"
      ></pagination-controls>
    </mat-card-actions>
  </mat-card>
  <div *ngIf="!( listLoading | async ) && list$ && !fields">
    <ng-container *ngTemplateOutlet="fieldItemsTemplate; context: {$implicit: list$}"></ng-container>
    <ng-container *ngIf="list$.length < metadata?.total">
      <pagination-controls
        [id]="id"
        [nextLabel]="'sortable_list.pagination.next' | translate"
        [previousLabel]="'sortable_list.pagination.previous' | translate"
        (pageChange)="paginate($event)"
      ></pagination-controls>
    </ng-container>
  </div>
</div>
