import { ReviewApprovalComponent } from 'src/components/pages/review-approval/review-approval.component';
import { BaseNewsItemsComponent } from 'src/components/pages/base-news-items/base-news-items.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from 'src/components/pages/home/home.component';
import { LoginComponent } from 'src/components/pages/login/login.component';
import { ActivitySearchComponent } from 'src/components/pages/activity-search/activity-search.component';
import { ActivityDetailsComponent } from 'src/components/pages/activity-details/activity-details.component';
import { canLeavePageGuard } from 'src/guards/can-leave-page.guard';
import { isActivityOwner } from 'src/guards/is-activity-owner.guard';
import { canEditActivityGuard } from 'src/guards/can-edit-activity';
import { DoenKidsCalendarComponent } from 'src/components/pages/calendar/calendar.component';
import { ActivityPreviewComponent } from 'src/components/pages/activity-preview/activity-preview.component';
import { ProgramDetailsComponent } from 'src/components/pages/program-details/program-details.component';
import { UserListComponent } from 'src/components/pages/user-list/user-list.components';
import { hasAccessToOrganizationGuard } from 'src/guards/has-access-to-organization-guard';
import { UserDetailsComponent } from 'src/components/pages/user-details/user-details.component';
import { doenKidsAuthGuard } from 'src/guards/doenkids-auth-guard';
import { ProgramTemplatePreviewComponent } from 'src/components/pages/program-template-preview/program-template-preview.component';
import { ProgramTemplateBundlePreviewComponent } from 'src/components/pages/program-template-bundle-preview/program-template-bundle-preview.component';
import { OrganizationUnitDetailsComponent } from 'src/components/pages/organization-unit-details/organization-unit-details.component';
import { SettingsOverviewComponent } from 'src/components/pages/settings-overview/settings-overview.component';
import { ProgramTemplatesComponent } from 'src/components/pages/program-templates/program-templates.component';
import { BaseActivitySearchComponent } from 'src/components/pages/base-activity-search/base-activity-search.component';
import { BaseActivityPreviewComponent } from 'src/components/pages/base-activity-preview/base-activity-preview.component';
import { ActiveUsersComponent } from 'src/components/pages/active-users/active-users.component';
import { isAdminLevelGuard } from 'src/guards/is-admin-level.guard';
import { UserActivityComponent } from 'src/components/pages/user-activity/user-activity.component';
import { ActivityEventLogComponent } from 'src/components/pages/activity-event-log/activity-event-log.component';
import { ProgramEventLogComponent } from 'src/components/pages/program-event-log/program-event-log.component';
import { ProgramTemplateReorderComponent } from 'src/components/pages/program-template-reorder/program-template-reorder.component';
// import { MediaTagsComponent } from 'src/components/pages/media-tags/media-tags.component';
import { InsightsProgramStatusProgramListComponent } from 'src/components/pages/insights-program-status-program-list/insights-program-status-program-list.component';
import { PinbordComponent } from 'src/components/pages/pinbord/pinbord.component';
import { PinbordFolderDetailsComponent } from 'src/components/pages/pinbord-folder-details/pinbord-folder-details.component';
import { ActivityOverviewComponent } from 'src/components/pages/activity-overview/activity-overview.component';
import { isOrganizationManagerGuard } from 'src/guards/is-organization-manager.guard';
import { ActivityReviewOverviewComponent } from 'src/components/pages/activity-review/activity-review-overview/activity-review-overview.component';
import { ActivityReviewComponent } from 'src/components/pages/activity-review/activity-review.component';
import { canAccessInsightsGuard } from '../guards/can-access-insights-guard';
import { NewsItemViewComponent } from '../components/shared/news-item-view/news-item-view.component';
import { hasAccessToOrganizationOrIsPublicGuard } from 'src/guards/has-access-to-organization-or-is-public-guard';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { LayoutComponent } from '../components/layout/layout/layout.component';

const routes = [
  {
    path: 'login',
    title: _('routes.login'),
    component: LoginComponent,
    data: { state: 'login' },
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [doenKidsAuthGuard],
    children: [
      {
        path: '',
        title: _('routes.home'),
        component: HomeComponent,
        data: { state: 'home' },
      },
      {
        path: 'base-activities',
        title: _('routes.base_activities'),
        component: BaseActivitySearchComponent,
        data: { state: 'base-activities' },
        children: [
          {
            path: 'preview/:id',
            title: _('routes.base_activities.preview'),
            component: BaseActivityPreviewComponent,
            data: { state: 'base-activity-preview', titleParameters: { activity: 'id' } },
          },
        ],
      },
      // Support legacy base-activity routes
      //
      {
        path: 'base-activities/:id',
        title: _('routes.base_activities.preview'),
        redirectTo: 'base-activities/preview/:id',
      },
      {
        path: 'activity-overview',
        title: _('routes.activities.overview'),
        component: ActivityOverviewComponent,
        data: { state: 'activity-overview' },
      },
      {
        path: 'activities',
        children: [
          {
            path: '',
            title: _('routes.activities.search'),
            component: ActivitySearchComponent,
            data: { state: 'activities' },
          },
          {
            path: 'preview/:id',
            title: _('routes.activities.preview'),
            component: ActivityPreviewComponent,
            data: { state: 'activity-preview', titleParameters: { activity: 'id' } },
          },
          {
            path: 'review/:id/overview',
            title: _('routes.activities.review.overview'),
            component: ActivityReviewOverviewComponent,
            data: { state: 'activity-review', titleParameters: { review: 'id' } },
          },
          {
            path: 'review/:id/create',
            title: _('routes.activities.review.create'),
            component: ActivityReviewComponent,
            data: { state: 'activity-review-create', titleParameters: { review: 'id' } },
          },
        ],
      },
      // Support legacy activity routes
      //
      {
        path: 'activities/:id',
        redirectTo: 'activities/preview/:id',
      },
      {
        path: 'activities/:id/edit',
        title: _('routes.activity.details'),
        component: ActivityDetailsComponent,
        data: { state: 'activity-details', titleParameters: { activity: 'id' } },
        canDeactivate: [canLeavePageGuard],
        canActivate: [canEditActivityGuard, isActivityOwner],
      },
      {
        path: 'activities/:id/events',
        title: _('routes.activity.events'),
        component: ActivityEventLogComponent,
        data: { state: 'activity-event-log', titleParameters: { activity: 'id' } },
        canActivate: [canEditActivityGuard],
      },
      {
        path: 'organization/:ou/users',
        title: _('routes.organization.users'),
        component: UserListComponent,
        data: { state: 'users', titleParameters: { organizationUnit: 'ou' } },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'organization/:ou/users/:id',
        title: _('routes.organization.user.detail'),
        component: UserDetailsComponent,
        data: { state: 'user-details', titleParameters: { organizationUnit: 'ou', user: 'id' } },
      },
      {
        path: 'organization/:ou/users/:id/activity',
        title: _('routes.organization.user.activity'),
        component: UserActivityComponent,
        data: { state: 'user-activity', titleParameters: { organizationUnit: 'ou', user: 'id' } },
        canActivate: [isAdminLevelGuard],
      },
      {
        path: 'calendar',
        title: _('routes.calendar'),
        component: DoenKidsCalendarComponent,
        data: { state: 'calendar' },
      },
      {
        path: 'organization/:ou/program/:id',
        title: _('routes.organization.program.detail'),
        component: ProgramDetailsComponent,
        data: { state: 'program-details', titleParameters: { organizationUnit: 'ou', program: 'id' } },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'organization/:ou/program/:id/events',
        title: _('routes.organization.program.events'),
        component: ProgramEventLogComponent,
        data: { state: 'program-events', titleParameters: { organizationUnit: 'ou', program: 'id' } },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'settings',
        title: _('routes.settings'),
        component: SettingsOverviewComponent,
        data: { state: 'settings' },
      },
      {
        path: 'organization/:ou/overview',
        title: _('routes.organization.details'),
        component: OrganizationUnitDetailsComponent,
        data: { state: 'organization-unit', titleParameters: { organizationUnit: 'ou' } },
        canActivate: [hasAccessToOrganizationGuard],
        CanDeactivate: [canLeavePageGuard],
      },
      {
        path: 'organization/:ou/activity',
        title: _('routes.organization.activity'),
        component: UserActivityComponent,
        data: { state: 'organization-unit-activity', titleParameters: { organizationUnit: 'ou' } },
        canActivate: [isAdminLevelGuard],
      },
      {
        path: 'organization/:ou/templates',
        title: _('routes.organization.templates'),
        component: ProgramTemplatesComponent,
        data: { state: 'program-templates', titleParameters: { organizationUnit: 'ou' } },
        canActivate: [hasAccessToOrganizationOrIsPublicGuard],
      },
      {
        path: 'organization/:ou/base/templates',
        title: _('routes.organization.base_templates'),
        component: ProgramTemplatesComponent,
        data: {
          state: 'base-program-templates',
          baseOnly: true,
          titleParameters: { organizationUnit: 'ou' },
        },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'organization/:ou/template/category/:id/reorder/:activityTypeId',
        title: _('routes.organization.templates.reorder'),
        component: ProgramTemplateReorderComponent,
        data: { state: 'program-template-reorder', titleParameters: { organizationUnit: 'ou', category: 'id' } },
        canActivate: [hasAccessToOrganizationGuard],
      },
      // Disable the organization guard on program templates
      // So that we can handle switching to the current organization in the component itself
      // (this is necessary for linking from news items for example)
      {
        path: 'organization/:ou/template/:id',
        title: _('routes.organization.template.preview'),
        component: ProgramTemplatePreviewComponent,
        data: { state: 'program-template-details', titleParameters: { organizationUnit: 'ou', template: 'id' } },
      },
      {
        path: 'organization/:ou/bundle/:id',
        title: _('routes.organization.bundle.preview'),
        component: ProgramTemplateBundlePreviewComponent,
        data: { state: 'program-template-bundle-details', titleParameters: { organizationUnit: 'ou', bundle: 'id' } },
      },
      {
        path: 'active-users',
        title: _('routes.active_users'),
        component: ActiveUsersComponent,
        data: { state: 'active-users' },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'organization/:ou/active-users',
        title: _('routes.organization.active_users'),
        component: ActiveUsersComponent,
        data: { state: 'organization-unit-active-users' },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'organization/:ou/insights-program-list/:type',
        title: _('routes.organization.insights_program_list'),
        component: InsightsProgramStatusProgramListComponent,
        data: { state: 'insights-program-list', titleParameters: { organizationUnit: 'ou' } },
        canActivate: [hasAccessToOrganizationGuard],
      },
      /* {
        path: 'organization/:ou/media-tags',
        component: MediaTagsComponent,
        data: { state: 'organization-media-tags' },
        canActivate: [HasAccessToOrganizationGuard],
      }, */
      {
        path: 'organization/:ou/insights',
        loadChildren: async () => (await import('./insights/insights.module')).InsightsModule,
        CanActivate: [canAccessInsightsGuard],
      },
      {
        path: 'base-news-items',
        title: _('routes.base_news_items'),
        component: BaseNewsItemsComponent,
        data: { state: 'base-news-items' },
        CanActivate: [isOrganizationManagerGuard],
      },
      {
        path: 'news-item/:id',
        title: _('routes.news_item'),
        component: NewsItemViewComponent,
        data: { state: 'news-item-view', titleParameters: { newsItem: 'id' } },
      },
      {
        path: 'organization/:ou/pinbord',
        title: _('routes.organization.pinboards'),
        component: PinbordComponent,
        data: { state: 'pinbord', titleParameters: { organizationUnit: 'ou' } },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'organization/:ou/pinbord/:id',
        title: _('routes.organization.pinboard.details'),
        component: PinbordFolderDetailsComponent,
        data: { state: 'pinbord-folder-details', titleParameters: { organizationUnit: 'ou', pinboard: 'id' } },
        canActivate: [hasAccessToOrganizationGuard],
      },
      {
        path: 'review-approval',
        title: _('routes.review_approval'),
        component: ReviewApprovalComponent,
        data: { state: 'review-approval' },
        CanActivate: [isAdminLevelGuard],
      },
    ],
  },
  {
    path: '**', redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
