import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ProgramTemplateBundleStore, IProgramTemplateBundleWithAccesLevel } from './program-template-bundle.store';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleQuery extends Query<IProgramTemplateBundleWithAccesLevel> {
  hasWriteAccess$() {
    return this.select().pipe(
      map((value) => value && value.access_level === 'WRITE'),
    );
  }

  constructor(protected store: ProgramTemplateBundleStore) {
    super(store);
  }
}
