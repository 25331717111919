import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProgramCategoryListQuery } from 'src/api/activity/program-category-list/program-category-list.query';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import {
  takeUntil, filter, map, tap,
} from 'rxjs/operators';
import { isNil, isArray } from 'lodash';
import { IProgramCategory } from 'typings/doenkids/doenkids';
import { ProgramCategoryListService } from 'src/api/activity/program-category-list/program-category-list.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PermissionProvider } from 'src/providers/permission.provider';
import { IProgramCategoryWithOrganizationUnit } from 'typings/api-activity';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';

@Component({
  selector: 'app-program-category-list',
  templateUrl: './program-category-list.component.html',
  styleUrls: ['./program-category-list.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class ProgramCategoryListComponent implements OnInit {
  private stop$: Subject<void> = new Subject();

  public selectedOUId: number;

  public categoryMaxLength = 100;

  public inlineEditingIndex = null;

  public isAdmin$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public form: UntypedFormGroup;

  public editingCategoryControl: UntypedFormControl ;

  public categories: IProgramCategoryWithOrganizationUnit[] = [];

  public categories$: Observable<IProgramCategoryWithOrganizationUnit[]>;

  constructor(
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
    private programCategoryListService: ProgramCategoryListService,
    private programCategoryListQuery: ProgramCategoryListQuery,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.isAdmin$ = this.$session.isAdmin$;
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$;
    this.form = this.fb.group({
      categoryName: [''],
      categories: [],
    });
    this.editingCategoryControl = this.fb.control('');
    this.categories$ = this.programCategoryListQuery.selectAll().pipe(
      takeUntil(this.stop$),
      filter((values) => !isNil(values)),
      map((categories) => categories.filter((category) => category.archived === false)),
      tap((categories) => {
        if (isArray(categories)) {
          this.form.controls.categories = this.fb.array(categories.map((category) => this.fb.group(category)));
        }
      }),
    );
  }

  ngOnInit() {
    this.$session.currentOuId$.pipe(takeUntil(this.stop$)).subscribe((organizationUnitId) => {
      this.selectedOUId = organizationUnitId;
      this._fetchAllCategoryName();
      this.categories$.pipe(
        takeUntil(this.stop$),
      ).subscribe((categories) => {
        this.categories = [...categories];
      });
    });
  }

  async drop(event: CdkDragDrop<string[]>) {
    const allCategories = await firstValueFrom(this.programCategoryListQuery.selectAll());

    const draggedCategory: IProgramCategory = allCategories[event.previousIndex];
    const droppedCategory: IProgramCategory = allCategories[event.currentIndex];
    let placement: 'before' | 'after' = 'before';

    if (event.currentIndex > event.previousIndex) {
      placement = 'after';
    }

    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);

    await this.programCategoryListService.reShuffle(draggedCategory.id, droppedCategory.id, placement);
    this._fetchAllCategoryName();
  }

  _fetchAllCategoryName() {
    return this.programCategoryListService.fetchAll(this.selectedOUId, {
      limit: 50, skip: 0, sortField: 'order', sortDirection: 'desc',
    });
  }

  setEditingIndex(index: number) {
    this.inlineEditingIndex = index;
    this.editingCategoryControl.setValue(this.form.get('categories').value[index].name);
  }

  unsetEditingIndex() {
    this.inlineEditingIndex = null;
    this.editingCategoryControl.setValue('');
  }

  async add() {
    const categoryName = this.form.controls.categoryName.value;
    if (categoryName) {
      const theCategoryAlreadyExists = await this._categoryAlreadyExists(categoryName);
      if (theCategoryAlreadyExists) {
        this.$i18nToastProvider.error(_('program_category.add.already_exists'));
      } else {
        const response = await this.programCategoryListService.create(this.selectedOUId, categoryName);
        if (isNil(response)) {
          this.$i18nToastProvider.error(_('program_category.add.failed'), { categoryName });
        }
        this._fetchAllCategoryName();
      }
    }

    // Reset the input value
    //
    this.form.controls.categoryName.setValue('');
  }

  async _categoryAlreadyExists(categoryName: string) {
    let existInCategoryList = false;
    const currentCategories = await firstValueFrom(this.categories$);
    if (categoryName && isArray(currentCategories)) {
      if (!isNil(currentCategories.find((category: IProgramCategory) => category.name.toLowerCase() === categoryName.toLowerCase()))) {
        existInCategoryList = true;
      }
    }
    return existInCategoryList;
  }

  async removeCategory(category: IProgramCategory) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program_category.remove.confirm.title')),
        description: this.$translateService.instant(_('program_category.remove.confirm.description'), { name: category.name }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result !== 'confirm') {
      return;
    }
    await this.programCategoryListService.archive(category.id);
    await this._fetchAllCategoryName();
  }

  async update(category: IProgramCategory) {
    if (category) {
      await this.programCategoryListService.update(category.id, { ...category, name: this.editingCategoryControl.value });
      this._fetchAllCategoryName();
    }
    this.unsetEditingIndex();
  }
}
