import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import {
  ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityReviewService } from 'src/api/activity/activity-review/activity-review.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IActivityReviews } from 'typings/doenkids/doenkids.d';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { REVIEW_MAX_CHARS } from '../../review-input/review-input.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewComponent {
  @Input() review: IActivityReviews;

  @Output() delete = new EventEmitter<number>();

  get isAuthor() {
    return this.session.getCurrentUser()?.user?.id === this.review.created_by_user_id;
  }

  @HostBinding('class') get classes() {
    return this.isAuthor ? 'authored' : '';
  }

  public maxChars = REVIEW_MAX_CHARS;

  public commentFormControl: FormControl<string>;

  public reviewCommentLoading$ = new BehaviorSubject(false);

  public isAdmin$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private $review: ActivityReviewService,
    private session: DoenkidsSessionProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.isAdmin$ = this.session.isAdmin$;
  }

  editReview() {
    const { id } = this.route.snapshot.params;

    if (!id) {
      return;
    }

    this.router.navigate([`activities/review/${id}/create`], {
      queryParams: {
        reviewId: this.review?.id ?? 'null',
      },
    });
  }

  async deleteReview() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('review.delete.dialog.title')),
        description: this.$translateService.instant(_('review.delete.dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const response = await this.$review.delete(this.review.id);
      if (response) {
        this.$i18nToastProvider.success(_('review.delete.success'));
        this.delete.emit(this.review.id);
      } else {
        this.$i18nToastProvider.error(_('review.delete.failed'));
      }
    }
  }

  startCommenting() {
    this.commentFormControl = new FormControl<string>(this.review.comment);
  }

  endCommenting() {
    this.commentFormControl = null;
  }

  async saveReviewComment() {
    const { value: comment } = this.commentFormControl;

    if (comment === this.review.comment) {
      this.endCommenting();
      return;
    }

    this.reviewCommentLoading$.next(true);

    if (this.review.comment_id) {
      await this.$review.updateComment(this.review.comment_id, comment);
    } else {
      await this.$review.createComment(this.review.id, comment);
    }

    this.reviewCommentLoading$.next(false);
    this.endCommenting();
  }
}
