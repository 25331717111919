<app-doenkids-menu-bar [showBackButton]="true">
  <div right>
    <button
      *ngIf="(isAdmin$ | async)"
      mat-icon-button
      type="button"
      (click)="goToActivities()"
      [matTooltip]="'user_details.activity.show.tooltip' | translate"
    >
    <mat-icon>assignment</mat-icon>
  </button>
  </div>
</app-doenkids-menu-bar>
<div class="form-container">
  <form [formGroup]="form">
    <div class="user-details" fxLayout="row" fxLayoutGap="20px" fxLayout.lt-md="column">
      <div fxFlex="50">
        <app-asset-upload purpose="avatar" identifier="avatar" (uploaded)="addFeaturedImage($event)" [disabled]="!(canEditUsers$ | async)">
          <ng-template let-uploadData="uploadData">
            <div fxFlex fxLayout="column" fxLayoutGap="20px" fxLayout.lt-md="row" fxLayout.lt-sm="column"
              fxLayoutAlign="space-between center">
              <app-doenkids-image [maxWidth]="200" [maxHeight]="400" [preview]="true"
                [mediaItem]="( mediaItem$ | async )" [defaultPlaceHolder]="avatarPlaceholder">
              </app-doenkids-image>
              <app-select-media [uploadData]="uploadData" identifier="avatar" purpose="avatar" (selected)="updateFeaturedImage($event)" [disabled]="!(canEditUsers$ | async)">
              </app-select-media>
            </div>
          </ng-template>
        </app-asset-upload>
      </div>
      <div class="user-details-fields">
        <div *ngIf="canEditUsers$ | async">
          <div class="organization-selector" fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'user.organization.label' | translate }}</mat-label>
              <input fxFill matInput [value]="(user$ | async)?.organization_unit" readonly>
            </mat-form-field>
            <button mat-icon-button (click)="changeOrganization()" [matTooltip]="'user.organization.select.tooltip' | translate">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
        <app-user-form fxFlex="100" [fxFlex.lt-md]="90" formControlName="user" [disabled]="!(canEditUsers$ | async)">
          <button
            *ngIf="(isAdmin$ | async)"
            color="secondary"
            mat-button
            type="button"
            (click)="syncAuth0Email()"
            [disabled]="!(auth0StatusIsEmailMismatch$ | async)"
          >
            {{ 'user_details.sync_email.label' | translate }}
          </button>
          <button
            *ngIf="!(isReader$ | async)"
            color="secondary"
            mat-button
            type="button"
            (click)="linkWithOrganization()"
            [disabled]="!(canEditUsers$ | async)"
          >
            {{ 'generic.link' | translate | uppercase }}
          </button>
          <button
            *ngIf="!(isReader$ | async)"
            color="primary"
            mat-button
            type="button"
            (click)="saveDetails()"
            [disabled]="!form.dirty || !form.valid || !(canEditUsers$ | async)"
          >
            {{ 'generic.save' | translate | uppercase }}
          </button>
        </app-user-form>
      </div>
    </div>
  </form>
</div>

<app-sortable-list class="not-selectable" [fields]="(fields$ | async)" [list$]="(user$  | async)?.linked_organization_units">
  <ng-container *appSortableListFields="let list">
    <tr *ngFor="let location of list">
      <td>
        <div class="cell-wrapper">{{location.name}}</div>
      </td>
      <td>
        <div class="cell-wrapper">{{location.activity_type }}</div>
      </td>
      <td>
        <div class="cell-wrapper">{{ getOrganizationNodePath(location.node_path_name) }}</div>
      </td>

      <td class="action-col">
        <button *ngIf="!(isReader$ | async)" mat-icon-button (click)="removeLocation( location.id )" [disabled]="!(canEditUsers$ | async)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="list?.length === 0">
      <td [attr.colspan]="(fields$ | async).length">
        <div class="cell-wrapper">{{ 'user_details.no_results' | translate }}</div>
      </td>
    </tr>
  </ng-container>
</app-sortable-list>
