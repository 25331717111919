import {
  Component, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { DoenKidsAuth0Provider } from 'src/providers/auth0.provider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from 'src/app/utils/translate.service';
import { debounce } from 'lodash';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  private stop$ = new Subject();

  private debouncedSetHeight = debounce(this.setHeight, 300);

  constructor(
    private $auth0Provider: DoenKidsAuth0Provider,
    private $translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.$translateService
      .onInitialLangAndLangChange$
      .pipe(takeUntil(this.stop$))
      .subscribe((langChangeEvent) => this.$auth0Provider.show(langChangeEvent.lang));
  }

  ngAfterViewInit() {
    document.addEventListener('resize', this.debouncedSetHeight);
    this.setHeight();
  }

  ngOnDestroy() {
    document.removeEventListener('resize', this.debouncedSetHeight);
    this.stop$.next(true);
  }

  private setHeight() {
    const notSupportedNotifications = document.getElementsByTagName('app-browser-not-supported');
    const notSupportedNotification = notSupportedNotifications?.item(0) as HTMLElement;
    const auth0Ref = document.getElementById('auth0-container');

    let auth0Height = '100vh';

    if (notSupportedNotification && auth0Ref) {
      auth0Height = `calc(100vh - ${notSupportedNotification.clientHeight}px)`;
    }

    if (auth0Ref) {
      auth0Ref.style.height = auth0Height;
    }
  }
}
