<h2 *ngIf="title">{{title}}</h2>
<div class="user-details-form" [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'user.email.label' | translate }}</mat-label>
    <input matInput name="userEmail" formControlName="email" [readonly]="form.get('id').value !== null">
    <mat-error *ngIf="form.get('email').invalid">
      <ng-container *ngIf="form.get('email').errors[ 'required' ]">{{ 'user.email.required' | translate }}</ng-container>
      <ng-container *ngIf="form.get('email').errors[ 'email' ]">{{ 'user.email.invalid' | translate }}</ng-container>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="( isManagement$ | async )">
    <mat-label>{{ 'user.role.label' | translate }}</mat-label>
    <mat-select formControlName="user_role">
      <mat-option value="user">{{ 'user.role.user' | translate }}</mat-option>
      <mat-option value="reader">{{ 'user.role.reader' | translate }}</mat-option>
      <mat-option *ngIf="isAdmin$ | async" value="administrator">{{ 'user.role.admin' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="options">
    <mat-checkbox name="user_active" formControlName="active">{{ 'user.active.label' | translate }}</mat-checkbox>
    <ng-content></ng-content>
  </div>
</div>
