import { IActivityReviewScoreType } from 'typings/doenkids/doenkids.d';
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface IActivityScoreTypeState extends EntityState<IActivityReviewScoreType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity_score_type' })
export class ActivityScoreTypeStore extends EntityStore<IActivityScoreTypeState, IActivityReviewScoreType> {
  constructor() {
    super();
  }
}
