/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ICustomerSymbol } from 'typings/doenkids/doenkids';

export interface IOrganizationUnitSymbolListState extends EntityState<ICustomerSymbol> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-unit-symbol-list' })
export class OrganizationUnitSymbolListStore extends EntityStore<IOrganizationUnitSymbolListState, ICustomerSymbol> {
  constructor() {
    super();
  }
}
