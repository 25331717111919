<h3 mat-dialog-title>
  {{ 'connect_konnect_dialog.title' | translate: {
  organizationUnitType: getOUTypeName(data.organizationUnitType) | translate,
  organizationUnitName: data.organization.name
} }}
</h3>
<mat-dialog-content scrollEnd (endReached)="scrollEndReached()">
  <form [formGroup]="konnectSearch" text-center>
    <mat-form-field class="organization-name" appearance="outline">
      <mat-label>Konnect {{ getOUTypeName(data.organizationUnitType) | translate }}</mat-label>
      <input formControlName="name" matInput>
    </mat-form-field>
  </form>
  <div class="konnect-search-results">
    <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.LOCATION && konnectDetails">
      <ng-container *ngFor="let konnectLocation of (konnectLocationResults$ | async)">
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-icon mat-card-avatar svgIcon="doenkids-location"></mat-icon>
            <mat-card-title>{{konnectLocation.name}} <span class="konnect-title-suffix">({{ konnectLocation.type_oko }})</span></mat-card-title>
            <mat-card-subtitle>
              <strong>{{konnectLocation.konnect_id}} </strong>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content *ngIf="konnectLocation.street || konnectLocation.housenumber || konnectLocation.postalcode || konnectLocation.city">
            <p>
              <span *ngIf="konnectLocation.street">{{konnectLocation.street}}</span>&nbsp;
              <span *ngIf="konnectLocation.housenumber">{{konnectLocation.housenumber}}</span>
            </p>
            <p>
              <span *ngIf="konnectLocation.postalcode">{{konnectLocation.postalcode}}</span>&nbsp;
              <span *ngIf="konnectLocation.city">{{konnectLocation.city}}</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button (click)="linkKonnectLocationToOrganization(konnectLocation)" mat-button>
              {{ 'generic.link' | translate | uppercase }}
            </button>
          </mat-card-actions>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="(konnectLocationResults$ | async)?.length === 0">
        <span>{{ 'connect_konnect_dialog.no_locations_found' | translate }}</span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data.organizationUnitType === EOrganizationUnitType.GROUP && konnectDetails">
      <ng-container *ngFor="let konnectGroup of (konnectGroupResults$ | async)">
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-icon mat-card-avatar svgIcon="doenkids-group"></mat-icon>
            <mat-card-title>{{konnectGroup.name}} <span class="konnect-title-suffix">({{ konnectGroup.type_oko }})</span></mat-card-title>
            <mat-card-subtitle>
              <strong>{{konnectGroup.konnect_id}} </strong>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions>
            <button (click)="linkKonnectGroupToOrganization(konnectGroup)" mat-button>
              {{ 'generic.link' | translate | uppercase }}
            </button>
          </mat-card-actions>
        </mat-card>
      </ng-container>
      <ng-container *ngIf="(konnectGroupResults$ | async)?.length === 0">
        <span>{{ 'connect_konnect_dialog.no_groups_found' | translate }}</span>
      </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>
