import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '../app/utils/translate.service';

@Pipe({
  name: 'i18n_list',
})
export class I18nListPipe implements PipeTransform {
  constructor(private $translateService: TranslateService) {
  }

  transform(items: Array<{ name: string }>): Observable<string> {
    return this.$translateService.onInitialTranslationAndLangOrTranslationChange$
      .pipe(
        map((langChange) => items
          .map((item) => `<b>${item.name}</b>`)
          .join(` ${langChange.translations['generic.and']} `)),
      );
  }
}
