/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IActivity } from 'typings/doenkids/doenkids';
import { TUserEntityPermission } from 'typings/api-generic';

export interface IStoreActivity {
  id: number;
  data: IActivity;
  access_level: TUserEntityPermission;
}

export interface IActivityState extends EntityState<IStoreActivity> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity' })
export class ActivityStore extends EntityStore<IActivityState, IStoreActivity> {
  constructor() {
    super();
  }
}
