import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgramTemplate } from 'typings/doenkids/doenkids';

export interface IProgramTemplateWithAccesLevel extends IProgramTemplate {
  access_level: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template' })
export class ProgramTemplateStore extends Store<IProgramTemplateWithAccesLevel> {
  constructor() {
    super({});
  }
}
