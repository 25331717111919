import {
  animate,
  animateChild,
  query as q,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DoenkidsFileDownloader, DownloadQueueItem } from 'src/providers/download-files.provider';

const query = (s, a, o = { optional: true }) => q(s, a, o);

@Component({
  selector: 'app-download-tray',
  templateUrl: './download-tray.component.html',
  styleUrls: ['./download-tray.component.scss'],
  animations: [
    // nice stagger effect when showing existing elements
    trigger('list', [
      transition(':enter', [
        // child animation selector + stagger
        query('@items',
          stagger(300, animateChild())),
      ]),
    ]),
    trigger('items', [
      // cubic-bezier for a tiny bouncing feel
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('1s cubic-bezier(.8,-0.6,0.2,1.5)',
          style({ transform: 'scale(1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8,-0.6,0.2,1.5)',
          style({
            transform: 'scale(0.5)',
            opacity: 0,
            height: '0px',
            margin: '0px',
          })),
      ]),
    ]),
  ],
})
export class DownloadTrayComponent implements OnInit {
  public queue$: Observable<DownloadQueueItem[]>;

  constructor(
    downloader: DoenkidsFileDownloader,
    changeRef: ChangeDetectorRef,
  ) {
    this.queue$ = downloader?.getDownloadQueue$().pipe(
      tap(() => changeRef.detectChanges()),
    );
  }

  ngOnInit(): void {
  }
}
