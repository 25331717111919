import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, firstValueFrom } from 'rxjs';
import * as dayjs from 'dayjs';
import { ProgramListService } from 'src/api/activity/program-list/program-list.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IPeriodListResponse } from 'typings/api-activity';
import { ProgramPeriodListService } from 'src/api/activity/program-period-list/program-period-list.service';
import { IProgram } from 'typings/doenkids/doenkids';
import { ProgramService } from 'src/api/activity/program/program.service';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-migrate-period-sections-to-other-program-dialog',
  templateUrl: './migrate-period-sections-to-other-program-dialog.component.html',
  styleUrls: ['./migrate-period-sections-to-other-program-dialog.component.scss'],
})
export class MigratePeriodSectionsToOtherProgramDialogComponent implements OnInit {
  private stop$: Subject<void> = new Subject<void>();

  public programDateForm: UntypedFormGroup;

  public programList: IProgram[];

  public periodList: IPeriodListResponse;

  public selectedProgram: UntypedFormControl;

  public selectedPeriod: UntypedFormControl;

  constructor(
    fb: UntypedFormBuilder,
    private $session: DoenkidsSessionProvider,
    private periodListService: ProgramPeriodListService,
    private programListService: ProgramListService,
    private programService: ProgramService,
    private dialogRef: MatDialogRef<MigratePeriodSectionsToOtherProgramDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentProgramId: number;
      periodId: number,
      title: string,
      confirmText: string,
      cancelText: string,
    },
  ) {
    this.programDateForm = fb.group({
      to: [''],
      from: [''],
    });
    this.selectedProgram = fb.control(this.data.currentProgramId);
    this.selectedPeriod = fb.control(this.data.periodId);
    this.programList = [];
  }

  async ngOnInit() {
    const currentProgram = await this.programService.fetch(this.data.currentProgramId);
    const beginningOfMonth = dayjs(currentProgram.from)
      .startOf('day')
      .startOf('month');
    const endOfMonth = dayjs(currentProgram.to)
      .startOf('day')
      .endOf('month');
    this.programDateForm.get('from').setValue(beginningOfMonth.format('YYYY-MM-DD'));
    this.programDateForm.get('to').setValue(endOfMonth.format('YYYY-MM-DD'));

    // this will emit with the first value so we don't have to call fetchProgramsInBetweenDates seperate in the ngOnInit
    this.programDateForm.valueChanges.pipe(takeUntil(this.stop$), distinctUntilChanged(isEqual)).subscribe((programData) => {
      const fromDate = typeof programData.from === 'string' ? programData.from
        : dayjs.tz(programData.from).startOf('day').format('YYYY-MM-DD');
      const toDate = typeof programData.to === 'string' ? programData.to
        : dayjs.tz(programData.to).startOf('day').format('YYYY-MM-DD');

      this.fetchProgramsInBetweenDates(fromDate, toDate);
    });

    this.selectedProgram.valueChanges.pipe(takeUntil(this.stop$)).subscribe((selectedProgram: number) => {
      this.fetchPeriodOfProgram(selectedProgram);
    });
  }

  async fetchProgramsInBetweenDates(startDate: string, endDate: string) {
    // console.trace('fetch programs in between dates', startDate, endDate);
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);

    const newPrograms: IProgram[] = [];
    const statusIds = [1, 2, 3];

    for (const statusId of statusIds) {
      // eslint-disable-next-line no-await-in-loop
      const newProgramResponse = await this.programListService.fetchAll({
        limit: 50000,
        skip: 0,
        statusId,
        organizationUnitId,
        from: startDate,
        to: endDate,
      }, true);
      newPrograms.push(...newProgramResponse.items);
    }

    this.programList = newPrograms;

    if (this.programList.length === 1) {
      const programId = this.programList[0].id;
      this.selectedProgram.setValue(programId);
    } else if (!this.programList.find((program) => program.id === this.selectedProgram.value)) {
      this.selectedProgram.setValue(0);
    } else if (!this.periodList || !this.periodList.items.find((period) => period.id === this.selectedPeriod.value)) {
      this.fetchPeriodOfProgram(this.selectedProgram.value);
    }
  }

  async fetchPeriodOfProgram(programId: number) {
    if (programId && programId !== 0) {
      this.periodList = await this.periodListService.fetchAll(programId, 5000, 0, 'order', 'asc', true);

      if (this.periodList.items.length === 1) {
        this.selectedPeriod.setValue(this.periodList.items[0].id);
      }

      if (!this.periodList.items.find((period) => period.id === this.selectedPeriod.value)) {
        this.selectedPeriod.setValue(0);
      }
    } else {
      this.periodList = {
        items: [],
        limit: 5000,
        skip: 0,
        total: 0,
      };
      this.selectedPeriod.setValue(0);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  close() {
    const periodId = this.selectedPeriod.value;
    this.dialogRef.close({ periodSectionId: periodId });
  }
}
