import { Injectable } from '@angular/core';
import { IActivity, IProgram } from 'typings/doenkids/doenkids';
import { isNil, snakeCase } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { PublishProgramService } from 'src/api/publish/program/program.service';
import { DownloadActivityPdfDialogComponent } from 'src/components/dialogs/download-activity-pdf-dialog/download-activity-pdf-dialog.component';
import * as zip from '@zip.js/zip.js';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { DoenkidsSessionProvider } from './session.provider';
import { DoenkidsFileDownloader } from './download-files.provider';
import { I18nToastProvider } from './i18n-toast.provider';
import { DoenKidsPreferencesProvider } from './preferences.provider';

@Injectable({
  providedIn: 'root',
})
export class DownloadProvider {
  // // Keep track of the amount of files downloading, so the loader is always up to date
  private _programsDownloading = 0;

  public get programsDownloading(): number {
    return this._programsDownloading;
  }

  public set programsDownloading(amount: number) {
    this._programsDownloading = amount;
    if (amount === 0) {
      this._programsDownloading$.next(false);
    } else {
      this._programsDownloading$.next(true);
    }
  }

  private _programsDownloading$ = new BehaviorSubject(false);

  public programsDownloading$: Observable<boolean> = this._programsDownloading$.asObservable();

  private _activitiesDownloading$ = new BehaviorSubject(false);

  public activitiesDownloading$: Observable<boolean> = this._activitiesDownloading$.asObservable();

  constructor(
    private matDialog: MatDialog,
    private $programPublishService: PublishProgramService,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
    private preference$: DoenKidsPreferencesProvider,
    private $session: DoenkidsSessionProvider,
    private $i18nToastProvider: I18nToastProvider,
    private $translateService: TranslateService,
  ) { }

  /**
   * Downloads the pdf using the pushish API
   */
  async downloadActivityPdf(activityDetails: IActivity) {
    if (isNil(activityDetails)) {
      this.$i18nToastProvider.error(_('download_provider.activity.unavailable'));
      return;
    }

    const organizationUnitId = (await firstValueFrom(this.$session.currentOuId$));

    this.matDialog.open(DownloadActivityPdfDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        activity: activityDetails,
        organizationUnitId,
      },
    });
  }

  public async downloadProgramPdfs(programs: IProgram[]) {
    const promises: Promise<Blob>[] = [];
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);

    for (let index = 0; index < programs.length; index++) {
      const program = programs[index];
      const filename = snakeCase(program.name);
      this.programsDownloading++;

      const request = {
        program_id: program.id,
        organization_unit_id: organizationUnitId,
      };

      promises.push(this.$programPublishService.fetchProgramPdf(request, { filename }));
    }

    try {
      const blobs = await Promise.all(promises);
      if (blobs) {
        if (!this.browserSupportsMultiDownload() && blobs.length > 1) {
          await this.downloadZIP(blobs);
        } else {
          for (const pdf of blobs) {
            // eslint-disable-next-line no-await-in-loop, @typescript-eslint/no-loop-func
            await (new Promise<void>((resolve) => {
              if (isNil(pdf)) {
                this.$i18nToastProvider.error('generic.error.download', { item: this.$translateService.instant(_('download.program_pdf.booklet')) });
              } else {
                this.downloadPdfBlob(pdf, this.$translateService.instant(_('download_provider.filename.program'), { plural: false }));
              }
              this.programsDownloading--;
              setTimeout(resolve, 50);
            }));
          }
        }
      }
    } catch (error) {
      console.error('[DOWNLOAD]: Error while trying to download program PDF\'s');
    }

    return true;
  }

  public browserSupportsMultiDownload() {
    // Chrome on iOS doesn't support it at this time (2022/08/31)
    // If you try to download multiple files at once (creating an a-tag, and clicking it) it will only prompt the first download.
    // If you wait 500ms for each anchor to be clicked, you get a prompt that this download will cancel out your current download.
    // eslint-disable-next-line no-undef
    return !navigator.userAgent.match('CriOS');
  }

  public async downloadZIP(blobs: Blob[]) {
    this.programsDownloading = 1;
    const blobWriter = new zip.BlobWriter('application/zip');
    const writer = new zip.ZipWriter(blobWriter);

    blobs.forEach((pdf, index) => {
      if (isNil(pdf)) {
        this.$i18nToastProvider.error(_('generic.error.download'), { item: this.$translateService.instant('download.program_pdf.booklet') });
      } else {
        const number = index + 1;
        const program = this.$translateService.instant(_('download_provider.filename.program'), { plural: false });
        const filename = `${program}-${number}.pdf`;
        writer.add(filename, new zip.BlobReader(pdf));
      }
    });

    // close the ZipReader
    await writer.close(undefined);

    const zipFilename = this.$translateService.instant(_('download_provider.filename.program'), { plural: true });
    // get the zip file as a Blob
    const blob = await blobWriter.getData();
    this.$doenkidsFileDownloaderService.addDownload({
      blob,
      name: `${zipFilename}.zip`,
    });
    this.programsDownloading--;
  }

  public async downloadBookletPdf(programs: IProgram[], mediaUuid: string) {
    try {
      const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
      const blob = await this.$programPublishService.fetchProgramBookletPdf({
        program_ids: programs.map((program) => program.id),
        cover_media_uuid: mediaUuid,
        organization_unit_id: organizationUnitId,
      });

      if (isNil(blob)) {
        this.$i18nToastProvider.error(_('generic.error.download'), { item: this.$translateService.instant(_('download.program_pdf.booklet')) });
      } else {
        this.downloadPdfBlob(blob, this.$translateService.instant(_('download_provider.filename.booklet')));
      }
    } catch (error) {
      console.error('[DOWNLOAD]: Error while trying to program booklet PDF');
    }
    return true;
  }

  public async downloadPdfBlob(pdf: Blob, fileName: string) {
    const blob = new Blob([pdf], {
      type: 'application/octet-stream',
    });
    // Create a download link element for the newly generated file, and then artificially click it.
    //
    this.$doenkidsFileDownloaderService.addDownload({
      blob,
      name: `${fileName}.pdf`,
    });
  }
}
