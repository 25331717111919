/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IGroupSize } from 'typings/doenkids/doenkids';

export interface IGroupSizeState extends EntityState<IGroupSize> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'group-size' })
export class GroupSizeStore extends EntityStore<IGroupSizeState, IGroupSize> {
  constructor() {
    super();
  }
}
