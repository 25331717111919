import {
  Component, Output, EventEmitter, OnDestroy, Input,
} from '@angular/core';
import { IMediaItem } from 'typings/section-types';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { isNil } from 'lodash';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UploadableDirective } from 'src/directives/uploadable.directive';
import { ImageSearchDialogComponent } from 'src/components/dialogs/image-search-result/image-search-result.component';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { TPurpose } from '../asset-upload/asset-upload.component';
import { IReplaceImageDialogData, ReplaceImageDialogComponentComponent } from 'src/components/dialogs/replace-image-dialog-component/replace-image-dialog-component.component';
import { IMedia } from 'typings/doenkids/doenkids';

_('select_media.purpose.header');
_('select_media.purpose.footer');
_('select_media.purpose.avatar');
_('select_media.purpose.attachment');
_('select_media.purpose.program_attachment');
_('select_media.purpose.program_template');
_('select_media.purpose.font');
_('select_media.purpose.explanation');
_('select_media.purpose.activity');
_('select_media.purpose.template');
_('select_media.purpose.period');
_('select_media.purpose.symbol');

@Component({
  selector: 'app-select-media',
  templateUrl: './select-media.component.html',
  styleUrls: ['./select-media.component.scss'],
})
export class SelectMediaComponent extends UploadableDirective implements OnDestroy {
  public stop$ = new Subject<void>();

  public noSearchPurposes: TPurpose[] = ['font', 'attachment', 'program-attachment', 'program-explanation', 'replacement'];

  @Input()
  public mediaItemUuid: string;

  @Input() purpose: TPurpose = 'activity';

  @Input()
    identifier = 'file';

  @Input()
    removable = false;

  @Input()
    disabled = false;

  @Input()
    programId: number = null;

  @Output()
    selected = new EventEmitter<IMediaItem>();

  @Output()
    removed = new EventEmitter<void>();

  public isAdmin$: Observable<boolean>;

  constructor(private matDialog: MatDialog, private $session: DoenkidsSessionProvider) {
    super();

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
  }

  async openImageSearch() {
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);

    const dialogRef = this.matDialog.open(ImageSearchDialogComponent, {
      maxHeight: '95vh',
      minWidth: '90vw',
      panelClass: 'image-search-modal',
      data: {
        showSearchInput: true,
        programId: this.programId,
        purpose: this.purpose,
        organizationUnitId,
      },
    });

    (dialogRef.componentInstance as ImageSearchDialogComponent).selectedImage$.pipe(
      filter((value) => !isNil(value)),
    ).subscribe((selectedImage) => {
      this.selected.emit(selectedImage);
      dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  remove() {
    this.selected.emit({ uuid: '', caption: '', description: '' });
    this.removed.emit();
  }

  shouldShowSearchOption() {
    return !this.noSearchPurposes.includes(this.purpose);
  }

  openFile() {
    if (!this.disabled) {
      this.uploadData?.openFile$?.next();
    }
  }

  async replaceImage() {
    if (this.mediaItemUuid) {
      const dialogRef = this.matDialog.open(ReplaceImageDialogComponentComponent, {
        maxHeight: '95vh',
        maxWidth: '600px',
        panelClass: 'image-replace-modal',
        data: {
          currentImageUuid: this.mediaItemUuid,
          purpose: this.purpose,
          identifier: this.identifier,
        } as IReplaceImageDialogData,
      });

      const result: IMedia = await firstValueFrom(dialogRef.afterClosed());

      if (result) {
        this.selected.emit({
          uuid: result.uuid,
          description: '',
          caption: '',
        });
      }
    }

  }
}
