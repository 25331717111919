import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { firstValueFrom } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IServiceResponse } from 'typings/api-generic';
import { IOrganizationUnitApiKey } from 'typings/doenkids/doenkids';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitApiKeyService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  @transaction()
  async fetchAPIKey(organizationUnitId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/organization-unit/${organizationUnitId}/api-key`)) as IServiceResponse;
    if (response?.data) {
      return response?.data?.key as IOrganizationUnitApiKey;
    }
    return null;
  }

  @transaction()
  async create(organizationUnitId: number) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/api-key`)) as IServiceResponse;

    if (response?.data) {
      return response?.data?.key as IOrganizationUnitApiKey;
    }
    return null;
  }

  async remove(organizationUnitId: number, apiKeyId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/organization-unit/${organizationUnitId}/api-key/${apiKeyId}`));
  }
}
