import { Component, Input, Output, EventEmitter, ViewChildren, QueryList, OnDestroy, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatSlider } from '@angular/material/slider';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';

interface ISliderOption {
  name: string;
  id?: number;
  weight?: number;
}

@Component({
  selector: 'app-weighted-value-sliders',
  templateUrl: './weighted-value-sliders.component.html',
  styleUrls: ['./weighted-value-sliders.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeightedValueSlidersComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: (c: UntypedFormControl) => {
        const total = c.value.reduce((acc, curr) => (curr.weight + acc), 0);

        const err = {
          incorrectValue: {
            given: total,
            max: 100,
            min: 100,
          },
        };

        return (total < 100) ? err : null;
      },
      multi: true,
    },
  ],
})
export class WeightedValueSlidersComponent implements ControlValueAccessor, OnDestroy {
  private stop$ = new Subject<void>();

  @ViewChildren('sliderControl') sliderElements: QueryList<MatSlider>;

  @Input() adjustOnChange = true;

  @Input() set options(options) {
    this._options = options;

    this.setSliders();
  }

  @Input() translationPrefix: string;

  @Output() saveRequested = new EventEmitter();

  public _options: ISliderOption[];

  public touched = false;

  public disabled = false;

  public toAssign$ = new BehaviorSubject<number>(100);

  public sliders: ISliderOption[];

  private _value;

  onChange: any = () => { };

  onTouched = () => {};

  ngOnDestroy(): void {
    this.stop$.next();
  }

  get stepSize() {
    const len = this._value.length;
    return len > 1 ? len - 1 : len;
  }

  isSelected(obj) {
    return this._value.findIndex(({ id }) => id === obj.id) !== -1 && !this.disabled;
  }

  writeValue(value: any) {
    this._value = value;

    this.setSliders();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  setSliders() {
    this.sliders = [];

    let total = 0;
    for (let i = 0; i < this._options.length; i += 1) {
      const option = this._options[i];
      const val = (this._value ?? []).find((v) => v.id === option.id)?.weight || 0;

      total += val;
      option.weight = val;
      this.sliders.push(option);
    }

    const toAssign = 100 - total;

    this.toAssign$.next((toAssign < 0 ? 0 : toAssign));
  }

  sliderChange(activeValue: number, index: number) {
    const activeSlider = this.sliders[index];
    activeSlider.weight = activeValue;

    // Get all other sliders with value higher than zero
    const toSubtractFrom = [];
    let total = activeValue;

    for (let i = 0; i < this.sliders.length; i++) {
      if (i !== index) {
        const slider = this.sliders[i];
        if (this.isSelected(slider)) {
          total += slider.weight;
          if (slider.weight > 0) {
            toSubtractFrom.push(slider);
          }
        }
      }
    }

    // If the total exceeds 100, reduce the values of the other sliders to bring it back to 100
    if (total > 100) {
      const initialExcess = total - 100;
      const initialSubtractAmount = initialExcess / toSubtractFrom.length;

      let definitiveExcess = initialExcess;
      let totalDifference = 0;

      /**
       * Check whether the other sliders should be adjusted
       */
      if (this.adjustOnChange) {
        const def: ISliderOption[] = toSubtractFrom.filter((option: ISliderOption) => {
          if (option.weight < initialSubtractAmount) {
            definitiveExcess -= option.weight;
            option.weight = 0;
            return false;
          }
          return true;
        });

        if (def.length) {
          const definitiveSubtractAmount = definitiveExcess / def.length;

          def.forEach((slider) => {
            const { rounded, difference } = this.floor10(slider.weight - definitiveSubtractAmount);
            totalDifference += difference;

            slider.weight = rounded;
          });
        }
      }

      this.toAssign$.next(totalDifference);
    } else {
      this.toAssign$.next(100 - total);
    }

    const values = this.sliders
      .filter((slider) => (slider.weight > 0));

    this.onChange(values);

    this.markAsTouched();
  }

  floor10(x) {
    const rounded = Math.floor(x / 10) * 10;
    return { rounded, difference: x - rounded };
  }

  async checkboxChange({ checked }, id: number, index: number) {
    const slider = this.sliders.find((s) => s.id === id);

    if (checked) {
      const val = await firstValueFrom(this.toAssign$);
      // Add to array
      this._value.push(slider);
      slider.weight = this.adjustOnChange ? val : 100;
    } else {
      const sliderIndex = this._value.findIndex((value) => value.id === id);
      slider.weight = 0;

      // remove from array
      this._value.splice(sliderIndex, 1);
    }

    this.sliderChange(slider.weight, index);
  }

  sliderClicked(id: number, event) {
    const index = this.sliders.findIndex((s) => s.id === id);
    const slider = this.sliders[index];

    if (!this.isSelected(slider)) {

      this._value.push(slider);

      // calculate value based on where user clicked
      const sliderElement = this.sliderElements.toArray()[index];
      const rect = (sliderElement._elementRef.nativeElement as HTMLElement).getBoundingClientRect();
      const percentage = ((event.clientX - rect.x) / rect.width) * 100;
      const value = Math.round(percentage / 10) * 10;

      slider.weight = value;

      this.sliderChange(slider.weight, index);
    }
  }
}
