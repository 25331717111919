import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual, isNil, sortBy, get, uniqBy } from 'lodash';
import { Subject, combineLatest, Observable, BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, map, takeUntil, debounceTime, distinctUntilChanged, mergeMap, tap } from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { fadeInOut } from 'src/animations';
import {
  EActivityStatusType,
  ESectionType,
  getNameForSectionType,
  IActivitySectionCount,
  ISectionChangedEvent,
  ISectionForm,
  SECTION_TYPE_DEFAULT_VALUES,
} from 'src/components/shared/section/section.component';
import { IActivity, IActivityStatus, IOrganizationUnitOverview, ISection, ISectionType } from 'typings/doenkids/doenkids';
import {
  IActivityKindListResponse,
  IActivityTypeListResponse,
  IAgeGroupListResponse,
  IAreaOfDevelopmentListResponse,
  IGroupSizeListResponse,
  IOrganizationUnitActivityRoles,
  IRangeOfDevelopmentListResponse,
  ITagListResponse,
} from 'typings/api-activity';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { IComponentCanDeactivate } from 'src/guards/can-leave-page.guard';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { UnloadProvider } from 'src/providers/unloadProvider';
import { PublishActivityService } from 'src/api/publish/activity/activity.service';
import { IPublishActivityRequest } from 'typings/api-publish';
import { ActivityQuery } from 'src/api/activity/activity/activity.query';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { ActivityStatusQuery } from 'src/api/generic/activity-status/activity-status.query';
import { ActivityStatusService } from 'src/api/generic/activity-status/activity-status.service';
import { SectionQuery } from 'src/api/activity/section/section.query';
import { SectionService } from 'src/api/activity/section/section.service';
import { SectionTypeQuery } from 'src/api/generic/section-type/section-type.query';
import { SectionTypeService } from 'src/api/generic/section-type/section-type.service';
import { OrganizationUnitActivityService } from 'src/api/customer/organization-unit-activity/organization-unit-activity.service';
import { DownloadProvider } from 'src/providers/download.provider';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { PublishActivityDialogComponent } from 'src/components/dialogs/publish-activity-dialog/publish-activity-dialog.component';
import { InfoDialogComponent } from 'src/components/dialogs/info-dialog/info-dialog.component';
import {
  IActivityOwner,
  IReviewActivityResponse,
  ReviewActivityDialogComponent,
} from 'src/components/dialogs/review-activity-dialog/review-activity-dialog.component';
import { OrganizationUnitListService } from 'src/api/customer/organization-unit-list/organization-unit-list.service';
import { UserListService } from 'src/api/customer/users-list/user-list.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IAttachmentListResponse } from 'typings/api-media';
import { DoenKidsPreferencesProvider } from 'src/providers/preferences.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { OrganizationUnitTypeNamePipe } from 'src/pipes/organization-unit-type-name';
import { I18nTitleStrategy } from 'src/app/utils/i18n-title-strategy';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { ActivityControlsComponent } from './activity-controls/activity-controls.component';
import { EOrganizationUnitType } from '../../dialogs/add-organization-dialog/add-organization-dialog.component';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { ActivityToReviewCountProvider } from 'src/providers/activity-to-review-count.provider';
import { ChoiceDialogComponent, IChoiceDialogData, IChoiceDialogResult } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { ActivitySelectionProvider } from 'src/providers/activity-selection.provider';

_('activity.section.type.activity_tips.title');
_('activity.section.type.activity_variations.title');
_('activity.section.type.activity_what.title');
_('activity.section.type.glossary.title');
_('activity.section.type.required_material.title');
_('activity.section.type.sources.title');
_('activity.section.type.sources.description');
_('activity.section.type.youtube.title');
_('activity.section.type.youtube.description');
_('activity.section.type.media.title');
_('activity.section.type.media.description');
_('activity.section.type.material.title');
_('activity.section.type.material.description');
_('activity.section.type.page_end.title');
_('activity.section.type.smallsteps_preparation.title');
_('activity.section.type.smallsteps_preparation.description');
_('activity.section.type.smallsteps_introduction.title');
_('activity.section.type.smallsteps_introduction.description');
_('activity.section.type.smallsteps_instruction.title');
_('activity.section.type.smallsteps_instruction.description');
_('activity.section.type.smallsteps_conclusion.title');
_('activity.section.type.smallsteps_conclusion.description');

_('activity.status.concept');
_('activity.status.review');
_('activity.status.published');
_('activity.status.unpublished');

interface IActivityStatusOption {
  value: EActivityStatusType;
  displayValue: string;
}

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut],
})
export class ActivityDetailsComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  /** The currently opened step on the expansion panel accordion.
   */
  step = 0;

  /** Section panels are either open (true) or closed (false).
   */
  public panelState = new Map<number, boolean>();

  /** Subject that will emit when this component gets destroyed.
   */
  private destroy$: Subject<any> = new Subject();

  public isMobile$: Observable<boolean>;

  @ViewChild('activityViewer') activityViewer;

  @ViewChild('attachmentViewer') attachmentViewer;

  @ViewChild(ActivityControlsComponent) controls: ActivityControlsComponent;

  private activityControlsFormGroup: UntypedFormGroup;

  /**
   * Subject to render PDF updates
   */
  public activityPdf$: Subject<string | Blob> = new Subject();

  public attachmentPdf$: Subject<string | Blob> = new Subject();

  public activityLoading: boolean;

  public attachmentsLoading: boolean;

  /** The last time a PDF Render was requested */
  private lastActivityRender: number;

  private lastAttachmentRender: number;

  private afterFirstPdfRender: boolean;

  /**
   * The name of the user that originally created the activity
   *
   */
  public activityCreatedByUser = '';

  /**
   * The name of the organization unit that originally created the activity
   *
   */
  public activityCreatedByOU = '';

  /** The form for the current activity.
   */
  public activityForm: UntypedFormGroup;

  /** The ID for the current activity.
   */
  public activityId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  readonly ESectionType = ESectionType;

  readonly EActivityStatusType = EActivityStatusType;

  // ActivityAPI observables.
  public activityDetails$: Observable<IActivity>;

  private activityLoading$: Observable<boolean>;

  public sectionsLoading$: Observable<boolean>;

  public isCopying$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public loading$: Observable<boolean>;

  public activitySectionList$: Observable<ISection[]>;

  public activitySectionTypes$: Observable<ISectionType[]>;

  public activitySectionCount$: Observable<IActivitySectionCount>;

  public activityStatuses$: Observable<IActivityStatus[]>;

  public isAdmin$: Observable<boolean>;

  public optionalSectionTypes$: Observable<ISectionType[]>;

  public currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public isDoenkidsManagement$: Observable<boolean>;

  public hasOUWritePermissions$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$: Observable<boolean>;

  public activityOwner$: BehaviorSubject<IOrganizationUnitActivityRoles> = new BehaviorSubject<IOrganizationUnitActivityRoles>(null);

  public activityPermissions$: BehaviorSubject<IOrganizationUnitActivityRoles[]> = new BehaviorSubject<IOrganizationUnitActivityRoles[]>(null);

  public hasActivityCopyPermission$: Observable<boolean>;

  public isCurrentOrganizationUnitIsOfTypeLocation$: Observable<boolean>;

  public isCurrentOrganizationUnitIsOfTypeGroup$: Observable<boolean>;

  public sectionsWithInfoDialog: number[] = [
    ESectionType.SOURCES,
    ESectionType.YOUTUBE,
    ESectionType.MEDIA,
    ESectionType.MATERIAL,
    ESectionType.SMALLSTEPS_PREPARATION,
    ESectionType.SMALLSTEPS_INTRODUCTION,
    ESectionType.SMALLSTEPS_INSTRUCTION,
    ESectionType.SMALLSTEPS_CONCLUSION,
  ];

  public isOwner$: Observable<boolean>;

  public activityStatusesToShow$: Observable<IActivityStatusOption[]>;

  public selectedPdfTabIndex: number = 0;

  public activityStatusCtrl: UntypedFormControl = new UntypedFormControl('', []);

  public possibleContentLanguages$: Observable<string[]>;

  public contentLanguageControl = new FormControl('');

  private relatedActivities$ = new BehaviorSubject<IActivity[]>([]);

  public isLocation$: Observable<boolean>;

  public publishedStatus = DoenkidsStaticValuesHelper.ACTIVITY_STATUS_PUBLISHED;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private activityQuery: ActivityQuery,
    private activityService: ActivityService,
    private activityStatusQuery: ActivityStatusQuery,
    private activityStatusService: ActivityStatusService,
    private sectionQuery: SectionQuery,
    public sectionService: SectionService,
    private sectionTypeQuery: SectionTypeQuery,
    private sectionTypeService: SectionTypeService,
    private publishActivityService: PublishActivityService,
    private $breakpoint: BreakpointsProvider,
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
    private $unload: UnloadProvider,
    private organizationUnitActivityService: OrganizationUnitActivityService,
    private downloadProvider: DownloadProvider,
    private organizationUnitListService: OrganizationUnitListService,
    private userListService: UserListService,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    private $organizationUnitTypeNamePipe: OrganizationUnitTypeNamePipe,
    private $i18nTitleStrategy: I18nTitleStrategy,
    private preference$: DoenKidsPreferencesProvider,
    private organizationUnitService: OrganizationUnitService,
    private activityToReviewCountProvider: ActivityToReviewCountProvider,
    private activitySelectionProvider: ActivitySelectionProvider,
  ) {
    this.activityDetails$ = this.activityId$.pipe(
      takeUntil(this.destroy$),
      filter((value) => !isNil(value)),
      mergeMap((activityId) =>
        this.activityQuery.selectEntity(activityId).pipe(
          filter((value) => !isNil(value)),
          debounceTime(300),
          map((activity) => activity.data),
          tap((activity) => this.$i18nTitleStrategy.updateTitleParameters({ activity: activity.name || activity.id })),
        ),
      ),
    );

    this.isMobile$ = this.$breakpoint.isMobile$.pipe(takeUntil(this.destroy$));

    this.activityLoading$ = this.activityQuery.selectLoading().pipe(takeUntil(this.destroy$));

    this.sectionsLoading$ = this.sectionQuery.selectLoading().pipe(takeUntil(this.destroy$));

    this.loading$ = combineLatest([this.activityLoading$, this.sectionsLoading$, this.isCopying$]).pipe(map((loading) => loading.includes(true))).pipe(takeUntil(this.destroy$));

    this.activitySectionList$ = this.sectionQuery.selectAll().pipe(map((sections) => sortBy(sections as any, 'order'))).pipe(takeUntil(this.destroy$));

    this.isLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.destroy$));

    this.activitySectionTypes$ = this.sectionTypeQuery.selectAll().pipe(
      takeUntil(this.destroy$),
      map((activitySectionTypes) => {
        // NOTE only these section types will be shown in the dropdown
        const desiredOrder = [
          'activity-what',
          'activity-material',
          'youtube',
          'media',
          'sources',
          'page-break',
          'smallsteps-preparation',
          'smallsteps-introduction',
          'smallsteps-instruction',
          'smallsteps-conclusion',
        ];

        const filteredActivitySectionTypes = activitySectionTypes.filter((sectionType) => desiredOrder.indexOf(sectionType.name) > -1);

        const sortedActivitySectionTypes = filteredActivitySectionTypes.sort(
          (sectionTypeA, sectionTypeB) => desiredOrder.indexOf(sectionTypeA.name) - desiredOrder.indexOf(sectionTypeB.name),
        );

        return sortedActivitySectionTypes;
      }),
    );

    this.activitySectionCount$ = this.activitySectionList$.pipe(
      takeUntil(this.destroy$),
      map((sections) => {
        const sectionCount: IActivitySectionCount = {};

        sections.forEach((section) => {
          if (sectionCount[section.type_id]) {
            sectionCount[section.type_id] += 1;
          } else {
            sectionCount[section.type_id] = 1;
          }
        });

        return sectionCount;
      }),
    );

    this.activityStatuses$ = this.activityStatusQuery.selectAll().pipe(takeUntil(this.destroy$));

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.destroy$));

    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.destroy$));

    this.isDoenkidsManagement$ = this.$permission.isDoenkidsManagement$.pipe(takeUntil(this.destroy$));

    this.hasOUWritePermissions$ = this.$permission.hasOUWritePermissions$.pipe(takeUntil(this.destroy$));

    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.destroy$));

    this.hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$.pipe(takeUntil(this.destroy$));

    this.hasActivityCopyPermission$ = this.$permission.hasActivityCopyPermission$.pipe(takeUntil(this.destroy$));

    this.isCurrentOrganizationUnitIsOfTypeLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.destroy$));

    this.isCurrentOrganizationUnitIsOfTypeGroup$ = this.$session.isCurrentOrganizationUnitIsOfTypeGroup$.pipe(takeUntil(this.destroy$));

    this.isOwner$ = combineLatest([this.activityOwner$, this.currentOUDetails$]).pipe(
      takeUntil(this.destroy$),
      map(([activityOwner, OUDetails]) => activityOwner?.organization_unit_id === OUDetails?.id),
    );

    this.activityStatusesToShow$ = combineLatest([
      this.activityStatuses$,
      this.isCurrentOrganizationUnitIsOfTypeLocation$,
      this.isCurrentOrganizationUnitIsOfTypeGroup$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([activityStatuses, ouIsOfTypeLocation, ouIsOfTypeGroup]) => {
        const showActivityStatuses: IActivityStatusOption[] = [];

        activityStatuses.forEach((activityStatus) => {
          switch (activityStatus.id) {
            case EActivityStatusType.CONCEPT:
              showActivityStatuses.push({
                value: EActivityStatusType.CONCEPT,
                displayValue: _('activity.details.status.change_to_concept'),
              });
              break;
            case EActivityStatusType.REVIEW:
              // only add this when you are a customer
              //
              if (!ouIsOfTypeLocation && !ouIsOfTypeGroup) {
                showActivityStatuses.push({
                  value: EActivityStatusType.REVIEW,
                  displayValue: _('activity.details.status.change_to_review'),
                });
              }
              break;
            case EActivityStatusType.PUBLISHED:
              showActivityStatuses.push({
                value: EActivityStatusType.PUBLISHED,
                displayValue: _('activity.details.status.change_to_published'),
              });
              break;
            case EActivityStatusType.UNPUBLISHED:
              showActivityStatuses.push({
                value: EActivityStatusType.UNPUBLISHED,
                displayValue: _('activity.details.status.change_to_unpublished'),
              });
              break;
            default:
              break;
          }
        });

        return showActivityStatuses;
      }),
    );

    this.optionalSectionTypes$ = this.sectionTypeQuery.selectAll().pipe(
      takeUntil(this.destroy$),
      map((activitySectionTypes) => {
        // NOTE only these section types will be shown in the dropdown
        const desiredOrder = [
          ESectionType.ACTIVITY_WHAT,
          ESectionType.MATERIAL,
          ESectionType.YOUTUBE,
          ESectionType.MEDIA,
          ESectionType.SOURCES,
          ESectionType.PAGE_END,
          ESectionType.SMALLSTEPS_INTRODUCTION,
          ESectionType.SMALLSTEPS_PREPARATION,
          ESectionType.SMALLSTEPS_INSTRUCTION,
          ESectionType.SMALLSTEPS_CONCLUSION,
        ];

        const filteredActivitySectionTypes = activitySectionTypes.filter((sectionType) => desiredOrder.indexOf(sectionType.id) > -1);

        return filteredActivitySectionTypes.sort((sectionTypeA, sectionTypeB) => desiredOrder.indexOf(sectionTypeA.id) - desiredOrder.indexOf(sectionTypeB.id));
      }),
    );

    this.possibleContentLanguages$ = this.$session.ouLanguages$.pipe(takeUntil(this.destroy$));

    this.activityDetails$.subscribe(async (activityDetails) => {
      this.contentLanguageControl.setValue(activityDetails.language ?? '');
      this.relatedActivities$.next((await firstValueFrom(this.activityService.relatedActivities(activityDetails.id)))?.items ?? []);
    });

    this.contentLanguageControl.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(async (newLanguage) => {
      const activityDetails = (await firstValueFrom(this.activityDetails$));
      const currentLanguage = activityDetails.language;

      if (currentLanguage.toLowerCase() !== newLanguage.toLowerCase()) {
        const activityWithNewLanguage = this.relatedActivities$.value.find((activity) => activity.language.toLowerCase() === newLanguage.toLowerCase()
          && activity.country_code.toLowerCase() === activityDetails.country_code.toLowerCase());

        if (activityWithNewLanguage) {
          this.router.navigate([`/activities/${activityWithNewLanguage.id}/edit`], { replaceUrl: true });
          await this.fetchActivityDetails(activityWithNewLanguage.id);
          await this.fetchActivityOwner(activityWithNewLanguage.id);
          this.activityId$.next(activityWithNewLanguage.id);
        } else {
          const translatedNewLanguage = this.$translateService.instant(`generic.language.${newLanguage.toLowerCase()}`);
          const data: IChoiceDialogData = {
            title: '',
            description: this.$translateService.instant(_('activity.content_language.dialog.description'),
              { language: translatedNewLanguage }),
            selectionOptions: [
              {
                label: _('activity.content_language.dialog.change_language'),
                value: 'change_language',
                labelShouldBeTranslated: true,
              },
              {
                label: _('activity.content_language.dialog.language_copy'),
                value: 'language_copy',
                labelShouldBeTranslated: true,
                labelParams: { language: translatedNewLanguage },
              },
            ],
            actions: {
              primaryAction: {
                label: this.$translateService.instant(_('generic.confirm')),
                value: 'confirm',
              },
            },
          };

          const dialogRef = this.dialog.open(ChoiceDialogComponent, {
            width: '600px',
            minWidth: '320px',
            data,
          });

          const result = await firstValueFrom(dialogRef.afterClosed()) as IChoiceDialogResult;

          if (result?.action === 'confirm' && result?.selectedOption === 'change_language') {
            await this.activityService.update(activityDetails.id, {
              ...activityDetails,
              language: newLanguage.toLowerCase(),
            });
          } else if (result?.action === 'confirm' && result?.selectedOption === 'language_copy') {
            await this.copyActivityWithFields(true, newLanguage, false);
          } else {
            this.contentLanguageControl.setValue(currentLanguage);
          }
        }
      }
    });

    this.fetchQueryParams();
  }

  fetchQueryParams() {
    // Get a snapshot of the current route parameters.
    //
    const { params } = this.route.snapshot;

    // Save the activity id, taken from the route parameters.
    //
    this.activityId$.next(+params.id);
    this.fetchActivityOwner(this.activityId$.value);

    if (isNil(this.activityId$.value)) {
      this.router.navigate(['/activities']);
    }
  }

  /**
   * Function that checks whether the user wants to leave the page.
   */
  async canDeactivate(): Promise<boolean> {
    const pendingChanges = (this.activityForm.pristine && this.activityControlsFormGroup.pristine) === false;

    let leavePage = true;

    if (pendingChanges) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('generic.unsaved_changes_dialog.title')),
          description: this.$translateService.instant(_('generic.unsaved_changes_dialog.description')),
        },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());

      if (result !== 'confirm') {
        leavePage = false;
      }
    }

    return leavePage;
  }

  canUnload() {
    // Block the browser from exiting the page if there's pending changes.
    //
    this.$unload.canUnload = this.activityForm.pristine && this.activityControlsFormGroup.pristine;
  }

  async listenToForms(activityControlsForm: UntypedFormGroup) {
    // Set up subscriptions that will block page leave if there's unsaved data.
    //
    this.activityControlsFormGroup = activityControlsForm;
    this.activityForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.canUnload());
    this.activityControlsFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.canUnload());
  }

  onPdfLoadedEvent(viewer: any, source: 'activity' | 'attachment') {
    viewer?.PDFViewerApplication?.preferences?.set('externalLinkTarget', 2);

    if (source === 'activity') {
      this.activityLoading = false;
    } else {
      this.attachmentsLoading = false;
    }
  }

  async ngOnInit() {
    // Create form group.
    //
    this.activityForm = this.fb.group({
      sections: this.fb.group({}),
    });

    combineLatest([
      this.hasOUWritePermissions$,
      this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$,
      this.hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$,
    ]).subscribe(([ouWritePermission, customerWritePermission, locationWritePermission]) => {
      const allowedToEdit = ouWritePermission || customerWritePermission || locationWritePermission;

      const formOpts = { onlySelf: true, emitEvent: false };

      const markAsPristine = this.activityForm.pristine;

      if (allowedToEdit) {
        this.activityForm.enable(formOpts);
      } else {
        this.activityForm.disable(formOpts);
      }

      if (markAsPristine) {
        this.activityForm.markAsPristine();
      }
    });

    const organizationUnitId = get(this.$session.getSession$.value.details, 'id');
    this.activityStatusService.fetchAll(10, 0);
    this.sectionTypeService.fetchAll(100, 0, undefined, undefined, organizationUnitId);

    // Get the current list of sections for this activity.
    //
    this.activitySectionList$
      .pipe(
        filter((value) => !isNil(value)),
        takeUntil(this.destroy$),
      )
      .subscribe((activitySections) => {
        // reset the sections formgroup when we iterate over them again
        //
        // this.activityForm.get('sections').setValue({});
        // Loop through the list of sections.
        //
        activitySections.forEach((activitySection) => {
          // For every section present in the list, add a formControl with the sectionId as the formControlName.
          //
          (this.activityForm.get('sections') as UntypedFormGroup).addControl(
            activitySection.id.toString(),
            this.fb.control(
              {
                data: activitySection.data || SECTION_TYPE_DEFAULT_VALUES[activitySection.type_id],
                columns: activitySection.columns || 1,
              },
              Validators.required,
            ),
          );
        });
      });

    // Fetch the activity creator details
    //
    this.activityDetails$
      .pipe(
        filter((value) => !isNil(value)),
        takeUntil(this.destroy$),
      )
      .subscribe((activityDetails) => {
        this.setActivityCreatedByValues(activityDetails);
        this.activityStatusCtrl.setValue(activityDetails.status_id);
      });

    // Render the activity PDF.
    //
    this.activityPdf$.pipe(filter((value) => !isNil(value))).subscribe((pdf) => {
      if (this.activityViewer) {
        if (typeof pdf === 'string') {
          this.activityViewer.pdfSrc = encodeURIComponent(pdf);
        } else {
          this.activityViewer.pdfSrc = pdf;
        }
        this.activityViewer.refresh();
      }
    });

    // Render the attachment PDF.
    //
    this.attachmentPdf$.pipe(filter((value) => !isNil(value))).subscribe((pdf) => {
      if (this.attachmentViewer) {
        if (typeof pdf === 'string') {
          this.attachmentViewer.pdfSrc = encodeURIComponent(pdf);
        } else {
          this.attachmentViewer.pdfSrc = pdf;
        }
        this.attachmentViewer.refresh();
      }
    });

    combineLatest([this.currentOUDetails$, this.activityDetails$])
      .pipe(
        filter(() => this.afterFirstPdfRender),
        takeUntil(this.destroy$),
        debounceTime(100),
        distinctUntilChanged(isEqual),
      )
      .subscribe(() => {
        this.renderPdfTab(this.selectedPdfTabIndex);
      });

    // we do this after the subscriptions so that all subscriptions are fired on the first retrieve
    //
    this.fetchActivityDetails(this.activityId$.value);

    await this.renderPdfTab(this.selectedPdfTabIndex);

    this.afterFirstPdfRender = true;
  }

  async appendSection(sectionType: ISectionType) {
    try {
      // Create new section.
      //
      await this.sectionService.create({
        type_id: sectionType.id,
        name: sectionType.name,
        activity_id: this.activityId$.value,
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Cleans up the form and refetches the section list for the current activity.
   * @param section The section that will be removed.
   */
  async removeSection(section: ISection) {
    const sectionTypeName = this.$translateService.instant(`activity.section.type.${this.getSectionTypeName(section.type_id)}.title`)?.toLowerCase();
    const sectionNameIncludesField = sectionTypeName.toLowerCase().includes(this.$translateService.instant('generic.field').toLowerCase());
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.section.remove.confirm.title'), { sectionTypeName, sectionNameIncludesField }),
        description: this.$translateService.instant(_('activity.section.remove.confirm.description'), { sectionTypeName, sectionNameIncludesField }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const response = await this.sectionService.archive(section.id);

      if (isNil(response) || response.code > 299) {
        this.$i18nToastProvider.error(_('activity.section.remove.error'), { sectionTypeName, sectionNameIncludesField });
      } else {
        // Remove the formControl for the section that is no longer present.
        //
        this.activityForm.removeControl(`sections.${section.id}`);
      }
    }
  }

  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      if (control.controls) {
        // control is a FormGroup
        this.markFormGroupTouched(control);
      } else {
        // control is a FormControl
        control.markAsTouched();
      }
    });
  }

  /**
   * Saves the current activity details, including every section attached to the activity.
   */
  async save() {
    if (!this.activityForm.dirty && !this.activityControlsFormGroup.dirty) {
      this.dialog.open(InfoDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('activity.save.unchanged')),
          description: '',
        },
      });
      return;
    }

    if (this.activityControlsFormGroup.get('name').value === '') {
      this.dialog.open(InfoDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('activity.save.missing_name')),
          description: '',
        },
      });
      return;
    }

    const promises = [this.controls.saveDetails(), this.saveSections()];

    // Make sure the page can be closed now.
    //
    this.activityForm.markAsPristine();
    this.activityControlsFormGroup.markAsPristine();
    this.$unload.canUnload = true;

    await Promise.all(promises);

    await this.renderPdfTab(this.selectedPdfTabIndex);
  }

  /** Change publication status of this activity */
  changeActivityStatus(statusId: number) {
    this.setPublishStatus(statusId);
  }

  /** Saves all sections in the current activity if there are any changes.
   */
  async saveSections() {
    const { sections }: { sections: ISectionForm[] } = this.activityForm.value;

    // Get all the sections in this activity.
    //
    const activitySectionsList = (await firstValueFrom(this.activitySectionList$)) as ISection[];

    const promises: Promise<ISection>[] = [];

    // Go through every section
    //
    for (let index = 0; index < activitySectionsList.length; index++) {
      const activitySection = activitySectionsList[index];

      // Set the activitySection data.
      //
      const sectionForm = sections[activitySection.id.toString()];

      // Save the section.
      //
      const promise = this.saveSection({
        sectionId: activitySection.id,
        sectionFormValue: sectionForm,
        activitySection,
        rerenderPDF: false,
        isMultipleSave: true,
      });
      promises.push(promise);
    }

    await Promise.all(promises);

    return null;
  }

  /** Save a single section's data if there are any changes.
   */
  async saveSection(params: {
    sectionId: number,
    sectionFormValue,
    activitySection?: ISection,
    rerenderPDF?: boolean,
    isMultipleSave?: boolean,
    forceUpdate?: boolean,
  }): Promise<ISection> {
    let activitySection = params.activitySection;
    const rerenderPDF = params.rerenderPDF ?? true;
    const isMultipleSave = params.isMultipleSave ?? false;
    const forceUpdate = params.forceUpdate ?? false;

    if (isNil(activitySection)) {
      // Get all the sections in this activity.
      //
      const activitySectionsList = (await firstValueFrom(this.activitySectionList$)) as ISection[];

      // Get the existing section data.
      //
      activitySection = activitySectionsList.find((section) => section.id === params.sectionId);
    }

    if (!forceUpdate && !isMultipleSave && this.activityControlsFormGroup.get('name').value === '') {
      this.dialog.open(InfoDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('activity.save.missing_name')),
          description: '',
        },
      });
      return activitySection;
    }

    if (!forceUpdate && !isMultipleSave && isEqual(activitySection.data, params.sectionFormValue.data) && activitySection.columns === params.sectionFormValue.columns) {
      this.dialog.open(InfoDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('activity.save.unchanged')),
          description: '',
        },
      });
      return activitySection;
    }

    // Combine the existing section data with the new form values.
    //
    activitySection.data = params.sectionFormValue.data;
    activitySection.columns = params.sectionFormValue.columns;
    await this.sectionService.update(activitySection);

    if (rerenderPDF) {
      this.renderPdfTab(this.selectedPdfTabIndex);
    }

    return activitySection;
  }

  saveSectionFromTemplate(sectionId: number, sectionChangeEvent: ISectionChangedEvent) {
    return this.saveSection({
      sectionId,
      sectionFormValue: sectionChangeEvent.formData,
    });
  }

  /**
   * Downloads the pdf using the pushish API
   */
  async downloadPDF() {
    const activityDetails = await firstValueFrom(this.activityDetails$);
    if (isNil(activityDetails)) {
      this.$i18nToastProvider.error(_('activity.pdf.download.not_available'));

      return;
    }

    this.downloadProvider.downloadActivityPdf(activityDetails);
  }

  /** Renders the pdf using the publish API.
   */
  async renderPdf() {
    this.activityLoading = true;

    // TODO fix backend of fetching pdf
    //
    this.lastActivityRender = new Date().getTime();
    const thisRenderStartedAt = this.lastActivityRender;

    let activityDetails: IActivity;
    let OUDetails: IOrganizationUnitOverview;

    try {
      activityDetails = await firstValueFrom(this.activityDetails$);
      OUDetails = await firstValueFrom(this.currentOUDetails$);
    } catch (e) {
      // this happens when we already destroyed the component but are still trying to render.
      // if the component is already destroyed we don't wanna render the pdf anymore so we just return
      //
      return;
    }

    const activityPdfRequest: IPublishActivityRequest = {
      activity_id: activityDetails.id,
      organization_unit_id: OUDetails.id,
      exclude_attachments: true,
    };

    let pdf: string | Blob;

    console.log('activity details', activityDetails);

    try {
      pdf = await this.publishActivityService.getActivityPdfUrl(activityPdfRequest, activityDetails.country_code);
    } catch (e) {
      if (thisRenderStartedAt === this.lastActivityRender) {
        this.$i18nToastProvider.error(_('activity.pdf.download.failed'), { name: activityDetails.name });
        this.activityLoading = false;
      }
    }

    // Only render the last time the PDF was requested to render to prevent 'flickering'
    //
    if (pdf && thisRenderStartedAt === this.lastActivityRender) {
      this.activityPdf$.next(pdf);
    }
  }

  /** Renders the pdf using the publish API.
   */
  async renderAttachments() {
    this.attachmentsLoading = true;

    // TODO fix backend of fetching pdf
    //
    this.lastAttachmentRender = new Date().getTime();
    const thisRenderStartedAt = this.lastAttachmentRender;

    const OUDetails = await firstValueFrom(this.currentOUDetails$);
    const activityDetails = await firstValueFrom(this.activityDetails$);

    const activityPdfRequest: IPublishActivityRequest = {
      activity_id: activityDetails.id,
      organization_unit_id: OUDetails.id,
      only_attachments: true,
    };

    let pdf: string | Blob;

    try {
      pdf = await this.publishActivityService.getActivityPdfUrl(activityPdfRequest, activityDetails.country_code);
    } catch (e) {
      if (thisRenderStartedAt === this.lastActivityRender) {
        this.$i18nToastProvider.error(_('activity.pdf.download.failed'), { name: activityDetails.name });
        this.attachmentsLoading = false;
      }
    }

    // Only render the last time the PDF was requested to render to prevent 'flickering'
    //
    if (pdf && thisRenderStartedAt === this.lastAttachmentRender) {
      this.attachmentPdf$.next(pdf);
    }
  }

  /** Destroy all subscriptions, leave no subscribers.
   */
  ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.$unload.canUnload = true;
  }

  /** Sets the publish status_id for the activity. */
  async setPublishStatus(statusId: number) {
    const currentStatusId = (await firstValueFrom(this.activityDetails$)).status_id;
    const dialogResult = await this.promptSetPublishStatus(statusId);

    if (dialogResult === 'confirm') {
      const activityDetails = await firstValueFrom(this.activityDetails$);
      const activity: IActivity = {
        ...activityDetails,
        status_id: statusId,
      };

      await this.activityService.update(this.activityId$.value, activity);
      this.activityToReviewCountProvider.refresh();
    } else {
      this.activityStatusCtrl.setValue(currentStatusId);
    }
  }

  /** Generates a dialog that prompts the user to change their publish status, and informs them of what will change.
   * @private
   * @param {number} statusId The status ID that was selected.
   */
  private async promptSetPublishStatus(statusId: EActivityStatusType) {
    const roles = await firstValueFrom(this.activityPermissions$);

    let dialogRef: MatDialogRef<PublishActivityDialogComponent | ConfirmationDialogComponent>;
    if (statusId === EActivityStatusType.PUBLISHED) {
      dialogRef = this.dialog.open(PublishActivityDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          roles,
        },
      });
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('activity.status.change.confirm.title')),
          description: this.$translateService.instant(_('activity.status.change.confirm.description'), { status: this.getActivityStatusName(statusId) }),
        },
      });
    }

    const dialogResult = await firstValueFrom(dialogRef.afterClosed());

    return dialogResult;
  }

  /** Sets the state of the current section's panel in the panelState map.
   * @param id The section whose Id will be changed.
   * @param opened Whether the section is opened or closed.
   */
  panelStateChange(id: number, opened: boolean) {
    this.panelState.set(id, opened);
  }

  tabChange(event: MatTabChangeEvent) {
    if (event.index === 1) {
      this.renderPdfTab(this.selectedPdfTabIndex);
    }
  }

  public async changeActivityOwner(organizationUnitId: number) {
    const activityDetails = await firstValueFrom(this.activityDetails$);

    if (organizationUnitId === 1) {
      if (this.activityOwner$.value.organization_unit_id === 1) {
        this.$i18nToastProvider.error(_('activity.owner.change.error.already_owned_by_doenkids'));

        return;
      }
      if (!isNil(activityDetails.replace_activity_id)) {
        this.$i18nToastProvider.error(_('activity.owner.change.error.copied_activity_owner_change'));

        return;
      }
      // Set the owner to Doenkids.
      //
      this.setActivityOU(1);
    } else {
      this.setActivityOU(organizationUnitId);
    }
  }

  private async setActivityOU(ouId: number = null) {
    const activityDetails = await firstValueFrom(this.activityDetails$);
    const activityId = activityDetails.id;

    // we can use the role id of the current activity owner as this is the same role we want to assign our new owner with
    await firstValueFrom(this.organizationUnitActivityService.update(ouId, activityId, this.activityOwner$.value.organization_unit_role_id));
    await firstValueFrom(this.organizationUnitActivityService.archive(this.activityOwner$.value.organization_unit_id, activityId));
    this.fetchActivityOwner(activityId);
  }

  async forkActivity() {
    const ouId = (await firstValueFrom(this.currentOUDetails$))?.id;
    const activityDetails = await firstValueFrom(this.activityDetails$);

    const newActivity = await this.activityService.copy(activityDetails.id, ouId);
    this.navigateToActivity(newActivity.id);
  }

  async unforkActivity() {
    const ouId = (await firstValueFrom(this.currentOUDetails$)).id;
    const activityDetails = await firstValueFrom(this.activityDetails$);

    await firstValueFrom(this.organizationUnitActivityService.archive(ouId, activityDetails.id));
    this.navigateToActivity(activityDetails.replace_activity_id);
  }

  async excludeActivity() {
    const activityStatuses = await firstValueFrom(this.activityStatuses$);
    const activityDetails = await firstValueFrom(this.activityDetails$);
    const unpublishedStatusId = activityStatuses.filter((status: IActivityStatus) => status.name === 'UNPUBLISHED')[0]?.id;

    if (unpublishedStatusId) {
      await this.activityService.update(this.activityId$.value, { ...activityDetails, status_id: unpublishedStatusId });
    }
  }

  async deleteActivity() {
    const dialogText: string[] = [_('activity.delete.confirm.description')];
    const dialogTextParams: any = {};
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);

    if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      const activityConnections = await firstValueFrom(this.activityService.listConnections(this.activityId$.value));
      const showableNameLength = 5;

      if (activityConnections.length > 0) {
        const templateCount = activityConnections.reduce((previous, current) => previous + (!isNil(current.template_id) ? 1 : 0), 0);
        if (templateCount > 0) {
          dialogTextParams.template_count = templateCount;
          const templateNames = uniqBy(activityConnections.map((activityConnection) => activityConnection.template_name).filter((name) => !isNil(name)), (name) => name.toLowerCase().trim());
          if (templateNames.length > showableNameLength) {
            dialogTextParams.template_names = [...templateNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.delete.confirm.description.activity_connections.and_other_templates'), {
                template_count: templateCount - showableNameLength,
              })].join(', ');
          } else {
            dialogTextParams.template_names = templateNames.join(', ');
          }
        }

        const programCount = (activityConnections.reduce((previous, current) => previous + (!isNil(current.program_id) ? 1 : 0), 0) - templateCount);

        if (programCount > 0) {
          dialogTextParams.program_count = programCount;
          const programNames = uniqBy(activityConnections.map((activityConnection) => activityConnection.program_name).filter((name) => !isNil(name)), (name) => name.toLowerCase().trim());
          if (programNames.length > showableNameLength) {
            dialogTextParams.program_names = [...programNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.delete.confirm.description.activity_connections.and_other_programs'), {
                program_count: programCount - showableNameLength,
              })].join(', ');
          } else {
            dialogTextParams.program_names = programNames.join(', ');
          }
        }

        if (programCount > 0 && templateCount > 0) {
          dialogText.push(_('activity.delete.confirm.description.activity_connections.templates_and_programs'));
        } else if (programCount === 0 && templateCount > 0) {
          dialogText.push(_('activity.delete.confirm.description.activity_connections.templates'));
        } else if (programCount > 0 && templateCount === 0) {
          dialogText.push(_('activity.delete.confirm.description.activity_connections.programs'));
        }
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.delete.confirm.title')),
        description: this.$translateService.instant(dialogText, dialogTextParams),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      await this.activityService.archive(this.activityId$.value);
      this.router.navigate(['/activities/'], { replaceUrl: true });
    }
  }

  async navigateToActivity(activityId: number) {
    if (activityId !== this.activityId$.value) {
      this.router.navigate([`/activities/${activityId}/edit`], { replaceUrl: true });
      await this.fetchActivityDetails(activityId);
      await this.fetchActivityOwner(activityId);
      this.activityId$.next(activityId);
    }
  }

  async fetchActivityDetails(activityId: number) {
    this.activityService.fetch(activityId);
    this.sectionService.fetchAll(activityId, 100, 0);
  }

  async fetchActivityOwner(activityId: number) {
    const activityOrganizationUnits = (await firstValueFrom(this.activityService.organizationUnits(activityId))) as IOrganizationUnitActivityRoles[];
    const owners = activityOrganizationUnits.filter(
      (activityRole) => activityRole.organization_unit_role_id === DoenkidsStaticValuesHelper.ACTIVITY_OWNER_ROLE,
    );
    this.activityPermissions$.next(activityOrganizationUnits);
    this.activityOwner$.next(owners[0]);
  }

  async reviewByOrganisation() {
    const session = await firstValueFrom(this.$session.getSession$);
    const activityId = await firstValueFrom(this.activityId$);

    let parent: IActivityOwner;

    if (session.details.parent_organization_unit_id) {
      parent = {
        id: session.details.parent_organization_unit_id,
        name: session.details.parent_organization_unit_name,
        type: EOrganizationUnitType.ORGANIZATION,
      };
    } else {
      const dkDetails = await this.organizationUnitService.fetch(DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER, true);
      parent = {
        id: dkDetails.id,
        name: dkDetails.name,
        type: EOrganizationUnitType.ORGANIZATION,
      };
    }

    parent.type = EOrganizationUnitType.ORGANIZATION;

    const dialogRef = this.dialog.open(ReviewActivityDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        parent,
        activityId,
      },
    });

    const result = (await firstValueFrom(dialogRef.afterClosed())) as IReviewActivityResponse;
    if (result?.status === 'confirm') {
      this.$i18nToastProvider.success(_('activity.status.review.requested_from'), {
        ownerType: this.$translateService.instant(this.$organizationUnitTypeNamePipe.transform(result.owner.type)).toLocaleLowerCase(),
        owner: result.owner.name,
      });
      await this.fetchActivityOwner(activityId);
    }
  }

  openInfoWindow($event: MouseEvent, sectionTypeId: ESectionType) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    if (!this.sectionsWithInfoDialog.includes(sectionTypeId)) {
      return;
    }

    const sectionTypeName = this.getSectionTypeName(sectionTypeId);

    this.dialog.open(InfoDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(`activity.section.type.${sectionTypeName}.title`),
        description: this.$translateService.instant(`activity.section.type.${sectionTypeName}.description`),
      },
    });
  }

  showEventLog() {
    this.router.navigate([`/activities/${this.activityId$.value}/events`]);
  }

  async setActivityCreatedByValues(activity: IActivity) {
    if (activity.status_id === EActivityStatusType.REVIEW) {
      if (activity.created_by_organization_unit_id) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const organization = await this.organizationUnitListService.fetch(activity.created_by_organization_unit_id);

          this.activityCreatedByOU = organization.name;
        } catch (errorResponse) {
          // There was an error fetching the ou but since its not critical to show this we supress the error and set the name to blank to be sure
          //
          this.activityCreatedByOU = '';
        }
      }

      if (activity.created_by_user_id) {
        // fetch user
        try {
          // eslint-disable-next-line no-await-in-loop
          const user = await this.userListService.fetch(activity.created_by_user_id);

          this.activityCreatedByUser = user.email;
        } catch (errorResponse) {
          // There was an error fetching the user but since its not critical to show this we supress the error and set the name to blank to be sure
          //
          this.activityCreatedByUser = '';
        }
      }
    } else {
      this.activityCreatedByUser = '';
      this.activityCreatedByOU = '';
    }
  }

  // Drop event for moving items around.
  //
  async dropSection(event: CdkDragDrop<{ id?: number; order?: number }[]>) {
    // Get the event values.
    //
    const { previousIndex, currentIndex, container } = event;

    // If the previous index is the exact same as the current, we don't need to move anything.
    //
    if (previousIndex === currentIndex) {
      return;
    }

    // Get the original list of items from before the drag and drop event,
    // and retrieve the dropped item from it.
    //
    const items = container.data as ISection[];
    const droppedItem = items[previousIndex];

    // Update the store optimistically by passing the third param as true.
    // Save the first promise to be awaited later.
    //
    const firstPromise = this.sectionService.update({ ...droppedItem, order: currentIndex + 1 });

    // Because the store is already being updated optimistically,
    // we only need to await the promises and restore the previous state if any promises fail.
    //
    const promises = [firstPromise];

    // Loop through all items in the drop list and determine whether they need to be updated.
    //
    for (let index = 0; index < items.length; index++) {
      // If the current item is actually the previous item, continue.
      //
      if (index === previousIndex) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // Get the current item,
      // and prepare the update promise.
      //
      const item = items[index];
      let promise: Promise<any>;

      // If the current item comes after the original dragged place, but before the drop position,
      // Save the index as the order. ( Less means the item moves up. )
      //
      if (index > previousIndex && index <= currentIndex) {
        promise = this.sectionService.update({ ...item, order: index });

        // If the current item comes before the original dragged place, but after the drop position,
        // add 2 to the index and save that value as the order. ( More means the item moves down. )
        //
      } else if (index < previousIndex && index >= currentIndex) {
        promise = this.sectionService.update({ ...item, order: index + 2 });
      }

      if (!isNil(promise)) {
        // Add the saved promise to the list of promises.
        //
        promises.push(promise);
      }
    }

    // Await all promises at the same time.
    //
    await Promise.all(promises);

    this.renderPdfTab(this.selectedPdfTabIndex);
  }

  async copyActivityWithFields(isLanguageCopy: boolean = false, language?: string, showToast: boolean = true) {
    this.isCopying$.next(true);
    const activityDetails = await firstValueFrom(this.activityDetails$);
    const activitySections = await firstValueFrom(this.activitySectionList$);
    const organizationUnitId = get(this.$session.getSession$.value.details, 'id');
    const selectedLanguage = (language ?? await firstValueFrom(this.$session.preferredContentLanguage$)).toLowerCase();
    const countryCode = await firstValueFrom(this.$session.ouCountryCode$);

    // Status ID 1 means the activity is a concept.
    //
    const statusId = 1;

    // create an IActivity object
    //
    const newActivity: IActivity = {
      name: activityDetails.name,
      status_id: statusId,
      subtitle: activityDetails.subtitle,
      summary: activityDetails.summary,
      duration: activityDetails.duration,
      location: activityDetails.location,
      media_uuid: activityDetails.media_uuid,
      preparation: activityDetails.preparation,
      language: selectedLanguage,
      country_code: countryCode.toUpperCase(),
      replace_activity_id: isLanguageCopy ? activityDetails.replace_activity_id : null,
    };

    // Post the IActivity object to the api
    //
    const createdActivity = (await firstValueFrom(this.activityService.create(organizationUnitId, newActivity))) as IActivity;

    // when the activity has successfully been created
    // link it to the current organization unit
    // and create material section for it
    //
    if (createdActivity) {
      const promises: Promise<any>[] = [];

      const activityTypes = (await firstValueFrom(this.activityService.activityType(this.activityId$.value))) as IActivityTypeListResponse;
      for (const activityType of activityTypes.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addActivityType(createdActivity.id as number, activityType.id as number)));
      }

      const activityAttachments = (await firstValueFrom(this.activityService.attachment(this.activityId$.value))) as IAttachmentListResponse;
      for (const activityAttachment of activityAttachments.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addAttachment(createdActivity.id as number, activityAttachment.media_id)));
      }

      const activityAgeGroups = (await firstValueFrom(this.activityService.ageGroup(this.activityId$.value))) as IAgeGroupListResponse;
      for (const activityAgeGroup of activityAgeGroups.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addAgeGroup(createdActivity.id as number, activityAgeGroup.id as number)));
      }

      const activityGroupSizes = (await firstValueFrom(this.activityService.groupSize(this.activityId$.value))) as IGroupSizeListResponse;
      for (const activityGroupSize of activityGroupSizes.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addGroupSize(createdActivity.id as number, activityGroupSize.id as number)));
      }

      const activityActivityKinds = (await firstValueFrom(this.activityService.activityKind(this.activityId$.value))) as IActivityKindListResponse;
      for (const activityActivityKind of activityActivityKinds.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addActivityKind(createdActivity.id as number, activityActivityKind.id as number)));
      }

      const activityDevelopmentAreas = (await firstValueFrom(this.activityService.areaOfDevelopment(this.activityId$.value))) as IAreaOfDevelopmentListResponse;
      for (const activityDevelopmentArea of activityDevelopmentAreas.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addAreaOfDevelopment(createdActivity.id as number, activityDevelopmentArea.id as number)));
      }

      const activityDevelopmentRanges = (await firstValueFrom(
        this.activityService.activityRangeOfDevelopment(this.activityId$.value),
      )) as IRangeOfDevelopmentListResponse;
      for (const activityDevelopmentRange of activityDevelopmentRanges.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addRangeOfDevelopment(createdActivity.id as number, activityDevelopmentRange.id as number)));
      }

      const activityTags = (await firstValueFrom(this.activityService.activityTag(this.activityId$.value))) as ITagListResponse;
      for (const activityTag of activityTags.items) {
        // eslint-disable-next-line no-await-in-loop
        promises.push(firstValueFrom(this.activityService.addActivityTag(createdActivity.id as number, activityTag.id as number)));
      }

      for (const section of activitySections) {
        const newSection: ISection = {
          type_id: section.type_id,
          name: section.name,
          activity_id: createdActivity.id,
          order: section.order,
          data: section.data,
          columns: section.columns,
        };

        // eslint-disable-next-line no-await-in-loop
        promises.push(this.sectionService.create(newSection));
      }

      if (isLanguageCopy) {
        promises.push(firstValueFrom(this.activityService.addRelatedActivity(activityDetails.id, createdActivity.id)));
      }

      await Promise.all(promises);

      this.isCopying$.next(false);
      this.router.navigate([`/activities/${createdActivity.id}/edit`], { replaceUrl: true });

      if (showToast) {
        this.$i18nToastProvider.success(_('activity.copy.success'));
      }
      await this.fetchActivityDetails(createdActivity.id);
      await this.fetchActivityOwner(createdActivity.id);
      this.activityId$.next(createdActivity.id);
    }
  }

  getSectionTypeName(sectionTypeId: ESectionType): string {
    return getNameForSectionType(sectionTypeId);
  }

  getActivityStatusName(activityStatusId: EActivityStatusType): string {
    switch (activityStatusId) {
      case EActivityStatusType.CONCEPT:
        return 'concept';
      case EActivityStatusType.REVIEW:
        return 'review';
      case EActivityStatusType.PUBLISHED:
        return 'published';
      case EActivityStatusType.UNPUBLISHED:
        return 'unpublished';
      default:
        return 'unknown';
    }
  }

  renderPdfTab(tabIndex: number) {
    if (tabIndex === 0) {
      return this.renderPdf();
    } else if (tabIndex === 1) {
      return this.renderAttachments();
    }
  }

  async createProgram() {
    const activity = await firstValueFrom(this.activityDetails$);
    this.activitySelectionProvider.clearSelectedActivities();
    this.activitySelectionProvider.addActivity(activity);

    this.router.navigate(['/calendar']);
  }
}
