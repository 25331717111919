import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IActivityStatusListResponse } from 'typings/api-activity';
import { ActivityStatusStore } from './activity-status.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityStatusService {
  constructor(private store: ActivityStatusStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 10, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const activityStatusResponse = await firstValueFrom(this.$baseApi.genericListCall('/activity/activity-status', limit, skip, sortField, sortDirection)) as IActivityStatusListResponse;

    this.store.set(activityStatusResponse?.items ?? []);
  }
}
