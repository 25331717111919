import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IAgeGroupDetails } from 'typings/doenkids/doenkids';
import { IAgeGroupState, AgeGroupStore } from './age-group.store';
import { filter, map } from 'rxjs';
import { isNil, max, min } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AgeGroupQuery extends QueryEntity<IAgeGroupState, IAgeGroupDetails> {
  constructor(protected store: AgeGroupStore) {
    super(store);
  }

  firstNonEmpty() {
    return this.selectAll().pipe(filter((ageGroups) => !isNil(ageGroups) && ageGroups.length > 0));
  }

  getAgeGroupsForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((ageGroups) => ageGroups.filter((ageGroup) => ageGroup.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }

  getAgeRangeForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((ageGroups) => ageGroups.filter((ageGroup) => ageGroup.country_code.toLowerCase() === countryCode.toLowerCase())),
      map((ageGroups) => {
        const minAgeFrom = min(ageGroups.map((ageGroup) => ageGroup.age_from));
        const maxAgeTo = max(ageGroups.map((ageGroup) => ageGroup.age_to));

        return { minAgeFrom, maxAgeTo };
      }),
    );
  }
}
