<h2 mat-dialog-title>{{ 'publish_activity_dialog.title' | translate }}</h2>
<mat-dialog-content>
  <p
    [innerHtml]="'publish_activity_dialog.description' | translate: {
      organizationUnitTypeName: selectedOwner.type | ou_type_name | translate,
      organizationUnitName: selectedOwner?.name || ''
    }"
  ></p>
  <p>
    {{ 'publish_activity_dialog.other.select.0' | translate }}
    <a (click)="selectOther()" href="javascript:void(0)">
      {{ 'publish_activity_dialog.other.select.1' | translate }}
    </a>
    {{ 'publish_activity_dialog.other.select.2' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onCancel()">
    {{ 'generic.cancel' | translate | uppercase }}
  </button>
  <span class="spacer"></span>
  <button mat-button color="primary" (click)="onConfirm()">{{ 'generic.ok' | translate }}</button>
</mat-dialog-actions>
