import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnitBrowseResponse } from 'typings/api-customer';
import { IOrganizationUnitBrowseState, OrganizationUnitBrowseStore } from './organization-unit-browse.store';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitBrowseQuery extends QueryEntity<IOrganizationUnitBrowseState, IOrganizationUnitBrowseResponse> {
  constructor(protected store: OrganizationUnitBrowseStore) {
    super(store);
  }
}
