import { StoreConfig, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IPeriodSection } from 'typings/doenkids/doenkids';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'period' })
export class PeriodSectionStore extends Store<IPeriodSection> {
  constructor() {
    super({});
  }
}
