import { TranslateService } from 'src/app/utils/translate.service';
import { environment } from '../../environments/environment';

export class LocaleIdProvider extends String {
  constructor(private $translateService: TranslateService) {
    super(environment.defaultLanguage);
  }

  toString() {
    return this.$translateService.currentLang;
  }
}
