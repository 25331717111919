import {
  Component, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNil } from 'lodash';
import { ProgramCategoryListService } from 'src/api/activity/program-category-list/program-category-list.service';
import { IProgramCategory, IProgramTemplate, IProgramTemplateBundle } from 'typings/doenkids/doenkids';
import { ProgramTemplateListService } from 'src/api/activity/program-template-list/program-template-list.service';
import { ProgramTemplateBundleListService } from 'src/api/activity/program-template-bundle-list/program-template-bundle-list.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PermissionProvider } from 'src/providers/permission.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nTitleStrategy } from '../../../app/utils/i18n-title-strategy';
import { I18nToastProvider } from '../../../providers/i18n-toast.provider';

@Component({
  selector: 'app-program-template-reorder',
  templateUrl: './program-template-reorder.component.html',
  styleUrls: ['./program-template-reorder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramTemplateReorderComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public selectedTab = 0;

  private programCategoryId: number;

  private activityTypeId: number;

  public programCategoryDetails: IProgramCategory;

  public programCategoryTemplates: IProgramTemplate[];

  public programCategoryTemplateBundles: IProgramTemplateBundle[];

  public selectedOUId: number;

  public isAdmin$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  constructor(
    private $session: DoenkidsSessionProvider,
    $permission: PermissionProvider,
    private activeRoute: ActivatedRoute,
    private programCategoryListService: ProgramCategoryListService,
    private programTemplateListService: ProgramTemplateListService,
    private programTemplateBundleListService: ProgramTemplateBundleListService,
    private $i18nTitleStrategy: I18nTitleStrategy,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.isAdmin$ = this.$session.isAdmin$;
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = $permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$;

    this.programCategoryTemplates = [];
    this.programCategoryTemplateBundles = [];
  }

  async ngOnInit() {
    const { params } = this.activeRoute.snapshot;
    this.programCategoryId = +params.id;
    this.activityTypeId = +params.activityTypeId;

    if (isNil(this.programCategoryId)) {
      this.$i18nToastProvider.error(_('program_template_reorder.invalid_url'));

      return;
    }

    this.$session.currentOuId$.pipe(takeUntil(this.stop$)).subscribe((organizationUnitId) => {
      this.selectedOUId = organizationUnitId;

      this.fetchProgramCategoryDetails();
      this.fetchProgramCategoryTemplates();
      this.fetchProgramCategoryTemplateBundles();
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async fetchProgramCategoryDetails() {
    this.programCategoryDetails = await this.programCategoryListService.fetch(this.programCategoryId, true);
    this.$i18nTitleStrategy.updateTitleParameters({ category: this.programCategoryDetails.name });
  }

  async fetchProgramCategoryTemplates() {
    const response = await this.programTemplateListService.fetchAll({
      organizationUnitId: this.selectedOUId,
      limit: 5000,
      skip: 0,
      sortField: 'order',
      sortDirection: 'asc',
      programCategoryId: this.programCategoryId,
      activityTypeId: this.activityTypeId,
      doNotPersist: true,
    });

    this.programCategoryTemplates = response.items;
  }

  async fetchProgramCategoryTemplateBundles() {
    const response = await this.programTemplateBundleListService.fetchAll({
      organizationUnitId: this.selectedOUId,
      limit: 5000,
      skip: 0,
      sortField: 'order',
      sortDirection: 'ASC',
      programCategoryId: this.programCategoryId,
      activityTypeId: this.activityTypeId,
      doNotPersist: true,
    });

    this.programCategoryTemplateBundles = response;
  }

  async droppedTemplate(event: CdkDragDrop<string[]>) {
    const draggedTemplate: IProgramTemplate = this.programCategoryTemplates[event.previousIndex];
    const droppedTemplate: IProgramTemplate = this.programCategoryTemplates[event.currentIndex];
    let placement: 'before' | 'after' = 'before';

    if (event.currentIndex > event.previousIndex) {
      placement = 'after';
    }

    moveItemInArray(this.programCategoryTemplates, event.previousIndex, event.currentIndex);

    this.loading$.next(true);
    await this.programTemplateListService.reOrder(draggedTemplate.id, droppedTemplate.id, placement);
    this.fetchProgramCategoryTemplates();
    this.loading$.next(false);
  }

  async droppedTemplateBundle(event: CdkDragDrop<string[]>) {
    const draggedTemplateBundle: IProgramTemplateBundle = this.programCategoryTemplateBundles[event.previousIndex];
    const droppedTemplateBundle: IProgramTemplateBundle = this.programCategoryTemplateBundles[event.currentIndex];
    let placement: 'before' | 'after' = 'before';

    if (event.currentIndex > event.previousIndex) {
      placement = 'after';
    }

    moveItemInArray(this.programCategoryTemplateBundles, event.previousIndex, event.currentIndex);

    this.loading$.next(true);
    await this.programTemplateBundleListService.reOrder(draggedTemplateBundle.id, droppedTemplateBundle.id, placement);
    this.fetchProgramCategoryTemplateBundles();
    this.loading$.next(false);
  }
}
