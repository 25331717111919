import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectProvider {
  private redirectToStorageKey = 'redirectTo';

  setPathToRedirectTo(path: string) {
    if (path === '/' || path === '/login') {
      return;
    }
    localStorage.setItem(this.redirectToStorageKey, path);
  }

  getPathToRedirectTo() {
    const storedRedirectLocation = localStorage.getItem(this.redirectToStorageKey);
    if (storedRedirectLocation) {
      return storedRedirectLocation;
    }

    return '/';
  }

  clearPathToRedirectTo() {
    localStorage.removeItem(this.redirectToStorageKey);
  }
}
