import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ProgramStore, IProgramWithAccesLevel } from './program.store';

@Injectable({ providedIn: 'root' })
export class ProgramQuery extends Query<IProgramWithAccesLevel> {
  hasWriteAccess$() {
    return this.select().pipe(
      map((value) => value && value.access_level === 'WRITE'),
    );
  }

  constructor(protected store: ProgramStore) {
    super(store);
  }
}
