<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.description"></p>
  <ng-container *ngIf="(loading$ | async)">
    <mat-spinner class="spinner"></mat-spinner>
  </ng-container>
  <ng-container *ngIf="!(loading$ | async)">
    <tag-input
      [formControl]="templateTags"
      [onlyFromAutocomplete]="true"
      displayBy="name"
      identifyBy="id"
      [placeholder]="'program_template_tag_dialog.tag.add.placeholder' | translate"
      [secondaryPlaceholder]="'program_template_tag_dialog.tag.add.secondary_placeholder' | translate"
    >
      <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="( allTags$ | async )" displayBy="name" identifyBy="id" [keepOpen]="false" [appendToBody]="false">
        <ng-template let-item="item" let-index="index">
          <div class="tag">
            <mat-icon *ngIf="item.organization_unit_id === (currentOUId$ | async)">push_pin</mat-icon>
            <span>
                {{ item.name }}
            </span>
          </div>
        </ng-template>
      </tag-input-dropdown>
    </tag-input>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()" color="primary" cdkFocusInitial>
    {{ 'generic.ok' | translate }}
  </button>
</div>
