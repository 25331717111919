
<table mat-table [dataSource]="parsedTranslations" class="mat-elevation-z8">

  <!-- Label -->
  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef>{{ 'organization.translations.translation_label.label' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{element.label}} </td>
  </ng-container>

  <!-- Value -->
  <ng-container *ngFor="let language of languagesToShow" [matColumnDef]="language">
    <th mat-header-cell *matHeaderCellDef>{{ 'organization.translations.'+ language +'.label' | translate }}</th>
    <td mat-cell *matCellDef="let element"> {{element[language] }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
