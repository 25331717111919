import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IProgramTemplateBundle,
  IProgramTemplate,
  IProgramTemplateStatus,
  IProgramTemplateAttachmentMedia,
  IProgramTemplateBundleAttachmentMedia,
  IOrganizationUnitProgramTemplateBundle,
  IActivityType,
} from 'typings/doenkids/doenkids';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import { takeUntil, map, filter, switchMap, shareReplay } from 'rxjs/operators';
import { isNil, get } from 'lodash';
import { DoenkidsAssetProvider } from 'src/providers/assets.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { ProgramTemplateBundleService } from 'src/api/activity/program-template-bundle/program-template-bundle.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ProgramTemplateBundleProgramTemplateListService } from 'src/api/activity/program-tempate-bundle-program-template-list/program-tempate-bundle-program-template-list.service';
import { ProgramTemplateBundleProgramTemplateListQuery } from 'src/api/activity/program-tempate-bundle-program-template-list/program-tempate-bundle-program-template-list.query';
import { PermissionProvider } from 'src/providers/permission.provider';
import { ProgramCategoryListQuery } from 'src/api/activity/program-category-list/program-category-list.query';
import { ProgramTemplateBundleQuery } from 'src/api/activity/program-template-bundle/program-template-bundle.query';
import { ProgramCategoryListService } from 'src/api/activity/program-category-list/program-category-list.service';
import { PublishProgramService } from 'src/api/publish/program/program.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ProgramTemplateBundleAttachmentService } from 'src/api/media/program-template-bundle-attachment/program-template-bundle-attachment.service';
import { ProgramTemplateAttachmentDialogComponent } from 'src/components/dialogs/program-template-attachment-dialog/program-template-attachment-dialog.component';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { OrganizationUnitProgramTemplateBundleService } from 'src/api/customer/organization-unit-program-template-bundle/organization-unit-progam-template-bundle.service';
import { IProgramTemplateWithTags, ProgramTagsService } from 'src/api/activity/program-tags/program-tags.service';
import { ProgramTemplateTagDialogComponent } from 'src/components/dialogs/program-template-tag-dialog/program-template-tag-dialog.component';
import { IUploadResponse } from 'typings/custom-app-types';
import { IMediaItem } from 'typings/section-types';
import { Navigation, SwiperOptions } from 'swiper';
import { IProgramCategoryWithOrganizationUnit, IProgramTemplateBundleListResponse } from 'typings/api-activity';
import { swiperStyles } from 'src/directives/swiper.directive';
import { TranslateService } from 'src/app/utils/translate.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nTitleStrategy } from 'src/app/utils/i18n-title-strategy';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { ProgramTemplateSelectionProvider } from 'src/providers/program-template-selection.provider';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';

_('program.template_bundle.status.concept');
_('program.template_bundle.status.review');
_('program.template_bundle.status.published');
_('program.template_bundle.status.unpublished');
_('program.template_bundle.status.template');

@Component({
  selector: 'app-program-template-bundle-preview',
  templateUrl: './program-template-bundle-preview.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./program-template-bundle-preview.component.scss'],
})
export class ProgramTemplateBundlePreviewComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  private bundleChange$ = new Subject<void>();

  private selectedOUId: number;

  public isSmall$: Observable<boolean>;

  public isAdmin$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public isApproved$ = new BehaviorSubject(false);

  public baseOnly$ = new BehaviorSubject(false);

  public isRevoked$ = new BehaviorSubject(false);

  public isReOrdering$ = new BehaviorSubject<boolean>(false);

  public isCustomer$: Observable<boolean>;

  public isLocation$: Observable<boolean>;

  public isRootNode$: Observable<boolean>;

  public bundleId: number;

  public bundle$: Observable<IProgramTemplateBundle>;

  public bundleOwner$: Observable<IOrganizationUnitProgramTemplateBundle>;

  public okoTypes$: Observable<IActivityType[]>;

  public templatesWithTags: IProgramTemplateWithTags[] = [];

  public programCategories$: Observable<IProgramCategoryWithOrganizationUnit[]>;

  public hasWriteAccess$: Observable<boolean>;

  public programBundleForm: UntypedFormGroup;

  public hasOUWritePermissions$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public hasProgramExplanationPermission$: Observable<boolean>;

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public canBeForked$: Observable<boolean>;

  public isTheOwner: boolean;

  public programBundleTemplatesGroup: UntypedFormArray;

  public config: SwiperOptions = {
    modules: [Navigation],
    slidesPerView: 'auto',
    keyboard: true,
    mousewheel: false,
    navigation: true,
    injectStyles: [swiperStyles],
  };

  public programTemplateBundleAttachments$: BehaviorSubject<IProgramTemplateAttachmentMedia[]> = new BehaviorSubject<IProgramTemplateAttachmentMedia[]>([]);

  public programTemplateBundleExplanationAttachments$: BehaviorSubject<IProgramTemplateAttachmentMedia[]> = new BehaviorSubject<
  IProgramTemplateAttachmentMedia[]
  >([]);

  public defaultTemplateAttachmentLimit = 5;

  public currentTemplateAttachmentLimit = this.defaultTemplateAttachmentLimit;

  public templateAttachmentLimitIncrements = 5;

  public possibleContentLanguages$: Observable<string[]>;

  public contentLanguageControl = new FormControl('');

  private relatedProgramTemplateBundles$: Observable<IProgramTemplateBundleListResponse>;

  public approveIsTranslate$: Observable<boolean>;

  constructor(
    fb: UntypedFormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private assetService: DoenkidsAssetProvider,
    private $permissions: PermissionProvider,
    private programTemplateBundleProgramTemplateListService: ProgramTemplateBundleProgramTemplateListService,
    private programTemplateBundleService: ProgramTemplateBundleService,
    private programTemplateBundleQuery: ProgramTemplateBundleQuery,
    private programCategoryQuery: ProgramCategoryListQuery,
    private $session: DoenkidsSessionProvider,
    private programCategoryService: ProgramCategoryListService,
    $breakPointsService: BreakpointsProvider,
    private matDialog: MatDialog,
    private $publishProgramService: PublishProgramService,
    private programTemplateBundleAttachmentService: ProgramTemplateBundleAttachmentService,
    private $downloadFileService: DoenkidsFileDownloader,
    private programTagService: ProgramTagsService,
    private organizationUnitProgramTemplateBundleService: OrganizationUnitProgramTemplateBundleService,
    private $translateService: TranslateService,
    private $i18nTitleStrategy: I18nTitleStrategy,
    private $i18nToastProvider: I18nToastProvider,
    private programTemplateSelectionProvider: ProgramTemplateSelectionProvider,
  ) {
    this.isSmall$ = $breakPointsService.isSmall$.pipe(takeUntil(this.stop$));
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));
    this.isCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));
    this.isLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));
    this.isRootNode$ = this.$session.isCurrentOrganizationUnitARootNode$.pipe(takeUntil(this.stop$));
    this.hasWriteAccess$ = this.programTemplateBundleQuery.hasWriteAccess$().pipe(takeUntil(this.stop$));
    this.hasOUWritePermissions$ = this.$permissions.hasOUWritePermissions$.pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permissions.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));
    this.hasProgramExplanationPermission$ = this.$permissions.hasProgramExplanationPermission$.pipe(takeUntil(this.stop$));

    this.bundle$ = this.programTemplateBundleQuery.select().pipe(takeUntil(this.stop$));
    this.bundleOwner$ = this.bundle$.pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value) && !isNil(value.id)),
      switchMap((bundle) => this.programTemplateBundleService.fetchOwner(bundle.id)),
      map((owners) => {
        const ownerRoles = owners.filter((owner) => owner.organization_unit_role_id === DoenkidsStaticValuesHelper.PROGRAM_OWNER_ROLE);
        return ownerRoles[0];
      }),
      shareReplay(1),
    );

    this.okoTypes$ = this.$session.availableActivityTypes$.pipe(takeUntil(this.stop$));
    this.programCategories$ = this.programCategoryQuery.selectAll().pipe(takeUntil(this.stop$));

    this.canBeForked$ = combineLatest([this.bundle$, this.baseOnly$, this.hasOUWritePermissions$, this.$session.isCurrentOrganizationUnitARootNode$]).pipe(
      takeUntil(this.stop$),
      map(([bundle, baseOnly, canWrite, isARootNode]) => ![baseOnly, canWrite, isARootNode].includes(false) && !bundle.replace_program_template_bundle_id),
    );

    this.programBundleForm = fb.group({
      name: ['', Validators.required],
      content: [''],
      type_oko: [''],
      program_category: ['', Validators.required],
      media_uuid: ['', Validators.required],
    });

    this.activeRoute.queryParams.pipe(takeUntil(this.stop$)).subscribe((query) => {
      this.baseOnly$.next(query.baseOnly === true || query.baseOnly === 'true');
      this.isRevoked$.next(query.isRevoked === true || query.isRevoked === 'true');
    });

    this.possibleContentLanguages$ = this.$session.ouLanguages$.pipe(takeUntil(this.stop$));

    this.approveIsTranslate$ = combineLatest([this.bundle$, this.$session.ouCountryCode$]).pipe(
      takeUntil(this.stop$),
      map(([bundle, ouCountryCode]) => bundle?.country_code !== ouCountryCode),
    );
  }

  displayErrorFetchingBundle() {
    this.$i18nToastProvider.error(_('program.template_bundle.view.failure'));
  }

  async ngOnInit() {
    this.activeRoute.params.pipe(takeUntil(this.stop$)).subscribe(async (params) => {
      const bundleIdParam = parseInt(`${params.id}`, 10);

      if (this.bundleId && this.bundleId !== bundleIdParam) {
        this.templatesWithTags = [];
        this.bundleChange$.next();
      }

      this.bundleId = bundleIdParam;

      if (isNil(this.bundleId)) {
        this.$i18nToastProvider.error(_('program.template_bundle.view.invalid'));
        return;
      }

      let bundle;
      try {
        this.fetchProgramBundleTemplates(this.bundleId);
        this.fetchProgramTemplateBundleAttachments();
        bundle = await this.programTemplateBundleService.fetch(this.bundleId);

        if (!bundle) {
          this.displayErrorFetchingBundle();
          this.router.navigate(['']);
          return;
        }
        this.contentLanguageControl.setValue(bundle.language ?? '');
        this.relatedProgramTemplateBundles$ = this.programTemplateBundleService
          .relatedBundles(bundle.id)
          .pipe(takeUntil(this.stop$), takeUntil(this.bundleChange$));
        this.$i18nTitleStrategy.updateTitleParameters({ bundle: bundle.name });

        const { media_uuid: mediaUuid, name, content, activity_type_id: activityTypeId, program_category_id: programCategoryId } = bundle;

        this.programBundleForm.setValue({
          name,
          content,
          media_uuid: mediaUuid,
          type_oko: activityTypeId,
          program_category: programCategoryId,
        });

        this.contentLanguageControl.valueChanges.pipe(takeUntil(this.stop$), takeUntil(this.bundleChange$)).subscribe(async (newLanguage) => {
          const programTemplateBundle = await firstValueFrom(this.bundle$);
          const currentLanguage = programTemplateBundle.language;
          const currentOUId = this.selectedOUId;

          if (currentLanguage.toLowerCase() !== newLanguage.toLowerCase()) {
            const relatedBundles = (await firstValueFrom(this.relatedProgramTemplateBundles$))?.items ?? [];
            const bundleWithNewLanguage = relatedBundles.find(
              (relatedBundle) =>
                relatedBundle.language.toLowerCase() === newLanguage.toLowerCase() &&
                relatedBundle.country_code.toLowerCase() === programTemplateBundle.country_code.toLowerCase(),
            );

            if (bundleWithNewLanguage) {
              this.router.navigate([`/organization/${currentOUId}/bundle/${bundleWithNewLanguage.id}`], { replaceUrl: true });
            } else {
              const newBundle = await this.createLanguageCopyOfProgramTemplateBundle(newLanguage);
              if (newBundle) {
                await this.router.navigate([`/organization/${currentOUId}/bundle/${newBundle.id}`], { replaceUrl: true });
              }
            }
          }
        });
      } catch (error) {
        this.displayErrorFetchingBundle();
        return;
      }
    });

    this.$session.currentOuId$.pipe(takeUntil(this.stop$)).subscribe((organizationId) => {
      this.selectedOUId = organizationId;
      this.programCategoryService.fetchAll(this.selectedOUId, {});
      this.fetchApprovalStatus();

      if (this.baseOnly$.value) {
        this.router.navigate([`/organization/${this.selectedOUId}/bundle/${this.bundleId}`], {
          replaceUrl: true,
          queryParams: { baseOnly: this.baseOnly$.value, isRevoked: this.isRevoked$.value },
        });
      } else {
        this.router.navigate([`/organization/${this.selectedOUId}/bundle/${this.bundleId}`], { replaceUrl: true });
      }
    });
  }

  openTagDialogForProgram(event: Event, template: IProgramTemplateWithTags) {
    event.stopPropagation();
    event.preventDefault();

    const dialogRef = this.dialog.open(ProgramTemplateTagDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_bundle.tags.dialog.title')),
        description: this.$translateService.instant(_('program.template_bundle.tags.dialog.description'), { templateName: template.name }),
        programTemplate: template,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'success') {
        const programTags = await this.programTagService.fetchAll(template.program_id, 5000, 0);

        template.tags = programTags;
      }
    });
  }

  async fetchProgramTemplateBundleAttachments() {
    const attachments = await this.programTemplateBundleAttachmentService.fetchAttachmentsWithMedia({ programTemplateBundleId: this.bundleId });

    const nonExplanationAttachments = [];
    const explanationAttachments = [];
    for (const attachment of attachments) {
      // eslint-disable-next-line no-await-in-loop
      const attachmentMedia = (await this.assetService.fetch(attachment.media_id))?.data;

      if (attachmentMedia.purpose === 'program-explanation') {
        explanationAttachments.push(attachment);
      } else {
        nonExplanationAttachments.push(attachment);
      }
    }

    this.programTemplateBundleAttachments$.next(nonExplanationAttachments);
    this.programTemplateBundleExplanationAttachments$.next(explanationAttachments);
  }

  async fetchApprovalStatus() {
    try {
      const result = await this.programTemplateBundleService.approvalFetch(this.selectedOUId, this.bundleId);
      this.isApproved$.next(!isNil(result?.id) && result.organization_unit_role_id !== 4);
    } catch (error) {
      this.isApproved$.next(false);
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async fetchProgramBundleTemplates(bundleId: number) {
    const templates = await this.programTemplateBundleProgramTemplateListService.fetchAll(
      {
        limit: 100,
        skip: 0,
        sortField: 'bundle_order',
        sortDirection: 'ASC',
        programBundleId: bundleId,
      },
      true,
    );

    const templatesWithTags: IProgramTemplateWithTags[] = [];

    for (const template of templates) {
      // eslint-disable-next-line no-await-in-loop
      const programTags = await this.programTagService.fetchAll(template.program_id, 5000, 0);

      templatesWithTags.push({
        ...template,
        tags: programTags,
      });
    }

    this.templatesWithTags = templatesWithTags;

    return templatesWithTags;
  }

  async openProgramTemplate(template: IProgramTemplate) {
    this.router.navigate([`/organization/${this.selectedOUId}/template/${template.id}`], {
      queryParams: {
        baseOnly: this.baseOnly$.value,
        bundleId: this.bundleId,
      },
    });
  }

  async addCoverImage(mediaItem: IUploadResponse | IMediaItem) {
    const coverImageCtrl = this.programBundleForm.get('media_uuid');
    const mediaUuid = mediaItem.uuid ? mediaItem.uuid : '';
    const oldUuid = coverImageCtrl.value;
    coverImageCtrl.setValue(mediaUuid || null);
    const bundle = await firstValueFrom(this.bundle$);
    const result = await this.programTemplateBundleService.update({ ...bundle, media_uuid: mediaUuid });

    if (isNil(result)) {
      this.$i18nToastProvider.error(_('program.template_bundle.cover_image.add.failure'));
      coverImageCtrl.setValue(oldUuid);
    }
  }

  async removeApproval() {
    const bundle = await firstValueFrom(this.bundle$);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_bundle.approval.remove.dialog.title')),
        description: this.$translateService.instant(_('program.template_bundle.approval.remove.dialog.description'), { bundle_name: bundle.name }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        await this.programTemplateBundleService.approvalRemove(this.selectedOUId, bundle.id);
        await this.fetchApprovalStatus();
        this.router.navigate([`/organization/${this.selectedOUId}/templates`]);
      }
    });
  }

  async approveBundle() {
    const bundle = await firstValueFrom(this.bundle$);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_bundle.approve.dialog.title')),
        description: this.$translateService.instant(_('program.template_bundle.approve.dialog.description'), { bundleName: bundle.name }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        await this.programTemplateBundleService.approve(this.selectedOUId, bundle.id);
        await this.fetchApprovalStatus();
        this.router.navigate([`/organization/${this.selectedOUId}/bundle/${bundle.id}`]);
      }
    });
  }

  async save() {
    const mediaUuid = this.programBundleForm.get('media_uuid').value;
    const name = this.programBundleForm.get('name').value;
    const activityTypeId = this.programBundleForm.get('type_oko').value;
    const programCategoryId = this.programBundleForm.get('program_category').value;
    const content = this.programBundleForm.get('content').value;
    const programTemplate = await firstValueFrom(this.bundle$);

    const result = await this.programTemplateBundleService.update({
      ...programTemplate,
      name,
      content,
      media_uuid: mediaUuid,
      activity_type_id: activityTypeId,
      program_category_id: programCategoryId,
    });

    if (isNil(result)) {
      this.$i18nToastProvider.error(_('program.template_bundle.save.failed'));
    }
  }

  removeBundle() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('program.template_bundle.remove.dialog.description')),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        const removedResult = await this.programTemplateBundleService.archive(this.bundleId);

        if (isNil(removedResult)) {
          this.$i18nToastProvider.error(_('program.template_bundle.remove.failed'));

          return;
        }
        this.router.navigate([`/organization/${this.selectedOUId}/templates`]);
      }
    });
  }

  async forkBundle(isTranslate: boolean = false) {
    let result;

    if (!isTranslate) {
      const currentProgramBundel = await firstValueFrom(this.bundle$);
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('program.template_bundle.fork.dialog.title')),
          description: this.$translateService.instant(_('program.template_bundle.fork.dialog.description'), { bundleName: currentProgramBundel.name }),
        },
      });

      result = await firstValueFrom(dialogRef.afterClosed());
    } else {
      result = 'confirm';
    }

    if (result === 'confirm') {
      const newBundle = await this.programTemplateBundleService.copy(this.selectedOUId, this.bundleId);
      this.programTemplateBundleService.fetch(newBundle.id);
      this.fetchProgramBundleTemplates(newBundle.id);
      if (!isTranslate) {
        this.$i18nToastProvider.success(_('program.template_bundle.fork.success'));
      }
      this.router.navigate([`/organization/${this.selectedOUId}/bundle/${newBundle.id}`]);
    }
  }

  public downloadPoster() {
    // eslint-disable-next-line no-undef
    const a = document.createElement('a');
    this._downloadPoster(a);
  }

  async changePublicationStatus(status: IProgramTemplateStatus) {
    const programTemplateBundle = await firstValueFrom(this.bundle$);
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_bundle.status.change.dialog.title')),
        // eslint-disable-next-line max-len
        description: this.$translateService.instant(_('program.template_bundle.status.change.dialog.title'), {
          status: this.translateStatusName(status.name),
        }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        programTemplateBundle.program_template_status_id = status.id;
        await this.programTemplateBundleService.update(programTemplateBundle);
        this.programTemplateBundleService.fetch(programTemplateBundle.id);
      }
    });
  }

  private async _downloadPoster(a: HTMLAnchorElement) {
    const programTemplate = await firstValueFrom(this.bundle$);
    const mediaUuid = get(programTemplate, 'media_uuid');

    if (isNil(programTemplate) || isNil(mediaUuid)) {
      this.$i18nToastProvider.error(_('program.poster.download.not_available'));

      return;
    }

    const mediaPromise = this.assetService.fetchByUuid(mediaUuid);
    const mediaAssetUrlPromise = this.assetService.getUrl(mediaUuid, { program_template_bundle_id: this.bundleId });

    const results = await Promise.all([mediaPromise, mediaAssetUrlPromise]);

    const media = results[0];
    const mediaAssetUrl = results[1];

    if (isNil(mediaAssetUrl) || mediaAssetUrl === '') {
      this.$i18nToastProvider.error(_('program.template_bundle.poster.download.failed'));

      return;
    }

    a.target = '_blank';
    a.href = mediaAssetUrl;
    a.download = media.filename;
    a.click();
  }

  translateStatusName(statusName: string) {
    switch (statusName.toUpperCase()) {
      case 'CONCEPT':
      case 'REVIEW':
      case 'PUBLISHED':
      case 'UNPUBLISHED':
      case 'TEMPLATE':
        return this.$translateService.instant(`program.template_bundle.status.${statusName.toLowerCase()}`);
      default:
        return statusName;
    }
  }

  public async downloadBundle() {
    this.isLoading$.next(true);

    const bundleName = this.programBundleForm.get('name').value;
    const posterUUID = this.programBundleForm.get('media_uuid').value;
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    const bundleTemplates = this.templatesWithTags;
    const bundleTemplateProgramIds = bundleTemplates.map((template) => template.program_id);

    let bundlePDF;
    try {
      bundlePDF = await this.$publishProgramService.fetchProgramBookletPdf({
        program_ids: bundleTemplateProgramIds,
        organization_unit_id: organizationUnitId,
        cover_media_uuid: posterUUID,
      });
    } catch (e) {
      // this is just to catch the error from bubbling up. the base api will show the toast
      //
    }

    if (bundlePDF) {
      this.$downloadFileService.addDownload({
        name: bundleName,
        blob: bundlePDF,
      });
    }

    this.isLoading$.next(false);
  }

  public toggleReOrdering() {
    this.isReOrdering$.next(!this.isReOrdering$.value);
  }

  async droppedTemplate(event: CdkDragDrop<string[]>) {
    const allTemplates = this.templatesWithTags;
    const draggedTemplate: IProgramTemplate = allTemplates[event.previousIndex];
    const droppedTemplate: IProgramTemplate = allTemplates[event.currentIndex];
    let placement: 'before' | 'after' = 'before';

    if (event.currentIndex > event.previousIndex) {
      placement = 'after';
    }

    this.isLoading$.next(true);
    try {
      await this.programTemplateBundleService.reOrder(this.bundleId, draggedTemplate.id, droppedTemplate.id, placement);
      this.fetchProgramBundleTemplates(this.bundleId);
    } catch (e) {
      console.error(e);
    }

    this.isLoading$.next(false);
  }

  async addAttachment() {
    const programTemplateBundle = await firstValueFrom(this.bundle$);

    const dialogRef = this.matDialog.open(ProgramTemplateAttachmentDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: {
        programTemplateBundleId: programTemplateBundle.id,
        purpose: 'program-attachment',
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchProgramTemplateBundleAttachments();
    });
  }

  async addExplanationAttachment() {
    const programTemplateBundle = await firstValueFrom(this.bundle$);

    const dialogRef = this.matDialog.open(ProgramTemplateAttachmentDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: {
        programTemplateBundleId: programTemplateBundle.id,
        purpose: 'program-explanation',
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchProgramTemplateBundleAttachments();
    });
  }

  async removeAttachment($event: any, attachment: IProgramTemplateBundleAttachmentMedia) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    await this.programTemplateBundleAttachmentService.delete(this.bundleId, attachment.media_id);
    this.fetchProgramTemplateBundleAttachments();
  }

  async downloadAttachment(attachment: IProgramTemplateBundleAttachmentMedia, event: MouseEvent) {
    // For iOS Safari, we first need to open the window in a *synchronous* function. Async window.open calls do NOT work.
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    //
    // I removed the window.open calls, and replaced them with html anchor element clicks.
    //
    event.preventDefault();
    event.stopPropagation();
    const a = document.createElement('a');
    this.assetService.getUrl(attachment.uuid, { program_template_bundle_id: this.bundleId }).then((mediaAssetUrl) => {
      fetch(mediaAssetUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = attachment.filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
    });
  }

  loadMoreAttachments() {
    this.currentTemplateAttachmentLimit += this.templateAttachmentLimitIncrements;
  }

  async editAttachment(attachment: IProgramTemplateAttachmentMedia, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.matDialog.open(ProgramTemplateAttachmentDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: {
        programTemplateBundleId: this.bundleId,
        currentAttachment: attachment,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchProgramTemplateBundleAttachments();
    });
  }

  async excludeProgramTemplateBundle() {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_bundle.exclude.dialog.title')),
        description: this.$translateService.instant(_('program.template_bundle.exclude.dialog.description')),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        const bundle = await firstValueFrom(this.bundle$);

        // magic number 4 is the value for a REVOKE record
        //
        await firstValueFrom(this.organizationUnitProgramTemplateBundleService.update(this.selectedOUId, bundle.id, 4));

        if (this.baseOnly$.value) {
          this.router.navigate([`/organization/${this.selectedOUId}/base/templates`]);
        } else {
          this.router.navigate([`/organization/${this.selectedOUId}/templates`]);
        }
      }
    });
  }

  public handleIsTheOwnerChange(isTheOwner: boolean) {
    if (isTheOwner) {
      this.programBundleForm.enable();
      this.contentLanguageControl.enable();
    } else {
      this.programBundleForm.disable();
      this.contentLanguageControl.disable();
    }
  }

  async createLanguageCopyOfProgramTemplateBundle(language: string) {
    this.isLoading$.next(true);
    const bundle = await firstValueFrom(this.bundle$);

    const newBundle = await this.programTemplateBundleService.copy(this.selectedOUId, bundle.id, undefined, undefined, language);

    const templatesInNewBundle = await this.fetchProgramBundleTemplates(newBundle.id);
    const nonRightLanguageTemplates = templatesInNewBundle.filter((template) => template.language !== language);

    this.isLoading$.next(false);

    if (nonRightLanguageTemplates.length > 0) {
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('program_template_bundle.copy.missed_templates.title')),
          description: this.$translateService.instant(_('program_template_bundle.copy.missed_templates.description'), {
            templates: nonRightLanguageTemplates.map((template) => template.name ?? '').join(', '),
            templateCount: nonRightLanguageTemplates.length,
          }),
        },
      });

      await firstValueFrom(dialogRef.afterClosed());
    }

    return newBundle;
  }

  async replaceTemplate($event: Event, template: IProgramTemplate) {
    $event.stopPropagation();
    $event.preventDefault();

    const bundle = await firstValueFrom(this.bundle$);

    this.programTemplateSelectionProvider.setTemplateToSwitch(template, bundle);

    this.router.navigate([`/organization/${this.selectedOUId}/templates`], { queryParams: { language: bundle.language } });
  }

  async previewAttachment(attachment: IProgramTemplateAttachmentMedia) {
    const a = document.createElement('a');
    this.assetService.getUrl(attachment.uuid, { program_template_bundle_id: this.bundleId }).then((mediaAssetUrl) => {
      a.href = mediaAssetUrl;
      a.target = '_blank';
      a.download = attachment.filename;
      a.click();
    });
  }
}
