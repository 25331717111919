<h1 mat-dialog-title>{{ 'program.template_bundle.create.title' | translate }}</h1>
<mat-dialog-content>
  <swiper-container *ngIf="$programTemplateSelection.programTemplates$ | async" class="swiper-container" fxFlex="auto" [config]="swiperConfig" dkSwiper init="false">
    <swiper-slide *ngFor="let item of $programTemplateSelection.programTemplates$ | async" class="swiper-slide">
      <app-doenkids-image [mediaItem]="item.media_uuid" [maxWidth]="100"></app-doenkids-image>
    </swiper-slide>
  </swiper-container>
  <app-asset-upload
    identifier="template-cover"
    purpose="program-template"
    (uploaded)="addCoverImage($event)">
    <ng-template let-uploadData="uploadData">
      <div class="image-container">
        <app-doenkids-image
          [maxWidth]="150"
          [maxHeight]="150"
          [preview]="true"
          [mediaItem]="createBundleForm.get( 'mediaUuid' ).value">
          <app-select-media
            identifier="template-cover"
            [uploadData]="uploadData"
            [removable]="createBundleForm.get( 'mediaUuid' ).value"
            (selected)="addCoverImage($event)"
            purpose="program-template">
          </app-select-media>
        </app-doenkids-image>
        <mat-error *ngIf="createBundleForm.get( 'mediaUuid' ).getError( 'required' )">
          {{ 'program.template_bundle.create.cover_image.required' | translate }}
        </mat-error>
      </div>
    </ng-template>
  </app-asset-upload>
  <form class="form-fields" [formGroup]="createBundleForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'program.template_bundle.create.name.label' | translate }}</mat-label>
      <input
        formControlName="name"
        matInput
        [placeholder]="'program.template_bundle.create.name.placeholder' | translate"
        required
      />
      <mat-error *ngIf="createBundleForm.get( 'name' ).getError( 'required' )">
        {{ 'program.template_bundle.create.name.required' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'program.template_bundle.create.suitable_for.label' | translate }}</mat-label>
      <mat-select formControlName="type_oko">
        <mat-option *ngFor="let activity_type of okoTypes$ | async" [value]="activity_type.id">
          {{ activity_type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'program.template_bundle.create.category.label' | translate }}</mat-label>
      <mat-select formControlName="programCategory">
        <mat-option *ngFor="let category of productCategories$ | async" [value]="category.id">{{category.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <button mat-button (click)="confirm()" color="primary" cdkFocusInitial [disabled]="createBundleForm.invalid">
    {{ 'generic.create' | translate | uppercase }}
  </button>
</mat-dialog-actions>
