import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IProgramCategoryListResponse } from 'typings/api-activity';
import { IProgramCategory } from 'typings/doenkids/doenkids';
import { ProgramCategoryListStore } from './program-category-list.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramCategoryListService {
  constructor(private store: ProgramCategoryListStore, private $baseApi: DoenKidsGenericApiProvider) { }

  async fetchAll(organizationUnitId: number, {
    limit = 100,
    skip = 0,
    sortField = 'order',
    sortDirection = 'desc',
    language,
    doNotPersist = false,
  }: {
    limit?: number,
    skip?: number,
    sortField?: string,
    sortDirection?: SortDirection,
    language?: string,
    doNotPersist?: boolean,
  }) {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      '/activity/program-category', limit, skip, sortField, sortDirection, { organizationUnitId, language },
    )) as IProgramCategoryListResponse;

    if (!doNotPersist) {
      this.store.set(response?.items ?? []);
    }

    return response;
  }

  async fetch(programCategoryId: number, doNotPersist: boolean = false) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(
      `/activity/program-category/${programCategoryId}`,
    )) as IProgramCategory;

    if (!doNotPersist && response) {
      this.store.upsert(response.id, response);
    }

    return response;
  }

  async create(organizationUnitId: number, categoryName: string): Promise<IProgramCategory> {
    const newCategory = await firstValueFrom(this.$baseApi.genericPostCall('/activity/program-category', { name: categoryName }, null, { organizationUnitId })) as IProgramCategory;

    this.store.update(newCategory);

    return newCategory;
  }

  async update(programCategoryId: number, programCategory: IProgramCategory): Promise<IProgramCategory> {
    const updatedCategory = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/program-category/${programCategoryId}`, {
      name: programCategory.name,
      order: programCategory.order,
    })) as IProgramCategory;
    return updatedCategory;
  }

  async reShuffle(movedCategory: number, categoryAsReference: number, placement: 'before' | 'after' = 'before') {
    await firstValueFrom(this.$baseApi.genericPutCall(`/activity/program-category/${movedCategory}/reorder/${categoryAsReference}?placement=${placement}`));
  }

  async archive(programCategoryId: number) {
    await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/program-category/${programCategoryId}`));
    this.store.remove(programCategoryId);
  }
}
