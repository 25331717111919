import {
  AfterViewInit,
  Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  defer,
  flatten,
  get,
  isEmpty,
  isEqual,
  isNil,
  keys,
  sortBy,
} from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { PeriodSectionService } from 'src/api/activity/period-section/period-section.service';
import { OrganizationUnitSymbolListQuery } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.query';
import { OrganizationUnitSymbolListService } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ICustomerSymbol, IPeriodSection } from 'typings/doenkids/doenkids';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { IProgramSummaryPeriod, IProgramSummaryPeriodSection } from 'typings/api-activity';
import { convertIProgramSummaryPeriodSectionToIPeriodSection } from 'src/api/activity/program/program.service';
import { EProgramPeriodSectionType } from 'src/components/shared/section/section.component';

@Component({
  selector: 'app-program-symbols-dialog',
  templateUrl: './program-symbols-dialog.component.html',
  styleUrls: ['./program-symbols-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramSymbolsDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();

  public form: UntypedFormGroup;

  @ViewChild(MatTable) table: MatTable<any>;

  public initialized = false;

  public symbols$: Observable<ICustomerSymbol[]>;

  public title: string;

  public subtitle: string;

  public programActivities$ = new BehaviorSubject<IProgramSummaryPeriodSection[]>([]);

  private localActivitySymbols: { [key: number]: number[] } = {};

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  public displayedColumns: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private periodSectionService: PeriodSectionService,
    private dialogRef: MatDialogRef<ProgramSymbolsDialogComponent>,
    private $session: DoenkidsSessionProvider,
    private elementRef: ElementRef,
    private organizationUnitSymbolListQuery: OrganizationUnitSymbolListQuery,
    private organizationUnitSymbolListService: OrganizationUnitSymbolListService,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    @Inject(MAT_DIALOG_DATA) public data: { programId: number, periods: IProgramSummaryPeriod[], maxSymbols: number, title: string, subtitle: string },

  ) {
    this.title = this.data.title;
    this.subtitle = this.data.subtitle;
    this.form = fb.group({});

    this.symbols$ = combineLatest([this.$session.organizationActivityTypeIds$, this.organizationUnitSymbolListQuery.selectAll()]).pipe(
      filter(([activityTypeIds, symbols]) => !isNil(activityTypeIds) && !isNil(symbols)),
      map(([activityTypeIds, symbols]) => {
        // null is also a valid type, means any of the above
        //
        const activitTypeCollection = [null].concat(activityTypeIds);
        const filteredSymbols = symbols.filter((symbol) => activitTypeCollection.includes(symbol.activity_type_id));
        return filteredSymbols;
      }),
    );

    this.programActivities$.next(flatten(this.data.periods.map((period) => (period.section ?? []).filter((section) => section.type_id === EProgramPeriodSectionType.ACTIVITY))));

    combineLatest([this.symbols$, this.programActivities$]).pipe(
      filter(([symbols, activities]) => !isNil(symbols) && !isNil(activities)),
      takeUntil(this.destroy$),
      map(([symbols = [], activities = []]) => {
        const results = [];
        activities.forEach((activity) => {
          const row = {
            activity: activity?.title,
            id: activity.id,
          };

          symbols.forEach((symbol) => {
            row[symbol.id] = { ...symbol, formId: `${activity.id}-${symbol.id}` };
          });

          results.push(row);
        });
        return sortBy(results, 'id');
      }),
    ).subscribe((incData) => {
      this.dataSource.data = incData;
    });

    this.symbols$.pipe(
      takeUntil(this.destroy$),
      filter((symbols) => !isNil(symbols)),
      map((symbols) => {
        let result = ['activity'];
        result = result.concat(symbols.map((symbol) => `${symbol.id}`));
        return result;
      }),
    ).subscribe((columns) => {
      this.displayedColumns = columns;
    });
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(isEqual),
    ).subscribe(() => {
      if (this.initialized) {
        this.checkMaxSymbols();
      }
    });
  }

  // I know, this is not the angular way.
  // But its late, its been another long day
  // And I give this my blessing
  //
  cssHackForErrorIndicator() {
    // First remove old class annotations
    //
    this.elementRef.nativeElement.querySelectorAll('.error-row').forEach((node) => {
      node.classList.remove('error-row');
    });

    // Add the new ones
    //
    this.elementRef.nativeElement.querySelectorAll('.error-checkbox').forEach((node) => {
      node.parentNode.parentElement.classList.add('error-row');
    });
  }

  async ngOnInit() {
    combineLatest([this.programActivities$, this.symbols$])
      .pipe(
        takeUntil(this.destroy$),
        filter(
          ([programActivities, symbols]) => !isNil(programActivities)
            && !isNil(symbols)
            && !isEmpty(symbols),
        ),
        distinctUntilChanged(isEqual),
      ).subscribe(async ([programActivities, symbols]) => {
        // Prepare a lookup dictionary
        //
        programActivities.forEach((activity) => {
          this.localActivitySymbols[activity.id] = get(activity, 'customer_symbol', []);
        });

        for (const programActivity of programActivities) {
          // Prepare the form array key. Initialize the form array if needed.
          //
          const programActivityId = programActivity.id;

          for (const symbol of symbols) {
            const symbolId = symbol.id;

            // Prepare the form control key, Initialize the form control if needed.
            //
            const checkboxId = `${programActivityId}-${symbolId}`;

            // Get the program activity's symbol list for an initial value.
            //
            const customerSymbol: string[] = get(programActivity, 'customer_symbol');

            // Set the control to true if the symbolId is present in the programActivity's customer_symbol array.
            //
            this.form.addControl(checkboxId, this.fb.control((!isNil(customerSymbol) && customerSymbol.includes(`${symbol.id}`))));
          }
        }
        this.initialized = true;

        // Check max symbol initially
        //
        this.checkMaxSymbols();
      });

    if (isEmpty(this.data.periods)) {
      this.$i18nToastProvider.error(_('program_symbols_dialog.no_activities.toast'));
      this.dialogRef.close();
    }

    const currentOrganizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    this.organizationUnitSymbolListService.fetchAll(currentOrganizationUnitId, 500, 0, 'display_order', 'desc');
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  async closeDialog() {
    this.dialogRef.close();
  }

  async checkMaxSymbols() {
    // Get the form value and obtain all the keys so we can loop through the list.
    //
    const symbolFormValue = get(this.form, 'value');

    const symbolKeys = Object.keys(symbolFormValue);

    const tmpDictionary = {};
    for (const prop in symbolFormValue) {
      if (Object.prototype.hasOwnProperty.call(symbolFormValue, prop)) {
        const propArray = prop.split('-');
        const activityId = parseInt(propArray[0], 10);
        const symbolId = parseInt(propArray[1], 10);

        // Only checked values
        //
        if (symbolFormValue[prop]) {
          if (tmpDictionary[activityId]) {
            tmpDictionary[activityId].push(symbolId);
          } else {
            tmpDictionary[activityId] = [symbolId];
          }
        }
      }
    }

    this.localActivitySymbols = tmpDictionary;
    let errorsFound = false;

    // The checkbox form control name is a concatenation of activity + symbol id's
    //
    symbolKeys.forEach((symbolKey) => {
      const splittedValue = symbolKey.split('-');
      const activityId = parseInt(get(splittedValue, '0'), 10);
      const programActivitySymbols = this.localActivitySymbols[activityId];
      const ctrl = this.form.controls[symbolKey];

      if (programActivitySymbols?.length > this.data.maxSymbols && !ctrl.hasError('tooMany')) {
        defer(() => {
          ctrl.markAsDirty();
          ctrl.markAsTouched();
          ctrl.setErrors({ tooMany: true });
        });
        errorsFound = true;
      } else if (programActivitySymbols?.length <= this.data.maxSymbols && ctrl.hasError('tooMany')) {
        ctrl.setErrors(null);
        ctrl.markAsPristine();
      }
    });

    if (errorsFound) {
      this.title = this.$translateService.instant('program_symbols_dialog.title.max_symbols', { maxSymbols: this.data.maxSymbols });
      this.subtitle = this.$translateService.instant('program_symbols_dialog.subtitle.max_symbols', { maxSymbols: this.data.maxSymbols });
    } else {
      this.title = this.data.title;
      this.subtitle = this.data.subtitle;
    }

    this.form.updateValueAndValidity();
    defer(() => {
      this.cssHackForErrorIndicator();
    });
  }

  /**
   * AssocheckMaxSymbolsiate a symbol with t period-activity
   */
  async saveSymbols() {
    try {
      const programActivities: IProgramSummaryPeriodSection[] = await firstValueFrom(this.programActivities$);
      const promises: Promise<IPeriodSection | void>[] = [];

      // Get the form value and obtain all the keys so we can loop through the list.
      //
      const symbolFormValue = this.form.value;
      const symbolKeys = keys(symbolFormValue);

      const updateSymbols: { [key: number]: string[] } = {};

      // The checkbox form control name is a concatenation of activity + symbol id's
      //
      symbolKeys.forEach((symbolKey) => {
        const splittedValue = symbolKey.split('-');
        const activityId = parseInt(get(splittedValue, '0'), 10);
        const symbolId = get(splittedValue, '1');
        const symbolIsChecked = get(symbolFormValue, symbolKey, false);
        const originalProgramActivitySymbols = (programActivities.find((programActivity) => programActivity.id === activityId)?.customer_symbol ?? []) as unknown as string[];
        const symbolWasActive = originalProgramActivitySymbols.includes(symbolId);

        // Check if the selection was changed
        //
        if (symbolIsChecked) {
          const symbolList = updateSymbols[activityId] ?? [];
          symbolList.push(symbolId);
          updateSymbols[activityId] = symbolList;
        } else if (!symbolIsChecked && symbolWasActive) {
          const symbolList = updateSymbols[activityId] ?? [];
          updateSymbols[activityId] = symbolList.filter((item) => item !== symbolId);
        }
      });

      // Now take those changes and turn them into update promises
      //
      const symbolIds = (await firstValueFrom(this.symbols$)).map((symbol) => `${symbol.id}`);
      keys(updateSymbols).forEach((updatedActivityIdKey) => {
        const updatedActivityId = parseInt(updatedActivityIdKey, 10);
        const programActivity = programActivities.find((activity) => activity.id === updatedActivityId);
        const periodOfActivity = this.data.periods.find((period) => !isNil((period.section ?? []).find((section) => section.id === updatedActivityId)));
        const sortedSymbols = updateSymbols[updatedActivityId].sort((symbolA, symbolB) => symbolIds.indexOf(symbolA) - symbolIds.indexOf(symbolB));
        programActivity.customer_symbol = sortedSymbols.map((symbol) => parseInt(symbol, 10));
        const converted = convertIProgramSummaryPeriodSectionToIPeriodSection(programActivity, periodOfActivity.id);

        promises.push(this.periodSectionService.update(converted));
      });

      await Promise.all(promises);

      // If no errors appeared, close the dialog.
      //
      this.dialogRef.close('confirm');
    } catch (e) {
      this.$i18nToastProvider.error(_('program_symbols_dialog.save.failed'));
    }
  }
}
