import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IPeriodSectionListResponse } from 'typings/api-activity';
import { IPeriodSection } from 'typings/doenkids/doenkids';
import { ProgramPeriodSectionStore } from './program-section.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramPeriodSectionService {
  constructor(private store: ProgramPeriodSectionStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAllByPeriodId(periodId: number, limit: number, skip: number, sortField: string = 'order', sortDirection: string = 'DESC', doNotPerist: boolean = false) {
    const fetchedSections = await firstValueFrom(this.$baseApi.genericListCall(
      '/activity/period-section', limit, skip, sortField, sortDirection, { periodId },
    )) as IPeriodSectionListResponse;

    if (!doNotPerist && fetchedSections) {
      this.store.set(fetchedSections.items);
      this.store.setMetadata({
        total: fetchedSections.total,
        limit: fetchedSections.limit,
        skip: fetchedSections.skip,
      });
    }

    return fetchedSections;
  }

  async fetchAndConcatByPeriodId(periodIds: number[]) {
    const promises: Promise<IPeriodSectionListResponse>[] = [];

    for (const periodId of periodIds) {
      const promise = firstValueFrom(this.$baseApi.genericListCall('/activity/period-section', 100, 0, 'order', 'ASC', { periodId })) as Promise<IPeriodSectionListResponse>;
      promises.push(promise);
    }

    const results = await Promise.all(promises);
    const programPeriodSections: IPeriodSection[] = [];

    // Loop over results
    //
    for (const result of results) {
      // Set the list and add it to the final list of results.
      //
      const list = result?.items;
      if (list) {
        programPeriodSections.push(...list);
      }
    }

    this.store.set(programPeriodSections);
    return programPeriodSections;
  }

  async fetch(sectionId: number) {
    const fetchedSection = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/period-section/${sectionId}`)) as IPeriodSection;

    return fetchedSection;
  }

  async create(section: IPeriodSection, organisationUnitId: number) {
    let url = '/activity/period-section';

    url = this.$baseApi.appendQueryParam(url, 'organizationUnitId', organisationUnitId);

    const newSection = await firstValueFrom(this.$baseApi.genericPostCall(url, section)) as IPeriodSection;
    this.store.upsert(newSection.id, newSection);
    return newSection;
  }

  async update(section: IPeriodSection) {
    const updatedSection = await firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/period-section/${section.id}`, section,
    )) as IPeriodSection;

    if (updatedSection) {
      this.store.upsert(updatedSection.id, updatedSection);
    }

    return updatedSection;
  }

  async archive(sectionId: number) {
    const response = await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/period-section/${sectionId}`));
    this.store.remove(sectionId);
    return response;
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
