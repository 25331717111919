import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitProgramTemplateService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  update(organizationUnitId: number, programTemplateId: number, organizationUnitRoleId: number) {
    // eslint-disable-next-line max-len
    return this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/program-template/${programTemplateId}?organizationUnitRoleId=${organizationUnitRoleId}`);
  }

  archive(organizationUnitId: number, programTemplateId: number) {
    return this.$baseApi.genericDeleteCall(`/customer/organization-unit/${organizationUnitId}/program-template/${programTemplateId}`);
  }
}
