import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IRenderSettingOverrides, IRenderSettings } from 'typings/api-publish';
import { IOrganizationUnitRenderSettings } from 'typings/doenkids/doenkids';

export interface IExtendedOrganizationUnitRenderSettings extends IOrganizationUnitRenderSettings {
  settings: IRenderSettings;
  overrides?: IRenderSettingOverrides;
  draft_settings?: IRenderSettings;
  draft_overrides?: IRenderSettingOverrides;
}

@Injectable({ providedIn: 'root' })
export class OrganizationUnitRenderSettingsService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  fetch(organizationUnitId: number) {
    return firstValueFrom(this.$baseApi.genericGetCallWithoutErrorHandler(`/customer/organization-unit/${organizationUnitId}/render-settings`)) as Promise<IExtendedOrganizationUnitRenderSettings>;
  }

  update(organizationUnitId: number, settings: IExtendedOrganizationUnitRenderSettings) {
    // eslint-disable-next-line max-len
    return firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/render-settings`, settings)) as Promise<IExtendedOrganizationUnitRenderSettings>;
  }
}
