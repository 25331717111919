import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { firstValueFrom } from 'rxjs';
import { COUNTRY_CODE_TO_LANGUAGE, TranslateService } from 'src/app/utils/translate.service';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IServiceResponse } from 'typings/api-generic';
import { IPublishActivityRequest } from 'typings/api-publish';

export interface IActivityHTMLResponse {
  header?: string;
  footer?: string;
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class PublishActivityService {

  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private $translate: TranslateService,
  ) {}

  async getTranslationsForActivity(countryCode: string) {
    let mappedCountryCode = COUNTRY_CODE_TO_LANGUAGE[countryCode.toLowerCase()];
    if (!mappedCountryCode) {
      mappedCountryCode = COUNTRY_CODE_TO_LANGUAGE.nl;
    }
    const translations = await firstValueFrom(this.$translate.getTranslation(mappedCountryCode));
    return {
      'activity.labels.duration': translations['activity.pdf.duration'],
      'activity.labels.preparation': translations['activity.pdf.preparation'],
      'activity.labels.area_of_development': translations['activity.pdf.area_of_development'],
      'activity.labels.range_of_development': translations['activity.pdf.range_of_development'],
      'activity.labels.age_group': translations['activity.pdf.age_group'],
      'activity.labels.location': translations['activity.pdf.location'],
      'activity.labels.activity_kind': translations['activity.pdf.activity_kind'],
      'activity.labels.group_size': translations['activity.pdf.group_size'],
      'activity.duration_minutes': translations['generic.minutes'],
      'activity.duration_minute': translations['generic.minute'],
    };
  }

  async fetchActivityPdf(publishActivityRequest: IPublishActivityRequest, activityCountryCode: string = 'NL', queryParams?: { filename?: string, debug?: boolean, forceUpdate?: boolean }) {
    try {
      let url = '/publish/activity/pdf';
      url = this.$baseApi.appendQueryParam(url, 'filename', queryParams?.filename);
      url = this.$baseApi.appendQueryParam(url, 'debug', queryParams?.debug);
      url = this.$baseApi.appendQueryParam(url, 'forceUpdate', queryParams?.forceUpdate);

      const translations = await this.getTranslationsForActivity(activityCountryCode);
      publishActivityRequest.translations = translations;
      publishActivityRequest.locale = this.$translate.currentLang;

      const fetchedActivityPdfBuffer = await firstValueFrom(this.$baseApi.uploadImage(url, publishActivityRequest)) as Blob;

      return fetchedActivityPdfBuffer;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getActivityPdfUrl(publishActivityRequest: IPublishActivityRequest, activityCountryCode: string = 'NL') {
    try {
      const translations = await this.getTranslationsForActivity(activityCountryCode);
      publishActivityRequest.translations = translations;
      publishActivityRequest.locale = this.$translate.currentLang;

      const fetchedProgramPdfUrl = await firstValueFrom(this.$baseApi.genericPostCallWithoutErrorHandler('/publish/activity/pdf-url', publishActivityRequest)) as IServiceResponse;

      return fetchedProgramPdfUrl.url;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  downloadPdfUrl(url: string, filename: string) {
    const link = window.document.createElement('a');
    link.download = filename;
    link.href = url;
    link.target = '_blank';
    link.click();
  }
}
