import {
  Component, OnInit, Inject, ViewEncapsulation, OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNil } from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

interface ICopyProgramDialogFields {
  name: string;
  to: string;
  from: string;
}

export interface IPossibleCopyProgramLocation {
  id: number;
  name: string;
}

export interface ICopyProgramDialogData extends ICopyProgramDialogFields {
  locations: IPossibleCopyProgramLocation[];
}

export interface ICopyProgramDialogResult extends ICopyProgramDialogFields {
  locations: IPossibleCopyProgramLocation[];
}

@Component({
  selector: 'app-copy-program-dialog',
  templateUrl: './copy-program-dialog.component.html',
  styleUrls: ['./copy-program-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CopyProgramDialogComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;

  private stop$ = new Subject();

  constructor(
    fb: UntypedFormBuilder,
    private $i18nToastProvider: I18nToastProvider,
    public dialogRef: MatDialogRef<CopyProgramDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICopyProgramDialogData,
  ) {
    this.form = fb.group({
      name: ['', Validators.required],
      to: ['', Validators.required],
      from: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  ngOnInit() {
    if (isNil(this.data)) {
      console.warn('No data passed to dialog, returning.');
      this.dialogRef.close();
      return;
    }

    this.form.patchValue({
      name: this.data.name,
      to: this.data.to,
      from: this.data.from,
    });

    const fromCtrl = this.form.get('from');
    const toCtrl = this.form.get('to');

    fromCtrl.valueChanges.pipe(
      takeUntil(this.stop$),
    ).subscribe((dateString) => {
      let from = dayjs.tz(dateString);
      from = from.set('hours', 8).set('minutes', 30);
      fromCtrl.setValue(from.toDate().toISOString(), { onlySelf: true, emitEvent: false });
    });

    toCtrl.valueChanges.pipe(
      takeUntil(this.stop$),
    ).subscribe((dateString) => {
      let to = dayjs.tz(dateString);
      to = to.set('hours', 18);
      toCtrl.setValue(to.toDate().toISOString(), { onlySelf: true, emitEvent: false });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirm() {
    if (!this.form.valid) {
      console.error('copy dialog form error', this.form.errors);
      this.$i18nToastProvider.error(_('dialog.copy_program.invalid_form'));
    }

    const formValue = this.form.value;

    const result: ICopyProgramDialogResult = {
      locations: this.data.locations,
      name: formValue.name,
      to: formValue.to,
      from: formValue.from,
    };

    this.dialogRef.close(result);
  }
}
