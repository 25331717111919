import { ProgramStatusService } from 'src/api/activity/program-status/program-status.service';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { DoenkidsSessionProvider, IDoenKidsSession } from 'src/providers/session.provider';
import { ProgramListService } from 'src/api/activity/program-list/program-list.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProgramStatusQuery } from 'src/api/activity/program-status/program-status.query';
import { Observable, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-insights-program-status-program-list',
  templateUrl: './insights-program-status-program-list.component.html',
  styleUrls: ['./insights-program-status-program-list.component.scss'],
})
export class InsightsProgramStatusProgramListComponent implements OnInit {
  public ouId: number;

  public session$: Observable<IDoenKidsSession>;

  public currentPage = 0;

  public MAX_ITEMS_PER_PAGE = 10;

  public ouName: string;

  public programStatusName;

  public programType: number;

  public list: any;

  public backUrl: string;

  constructor(
    private route: ActivatedRoute,
    private $program: ProgramListService,
    private router: Router,
    private $doenkidsSession: DoenkidsSessionProvider,
    private $organizationUnit: OrganizationUnitService,
    private $programStatus: ProgramStatusService,
    private $programStatusQuery: ProgramStatusQuery,
  ) {
    this.session$ = this.$doenkidsSession.getSession$.asObservable();
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    this.ouId = +params.ou;
    this.programType = +params.type;

    const { ownerOu, from } = await firstValueFrom(this.route.queryParams);

    this.backUrl = `organization/${this.ouId}/insights/${from}`;

    const result = (await this.$program.fetchAll({
      organizationUnitId: ownerOu,
      statusId: this.programType,
      limit: 200,
    })) as any;

    this.list = result.items;

    const organizationUnit = await this.$organizationUnit.fetch(ownerOu);
    this.ouName = organizationUnit.name;

    await this.$programStatus.fetchAll();
    const status = await firstValueFrom(this.$programStatusQuery.selectAll());

    this.programStatusName = status
      .find((s) => s.id === this.programType)
      .name.toLowerCase();
  }

  paginate(page: number) {
    this.currentPage = page;
  }

  navigate(programId: number): void {
    this.router.navigate([`organization/${this.ouId}/program/${programId}`], {
      queryParams: { origin: 'insights' },
    });
  }
}
