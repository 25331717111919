import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isNil } from '@datorama/akita';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { firstValueFrom } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

export async function checkAccessToOrganization(route: ActivatedRouteSnapshot, $session: DoenkidsSessionProvider, organizationUnitService: OrganizationUnitService) {
  const { params } = route;

  function sorryNoAccessAllowed() {
    const router = inject(Router);
    const $i18nToastProvider = inject(I18nToastProvider);

    $i18nToastProvider.error(_('has_access_to_organization.unauthorized'));
    router.navigate(['']);
  }

  // If an ou is provided in the params we need to follow
  // a few checks. We start by looking it that OU is the
  // currently selected one
  //
  if (params.ou) {
    const organizationUnitId = parseInt(params.ou, 10);
    const organizationUnitFromSession = await firstValueFrom($session.getOrganizationUnit$.pipe(
      filter((value) => !isNil(value)),
    ));

    if (organizationUnitFromSession.id !== organizationUnitId) {
      // Oeps, the OU ID from the URL is not the same as the one selected
      // Well than we have to continue checking if this user has access
      // to this organization, oh and if it exists at all ...
      //

      try {
        const organization = await organizationUnitService.fetch(organizationUnitId);
        if (isNil(organization)) {
          sorryNoAccessAllowed();
          return false;
        }

        const hasWritePermissionForOu = await $session.hasWritePermissionForOU(organization.id);

        if (!hasWritePermissionForOu) {
          sorryNoAccessAllowed();
          return false;
        }
        // We need to switch the organization to this organization
        //
        $session.selectOrganizationUnit(organization.id);
      } catch (error) {
        console.error('[DOENKIDS-AUTH-GUARD]: The user has no access to this organization');
        sorryNoAccessAllowed();
        return false;
      }
    }
  }
  return true;
}

// eslint-disable-next-line max-len
export const hasAccessToOrganizationGuard = async (route: ActivatedRouteSnapshot) => {
  const $session = inject(DoenkidsSessionProvider);
  const organizationUnitService = inject(OrganizationUnitService);

  return checkAccessToOrganization(route, $session, organizationUnitService);
};
