import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IUserActive } from 'typings/doenkids/doenkids';

export interface IUserFetchAllActiveState extends EntityState<IUserActive> {
  limit: number;
  skip: number;
  total: number;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'user-fetch-all-active',
  idKey: 'id',
})
export class UserFetchAllActiveStore extends EntityStore<IUserFetchAllActiveState> {
  constructor() {
    super([]);
  }

  setMetadata({ total, skip, limit }) {
    this.update({ total, skip, limit });
  }
}
