import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnitProgramRoles } from 'typings/api-activity';

export interface IProgramOrganizationUnitState extends EntityState<IOrganizationUnitProgramRoles> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-organization-unit', idKey: 'organization_unit_id' })
export class ProgramOrganizationUnitStore extends EntityStore<IProgramOrganizationUnitState, IOrganizationUnitProgramRoles> {
  constructor() {
    super([]);
  }
}
