<app-doenkids-menu-bar [showBackButton]="true" [loading]="( activityLoading$ | async ) || (forking$ | async)">
  <div fxLayout="row" fxLayoutAlign="start center" left>
    <ng-container *ngIf="!(isReader$ | async) && !(approveIsTranslate$ | async)">
      <button mat-icon-button (click)="approveActivity()" [matTooltip]="'activity.preview.approve.tooltip' | translate">
        <mat-icon >done</mat-icon>
      </button>
      <button
        *ngIf="(hasOUWritePermission$ | async) && (activityDetails$ | async)?.replace_activity_id === null"
        mat-icon-button
        type="button"
        (click)="forkActivity()"
        [matTooltip]="'activity.preview.copy.create.tooltip' | translate"
      >
        <mat-icon class="icon-btn">call_split</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="!(isReader$ | async) && (approveIsTranslate$ | async)">
      <button mat-icon-button (click)="forkActivity()" [matTooltip]="'activity.preview.translate.tooltip' | translate">
        <mat-icon>translate</mat-icon>
      </button>
    </ng-container>
  </div>
  <div right>
    <button
      *ngIf="
        !(isReader$ | async) &&
        ( activityDetails$ | async )?.replace_activity_id === null &&
        !(isRevokedInNodePath$ | async)
      "
      mat-icon-button
      type="button"
      (click)="excludeActivity()"
      [matTooltip]="'activity.exclude.tooltip' | translate"
    >
      <mat-icon>remove_circle</mat-icon>
    </button>
  </div>
</app-doenkids-menu-bar>
<app-split-page
  [activeTab]="0"
  [leftLabel]="'activity.preview.tab.details' | translate"
  [rightLabel]="'activity.preview.tab.preview' | translate"
  (selectedTabChange)="renderPdf()"
>
  <ng-template #left>
    <div class="sections-container">
      <div *ngIf="(activityDetails$ | async) as activity" class="details">
        <div class="main-details" fxLayout="column" fxLayout.gt-md="row-reverse"
          fxLayoutAlign="space-between space-between">
          <app-doenkids-image [maxHeight]="150" [maxWidth]="150" [mediaItem]="( activityDetails$ | async )?.media_uuid">
          </app-doenkids-image>
          <div class="title" fxLayout="column">
            <h1 [style.color]="headingColor">
              {{ activity.name !== '' ? activity.name : ('generic.new' | translate) }}
            </h1>
            <h2 [style.color]="subheadingColor">{{ activity.subtitle }}</h2>
            <p class="summary" *ngIf="activity.summary"><i>{{ activity.summary }}</i></p>
          </div>
        </div>
      </div>
      <div *ngFor="let section of ( mediaSections$ | async )">
        <h2 *ngIf="section.data?.title" [style.color]="headingColor">{{ section.data?.title }}</h2>
        <h2 *ngIf="section.data?.subtitle" [style.color]="subheadingColor">{{ section.data?.subtitle }}</h2>

        <!-- Videos -->
        <ng-container *ngFor="let safeUrl of section.data?.video">
          <div class="video-cards animated fadeIn" *ngIf="safeUrl && safeUrl.length > 1">
            <iframe *ngIf="safeUrl" [src]="safeUrl | safe: 'resourceUrl'"></iframe>
          </div>
        </ng-container>
      </div>
      <div *ngFor="let section of ( notMediaSections$ | async )" [ngSwitch]="section.type_id">
        <h2 *ngIf="section.data?.title" [style.color]="headingColor">{{ section.data?.title }}</h2>
        <h2 *ngIf="section.data?.subtitle" [style.color]="subheadingColor">{{ section.data?.subtitle }}</h2>
        <ng-container *ngSwitchDefault>
          <div *ngIf="section.data?.content" [innerHTML]="section.data?.content" class="ql-editor"></div>
        </ng-container>

        <ng-container *ngSwitchCase="ESectionType.GLOSSARY">
          {{ section.data?.terms }}
          <div *ngFor="let term of section.data?.term">
            <b>{{ term.name }}</b><span>{{ term.description }}</span>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="ESectionType.SOURCES">
          <div *ngFor="let source of section.data?.source">
            <a [href]="source" target="_blank">{{ source }}</a>
          </div>
        </ng-container>

        <div *ngSwitchCase="ESectionType.MEDIA" class="media-container">
          <ng-container *ngFor="let media of section.data?.media">
            <ng-container *ngIf="media?.uuid">
              <mat-card appearance="outlined" class="media-item">
                <app-doenkids-image [maxHeight]="150" [maxWidth]="150" [mediaItem]="media?.uuid" class="media-image">
                </app-doenkids-image>
                <br />
                <i>{{ media.description }}</i>
              </mat-card>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <!-- Attatchements in metaData card-->
      <div class="attachments">
        <h2 *ngIf="( activityAttachments$ | async ).length > 0">{{ 'generic.attachments' |translate }}</h2>
        <div class="attachment-container">
          <div *ngFor="let attachment of activityAttachments$ | async" class="attachment">
            <div class="attachment-content-container">
              <mat-icon class="attachment-icon">insert_drive_file</mat-icon>
              <a class="attachment-link" (click)="openAttachment(attachment)">
                <span>{{ attachment['filename'] }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #right>
    <div class="pdf-container">
      <div class="spinner-container" *ngIf="pdfLoading">
        <mat-spinner></mat-spinner>
      </div>
      <ng2-pdfjs-viewer (onDocumentLoad)="onPdfLoadedEvent()" [viewerId]="(activityDetails$ | async)?.id?.toString()" [attr.visible]="!pdfLoading" #pdfViewer></ng2-pdfjs-viewer>
    </div>
  </ng-template>

</app-split-page>
