import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate_array',
})
export class TruncateArrayPipe implements PipeTransform {
  transform(value: any[], limit = Infinity) {
    return value ? value.slice(0, limit) : [];
  }
}
