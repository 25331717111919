<form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'required_material_section.title.label' | translate }}</mat-label>
      <input type="text" formControlName="title" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'required_material_section.subtitle.label' | translate }}</mat-label>
      <input type="text" formControlName="subtitle" matInput>
    </mat-form-field>
  </div>
  <div formArrayName="material" class="materials" #materialArray>
    <div *ngFor="let control of materialFormArray.controls; let index = index" [formGroupName]="index">
      <div class="material-container">
        <mat-form-field appearance="outline" class="section-form-field w-100" (keyup.enter)="materialFormArray?.removeAt(index)">
          <mat-label>{{ 'required_material_section.material.label' | translate }}</mat-label>
          <input type="text" formControlName="name" matInput>
          <button
            *ngIf="!form.disabled"
            type="button"
            matSuffix
            mat-icon-button
            [attr.aria-label]="'required_material_section.material.remove.label' | translate"
            (click)="materialFormArray?.removeAt(index);"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
