import { PinbordProvider, PINBORD_FOLDER_LIMIT } from 'src/providers/pinbord.provider';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { map, takeUntil,
} from 'rxjs/operators';
import {
  AddNewPinbordFolderDialogComponent,
} from 'src/components/dialogs/add-new-pinbord-folder-dialog/add-new-pinbord-folder-dialog.component';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IFavoriteFolderDetails } from 'typings/doenkids/doenkids';
import { IStoreFavoriteFolderDetails } from 'src/api/activity/favorite-folder/favorite-folder.store';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { EOrganizationUnitType } from 'src/components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

@Component({
  selector: 'app-pinbord',
  templateUrl: './pinbord.component.html',
  styleUrls: ['./pinbord.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PinbordComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public currentFolders$: Observable<IStoreFavoriteFolderDetails>;

  public currentPage = 1;

  MAX_ITEMS_PER_PAGE = 24;

  /**
   * We display the name in the template
   */
  public currentOuName$: Observable<string>;

  private currentOuId$: Observable<number>;

  public isReader$: Observable<boolean>;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    $pinbord: PinbordProvider,
    private $session: DoenkidsSessionProvider,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.currentFolders$ = $pinbord.currentFolders$.asObservable();

    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));

    this.currentOuName$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
      map((ou) => ou.name),
    );

    this.currentOuId$ = this.$session.currentOuId$.pipe(
      takeUntil(this.stop$),
    );
  }

  ngOnInit() {
    // When a new organization is rewrite the current url
    //
    this.$session.getOrganizationUnitSwitch$.pipe(takeUntil(this.stop$)).subscribe((organizationUnit) => {
      if (parseInt(`${organizationUnit.organization_unit_type_id}`, 10) !== EOrganizationUnitType.LOCATION) {
        this.router.navigate(['/'], { replaceUrl: true });
      } else {
        this.router.navigate([`/organization/${organizationUnit.id}/pinbord`], { replaceUrl: true });
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async goToFolder(folder: IFavoriteFolderDetails) {
    const ouId = await firstValueFrom(this.currentOuId$);
    this.router.navigate([`/organization/${ouId}/pinbord/${folder.id}`]);
  }

  async addNewFolder() {
    const currentFolders = await firstValueFrom(this.currentFolders$);
    if (currentFolders?.totalNoOfFolders >= PINBORD_FOLDER_LIMIT) {
      this.$i18nToastProvider.error(_('pinboard.limit.reached'), { limit: PINBORD_FOLDER_LIMIT });
      return;
    }
    this.dialog.open(AddNewPinbordFolderDialogComponent, {});
  }

  paginate(page: number) {
    this.currentPage = page;
  }
}
