import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

export interface IPeriodForm {
  name: string;
  subtitle: string;
  summary: string;
  start_date: string;
  end_date: string;
}

@Component({
  selector: 'app-program-period',
  templateUrl: './program-period.component.html',
  styleUrls: ['./program-period.component.scss'],
})
export class ProgramPeriodComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() period: UntypedFormGroup;

  @Input() program: UntypedFormGroup;

  @Output() uploaded = new EventEmitter();

  @Output() selected = new EventEmitter();

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  mediaUploaded(mediaUuid) {
    this.uploaded.emit(mediaUuid);
  }

  mediaSelected(mediaUuid) {
    this.selected.emit(mediaUuid);
  }
}
