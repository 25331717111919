import { Injectable } from '@angular/core';

interface ISavedScrollPosition {
  [pageName: string]: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollPosition: ISavedScrollPosition = {};

  setScroll(pageName: string, position?: number): void {
    this.scrollPosition[pageName] = position;
  }

  getScroll(pageName: string): number {
    return this.scrollPosition[pageName];
  }

  applySavedScroll(pageName: string, element?: HTMLElement) {
    if (!element) {
      return;
    }

    const savedScrollPosition = this.getScroll(pageName);
    if (savedScrollPosition) {
      element.scrollTop = savedScrollPosition;
      this.setScroll(pageName, undefined);
    }
  }
}
