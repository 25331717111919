import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location as ALocation } from '@angular/common';
import { PermissionProvider } from 'src/providers/permission.provider';
import { filter } from 'rxjs/operators';
import { isNil } from '@datorama/akita';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { firstValueFrom } from 'rxjs';

export const canEditActivityGuard = async () => {
  const location = inject(ALocation);
  const $permission = inject(PermissionProvider);
  const router = inject(Router);
  const $session = inject(DoenkidsSessionProvider);

  const organizationUnitId = await firstValueFrom($session.currentOuId$);
  const currentUserPermissions = await firstValueFrom($session.userPermissions$.pipe(filter((value) => !isNil(value))));
  const isAdmin = await firstValueFrom($session.isAdmin$.pipe(filter((value) => !isNil(value))));

  const hasWritePermissions = $permission.checkOUWritePermission(organizationUnitId, currentUserPermissions, isAdmin);

  if (!hasWritePermissions && router.navigated) {
    location.back();
  } else if (!hasWritePermissions) {
    router.navigate(['/']);
  }

  return hasWritePermissions;
};
