import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '../app/utils/translate.service';

@Pipe({
  name: 'i18n_date',
})
export class I18nDatePipe implements PipeTransform {
  constructor(private $translate: TranslateService) {
  }

  transform(value: string | Date, format: string = 'mediumDate', timezone?: string): Observable<string> {
    if (value) {
      return this.$translate.onInitialLangAndLangChange$
        .pipe(map((event) => formatDate(value, format, event.lang, timezone)));
    }
    return of('');
  }
}
