import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ISearchActivity } from 'typings/doenkids/doenkids';
import { IActivitySearchState, ActivitySearchStore } from './activity-search.store';
import { Observable } from 'rxjs';

export interface IActivitySearchMetaData {
  total: number;
  skip: number;
  limit: number;
  query: string;
  aggregations: any;
  took: any;
  suggest: any;
}

@Injectable({ providedIn: 'root' })
export class ActivitySearchQuery extends QueryEntity<IActivitySearchState, ISearchActivity> {
  constructor(protected store: ActivitySearchStore) {
    super(store);
  }

  get getAggregations() {
    return this.select((value) => value.aggregations);
  }

  get getSuggestions() {
    return this.select((value) => value.suggest);
  }

  get getMetadata(): Observable<IActivitySearchMetaData> {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
      query: value.query,
      aggregations: value.aggregations,
      took: value.took,
      suggest: value.suggest,
    }));
  }
}
