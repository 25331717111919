import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { IUserDetails, ISearchUserListResponse, ISearchUserListRequest, IUserExportListRequest } from 'typings/api-customer';
import { IUser } from 'typings/doenkids/doenkids';
import { IServiceResponse } from 'typings/api-generic';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { OrganizationUnitUsersListStore } from './organization-unit-users-list.store';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitUsersListService {
  constructor(
    private store: OrganizationUnitUsersListStore,
    private $baseApi: DoenKidsGenericApiProvider,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
  ) { }

  async fetchAll(organizationUnitId: number, requestBody: ISearchUserListRequest): Promise<IUserDetails[]> {
    const response = await firstValueFrom(this.$baseApi
      .genericPostCall(`/customer/organization-unit/${organizationUnitId}/user`, requestBody)) as ISearchUserListResponse;
    this.store.set(response?.items ?? []);
    this.store.update({
      limit: response?.limit,
      total: response?.total,
      skip: response?.skip,
    });
    return response?.items ?? [];
  }

  async fetch(userId: number) {
    const user = await firstValueFrom(this.$baseApi.genericGetCall(`/customer/user/${userId}`)) as IUserDetails;
    this.store.remove(user.id);
    this.store.upsert(userId, user);
    return Promise.resolve(user);
  }

  sendPasswordResetEmail(user: IUser | IUserDetails) {
    let url = `/customer/user/${user.id}/auth0_password_email`;

    url = this.$baseApi.appendQueryParam(url, 'client_id', environment.auth0Tennant.clientId);

    return firstValueFrom(this.$baseApi.genericPostCall(url, {})) as Promise<IServiceResponse>;
  }

  sendVerificationEmail(user: IUser | IUserDetails) {
    let url = `/customer/user/${user.id}/auth0_email_verify`;

    url = this.$baseApi.appendQueryParam(url, 'client_id', environment.auth0Tennant.clientId);

    return firstValueFrom(this.$baseApi.genericPostCall(url, {})) as Promise<IServiceResponse>;
  }

  blockUser(user: IUser | IUserDetails) {
    return firstValueFrom(this.$baseApi.genericPostCall(`/customer/user/${user.id}/block`, {})) as Promise<IServiceResponse>;
  }

  unblockUser(user: IUser | IUserDetails) {
    return firstValueFrom(this.$baseApi.genericPostCall(`/customer/user/${user.id}/unblock`, {})) as Promise<IServiceResponse>;
  }

  auth0EmailSet(userId: number) {
    return firstValueFrom(this.$baseApi.genericPutCall(`/customer/user/${userId}/auth0_email`, undefined));
  }

  async create(user: IUser): Promise<IUserDetails> {
    let url = '/customer/user';

    url = this.$baseApi.appendQueryParam(url, 'client_id', environment.auth0Tennant.clientId);

    const newUser = await firstValueFrom(this.$baseApi.genericPostCall(url, user)) as IUserDetails;

    if (newUser) {
      this.store.upsert(newUser.id, newUser);
    }

    return newUser;
  }

  async downloadExcelList(organizationUnitId: number, requestBody: IUserExportListRequest) {
    let url = `${this.$baseApi.API_END_POINT}/customer/organization-unit/${organizationUnitId}/user/export`;
    const headers = this.$baseApi.getAuthorizationHeader();

    const fullFileName = requestBody.filename.endsWith('.xlsx') ? requestBody.filename : `${requestBody.filename}.xlsx`;
    requestBody.filename = fullFileName;

    this.$doenkidsFileDownloaderService.addDownload({ name: fullFileName, url, headers, method: 'POST', requestBody });
  }
}
