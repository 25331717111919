import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import Auth0Lock from 'auth0-lock';
import { RedirectProvider } from './redirect.provider';
import { environment } from '../environments/environment';
import { isNil } from 'lodash';

@Injectable()
export class DoenKidsAuth0Provider {
  public currentSession$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private init: any = {};

  private lock: Auth0Lock;

  private options = {
    oidcConformant: true,
    configurationBaseUrl: environment.auth0Tennant.baseUrl,
    auth: {
      params: {
        scope: 'openid profile user_id email',
      },
      responseType: 'token',
      audience: environment.auth0Tennant.audience,
      redirectUrl: [document.location.protocol, '//', document.location.host, '/'].join(''),
    },
    closable: false,
    container: 'auth0-container',
    autofocus: true,
    rememberLastLogin: true,
    language: environment.defaultLanguage,
    languageDictionary: {
      title: environment.auth0Tennant.title,
    },
    displayName: environment.auth0Tennant.title,
    theme: {
      foregroundColor: environment.auth0Tennant.themeColor,
      logo: environment.auth0Tennant.logoUrl,
      primaryColor: environment.auth0Tennant.themeColor,
    },
  };

  private ACCESS_TOKEN_STORAGE_KEY = 'accessToken';

  private PROFILE_STORAGE_KEY = 'profile';

  private _accessToken$ = new BehaviorSubject<string | null>(null);

  public accessToken$: Observable<string>;

  constructor(private router: Router, private $redirect: RedirectProvider) {
    this.init = {};
    this.init.promise = new Promise((resolve, reject) => {
      this.init.resolve = resolve;
      this.init.reject = reject;
    });

    this.initializeLockForLanguage(environment.defaultLanguage);

    // Already an profile or are we not handling an callback with an access token from Auth0? Resolve now.
    // When this is not the case, assume we either show the lock and have the user login himself,
    // or the access token is being processed resulting in either a authenticated event or an authorization error event
    //
    if (localStorage.getItem(this.PROFILE_STORAGE_KEY) || !document.location.hash || !document.location.hash.match(/access_token=/)) {
      this.init.resolve();

      this.accessToken$ = this._accessToken$.pipe(
        filter((token) => !isNil(token)),
      );

      const storedToken = localStorage.getItem(this.ACCESS_TOKEN_STORAGE_KEY);

      if (storedToken) {
        this._accessToken$.next(storedToken);
      }
    }
  }

  initializeLockForLanguage(lang: string) {
    const language = lang.split('-')[0];
    if (this.lock && language === this.options.language) {
      return;
    }
    if (this.lock) {
      this.lock.hide();
    }

    this.options = { ...this.options, language };

    // Set the SpringTree credentials
    //
    this.lock = new Auth0Lock(
      environment.auth0Tennant.clientId,
      environment.auth0Tennant.domain,
      this.options,
    );

    this.lock.on('show', () => {
      console.log('[SERVICE:AUTH0] Showing lock');
    });

    this.lock.on('hide', () => {
      console.log('[SERVICE:AUTH0] Hiding lock');
    });

    this.lock.on('authorization_error', (error) => {
      console.error('[AUTH0] Auth error', error);
      // Resolve initialization when there was an error
      //
      this.init.resolve();
    });

    this.lock.on('unrecoverable_error', (error) => {
      console.error('[AUTH0] Unrecoverable error', error);
    });

    // Listening for the authenticated event
    //
    this.lock.on('authenticated', (authResult) => {
      // Use the token in authResult to getUserInfo() and save it to localStorage
      //
      console.log('[AUTH0]: result', authResult);
      this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
        console.log('[AUTH0]: user information', profile, error);
        if (error) {
          console.error('[SERVICE:AUTH0] Problem getting user info', error);
          // Handle error
          // **TODO**
          //
          return;
        }

        localStorage.setItem(this.ACCESS_TOKEN_STORAGE_KEY, authResult.accessToken);
        localStorage.setItem(this.PROFILE_STORAGE_KEY, JSON.stringify(profile));
        this._accessToken$.next(authResult.accessToken);
        this.currentSession$.next(authResult);
        this.lock.hide();

        const urlToRedirectTo = this.$redirect.getPathToRedirectTo();
        this.$redirect.clearPathToRedirectTo();

        // Resolve initialization so the route guard can continue
        //
        this.init.resolve();
        this.router.navigate([urlToRedirectTo]);
      });
    });
  }

  // Show lock popup
  //
  show(lang: string) {
    this.initializeLockForLanguage(lang);

    // these 2 if statements are for when the users session is expired and they have to login again
    // if the profile or the accesstoken is still present remove it as it gives issues on firefox that
    // the user keeps getting returned to the login page
    //
    if (localStorage.getItem(this.PROFILE_STORAGE_KEY)) {
      localStorage.removeItem(this.PROFILE_STORAGE_KEY);
    }
    if (localStorage.getItem(this.ACCESS_TOKEN_STORAGE_KEY)) {
      localStorage.removeItem(this.ACCESS_TOKEN_STORAGE_KEY);
      this._accessToken$.next(null);
    }
    this.lock.show();
  }

  // Verify that there's a token in localStorage
  //
  token() {
    return this._accessToken$.value;
  }

  // Logout the current user
  //
  logout() {
    localStorage.removeItem(this.ACCESS_TOKEN_STORAGE_KEY);
    this._accessToken$.next(null);
    localStorage.removeItem(this.PROFILE_STORAGE_KEY);
    this.currentSession$.next(undefined);
    this.lock.logout({
      returnTo: `${document.location.protocol}//${document.location.hostname}:${document.location.port}`,
    });
  }

  // Return profile, but wait until we have initialized
  //
  profile() {
    return new Promise<null | any>((resolve, reject) => {
      this.init.promise
        .then(() => {
          const rawProfile = localStorage.getItem(this.PROFILE_STORAGE_KEY);
          if (rawProfile) {
            resolve(JSON.parse(rawProfile));
            this.currentSession$.next(rawProfile);
          } else {
            resolve(null);
          }
        })
        .catch(reject);
    });
  }
}
