import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OpendataLrkSearchStore, IOpendataLrkState } from './opendata-lrk.store';

@Injectable({ providedIn: 'root' })
export class OpendataLrkSearchQuery extends QueryEntity<IOpendataLrkState, any> {
  constructor(protected store: OpendataLrkSearchStore) {
    super(store);
  }

  get getAggregations() {
    return this.select((value) => value.aggregations);
  }

  get getSuggestions() {
    return this.select((value) => value.suggest);
  }

  get getMetadata() {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
      query: value.query,
      aggregations: value.aggregations,
      took: value.took,
      suggest: value.suggest,
    }));
  }
}
