import { slideUpDown, fadeIn } from 'src/animations';
import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [fadeIn, slideUpDown],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
  showDurationDisclaimer$ = of(true).pipe(
    delay(2500),
  );

  @HostBinding('@fadeIn') get fadeIn() {
    return true;
  }
}
