<app-doenkids-menu-bar
  [showBackButton]="true"
  [backUrl]="backUrl"
></app-doenkids-menu-bar>

<mat-toolbar class="doenkids-toolbar">
  <h2>
    {{ 'insights_program_status_program_list.title' | translate: {
      organizationUnitName: ouName,
      status: ('program.status.' + programStatusName) | translate
    } }}
  </h2>
</mat-toolbar>

<mat-card appearance="outlined">
  <mat-list *ngIf="list">
    <mat-list-item *ngFor="let item of list | paginate: {
      id: 'insightsProgramStatusList',
      itemsPerPage: MAX_ITEMS_PER_PAGE,
      currentPage: currentPage,
      totalItems: list.length
    }" (click)="navigate(item.id)">
      <mat-icon matListItemIcon>calendar_today</mat-icon>
      <div matListItemTitle> {{ item.name }}</div>
      <div matListItemLine> {{ item.from | i18n_date | async }} - {{ item.to | i18n_date | async }} </div>
    </mat-list-item>
  </mat-list>
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="list && list.length > MAX_ITEMS_PER_PAGE">
    <pagination-controls
      id="insightsProgramStatusList"
      [nextLabel]="'generic.pagination.next' | translate"
      [previousLabel]="'generic.pagination.previous' | translate"
      (pageChange)="paginate($event)"
    ></pagination-controls>
  </div>
</mat-card>
