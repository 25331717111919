<mat-card appearance="outlined">
	<div class="file">
    <p>{{ item.name }}</p>
    <div class="buttons">
      <button (click)="finalizeDownload(item)" mat-icon-button [attr.aria-label]="'download_tray_item.close' | translate">
        <mat-icon>close</mat-icon>
      </button>
    </div>
	</div>
	<mat-progress-bar *ngIf="download$ | async as download"
		[mode]="download.state == 'PENDING' ? 'buffer' : 'determinate'"
    [value]="download.progress">
	</mat-progress-bar>
</mat-card>
