import {
  Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  get, intersection, isEqual, isNil, size, sortBy, uniqBy,
} from 'lodash';
import {
  BehaviorSubject, combineLatest, Observable, Subject, firstValueFrom,
} from 'rxjs';
import {
  debounceTime, distinctUntilChanged, filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import { ActivityStatusQuery } from 'src/api/generic/activity-status/activity-status.query';
import { ActivityStatusService } from 'src/api/generic/activity-status/activity-status.service';
import { ActivityQuery } from 'src/api/activity/activity/activity.query';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { SectionQuery } from 'src/api/activity/section/section.query';
import { SectionService } from 'src/api/activity/section/section.service';
import { AssetService } from 'src/api/media/asset/asset.service';
import { PublishActivityService } from 'src/api/publish/activity/activity.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IOrganizationUnitActivityRoles } from 'typings/api-activity';
import { IAttachmentListResponse, IAssetUrl } from 'typings/api-media';
import { IPublishActivityRequest } from 'typings/api-publish';
import {
  IActivity, IActivityStatus, IAttachmentMedia, IOrganizationUnitOverview, ISection,
} from 'typings/doenkids/doenkids';
import { IYoutube } from 'typings/section-types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { ESectionType } from 'src/components/shared/section/section.component';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

@Component({
  selector: 'app-base-activity-preview',
  templateUrl: './base-activity-preview.component.html',
  styleUrls: ['./base-activity-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseActivityPreviewComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  public activityId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public forkedId: number;

  public activityDetails$: Observable<IActivity>;

  public activityLoading$: Observable<boolean>;

  private afterFirstPdfRender = false;

  // All the metadata
  public activityAttachments$: BehaviorSubject<IAttachmentMedia[]> = new BehaviorSubject<IAttachmentMedia[]>([]);

  public sections$: Observable<ISection[]>;

  public mediaSections$: Observable<ISection[]>;

  public notMediaSections$: Observable<ISection[]>;

  public headingColor = '#000000';

  public subheadingColor = '#000000';

  public bodyTextColor = '#000000';

  public bodyBackgroundColor = '#FFFFFF';

  /** Subject to render PDF updates
   */
  private pdf$: BehaviorSubject<string | Blob> = new BehaviorSubject<string | Blob>('');

  public pdfLoading: boolean;

  /** The last time a PDF Render was requested */
  private lastPdfRender: number;

  @ViewChild('pdfViewer') pdfViewer;

  public isSmall$: Observable<boolean>;

  public isMedium$: Observable<boolean>;

  public isMobile$: Observable<boolean>;

  public isAdmin$: Observable<boolean>;

  public currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public isManagement$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  private activityOwnerRoles$: BehaviorSubject<IOrganizationUnitActivityRoles[]> = new BehaviorSubject<IOrganizationUnitActivityRoles[]>([]);

  public activityOwner$: Observable<IOrganizationUnitActivityRoles> = this.activityOwnerRoles$.pipe(
    filter((value) => !isNil(value)),
    map((roles) => {
      const owners = roles.filter((activityRole) => activityRole.organization_unit_role_id === DoenkidsStaticValuesHelper.ACTIVITY_OWNER_ROLE);
      return owners[0];
    }),
  );

  public activityStatuses$: Observable<IActivityStatus[]>;

  public isRevokedInNodePath$: Observable<boolean>;

  public hasActivityCopyPermission$: Observable<boolean>;

  public hasOUWritePermission$: Observable<boolean>;

  readonly ESectionType = ESectionType;

  public approveIsTranslate$: Observable<boolean>;

  public forking$ = new BehaviorSubject<boolean>(false);

  private hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    sectionQuery: SectionQuery, private sectionService: SectionService,
    private $session: DoenkidsSessionProvider,
    private assetService: AssetService,
    private publishActivityService: PublishActivityService,
    private $breakpoint: BreakpointsProvider,
    private $permission: PermissionProvider,
    private activityService: ActivityService, activityQuery: ActivityQuery,
    private activityStatusQuery: ActivityStatusQuery, private activityStatusService: ActivityStatusService,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.activityDetails$ = this.activityId$.pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value)),
      mergeMap((activityId) => activityQuery.selectEntity(activityId).pipe(
        filter((value) => !isNil(value)),
        debounceTime(300),
        map((activity) => activity.data),
      )),
    );

    this.activityLoading$ = activityQuery.selectLoading().pipe(takeUntil(this.stop$));

    this.sections$ = sectionQuery.selectAll().pipe(
      takeUntil(this.stop$),
      map((sections) => sortBy(sections, 'order')),
      map((sections) => sections.map((section) => {
        if (section.type_id === ESectionType.YOUTUBE) {
          // Map all video urls, change them to an accepted format.
          //
          const parsedVideos = get(section, 'data.video', []).map((url: string) => {
            // Regular expression that can take just about any youtube link and get the ID.
            //
            // From: https://stackoverflow.com/a/5831191
            //
            // eslint-disable-next-line max-len
            const re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;

            // Replace the youtube
            return url.replace(re, 'https://www.youtube-nocookie.com/embed/$1');
          });

          const data: IYoutube = {
            ...section.data,
            video: parsedVideos,
          } as IYoutube;

          return { ...section, data };
        }
        return section;
      })),
    );

    this.mediaSections$ = this.sections$.pipe(
      takeUntil(this.stop$),
      map((sections) => sections.filter((section) => section.type_id === ESectionType.YOUTUBE)),
    );

    this.notMediaSections$ = this.sections$.pipe(
      takeUntil(this.stop$),
      map((sections) => sections.filter((section) => section.type_id !== ESectionType.YOUTUBE)),
    );

    this.isSmall$ = this.$breakpoint.isSmall$.pipe(takeUntil(this.stop$));

    this.isMedium$ = this.$breakpoint.isMedium$.pipe(takeUntil(this.stop$));

    this.isMobile$ = this.$breakpoint.isMobile$.pipe(takeUntil(this.stop$));

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));

    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));

    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$));

    this.isManagement$ = this.$permission.isDoenkidsManagement$.pipe(takeUntil(this.stop$));

    this.activityStatuses$ = this.activityStatusQuery.selectAll().pipe(takeUntil(this.stop$));

    this.isRevokedInNodePath$ = combineLatest([this.currentOUDetails$, this.activityOwnerRoles$]).pipe(
      takeUntil(this.stop$),
      /**
       * Let's check if there in my node_path this activity is revoked
       */
      map(([organization, roles = []]) => {
        const { node_path: nodePath } = organization;
        // @ts-ignore:next-line
        const organizationIdsInMyPath = nodePath.map((id) => parseInt(id, 10));
        const organisationsWhoRevokedThisActivity = roles
          .filter((role) => role.organization_unit_role_name === 'REVOKED')
          .map((role) => role.organization_unit_id);
        const organizationIdsThatOverlap = intersection(organizationIdsInMyPath, organisationsWhoRevokedThisActivity);
        return size(organizationIdsThatOverlap) > 0;
      }),
    );

    this.hasActivityCopyPermission$ = this.$permission.hasActivityCopyPermission$.pipe(takeUntil(this.stop$));

    this.hasOUWritePermission$ = this.$permission.hasOUWritePermissions$.pipe(takeUntil(this.stop$));

    this.fetchQueryParameters();

    this.approveIsTranslate$ = combineLatest([this.activityDetails$, this.currentOUDetails$]).pipe(
      takeUntil(this.stop$),
      map(([activityDetails, ouDetails]) => activityDetails?.country_code !== ouDetails?.country_code),
    );
  }

  onPdfLoadedEvent() {
    this.pdfViewer?.PDFViewerApplication?.preferences?.set('externalLinkTarget', 2);
    this.pdfLoading = false;
  }

  async ngOnInit() {
    this.fetchActivityDetails(this.activityId$.value);
    this.sectionService.fetchAll(this.activityId$.value, 100, 0);
    this.activityStatusService.fetchAll();

    // Initially render the PDF
    //
    const firstPDFRender = this.renderPdf();

    // Render the current PDF.
    //
    this.pdf$.pipe(
      filter((value) => !isNil(value) && value !== ''),
    ).subscribe((pdf) => {
      if (this.pdfViewer) {
        if (typeof pdf === 'string') {
          this.pdfViewer.pdfSrc = encodeURIComponent(pdf);
        } else {
          this.pdfViewer.pdfSrc = pdf;
        }
        this.pdfViewer.refresh();
      }
    });

    this.activityId$.pipe(takeUntil(this.stop$)).subscribe((newActivityId: number) => {
      this.sectionService.fetchAll(newActivityId, 100, 0);
    });

    combineLatest([this.currentOUDetails$, this.activityDetails$]).pipe(
      filter(() => this.afterFirstPdfRender),
      takeUntil(this.stop$), debounceTime(100),
      distinctUntilChanged(isEqual),
    ).subscribe(async () => {
      this.renderPdf();
    });

    await firstPDFRender;

    this.afterFirstPdfRender = true;
    this.fetchActivityAttachments();
  }

  /** Destroy all subscriptions, leave no subscribers.
   */
  ngOnDestroy() {
    this.stop$.next();
  }

  /** Renders the pdf using the publish API.
   */
  async renderPdf() {
    this.pdfLoading = true;

    const thisRenderStartedAt = (new Date()).getTime();
    this.lastPdfRender = thisRenderStartedAt;

    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = null;
    }

    const activityDetails = await firstValueFrom(this.activityDetails$);
    const OUDetails = await firstValueFrom(this.currentOUDetails$);

    let pdf: string | Blob;

    try {
      const activityPdfRequest: IPublishActivityRequest = {
        activity_id: activityDetails.id,
        organization_unit_id: OUDetails.id,
      };

      pdf = await this.publishActivityService.getActivityPdfUrl(activityPdfRequest, activityDetails.country_code);
    } catch (e) {
      if ((thisRenderStartedAt === this.lastPdfRender)) {
        this.$i18nToastProvider.error(_('activity.preview.pdf.download.failed'), { name: activityDetails.name });
      }
    }

    // Only render the last time the PDF was requested to render to prevent 'flickering'
    //
    if (pdf && (thisRenderStartedAt === this.lastPdfRender)) {
      this.pdf$.next(pdf);
    }
  }

  async openAttachment(attachment: IAttachmentMedia) {
    console.log(attachment, '##');
    // eslint-disable-next-line no-undef
    const a = document.createElement('a');

    const mediaAssetUrl = await firstValueFrom(this.assetService.getUrl(attachment.uuid)) as IAssetUrl;

    a.href = mediaAssetUrl.url;
    a.download = attachment.filename;
    a.click();
  }

  fetchQueryParameters() {
    // Get a snapshot of the current route parameters.
    //
    const { params } = this.route.snapshot;

    // Save the activity id, taken from the route parameters.
    //
    this.activityId$.next(+params.id);

    this.getActivityRoles(this.activityId$.value);
  }

  async fetchActivityDetails(activityId: number) {
    let rerenderPDF = false;

    if (activityId !== this.activityId$.value) {
      this.fetchQueryParameters();
      rerenderPDF = true;
    }

    await this.activityService.fetch(activityId);

    if (rerenderPDF) {
      await this.renderPdf();
    }
  }

  async forkActivity() {
    this.forking$.next(true);
    const approveIsTranslate = await firstValueFrom(this.approveIsTranslate$);

    let autoTranslate = false;
    if (approveIsTranslate) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('activity.auto_translate_dialog.title')),
          description: this.$translateService.instant(_('activity.auto_translate_dialog.description')),
        },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());

      if (result === 'confirm') {
        autoTranslate = true;
      }
    }

    const ouId = (await firstValueFrom(this.currentOUDetails$)).id;
    const activityDetails = await firstValueFrom(this.activityDetails$);
    const newActivity = await this.activityService.copy(activityDetails.id, ouId, { autoTranslate });
    this.router.navigate([`/activities/${newActivity.id}/edit`], { replaceUrl: true });
    this.forking$.next(false);
  }

  async excludeActivity() {
    if (!this.activityOwnerRoles$.value) {
      await this.getActivityRoles(this.activityId$.value);
    }

    const dialogText: string[] = [_('activity.exclude.confirm.description')];
    const dialogTextParams: any = {};
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const ouId = (await firstValueFrom(this.currentOUDetails$))?.id;
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);

    if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      const activityConnections = await firstValueFrom(this.activityService.listConnections(this.activityId$.value, ouId));
      const showableNameLength = 5;

      if (activityConnections.length > 0) {
        const templateCount = activityConnections.reduce((previous, current) => previous + (!isNil(current.template_id) ? 1 : 0), 0);
        if (templateCount > 0) {
          dialogTextParams.template_count = templateCount;
          const templateNames = uniqBy(activityConnections.map((activityConnection) => activityConnection.template_name).filter((name) => !isNil(name)), (name) => name.toLowerCase().trim());
          if (templateNames.length > showableNameLength) {
            dialogTextParams.template_names = [...templateNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.exclude.confirm.description.activity_connections.and_other_templates'), {
                template_count: templateCount - showableNameLength,
              })].join(', ');
          } else {
            dialogTextParams.template_names = templateNames.join(', ');
          }
        }

        const programCount = (activityConnections.reduce((previous, current) => previous + (!isNil(current.program_id) ? 1 : 0), 0) - templateCount);

        if (programCount > 0) {
          dialogTextParams.program_count = programCount;
          const programNames = uniqBy(activityConnections.map((activityConnection) => activityConnection.program_name).filter((name) => !isNil(name)), (name) => name.toLowerCase().trim());
          if (programNames.length > showableNameLength) {
            dialogTextParams.program_names = [...programNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.exclude.confirm.description.activity_connections.and_other_programs'), {
                program_count: programCount - showableNameLength,
              })].join(', ');
          } else {
            dialogTextParams.program_names = programNames.join(', ');
          }
        }

        if (programCount > 0 && templateCount > 0) {
          dialogText.push(_('activity.exclude.confirm.description.activity_connections.templates_and_programs'));
        } else if (programCount === 0 && templateCount > 0) {
          dialogText.push(_('activity.exclude.confirm.description.activity_connections.templates'));
        } else if (programCount > 0 && templateCount === 0) {
          dialogText.push(_('activity.exclude.confirm.description.activity_connections.programs'));
        }
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.exclude.confirm.title')),
        description: this.$translateService.instant(dialogText, dialogTextParams),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const currentOUDetails = await firstValueFrom(this.currentOUDetails$);
      const currentOURightsArray = this.activityOwnerRoles$.value.filter((activityRole) => activityRole.organization_unit_id === currentOUDetails.id);
      const currentOURights = currentOURightsArray[0]?.organization_unit_role_id ?? null;

      if (currentOURights && currentOURights === DoenkidsStaticValuesHelper.ACTIVITY_REVOKED_ROLE) {
        // if you already have a revoked right on this activity you shouldn't even see it
        // but we will let you know we already excluded it
        //
        this.$i18nToastProvider.error(_('activity.exclude.already_excluded'));
      } else {
        await firstValueFrom(this.activityService.unapprove(this.activityId$.value, currentOUDetails.id));
      }

      this.router.navigate(['/base-activities']);
    }
  }

  async fetchActivityAttachments() {
    const activityAttachments = await firstValueFrom(this.activityService.attachment(this.activityId$.value)) as IAttachmentListResponse;
    this.activityAttachments$.next(activityAttachments?.items);
  }

  public async approveActivity() {
    const activityDetails: IActivity = await firstValueFrom(this.activityDetails$);

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('activity.preview.approve.confirm.description'), { organization: activityDetails.name }),
      },
    });

    const dialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (dialogResult !== 'confirm') {
      return;
    }

    const OUDetails = await firstValueFrom(this.currentOUDetails$);
    await firstValueFrom(this.activityService.approve(activityDetails.id, OUDetails.id));

    this.router.navigate(['/base-activities']);
  }

  private async getActivityRoles(activityId: number) {
    this.activityOwnerRoles$.next(await firstValueFrom(this.activityService.organizationUnits(activityId)) as IOrganizationUnitActivityRoles[]);
  }
}
