import {
  Component, Output, EventEmitter, Input, TemplateRef, ContentChild, ContentChildren, QueryList, OnDestroy, AfterViewInit,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { isNil, cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DragHandleComponent } from '../../shared/drag-handle/drag-handle.component';

/** Implement this as such
 * @example
 *   <app-panel-container
 *     *ngIf="(itemList$ | async) as items"
 *     cdkDropList [cdkDropListData]="periods" (cdkDropListDropped)="dropItem($event)"
 *     (updated)="onUpdate()">
 *     <ng-template let-itemIndex="index">
 *       <ng-container *ngIf="items[itemIndex] as item">
 *         <mat-expansion-panel cdkDrag>
 *           <mat-expansion-panel-header>
 *             <mat-panel-title>
 *               <app-drag-handle cdkDragHandle></app-drag-handle>
 *             </mat-panel-title>
 *           </mat-expansion-panel-header>
 *           <ng-template matExpansionPanelContent>
 *           </ng-template>
 *         </mat-expansion-panel>
 *       </ng-container>
 *     </ng-template>
 *   </app-panel-container>
 */
@Component({
  selector: 'app-panel-container',
  templateUrl: './panel-container.component.html',
  styleUrls: ['./panel-container.component.scss'],
})
export class PanelContainerComponent implements OnDestroy, AfterViewInit {
  // A template reference is required for the template outlet to work.
  //
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  // References to expansion pannels to control their expanded status and listen for changes.
  //
  @ContentChildren(MatExpansionPanel, { descendants: false }) expansionPanels: QueryList<MatExpansionPanel>;

  // References to drag handles to control their disabled state.
  //
  @ContentChildren(DragHandleComponent, { descendants: true }) dragHandles: QueryList<DragHandleComponent>;

  // The currently active panel.
  //
  public activePanel = null;

  public previousPanel = null;

  // Subjects that we emit on certain events.
  //
  public changes$ = new Subject<void>();

  private destroy$ = new Subject<void>();

  // The item ID for the panel that currently has dragging disabled.
  //
  public expandedItemId: number;

  // An object with item ID keys and panel ID values. Used to find out what expansion panel is currently opened and undraggable.
  //
  public itemPanels: { [index: number]: any };

  /** The items that need to be ordered. */
  @Input()
  cdkDropListData: any[];

  /** Emits when a component has been updated. */
  @Output()
  updated = new EventEmitter<void>();

  ngAfterViewInit() {
    // When the expansionpanels change their state, call the onChanges method.
    //
    this.expansionPanels.changes.pipe(takeUntil(this.destroy$)).subscribe(this.onChanges.bind(this));
  }

  /** Gets called every time the expansion panels change. */
  onChanges() {
    // Effectively reset the current subscriptions.
    //
    this.changes$.next();

    this.itemPanels = {};

    // Loop through all the current expansion panels.
    //
    this.expansionPanels.forEach((expansionPanel, index) => {
      this.setPanelItem(expansionPanel, index);
      // Subscribe to the expansion panel changes.
      //
      expansionPanel.expandedChange
        .pipe(takeUntil(this.changes$))
        .subscribe((change) => this.onExpansionPanelChange(expansionPanel, index, change));
    });
  }

  /** Stores the ID of an expansionPanel and an item together. */
  setPanelItem(expansionPanel, index) {
    const item = this.cdkDropListData[index];

    const panelId = expansionPanel.id;
    const itemId = item.id;

    this.itemPanels[itemId] = panelId;
  }

  /** Set the current disabled drag object */
  setDragDisable(expansionPanelId) {
    // Get the item ID, which was stored as a key in the itemPanels object.
    //
    const itemId = Object.keys(this.itemPanels);

    // Find the relevant itemID.
    //
    const foundItemId = itemId.find((id) => this.itemPanels[id] === expansionPanelId);

    if (foundItemId) {
      // Store the itemId in the dragDisabledId object.
      this.expandedItemId = +foundItemId;
    }
  }

  onExpansionPanelChange(expansionPanel, index, change) {
    const dragHandle = this.dragHandles.find((item, dragHandleIndex) => index === dragHandleIndex);

    if (!isNil(dragHandle)) {
      dragHandle.disabled = change;
    }

    if (change) {
      this.activePanel = expansionPanel.id;
      this.setDragDisable(expansionPanel.id);
      this.collapseEveryoneExcept(expansionPanel.id);
    } else if (!change && expansionPanel.id === this.activePanel) {
      this.activePanel = null;
      this.expandedItemId = null;
    }
  }

  collapseEveryoneExcept(exceptExpansionPanelIndex) {
    this.expansionPanels.forEach((expansionPanel) => {
      if (exceptExpansionPanelIndex !== expansionPanel.id) {
        expansionPanel.close();
      }
    });
  }

  collapseEveryone() {
    this.previousPanel = cloneDeep(this.activePanel);

    this.expansionPanels.forEach((expansionPanel) => {
      expansionPanel.close();
    });
  }

  openLastActivePanel() {
    const lastActivePanel = this.expansionPanels.find((panel) => panel.id === this.previousPanel);

    if (!isNil(lastActivePanel)) {
      lastActivePanel.expanded = true;
      this.previousPanel = null;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  isDisabled(index): boolean {
    const handle = this.dragHandles.find((item, dragIndex) => index === dragIndex);
    return handle ? handle.disabled : false;
  }

  // Will track elements by their ID to see if they were changed.
  //
  trackElement(index: any, element: { id: number; }) {
    return element ? element.id : null;
  }
}
