import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProgram } from 'typings/doenkids/doenkids';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DownloadProvider } from 'src/providers/download.provider';
import { sortBy } from 'lodash';
import { IUploadResponse } from 'typings/custom-app-types';
import { IMediaItem } from 'typings/section-types';

export interface IActivityReviewInput {
  title: string;
  description: string;
  programs: IProgram[];
}

export interface IAtivityReviewOutput {
  programs: IProgram[];
  media_uuid: string;
  status: string;
}

@Component({
  selector: 'app-create-program-booklet',
  templateUrl: './create-program-booklet.component.html',
  styleUrls: ['./create-program-booklet.component.scss'],
})
export class CreateProgramBookletComponent {
  title: string;

  description: string;

  programs: IProgram[];

  downloading = false;

  public newBookletForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreateProgramBookletComponent>,
    private downloadProvider: DownloadProvider,
    @Inject(MAT_DIALOG_DATA) data: IActivityReviewInput,
  ) {
    this.title = data.title;
    this.description = data.description;
    this.programs = sortBy(data.programs, (program) => program.from);

    this.newBookletForm = this.fb.group({
      media_uuid: [''],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async onConfirm() {
    const mediaUuid = this.newBookletForm.get('media_uuid').value;
    this.downloading = true;
    await this.downloadProvider.downloadBookletPdf(this.programs, mediaUuid);

    this.dialogRef.close({
      status: 'confirm',
      media_uuid: this.newBookletForm.get('media_uuid').value,
      programs: this.programs,
    } as IAtivityReviewOutput);
    this.downloading = true;
  }

  addCoverImage($event: IUploadResponse | IMediaItem) {
    this.newBookletForm.get('media_uuid').setValue($event.uuid);
  }
}
