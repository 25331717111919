import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IKvsMethodState, IKvsMethodWithObservationPoints, KvsMethodStore } from './kvs-method.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KvsMethodQuery extends QueryEntity<IKvsMethodState, IKvsMethodWithObservationPoints> {
  constructor(protected store: KvsMethodStore) {
    super(store);
  }

  getKvsMethodsForCountryCode(countryCode: string) {
    return this.selectAll().pipe(
      map((kvsMethods) => kvsMethods.filter((kvsMethod) => kvsMethod.country_code.toLowerCase() === countryCode.toLowerCase())),
    );
  }
}
