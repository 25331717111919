import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProgramTemplateStatus } from 'typings/doenkids/doenkids';
import { ProgramTemplateStatusStore, IProgramTemplateStatusState } from './program-template-status.store';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateStatusQuery extends QueryEntity<IProgramTemplateStatusState, IProgramTemplateStatus> {
  constructor(protected store: ProgramTemplateStatusStore) {
    super(store);
  }
}
