import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IPeriodSection } from 'typings/doenkids/doenkids';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { PeriodSectionStore } from './period-section.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PeriodSectionService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: PeriodSectionStore) {}

  @transaction()
  async create(periodSection: IPeriodSection) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall('/activity/period-section', periodSection)) as IPeriodSection;
    this.store.update(response);
    return response;
  }

  @transaction()
  async fetch(id: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/period-section/${id}`)) as IPeriodSection;
    this.store.update(response);
    return response;
  }

  async update(periodSection: IPeriodSection) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/period-section/${periodSection.id}`, periodSection)) as IPeriodSection;
    this.store.update(response);
  }

  async archive(id: number) {
    await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/period-section/${id}`));
    this.store.reset();
  }
}
