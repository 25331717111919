<h3 mat-dialog-title>{{ 'image.replace.dialog.title' | translate }}</h3>
<mat-dialog-content class="replace-image-dialog">
  {{ 'image.replace.dialog.subtitle' | translate }}
  <div class="image-selection">
    <h4 class="current_image_title">{{ 'image.replace.dialog.current_image' | translate }}</h4>
    <div class="current_image_content">
      <app-doenkids-image  [maxWidth]="200" [maxHeight]="400" [preview]="true" [mediaItem]="data.currentImageUuid"></app-doenkids-image>
    </div>

    <h4 class="new_image_title">{{ 'image.replace.dialog.new_image' | translate }}</h4>
    <app-asset-upload class="new_image_content" [identifier]="data.identifier" [purpose]="data.purpose" [isReplacement]="true" (replacementFile)="setNewImage($event)">
      <ng-template let-uploadData="uploadData">
        <div class="image-container">
          <app-doenkids-image [maxWidth]="200" [maxHeight]="400" [preview]="true" [mediaItem]="newImage$ | async"></app-doenkids-image>
        </div>
        <app-select-media
          [uploadData]="uploadData"
          [identifier]="data.identifier"
          purpose="replacement"
        ></app-select-media>
      </ng-template>
    </app-asset-upload>
  </div>
  <span class="warning">{{ 'image.replace.dialog.warning' | translate }}</span>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" fxFlexFill>
  <button mat-button color="primary" (click)="onClose()" [disabled]="isLoading$ | async">
    {{ 'generic.close' | translate }}
  </button>
  <button mat-button color="primary" (click)="onConfirm()" [disabled]="isLoading$ | async">
    <ng-container *ngIf="!(isLoading$ | async)">{{ 'generic.save' | translate }}</ng-container>
    <ng-container *ngIf="(isLoading$ | async)"><mat-spinner class="spinner" [diameter]="20"></mat-spinner></ng-container>
  </button>
</mat-dialog-actions>
