import {
  Component, OnInit, OnDestroy, ViewEncapsulation, Output, EventEmitter, Input,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  takeUntil, map, debounceTime, filter, distinctUntilChanged,
  startWith,
} from 'rxjs/operators';
import {
  isNil, isEqual, values, isEmpty,
} from 'lodash';
import {
  Subject, combineLatest, Observable, BehaviorSubject, firstValueFrom,
} from 'rxjs';
import { DoenkidsSessionProvider, IDoenKidsSession } from 'src/providers/session.provider';
import { IActivityStatus, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { ActivityStatusService } from 'src/api/generic/activity-status/activity-status.service';
import { ActivityStatusQuery } from 'src/api/generic/activity-status/activity-status.query';
import { IOrganizationUnitTreeNode } from 'typings/api-customer';
import { OrganizationUnitTreeService } from 'src/api/customer/organization-unit-tree/organization-unit-tree.service';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { ActivatedRoute } from '@angular/router';
import { EActivityStatusType } from 'src/components/shared/section/section.component';
import { TranslateService } from 'src/app/utils/translate.service';
// eslint-disable-next-line import/no-cycle
import { sortingOptions } from '../activity-search.component';

export interface OUSelectOption {
  id: number;
  name: string;
}

// These should be one of the values of the below interface
//
export type TActivityType = 'All' | 'OUOnly' | 'number' | 'Archived' | 'Originals';
export type TActivityStatus = 'All' | EActivityStatusType;
export const DEFAULT_FILTER_TYPE: TActivityType = 'All';
export const DEFAULT_FILTER_STATUS: EActivityStatusType = EActivityStatusType.PUBLISHED;

export interface IActivityStatusFilters {
  showActivities: TActivityType | number;
  activityStatus: TActivityStatus;
}

interface IOrganizationFilterOption {
  value: TActivityType | number;
  displayValue: string;
}

const statusFilters: Record<TActivityStatus, TActivityType[]> = {
  'All': ['All', 'OUOnly', 'Originals', 'Archived'],
  [EActivityStatusType.CONCEPT]: ['All', 'OUOnly', 'Originals', 'Archived'],
  [EActivityStatusType.REVIEW]: ['All', 'OUOnly', 'Originals', 'Archived'],
  [EActivityStatusType.PUBLISHED]: ['All', 'number', 'OUOnly', 'Originals', 'Archived'],
  [EActivityStatusType.UNPUBLISHED]: ['All', 'OUOnly', 'Originals', 'Archived'],
};

export interface IActivityStatusOption {
  value: TActivityStatus;
  displayValue: string;
}

@Component({
  selector: 'app-activity-status-filters',
  templateUrl: './activity-status-filters.component.html',
  styleUrls: ['./activity-status-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityStatusFiltersComponent implements OnInit, OnDestroy {
  @Input() public activityStatusFilterForm: UntypedFormGroup;

  @Input() public sortOptionControl: UntypedFormControl;

  @Output() public change: EventEmitter<IActivityStatusFilters> = new EventEmitter();

  public currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public currentSessionDetails$: Observable<IDoenKidsSession>;

  public isAdmin$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  private stop$ = new Subject<void>();

  private ouIsOfTypeLocation: Observable<boolean>;

  private ouIsOfTypeGroup: Observable<boolean>;

  private parentOUOptions$: BehaviorSubject<OUSelectOption[]> = new BehaviorSubject<OUSelectOption[]>([]);

  protected organizationFilterOptions$: Observable<IOrganizationFilterOption[]>;

  /**
   * We will only show the status filters (for concept etc.) when we are looking at ou only
   * Or ... if you're a OU of type customer and you have children
   */
  public displayStatusFilters$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output() public filtersActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isDoenkidsManagement$: Observable<boolean>;

  public activityStatuses$: Observable<IActivityStatus[]>;

  public activityStatusesToShow$: Observable<IActivityStatusOption[]>;

  public localSortingOptions = sortingOptions;

  public isLarge$: Observable<boolean>;

  constructor(
    private $session: DoenkidsSessionProvider,
    private activityStatusService: ActivityStatusService,
    private activityStatusQuery: ActivityStatusQuery,
    private breakPointsProvider: BreakpointsProvider,
    private $permission: PermissionProvider,
    private organizationUnitTreeService: OrganizationUnitTreeService,
    private $translateService: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$));
    this.currentSessionDetails$ = this.$session.getSession$.asObservable().pipe(takeUntil(this.stop$));
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.asObservable().pipe(takeUntil(this.stop$));
    this.ouIsOfTypeLocation = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));
    this.ouIsOfTypeGroup = this.$session.isCurrentOrganizationUnitIsOfTypeGroup$.pipe(takeUntil(this.stop$));
    this.isDoenkidsManagement$ = this.$permission.isDoenkidsManagement$.asObservable().pipe(takeUntil(this.stop$));
    this.activityStatuses$ = this.activityStatusQuery.selectAll().pipe(takeUntil(this.stop$));
    this.isLarge$ = this.breakPointsProvider.isLarge$.pipe(takeUntil(this.stop$));
    this.activityStatusesToShow$ = combineLatest([
      this.isReader$,
      this.activityStatuses$,
      this.ouIsOfTypeLocation,
      this.ouIsOfTypeGroup,
    ]).pipe(
      takeUntil(this.stop$),
      map(([isReader, activityStatuses, ouIsOfTypeLocation, ouIsOfTypeGroup]) => {
        const publishedStatus: IActivityStatusOption = {
          value: EActivityStatusType.PUBLISHED,
          displayValue: _('activity.status.published'),
        };
        if (isReader) {
          return [publishedStatus];
        }
        const showActivityStatuses: IActivityStatusOption[] = [{
          value: 'All',
          displayValue: _('activity.status.all'),
        }];

        activityStatuses.forEach((activityStatus) => {
          switch (activityStatus.id) {
            case EActivityStatusType.CONCEPT:
              showActivityStatuses.push({
                value: EActivityStatusType.CONCEPT,
                displayValue: _('activity.status.concept'),
              });
              break;
            case EActivityStatusType.REVIEW:
              // only add this when you are a customer
              //
              if (!ouIsOfTypeLocation && !ouIsOfTypeGroup) {
                showActivityStatuses.push({
                  value: EActivityStatusType.REVIEW,
                  displayValue: _('activity.status.review'),
                });
              }
              break;
            case EActivityStatusType.PUBLISHED:
              showActivityStatuses.push(publishedStatus);
              break;
            case EActivityStatusType.UNPUBLISHED:
              showActivityStatuses.push({
                value: EActivityStatusType.UNPUBLISHED,
                displayValue: _('activity.status.unpublished'),
              });
              break;
            default:
              break;
          }
        });

        return showActivityStatuses;
      }));
  }

  async ngOnInit() {
    // Fetch all activity statuses.
    //
    this.activityStatusService.fetchAll();

    this.setUpSubscriptions();
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.activityStatusFilterForm.disable();
    } else {
      this.activityStatusFilterForm.enable();
    }
  }

  changeSelection() {
    this.change.emit(this.activityStatusFilterForm.value);
  }

  private setUpSubscriptions() {
    this.isReader$.pipe(takeUntil(this.stop$)).subscribe((isReader) => {
      const activityStatusControl = this.activityStatusFilterForm.get('activityStatus');
      if (isReader && !activityStatusControl.disabled) {
        activityStatusControl.disable();
      } else if (!isReader && activityStatusControl.disabled) {
        activityStatusControl.enable();
      }
    });
    this.organizationFilterOptions$ = combineLatest([
      this.activityStatusFilterForm.get('activityStatus').valueChanges.pipe(startWith(this.activityStatusFilterForm.get('activityStatus').value)),
      this.parentOUOptions$,
      this.currentSessionDetails$,
      this.isAdmin$,
      this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$,
      this.$translateService.onInitialTranslationAndLangOrTranslationChange$,
    ])
      .pipe(
        takeUntil(this.stop$),
        map(([currentActivityStatus, parentOrganizationUnitOptions, session, isAdmin, hasWritePermission]) => {
          const optionsToAdd = statusFilters[currentActivityStatus as EActivityStatusType] ?? [];
          const options: IOrganizationFilterOption[] = [];

          for (const optionToAdd of optionsToAdd) {
            if (optionToAdd === 'All') {
              options.push({
                value: 'All',
                displayValue: this.$translateService.instant(_('activity_status_filters.all_activities')),
              });
            }

            if (optionToAdd === 'number' && parentOrganizationUnitOptions) {
              for (const parentOrganizationUnitOption of parentOrganizationUnitOptions) {
                options.push({
                  value: parentOrganizationUnitOption.id,
                  displayValue: this.$translateService.instant(_('activity_status_filters.parent_activities'), { parent: parentOrganizationUnitOption.name }),
                });
              }
            }
            if (optionToAdd === 'OUOnly' && session?.details?.id) {
              options.push({
                value: session.details.id,
                displayValue: this.$translateService.instant(_('activity_status_filters.organization_activities'), { organization: session.details.name }),
              });
            }

            if (optionToAdd === 'Originals' && (isAdmin || hasWritePermission)) {
              options.push({
                value: 'Originals',
                displayValue: this.$translateService.instant(_('activity_status_filters.originals')),
              });
            }

            if (optionToAdd === 'Archived' && (isAdmin || hasWritePermission)) {
              options.push({
                value: 'Archived',
                displayValue: this.$translateService.instant(_('activity_status_filters.archived')),
              });
            }
          }

          return options;
        }),
      );

    this.currentOUDetails$
      .pipe(
        takeUntil(this.stop$),
        filter((organization) => !isNil(organization)),
        distinctUntilChanged(isEqual),
      )
      .subscribe(async (organizationUnitDetails) => {
        await this.setOrganizationUnitTree(organizationUnitDetails);
        await this.manageShowActivities();
      });

    this.activityStatusFilterForm
      .get('showActivities')
      .valueChanges.pipe(takeUntil(this.stop$), startWith(this.activityStatusFilterForm.get('showActivities').value))
      .subscribe(async () => {
        await this.manageShowActivities({ forceEmit: true });
        // await this.manageStatus(this.activityStatusFilterForm.get('activityStatus').value);
      });

    this.activityStatusFilterForm
      .get('activityStatus')
      .valueChanges.pipe(takeUntil(this.stop$), startWith(this.activityStatusFilterForm.get('activityStatus').value))
      .subscribe((activityStatus) => {
        this.manageStatus({ forceEmit: true, activityStatus });
      });

    this.displayStatusFilters$.pipe(takeUntil(this.stop$), debounceTime(50)).subscribe((displayStatusFilters) => this.filtersActive.emit(displayStatusFilters));
  }

  private async manageShowActivities(params?: { forceEmit?: boolean }) {
    const showActivitiesCtrl = this.activityStatusFilterForm.get('showActivities');
    const statusCtrl = this.activityStatusFilterForm.get('activityStatus');
    const currentOUId = (await firstValueFrom(this.currentOUDetails$))?.id;
    const showActivitiesCtrlValue = showActivitiesCtrl.value;
    let newShowActivitiesCtrlValue = showActivitiesCtrl.value;
    const statusCtrlValue = statusCtrl.value;
    let newStatusCtrlValue = statusCtrl.value;
    // Set filter options through query params
    //
    const activityStatus = await firstValueFrom(this.route.queryParams.pipe(map((queryParams) => queryParams.activityStatus)));

    // if we have selected one of the parent OUs but it no longer in the parentOU tree set the value to all
    //
    if (typeof showActivitiesCtrlValue === 'number' && showActivitiesCtrlValue !== currentOUId && !isEmpty(this.parentOUOptions$.value)) {
      const parentOUIds = this.parentOUOptions$.value.map((parentOU) => parentOU.id);

      if (parentOUIds.indexOf(showActivitiesCtrlValue) === -1) {
        newShowActivitiesCtrlValue = DEFAULT_FILTER_TYPE;
      }
      newStatusCtrlValue = (activityStatus ?? DEFAULT_FILTER_STATUS);
    } else if (typeof showActivitiesCtrlValue === 'number' && showActivitiesCtrlValue !== currentOUId && isEmpty(this.parentOUOptions$.value)) {
      newShowActivitiesCtrlValue = DEFAULT_FILTER_TYPE;
      this.displayStatusFilters$.next(true);
    }

    if (`${statusCtrlValue}`?.toLowerCase() === 'alles') {
      newStatusCtrlValue = DEFAULT_FILTER_STATUS;
    }

    if (showActivitiesCtrlValue === 'Originals' || showActivitiesCtrlValue === 'Archived') {
      newStatusCtrlValue = DEFAULT_FILTER_STATUS;
      this.displayStatusFilters$.next(false);
    } else {
      this.displayStatusFilters$.next(true);
    }

    if (params?.forceEmit || newShowActivitiesCtrlValue !== showActivitiesCtrlValue || newStatusCtrlValue !== statusCtrlValue) {
      if (newShowActivitiesCtrlValue !== showActivitiesCtrlValue) {
        showActivitiesCtrl.setValue(newShowActivitiesCtrlValue);
      }

      if (newStatusCtrlValue !== statusCtrlValue) {
        statusCtrl.setValue(newStatusCtrlValue);
      }

      this.changeSelection();
    }
  }

  private async manageStatus(params?: { forceEmit?: boolean, activityStatus: EActivityStatusType }) {
    const activityStatusCtrl = this.activityStatusFilterForm.get('activityStatus');
    const showActivitiesCtrl = this.activityStatusFilterForm.get('showActivities');
    const showActivitiesCtrlValue = showActivitiesCtrl.value as TActivityType;
    const isReader = await firstValueFrom(this.isReader$);
    const writePermission = await firstValueFrom(this.$permission.hasOUWritePermissions$);
    const ouIsOfTypeLocation = await firstValueFrom(this.ouIsOfTypeLocation);
    const ouIsOfTypeGroup = await firstValueFrom(this.ouIsOfTypeGroup);
    const statusOptions = statusFilters[params.activityStatus] ?? [];
    const currentShowActivitiesIsAnOption = statusOptions.includes(showActivitiesCtrlValue) || statusOptions.includes(typeof showActivitiesCtrlValue as TActivityType);

    let newActivityStatusCtrlValue = activityStatusCtrl.value;
    let newShowActivitiesCtrlValue: TActivityType = showActivitiesCtrl.value;

    // When switching status

    // only when we are looking for OUOnly we can set the status filters and this we only have to check there what we are showing
    // if the status is review or unpublished and we have to hide those set the status to the default
    //
    if (isReader && newActivityStatusCtrlValue !== EActivityStatusType.PUBLISHED) {
      newActivityStatusCtrlValue = EActivityStatusType.PUBLISHED;
    } else if (!currentShowActivitiesIsAnOption) {
      newShowActivitiesCtrlValue = DEFAULT_FILTER_TYPE;
      this.displayStatusFilters$.next(true);
    } else if (showActivitiesCtrlValue === 'OUOnly' && writePermission) {
      this.displayStatusFilters$.next(true);
    } else if (typeof showActivitiesCtrlValue === 'number') {
      const selectedOUWritePermission = await this.$permission.checkOUWritePermission(showActivitiesCtrlValue);

      if (selectedOUWritePermission) {
        this.displayStatusFilters$.next(true);
      } else {
        newActivityStatusCtrlValue = DEFAULT_FILTER_STATUS;
        this.displayStatusFilters$.next(false);
      }
    } else if (showActivitiesCtrlValue === 'All') {
      this.displayStatusFilters$.next(true);
    } else {
      newActivityStatusCtrlValue = DEFAULT_FILTER_STATUS;
      this.displayStatusFilters$.next(false);
    }

    // if we have the status set to review and we are a location or group reset to default filter status as this is a not allowed status for locations and groups
    //
    if (newActivityStatusCtrlValue === EActivityStatusType.REVIEW && (ouIsOfTypeLocation || ouIsOfTypeGroup)) {
      newActivityStatusCtrlValue = DEFAULT_FILTER_STATUS;
    }

    if (params?.forceEmit || newActivityStatusCtrlValue !== activityStatusCtrl.value || newShowActivitiesCtrlValue !== showActivitiesCtrlValue) {
      if (newActivityStatusCtrlValue !== activityStatusCtrl.value) {
        activityStatusCtrl.setValue(newActivityStatusCtrlValue);
      }

      if (newShowActivitiesCtrlValue !== showActivitiesCtrlValue) {
        showActivitiesCtrl.setValue(newShowActivitiesCtrlValue);
      }

      this.changeSelection();
    }
  }

  private async setOrganizationUnitTree(OUDetails: IOrganizationUnitOverview) {
    if (![DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION, DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_GROUP].includes(OUDetails.organization_unit_type_id)
        && OUDetails.parent_organization_unit_id) {
      const treeResponse = await this.organizationUnitTreeService.fetch(OUDetails.parent_organization_unit_id, OUDetails.organization_unit_type_id);

      const parentOUs = await this.findAllParentNodes(treeResponse.items, [], OUDetails.id);

      this.parentOUOptions$.next(parentOUs);
    } else {
      this.parentOUOptions$.next([]);
    }
  }

  private async findAllParentNodes(treeResponse: IOrganizationUnitTreeNode[], parentNodes: OUSelectOption[], currentOUId: number) {
    for (const treeNode of treeResponse) {
      if (treeNode.id !== currentOUId) {
        const childArray = values(treeNode.children);

        if (childArray.length === 1) {
          // eslint-disable-next-line no-await-in-loop
          const hasNodeWritePermission = await this.$permission.checkOUWritePermission(treeNode.id);

          if (hasNodeWritePermission) {
            // if we only have one child we know it is a parent node and we add it if we have write permission on it and find any parent nodes among its child
            parentNodes.push({
              id: treeNode.id,
              name: treeNode.name,
            });
          }
          // eslint-disable-next-line no-await-in-loop
          await this.findAllParentNodes(childArray, parentNodes, currentOUId);
        } else if (childArray.length > 1) {
          // eslint-disable-next-line no-await-in-loop
          const hasNodeWritePermission = await this.$permission.checkOUWritePermission(treeNode.id);

          if (hasNodeWritePermission) {
            // if we have multiple children for this node we arrived at the last parent node as beneath here we only have siblings in the children array
            // we only add this to the parent nodes if we have write permission on it
            parentNodes.push({
              id: treeNode.id,
              name: treeNode.name,
            });
          }

          const childArrayIds = childArray.map((child) => child.id);

          if (!childArrayIds.includes(currentOUId)) {
            // eslint-disable-next-line no-await-in-loop
            await this.findAllParentNodes(childArray, parentNodes, currentOUId);
          }
        }
      }
    }

    return parentNodes;
  }
}
