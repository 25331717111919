import {
  AfterViewInit, Component, ElementRef, OnInit,
} from '@angular/core';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { enableAkitaProdMode } from '@datorama/akita';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationEnd, Router } from '@angular/router';
import { UnloadProvider } from 'src/providers/unloadProvider';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import '@fullcalendar/web-component/global';
import { registerSmartBreak } from '../assets/quill/shift-enter-binding';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from './utils/translate.service';
import { NavigationEventsProvider } from 'src/providers/navigation-events.provider';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

const QUILL_EDITOR_CSS_TRANSLATIONS = {
  '--i18n-change': _('generic.change'),
  '--i18n-save': _('generic.save'),
  '--i18n-remove': _('generic.remove'),
  '--i18n-quill-link-label': _('quill.link.label'),
};


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'doenkids-admin-portal';

  initialized$ = new BehaviorSubject<boolean>(false);

  constructor(
    private elementRef: ElementRef,
    private $translate: TranslateService,
    private $unload: UnloadProvider,
    private gtmService: GoogleTagManagerService,
    private iconRegistry: MatIconRegistry,
    private router: Router,
    private sanitizer: DomSanitizer,
    private navigationEventsProvider: NavigationEventsProvider,
    private metaService: Meta,
  ) {
    this.setMetaTags();
  }

  async ngOnInit() {
    enableAkitaProdMode();

    // Track to initialization event
    //
    try {
      this.gtmService.pushTag({ event: 'init' });
    } catch (e) {
      // if something goes wrong here though luck
      console.log('google tag manager failed', e);
    }


    // Send page events to Google Tag Manager
    //
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ).subscribe((event) => {
      try {
        this.gtmService.pushTag({
          event: 'pageview',
          pageName: (event as NavigationEnd).url,
        });
      } catch (e) {
        // if something goes wrong here though luck
        console.log('google tag manager failed', e);
      }
    });

    this.navigationEventsProvider.trackNavigationEvents();

    this.iconRegistry.addSvgIcon('doenkids-customer', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_customer.svg'));
    this.iconRegistry.addSvgIcon('doenkids-location', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_location.svg'));
    this.iconRegistry.addSvgIcon('doenkids-group', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_group.svg'));
    this.iconRegistry.addSvgIcon('ou-type-1', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_node.svg'));
    this.iconRegistry.addSvgIcon('ou-type-2', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_customer.svg'));
    this.iconRegistry.addSvgIcon('ou-type-3', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_location.svg'));
    this.iconRegistry.addSvgIcon('ou-type-4', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_group.svg'));
    this.iconRegistry.addSvgIcon('jibbie-linked', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_jibbie_linked_24px.svg'));
    this.iconRegistry.addSvgIcon('jibbie-unlinked', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_jibbie_unlinked_24px.svg'));
    this.iconRegistry.addSvgIcon('konnect-linked', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_konnect_linked_24px.svg'));
    this.iconRegistry.addSvgIcon('konnect-unlinked', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ic_konnect_unlinked_24px.svg'));
    this.iconRegistry.addSvgIcon('excel', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/excel.svg'));
    this.iconRegistry.addSvgIcon('nl', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/languages/nl.svg'));
    this.iconRegistry.addSvgIcon('fr', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/languages/fr.svg'));
    this.iconRegistry.addSvgIcon('en', this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/languages/en.svg'));

    registerSmartBreak();
  }

  ngAfterViewInit(): void {
    this.$translate
      .onInitialTranslationAndLangOrTranslationChange$
      .subscribe((event) => {
        if (!this.initialized$.value) {
          this.initialized$.next(true);
        }
        this.updateQuillEditorCssTranslations(event.translations);
      });
  }

  doBeforeUnload() {
    this.$unload.unload();
    return this.$unload.canUnload;
  }

  private updateQuillEditorCssTranslations(translations: any): void {
    Object.keys(QUILL_EDITOR_CSS_TRANSLATIONS).forEach((cssVariableName) => {
      const translationKey = QUILL_EDITOR_CSS_TRANSLATIONS[cssVariableName];
      const translation = translations[translationKey];
      if (translation && translation !== translationKey) {
        this.elementRef.nativeElement.style.setProperty(cssVariableName, `'${translation}'`);
      } else {
        this.elementRef.nativeElement.style.removeProperty(cssVariableName);
      }
    });
  }

  private setMetaTags() {
    this.metaService.updateTag({ property: 'og:locale', content: environment.defaultLanguage });
    this.metaService.updateTag({ property: 'og:url', content: [document.location.protocol, '//', document.location.host, '/'].join('') });
  }
}
