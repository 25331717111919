/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOpendataLrk } from 'typings/doenkids/doenkids';

export interface IOpendataLrkState extends EntityState<IOpendataLrk> {
  limit: number;
  skip: number;
  total: number;
  query?: string;
  aggregations: any;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'opendata-lrk-search' })
export class OpendataLrkSearchStore extends EntityStore<IOpendataLrkState, IOpendataLrk> {
  constructor() {
    super();
  }

  setMetadata({
    total, skip, limit, query, took,
  }) {
    this.update({
      total, skip, limit, query, took,
    });
  }

  setAggregations(aggregations: any) {
    this.update({ aggregations });
  }
}
