<app-doenkids-menu-bar [showBackButton]="true"></app-doenkids-menu-bar>

<app-split-page [leftWidth]="40" *ngVar="(reviews$ | async) as reviews">
  <ng-template #left>
    <div class="left-content">
      <mat-form-field appearance="outline">
        <mat-select [(value)]="selectedStatusOption" (selectionChange)="selectionChange()">
          <mat-option [value]="option.value" *ngFor="let option of reviewStatusOptions">
            {{ ('review.status.' + (option.value | lowercase)) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <card-list *showSpinner="(reviewsLoading$ | async)">
        <ng-container *noContent="!reviews || !(reviews?.length > 0); title: 'review_approval.no_reviews' | translate">
          <card-list-item
          *ngFor="let review of reviews | paginate: (paginationConfig$ | async)"
          (click)="selectReview(review)"
          [selected]="review.id === (selectedReview$ | async)?.id"
          [mediaUuid]="review.activity_media_uuid">
            <span>
              {{ (review.average_review_score / 20) | i18n_number: '1.1-1' | async }}
              <mat-icon [style.fontSize.px]="16">star</mat-icon>
            </span>
            <span>{{review.organization_unit_name}}</span>
            <span>{{review.activity_name}}</span>
          </card-list-item>
        </ng-container>
      </card-list>
    </div>
    <pagination-controls *ngIf="(paginationConfig$ | async).totalItems > MAX_ITEMS_PER_PAGE" id="reviewApproval" (pageChange)="paginateReviews($event)"></pagination-controls>
  </ng-template>
  <ng-template #right>
    <div fxFlex fxLayout="column" class="right-content">
      <app-review-preview
        [admin]="true"
        [selectedStatusOption]="selectedStatusOption"
        [review]="selectedReview"
        *ngIf="(selectedReview$ | async) as selectedReview; else select"
        (statusChange)="statusChange()">
      </app-review-preview>

      <ng-template #select>
        <h1>{{ 'review_approval.make_selection' | translate }}</h1>
      </ng-template>
    </div>
  </ng-template>
</app-split-page>
