import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FavoriteFolderStore, IFavoriteFolderState, IStoreFavoriteFolderDetails } from './favorite-folder.store';

@Injectable({ providedIn: 'root' })
export class FavoriteFolderQuery extends QueryEntity<IFavoriteFolderState, IStoreFavoriteFolderDetails> {
  constructor(protected store: FavoriteFolderStore) {
    super(store);
  }
}
