/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnit } from 'typings/doenkids/doenkids';

export interface IOrganizationUnitListState extends EntityState<IOrganizationUnit> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-unit-list' })
export class OrganizationUnitListStore extends EntityStore<IOrganizationUnitListState, IOrganizationUnit> {
  constructor() {
    super();
  }
}
