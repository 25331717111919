import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IOrganizationUnitProgramRoles, IOrganizationUnitProgramAssignment } from 'typings/api-activity';
import { IServiceResponse } from 'typings/api-generic';
import { ProgramOrganizationUnitStore } from './program-organization-unit.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgramOrganizationUnitService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: ProgramOrganizationUnitStore) {}

  async fetch(programId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program/${programId}/organization-unit`)) as IOrganizationUnitProgramRoles[];
    this.store.set(response);
    return response;
  }

  async assign({
    programId,
    assignments,
  } : {
    programId: number,
    assignments: IOrganizationUnitProgramAssignment[],
  }) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall(
      `/activity/program/${programId}/organization-unit`, { assignment: assignments },
    )) as IServiceResponse;
    this.fetch(programId);
    return response;
  }
}
