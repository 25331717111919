<div id="fab-dismiss"
     *ngIf="fabTogglerState === 'active'"
     (click)="onToggleFab()">
</div>
<div class='fab-button-container'>
  <div *ngIf="buttons.length > 0" [@speedDialStagger]="buttons.length">
    <div *ngFor="let btn of buttons" (click)="!btn.disabled && onClickFab(btn)" [class]="'fab-entry fab-entry-' + btn.action">
      <button mat-mini-fab color="secondary" [disabled]="btn.disabled">
        <i class="material-icons" *ngIf="btn.icon">{{btn.icon}}</i>
        <mat-icon *ngIf="btn.svgIcon" [svgIcon]="btn.svgIcon"></mat-icon>
      </button>
      <label class="fab-button-label">{{ btn.label }}</label>
    </div>
  </div>
  <button mat-fab class="fab-toggler" color="primary" (click)="onToggleFab()" *ngIf="fabButtons.length > 0">
    <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">{{ toggleIcon }}</i>
  </button>
</div>
