import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest, concat, firstValueFrom, mergeMap, Observable, Subject,
} from 'rxjs';
import { LangChangeEvent, TranslateService as NgxTranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import {
  filter, map, shareReplay, startWith, takeUntil, tap,
} from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/nl';
import 'dayjs/locale/nl-be';
import 'dayjs/locale/fr';
import { isArray, isNil } from 'lodash';
import { IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { TEST_LOCATIONS } from 'src/providers/generic.provider';

_('generic.language.nl_NL');
_('generic.language.en_GB');
_('generic.language.nl_BE');
_('generic.language.fr_BE');

export function translateServiceInitializer($i18TranslateProvider: TranslateService) {
  return () => $i18TranslateProvider.initialize();
}

export interface ICountryLanguageLocale {
  language: string;
  locale: string;
}

const LOCALE_TO_DAYJS_LOCALE_MAP = {
  'en-GB': 'en-gb',
  'nl-NL': 'nl',
  'nl-BE': 'nl-be',
  'fr-BE': 'fr',
  'fr': 'fr',
};

export const COUNTRY_CODE_TO_LANGUAGE = {
  'gb': 'en-GB',
  'nl': 'nl-NL',
  'be': 'nl-BE',
  'fr': 'fr',
};

export const LOCALE_STORAGE_KEY = 'locale';

export function getEnabledLanguages() {
  if (environment.production && TEST_LOCATIONS.includes(window.location.hostname)) {
    return environment.testEnabledLanguages;
  }
  return environment.enabledLanguages;
}

export function getStoredLocale() {
  const storedLocale = localStorage.getItem(LOCALE_STORAGE_KEY);

  return getEnabledLanguages().includes(storedLocale)
    ? storedLocale
    : environment.defaultLanguage;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService implements OnDestroy {
  onInitialLangAndLangChange$: Observable<{ lang: string }>;

  onLangOrTranslationChange$: Observable<[TranslationChangeEvent, LangChangeEvent]>;

  onInitialTranslationAndLangOrTranslationChange$: Observable<TranslationChangeEvent>;

  onLangChange$: EventEmitter<LangChangeEvent>;

  private registeredLocales = ['en'];

  private stop$ = new Subject();

  // These are the translations as defined in our translation files
  // for the non default languages it is a merged object of the default language and whatever
  // is defined for the requested language.
  // These translations DO NOT include any ou translation defined in the database
  //
  private baseTranslationsOfLanguage$ = new BehaviorSubject<{ [lang: string]: any }>({});

  // These are the translations that have the base translations and any translations defined
  // for the selected ou in the database
  //
  private loadedTranslations$ = new BehaviorSubject<{ [lang: string]: any }>({});

  // this is a map to keep track of what base translations are currently loading so we don't have to
  // fire off another promise to load the same language.
  // the key in the map is the language requested and the promise is what will actually do the loading
  //
  private loadingBaseTranslationsMap = new Map<string, Promise<any>>();

  // this is a map to keep track of what translations are currently loading so we don't have to
  // fire off another promise to load the same language
  // the key in the map is the language requested and the promise is what will actually do the loading
  //
  private loadingTranslationsMap = new Map<string, Promise<void>>();

  constructor(
    private readonly $ngxTranslateService: NgxTranslateService,
    private readonly $session: DoenkidsSessionProvider,
    private readonly dateAdapter: DateAdapter<any>,
    private readonly $iconRegistry: MatIconRegistry,
    private readonly $sanitizer: DomSanitizer,
  ) {
    this.onInitialLangAndLangChange$ = this.$ngxTranslateService.onLangChange
      .pipe(
        startWith({ lang: getStoredLocale() }),
        mergeMap((langChangeEvent) => this.registerLocaleData(langChangeEvent)),
        tap((langChangeEvent) => this.dateAdapter.setLocale(langChangeEvent.lang)),
        tap((langChangeEvent) => localStorage.setItem('locale', langChangeEvent.lang)),
        shareReplay(1),
      );

    this.onLangOrTranslationChange$ = combineLatest([
      this.$ngxTranslateService.onTranslationChange,
      this.$ngxTranslateService.onLangChange,
    ]);

    this.onInitialTranslationAndLangOrTranslationChange$  = combineLatest([
      concat(
        this.onInitialLangAndLangChange$.pipe(
          mergeMap((langEvent) => this.getTranslation(langEvent.lang)
            .pipe(map((translations) => {
              return {
                lang: this.$ngxTranslateService.currentLang,
                translations,
              } as TranslationChangeEvent;
            })),
          ),
        ),
        this.$ngxTranslateService.onTranslationChange,
      ),
      this.onInitialLangAndLangChange$,
    ]).pipe(map(([mappedTranslations]) => mappedTranslations))
      .pipe(filter((changeEvent) => !isNil(changeEvent.translations)));

    this.onLangChange$ = this.$ngxTranslateService.onLangChange;
  }

  async initialize() {
    const initialLocale = getStoredLocale();

    await this.registerLocaleData({ lang: initialLocale });

    this.$ngxTranslateService.setDefaultLang(environment.defaultLanguage);
    this.$ngxTranslateService.addLangs(getEnabledLanguages());
    this.use(initialLocale);
    let currentLoadedLanguages = this.loadedTranslations$.value;
    currentLoadedLanguages[environment.defaultLanguage] = await firstValueFrom(this.$ngxTranslateService.currentLoader.getTranslation(environment.defaultLanguage));
    this.loadedTranslations$.next(currentLoadedLanguages);

    this.registerFlagIconsForEnabledLanguages();

    this.onInitialLangAndLangChange$.pipe(takeUntil(this.stop$)).subscribe((langChange) => {
      dayjs.locale(LOCALE_TO_DAYJS_LOCALE_MAP[langChange.lang].toLowerCase());
    });

    this.$session.getOrganizationUnitSwitch$.pipe(
      takeUntil(this.stop$),
    ).subscribe((organization) => {
      this.loadTranslation(this.currentLang, organization);
    });

    firstValueFrom(this.$session.getOrganizationUnit$).then((organization) => {
      const translations = this.loadedTranslations$.value[environment.defaultLanguage];
      const organizationTranslationsForCurrentLang = { ...(organization.i18n_translation?.nl_NL ?? {}),  ...(organization.i18n_translation?.[environment.defaultLanguage.replace('-', '_')] ?? {}) };
      const currentOrganizationHasTranslations = Object.keys(organizationTranslationsForCurrentLang).length > 0;

      if (currentOrganizationHasTranslations) {
        const combinedTranslations = { ...translations, ...organizationTranslationsForCurrentLang };
        this.$ngxTranslateService.setTranslation(environment.defaultLanguage, combinedTranslations);
        currentLoadedLanguages = this.loadedTranslations$.value;
        currentLoadedLanguages[environment.defaultLanguage] = combinedTranslations;
        this.loadedTranslations$.next(currentLoadedLanguages);
      }
    });
  }

  ngOnDestroy(): void {
    this.stop$.next(true);
  }

  get currentLang(): string {
    return this.$ngxTranslateService.currentLang;
  }

  instant(key: string | string[], interpolateParams?: object): string {
    if (isArray(key)) {
      let combinedString = '';

      for (const keyEntry of key) {
        combinedString += ` ${this.$ngxTranslateService.instant(keyEntry, interpolateParams)}`;
      }

      return combinedString.trim();
    }
    return this.$ngxTranslateService.instant(key, interpolateParams);
  }

  get(key: string | string[], interpolateParams?: object): Observable<string> {
    return this.$ngxTranslateService.get(key, interpolateParams);
  }

  getTranslation(lang: string) {
    return this.loadedTranslations$.pipe(
      filter((translations) => {
        const langAlreadyLoaded = Object.keys(translations).includes(lang);
        if (!langAlreadyLoaded && !this.loadingTranslationsMap.has(lang)) {
          this.loadingTranslationsMap.set(lang, this.loadTranslation(lang).then(() => {
            this.loadingTranslationsMap.delete(lang);
          }));
        }
        return langAlreadyLoaded;
      }),
      map((translations) => translations[lang]),
    );
  }

  private async loadTranslation(lang: string, organization?: IOrganizationUnitOverview) {
    lang = lang.replace('_', '-');
    await this.getBaseTranslations(lang);

    const translations = this.baseTranslationsOfLanguage$.value[lang];
    organization = organization ?? this.$session.getCurrentOu();

    let combinedTranslations = translations;
    if (organization) {
      const organizationTranslationsForCurrentLang = { ...(organization.i18n_translation?.nl_NL ?? {}),  ...(organization.i18n_translation?.[lang.replace('-', '_')] ?? {}) };
      const currentOrganizationHasTranslations = Object.keys(organizationTranslationsForCurrentLang).length > 0;

      if (currentOrganizationHasTranslations) {
        combinedTranslations = { ...translations, ...organizationTranslationsForCurrentLang };
      }
    }

    this.$ngxTranslateService.setTranslation(lang, combinedTranslations);
    const loadedTranslations = this.loadedTranslations$.value;
    loadedTranslations[lang] = combinedTranslations;
    this.loadedTranslations$.next(loadedTranslations);
  }

  public async getBaseTranslations(lang: string) {
    lang = lang.replace('_', '-');
    if (!this.baseTranslationsOfLanguage$.value[lang]) {
      const currentBaseTranslations = this.baseTranslationsOfLanguage$.value;
      if (!this.loadingBaseTranslationsMap.get(lang)) {
        if (lang !== environment.defaultLanguage) {
          this.loadingBaseTranslationsMap.set(lang,
            firstValueFrom(
              combineLatest([
                this.$ngxTranslateService.currentLoader.getTranslation(environment.defaultLanguage),
                this.$ngxTranslateService.currentLoader.getTranslation(lang)],
              ).pipe(
                map(([defaultLanguageTranslations, langTranslations]) => ({ ...defaultLanguageTranslations, ...langTranslations })),
              )));
        } else {
          this.loadingBaseTranslationsMap.set(lang, firstValueFrom(this.$ngxTranslateService.currentLoader.getTranslation(lang)));
        }
      }
      currentBaseTranslations[lang] = await this.loadingBaseTranslationsMap.get(lang);
      if (this.loadingBaseTranslationsMap.has(lang)) {
        this.loadingBaseTranslationsMap.delete(lang);
      }
      this.baseTranslationsOfLanguage$.next(currentBaseTranslations);
    }

    return this.baseTranslationsOfLanguage$.value[lang];
  }

  getLangs() {
    return this.$ngxTranslateService.getLangs();
  }

  use(lang: string) {
    return this.$ngxTranslateService.use(lang);
  }

  getDayjsLocaleInstance(date?: any) {
    let dayjsObj;
    if (date) {
      dayjsObj = dayjs(date);
    } else {
      dayjsObj = dayjs();
    }
    return dayjsObj.locale(LOCALE_TO_DAYJS_LOCALE_MAP[this.currentLang]);
  }

  private registerLocaleData(langChangeEvent: { lang: string }): Promise<{ lang: string }> {
    if (this.registeredLocales.includes(langChangeEvent.lang)) {
      return new Promise<{ lang: string }>((resolve) => resolve(langChangeEvent));
    }

    const angularLocale = environment.angularLanguageLocaleMap[langChangeEvent.lang];

    // https://github.com/webpack/webpack/issues/13865
    return import(`/node_modules/@angular/common/locales/${angularLocale}.mjs`)
      .then((module) => {
        registerLocaleData(module.default);
        this.registeredLocales.push(langChangeEvent.lang);

        return langChangeEvent;
      });
  }

  private registerFlagIconsForEnabledLanguages(): void {
    getEnabledLanguages().forEach((language) => {
      const flagName = language.split('-')[1].toLowerCase();
      this.$iconRegistry.addSvgIcon(language, this.$sanitizer.bypassSecurityTrustResourceUrl(`./assets/flags/${flagName}.svg`));
    });
  }
}
