<app-doenkids-menu-bar [showBackButton]="true" [backUrl]="backUrl" [loading]="pageLoading$ | async">
  <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center" left>
    <button
      *ngIf="!(isReader$ | async)"
      [disabled]="!(hasWriteAccess$ | async) || !(isTheOwner$ | async)"
      mat-icon-button
      type="button"
      (click)="addPeriod()"
      [matTooltip]="'program_details.field.add' | translate"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$ | async)"
      [disabled]="!(hasWriteAccess$ | async) || (savingProgramPleaseWait$ | async) || !(isTheOwner$ | async)"
      mat-icon-button
      type="button"
      (click)="saveDetails()"
      [matTooltip]="'program.save' | translate"
      [disabled]="!programForm.valid"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      *ngIf="!(isReader$ | async) && showProgramTemplateCreate$ | async"
      [disabled]="!(hasWriteAccess$ | async) || !(isTheOwner$ | async)"
      mat-icon-button
      type="button"
      (click)="createProgramTemplate()"
      [matTooltip]="'program_details.template.create' | translate"
    >
      <mat-icon>file_copy</mat-icon>
    </button>
    <button mat-icon-button type="button" (click)="downloadPDF()" [matTooltip]="'program_details.pdf.download' | translate">
      <mat-icon>get_app</mat-icon>
    </button>
    <button *ngIf="!(isReader$ | async)" mat-icon-button type="button" (click)="copyProgramToOtherLocation()" [matTooltip]="'program_details.copy' | translate">
      <mat-icon>call_split</mat-icon>
    </button>
    <ng-container *ngIf="!(isReader$ | async) && (thereAreCustomerSymbolsToUse$ | async)">
      <button type="button" mat-icon-button (click)="selectSymbols()" [matTooltip]="'program_details.symbols.edit' | translate" [disabled]="!(isTheOwner$ | async)">
        <mat-icon>radio_button_checked</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="(programPeriods$ | async) as periods">
      <button
        *ngIf="!(isReader$ | async) && periodForm.value[periods[0]?.id]?.period_type === 'FREEFORM'"
        [disabled]="!(hasWriteAccess$ | async) || !(isTheOwner$ | async)"
        type="button"
        mat-icon-button
        (click)="convertProgramToDayProgram(periods)"
        [matTooltip]="'program_details.covert.to_day_program' | translate"
      >
        <mat-icon>event_available</mat-icon>
      </button>
      <button
        *ngIf="!(isReader$ | async) && periodForm.value[periods[0]?.id]?.period_type === 'DATE'"
        [disabled]="!(hasWriteAccess$ | async) || !(isTheOwner$ | async)"
        type="button"
        mat-icon-button
        (click)="convertProgramToFreeForm(periods)"
        [matTooltip]="'program_details.convert.to_freeform' | translate"
      >
        <mat-icon>event_busy</mat-icon>
      </button>
    </ng-container>
    <div *ngIf="(program$ | async)?.data as program" fxLayoutAlign="start center">
      <mat-chip-listbox hideSingleSelectionIndicator *ngIf="(!(hasWriteAccess$ | async) || (isAdmin$ | async)) && (isTheOwner$ | async)">
        <mat-chip-option *ngIf="programOwner$ | async; else noOwner" class="status-chip customer-chip">
          {{ (programOwner$ | async)?.name }}
        </mat-chip-option>
        <ng-template #noOwner>
          <mat-chip-option class="status-chip customer-chip unpublished">{{ 'program_details.no_owner' | translate }}</mat-chip-option>
        </ng-template>
      </mat-chip-listbox>

      <app-owner-chip
        *ngIf="programOwner$ | async as owner"
        (isTheOwnerChange)="handleIsTheOwnerChange($event)"
        [owningOuId]="owner.organization_unit_id"
      ></app-owner-chip>

      <ng-container *ngVar="(possibleContentLanguages$ | async) as contentLanguages">
        <mat-form-field
          *ngIf="(hasWriteAccess$ | async) && contentLanguages.length > 1"
          appearance="outline"
          class="compact-select content-language-select"
        >
          <mat-label>{{ 'program.content_language.label' | translate }}</mat-label>
          <mat-select [formControl]="contentLanguageControl">
            <mat-select-trigger *ngIf="contentLanguageControl.value">
              <mat-icon [svgIcon]="contentLanguageControl.value.toLowerCase()"></mat-icon>
              {{ 'generic.language.' + contentLanguageControl.value.toLowerCase()| translate }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let contentLanguage of contentLanguages"
              [value]="contentLanguage"
            >
              <mat-icon [svgIcon]="contentLanguage.toLowerCase()"></mat-icon>
              {{ 'generic.language.' + contentLanguage.toLowerCase() | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="program.program_status_id as statusId">
        <ng-container *ngIf="(hasWriteAccess$ | async) as writeAccess">
          <mat-form-field
            *ngIf="program.program_status_id as statusId"
            [matTooltip]="writeAccess ? ('program_details.status.change' | translate) : undefined"
            class="compact-select status-select"
            appearance="outline"
          >
            <mat-select [formControl]="programStatusCtrl">
              <ng-container *ngFor="let status of showableProgramStatusOptions$ | async">
                <mat-option
                  [ngClass]="[getProgramStatusName(statusId)]"
                  (click)="changePublicationStatus(status)"
                  [value]="status.id"
                >
                  {{ 'program.status.' + getProgramStatusName(status.id) | translate }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div right>
    <button *ngIf="hasWriteAccess$ | async" mat-icon-button type="button" (click)="goToEventLogs()" [matTooltip]="'program_details.event_log.view' | translate">
      <mat-icon>assignment</mat-icon>
    </button>
    <button *ngIf="!(isReader$ | async) && (isTheOwner$ | async)" mat-icon-button type="button" (click)="removeProgram()" [matTooltip]="'program.delete' | translate">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</app-doenkids-menu-bar>
<app-split-page
  [leftLabel]="'generic.details' | translate"
  [rightLabel]="(selectedPeriodId$ | async) ? ('program_details.tab.activities' | translate) : ('program_details.tab.template' | translate)"
  [activeTab]="activeTab"
  (selectedTabChange)="openTab($event.index)"
>
  <ng-template #left>
    <div class="details-container">
      <mat-accordion>
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ 'program_details.title' | translate }}</mat-panel-title>
            <mat-panel-description>{{ 'program_details.description' | translate }}</mat-panel-description>
          </mat-expansion-panel-header>

          <form [formGroup]="programForm">
            <div fxLayout="row">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'program.title.label' | translate }}</mat-label>
                <input type="text" matInput formControlName="name" [errorStateMatcher]="errorStateMatcher" />
                <mat-error *ngIf="programForm.get('name').hasError('required')">
                  {{ 'generic.form.required_field' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="column" class="program-content">
              <mat-label>{{ 'program.content.label' | translate }}</mat-label>
              <quill-editor bounds="self" theme="bubble" formControlName="content" [placeholder]="'program.content.placeholder' | translate"></quill-editor>
            </div>

            <div fxLayout="row" class="program-dates">
              <mat-form-field>
                <mat-label>{{ 'generic.period.start_date' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" [max]="programForm.get('to').value" formControlName="from" readonly />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'generic.period.end_date' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" [min]="programForm.get('from').value" formControlName="to" readonly />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="select-field">
              <mat-form-field color="primary" class="customer-field">
                <mat-label>{{ 'program.location.placeholder ' | translate }}</mat-label>
                <mat-select formControlName="customer_location_id">
                  <mat-option *ngFor="let location of availableLocations$ | async" [value]="location.id">
                    {{ location.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="select-field group-selection">
              <mat-label>{{ 'program.groups.label' | translate }}</mat-label>
              <mat-selection-list *ngIf="(availableGroups$ | async)?.length >= 1" class="program-groups" formControlName="customer_groups" role="listbox">
                <mat-list-option [value]="availableGroup.id" *ngFor="let availableGroup of availableGroups$ | async">
                  <span class="option-label">{{ availableGroup.name }}</span>
                </mat-list-option>
              </mat-selection-list>
              <div class="error-text" *ngIf="(availableGroups$ | async)?.length === 0">
                {{ 'program.groups.none_available' | translate }}
              </div>
            </div>
          </form>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="!(templateImagesDisabled$ | async) && (templateImagesCount$ | async) > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ 'program.template_images.title' | translate }}</mat-panel-title>
            <mat-panel-description>
              {{
                'program.template_images.description'
                  | translate
                    : {
                        imageCount: (templateImagesCount$ | async)
                      }
              }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <div class="template-image-container" *ngFor="let _ of [].constructor((templateImagesCount$ | async)); let index = index">
              <app-asset-upload
                *ngIf="'templateImage' + index; let identifier"
                [identifier]="identifier"
                purpose="template"
                (uploaded)="addTemplateImage($event, index)"
              >
                <ng-template let-uploadData="uploadData">
                  <div class="image-container">
                    <app-doenkids-image
                      class="square"
                      *ngIf="(templateImages$ | async) as templateImages"
                      [maxWidth]="200"
                      [maxHeight]="200"
                      [preview]="true"
                      [mediaItem]="this.templateImagesForm.get(index.toString())?.value"
                      [canReplaceImage]="(index !== 0 || index === 0 && !(templateImagesFirstFixed$ | async)) && isAdmin$ | async"
                    >
                      <app-select-media
                        *ngIf="(index !== 0 || index === 0 && !(templateImagesFirstFixed$ | async)) && !(isReadOnly$ | async)"
                        [mediaItemUuid]="this.templateImagesForm.get(index.toString())?.value"
                        [identifier]="identifier"
                        [uploadData]="uploadData"
                        [removable]="this.templateImagesForm.get(index.toString())?.value"
                        (selected)="addTemplateImage($event, index)"
                        purpose="template"
                        [programId]="programId"
                      >
                      </app-select-media>
                    </app-doenkids-image>
                  </div>
                </ng-template>
              </app-asset-upload>
            </div>
            <button
              *ngIf="!(isReadOnly$ | async)"
              [disabled]="!(hasWriteAccess$ | async)"
              mat-button
              fxFlexFill
              color="primary"
              type="button"
              (click)="saveTemplateSettings()"
            >
              {{ 'generic.save' | translate }}
            </button>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(hasProgramExplanationPermission$ | async) && (programExplanations$ | async)?.length > 0">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ 'program.explanation.title' | translate }}</mat-panel-title>
            <mat-panel-description>
              {{ 'program.explanation.description' | translate: { explanationCount: (programExplanations$ | async).length } }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <div class="download-buttons" *ngFor="let explanation of programExplanations$ | async; let index = index">
              <button mat-flat-button color="primary" type="button" (click)="downloadAttachment(explanation)">
                {{
                  'program_details.attachment.download'
                    | translate
                      : {
                          name: explanation.display_name ? explanation.display_name : explanation.filename
                        }
                }}
              </button>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="(programAttachments$ | async)?.length > 0">
          <mat-expansion-panel-header *ngVar="(programAttachments$ | async)?.length as count">
            <mat-panel-title>{{ 'generic.attachments' | translate }}</mat-panel-title>
            <mat-panel-description>{{ 'program_details.attachment.count' | translate: { count } }}</mat-panel-description>
          </mat-expansion-panel-header>
          <div class="panel-body">
            <div class="download-buttons" *ngFor="let attachment of programAttachments$ | async; let index = index">
              <button mat-flat-button color="primary" type="button" (click)="downloadAttachment(attachment)">
                {{ 'program_details.attachment.download' | translate: { name: (attachment.display_name ?? attachment.filename) } }}
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <form [formGroup]="periodForm">
        <app-panel-container
          *ngIf="(programPeriods$ | async) as periods"
          cdkDropList
          [cdkDropListData]="periods"
          (cdkDropListDropped)="dropPeriod($event)"
          appExpansionPanelController
        >
          <ng-template let-periodIndex="index">
            <ng-container *ngIf="periods[periodIndex] as period">
              <div
                class="activity-wrapper"
                cdkDrag
                [class.hasActivities]="periodHasActivities(period) && periodIsSelected(period)"
              >
                <mat-expansion-panel
                  class="period-panel"
                  [class.selected]="(selectedPeriodId$ | async) === period.id"
                  [class.hasActivities]="periodHasActivities(period) && periodIsSelected(period)"
                  [expanded]="(selectedPeriodId$ | async) === period.id"
                  (afterCollapse)="detectAllCollapsed()"
                  (afterExpand)="openPanel(period.id)"
                  *ngIf="periodForm.get(period?.id?.toString()) as singlePeriodForm"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <app-drag-handle cdkDragHandle></app-drag-handle>
                      <span>
                        {{
                          singlePeriodForm.get('period_type')?.value === 'FREEFORM'
                            ? singlePeriodForm.get('name')?.value || ('generic.field' | translate)
                            : (singlePeriodForm.get('start_date')?.value | i18n_date: 'fullDate' | async | titlecase)
                        }}
                      </span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <app-program-period [period]="$any(singlePeriodForm)" [program]="programForm">
                      <div class="period-activities" *ngIf="periodHasActivities(period)">
                        <fieldset>
                          <legend>{{ 'program.activities.label' | translate }}</legend>
                          <mat-list>
                            <ng-container *ngFor="let activity of getPeriodSectionActivities(period); let isLast = last">
                              <ng-container *ngIf="activity">
                                <mat-list-item (click)="openPeriodSectionActivity(period.id, activity.id)" class="period-section-activity">
                                  <mat-icon matListItemIcon>visibility</mat-icon>
                                  <div matListItemTitle>{{ activity?.title ? activity?.title : ('generic.activity' | translate) }}</div>
                                </mat-list-item>
                                <mat-divider *ngIf="!isLast"></mat-divider>
                              </ng-container>
                            </ng-container>
                          </mat-list>
                        </fieldset>
                      </div>
                      <div class="period-button-section" fxLayout="row" fxLayoutAlign="space-between center">
                        <button (click)="removePeriod(period)" mat-button type="button">
                          {{ 'generic.delete' | translate }}
                        </button>
                        <button mat-button type="button" class="icon-button" (click)="switchBetweenPDFandPeriodSections(); openTab(1)">
                          {{
                            (rightSideView$ | async) === 'periodSections' ? ('program_details.template' | translate) : ('program_details.overview' | translate)
                          }}
                          <mat-icon *ngIf="(rightSideView$ | async) !== 'periodSections'">edit</mat-icon>
                          <mat-icon *ngIf="(rightSideView$ | async) === 'periodSections'">visibility</mat-icon>
                        </button>
                        <button (click)="savePeriod(period)" mat-button type="button">
                          {{ 'generic.save' | translate }}
                        </button>
                      </div>
                    </app-program-period>
                  </ng-template>
                </mat-expansion-panel>
                <div class="period-activities" *ngIf="periodHasActivities(period) && periodIsSelected(period)">
                  <fieldset>
                    <legend>{{ 'program.activities.label' | translate }}</legend>
                    <mat-list>
                      <ng-container *ngFor="let activity of getPeriodSectionActivities(period); let isLast = last">
                        <ng-container *ngIf="activity">
                          <mat-list-item (click)="openPeriodSectionActivity(period.id, activity.id)" class="period-section-activity">
                            <mat-icon matListItemIcon>visibility</mat-icon>
                            <div matListItemTitle>{{ activity?.title ? activity.title : ('generic.activity' | translate) }}</div>
                          </mat-list-item>
                          <mat-divider *ngIf="!isLast"></mat-divider>
                        </ng-container>
                      </ng-container>
                    </mat-list>
                  </fieldset>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </app-panel-container>
      </form>
    </div>
  </ng-template>
  <ng-template #right>
    <div class="pdf-container" *ngIf="(rightSideView$ | async) === 'pdf'">
      <div class="document-content-container">
        <div *ngIf="pdfLoading$ | async" class="spinner-container">
          <mat-spinner></mat-spinner>
        </div>
        <ng2-pdfjs-viewer (onDocumentLoad)="onPdfLoadedEvent()" [attr.visible]="!(pdfLoading$ | async)" #pdfViewer></ng2-pdfjs-viewer>
      </div>
    </div>
    <app-program-period-sections
      #programSections
      [readOnly]="(isReadOnly$ | async)"
      [periodId$]="selectedPeriodId$"
      [periods$]="programPeriods$"
      [programId]="programId"
      [programLanguage]="(program$ | async)?.data?.language"
      (close)="setPanelToPdf()"
      *ngIf="(rightSideView$ | async) === 'periodSections'"
      (periodSectionsChanged)="updatePeriodActivities()"
      [excludedActivityIds$]="excludedActivityIds$"
    >
    </app-program-period-sections>
  </ng-template>
</app-split-page>
