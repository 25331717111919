import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DoenKidsPreferencesProvider {
  private INCLUDE_INCATIVE_USERS_KEY = 'includeInactiveUsers';

  private INCLUDE_INCATIVE_LOCATIONS_KEY = 'includeInactiveLocations';

  private SEARCH_ALL_ORGANIZATIONS = 'searchAllOrganizations';

  public showInactiveUsers$ = new BehaviorSubject(localStorage.getItem(this.INCLUDE_INCATIVE_USERS_KEY) === 'true');

  public showInactiveLocations$ = new BehaviorSubject(localStorage.getItem(this.INCLUDE_INCATIVE_LOCATIONS_KEY) === 'true');

  public searchInAllOrganizations$ = new BehaviorSubject(localStorage.getItem(this.SEARCH_ALL_ORGANIZATIONS) === 'true');

  public toggleShowInactiveUsers(toggle: boolean) {
    this.showInactiveUsers$.next(toggle);
    localStorage.setItem(this.INCLUDE_INCATIVE_USERS_KEY, `${toggle}`);
  }

  public toggleShowInactiveLocations(toggle: boolean) {
    this.showInactiveLocations$.next(toggle);
    localStorage.setItem(this.INCLUDE_INCATIVE_LOCATIONS_KEY, `${toggle}`);
  }

  public toggleSearchInAllOrganizations(toggle: boolean) {
    this.searchInAllOrganizations$.next(toggle);
    localStorage.setItem(this.SEARCH_ALL_ORGANIZATIONS, `${toggle}`);
  }
}
