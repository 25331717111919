export interface ISearchParams {
  limit: number;
  skip: number;
  query?: string;
};

export enum SearchType {
  Activity = 1,
  Customer = 2,
  Media = 3,
  User = 4,
  Program = 5,
}

export interface SearchResponse<T> {
  took: number;
  timed_out: boolean;
  _scroll_id?: string | undefined;
  _shards: {
    total: number;
    successful: number;
    failed: number;
    skipped: number;
  };
  hits: {
      total: number;
      max_score: number;
      hits: Array<{
          _index: string;
          _type: string;
          _id: string;
          _score: number;
          _source: T;
          _version?: number | undefined;
          _explanation?: Explanation | undefined;
          fields?: any;
          highlight?: any;
          inner_hits?: any;
          matched_queries?: string[] | undefined;
          sort?: string[] | undefined;
      }>;
  };
  aggregations?: any;
}

export interface Explanation {
  value: number;
  description: string;
  details: Explanation[];
}
