import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { AddNewPinbordFolderDialogComponent } from 'src/components/dialogs/add-new-pinbord-folder-dialog/add-new-pinbord-folder-dialog.component';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IFavoriteFolderDetails } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-pinbord-folder',
  templateUrl: './pinbord-folder.component.html',
  styleUrls: ['./pinbord-folder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PinbordFolderComponent implements OnDestroy {
  private stop$ = new Subject<void>();

  @Input()
    folder: IFavoriteFolderDetails;

  public isReader$: Observable<boolean>;

  constructor(
    private dialog: MatDialog,
    $session: DoenkidsSessionProvider,
  ) {
    this.isReader$ = $session.isReader$.pipe(takeUntil(this.stop$));
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async editFolder($event: Event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    const isReader = await firstValueFrom(this.isReader$);

    if (isReader) {
      return;
    }

    this.dialog.open(AddNewPinbordFolderDialogComponent, {
      data: {
        folder: this.folder,
      },
    });
  }
}
