<ng-container *ngVar="(newsItemSlides$ | async) as newsItemSlides">
  <ng-container *ngIf="newsItemSlides?.length > 0; else noData">
    <swiper-container dkSwiper [config]="swiperConfig$ | async" (reachEnd)="endReached()" init="false">
      <swiper-slide *ngFor="let slide of newsItemSlides; index as i; trackBy: trackByIndex" fxLayout="column">
        <div
          *ngFor="let item of slide.items; trackBy: trackById"
          [style.--i18n-newsfeed-item-unread]="'\'' + ('newsfeed.item.unread' | translate) + '\''"
          class="news-item"
          (click)="viewItem(item.id)"
          [class.unread]="!(readItems$ | async).includes(item.id)"
        >
          <app-doenkids-image [mediaItem]="{ uuid: item.media_uuid, description: '' }"></app-doenkids-image>
          <div class="text-overlay" fxLayout="column" fxLayoutAlign="space-between flex-start">
            <span class="date">
              {{ item.organization_unit_name }} •
              {{ (item.show_from | from_now: 'D MMMM YYYY' | async) }}
            </span
            >
            <div class="text">
              <div class="title">{{ item.subject }}</div>
              <span class="content" [innerHTML]="item.content | htmlToPlaintext"></span>
            </div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide *ngIf="(newsItemsLoading$ | async) && !(end$ | async)" fxLayoutAlign="center center" class="end swiper-slide">
        <div class="container">
          <mat-spinner diameter="48"></mat-spinner>
        </div>
      </swiper-slide>
      <swiper-slide *ngIf="(end$ | async) && newsItemSlides[newsItemSlides.length - 1]?.items?.length === 2" fxLayoutAlign="center center" class="end swiper-slide">
        <h3>{{ 'generic.end' | translate }}</h3>
      </swiper-slide>
    </swiper-container>
    <div class="swiper-pagination"></div>
  </ng-container>
</ng-container>

<ng-template #noData>
  <div class="container">
    <div class="no-data" fxLayoutAlign="center center" fxLayout="column">
      <span>{{ 'newsfeed.no_news' | translate }}</span>
    </div>
  </div>
</ng-template>
