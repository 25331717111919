import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { NewsItemService } from 'src/api/customer/news-item/news-item.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject, combineLatest, distinctUntilChanged, takeUntil } from 'rxjs';
import { PermissionProvider } from 'src/providers/permission.provider';
import { IActivityType } from 'typings/doenkids/doenkids';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-base-news-items',
  templateUrl: './base-news-items.component.html',
  styleUrls: ['./base-news-items.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseNewsItemsComponent implements OnInit {
  private stop$ = new Subject<void>();

  constructor(
    private $newsItem: NewsItemService,
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider) { }

  private _newsItems$ = new BehaviorSubject([]);

  public newsItems$ = this._newsItems$.asObservable().pipe(distinctUntilChanged(isEqual));

  ngOnInit() {
    combineLatest(
      [this.$session.availableActivityTypes$, this.$session.isRootOrganization$, this.$permission.hasNewsItemPermission$],
    ).pipe(takeUntil(this.stop$)).subscribe(([activityTypes, isRootOrganization, hasNewsPermission]) => {
      this.fetch(activityTypes, isRootOrganization, hasNewsPermission);
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async fetch(activityTypes: IActivityType[], isRootOrganization: boolean, hasNewsPermission: boolean) {
    const { items } = await this.$newsItem.fetchAll(1, 'ORGANIZATION_TREE', false);
    const organizationActivityTypIds = activityTypes.map((activityType) => `${activityType.id}`);
    let filteredItems = items;
    if (isRootOrganization && !hasNewsPermission) {
      filteredItems = items.filter((newsItem) => newsItem.activity_type_id.some((activityTypeId) => organizationActivityTypIds.includes(`${activityTypeId}`)));
    }
    this._newsItems$.next(filteredItems);
  }
}
