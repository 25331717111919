/* eslint-disable no-continue */
/* eslint-disable no-prototype-builtins */
import {
  Component, OnInit, Input, OnDestroy, ViewEncapsulation, Output, EventEmitter, OnChanges,
} from '@angular/core';
import { isNil, isEmpty, toArray } from 'lodash';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-search-chips',
  templateUrl: './search-chips.component.html',
  styleUrls: ['./search-chips.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchChipsComponent implements OnInit, OnChanges, OnDestroy {
  private stop$ = new Subject<void>();

  @Input() storageKey = 'activitySearch';

  @Input()
  public translationPrefix = 'activity';

  @Input() set aggregationData(aggregations: { [index: string]: any }) {
    this._aggregationData = this.mergeBuckets(aggregations);
  }

  get aggregationData() {
    return this._aggregationData;
  }

  private _aggregationData: any;

  @Input() set aggregationLabels(labels: { [index: string]: string }) {
    this._aggregationLabels = labels;

    if (isNil(labels)) {
      return;
    }

    this.setAggregationKeys(labels);
  }

  get aggregationLabels(): { [index: string]: string } {
    return this._aggregationLabels;
  }

  @Input() set selection(undedupedSelection: { [index: string]: string[] }) {
    this._dedupedSelection = this.undedupeSelection(undedupedSelection);
  }

  get selection() {
    return this._dedupedSelection;
  }

  private _dedupedSelection: any;

  @Output() public onRemove: EventEmitter<{ name: string, value: string }> = new EventEmitter();

  @Output() public onRemoveAll: EventEmitter<boolean> = new EventEmitter();

  private _aggregationLabels: { [index: string]: string };

  public aggregationKeys = [];

  public hasData$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnInit() {
    this.checkForSelectedData();
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  ngOnChanges() {
    this.checkForSelectedData();
  }

  private checkForSelectedData() {
    if (this.selection) {
      this.hasData$.next(!isEmpty(toArray(this.selection).filter((item) => !isEmpty(item))));
    }
  }

  /** Sets the aggregation keys for the template to loop over.
   * @private
   * @param {{ [index: string]: string }} labels The labels passed to this component.
   */
  private setAggregationKeys(labels: { [index: string]: string }) {
    // Get the keys for the aggregations based off the labels passed to the component.
    //
    this.aggregationKeys = Object.keys(labels).filter((key) => !this.aggregationKeys.hasOwnProperty(key));
  }

  public remove(value: string, key: string) {
    this.onRemove.emit({ name: key, value });
  }

  public removeAllFilters() {
    this.onRemoveAll.emit(true);
    // eslint-disable-next-line no-undef
    localStorage.removeItem(this.storageKey);
  }

  private undedupeSelection(undedupedSelection: { [index: string]: string[] }) {
    const dedupedSelection: any = {};

    Object.keys(undedupedSelection).forEach((aggregationKey: string) => {
      const aggregationValues: string[] = undedupedSelection[aggregationKey];

      const dedupedAggregationValues: string[] = [];

      aggregationValues.forEach((aggregationValue) => {
        if (!dedupedAggregationValues.includes(aggregationValue)) {
          dedupedAggregationValues.push(aggregationValue);
        }
      });

      dedupedSelection[aggregationKey] = dedupedAggregationValues;
    });

    return dedupedSelection;
  }

  private mergeBuckets(aggregationData: { [index: string]: any }) {
    const mergedAggregationData: any = {};

    if (aggregationData) {
      Object.keys(aggregationData).forEach((aggregationKey: string) => {
        if (aggregationKey !== 'tags') {
          mergedAggregationData[aggregationKey] = aggregationData[aggregationKey];
        } else {
          const ouTags = aggregationData[aggregationKey].ou?.tags?.buckets || [];
          const dkTags = aggregationData[aggregationKey].dk?.tags?.buckets || [];
          const combinedTags = [];

          for (const ouTag of ouTags) {
            if (!combinedTags.find((combinedTag) => combinedTag.key === ouTag.key)) {
              combinedTags.push(ouTag);
            }
          }

          for (const dkTag of dkTags) {
            if (!combinedTags.find((combinedTag) => combinedTag.key === dkTag.key)) {
              combinedTags.push(dkTag);
            }
          }

          mergedAggregationData[aggregationKey] = { buckets: combinedTags };
        }
      });
    }

    return mergedAggregationData;
  }
}
