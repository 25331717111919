import {
  Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  isEqual, isEmpty, omit, isFunction, defer,
} from 'lodash';
import {
  Observable, Subject, firstValueFrom,
} from 'rxjs';
import {
  auditTime, distinctUntilChanged, takeUntil, debounceTime,
} from 'rxjs/operators';
import { MediaSearchService } from 'src/api/search/media/media-search.service';
import { MediaSearchQuery } from 'src/api/search/media/media-search.query';
import { IMediaItem } from 'typings/section-types';
import { IMedia, ISearchMedia } from 'typings/doenkids/doenkids';
import { NgxMasonryComponent } from 'src/components/layout/ngx-masonry/ngx-masonry.component';
import { isNil } from '@datorama/akita';
import { IProgramActivityResponse } from 'typings/api-activity';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SearchType } from 'src/api/types';
import { IMediaSearchRequest } from 'typings/api-search';
import { ProgramActivitiesService } from 'src/api/activity/program-activities/program-activities.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { ArchiveMediaItemDialogComponent } from '../archive-media-item-dialog/archive-media-item-dialog.component';
import { AddMediaTagsDialogComponent } from '../add-media-tags-dialog/add-media-tags-dialog.component';

export interface ISearchResultDialogData {
  showSearchInput: boolean;
  programId?: number;
  purpose?: string;
  organizationUnitId?: number;
}

interface IImageSearchResultTab {
  title: string;
  templateName: string;
}

@Component({
  selector: 'app-image-search-result',
  templateUrl: './image-search-result.component.html',
  styleUrls: ['./image-search-result.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageSearchDialogComponent implements OnInit, OnDestroy {
  private scrollContainer: any;

  public searchResults$: Observable<any[]>;

  public loading$: Observable<boolean>;

  public aggregations$: Observable<any>;

  public aggregations;

  public searchForm: UntypedFormGroup;

  public queryParams: IMediaSearchRequest = { limit: 40, skip: 0, query: '' };

  public selectedImage$: Subject<IMediaItem> = new Subject<IMediaItem>();

  private stop$ = new Subject();

  private imagesLoaded$ = new Subject();

  private organizationUnitId: number;

  public searchType = SearchType.Media;

  public tabs: IImageSearchResultTab[];

  public programMediaIds: string[] = [];

  public currentTabSelected = 0;

  public allowedToEdit$: Observable<boolean>;

  public tabIndexes = {
    mediaSearch: 1,
    programMedia: 0,
  };

  @ViewChild(NgxMasonryComponent) masonry;

  updateMasonryLayout() {
    if (this.masonry) {
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  constructor( // TODO: Correct the query and service.
    private formBuilder: UntypedFormBuilder,
    private mediaSearchQuery: MediaSearchQuery,
    private mediaSearchService: MediaSearchService,
    private activeRoute: ActivatedRoute,
    private $permissions: PermissionProvider,
    private router: Router,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private programActivitiesService: ProgramActivitiesService,
    private $translateService: TranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: ISearchResultDialogData,
  ) {
    this.searchResults$ = this.mediaSearchQuery.selectAll();
    this.loading$ = this.mediaSearchQuery.selectLoading();
    this.aggregations$ = this.mediaSearchQuery.getAggregations;

    this.searchForm = this.formBuilder.group({
      label: [[]],
      purpose: [[]],
      mime_type: [[]],
      media_tags: [[]],
      activity_media_tags: [[]],
      query: [''],
    });

    this.allowedToEdit$ = this.$permissions.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$;

    this.tabs = [];

    // Check if there is a purpose provided
    //
    if (dialogData && dialogData.purpose) {
      this.searchForm.get('purpose').setValue([dialogData.purpose]);
    }

    if (dialogData.organizationUnitId) {
      this.organizationUnitId = dialogData.organizationUnitId;
    }

    // if there is a program id passed we should have as first tab program media
    //
    if (dialogData.programId) {
      this.tabs.push({
        title: this.$translateService.instant(_('image_search.tab.program_images')),
        templateName: 'programMedia',
      });
    }

    this.tabs.push({
      title: this.$translateService.instant(_('image_search.tab.media')),
      templateName: 'mediaSearch',
    });
  }

  ngOnInit() {
    this.mediaSearchService.setLoading(false);

    this.searchForm.valueChanges.pipe(
      distinctUntilChanged(isEqual),
      takeUntil(this.stop$),
      debounceTime(300),
    ).subscribe(async (aggregations) => {
      this.fetchImages({
        ...this.queryParams,
        limit: 50,
        skip: 0,
        filter: omit(aggregations, 'query'),
        query: aggregations.query,
      }, false);
    });

    this.imagesLoaded$.pipe(
      takeUntil(this.stop$),
      auditTime(300),
    ).subscribe(() => {
      this.updateMasonryLayout();
    });

    this.searchForm.updateValueAndValidity();

    if (this.dialogData.programId) {
      this.fetchProgramMedia(this.dialogData.programId);
    }
  }

  async fetchImages(queryParams: IMediaSearchRequest, concat: boolean) {
    if (!queryParams && !isNil(this.scrollContainer) && isFunction(this.scrollContainer.scroll)) {
      this.scrollContainer.scroll(0, 0);
    }

    this.queryParams = queryParams;

    if (this.organizationUnitId) {
      this.queryParams.organizationUnitId = this.organizationUnitId;
    }
    return this.mediaSearchService.fetch(queryParams, concat);
  }

  async fetchProgramMedia(programId: number) {
    this.programMediaIds = [];
    this.programActivitiesService.fetch(programId).then((activities: IProgramActivityResponse[]) => {
      if (!isEmpty(activities)) {
        activities.forEach(async (activity: IProgramActivityResponse) => {
          if (activity.media_uuid) {
            this.programMediaIds.push(activity.media_uuid);
          }
        });
      }
    });
  }

  async deleteMediaItem(mediaItem: ISearchMedia) {
    const dialogRef = this.dialog.open(ArchiveMediaItemDialogComponent, {
      width: '100%',
      maxWidth: '80%',
      minWidth: '320px',
      data: { item: mediaItem },
    });

    const isRemoved = await firstValueFrom(dialogRef.afterClosed());
    if (isRemoved) {
      const element = this.elementRef.nativeElement.querySelector(`#media${mediaItem.uuid}`);
      if (element) {
        element.remove();
        defer(() => {
          this.updateMasonryLayout();
        });
      }
    }
  }

  async detailsOfMediaItem(mediaItem: ISearchMedia) {
    this.dialog.open(AddMediaTagsDialogComponent, {
      width: '600px',
      minWidth: '400px',
      data: {
        mediaItem,
      },
    });
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  imageLoaded() {
    this.imagesLoaded$.next(undefined);
  }

  scrollEndReached(elem) {
    if (isNil(this.scrollContainer)) {
      this.scrollContainer = elem;
    }
    this.loadMoreImages();
  }

  public async loadMoreImages() {
    const offset = this.queryParams.skip + this.queryParams.limit;

    const { total } = (await firstValueFrom(this.mediaSearchQuery.getMetadata));

    if (offset < total) {
      this.fetchImages({ ...this.queryParams, skip: offset }, true);
    }
  }

  imageSelected(media: IMedia | string) {
    const currentUrl = this.activeRoute.snapshot.url.join('');

    const mediaItem: IMediaItem = {
      uuid: '',
      description: '',
    };

    if (typeof (media) === 'string') {
      mediaItem.uuid = media;
    } else if (media) {
      mediaItem.uuid = media.uuid;
    }

    // When we've landed on media search we redirect to the details
    //
    if (currentUrl === 'media-search' && typeof (media) !== 'string') {
      this.router.navigate([`/media/${media.id}`]);
    }
    this.selectedImage$.next(mediaItem);
  }

  tabChanged($event: MatTabChangeEvent) {
    this.currentTabSelected = $event.index;
  }
}
