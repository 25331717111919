<app-doenkids-menu-bar></app-doenkids-menu-bar>
<div class="page-container" #pageContainer *ngVar="(currentFolders$ | async) as folder">
  <h2>{{ 'pinboard.title' | translate: {organization: currentOuName$ | async} }}</h2>
  <p>{{ 'pinboard.description' | translate }}</p>
  <p *ngIf="folder?.folders.length === 0">{{ 'pinboard.no_folders' | translate }}</p>
  <div class="folders" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
    <div
      *ngIf="!(isReader$ | async)"
      class="image-panel"
      id="new-pinbord-button"
      role="button"
      fxFlex
      fxLayoutAlign="center center"
      (click)="addNewFolder()"
      [matTooltip]="'pinboard.folder.add.tooltip' | translate"
    >
      <mat-icon>create_new_folder</mat-icon>
    </div>
    <ng-container *ngIf="folder?.folders?.length > 0">
      <app-pinbord-folder
        (click)="goToFolder(fav)"
        [folder]="fav"
        *ngFor="let fav of (folder?.folders ?? []) | paginate
        : {
            id: 'pinbord-pagination-controls',
            itemsPerPage: MAX_ITEMS_PER_PAGE,
            currentPage: currentPage,
            totalItems: folder?.folders?.length ?? 0
          }"
      ></app-pinbord-folder>
    </ng-container>
  </div>
  <pagination-controls
    *ngIf="folder?.totalNoOfFolders > MAX_ITEMS_PER_PAGE"
    id="pinbord-pagination-controls"
    [nextLabel]="'generic.pagination.next' | translate"
    [previousLabel]="'generic.pagination.previous' | translate"
    (pageChange)="paginate($event)"
  ></pagination-controls>
</div>
