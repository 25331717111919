import {
  Component, Output, EventEmitter, Input, ViewEncapsulation, OnChanges, SimpleChanges, OnInit, ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom } from 'rxjs';
import { fadeInOut } from 'src/animations';
import { ISearchActivity } from 'typings/doenkids/doenkids';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { OrganizationUnitListService } from 'src/api/customer/organization-unit-list/organization-unit-list.service';
import { UserListService } from 'src/api/customer/users-list/user-list.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActivityHighLightService } from 'src/api/activity/activity-highlight/activity-highlight.service';
import { MatDialog } from '@angular/material/dialog';
import {
  AddActivityToPinbordFolderDialogComponent,
} from 'src/components/dialogs/add-activity-to-pinbord-folder-dialog/add-activity-to-pinbord-folder-dialog.component';
import { PinbordProvider } from 'src/providers/pinbord.provider';
import { MatMenuTrigger } from '@angular/material/menu';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  RemoveActivityFromPinbordFolderDialogComponent,
} from 'src/components/dialogs/remove-activity-from-pinbord-folder-dialog/remove-activity-from-pinbord-folder-dialog.component';
import { isNil } from 'lodash';

type ActivityAction = 'pin' | 'unpin' | 'highlight' | 'unHighlight';
_('activity.card.action.pin');
_('activity.card.action.unpin');
_('activity.card.action.highlight');
_('activity.card.action.unHighlight');

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut],
})
export class ActivityCardComponent implements OnInit, OnChanges {
  private stop$ = new Subject<void>();

  private _activity: ISearchActivity;

  @Input() set activity(activity: ISearchActivity) {
    this._activity = activity;
    this.activity$.next(activity);
  }

  @ViewChild(MatMenuTrigger) actionsMenu: MatMenuTrigger;

  get activity(): ISearchActivity {
    return this._activity;
  }

  private activity$ = new BehaviorSubject<ISearchActivity>(null);

  public isOrganizationOfTypeLocation$: Observable<boolean>;

  public isOrganizationOfTypeGroup$: Observable<boolean>;

  public currentOUId$: Observable<number>;

  public currentOUNodePath$: Observable<number[]>;

  public isAdmin$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public hasOUWritePermission$: Observable<boolean>;

  public isOnAPinbord = false;

  public activityActions$ = new BehaviorSubject<ActivityAction[]>([]);

  public isReader$: Observable<boolean>;

  public activityIsHighlighted$: Observable<boolean>;

  public activityIsHighlighedByCurrentOU$: Observable<boolean>;

  public activityHighlightingIsEnabled$: Observable<boolean>;

  /**
   * The name of the user that originally created the activity
   *
   */
  public activityCreatedByUser = '';

  /**
   * The name of the organization unit that originally created the activity
   *
   */
  public activityCreatedByOU = '';

  public shouldShowCountryFlag$: Observable<boolean>;

  public shouldShowActivityIsCopy$: Observable<boolean>;

  @Output() loaded = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private $session: DoenkidsSessionProvider,
    private $permission: PermissionProvider,
    private organizationUnitListService: OrganizationUnitListService,
    private userListService: UserListService,
    private $activityHighLight: ActivityHighLightService,
    private $pinbord: PinbordProvider,
  ) {
    this.isOrganizationOfTypeLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));
    this.isOrganizationOfTypeGroup$ = this.$session.isCurrentOrganizationUnitIsOfTypeGroup$.pipe(takeUntil(this.stop$));
    this.currentOUId$ = this.$session.currentOuId$.pipe(takeUntil(this.stop$));
    this.currentOUNodePath$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$), map((ou) => ou.node_path.map((node) => parseInt(`${node}`, 10))));
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));
    this.hasOUWritePermission$ = this.$permission.hasOUWritePermissions$.pipe(takeUntil(this.stop$));

    this.activityIsHighlighted$ = combineLatest([this.activity$, this.currentOUNodePath$]).pipe(takeUntil(this.stop$), map(([activity, nodePath]) =>
      activity?.highlighted_organization_unit_id?.some((highlightOuId) => nodePath.includes(highlightOuId)) ?? false,
    ));

    this.activityIsHighlighedByCurrentOU$ = combineLatest([this.activity$, this.currentOUId$]).pipe(takeUntil(this.stop$), map(([activity, currentOuId]) =>
      activity?.highlighted_organization_unit_id?.includes(currentOuId) ?? false,
    ));

    this.activityHighlightingIsEnabled$ = combineLatest([this.isAdmin$, this.hasOUWritePermission$, this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$, this.isReader$]).pipe(
      takeUntil(this.stop$),
      map(([isAdmin, hasOUWritePermission, hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree, isReader]) =>
        isAdmin || (!isReader && hasOUWritePermission && hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree),
      ),
    );

    this.shouldShowCountryFlag$ = this.$session.ouLanguages$.pipe(
      takeUntil(this.stop$),
      map((languages) => languages.length > 1),
    );

    this.shouldShowActivityIsCopy$ = combineLatest([this.isOrganizationOfTypeLocation$, this.isOrganizationOfTypeGroup$, this.activity$]).pipe(
      takeUntil(this.stop$),
      filter(([isLocation, isGroup, activityDetails]) => !isNil(isLocation) && !isNil(isGroup) && !isNil(activityDetails)),
      map(([isLocation, isGroup, activityDetails]) => {
        return !((isLocation || isGroup)) &&
          (!isNil(activityDetails.replace_activity_id) && !isNil(activityDetails.replaced_activity_country_code)
          && activityDetails.replaced_activity_country_code.toLowerCase() === activityDetails.country_code.toLowerCase());
      }),
    );
  }

  async ngOnInit() {
    if (this.activity) {
      this.setActivityCreatedByValues(this.activity);
      await this.setIsOnPinbord(this.activity);
      this.setHighlightActions();
      await this.setPinbordActions();
    }

    this.$pinbord.currentFolders$.pipe(takeUntil(this.stop$)).subscribe(async () => {
      await this.setIsOnPinbord();
      await this.setPinbordActions();
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.activity && !changes.activity.firstChange) {
      this.setActivityCreatedByValues(changes.activity.currentValue);
      await this.setIsOnPinbord(changes.activity.currentValue);
      this.setHighlightActions();
      await this.setPinbordActions();
    }
  }

  imageLoaded() {
    this.loaded.next();
  }

  async setActivityCreatedByValues(activity: ISearchActivity) {
    if (activity.status_id === 2) {
      if (activity.created_by_organization_unit_id) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const organization = await this.organizationUnitListService.fetch(activity.created_by_organization_unit_id);

          this.activityCreatedByOU = organization.name;
        } catch (errorResponse) {
          // There was an error fetching the ou but since its not critical to show this we supress the error and set the name to blank to be sure
          //
          this.activityCreatedByOU = '';
        }
      }

      if (activity.created_by_user_id) {
        // fetch user
        try {
          // eslint-disable-next-line no-await-in-loop
          const user = await this.userListService.fetch(activity.created_by_user_id);

          this.activityCreatedByUser = user.email;
        } catch (errorResponse) {
          // There was an error fetching the user but since its not critical to show this we supress the error and set the name to blank to be sure
          //
          this.activityCreatedByUser = '';
        }
      }
    } else {
      this.activityCreatedByUser = '';
      this.activityCreatedByOU = '';
    }
  }

  async setPinbordActions() {
    const ouIsLocation = await firstValueFrom(this.isOrganizationOfTypeLocation$);

    if (!ouIsLocation) {
      return;
    }
    const isReader = await firstValueFrom(this.isReader$);
    const currentFoldersLength = ((await firstValueFrom(this.$pinbord.currentFolders$))?.folders ?? []).length;
    const currentActions: ActivityAction[] = [...this.activityActions$.value];
    const newActions = currentActions.filter((action) => !['pin', 'unpin'].includes(action));

    if (isReader) {
      this.activityActions$.next(newActions);
      return;
    }

    if (this.isOnAPinbord) {
      newActions.unshift('unpin');
      if (currentFoldersLength > 1) {
        newActions.unshift('pin');
      }
    } else {
      newActions.unshift('pin');
    }

    this.activityActions$.next(newActions);
  }

  async setIsOnPinbord(activity?: ISearchActivity) {
    const currentFolders = (await firstValueFrom(this.$pinbord.currentFolders$))?.folders ?? [];
    const activityToFind = activity ?? this.activity;

    if (currentFolders.find((folder) => folder.activities.find((folderActivity) => folderActivity?.id === activityToFind.id))) {
      this.isOnAPinbord = true;
    } else {
      this.isOnAPinbord = false;
    }
  }

  async setHighlightActions() {
    const activityHighlightingIsEnabled = await firstValueFrom(this.activityHighlightingIsEnabled$);
    const currentActions = [...this.activityActions$.value];
    const newActions = currentActions.filter((action) => !['hightlight', 'unHighlight'].includes(action));

    if (!activityHighlightingIsEnabled) {
      this.activityActions$.next(newActions);
      return;
    }

    const activityIsHighLighted = await firstValueFrom(this.activityIsHighlighted$);
    const activityIsHighlightedByCurrentOU = await firstValueFrom(this.activityIsHighlighedByCurrentOU$);

    if (activityIsHighLighted && activityIsHighlightedByCurrentOU) {
      newActions.push('unHighlight');
    }

    if (!activityIsHighLighted) {
      newActions.push('highlight');
    }

    this.activityActions$.next(newActions);
  }

  openActionsMenu($event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.actionsMenu) {
      this.actionsMenu.openMenu();
    }
  }

  executeActivityAction(activityAction: ActivityAction) {
    switch (activityAction) {
      case 'pin':
        this.placeActivityOnPinbord();
        break;
      case 'unpin':
        this.removeActivityFromPinbord();
        break;
      case 'highlight':
        this.highlightActivity();
        break;
      case 'unHighlight':
        this.unHighlightActivity();
        break;
      default:
        break;
    }
  }

  async highlightActivity() {
    try {
      const currentOuId = await firstValueFrom(this.currentOUId$);
      await this.$activityHighLight.update(currentOuId, this.activity.id);
      if (!this.activity.highlighted_organization_unit_id) {
        this.activity.highlighted_organization_unit_id = [];
      }
      this.activity.highlighted_organization_unit_id.push(currentOuId);

      this.setHighlightActions();
    } catch (e) {
      // dont do anything yet
    }
  }

  async unHighlightActivity() {
    try {
      const currentOuId = await firstValueFrom(this.currentOUId$);
      await this.$activityHighLight.remove(currentOuId, this.activity.id);
      const ouIndex = this.activity.highlighted_organization_unit_id.indexOf(currentOuId);

      if (ouIndex > -1) {
        (this.activity as any).highlighted_organization_unit_id.splice(ouIndex, 1);
      }

      this.setHighlightActions();
    } catch (e) {
      // dont do anything yet
    }
  }

  async placeActivityOnPinbord() {
    this.dialog.open(AddActivityToPinbordFolderDialogComponent, {
      data: {
        activities: [this.activity],
      },
    });
  }

  async removeActivityFromPinbord() {
    this.dialog.open(RemoveActivityFromPinbordFolderDialogComponent, {
      data: {
        activity: this.activity,
      },
    });
  }
}
