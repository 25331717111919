<div class="overlay">
  <div class="image-panel">
    <div class="image-layout">
      <div class="image-col-1">
        <div *ngIf="folder.activities && folder.activities[0]" class="relative">
          <app-doenkids-image [mediaItem]="{ uuid: folder.activities[0].media_uuid , description: '' }"></app-doenkids-image>
        </div>
      </div>
      <div class="image-col-2">
        <div class="image-row">
          <div *ngIf="folder.activities && folder.activities[1]" class="relative">
            <app-doenkids-image [mediaItem]="{ uuid: folder.activities[1].media_uuid , description: '' }"></app-doenkids-image>
          </div>
        </div>
        <div class="image-row">
          <div *ngIf="folder.activities && folder.activities[2]" class="relative">
            <app-doenkids-image [mediaItem]="{ uuid: folder.activities[2].media_uuid , description: '' }"></app-doenkids-image>
          </div>
        </div>
      </div>
    </div>
    <button mat-mini-fab *ngIf="!(isReader$ | async)" class="edit-icon" color="primary" [attr.aria-label]="'generic.edit' | translate" (click)="editFolder($event)">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div class="title-panel">
    <h3 class="mat-title">{{folder.name}}</h3>
    <div class="mat-h5">{{ 'pinboard.folder.activity_count' | translate: {activityCount: folder?.activities?.length || 0} }}</div>
  </div>
</div>
