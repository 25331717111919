<h3 mat-dialog-title>{{ 'dialog.add_organization_customer_symbol.title' | translate: {organization: data.organizationUnitName} }}</h3>
<mat-dialog-content>
  <form [formGroup]="newSymbolForm">
    <div fxLayout="column" fxLayoutGap="20px">
      <app-asset-upload class="image" identifier="symbol-image" purpose="symbol" (uploaded)="addSymbolImage($event)">
        <ng-template let-uploadData="uploadData">
            <div class="image-container">
              <app-doenkids-image class="image" [maxWidth]="100" [maxHeight]="100" [mediaItem]="newSymbolForm.get('media_uuid').value"></app-doenkids-image>
            </div>
            <app-select-media identifier="symbol-image" [uploadData]="uploadData" purpose="symbol" (selected)="addSymbolImage($event)"></app-select-media>
        </ng-template>
      </app-asset-upload>
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-around start" >
        <mat-form-field appearance="outline">
          <mat-label>{{ 'customer_symbol.name.label' | translate }}</mat-label>
          <input formControlName="name" matInput>
          <mat-error *ngIf="newSymbolForm.get('name')?.invalid">
            {{ 'generic.form.required_field' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'customer_symbol.activity_type.label' | translate }}</mat-label>
          <mat-select formControlName="activity_type_id">
            <mat-option [value]="null">
              {{ 'dialog.add_organization_customer_symbol.all_types' | translate }}
            </mat-option>
            <ng-container *ngFor="let type of (activityTypes$ | async)">
              <mat-option [value]="type.id">
                {{ type.name }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button (click)="createNewSymbol()" [disabled]="!newSymbolForm.valid" mat-button>{{ 'generic.save' | translate }}</button>
</mat-dialog-actions>
