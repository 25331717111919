/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IKvsMethod, IKvsObservationPointDetails } from 'typings/doenkids/doenkids.d';

export interface IKvsMethodWithObservationPoints extends IKvsMethod {
  subsections: IKvsAreaOfDevelopmentSubsection[];
  min_age_months: number;
  max_age_months: number;
}

export interface IKvsAreaOfDevelopmentSubsection {
  name: string;
  code?: string;
  min_age_months: number;
  max_age_months: number;
  subsections?: IKvsAreaOfDevelopmentSubsection[];
  observationPoints?: IKvsObservationPointDetails[];
}

export interface IKvsMethodState extends EntityState<IKvsMethodWithObservationPoints> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'kvs-method' })
export class KvsMethodStore extends EntityStore<IKvsMethodState, IKvsMethodWithObservationPoints> {
  constructor() {
    super();
  }
}
