<ng-container *ngIf="tabs.length > 1">
  <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="start">
    <ng-container *ngFor="let tab of tabs">
      <mat-tab [label]="tab.title">
        <ng-container *ngIf="tab.templateName === 'mediaSearch'">
          <ng-container [ngTemplateOutlet]="mediaSearch"></ng-container>
        </ng-container>
        <ng-container *ngIf="tab.templateName === 'programMedia'">
          <ng-container [ngTemplateOutlet]="programMedia"></ng-container>
        </ng-container>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-container>
<ng-container *ngIf="tabs.length === 1">
  <ng-container [ngTemplateOutlet]="mediaSearch"></ng-container>
</ng-container>

<ng-template #mediaSearch>
  <div class="page-container">
    <ng-container *ngIf="dialogData?.showSearchInput === true && searchType !== undefined">
      <form [formGroup]="searchForm">
        <mat-form-field class="filter-media" appearance="outline">
          <mat-label>{{ 'generic.search' | translate }}</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input formControlName="query" matInput [placeholder]="'image_search.search.placeholder' | translate">
        </mat-form-field>
      </form>
    </ng-container>
    <div class="media-search-container">
      <ng-container *ngIf="( searchResults$ | async ).length > 0">
        <div class="aggregations" *ngIf="( aggregations$ | async ) as aggregations">
          <form [formGroup]="searchForm">
            <div class="filter" *ngIf="aggregations.media_tags && aggregations.media_tags.buckets.length > 0">
              <h4>{{ 'image_search.image_tags.title' | translate }}</h4>
              <mat-selection-list #tag formControlName="media_tags">
                <ng-container *ngFor="let option of aggregations.media_tags.buckets">
                  <mat-list-option *ngIf="option.key" [value]="option.key"
                    [selected]="searchForm.get('media_tags').value.includes(option.key)">
                    <span class="option-media_tags">{{option.key}}&nbsp;<span
                        class="count">({{option.doc_count}})</span></span>
                  </mat-list-option>
                </ng-container>
              </mat-selection-list>
            </div>
            <div class="filter" *ngIf="aggregations.activity_media_tags && aggregations.activity_media_tags.buckets.length > 0">
              <h4>{{ 'image_search.activity_tags.title' | translate }}</h4>
              <mat-selection-list #tag formControlName="activity_media_tags">
                <ng-container *ngFor="let option of aggregations.activity_media_tags.buckets">
                  <mat-list-option *ngIf="option.key" [value]="option.key"
                    [selected]="searchForm.get('activity_media_tags').value.includes(option.key)">
                    <span class="option-activity_media_tags">{{option.key}}&nbsp;<span
                        class="count">({{option.doc_count}})</span></span>
                  </mat-list-option>
                </ng-container>
              </mat-selection-list>
            </div>
            <div class="filter" *ngIf="aggregations.mime_type && aggregations.mime_type.buckets.length > 0">
              <h4>{{ 'image_search.type.title' | translate }}</h4>
              <mat-selection-list #tag formControlName="mime_type">
                <ng-container *ngFor="let option of aggregations.mime_type.buckets ">
                  <mat-list-option *ngIf="option.key && option.key === 'image/gif'"  [value]="option.key"
                    [selected]="searchForm.get('mime_type').value.includes(option.key)">
                    <span class="option-mime_type">GIF's&nbsp;<span
                        class="count">({{option.doc_count}})</span></span>
                  </mat-list-option>
                </ng-container>
              </mat-selection-list>
            </div>
          </form>
        </div>
      </ng-container>

      <ng-container>
        <div scrollEnd #scrollContainer (endReached)="scrollEndReached(scrollContainer)" class="search-results">
          <ng-container *ngIf="(searchResults$ | async) as results">
            <ng-container *ngIf="(tabs.length === 1) || (currentTabSelected === tabIndexes.mediaSearch)"
              [ngTemplateOutlet]="resultsTemplate" [ngTemplateOutletContext]="{ results: results }"></ng-container>
          </ng-container>
          <div class="spinner-container" *ngIf="(loading$ | async)">
            <mat-spinner>
            </mat-spinner>
          </div>
          <div *ngIf="(searchResults$ | async).length === 0" class="not-found">
            {{ 'image_search.no_results' | translate }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #programMedia>
  <div class="program-media-container">
    <div class="search-results">
      <ng-container *ngIf="programMediaIds.length === 0">
        {{ 'image_search.no_results_for_program' | translate }}
      </ng-container>
      <ng-container *ngIf="programMediaIds.length > 0">
        <ng-container *ngIf="(tabs.length !== 1) && (currentTabSelected === tabIndexes.programMedia)"
          [ngTemplateOutlet]="resultsTemplate" [ngTemplateOutletContext]="{ results: programMediaIds }"></ng-container>
      </ng-container>
    </div>
  </div>

</ng-template>

<ng-template #resultsTemplate let-results="results">
  <ngx-masonry id="masonry" [options]="{ gutter: 10, transitionDuration: '0', fitWidth: true, horizontalOrder: true }" [updateLayout]="true">
    <ngxMasonryItem [id]="'media' + image?.uuid" *ngFor="let image of results; let index = index" class="masonry-item animated fadeIn">
      <button
        *ngIf="(allowedToEdit$ | async)"
        class="media-tag-menu"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [attr.aria-label]="'image_search.media_options.label' | translate">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="detailsOfMediaItem(image)" mat-menu-item>
          <mat-icon>remove_red_eye</mat-icon>
          <span>{{ 'image_search.details.show' | translate }}</span>
        </button>
        <button (click)="deleteMediaItem(image)" mat-menu-item>
          <mat-icon>close</mat-icon>
          <span>{{ 'generic.archive' | translate }}</span>
        </button>
      </mat-menu>
      <app-doenkids-image (click)="imageSelected(image)" [maxWidth]="200" [maxHeight]="400" (loaded)="imageLoaded()" [preview]="!image.mime_type || image.mime_type !== 'image/gif'"
        [mediaItem]="image"></app-doenkids-image>
    </ngxMasonryItem>
  </ngx-masonry>
</ng-template>
