import { NewsItemService } from 'src/api/customer/news-item/news-item.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BehaviorSubject, Subject, firstValueFrom,
} from 'rxjs';
import { I18nTitleStrategy } from '../../../app/utils/i18n-title-strategy';
import { INewsItemDetails } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-news-item-view',
  templateUrl: './news-item-view.component.html',
  styleUrls: ['./news-item-view.component.scss'],
})
export class NewsItemViewComponent implements OnInit, OnDestroy {
  public newsItem$ = new BehaviorSubject<INewsItemDetails>(null);

  public isLoading$ = new BehaviorSubject(false);

  private stop$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private $newsItem: NewsItemService,
    private readonly $i18nTitleStrategy: I18nTitleStrategy,
  ) {}

  ngOnInit() {
    this.fetchItem();
  }

  ngOnDestroy() {
    this.stop$.next(undefined);
  }

  async fetchItem() {
    const { id } = await firstValueFrom(this.route.params);

    if (!id) {
      return;
    }

    const item = await this.$newsItem.fetch(id);

    this.isLoading$.next(true);

    if (item) {
      this.$i18nTitleStrategy.updateTitleParameters({ newsItem: item.subject });
      this.newsItem$.next(item);
    }
    this.isLoading$.next(false);
  }
}
