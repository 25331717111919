<div class="expansion-container" [class.expanded]="expanded" [@bounceUp]="( selectedActivities$ | async )">
  <div class="expansion-header" (click)="toggleContainer()">
    <mat-icon *ngIf="expanded">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="!expanded">keyboard_arrow_up</mat-icon>
    {{ 'selected_activities.collect_activities' | translate: {activityCount: noOfSelectedActivities$ | async} }}
  </div>
  <div class="expansion-body" #activityLines>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button
        class="add-button"
        [class.multiple-buttons]="!addToExistingProgram && (isOrganizationOfTypeLocation$ | async)"
        mat-button
        color="primary"
        [disabled]="(noOfSelectedActivities$ | async) === 0"
        (click)="createProgram()"
      >
        {{ addToExistingProgram
          ? ('selected_activities.add_to_existing_program' | translate)
          : ('selected_activities.create_program' | translate) }}
      </button>
      <button
        *ngIf="!addToExistingProgram && (isOrganizationOfTypeLocation$ | async)"
        class="add-button multiple-buttons"
        mat-button color="primary"
        [disabled]="(noOfSelectedActivities$ | async) === 0"
        (click)="addToPinbordFolder()"
      >
        {{ 'selected_activities.add_to_pinboard' | translate }}
      </button>
    </div>
    <mat-selection-list @slideUpDown>
      <ng-container *ngFor="let activity of selectedActivities$ | async">
        <mat-list-option *ngIf="activity" (click)="removeActivity( activity )" [selected]="true" checkboxPosition="after">
          <app-doenkids-image matListItemAvatar [maxWidth]="40" [maxHeight]="40" [preview]="true"
            [mediaItem]="{ uuid: activity.media_uuid , description: '' }"></app-doenkids-image>
          <h2 matListItemTitle>
            {{ activity.name !== '' ? activity.name :  ('generic.new' | translate) }}
          </h2>
          <p matListItemLine>{{activity.summary}}</p>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </div>
</div>
