import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';
export interface ISearchData {
  name: string;
  routerLink: string;
  service: any;
}


@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnDestroy, OnInit, OnChanges {
  private stop$: Subject<void> = new Subject<void>();

  @Input() query: string;

  @Output() searched: EventEmitter<string> = new EventEmitter<string>();

  public searchControl: UntypedFormControl;

  constructor(
    public sessionProvider: DoenkidsSessionProvider,
  ) {
    this.searchControl = new UntypedFormControl('');

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this.stop$)).subscribe((newQuery: string) => {
      this.doSearch(newQuery);
      this.query = newQuery;
    });
  }

  ngOnInit(): void {
    // if we already have query (for example from a stored search) set this value to the search control
    //
    if (this.query !== '') {
      this.searchControl.setValue(this.query);
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentQuery = get(changes, 'query.currentValue');

    if (this.searchControl.value !== currentQuery) {
      this.searchControl.setValue(currentQuery);
    }
  }

  doSearch(query: string) {
    this.searched.emit(query);
  }

  public clearSearch() {
    this.searchControl.setValue('');
    this.doSearch('');
  }
}
