/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Component, forwardRef, ExistingProvider, HostListener, Input, Output, EventEmitter, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy,
} from '@angular/core';
import {
  UntypedFormGroup, UntypedFormBuilder, NG_VALUE_ACCESSOR, ControlValueAccessor, ValidationErrors, NG_ASYNC_VALIDATORS, AsyncValidator,
} from '@angular/forms';
import {
  noop, get, isNil, size, isEqual,
  uniq,
} from 'lodash';
import { ICustomerSymbol } from 'typings/doenkids/doenkids';

import {
  map, distinctUntilChanged, filter, mergeMap, takeUntil,
} from 'rxjs/operators';
import { IActivity } from 'typings/period-section-types';
import {
  Observable, of, merge, combineLatest, firstValueFrom, Subject,
} from 'rxjs';
import { DoenkidsTemplateProvider } from 'src/providers/doenkids-template.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { OrganizationUnitSymbolListQuery } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.query';
import { PermissionProvider } from 'src/providers/permission.provider';
import { IUploadResponse } from 'typings/custom-app-types';
import { IMediaItem } from 'typings/section-types';

export const PERIOD_ACTIVITY_SECTION_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PeriodActivitySectionComponent),
  multi: true,
};

export const PERIOD_ACTIVITY_SECTION_VALIDATOR = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => PeriodActivitySectionComponent),
  multi: true,
};

@Component({
  selector: 'app-period-activity-section',
  templateUrl: './period-activity-section.component.html',
  styleUrls: ['./period-activity-section.component.scss'],
  providers: [PERIOD_ACTIVITY_SECTION_CONTROL_VALUE_ACCESSOR, PERIOD_ACTIVITY_SECTION_VALIDATOR],
  encapsulation: ViewEncapsulation.None,
})
export class PeriodActivitySectionComponent implements ControlValueAccessor, AsyncValidator, OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  @Input() sectionId: number;

  @Output() uploaded = new EventEmitter<IMediaItem>();

  @Output() selected = new EventEmitter<IMediaItem>();

  @Input() customerSymbols$: Observable<ICustomerSymbol[]>;

  public form: UntypedFormGroup;

  public periodSymbols$: Observable<ICustomerSymbol[]>;

  public symbols$: Observable<ICustomerSymbol[]>;

  public hasTemplateOptionsPermission$: Observable<boolean>;

  public hasCustomerSymbolsPermission$: Observable<boolean>;

  public disableActivityImage$: Observable<boolean>;

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  public isAdmin$: Observable<boolean>;

  constructor(
    fb: UntypedFormBuilder,
    private $doenkidsTemplate: DoenkidsTemplateProvider,
    private customerSymbols: OrganizationUnitSymbolListQuery,
    private $permission: PermissionProvider,
    private $session: DoenkidsSessionProvider,
    private cd: ChangeDetectorRef,
  ) {
    if (!this.customerSymbols$) {
      this.customerSymbols$ = this.customerSymbols.selectAll();
    }
    this.form = fb.group({
      title: [],
      subtitle: [],
      timeslot: [],
      media_uuid: [],
      content: [],
      additional_content: [],
      customer_symbol: [],
    });

    this.hasTemplateOptionsPermission$ = this.$permission.hasTemplateOptionsPermission$.pipe(takeUntil(this.stop$));

    this.hasCustomerSymbolsPermission$ = this.$permission.hasCustomerSymbolsPermission$.pipe(takeUntil(this.stop$));

    this.disableActivityImage$ = this.$doenkidsTemplate.programDisableActivityImage$.pipe(takeUntil(this.stop$));

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
  }

  ngOnInit() {
    const symbolsFormIput = this.form.get('customer_symbol');
    this.periodSymbols$ = this.customerSymbols$.pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value)),
      mergeMap((allSymbols: ICustomerSymbol[]) => merge(of(symbolsFormIput.value), symbolsFormIput.valueChanges).pipe(
        map((symbolIds) => (symbolIds || []).map((symbolId) => {
          const result = allSymbols.find((symbol) => symbol.id === parseInt(`${symbolId}`, 10));
          if (result) {
            return result;
          }

          // It's possible the symbol is deleted and therefore cannot be mapped anymore
          // In that case we 'construct' an symbol object with just the key so removing will
          // still work in that case
          //
          return {
            id: symbolId,
          } as ICustomerSymbol;
        },
        )),
      )),
      // Sort the symbols by display order
      map((symbols) => symbols.sort((symbolA, symbolB) => (symbolA.display_order ?? 0) - (symbolB.display_order ?? 0))),
    );

    this.symbols$ = combineLatest([this.customerSymbols$, this.$session.organizationActivityTypeIds$]).pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value)),
      mergeMap(([allSymbols, activityTypeIds]) => merge(
        of(symbolsFormIput.value),
        symbolsFormIput.valueChanges,
      ).pipe(
        map((customerSymbols: string[]) => {
          if (size(customerSymbols)) {
            return (allSymbols || []).filter((symbol) => !customerSymbols.includes(`${symbol.id}`) && (isNil(symbol.activity_type_id) || activityTypeIds.includes(symbol.activity_type_id)));
          }
          return allSymbols.filter((symbol) => activityTypeIds.includes(symbol.activity_type_id));
        }),
      )),
      distinctUntilChanged(),
    );
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  /**
   * Associate a symbol with this period activity
   */
  async addSymbol(symbol: ICustomerSymbol) {
    // Add a symbol to the form
    //
    const customerSymbolCtrl = this.form.get('customer_symbol');
    const value = customerSymbolCtrl.value as string[];

    if (!isNil(value)) {
      // we get the customer symbols here and sort them to make sure they are sorted by display order
      // after which we map them to just the ids so we can use it to sort our new array of symbols
      //
      const customerSymbolIds = (await firstValueFrom(this.customerSymbols$))
        .sort((symbolA, symbolB) => (symbolA.display_order ?? 0) - (symbolB.display_order ?? 0))
        .map((sortedSymbols) => `${sortedSymbols.id}`);

      const newValue = uniq([...value, `${symbol.id}`]);
      newValue.sort((symbolIdA, symbolIdB) => customerSymbolIds.indexOf(symbolIdA) - customerSymbolIds.indexOf(symbolIdB));
      customerSymbolCtrl.setValue(newValue);
    } else {
      customerSymbolCtrl.setValue([`${symbol.id}`]);
    }
  }

  /**
   * Remove a symbol from this period and update the list
   */
  removeSymbol(symbol: ICustomerSymbol) {
    // Remove a symbol from the form
    const customerSymbolCtrl = this.form.get('customer_symbol');
    const value = customerSymbolCtrl.value as string[];
    const newValue = value.filter((symbolId) => symbolId !== `${symbol.id}`);
    customerSymbolCtrl.setValue(newValue);
  }

  writeValue(value: IActivity) {
    if (value) {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        title, subtitle, timeslot, media_uuid, content, additional_content, customer_symbol,
      } = value;
      this.form.get('title').setValue(title);
      this.form.get('subtitle').setValue(subtitle);
      this.form.get('media_uuid').setValue(media_uuid);
      this.form.get('content').setValue(content);
      this.form.get('timeslot').setValue(timeslot);
      this.form.get('additional_content').setValue(additional_content);
      this.form.get('customer_symbol').setValue(uniq(customer_symbol));
    }
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  mediaUploaded(uploadResponse: IUploadResponse) {
    this.uploaded.emit({
      uuid: uploadResponse.uuid,
      description: '',
    });
  }

  async mediaSelected(mediaItem: IMediaItem) {
    const control = this.form.get('media_uuid');
    const isEqualImage = isEqual(control.value, mediaItem.uuid);
    const isAdmin = await firstValueFrom(this.isAdmin$);

    if (isAdmin && isEqualImage) {
      control?.setValue(null);
      this.cd.detectChanges();
    }
    this.selected.emit(mediaItem);
  }

  async validate(): Promise<ValidationErrors> {
    const nameRequired = this.getError('name', 'required');
    const summaryRequired = this.getError('summary', 'required');

    if (isNil(nameRequired) && isNil(summaryRequired)) {
      return null;
    }

    return { nameRequired, summaryRequired };
  }

  getError(controlName: string, errorName: string) {
    const error = get(this.form.get(controlName), `errors.${errorName}`);
    return error;
  }
}
