import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as utc from 'dayjs/plugin/utc';
import * as weekday from 'dayjs/plugin/weekday';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

// auth0 needs this
//
(window as any).global = window;

if (environment.production) {
  enableProdMode();
}

dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Amsterdam');
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

// register Swiper custom elements
register();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => console.error(err));
