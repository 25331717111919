/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IOrganizationUnitTreeNode } from 'typings/api-customer';

export interface IOrganizationUnitTreeState extends EntityState<IOrganizationUnitTreeNode> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-unit-tree' })
export class OrganizationUnitTreeStore extends EntityStore<IOrganizationUnitTreeState, IOrganizationUnitTreeNode> {
  constructor() {
    super();
  }
}
