<form [formGroup]="period" *ngIf="period">
  <div class="input-wrapper">
    <mat-form-field appearance="outline" class="input-name" *ngIf="period?.get('period_type')?.value === 'FREEFORM'">
      <mat-label>{{ 'program_period.name.label' | translate }}</mat-label>
      <input type="text" matInput formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline" class="input-name" *ngIf="period?.get('period_type')?.value === 'DATE'">
      <mat-label>{{ 'program_period.start_date.label' | translate }}</mat-label>
      <input matInput readonly [matDatepicker]="startDate" [min]="program.get('from').value" [max]="program.get('to').value" formControlName="start_date" />
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="input-subtitle">
      <mat-label>{{ 'program_period.subtitle.label' | translate }}</mat-label>
      <input type="text" matInput formControlName="subtitle">
    </mat-form-field>
    <mat-form-field appearance="outline" class="input-description">
      <mat-label>{{ 'program_period.summary.label' | translate }}</mat-label>
      <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5" formControlName="summary">
      </textarea>
    </mat-form-field>
    <ng-content></ng-content>
  </div>
</form>
