import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IActivityReviewScoreType } from 'typings/doenkids/doenkids';
import { ActivityScoreTypeStore } from './activity-review-score-type.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivityReviewScoreTypeService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: ActivityScoreTypeStore) {}

  @transaction()
  async fetchAll() {
    const result = await firstValueFrom(this.$baseApi.genericListCall('/activity/activity-review-score-type', 100, 0)) as { items: IActivityReviewScoreType[] };
    this.store.set(result?.items ?? []);
    return result;
  }
}
