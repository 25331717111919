import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { filter, map, tap } from 'rxjs/operators';
import { isNil, get } from 'lodash';
import { ISearchActivity, IOrganizationUnit } from 'typings/doenkids/doenkids';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { OrganizationUnitListService } from 'src/api/customer/organization-unit-list/organization-unit-list.service';
import { Router } from '@angular/router';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IRelatedActivityResponse } from 'typings/api-activity';
import {
  BrowseOrganizationUnitDialogComponent, ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { EOrganizationUnitType } from '../add-organization-dialog/add-organization-dialog.component';

export interface IReviewActivityResponse {
  status: string;
  owner: IActivityOwner;
}

export interface IActivityOwner {
  id: number;
  name: string;
  type: number;
}

interface IActivityReviewInput {
  parent: IActivityOwner;
  activityId: number;
}

@Component({
  selector: 'app-review-activity-dialog',
  templateUrl: './review-activity-dialog.component.html',
  styleUrls: ['./review-activity-dialog.component.scss'],
})
export class ReviewActivityDialogComponent implements OnInit {
  public selectedOwner: IActivityOwner;

  public originalOwner: IActivityOwner;

  private activityId: number;

  public organizationUnitDictionary: IOrganizationUnit[] = [];

  private fetchingOuIds: number[] = [];

  public relatedActivities$ = new Subject<IRelatedActivityResponse>();

  public isReviewing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public activityCopies$ = this.relatedActivities$.pipe(
    filter((value) => !isNil(value)),
    map((value) => value.copies ?? []),
    tap((copies) => copies.forEach((copy) => this.fetchOuDetails(get(copy, 'organization_unit_id.0') as number))),
  );

  public activitySource$ = this.relatedActivities$.pipe(
    filter((value) => !isNil(value)),
    map((value) => value.source ?? null),
    tap((source) => this.fetchOuDetails(get(source, 'organization_unit_id.0') as number)),
  );

  public typeOfTheOwner = '';

  public getOrganizationName(activity: ISearchActivity) {
    const organizationUnitId = get(activity, 'organization_unit_id.0') as number;
    if (this.organizationUnitDictionary && organizationUnitId && this.organizationUnitDictionary[organizationUnitId]) {
      return this.organizationUnitDictionary[organizationUnitId].name;
    }

    return this.$translateService.instant(_('review_activity_dialog.organization.unknown'));
  }

  public async goToActivity(activity: ISearchActivity) {
    const organizationUnitId = get(activity, 'organization_unit_id.0') as number;
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(
          _('review_activity_dialog.navigate.dialog.description'),
          { organizationUnitName: this.getOrganizationName(activity) },
        ),
      },
    });

    const dialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (dialogResult !== 'confirm') {
      return;
    }

    this.$session.selectOrganizationUnit(organizationUnitId);
    this.router.navigate([`/activities/preview/${activity.id}`]);
    this.dialogRef.close();
  }

  constructor(
    public dialogRef: MatDialogRef<ReviewActivityDialogComponent>,
    public matDialog: MatDialog,
    private router: Router,
    public activityService: ActivityService,
    private organizationListService: OrganizationUnitListService,
    private $session: DoenkidsSessionProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    @Inject(MAT_DIALOG_DATA) public data: IActivityReviewInput,
  ) {
    const selectedParent = this.data?.parent;
    this.activityId = this.data?.activityId;
    if (selectedParent) {
      this.originalOwner = {
        id: selectedParent.id,
        name: selectedParent.name,
        type: selectedParent.type,
      };
      this.changeOwner({
        id: selectedParent.id,
        name: selectedParent.name,
        type: selectedParent.type,
      });
    }
  }

  async ngOnInit() {
    const response = await firstValueFrom(this.activityService.getCopyAndSource(this.activityId));
    this.relatedActivities$.next(response);
  }

  changeOwner(owner: IActivityOwner) {
    this.selectedOwner = owner;
  }

  async selectOther() {
    const dialogRef = this.matDialog.open(BrowseOrganizationUnitDialogComponent, {
      data: {
        title: this.$translateService.instant(_('review_activity_dialog.organization.select.dialog.title')),
        description: this.$translateService.instant(_('review_activity_dialog.organization.select.dialog.description')),
        selectableOrganizationUnitTypeIds: [EOrganizationUnitType.ORGANIZATION],
        initialOrganizationUnitSelection: [{
          id: this.selectedOwner.id,
          name: this.selectedOwner.name,
          organization_unit_type_id: this.selectedOwner.type,
        }],
      },
    });

    const selection: ISelectedOrganizationUnit[] = await firstValueFrom(dialogRef.afterClosed());
    const result = selection && selection[0];

    if (result.organization_unit_type_id) {
      if (result.organization_unit_type_id === EOrganizationUnitType.ORGANIZATION) {
        this.changeOwner({
          id: result.id,
          name: result.name,
          type: result.organization_unit_type_id,
        });
      } else {
        this.$i18nToastProvider.error(_('review_activity_dialog.error.organization_only'));
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async onConfirm() {
    if (this.selectedOwner.id && this.activityId) {
      this.isReviewing$.next(true);
      const currentOUId = await firstValueFrom(this.$session.currentOuId$);
      try {
        await firstValueFrom(this.activityService.requestReview(this.activityId, this.selectedOwner.id, currentOUId));
        this.isReviewing$.next(false);
        this.dialogRef.close({
          status: 'confirm',
          owner: this.selectedOwner,
        });
      } catch (e) {
        this.isReviewing$.next(false);
        this.$i18nToastProvider.error(_('review_activity_dialog.request.failed'));
      }
    } else {
      this.$i18nToastProvider.error(_('review_activity_dialog.request.failed'));
    }
  }

  async fetchOuDetails(ouId?: number) {
    if (!isNil(ouId) && !this.organizationUnitDictionary[ouId] && !this.fetchingOuIds.includes(ouId)) {
      this.fetchingOuIds.push(ouId);
      const fetchedOu = await this.organizationListService.fetch(ouId);
      this.organizationUnitDictionary[ouId] = fetchedOu;
      const fetchOuIdIndex = this.fetchingOuIds.indexOf(ouId);

      if (fetchOuIdIndex > -1) {
        this.fetchingOuIds.splice(fetchOuIdIndex, 1);
      }
    }
  }
}
