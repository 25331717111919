<h1 mat-dialog-title>{{ 'dialog.copy_program.title' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="column" fxLayoutGap="16px" *ngIf="data.locations.length > 0">
      <p
        [innerHTML]="'dialog.copy_program.description' | translate: {
          locations: data.locations | i18n_list | async
        }"
      ></p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'program.title.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="name">
      </mat-form-field>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="16px" class="program-dates">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="startDate"
            [max]="form.get('to').value"
            [placeholder]="'generic.period.end_date' | translate"
            formControlName="from"
            readonly
          >
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="endDate"
            [min]="form.get('from').value"
            [placeholder]="'generic.period.start_date' | translate"
            formControlName="to"
            readonly
          >
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate ></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <button mat-button color="primary" (click)="confirm()" cdkFocusInitial>
    {{ 'generic.ok' | translate | uppercase }}
  </button>
</div>
