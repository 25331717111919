/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IAreaOfDevelopment } from 'typings/doenkids/doenkids';

export interface IAreaOfDevelopmentState extends EntityState<IAreaOfDevelopment> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'area-of-development' })
export class AreaOfDevelopmentStore extends EntityStore<IAreaOfDevelopmentState, IAreaOfDevelopment> {
  constructor() {
    super();
  }
}
