import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ISectionType } from 'typings/doenkids/doenkids';
import { IProgramSectionTypeState, ProgramSectionTypeStore } from './program-section-type.store';

@Injectable({ providedIn: 'root' })
export class ProgramSectionTypeQuery extends QueryEntity<IProgramSectionTypeState, ISectionType> {
  constructor(protected store: ProgramSectionTypeStore) {
    super(store);
  }
}
