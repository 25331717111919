import { Location as ALocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ActivitySelectionProvider } from 'src/providers/activity-selection.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { PinbordProvider } from 'src/providers/pinbord.provider';
import { ProgramCreationProvider } from 'src/providers/program-creation.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IFavoriteFolderDetails, ISearchActivity } from 'typings/doenkids/doenkids';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nTitleStrategy } from 'src/app/utils/i18n-title-strategy';
import { TranslateService } from 'src/app/utils/translate.service';

@Component({
  selector: 'app-pinbord-folder-details',
  templateUrl: './pinbord-folder-details.component.html',
  styleUrls: ['./pinbord-folder-details.component.scss'],
})
export class PinbordFolderDetailsComponent implements OnInit, OnDestroy {
  private stop$: Subject<void> = new Subject<void>();

  public hasOUWritePermission$: Observable<boolean>;

  public currentFolder: IFavoriteFolderDetails;

  public selectActivitiesControl: UntypedFormControl = new UntypedFormControl(false);

  public isSelectingActivties$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private location: ALocation,
    $permission: PermissionProvider,
    private $pinbord: PinbordProvider,
    private $activitySelection: ActivitySelectionProvider,
    private $programCreate: ProgramCreationProvider,
    private $session: DoenkidsSessionProvider,
    private $translateService: TranslateService,
    private readonly $i18nTitleStrategy: I18nTitleStrategy,
  ) {
    this.hasOUWritePermission$ = $permission.hasOUWritePermissions$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));
    this.isSelectingActivties$ = this.$activitySelection.isSelecting$.asObservable().pipe(takeUntil(this.stop$));
  }

  ngOnInit(): void {
    this.$pinbord.currentFolders$.pipe(takeUntil(this.stop$)).subscribe((currentFolders) => {
      if (currentFolders?.folders) {
        this.fetchFolder(currentFolders.folders);
      }
    });

    // If the checkbox for collecting activities gets toggled, toggle the status.
    //
    this.selectActivitiesControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((isSelecting) => {
      if (isSelecting !== this.$activitySelection.isSelecting$.value) {
        this.$activitySelection.toggleSelecting();
      }
    });

    this.$activitySelection.isSelecting$.pipe(takeUntil(this.stop$)).subscribe((isSelecting) => {
      if (isSelecting !== this.selectActivitiesControl.value) {
        this.selectActivitiesControl.setValue(isSelecting);
      }
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  fetchFolder(folders: IFavoriteFolderDetails[]) {
    // Get a snapshot of the current route parameters.
    //
    const { params } = this.route.snapshot;
    this.currentFolder = folders.find((folder) => folder.id === parseInt(params.id, 10));
    this.$i18nTitleStrategy.updateTitleParameters({ pinboard: this.currentFolder?.name ?? '' });
  }

  async deletePinbordFolder() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.remove')),
        description: this.$translateService.instant(_('pinboard.folder.delete.confirm.dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const currentOUId = await firstValueFrom(this.$session.currentOuId$);
      await this.$pinbord.removeFolder(currentOUId, this.currentFolder.id);
      await this.$pinbord.fetchFolders(currentOUId);
      if (this.router.navigated) {
        this.location.back();
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  activityClicked(activity: ISearchActivity) {
    if (this.selectActivitiesControl.value) {
      this.$activitySelection.toggleActivitySelected(activity);
      return;
    }

    this.router.navigate([`/activities/preview/${activity.id}`]);
  }

  activityIsSelected(activity: ISearchActivity) {
    const isSelected = this.$activitySelection.selectedActivities$.value.find((selectedActivity) => selectedActivity.id === activity.id);
    return isSelected;
  }

  async createProgram() {
    this.$programCreate.promptProgramFromPinbord(this.currentFolder);
  }

  goToActivities() {
    if (!this.$activitySelection.isSelecting$.value) {
      this.$activitySelection.toggleSelecting();
    }
    this.router.navigate(['/activities']);
  }
}
