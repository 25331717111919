<ng-container *ngIf="!(listLoading$ | async)">
  <ng-container *ngIf="list$ | async as list">
    <div class="filter-section">
      <mat-form-field>
        <mat-label>{{
          'organization_unit_table.search.label'
            | translate
              : {
                  organizationUnitTypeName: organizationUnitTypeId | ou_type_name | translate
                }
        }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" />
      </mat-form-field>
      <button
        (click)="downloadLocationsExcel()"
        mat-button
        [matTooltip]="
          'organization_unit_table.excel.download.tooltip'
            | translate
              : {
                  organizationUnitTypeName: organizationUnitTypeId | ou_type_name | translate
                }
        "
      >
        <mat-icon svgIcon="excel"></mat-icon>
      </button>
      <button
        (click)="downloadLocationAndGroupsExcel()"
        mat-button
        [matTooltip]="
          'organization_unit_table.excel.groups.download.tooltip'
            | translate
              : {
                  organizationUnitTypeName: organizationUnitTypeId | ou_type_name | translate
                }
        "
      >
       <mat-icon>upload_file</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="list.length > 0; else notFound">
      <table
        mat-table
        class="table"
        [dataSource]="
          list
            | paginate
              : {
                  id: 'pagination-ou-type-' + organizationUnitTypeId,
                  itemsPerPage: MAX_ITEMS_PER_PAGE,
                  currentPage: currentPage,
                  totalItems: list.length
                }
        "
        class="mat-elevation-z8"
      >
        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ 'organization_unit_table.heading.organization' | translate }}</th>
          <td mat-cell *matCellDef="let element" [class.no-pointer]="organizationUnitTypeId === 4">
            <ng-container *ngIf="(editingOU$ | async) === element.id">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon [svgIcon]="'ou-type-' + element.type_id"></mat-icon>&nbsp;&nbsp;
                <mat-form-field class="full-width-form-field">
                  <mat-label>{{ 'organization_unit.name.label' | translate }}</mat-label>
                  <input matInput [formControl]="editingOUControl" />
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="(editingOU$ | async) !== element.id">
              <div fxLayout="row" fxLayoutAlign="start center" (click)="showDetails(element)">
                <mat-icon [svgIcon]="'ou-type-' + element.type_id"></mat-icon>&nbsp;&nbsp; {{ element.name }}&nbsp;
                <span class="suffix" *ngIf="element.type_id === 3 && element.activity_type.length === 1">({{ element.activity_type[0] }})</span>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions" *ngIf="editable">
          <th mat-header-cell *matHeaderCellDef>{{ 'organization_unit_table.heading.actions' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="start left" *ngIf="(hasOUWritePermissions$ | async) && editable">
              <ng-container *ngIf="(editingOU$ | async) === element.id">
                <button mat-button [disabled]="listLoading$ | async" (click)="saveRow(element)" *ngIf="(editingOU$ | async) === element.id">
                  <mat-icon>save</mat-icon>
                </button>
                <button mat-button [disabled]="listLoading$ | async" (click)="cancelEditingRow()">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="(editingOU$ | async) !== element.id">
                <button mat-button [disabled]="listLoading$ | async" (click)="editRow(element)"><mat-icon>edit</mat-icon></button>
                <button
                  mat-button
                  [disabled]="listLoading$ | async"
                  (click)="showAPIKeyDialog(element)"
                  *ngIf="organizationUnitTypeId === 2 || organizationUnitTypeId === 3"
                >
                  <mat-icon>vpn_key</mat-icon>
                </button>
                <ng-container *ngVar="element.konnectOrganizationUnit && element.id !== element.konnectOrganizationUnit.id as hasKonnectFromParent">
                  <button
                    mat-button
                    [disabled]="listLoading$ | async"
                    (click)="connectKonnectOU(element)"
                    [matTooltip]="
                      hasKonnectFromParent
                        ? ('konnect.connect.tooltip' | translate : { konnectOrganizationUnitName: element.konnectOrganizationUnit?.name })
                        : ''
                    "
                    *ngIf="konnectDetails && !organizationIsAlreadyLinkedToKonnect(element)"
                  >
                    <mat-icon [matBadge]="hasKonnectFromParent ? 'account_tree' : ''" svgIcon="konnect-unlinked"></mat-icon>
                  </button>
                </ng-container>
                <button
                  mat-button
                  [disabled]="listLoading$ | async"
                  (click)="unlinkKonnectOU(element)"
                  *ngIf="konnectDetails && organizationIsAlreadyLinkedToKonnect(element)"
                >
                  <mat-icon svgIcon="konnect-linked"></mat-icon>
                </button>
                <button
                  mat-button
                  [disabled]="listLoading$ | async"
                  (click)="connectJibbieOU(element)"
                  *ngIf="jibbieDetails && !organizationIsAlreadyLinkedToJibbie(element.id)"
                >
                  <mat-icon svgIcon="jibbie-unlinked"></mat-icon>
                </button>
                <button
                  mat-button
                  [disabled]="listLoading$ | async"
                  (click)="unlinkJibbieOU(element)"
                  *ngIf="jibbieDetails && organizationIsAlreadyLinkedToJibbie(element.id)"
                >
                  <mat-icon svgIcon="jibbie-linked"></mat-icon>
                </button>
                <button mat-button [disabled]="listLoading$ | async" (click)="removeOrganization(element, $event)"><mat-icon>delete</mat-icon></button>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <ng-container *ngIf="metaData$ | async as metadata">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="list.length > MAX_ITEMS_PER_PAGE">
          <pagination-controls
            [id]="'pagination-ou-type-' + organizationUnitTypeId"
            [nextLabel]="'generic.pagination.next' | translate"
            [previousLabel]="'generic.pagination.previous' | translate"
            (pageChange)="paginate($event)"
          ></pagination-controls>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notFound>
  <app-empty-page-message
    [message]="
      'organization_unit_table.not_found'
        | translate
          : {
              organizationUnitTypeName: organizationUnitTypeId | ou_type_name | translate
            }
    "
    [svgIcon]="'ou-type-' + organizationUnitTypeId"
  ></app-empty-page-message>
</ng-template>
<button mat-fab class="doenkids-fab" color="primary" (click)="addOrganizationUnit()" *ngIf="canAddNewItem">
  <mat-icon [attr.aria-label]="'organization_unit_table.organization_unit.add.label' | translate">add</mat-icon>
</button>
