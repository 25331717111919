import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgramActivityResponse } from 'typings/api-activity';

export interface IProgramActivitiesState extends EntityState<IProgramActivityResponse> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-activities' })
export class ProgramActivitiesStore extends EntityStore<IProgramActivitiesState, IProgramActivityResponse> {
  constructor() {
    super([]);
  }
}
