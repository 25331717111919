import {
  Directive,
  Input,

  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerComponent } from '../components/shared/spinner/spinner.component';

@Directive({
  selector: '[showSpinner]',
})
export class SpinnerDirective {
  @Input() set showSpinner(isLoading: boolean) {
    this.viewContainer.clear();

    if (isLoading) {
      this.viewContainer.createComponent(SpinnerComponent);
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}
}
