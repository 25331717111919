import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlToPlaintext',
})
export class HtmlToPlaintextPipe implements PipeTransform {
  // Removes all HTML tags from a string, except for <br>
  transform(value: string): string {
    return value?.replace(/<(?!br\s*\/?)[^>]+>/g, ' ');
  }
}
