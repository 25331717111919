import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IOrganizationUnitTagAll } from 'typings/doenkids/doenkids';
import { ITagListState, TagListStore } from './tag-list.store';

@Injectable({ providedIn: 'root' })
export class TagListQuery extends QueryEntity<ITagListState, IOrganizationUnitTagAll> {
  constructor(protected store: TagListStore) {
    super(store);
  }
}
