import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { DoenKidsAuth0Provider } from 'src/providers/auth0.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { CurrentUserService } from 'src/api/customer/auth/auth.service';
import * as dayjs from 'dayjs';
import { RedirectProvider } from 'src/providers/redirect.provider';

export const doenKidsAuthGuard = async () => {
  const router = inject(Router);
  const $session = inject(DoenkidsSessionProvider);
  const $auth0Provider = inject(DoenKidsAuth0Provider);
  const currentUserService = inject(CurrentUserService);
  const $redirect = inject(RedirectProvider);

  return new Promise((resolve) => $auth0Provider.profile()
    .then(async (profile) => {
      if (!profile) {
        console.log('[AUTH-GUARD] redirect to login');
        $redirect.setPathToRedirectTo(document.location.pathname);
        router.navigate(['login']);
        resolve(true);
      } else {
        const dayjsDateProfileIsUpdated = dayjs(profile.updated_at, 'YYYY-MM-DD');
        const currentDate = dayjs(dayjs().format('YYYY-MM-DD'));

        if (dayjsDateProfileIsUpdated.isBefore(currentDate)) {
          $redirect.setPathToRedirectTo(document.location.pathname);
          $auth0Provider.logout();
          resolve(false);
          return;
        }

        console.log('[AUTH-GUARD] Discovered valid session');
        if (!($session.getCurrentUser())) {
          await currentUserService.fetch();
        }
        resolve(true);
      }
    }).catch((e) => {
      console.error('[AUTH-GUARD] Problem while testing for profile', e);
      resolve(false);
    }));
};
