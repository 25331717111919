<h3 mat-dialog-title>{{ data.title }}</h3>
<mat-dialog-content>
  <div fxFlexFill fxLayout="column" fxLayoutGap="16px" *ngIf="( periods$ | async )?.length > 1">
    <p>
      <ng-container *ngIf="(currentPeriod$ | async) as period">
        <ng-container *ngIf="period.period_type === 'FREEFORM'">
          {{ 'migrate_period_sections_dialog.description.freeform' | translate: { periodName: period.name || undefined, isCopying: data.isCopying } }}
        </ng-container>
        <ng-container *ngIf="period.period_type === 'DATE'">
          {{ 'migrate_period_sections_dialog.description.date' | translate: { periodStartDate: (period.start_date | i18n_date: 'fullDate' | async | titlecase) || undefined, isCopying: data.isCopying } }}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!(currentPeriod$ | async)">
        {{ 'migrate_period_sections_dialog.current_field' | translate: { isCopying: data.isCopying } }}
      </ng-container>
    </p>
    <mat-form-field appearance="outline">
      <mat-select [formControl]="periodControl" [placeholder]="'migrate_period_sections_dialog.period.select.placeholder' | translate">
        <mat-option *ngFor="let period of ( periods$ | async )" [value]="period.id">
          <ng-container *ngIf="period.period_type === 'FREEFORM'">
            {{ period?.name ? period.name : ('generic.field' | translate) }}
          </ng-container>
          <ng-container *ngIf="period.period_type === 'DATE'">
            {{ period?.start_date
              ? (period.start_date | i18n_date: 'fullDate' | async | titlecase)
              : ('generic.field' | translate) }}
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <p *ngIf="( periods$ | async )?.length === 1">
    {{ 'migrate_period_sections_dialog.single_period_option' | translate: {
      currentPeriodName: (currentPeriod$ | async)?.name || undefined,
      periodName: (periods$ | async)[0]?.name || undefined,
      isCopying: data.isCopying
    } }}
  </p>
  <p *ngIf="data.isCopying">
    {{ 'migrate_period_sections_dialog.copy_to_another_program' | translate }}
    <a class="pointer-anchor" (click)="copyToOtherProgram();">{{ 'generic.here' | translate }}</a>
  </p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" fxFlexFill>
  <button mat-button (click)="cancel()">
    {{ data.cancelText }}
  </button>
  <button mat-button color="primary" (click)="close()" [disabled]="!periodControl.valid">
    {{ data.confirmText }}
  </button>
</mat-dialog-actions>
