<h3 class="settings-category-title">{{ 'settings_overview.tab.category.title.language' | translate }}</h3>
<mat-card class="settings-item-card">
  <mat-card-content
    ><div class="locale-language-select">
      <div class="locale-select">
        <span class="label">
          {{ 'generic.locale.select.label' | translate }}
          <mat-icon class="info-icon" (click)="openInfoWindow($event, 'locale')">info</mat-icon>
        </span>
        <mat-form-field appearance="outline">
          <mat-select class="locale-select" [formControl]="selectedLocale" panelWidth="190">
            <mat-select-trigger>
              <mat-icon [svgIcon]="selectedLocale.value"></mat-icon>
              {{ 'generic.locale.' + selectedLocale.value.replace('-', '_') | translate }}
            </mat-select-trigger>
            <mat-option *ngFor="let locale of availableLocales" [value]="locale">
              <mat-icon [svgIcon]="locale"></mat-icon>
              {{ 'generic.locale.' + locale.replace('-', '_') | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <ng-container *ngVar="(availableContentLanguages$ | async) as contentLanguages">
        <div class="content-language-select" *ngIf="contentLanguages?.length > 0">
          <span class="label">
            {{ 'generic.language.select.label' | translate }}
            <mat-icon class="info-icon" (click)="openInfoWindow($event, 'language')">info</mat-icon>
          </span>
          <mat-form-field appearance="outline">
            <mat-select class="language-select" [formControl]="selectedContentLanguage" panelWidth="190">
              <mat-select-trigger>
                <mat-icon [svgIcon]="selectedContentLanguage.value.toLowerCase()"></mat-icon>
                {{ 'generic.language.' + selectedContentLanguage.value.replace('-', '_') | translate }}
              </mat-select-trigger>
              <mat-option *ngFor="let contentLanguage of contentLanguages" [value]="contentLanguage">
                <mat-icon [svgIcon]="contentLanguage.toLowerCase()"></mat-icon>
                {{ 'generic.language.' + contentLanguage.replace('-', '_') | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
