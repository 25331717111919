import { Injectable } from '@angular/core';
/* eslint-disable @typescript-eslint/no-useless-constructor */
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ISearchProgramTemplate } from 'typings/doenkids/doenkids';

export interface ITemplateSearchState extends EntityState<ISearchProgramTemplate> {
  query?: string;
  limit: number;
  skip: number;
  total: number;
  organizationUnitId: any;
  nodeOnly?: any;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'template-search' })
export class TemplateSearchStore extends EntityStore<ITemplateSearchState, ISearchProgramTemplate> {
  constructor() {
    super();
  }

  setMetadata({
    total, skip, limit, query, took,
  }) {
    this.update({
      total, skip, limit, query, took,
    });
  }

  setAggregations(aggregations: any) {
    this.update({ aggregations });
  }

  setSuggestions(suggest: any) {
    this.update({ suggest });
  }
}
