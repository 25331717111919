import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitActivityService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  update(organizationUnitId: number, activityId: number, organizationUnitRoleId: number) {
    return this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/activity/${activityId}?organizationUnitRoleId=${organizationUnitRoleId}`);
  }

  archive(organizationUnitId: number, activityId: number) {
    return this.$baseApi.genericDeleteCall(`/customer/organization-unit/${organizationUnitId}/activity/${activityId}`);
  }
}
