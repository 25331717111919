<h3 mat-dialog-title>{{ (data?.folder ? 'dialog.add_new_pinboard_folder.edit' : 'dialog.add_new_pinboard_folder.add') | translate }}</h3>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'dialog.add_new_pinboard_folder.folder_name' | translate }}</mat-label>
    <input type="text" matInput [formControl]="folderName">
    <mat-error *ngIf="folderName.hasError('duplicate_name')">
      {{ 'dialog.add_new_pinboard_folder.duplicate_name' | translate }}
    </mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" fxFlexFill>
  <button mat-button color="primary" (click)="onClose()">{{ 'generic.close' | translate }}</button>
  <button mat-button color="primary" (click)="onAdd()">{{ 'generic.add' | translate }}</button>
</mat-dialog-actions>
