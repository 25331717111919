import {
  Component, OnInit, Inject, OnDestroy, ViewEncapsulation,
} from '@angular/core';
import { IJibbieLocation, IJibbieOrganisation, IJibbieOrganizationUnit } from 'typings/doenkids/doenkids';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IJibbieLocationListResponse, IJibbieOrganisationListResponse } from 'typings/api-customer';
import { JibbieLocationQuery } from 'src/api/customer/jibbie/location/jibbie-location.query';
import { JibbieLocationService } from 'src/api/customer/jibbie/location/jibbie-location.service';
import { JibbieOrganizationQuery } from 'src/api/customer/jibbie/organization/jibbie-organization.query';
import { JibbieOrganizationService } from 'src/api/customer/jibbie/organization/jibbie-organization.service';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { EOrganizationUnitType } from '../add-organization-dialog/add-organization-dialog.component';

@Component({
  selector: 'app-connect-jibbie-ou-dialog',
  templateUrl: './connect-jibbie-ou-dialog.component.html',
  styleUrls: ['./connect-jibbie-ou-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConnectJibbieOuDialogComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  public loading$ = new Subject<boolean>();

  public jibbieSearch: UntypedFormGroup;

  private JIBBIE_SEARCH_LIMIT = 5;

  private jibbieCurrentPage = 0;

  public jibbieDetails: IJibbieOrganizationUnit;

  public jibbieLocationResults$: BehaviorSubject<IJibbieLocation[]> = new BehaviorSubject<IJibbieLocation[]>([]);

  public jibbieLocationSearching$: Observable<boolean>;

  private totalJibbieLocations = 0;

  public jibbieOrganisationResults$: BehaviorSubject<IJibbieOrganisation[]> = new BehaviorSubject<IJibbieOrganisation[]>([]);

  public jibbieOrganisationSearching$: Observable<boolean>;

  private totalJibbieOrganizations = 0;

  protected readonly EOrganizationUnitType = EOrganizationUnitType;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ConnectJibbieOuDialogComponent>,
    private organizationUnitService: OrganizationUnitService,
    private jibbieLocationQuery: JibbieLocationQuery,
    private jibbieLocationService: JibbieLocationService,
    private jibbieOrganisationQuery: JibbieOrganizationQuery,
    private jibbieOrganisationService: JibbieOrganizationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      organizationUnitType: number,
      organizationUnitId: number,
      organizationUnitName: string,
      parentOUId: number,
      parentOUName: string,
    },
  ) {
    this.jibbieLocationSearching$ = this.jibbieLocationQuery.selectLoading();
    this.jibbieOrganisationSearching$ = this.jibbieOrganisationQuery.selectLoading();
  }

  async ngOnInit() {
    this.jibbieSearch = this.fb.group({
      name: [''],
    });

    this.jibbieSearch.get('name').valueChanges.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((newValue: string) => {
      this.searchJibbie(newValue, false);
    });

    if (this.data.parentOUId && (this.data.organizationUnitType === EOrganizationUnitType.LOCATION)) {
      const fetchedJibbieDetails = await this.organizationUnitService.jibbieDetails(this.data.parentOUId);

      if (fetchedJibbieDetails.src !== 'none') {
        this.jibbieDetails = fetchedJibbieDetails;

        this.searchJibbie('', false);
      } else {
        this.jibbieDetails = null;
      }
    } else if (this.data.parentOUId && (this.data.organizationUnitType === EOrganizationUnitType.ORGANIZATION)) {
      this.searchJibbie('', false);
    }
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async searchJibbie(name: string, concatenate: boolean) {
    if (this.data.organizationUnitType === EOrganizationUnitType.LOCATION && this.jibbieDetails) {
      const skip = concatenate ? ((this.jibbieCurrentPage + 1) * this.JIBBIE_SEARCH_LIMIT) : 0;
      // through the query the results are gonna appear on the screen
      // TODO don't cast jibbie details to any
      //
      await this.jibbieLocationService.fetchAll((this.jibbieDetails as any).jibbie_id, {
        limit: this.JIBBIE_SEARCH_LIMIT,
        skip,
        sortField: 'name',
        sortDirection: 'ASC',
        search: name,
      })
        .then((jibbieLocationListResponse: IJibbieLocationListResponse) => {
          const filteredResults = jibbieLocationListResponse.items.filter((location) => !location.organization_unit_id);
          if (concatenate) {
            const currentJibbieLocationList = this.jibbieLocationResults$.value;
            this.jibbieLocationResults$.next([...currentJibbieLocationList, ...filteredResults]);
            this.jibbieCurrentPage += 1;
          } else {
            this.jibbieLocationResults$.next(filteredResults);
            this.jibbieCurrentPage = 0;
          }
          this.totalJibbieLocations = jibbieLocationListResponse.total;

          // as long as we didn't get all locations yet and we have less locations in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalJibbieLocations > (jibbieLocationListResponse.limit + jibbieLocationListResponse.skip)
            && this.jibbieLocationResults$.value.length < this.JIBBIE_SEARCH_LIMIT) {
            this.searchJibbie(name, true);
          }
        });
    } else if (this.data.organizationUnitType === EOrganizationUnitType.ORGANIZATION) {
      const skip = concatenate ? ((this.jibbieCurrentPage + 1) * this.JIBBIE_SEARCH_LIMIT) : 0;
      await this.jibbieOrganisationService.fetchAll({
        limit: this.JIBBIE_SEARCH_LIMIT,
        skip,
        sortField: 'name',
        sortDirection: 'ASC',
        search: name,
      })
        .then((jibbieOrganisationListResponse: IJibbieOrganisationListResponse) => {
          const filteredResults = jibbieOrganisationListResponse.items.filter(
            (jibbieOrganisation) => !jibbieOrganisation.organization_unit_id,
          );
          if (concatenate) {
            const currentJibbieOrganisationList = this.jibbieOrganisationResults$.value;
            this.jibbieOrganisationResults$.next([...currentJibbieOrganisationList, ...filteredResults]);
            this.jibbieCurrentPage += 1;
          } else {
            this.jibbieOrganisationResults$.next(filteredResults);
            this.jibbieCurrentPage = 0;
          }

          this.totalJibbieOrganizations = jibbieOrganisationListResponse.total;

          // as long as we didn't get all groups yet and we have less groups in our results then on our first search should yield
          // search again and concat the results till we have enough of them
          if (this.totalJibbieOrganizations > (jibbieOrganisationListResponse.limit + jibbieOrganisationListResponse.skip)
            && this.jibbieOrganisationResults$.value.length < this.JIBBIE_SEARCH_LIMIT) {
            this.searchJibbie(name, true);
          }
        });
    }
  }

  async linkJibbieLocationToOrganization(location: IJibbieLocation) {
    try {
      this.loading$.next(true);

      // connect konnect location to current ou
      //
      await this.jibbieLocationService.setOrganizationUnit(location.id, this.data.organizationUnitId);

      this.closeDialog();
    } catch (e) {
      console.log('error linking Konnect location to OU', e);
    }
  }

  async linkJibbieOrganisationToOrganization(organisation: IJibbieOrganisation) {
    try {
      this.loading$.next(true);

      // connect konnect location to current ou
      //
      await this.jibbieOrganisationService.setOrganizationUnit(organisation.id, this.data.organizationUnitId);

      this.closeDialog();
    } catch (e) {
      console.log('error linking Konnect group to OU', e);
    }
  }

  getOUTypeName(ouType: EOrganizationUnitType): string {
    switch (ouType) {
      case EOrganizationUnitType.ORGANIZATION:
        return _('organization_unit.organization');
      case EOrganizationUnitType.LOCATION:
        return _('organization_unit.location');
      case EOrganizationUnitType.GROUP:
        return _('organization_unit.group');
      default:
        return _('organization_unit.root');
    }
  }

  closeDialog() {
    this.loading$.next(false);
    this.jibbieLocationResults$.next([]);
    this.jibbieOrganisationResults$.next([]);
    this.dialogRef.close();
  }

  scrollEndReached() {
    this.searchJibbie(this.jibbieSearch.get('name').value, true);
  }
}
