import Quill from 'quill';

const Delta = Quill.import('delta');
// const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

export function lineBreakMatcher() {
  const newDelta = new Delta();
  newDelta.insert({ softbreak: '' });
  return newDelta;
}

export class SoftLineBreakBlot extends Embed {
  static blotName = 'softbreak';

  static tagName = 'br';

  static className = 'softbreak';

  length() {
    return 1;
  }

  value() {
    return '\n';
  }

  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

export function registerSmartBreak() {
  Quill.register(SoftLineBreakBlot);
}

export const shiftEnterBinding = {
  key: 13,
  shiftKey: true,
  handler(range, test) {
    const currentLeaf = this.quill.getLeaf(range.index)[0];
    const nextLeaf = this.quill.getLeaf(range.index + 1)[0];

    console.log(range, test);

    this.quill.insertEmbed(range.index, 'softbreak', true, 'user', 'api');

    // Insert a second break if:
    // At the end of the editor, OR next leaf has a different parent (<p>)
    if (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent)) {
      this.quill.insertEmbed(range.index, 'softbreak', true, 'user', 'api');
    }

    // Now that we've inserted a line break, move the cursor forward
    this.quill.setSelection(range.index + 1, Quill.sources.SILENT, 'api');
  },
};
