<div fxLayout="column" fxLayoutGap="16px">
  <mat-card appearance="outlined" *ngVar="activity$ | async as activity">
    <app-review-activity-description *showSpinner="!activity" [activity]="$any(activity)">
      <button button mat-flat-button [routerLink]="'../activities/preview/' + activity.id">
        {{ 'review_preview.view_activity' | translate }}
        <mat-icon>chevron_right</mat-icon>
      </button>
    </app-review-activity-description>
  </mat-card>

  <mat-card class="review-card" appearance="outlined" fxLayout="column">
    <div fxFlex fxLayoutAlign="end center">
      <mat-chip-listbox hideSingleSelectionIndicator>
        <mat-chip-option [class]="'status-' + review.activity_review_status_id">
          {{ getStatusName(review.activity_review_status_id) | translate }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>

    <div fxFlex fxLayoutAlign="space-between start">
      <div>
        <div *ngFor="let score of review.review_scores; trackBy: trackById">
          <h3>{{ score.type }} {{ score.score / 20 | i18n_number: '1.1-1' | async }}</h3>
          <app-review-rating [editable]="false" [value]="score.score"></app-review-rating>
        </div>
      </div>

      <div class="review-total" fxLayout="column" fxLayoutAlign="space-between start">
        <h1>{{ review.average_review_score / 20 | i18n_number: '1.1-1' | async }}</h1>
        <app-review-rating [value]="review.average_review_score" [editable]="false"></app-review-rating>
      </div>
    </div>

    <mat-chip-listbox hideSingleSelectionIndicator>
      <mat-chip-option>{{ 'review.job_title' | translate: { jobTitle: review.job_title } }}</mat-chip-option>
      <mat-chip-option *ngIf="reviewAgeGroup$ | async as ageGroup">
        {{ 'review.age_group' | translate: { ageGroup: ageGroup.name } }}
      </mat-chip-option>
      <mat-chip-option>{{ 'review.is_anonymous' | translate: { isAnonymous: review.anonymous } }}</mat-chip-option>
      <mat-chip-option>{{ 'review.latest_update' | translate: { lastUpdate: reviewDate | i18n_date: 'd MMMM YYYY' | async } }}</mat-chip-option>
    </mat-chip-listbox>

    <br />

    <h3>{{ 'review_preview.title' | translate }}</h3>

    <p>"{{ review.review }}"</p>

    <ng-container *ngIf="review.recommendation?.length > 0">
      <h3>{{ 'review_preview.tips.title' | translate }}</h3>

      <p>"{{ review.recommendation }}"</p>
    </ng-container>

    <div fxFlex fxLayoutGap="16px" fxLayoutAlign="end center" *ngIf="admin">
      <button mat-flat-button color="warning" (click)="deny()" *ngIf="this.selectedStatusOption !== 'DENIED'">
        <mat-icon>close</mat-icon>
        {{ 'review_preview.status.change.deny' | translate }}
      </button>
      <button mat-flat-button color="primary" (click)="approve()" *ngIf="this.selectedStatusOption !== 'APPROVED'">
        <mat-icon>checkmark</mat-icon>
        {{ 'review_preview.status.change.approve' | translate }}
      </button>
    </div>
  </mat-card>
</div>
