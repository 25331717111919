import {
  Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, ViewChildren, QueryList,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PaginationInstance } from 'ngx-pagination';
import {
  Observable, Subject, BehaviorSubject, firstValueFrom,
} from 'rxjs';
import {
  takeUntil, auditTime, map, distinctUntilChanged, filter,
} from 'rxjs/operators';
import {
  isNil, each, isArray, size, omitBy, isEqual, isEmpty, set,
} from 'lodash';
import { IOrganizationUnitOverview, ISearchActivity } from 'typings/doenkids/doenkids';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { NgxMasonryComponent } from 'src/components/layout/ngx-masonry/ngx-masonry.component';
import { fadeInOut } from 'src/animations';
import { IField } from 'src/components/layout/sortable-list/sortable-list.component';
import { IBaseActivitySearchRequest, IActivitySearchRequest } from 'typings/api-search';
import { SplitPageComponent } from 'src/components/layout/split-page/split-page.component';
import { ActivityAggregationsProvider } from 'src/providers/activity-aggregations.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { BaseActivitySearchService } from 'src/api/search/base-activity/base-activity-search.service';
import { BaseActivitySearchQuery, IBaseActivitySearchMetadata } from 'src/api/search/base-activity/base-activity-search.query';
import { Router, NavigationEnd } from '@angular/router';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { ACTIVITY_SEARCH_FIELDS } from '../activity-search/activity-search.component';
import { TranslateService } from '../../../app/utils/translate.service';
import { ISearchEvent } from 'src/components/shared/activity-search-bar/activity-search-bar.component';
import { ScrollService } from 'src/app/utils/scroll.service';
import { ActivityTypeQuery } from 'src/api/generic/activity-type/activity-type.query';

interface IStoredBaseSearch {
  queryParams: IBaseActivitySearchRequest;
  concat: boolean;
  aggregations: any;
}

@Component({
  selector: 'app-base-activity-search',
  templateUrl: './base-activity-search.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./base-activity-search.component.scss'],
  animations: [fadeInOut],
})
export class BaseActivitySearchComponent implements OnInit, OnDestroy {
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  @ViewChild(NgxMasonryComponent, { read: ElementRef }) masonryEl: ElementRef;

  @ViewChild('pageContainer', { read: ElementRef }) pageEl: ElementRef;

  @ViewChild(SplitPageComponent) splitPage: SplitPageComponent;

  @ViewChildren('resultsHeader', { read: ElementRef }) resultsHeader: QueryList<ElementRef>;

  public backUrl$: Observable<string>;

  public SEARCH_LIMIT = 15;

  public aggregationControl: UntypedFormControl = new UntypedFormControl();

  /** The labels for the aggregationlist */
  public aggregationLabels: Observable<{ [index: string]: string }>;

  // Observable that will inform us if an aggregation is selected on the lefthand-side
  //
  public containsAggregations$: Observable<boolean>;

  private fetchActivities$: Subject<{
    queryParams: IBaseActivitySearchRequest,
    concatenateResults?: boolean,
    fetchAllAndIgnoreSkip?: boolean,
    aggregations?: any;
  }> = new Subject();

  private stop$ = new Subject();

  public currentPage: number;

  public showFilters: boolean;

  public baseSearchResults$: Observable<ISearchActivity[]>;

  public baseAggregations$: Observable<any>;

  public aggregations$: Observable<any>;

  public isSearching$: Observable<boolean>;

  public baseActivitySearchMetadata$: Observable<IBaseActivitySearchMetadata>;

  private imagesLoaded$ = new Subject();

  public isSmall$: Observable<boolean>;

  public baseSearchSuggestions$: Observable<any>;

  public isAdmin$: Observable<boolean>;

  public hideAggregations$ = new BehaviorSubject<boolean>(false);

  public toolBarContentOpen = new BehaviorSubject<boolean>(false);

  public currentSearchParams: IBaseActivitySearchRequest;

  public hasSelectedOUWritePermission$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentOUDetails$: Observable<IOrganizationUnitOverview>;

  private baseActivitySearchStorageKey = 'baseActivitySearch';

  public searchResultsDefaultTopPadding = 64;

  public searchResultsDefaultBottomMargin = 16;

  public fields: IField[] = [];

  /**
   * The configuration object for the pagination of the list.
   */
  public paginationConfig$: Observable<PaginationInstance>;

  private hasScrolledToStoredActivity = false;

  // Update the masonry layout
  //
  updateMasonryLayout() {
    if (this.masonry) {
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  constructor(
    private router: Router,
    private activityAggregationsProvider: ActivityAggregationsProvider,
    private sessionProvider: DoenkidsSessionProvider,
    private baseActivitySearchService: BaseActivitySearchService, private baseActivitySearchQuery: BaseActivitySearchQuery,
    private breakPointsProvider: BreakpointsProvider,
    private $session: DoenkidsSessionProvider,
    private $translateService: TranslateService,
    private scrollService: ScrollService,
    private activityTypeQuery: ActivityTypeQuery,
  ) {
    this.aggregationLabels = this.activityAggregationsProvider.baseAggregationLabels$.asObservable();

    this.containsAggregations$ = this.aggregationControl.valueChanges.pipe(
      map((aggregations) => {
        let containsOptions = false;
        each(aggregations, (value) => {
          if (isArray(value) && size(value) > 0) {
            containsOptions = true;
          }
        });
        return containsOptions;
      }),
    );

    this.baseSearchResults$ = this.baseActivitySearchQuery.selectAll();

    this.baseAggregations$ = this.activityAggregationsProvider.sortedBaseAggregations$;

    this.aggregations$ = this.activityAggregationsProvider.sortedAggregations$;

    this.isSearching$ = this.baseActivitySearchQuery.selectLoading();

    this.baseActivitySearchMetadata$ = this.baseActivitySearchQuery.getMetadata;

    this.isSmall$ = this.breakPointsProvider.isSmall$;

    this.baseSearchSuggestions$ = this.baseActivitySearchQuery.getSuggestions;

    this.isAdmin$ = this.sessionProvider.isAdmin$;

    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
    );

    this.paginationConfig$ = this.baseActivitySearchMetadata$.pipe(
      map((metaData) => {
        const paginationConfig: PaginationInstance = {
          currentPage: this.currentPage,
          itemsPerPage: metaData.limit,
          totalItems: metaData.total,
        };

        return paginationConfig;
      }),
    );
  }

  async ngOnInit() {
    const organizationUnitId = await firstValueFrom(this.sessionProvider.currentOuId$);
    const contentLanguage = await firstValueFrom(this.$session.preferredContentLanguage$);
    this.currentSearchParams = {
      query: '',
      limit: this.SEARCH_LIMIT,
      skip: 0,
      organizationUnitId,
      filter: {},
      language: contentLanguage,
    };

    this.$translateService.onInitialTranslationAndLangOrTranslationChange$
      .pipe(takeUntil(this.stop$))
      .subscribe((event) => {
        this.fields = ACTIVITY_SEARCH_FIELDS.map((field) => ({
          ...field,
          label: field.label ? event.translations[field.label] : '',
        }));
      });

    // Make sure we don't fetch activities unnecessarily
    //
    this.fetchActivities$.pipe(
      filter((request) => !isNil(request)),
      takeUntil(this.stop$),
      distinctUntilChanged(isEqual),
    ).subscribe((request) => {
      this.dofetchActivities(request.queryParams, request.concatenateResults, request.fetchAllAndIgnoreSkip);
    });

    // When a new organization is selected search again
    //
    this.$session.getOrganizationUnitSwitch$.pipe(takeUntil(this.stop$)).subscribe(async (ou) => {
      // navigate away if no longer a root node else set ou id for search and do search
      //
      if (ou.parent_organization_unit_id !== null || ou.id === DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER) {
        this.router.navigate(['/activities']);
      } else {
        this.currentSearchParams.organizationUnitId = ou.id;
        this.currentSearchParams.activityTypeId = isEmpty(ou.activity_type_ids) ?
          this.activityTypeQuery.getActivityTypeByCountryCode(ou.country_code).map((activityType) => activityType.id)
          : ou.activity_type_ids.map((activityTypeId) => parseInt(`${activityTypeId}`, 10));

        if (!ou.languages.includes(this.currentSearchParams.language)) {
          this.currentSearchParams.language = await firstValueFrom(this.$session.preferredContentLanguage$);
        }

        this.fetchActivities$.next({
          queryParams: this.currentSearchParams,
          concatenateResults: false,
          fetchAllAndIgnoreSkip: true,
          aggregations: this.getAggregationControlValue(),
        });
      }
    });

    // Wait for the a first emission of the ou selected. We need this to actually know in what context we need to search
    //
    this.currentSearchParams.activityTypeId = await firstValueFrom(this.$session.organizationActivityTypeIds$);

    // Execute a stored search if there is one.
    //
    await this.storedSearch();

    // at this point the status filters have been set with initial filters and possible stored values so we can see what the current values
    // are and set the write permission accordingly
    //
    this.setUpSubscriptions();
  }

  private async storedSearch() {
    const searchValuesJSON = localStorage.getItem(this.baseActivitySearchStorageKey);

    if (isNil(searchValuesJSON)) {
      this.fetchActivities$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
      });
      return;
    }

    const storedSearch: IStoredBaseSearch = JSON.parse(searchValuesJSON);
    await this.setUpStoredSearch(storedSearch);

    this.fetchActivities$.next({
      queryParams: this.currentSearchParams,
      concatenateResults: false,
      fetchAllAndIgnoreSkip: true,
      aggregations: this.getAggregationControlValue(),
    });
  }

  private async setUpStoredSearch(storedSearch: IStoredBaseSearch) {
    const organizationUnitDetails = await firstValueFrom(this.currentOUDetails$);
    const organizationUnitId = organizationUnitDetails.id;

    // when the stored search if for another OU stop with setup as it is not a valid search for this OU
    //
    if (storedSearch.queryParams.organizationUnitId !== organizationUnitId) {
      return;
    }

    // get the aggregation settings from the stored search
    //
    this.aggregationControl.setValue(storedSearch.aggregations);

    // eslint-disable-next-line prefer-destructuring
    const queryParams: IBaseActivitySearchRequest | IActivitySearchRequest = storedSearch.queryParams;

    this.currentSearchParams = queryParams;

    if (!organizationUnitDetails.languages.includes(queryParams.language)) {
      this.currentSearchParams.language = await firstValueFrom(this.$session.preferredContentLanguage$);
    }
  }

  private setUpSubscriptions() {
    this.router.events.pipe(takeUntil(this.stop$)).subscribe((event) => {
      if (event instanceof NavigationEnd && event.url === '/base-activities') {
        this.dofetchActivities(this.currentSearchParams);
      }
    });

    // Listen for updates of the activities
    //
    this.baseSearchResults$.pipe(
      takeUntil(this.stop$),
    ).subscribe(() => {
      this.updateMasonryLayout();
    });

    // Update masonay when all images are loaded
    //
    this.imagesLoaded$.pipe(
      takeUntil(this.stop$),
      auditTime(300),
    ).subscribe(async () => {
      this.updateMasonryLayout();
      const mainScrollContainer = document.getElementById('main-scroll-container');

      this.scrollService.applySavedScroll('base-activity-search', mainScrollContainer);
    });

    // Prepare a observable that is true when the aggragations that are selected have changed
    //
    this.aggregationControl.valueChanges.pipe(
      distinctUntilChanged((a, b) => {
        // Only compare the selected values, because new aggregations can be added
        // during a text search, this should not interfere with this subscription
        //
        const newA = omitBy(a, isEmpty);
        const newB = omitBy(b, isEmpty);
        return isEqual(newA, newB);
      }),
    ).subscribe((aggregations) => {
      this.fetchActivities$.next({
        queryParams: this.currentSearchParams,
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations,
      });
    });
  }

  public removeFilter($event: { name: string; value: string }) {
    const { name, value } = $event;
    const currentSelection: { [name: string]: string[] } = this.aggregationControl.value;
    if (currentSelection && currentSelection[name] && isArray(currentSelection[name]) && currentSelection[name].includes(value)) {
      currentSelection[name] = currentSelection[name].filter((label) => label !== value);
      this.aggregationControl.setValue(currentSelection);
    }
  }

  public removeAllFilters() {
    const currentSelection: { [name: string]: string[] } = this.aggregationControl.value;
    if (currentSelection) {
      each(currentSelection, (value, key) => {
        set(currentSelection, key, []);
      });
      this.aggregationControl.setValue(currentSelection);
    }
  }

  public trySuggestion(suggestion: string, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.fetchActivities$.next({
      queryParams: {
        ...this.currentSearchParams,
        query: suggestion ?? '',
      },
      concatenateResults: false,
      fetchAllAndIgnoreSkip: true,
      aggregations: this.getAggregationControlValue(),
    });
  }

  public async loadMoreActivities() {
    const currentSearchResultsLength = ((await firstValueFrom(this.baseSearchResults$)) ?? []).length;
    if (currentSearchResultsLength > 0) {
      const offset = this.currentSearchParams.skip + this.currentSearchParams.limit;

      const { total } = (await firstValueFrom(this.baseActivitySearchMetadata$));

      // Check if there are more to fetch
      //
      if (offset < total) {
        this.fetchActivities$.next({
          queryParams: {
            ...this.currentSearchParams,
            skip: offset,
          },
          concatenateResults: true,
          fetchAllAndIgnoreSkip: false,
          aggregations: this.getAggregationControlValue(),
        });
      }
    }
  }

  private async dofetchActivities(queryParams: IBaseActivitySearchRequest, concatenateResults = false, fetchAllAndIgnoreSkip = false) {
    if (queryParams.skip === 0) {
      concatenateResults = false;
    }

    const OUDetails = await firstValueFrom(this.currentOUDetails$);
    const aggregations = this.getAggregationControlValue();

    // default set the params for the base activity search
    //
    this.currentSearchParams = {
      ...queryParams,
      organizationUnitId: OUDetails.id,
      filter: aggregations,
      language: 'nl',
      countryCode: 'NL',
    };

    // if we have a specific activityTypeId set this to the request. this can be set on the base search as the regular search
    //
    if (!isEmpty(OUDetails?.activity_type_ids)) {
      this.currentSearchParams.activityTypeId = OUDetails.activity_type_ids.map((activityTypeId) => parseInt(`${activityTypeId}`, 10));
    }

    // When restoring a search from locale storage
    // we want to do a full search and igore the skip parameter
    //
    if (fetchAllAndIgnoreSkip === true) {
      this.currentSearchParams.limit += this.currentSearchParams.skip;
      this.currentSearchParams.skip = 0;
    }

    // Store the search params to use later.
    //
    const storedSearch: IStoredBaseSearch = {
      queryParams: this.currentSearchParams,
      concat: concatenateResults,
      aggregations,
    };
    localStorage.setItem(this.baseActivitySearchStorageKey, JSON.stringify(storedSearch));

    this.baseActivitySearchService.fetch(this.currentSearchParams, concatenateResults);
  }

  imageLoaded() {
    this.imagesLoaded$.next(undefined);
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  async activityClicked(clickedActivity: ISearchActivity) {
    const scrollDiv = document.getElementById('main-scroll-container');
    const scrollPosition = scrollDiv?.scrollTop;
    this.scrollService.setScroll('base-activity-search', scrollPosition);
    this.router.navigate([`/base-activities/preview/${clickedActivity.id}`]);
  }

  onScrollEnd() {
    this.loadMoreActivities();
  }

  tabChange(tab: MatTabChangeEvent) {
    if (tab.index === 1) {
      setTimeout(() => this.updateMasonryLayout(), 0);
    }
  }

  async toggleAggregations(active?: boolean) {
    active = !isNil(active) ? active : !(await firstValueFrom(this.hideAggregations$));

    this.hideAggregations$.next(active);

    setTimeout(() => {
      this.updateMasonryLayout();
    }, 300);
  }

  public updateToolbarMenuOpen(toolBarMenuIsOpen: boolean) {
    this.toolBarContentOpen.next(toolBarMenuIsOpen);
  }

  doSearch(searchEvent: ISearchEvent) {
    const currentQuery = this.currentSearchParams?.query ?? '';
    const newQuery = searchEvent.query ?? this.currentSearchParams?.query ?? '';
    const currentLang = this.currentSearchParams?.language ?? '';
    const newLang = searchEvent.language ?? this.currentSearchParams?.language ?? '';

    if (!isEqual(currentQuery, newQuery) || !isEqual(currentLang, newLang)) {
      this.fetchActivities$.next({
        queryParams: {
          ...this.currentSearchParams,
          query: newQuery,
          language: newLang,
        },
        concatenateResults: false,
        fetchAllAndIgnoreSkip: true,
        aggregations: this.getAggregationControlValue(),
      });
    }
  }

  getAggregationControlValue() {
    const aggregations: { [index: string]: [any] } = isNil(this.aggregationControl.value) ? {} : this.aggregationControl.value;

    const dedupedAggregations: { [index: string]: string[] } = {};

    Object.keys(aggregations).forEach((aggregationKey: string) => {
      const aggregationValues: string[] = aggregations[aggregationKey];

      const dedupedAggregationValues: string[] = [];

      aggregationValues.forEach((aggregationValue) => {
        if (!dedupedAggregationValues.includes(aggregationValue)) {
          dedupedAggregationValues.push(aggregationValue);
        }
      });

      dedupedAggregations[aggregationKey] = dedupedAggregationValues;
    });

    return dedupedAggregations;
  }
}
