import { AfterViewInit, Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { SwiperOptions } from 'swiper';

export const swiperStyles = `
  .swiper-button-next::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;
    background: linear-gradient(90deg, #00000000 0%, #ffffff60 100%);
  }
  .swiper-button-prev::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    background: linear-gradient(270deg, #00000000 0%, #ffffff60 100%);
  }

  .swiper-button-next::after {
    transition: transform 200ms;
  }
  .swiper-button-prev::after {
    transition: transform 200ms;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #e11f7c;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;
    top: 0;
    margin-top: 0;
    font-size: 40px;
    transition: opacity 200ms;
    opacity: 0;
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    opacity: 1;
  }

  .swiper-button-next:hover::before,
  .swiper-button-prev:hover::before {
    opacity: 1;
  }

  .swiper-button-next:hover::after,
  .swiper-button-prev:hover::after {
    opacity: 1;
    transform: scale(1.2);
  }

  .swiper-button-next:active::after,
  .swiper-button-prev:active::after {
    transform: scale(0.9) !important;
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }
  `;

@Directive({
  selector: '[dkSwiper]',
})
export class SwiperDirective implements AfterViewInit, OnChanges {

  @Input('config')
    config?: SwiperOptions;

  constructor(private containerRef: ViewContainerRef) {}

  ngAfterViewInit() {
    if (this.containerRef.element?.nativeElement) {
      Object.assign(this.containerRef.element.nativeElement, this.config);

      // @ts-ignore
      this.containerRef.element.nativeElement.initialize();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.containerRef.element && changes.config && !changes.config.firstChange) {
      this.setNestedConfigProperty(this.containerRef.element.nativeElement, changes.config.currentValue);
    }
  }

  setNestedConfigProperty(element: HTMLElement, object: any) {
    for (const configKey of Object.keys(object)) {
      if (configKey === 'slidesPerView' || configKey === 'spaceBetween') {
        element[configKey] = object[configKey];
      }
    }
  }
}
