import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MediaSearchStore, IMediaSearchState } from './media-search.store';

@Injectable({ providedIn: 'root' })
export class MediaSearchQuery extends QueryEntity<IMediaSearchState, any> {
  constructor(protected store: MediaSearchStore) {
    super(store);
  }

  get getAggregations() {
    return this.select((value) => value.aggregations);
  }

  get getSuggestions() {
    return this.select((value) => value.suggest);
  }

  get getMetadata() {
    return this.select((value) => ({
      total: value.total,
      skip: value.skip,
      limit: value.limit,
      query: value.query,
      aggregations: value.aggregations,
      took: value.took,
      suggest: value.suggest,
    }));
  }
}
