import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointsProvider {

  public isXSmall$: Observable<boolean>;

  public isSmall$: Observable<boolean>;

  public isMedium$: Observable<boolean>;

  public isLarge$: Observable<boolean>;

  public isXLarge$: Observable<boolean>;

  public isMobile$: Observable<boolean>;

  public isHandset$: Observable<boolean>;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    this.isXSmall$ = this.breakpointObserver
      .observe( [ Breakpoints.XSmall ] )
      .pipe( map( result => result.matches ) );

    this.isSmall$ = this.breakpointObserver
      .observe( [ Breakpoints.Small, Breakpoints.XSmall ] )
      .pipe( map( result => result.matches ) );

    this.isMedium$ = this.breakpointObserver
      .observe( [ Breakpoints.Medium ] )
      .pipe( map( result => result.matches ) );

    this.isLarge$ = this.breakpointObserver
      .observe( [ Breakpoints.Large, Breakpoints.XLarge ] )
      .pipe( map( result => result.matches ) );

    this.isXLarge$ = this.breakpointObserver
      .observe( [ Breakpoints.XLarge ] )
      .pipe( map( result => result.matches ) );

    this.isMobile$ = this.breakpointObserver
      .observe( [ Breakpoints.Handset, Breakpoints.Tablet ] )
      .pipe( map( result => result.matches ) );

    this.isHandset$ = this.breakpointObserver
      .observe( [ Breakpoints.Handset ] )
      .pipe( map( result => result.matches ) );
  }
}
