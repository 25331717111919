import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNil } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { PinbordProvider } from 'src/providers/pinbord.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IFavoriteFolderDetails } from 'typings/doenkids/doenkids';

@Component({
  selector: 'app-add-new-pinbord-folder-dialog',
  templateUrl: './add-new-pinbord-folder-dialog.component.html',
  styleUrls: ['./add-new-pinbord-folder-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddNewPinbordFolderDialogComponent implements OnInit {
  public folderName = new UntypedFormControl('', [Validators.required]);

  private currentFolders: IFavoriteFolderDetails[] = [];

  private currentFolderNameValidator = (folders: IFavoriteFolderDetails[], currentFolder?: IFavoriteFolderDetails) => (formControl: UntypedFormControl): ValidationErrors | null => {
    if (isNil(currentFolder) && folders.find((folder) => folder.name === formControl.value)) {
      return { duplicate_name: true };
    }

    return null;
  };

  constructor(
    private dialogRef: MatDialogRef<AddNewPinbordFolderDialogComponent>,
    private $pinbord: PinbordProvider,
    private $session: DoenkidsSessionProvider,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      folder?: IFavoriteFolderDetails
    },
  ) {}

  async ngOnInit() {
    if (this.data?.folder) {
      this.folderName.setValue(this.data.folder.name);
    }
    this.currentFolders = (await firstValueFrom(this.$pinbord.currentFolders$)).folders;
    this.folderName.setValidators([Validators.required, this.currentFolderNameValidator(this.currentFolders, this.data?.folder)]);
  }

  onClose() {
    this.dialogRef.close();
  }

  async onAdd() {
    const organizationUnit = await firstValueFrom(this.$session.getOrganizationUnit$);
    const currentOuId = organizationUnit.id;

    let folder: IFavoriteFolderDetails;

    if (this.data?.folder) {
      folder = await this.$pinbord.updateFolder(currentOuId, this.data.folder.id, this.folderName.value);
    } else {
      folder = await this.$pinbord.addFolder(currentOuId, this.folderName.value);
    }

    this.dialogRef.close({
      folder,
    });
  }
}
