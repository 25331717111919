import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ProgramListStore } from './program-list.store';
import { IProgramListRequest, IProgramListResponse } from '../../../../typings/api-activity';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramListService {
  constructor(private store: ProgramListStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(params: IProgramListRequest, doNotPerist: boolean = false): Promise<IProgramListResponse> {
    this.store.setLoading(true);
    try {
      const response = await firstValueFrom(this.$baseApi.genericPostCall('/activity/program/list', params)) as IProgramListResponse;
      if (!doNotPerist) {
        this.store.set(response?.items ?? []);
      }
      this.store.setLoading(false);
      return response;
    } catch (error) {
      return null;
    }
    this.store.setLoading(false);
    return null;
  }
}
