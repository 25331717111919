<app-doenkids-menu-bar [showBackButton]="true" [loading]="loading$ | async">
</app-doenkids-menu-bar>
<div class="program-template-reorder">
  <h2 *ngIf="programCategoryDetails">{{ programCategoryDetails.name }}</h2>

  <mat-tab-group [(selectedIndex)]="selectedTab" *ngIf="programCategoryTemplates?.length > 0 && programCategoryTemplateBundles?.length > 0" mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab [label]="'program_template_reorder.tab.templates' | translate">
      <ng-container *ngTemplateOutlet="programCategoryTemplateList"></ng-container>
    </mat-tab>
    <mat-tab [label]="'program_template_reorder.tab.template_bundles' | translate">
      <ng-container *ngTemplateOutlet="programCategoryTemplateBundleList"></ng-container>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="programCategoryTemplates?.length > 0 && programCategoryTemplateBundles?.length === 0">
    <ng-container *ngTemplateOutlet="programCategoryTemplateList"></ng-container>
  </div>

  <div *ngIf="programCategoryTemplates?.length === 0 && programCategoryTemplateBundles?.length > 0">
    <ng-container *ngTemplateOutlet="programCategoryTemplateBundleList"></ng-container>
  </div>
</div>

<ng-template #programCategoryTemplateList>
  <div cdkDropList class="program-category-template-list" (cdkDropListDropped)="droppedTemplate($event)">
    <div class="category-box" [class.draggable]="(isAdmin$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
      *ngFor="let template of programCategoryTemplates"
      cdkDrag
      [cdkDragDisabled]="(isAdmin$ | async) ? false : !(hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
    >
      <app-doenkids-image
        [preview]="true"
        [mediaItem]="template.media_uuid"
        [maxWidth]="100"
      ></app-doenkids-image>
      <span>{{template.name}}</span>
    </div>
  </div>
</ng-template>

<ng-template #programCategoryTemplateBundleList>
  <div cdkDropList class="program-category-template-bundle-list" (cdkDropListDropped)="droppedTemplateBundle($event)">
    <div class="category-box" [class.draggable]="(isAdmin$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
      *ngFor="let templateBundle of programCategoryTemplateBundles"
      cdkDrag
      [cdkDragDisabled]="(isAdmin$ | async) ? false : !(hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)"
    >
      <app-doenkids-image
        [preview]="true"
        [mediaItem]="templateBundle.media_uuid"
        [maxWidth]="100"
      ></app-doenkids-image>
      <span>{{templateBundle.name}}</span>
    </div>
  </div>
</ng-template>
