import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { UserFetchEventsStore } from './user-fetch-events.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFetchEventsService {
  @transaction()
  async fetch(
    userId: number,
    limit: number = 100,
    skip: number = 0,
  ) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(
      `/customer/user/${userId}/event-log?limit=${limit}&skip=${skip}`,
    )) as any;

    const hits = response?.items ?? [];
    this.store.set(hits);

    this.store.update({
      limit,
      skip,
      total: response?.total ?? 0,
    });

    return hits;
  }

  constructor(
    private store: UserFetchEventsStore,
    private $baseApi: DoenKidsGenericApiProvider,
  ) { }
}
