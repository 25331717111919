<h2 mat-dialog-title>{{ 'dialog.add_existing_user.title' | translate }}</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>{{ 'user.email' | translate }}</mat-label>
    <input matInput [formControl]="userName" [matAutocomplete]="auto" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUser">
      <mat-option *ngFor="let user of (filteredUsers$ | async)" [value]="user">
        {{ user.email }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="closeDialog()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <button mat-button color="primary" (click)="onCloseToConnect()">{{ 'generic.save_and_link' | translate }}</button>
  <button mat-button color="primary" (click)="onOkClick()" cdkFocusInitial>{{ 'generic.save' | translate }}</button>
</mat-dialog-actions>
