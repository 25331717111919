import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { IActivityTypeListResponse } from 'typings/api-activity';
import { ActivityTypeStore } from './activity-type.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityTypeService {
  constructor(private store: ActivityTypeStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const activityTypeListRepsonse = await firstValueFrom(this.$baseApi.genericListCall('/activity/activity-type', limit, skip, sortField, sortDirection)) as IActivityTypeListResponse;

    this.store.set(activityTypeListRepsonse?.items ?? []);

    return activityTypeListRepsonse.items;
  }
}
