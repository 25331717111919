import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-insights-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultsComponent {
  @Input() title?: string;
}
