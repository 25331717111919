import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionProvider } from 'src/providers/permission.provider';
import { Location as ALocation } from '@angular/common';
import { isNil } from '@datorama/akita';
import { filter } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

export const isOrganizationManagerGuard = async () => {
  const location = inject(ALocation);
  const router = inject(Router);
  const $permission = inject(PermissionProvider);

  const hasParentOUWritePermission = await firstValueFrom($permission.hasParentOUWritePermissions$.pipe(filter((value) => !isNil(value))));

  if (!hasParentOUWritePermission && router.navigated) {
    location.back();
  } else if (!hasParentOUWritePermission) {
    router.navigate(['/']);
  }

  return hasParentOUWritePermission;
};
