import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { IProgramTemplateAttachmentMedia } from 'typings/doenkids/doenkids';

export interface IDoenkidsProgramTemplateAttachmentState extends EntityState<IProgramTemplateAttachmentMedia> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-attachment-list' })
export class ProgramTemplateAttachmentStore extends EntityStore<IDoenkidsProgramTemplateAttachmentState, IProgramTemplateAttachmentMedia> {
  constructor() {
    super([]);
  }
}
