<div mat-dialog-title>
  <h3>{{ data.title }}</h3>
</div>
<div mat-dialog-content>
  <div>
    <p>{{ 'migrate_period_sections.period.description' | translate }}</p>
    <div [formGroup]="programDateForm" fxLayout="row" fxLayoutGap="20px">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'generic.period.start_date' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          [max]="programDateForm.get('to').value"
          [placeholder]="'generic.period.start_date' | translate"
          formControlName="from"
        >
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'generic.period.end_date' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="endDate"
          [min]="programDateForm.get('from').value"
          [placeholder]="'generic.period.end_date' | translate"
          formControlName="to"
        >
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate ></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div>
    <p>{{ 'migrate_period_sections.target_program.description' | translate }}</p>
    <p>{{ 'migrate_period_sections.target_program.secondary_description' | translate }}</p>
    <mat-form-field appearance="outline" class="full-width-form-field">
      <mat-select [formControl]="selectedProgram">
        <mat-option *ngFor="let program of programList" [value]="program.id">{{ program.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <p>{{ 'migrate_period_sections.target_period.description' | translate }}</p>
    <mat-form-field appearance="outline" class="full-width-form-field">
      <mat-select [formControl]="selectedPeriod" [placeholder]="'migrate_period_sections.target_period.placeholder' | translate">
        <mat-option *ngFor="let period of periodList?.items" [value]="period.id">
          <ng-container *ngIf="period.period_type === 'FREEFORM'">
            {{ period?.name ? period.name : ('generic.field' | translate) }}
          </ng-container>
          <ng-container *ngIf="period.period_type === 'DATE'">
            {{ period?.start_date
              ? (period.start_date | i18n_date: 'fullDate' | async | titlecase)
              : ('generic.field' | translate) }}
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" fxFlexFill>
  <button mat-button (click)="cancel()">
    {{ data.cancelText }}
  </button>
  <button mat-button color="primary" (click)="close()" [disabled]="!selectedPeriod.valid || selectedPeriod.value === data.periodId || selectedPeriod.value === 0">
    {{ data.confirmText }}
  </button>
</div>
