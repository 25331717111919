<ng-container *ngIf="message">
  <h1 class="title">{{ message.subject }}</h1>
  <div class="details" fxLayoutAlign="space-between center">
    <span class="date">
      {{ message.organization_unit_name }} •
      {{ message.show_from | from_now: 'D MMMM YYYY' | async }}
    </span>
    <div class="actions" fxLayoutAlign="center center" *ngVar="(read$ | async) as readStatus">
      <button
        mat-icon-button
        [color]="readStatus ? 'primary' : 'accent'"
        [matTooltip]="(readStatus ? 'newsfeed.item.mark_as_unread.tooltip' : 'newsfeed.item.mark_as_read.tooltip') | translate"
        (click)="changeReadStatus()"
        *ngIf="(partOfOrganization$ | async)"
      >
        <mat-icon>visibility</mat-icon>
      </button>
      <a mat-icon-button [matTooltip]="'newsfeed.item.mail.tooltip' | translate" (click)="mailTo()" rel="noopener noreferrer" target="_blank">
        <mat-icon>send</mat-icon>
      </a>
      <button
        *ngIf="shouldShowBaseNewsItemCallToAction$ | async"
        color="accent"
        mat-button
        [matTooltip]="'newsfeed.item.copy.tooltip' | translate"
        (click)="publishToOwnOrganization()"
      >
        <mat-icon>content_copy</mat-icon> {{ 'newsfeed.item.copy.label' | translate }}
      </button>
    </div>
  </div>
  <div class="content">
    <app-doenkids-image fxLayoutAlign="center center" [mediaItem]="{ uuid: message.media_uuid, description: '' }"> </app-doenkids-image>
    <span class="item-content" [innerHtml]="message.content | safe: 'html'"></span>
  </div>
</ng-container>
