import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IAuthenticatedUser } from 'typings/api-customer';
import { CurrentUserStore } from './auth.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(private store: CurrentUserStore, private $baseApi: DoenKidsGenericApiProvider) {}

  @transaction()
  async fetch() {
    const authenticatedUser = await firstValueFrom(this.$baseApi.genericGetCall('/customer/auth')) as IAuthenticatedUser;
    this.store.update(authenticatedUser);

    return authenticatedUser;
  }
}
