<h3 mat-dialog-title>{{ 'dialog.add_activity_to_pinboard_folder.title' | translate: {activityCount: data.activities.length} }}</h3>
<mat-dialog-content>
  <ng-container *ngIf="currentFolders.length === 0">
    <span>{{ 'dialog.add_activity_to_pinboard_folder.no_pinboards' | translate }}</span>
  </ng-container>
  <ng-container *ngIf="currentFolders.length === 1 && data.activities.length === 1 && !activityIsAlreadyOnFolder(data.activities[0], currentFolders[0].id)">
    <span [innerHTML]="'dialog.add_activity_to_pinboard_folder.confirm' | translate: {folder: currentFolders[0].name}"></span>
  </ng-container>
  <ng-container *ngIf="currentFolders.length === 1 && data.activities.length === 1 && activityIsAlreadyOnFolder(data.activities[0], currentFolders[0].id)">
    <span [innerHTML]="'dialog.add_activity.to_pinboard_folder.already_in_folder' | translate: {folder: currentFolders[0].name}"></span>
  </ng-container>
  <ng-container *ngIf="currentFolders.length === 1 && data.activities.length > 1">
    <span [innerHTML]="'dialog.add_activity_to_pinboard_folder.confirm' | translate: {folder: currentFolders[0].name}"></span>
  </ng-container>
  <ng-container *ngIf="currentFolders.length > 1">
    <div class="activity" *ngIf="data.activities.length === 1">
      <div class="image-container">
        <app-doenkids-image [mediaItem]="{ uuid: data.activities[0].media_uuid , description: '' }"></app-doenkids-image>
      </div>
      <div class="text-container">
        <h2 class="activity-title">
          {{ data.activities[0].name !== '' ? data.activities[0].name : ('generic.new' | translate) }}
        </h2>
        <h3>
          {{ data.activities[0].subtitle }}
        </h3>
      </div>
    </div>
    <div *ngIf="data.activities.length > 1">
      <h2>{{ 'generic.activities' | translate }}</h2>
      <p *ngFor="let activity of data.activities">{{ activity.name }}</p>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'dialog.activity_to_pinboard_folder.select.label' | translate }}</mat-label>
      <mat-select [formControl]="selectedFolder" multiple>
        <ng-container *ngFor="let folder of currentFolders">
          <mat-option [value]="folder.id">
            {{ folder.name }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" fxFlexFill>
  <button mat-button color="primary" (click)="onClose()">{{ 'generic.close' | translate }}</button>
  <button
    mat-button color="primary"
    (click)="onAdd()"
    [disabled]="
      !selectedFolder.valid || currentFolders.length === 0
      || (
        currentFolders.length === 1
        && data.activities.length === 1
        && activityIsAlreadyOnFolder(data.activities[0], currentFolders[0].id)
      )
    "
  >
    {{ 'dialog.add_activity_to_pinboard_folder.add' | translate }}
  </button>
</mat-dialog-actions>
