import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { IField, IReloadEvent } from 'src/components/layout/sortable-list/sortable-list.component';
import { IEventLogListResponse } from 'typings/api-customer';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '../../../app/utils/translate.service';

const FIELDS = [
  {
    label: _('activity.event_log.field.who'),
    field: 'email',
    width: '15%',
  },
  {
    label: _('activity.event_log.field.when'),
    field: 'last_seen',
    width: '15%',
    hideLessThanMedium: true,
  },
  {
    label: _('activity.event_log.field.for'),
    field: 'organization_unit_id',
    width: '15%',
    hideLessThanMedium: true,
  },
  {
    label: _('activity.event_log.field.action'),
    field: 'event_type',
    width: '15%',
  },
  {
    label: _('activity.event_log.field.what'),
    field: 'user_role',
    width: '40%',
  },
];

@Component({
  selector: 'app-activity-event-log',
  templateUrl: './activity-event-log.component.html',
  styleUrls: ['./activity-event-log.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityEventLogComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  public selectedActivityId: number;

  public eventList$: BehaviorSubject<IEventLogListResponse> = new BehaviorSubject<IEventLogListResponse>(null);

  public currentPage: number;

  public SEARCH_LIMIT = 50;

  public lastReloadEvent: IReloadEvent;

  public lastUpdated = new Date();

  public fields$: Observable<IField[]>;

  /**
   * The configuration object for the pagination of the list.
   */
  public paginationConfig$: Observable<PaginationInstance>;

  constructor(
    private $activityService: ActivityService,
    private route: ActivatedRoute,
    private $translateService: TranslateService,
  ) {
    this.fields$ = this.$translateService.onInitialTranslationAndLangOrTranslationChange$.pipe(
      takeUntil(this.stop$),
      map((event) => FIELDS.map((field) => ({
        ...field,
        label: event.translations[field.label],
      }))),
    );

    this.paginationConfig$ = this.eventList$.pipe(
      takeUntil(this.stop$),
      map((metaData: IEventLogListResponse) => {
        const paginationConfig: PaginationInstance = {
          currentPage: (metaData.total > metaData.limit) && metaData.skip !== 0 ? (Math.ceil(metaData.skip / metaData.limit) + 1) : 1,
          itemsPerPage: metaData.limit,
          totalItems: metaData.total,
        };
        return paginationConfig;
      }),
    );
  }

  ngOnInit(): void {
    const { params } = this.route.snapshot;
    this.selectedActivityId = +params.id;
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  public async getList($event: IReloadEvent) {
    // currentpage, sortDirection and sortField don't do anything but are needed for the reload event
    this.lastReloadEvent = {
      currentPage: this.currentPage,
      limit: $event.limit,
      skip: $event.skip,
      sortDirection: $event.sortDirection,
      sortField: $event.sortField,
    };

    const response = await firstValueFrom(this.$activityService.eventLog(
      this.selectedActivityId,
      $event.limit,
      $event.skip,
    ));

    this.eventList$.next(response);

    this.lastUpdated = new Date();
  }
}
