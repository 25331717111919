import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { IProgramTemplate } from 'typings/doenkids/doenkids';

export interface IProgramTemplateListState extends EntityState<IProgramTemplate> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-list' })
export class ProgramTemplateListStore extends EntityStore<IProgramTemplateListState, IProgramTemplate> {
  constructor() {
    super([]);
  }
}
