import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IProgramTemplate } from 'typings/doenkids/doenkids';
import { IProgramTemplateListResponse } from 'typings/api-activity';
import { ProgramTemplateBundleProgramTemplateListStore } from './program-tempate-bundle-program-template-list.store';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleProgramTemplateListService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private store: ProgramTemplateBundleProgramTemplateListStore,
  ) {}

  async fetchAll({
    programBundleId,
    limit = 250,
    skip = 0,
    sortDirection,
    sortField,
  }: {
    programBundleId: number,
    limit?: number,
    skip?: number,
    sortDirection?: string,
    sortField?: string,
  }, doNotPerist: boolean = false): Promise<IProgramTemplate[]> {
    const response = await firstValueFrom(this.$baseApi.genericListCall(
      `/activity/program-template-bundle/${programBundleId}/program-templates`, limit, skip, sortField, sortDirection,
    )) as IProgramTemplateListResponse;
    if (!doNotPerist) {
      this.store.set(response?.items ?? []);
    }
    return response?.items ?? [];
  }
}
