<form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.title' | translate }}</mat-label>
      <input type="text" formControlName="title" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
      <mat-label>{{ 'generic.subtitle' | translate }}</mat-label>
      <input type="text" formControlName="subtitle" matInput>
    </mat-form-field>
  </div>
  <div formArrayName="source" class="sources">
    <div class="source-container" *ngFor="let control of sourceFormArray.controls; let index = index">
      <mat-form-field appearance="outline" class="section-form-field w-100" floatLabel="always">
        <mat-label>{{ 'activity.section.sources.source.label' | translate }}</mat-label>
        <input type="text" [formControlName]="index" matInput>
        <button *ngIf="!form.disabled" type="button" matSuffix mat-icon-button aria-label="Remove" (click)="sourceFormArray.removeAt(index);">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</form>
