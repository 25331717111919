import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProgramTemplate } from 'typings/doenkids/doenkids';
import { IProgramTemplateListState, ProgramTemplateListStore } from './program-template-list.state';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateListQuery extends QueryEntity<IProgramTemplateListState, IProgramTemplate> {
  constructor(protected store: ProgramTemplateListStore) {
    super(store);
  }
}
