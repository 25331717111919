<h1 mat-dialog-title>{{data.title}}</h1>
<ng-container *ngIf="descriptionContainsHTML; else paragraph">
  <div mat-dialog-content [innerHTML]="data.description">
  </div>
</ng-container>
<ng-template #paragraph>
  <div mat-dialog-content>
    <p [innerHTML]="data.description"></p>
  </div>
</ng-template>
<div mat-dialog-actions>
  <button *ngIf="!data.hideCancel" mat-button (click)="onNoClick()">{{ 'generic.cancel' | translate | uppercase }}</button>
  <span class="spacer"></span>
  <button mat-button (click)="confirm()" color="primary" cdkFocusInitial>{{ 'generic.ok' | translate }}</button>
</div>
