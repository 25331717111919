import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { INewsItemDetails } from 'typings/doenkids/doenkids';

@Injectable({
  providedIn: 'root',
})
export class NewsfeedProvider {
  private _itemMarkedAsRead$ = new BehaviorSubject<number>(null);

  public itemMarkedAsRead$: Observable<number> = this._itemMarkedAsRead$.asObservable();

  private _pendingNewsItem$ = new BehaviorSubject<INewsItemDetails>(null);

  public pendingNewsItem$ = this._pendingNewsItem$.asObservable();

  setPendingNewsItem(newsItem: INewsItemDetails) {
    this._pendingNewsItem$.next(newsItem);
  }

  clearPendingNewsItem() {
    this._pendingNewsItem$.next(null);
  }

  setItemMarkedAsRead(itemId: number) {
    this._itemMarkedAsRead$.next(itemId);
  }

  clearItemMarkedAsRead() {
    this._itemMarkedAsRead$.next(null);
  }
}
