import {
  Component, ViewEncapsulation, Input, OnInit, OnChanges, SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { KonnectOrganizationService } from 'src/api/customer/konnect/organization/konnect-organization.service';
import { ICreateKonnectOrganisation } from 'typings/api-customer';
import { IKonnectOrganizationUnit } from 'typings/doenkids/doenkids';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { get, isNil } from 'lodash';
import {
  map,
} from 'rxjs/operators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

const ERROR_MESSAGES = {
  400: _('konnect_form.invalid_request'),
  401: _('konnect_form.invalid_credentials'),
  403: _('konnect_form.unauthorized'),
  408: _('konnect_form.timeout'),
  500: _('konnect_form.server_error'),
};

@Component({
  selector: 'app-konnect-form',
  templateUrl: './konnect-form.component.html',
  styleUrls: ['./konnect-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KonnectFormComponent implements OnInit, OnChanges {
  @Input() organizationUnitId: number;

  public konnectOrganizationform: UntypedFormGroup;

  private konnectDetails$: BehaviorSubject<IKonnectOrganizationUnit> = new BehaviorSubject<IKonnectOrganizationUnit>(null);

  public hasKonnectDetails$: Observable<boolean> = this.konnectDetails$.pipe(
    map((value) => !isNil(value) && !isNil(value.src) && value.src === 'organisation'),
  );

  public konnectIsSyncing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public defaultUsernamePlaceholder = '';

  public defaultPasswordPlaceholder = '';

  public usernamePlaceholder = '';

  public passwordPlaceholder = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private organizationUnitService: OrganizationUnitService,
    private konnectOrganizationService: KonnectOrganizationService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.konnectOrganizationform = this.formBuilder.group({
      endpoint: ['', Validators.required],
      customerKey: ['', Validators.required],
      username: ['', Validators.required],
      credentials: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.fetchKonnectDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizationUnitId && !changes.organizationUnitId.firstChange) {
      this.fetchKonnectDetails();
    }
  }

  async fetchKonnectDetails() {
    try {
      const konnectDetails = await this.organizationUnitService.konnectDetails(this.organizationUnitId);

      this.konnectDetails$.next(konnectDetails);

      if (konnectDetails && konnectDetails.src && konnectDetails.src === 'organisation') {
        this.konnectOrganizationform.get('customerKey').setValue(konnectDetails.customer_key);

        await this.fetchKonnectOrganizationDetails(konnectDetails.id);
      } else {
        this.konnectOrganizationform.get('customerKey').setValue('');
        this.konnectOrganizationform.get('endpoint').setValue('');
        this.usernamePlaceholder = this.defaultUsernamePlaceholder;
        this.konnectOrganizationform.get('username').setValue('');
        this.passwordPlaceholder = this.defaultPasswordPlaceholder;
        this.konnectOrganizationform.get('credentials').setValue('');
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  async fetchKonnectOrganizationDetails(konnectOrganizationId: number) {
    const konnectOrganizationDetails = await this.konnectOrganizationService.fetch(konnectOrganizationId);

    this.konnectOrganizationform.get('endpoint').setValue(konnectOrganizationDetails.endpoint);
    this.usernamePlaceholder = konnectOrganizationDetails.username_mask;
    this.konnectOrganizationform.get('username').setValue('');
    this.passwordPlaceholder = konnectOrganizationDetails.credentials_mask;
    this.konnectOrganizationform.get('credentials').setValue('');
  }

  async saveDetails() {
    const updatedKonnectOrganizationDetails: ICreateKonnectOrganisation = {
      endpoint: this.konnectOrganizationform.get('endpoint').value,
      customer_key: this.konnectOrganizationform.get('customerKey').value,
      username: this.konnectOrganizationform.get('username').value,
      credentials: this.konnectOrganizationform.get('credentials').value,
    };

    const hasKonnectDetails = await firstValueFrom(this.hasKonnectDetails$);

    if (hasKonnectDetails) {
      await this.konnectOrganizationService.update(this.konnectDetails$.value.id, updatedKonnectOrganizationDetails);
      await this.fetchKonnectDetails();
      this.$i18nToastProvider.success(_('konnect_form.updated'));
    } else {
      const newKonnectOrganization = await this.konnectOrganizationService.create(updatedKonnectOrganizationDetails);

      // when we create the new konnect organization we want to connect that one to the current ou
      //
      await this.konnectOrganizationService.setOrganizationUnit(newKonnectOrganization.id, this.organizationUnitId);

      this.fetchKonnectDetails();
    }
  }

  async syncLocationsAndGroups() {
    this.konnectIsSyncing$.next(true);
    try {
      await this.konnectOrganizationService.sync(this.konnectDetails$.value.id);

      this.$i18nToastProvider.success(_('konnect_form.synchronized'));
    } catch (e) {
      console.error('something went wrong syncing konnect details', e);
      const statusCode = get(e, 'error.errors.statusCode');
      this.$i18nToastProvider.error(ERROR_MESSAGES[statusCode] || _('konnect_form.unknown_error'));
    }

    this.konnectIsSyncing$.next(false);
  }
}
