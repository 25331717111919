import { Injectable } from '@angular/core';
import { isNil, isObject } from 'lodash';
import { filter, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { JibbieLocationService } from 'src/api/customer/jibbie/location/jibbie-location.service';
import { JibbieOrganizationService } from 'src/api/customer/jibbie/organization/jibbie-organization.service';
import { JibbieActivityService } from 'src/api/customer/jibbie/activity/jibbie-activity.service';
import { DoenkidsSessionProvider } from './session.provider';
import { PermissionProvider } from './permission.provider';

@Injectable({
  providedIn: 'root',
})
export class JibbieProvider {
  isLinkedWithJibbie$ = new BehaviorSubject<null | boolean>(null);

  /**
   * This method will tell the app if an activity is synced to
   * Jibbie for a certain organization unit id
   *
   * @param activiyId - The activity Id to check
   * @param organizationUnitId - The organization unit id to check
   */
  async isActivitySyncedWithJibbie(activiyId: number, organizationUnitId: number) {
    const isOrganizationLinkedWithJibbie = await firstValueFrom(this.isLinkedWithJibbie$);
    let jibbieOrganizationUnitActivity;
    if (isOrganizationLinkedWithJibbie) {
      try {
        jibbieOrganizationUnitActivity = await this.$jibbieActivityService.getActivityOrganizationLink(activiyId, organizationUnitId);
      } catch (error) {
        // no link found for the activity and the ou combination so lets just do nothing here
        //
      }
      return isObject(jibbieOrganizationUnitActivity);
    }

    return false;
  }

  constructor(
    private $session: DoenkidsSessionProvider,
    private $jibbieLocationService: JibbieLocationService,
    private $jibbieActivityService: JibbieActivityService,
    private $jibbieOrganizationService: JibbieOrganizationService,
    private $permissions: PermissionProvider,
  ) {
    /**
     * With each selected organization check if the jibbie permission is activated
     * If it is then check if it is linked with Jibbie
     */
    this.$permissions.hasJibbieIntegrationPermission$.pipe(
      filter((permission) => !isNil(permission)),
      withLatestFrom(this.$session.getOrganizationUnit$),
    ).subscribe(async ([hasJibbieIntegration, selectedOrganization]) => {
      if (hasJibbieIntegration) {
        let organization;
        try {
          if (selectedOrganization?.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION) {
            organization = await this.$jibbieLocationService.getOrganizationById(selectedOrganization.id);
          } else {
            organization = await this.$jibbieOrganizationService.getOrganizationById(selectedOrganization.id);
          }
        } catch (e) {
          // don't do anything if fetching the jibbie location fails as it will set isLinkedWithJibbie as false
        }

        if (isObject(organization)) {
          this.isLinkedWithJibbie$.next(true);
        } else {
          this.isLinkedWithJibbie$.next(false);
        }
      } else {
        this.isLinkedWithJibbie$.next(false);
      }
    });
  }
}
