import {
  Component, Output, EventEmitter, Input,
} from '@angular/core';
import { doenkidsSpeedDialAnimation } from './doenkids-speed-dial-animation';

export interface FabButton {
  svgIcon?: string;
  icon?: string;
  label: string | string[];
  action: string;
  disabled?: boolean;
}

/**
 * Container for Speed dial content to show in Overlay when triggered
 * @example
 * ```html
 * <doenkids-speed-dial class="fab-container" (fabClick)="fabClick($event)" [fabButtons]="fabButtons"></doenkids-speed-dial>
 * ```
 */
@Component({
  selector: 'doenkids-speed-dial',
  templateUrl: './doenkids-speed-dial.component.html',
  styleUrls: ['./doenkids-speed-dial.component.scss'],
  animations: [doenkidsSpeedDialAnimation],
})
export class DoenkidsSpeedDialComponent {
  @Input() toggleIcon = 'edit';

  @Input() fabButtons: FabButton[];

  @Output() fabClick = new EventEmitter();

  fabTogglerState: string;

  buttons: FabButton[];

  constructor() {
    this.buttons = [];
    this.fabTogglerState = 'inactive';
  }

  private showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  private hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  public onToggleFab() {
    if (this.buttons.length > 0) {
      this.hideItems();
    } else {
      this.showItems();
    }
  }

  public onClickFab(btn: FabButton) {
    this.hideItems();
    this.fabClick.emit(btn.action);
  }
}
