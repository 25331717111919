import { RouterModule } from '@angular/router';
import { NoContentDirective } from 'src/directives/no-content.directive';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoenkidsMenuBarComponent } from 'src/components/layout/doenkids-menu-bar/doenkids-menu-bar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPaginationModule } from 'ngx-pagination';
import { WeightedValueSlidersComponent } from 'src/components/shared/weighted-value-sliders/weighted-value-sliders.component';
import { DoenKidsTranslatePipe } from 'src/pipes/doenkids_translate.pipe';
import { DoenkidsImageComponent } from 'src/components/layout/doenkids-image/doenkids-image.component';
import { DateRangeSelectorComponent } from 'src/components/shared/date-range-selector/date-range-selector.component';
import { DateRangeSelectorPredefinedRangesComponent } from 'src/components/shared/date-range-selector/date-range-selector-predefined-ranges/date-range-selector-predefined-ranges.component';
import { VarDirective } from 'src/directives/ng-var.directive';
import { SpinnerComponent } from 'src/components/shared/spinner/spinner.component';
import { NoResultsComponent } from 'src/components/shared/no-results/no-results.component';
import { SpinnerDirective } from 'src/directives/spinner.directive';
import { ReviewRatingComponent } from 'src/components/shared/reviews/review-overview/review/review-rating/review-rating.component';
import { SortableListComponent } from 'src/components/layout/sortable-list/sortable-list.component';
import { SortableListFieldsDirective } from 'src/components/layout/sortable-list/sortable-list-fields.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material/material.module';
import { LanguageSelectComponent } from '../components/language-select/language-select.component';
import { I18nDatePipe } from '../pipes/i18n-date.pipe';
import { I18nNumberPipe } from '../pipes/i18n-number.pipe';
import { OrganizationUnitTypeNamePipe } from '../pipes/organization-unit-type-name';
import { BytesPipe } from '../pipes/bytes.pipe';
import { I18nToastProvider } from '../providers/i18n-toast.provider';

@NgModule({
  declarations: [
    DoenkidsMenuBarComponent,
    WeightedValueSlidersComponent,
    DoenKidsTranslatePipe,
    DoenkidsImageComponent,
    DateRangeSelectorComponent,
    DateRangeSelectorPredefinedRangesComponent,
    LanguageSelectComponent,
    I18nDatePipe,
    I18nNumberPipe,
    SpinnerDirective,
    VarDirective,
    SpinnerComponent,
    NoResultsComponent,
    NoContentDirective,
    ReviewRatingComponent,
    SortableListComponent,
    SortableListFieldsDirective,
    OrganizationUnitTypeNamePipe,
    BytesPipe,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, FormsModule, FlexLayoutModule, RouterModule, NgxPaginationModule, TranslateModule],
  exports: [
    DoenkidsMenuBarComponent,
    MaterialModule,
    ReactiveFormsModule,
    WeightedValueSlidersComponent,
    FormsModule,
    FlexLayoutModule,
    NgxPaginationModule,
    DateRangeSelectorComponent,
    DateRangeSelectorPredefinedRangesComponent,
    NgApexchartsModule,
    DoenKidsTranslatePipe,
    DoenkidsImageComponent,
    I18nDatePipe,
    I18nNumberPipe,
    SpinnerDirective,
    VarDirective,
    SpinnerComponent,
    NoResultsComponent,
    NoContentDirective,
    ReviewRatingComponent,
    SortableListComponent,
    SortableListFieldsDirective,
    LanguageSelectComponent,
    BytesPipe,
    OrganizationUnitTypeNamePipe,
  ],
  providers: [
    DoenKidsTranslatePipe,
    I18nToastProvider,
    OrganizationUnitTypeNamePipe,
  ],
})
export class SharedModule {}
