import {
  Component, OnDestroy, ViewEncapsulation, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { isNil, isEqual } from 'lodash';
import {
  takeUntil, startWith, map, distinctUntilChanged, mergeMap,
} from 'rxjs/operators';
import { Subject, Observable, firstValueFrom, combineLatest, of } from 'rxjs';
import { IField, IReloadEvent } from 'src/components/layout/sortable-list/sortable-list.component';
import { IOrganizationUnitOverview, IUser } from 'typings/doenkids/doenkids';
import { OrganizationUnitUsersListService } from 'src/api/customer/organization-unit-users-list/organization-unit-users-list.service';
import { UserDialogComponent } from 'src/components/dialogs/user-dialog/user-dialog.component';
import { PaginationInstance } from 'ngx-pagination';
import { Router } from '@angular/router';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { DoenKidsPreferencesProvider } from 'src/providers/preferences.provider';
import { IUserDetails, IAuthenticatedUser } from 'typings/api-customer';
import { UserListQuery } from 'src/api/customer/users-list/user-list.query';
import { UserListService } from 'src/api/customer/users-list/user-list.service';
import { OrganizationUnitUsersListQuery } from 'src/api/customer/organization-unit-users-list/organization-unit-users-list.query';
import { OrganizationUnitUserService } from 'src/api/customer/organization-unit-user/organization-unit-user.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { FabButton } from 'src/components/layout/doenkids-speed-dial/doenkids-speed-dial.component';
import { AddExistingUserDialogComponent } from 'src/components/dialogs/add-existing-user-dialog/add-existing-user-dialog.component';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import {
  BrowseOrganizationUnitDialogComponent, ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { IServiceResponse } from 'typings/api-generic';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';

const FLOATING_ACTION_BUTTONS = [
  {
    icon: 'person',
    label: _('user_list.floating_action_button.add_existing'),
    action: 'add-existing',
    disabled: false,
  },
  {
    icon: 'person_add',
    label: _('user_list.floating_action_button.add_new'),
    action: 'add-new',
    disabled: false,
  },
];

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./user-list.component.scss'],
})

export class UserListComponent implements OnDestroy, OnInit {
  public usersList: IUserDetails[];

  private stop$: Subject<void> = new Subject();

  private lastReloadEvent: IReloadEvent;

  public includeInActiveUsers$: Observable<boolean>;

  public searchInAllUsers$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public currentPage: number;

  public searchQuery = '';

  public list$: Observable<IUserDetails[]>;

  public listLoading$: Observable<boolean>;

  public metaData: Observable<{ total: number, skip: number, limit: number, query: string }>;

  public isAdmin$: Observable<boolean>;

  public isNotAdmin$: Observable<boolean>;

  public isCustomer$: Observable<boolean>;

  public currentUser$: Observable<IAuthenticatedUser>;

  public currentOUName$: Observable<string>;

  public isRootNode$: Observable<boolean>;

  public organizationUnitId$: Observable<number>;

  public SEARCH_LIMIT = 15;

  protected fields$: Observable<IField[]>;

  protected floatingActionButtons$: Observable<FabButton[]>;

  /**
   * The configuration object for the pagination of the list.
   */
  public paginationConfig$: Observable<PaginationInstance>;

  constructor(
    private dialog: MatDialog,
    private organizationUnitUsersListQuery: OrganizationUnitUsersListQuery,
    private organizationUnitUsersListService: OrganizationUnitUsersListService,
    private organizationUnitUserService: OrganizationUnitUserService,
    private usersListQuery: UserListQuery,
    private usersListService: UserListService,
    private $session: DoenkidsSessionProvider,
    private $preferences: DoenKidsPreferencesProvider,
    private $permission: PermissionProvider,
    private router: Router,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.includeInActiveUsers$ = this.$preferences.showInactiveUsers$.asObservable().pipe(takeUntil(this.stop$));
    this.searchInAllUsers$ = this.$preferences.searchInAllOrganizations$.asObservable().pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));
    this.isCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));
    this.currentUser$ = this.$session.getUser$.pipe(takeUntil(this.stop$));
    this.isRootNode$ = this.$session.isCurrentOrganizationUnitARootNode$.pipe(takeUntil(this.stop$));

    this.list$ = this.searchInAllUsers$.pipe(
      mergeMap((searchAllUsers) => {
        if (!searchAllUsers) {
          return this.organizationUnitUsersListQuery.selectAll();
        }
        return this.usersListQuery.selectAll();
      }),
    );

    this.listLoading$ = combineLatest([this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$, this.searchInAllUsers$]).pipe(
      mergeMap(([hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree, searchAllUsers]) => {
        if (!hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
          return of(false);
        }
        if (!searchAllUsers) {
          return this.organizationUnitUsersListQuery.selectLoading().pipe(startWith(false));
        }
        return this.usersListQuery.selectLoading().pipe(startWith(false));
      }),
    );

    this.metaData = this.searchInAllUsers$.pipe(
      mergeMap((searchAllUsers) => {
        if (!searchAllUsers) {
          return this.organizationUnitUsersListQuery.getMetadata();
        }
        return this.usersListQuery.getMetadata();
      }),
    );

    this.isAdmin$ = this.$session.isAdmin$;
    this.isNotAdmin$ = this.isAdmin$.pipe(
      takeUntil(this.stop$),
      map((isAdmin) => !isAdmin),
    );

    this.organizationUnitId$ = this.$session.currentOuId$.pipe(
      takeUntil(this.stop$),
    );

    this.fields$ = this.$translateService
      .onInitialTranslationAndLangOrTranslationChange$
      .pipe(
        takeUntil(this.stop$),
        map((langChange) => [
          {
            label: langChange.translations[_('user_list.fields.email')],
            field: 'email',
            width: '20%',
          },
          {
            label: langChange.translations[_('user_list.fields.auth0')],
            field: 'auth0_status',
            width: '5%',
            hide: this.isNotAdmin$,
          },
          {
            label: langChange.translations[_('user_list.fields.active')],
            field: 'active',
            width: '5%',
            hide: this.isNotAdmin$,
          },
          {
            label: langChange.translations[_('user_list.fields.role')],
            field: 'user_role',
            width: '10%',
            hide: this.isNotAdmin$,
          },
          {
            label: langChange.translations[_('user_list.fields.last_seen')],
            field: 'last_seen',
            width: '15%',
            hideLessThanSmall: true,
          },
          {
            label: langChange.translations[_('user_list.fields.login_count')],
            field: 'auth0_login_count',
            width: '5%',
            hideLessThanMedium: true,
          },
          {
            label: langChange.translations[_('user_list.fields.created_at')],
            field: 'created_at',
            width: '10%',
            hideLessThanMedium: true,
          },
          {
            label: langChange.translations[_('user_list.fields.organization_unit')],
            field: 'organization_unit',
            width: '15%',
            hideLessThanSmall: true,
          },
          {
            label: langChange.translations[_('user_list.fields.linked_organization')],
            field: 'linked_organisation',
            width: '10%',
            hideLessThanSmall: true,
          },
          {
            label: langChange.translations[_('user_list.actions')],
            field: 'password_reset',
            width: '5%',
            disableSort: true,
            hideLessThanSmall: true,
          },
        ]),
      );

    this.floatingActionButtons$ = this.$translateService.onInitialTranslationAndLangOrTranslationChange$
      .pipe(
        takeUntil(this.stop$),
        map((langChange) => FLOATING_ACTION_BUTTONS.map((button) => ({ ...button, label: langChange.translations[button.label] }))),
      );

    this.paginationConfig$ = this.metaData.pipe(
      map((metaData) => {
        const paginationConfig: PaginationInstance = {
          currentPage: this.currentPage,
          itemsPerPage: metaData.limit,
          totalItems: metaData.total,
        };
        return paginationConfig;
      }),
    );

    this.currentOUName$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
      map((organization) => organization.name),
    );
  }

  async ngOnInit() {
    this.$session.getOrganizationUnitSwitch$.pipe(
      takeUntil(this.stop$),
      distinctUntilChanged(isEqual),
    ).subscribe(async (organizationUnit: IOrganizationUnitOverview) => {
      if (organizationUnit) {
        const searchAllUsers = await firstValueFrom(this.searchInAllUsers$);
        if (!searchAllUsers) {
          this.router.navigate([`/organization/${organizationUnit.id}/users`], { replaceUrl: true });
          this.doSearch('');
        }
      }
    });

    this.list$.subscribe((users) => {
      this.usersList = users;
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  public toggleInactiveUsers(event: MatSlideToggleChange) {
    this.$preferences.toggleShowInactiveUsers(event.checked);
    this.lastReloadEvent.skip = 0;
    this.getList(this.lastReloadEvent);
  }

  public toggleSearchAllUsers(event: MatSlideToggleChange) {
    this.$preferences.toggleSearchInAllOrganizations(event.checked);
    this.lastReloadEvent.skip = 0;
    this.getList(this.lastReloadEvent);
  }

  public async getList($event: IReloadEvent) {
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);
    if (!hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      return;
    }
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const searchAllUsers = await firstValueFrom(this.searchInAllUsers$);
    const ouId = await firstValueFrom(this.organizationUnitId$);
    this.lastReloadEvent = $event;
    this.currentPage = $event.currentPage;
    if (searchAllUsers) {
      this.usersListService.search({
        limit: $event.limit,
        skip: $event.skip,
        search: this.searchQuery ?? '',
        active: !this.$preferences.showInactiveUsers$.value,
      }, false, undefined, isAdmin ? undefined : ouId);
    } else {
      this.organizationUnitUsersListService.fetchAll(ouId, {
        ...$event,
        search: this.searchQuery,
        active: !this.$preferences.showInactiveUsers$.value,
      });
    }
  }

  async doSearch(newQuery: string) {
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);
    if (!hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      return;
    }
    const ouId = await firstValueFrom(this.organizationUnitId$);
    const searchAllUsers = await firstValueFrom(this.searchInAllUsers$);
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const newReloadEvent = {
      limit: this.SEARCH_LIMIT,
      skip: 0,
      currentPage: 1,
      sortField: 'created_at',
      sortDirection: 'DESC',
      search: newQuery,
    };
    this.searchQuery = newQuery;
    if (searchAllUsers) {
      this.usersListService.search({
        ...newReloadEvent,
      }, false, undefined, isAdmin ? undefined : ouId);
    } else {
      this.organizationUnitUsersListService.fetchAll(ouId, {
        ...newReloadEvent,
        active: true,
      });
    }
  }

  async openUserDialog() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '600px',
      minWidth: '420px',
    });
    const response: { user: IUser, chooseOu: boolean } = await firstValueFrom(dialogRef.afterClosed());
    if (!isNil(response) && !isNil(response.user)) {
      const userFormData = response.user;
      const ouId = await firstValueFrom(this.organizationUnitId$);

      if (isNil(userFormData.id)) {
        delete userFormData.id;
      }

      const userToCreate = {
        ...userFormData,
        organization_unit_id: ouId,
      } as IUser;

      let chosenOus = response.chooseOu ? await this.openBrowseOrganizationUnitDialog() : [];
      chosenOus = chosenOus?.length > 0 ? chosenOus : [];

      if (response.chooseOu && chosenOus.length === 0) {
        // User wanted to create the user, but decided to not choose any oU's, be probably thought he'd cancel the entire action.
        //
        return null;
      }

      const newUser = await this.organizationUnitUsersListService.create(userToCreate);

      const addUserToOrganisationPromises: Promise<IServiceResponse>[] = [];
      if (response.chooseOu) {
        for (const chosenOu of chosenOus) {
          addUserToOrganisationPromises.push(this.organizationUnitUserService.addUserToOrganization(newUser.id, chosenOu.id));
        }
      } else {
        // if we didn't choose to choose the OU that the user should be connected to connect to the current OU so that the user
        // doesn't float around without being connected to something
        //
        addUserToOrganisationPromises.push(this.organizationUnitUserService.addUserToOrganization(newUser.id, ouId));
      }

      await Promise.all(addUserToOrganisationPromises);

      await this.router.navigate([`/organization/${ouId}/users/${newUser.id}`]);
    }
    return null;
  }

  async addExistingUser() {
    const dialogRef = this.dialog.open(AddExistingUserDialogComponent, {
      width: '600px',
      minWidth: '420px',
    });
    const response: { user: IUserDetails, chooseOu: boolean } = await firstValueFrom(dialogRef.afterClosed());
    if (!isNil(response) && !isNil(response.user)) {
      const chosenUser = response.user;
      const ouId = await firstValueFrom(this.organizationUnitId$);

      await this.organizationUnitUserService.addUserToOrganization(chosenUser.id, ouId);

      if (response.chooseOu) {
        const chosenOus = await this.openBrowseOrganizationUnitDialog();

        if (!isNil(chosenOus) && chosenOus.length > 0) {
          for (const chosenOu of chosenOus) {
            // eslint-disable-next-line no-await-in-loop
            await this.organizationUnitUserService.addUserToOrganization(chosenUser.id, chosenOu.id);
          }
        }
      }

      this.getList(this.lastReloadEvent);
    }
    return null;
  }

  async openBrowseOrganizationUnitDialog() {
    // if we did choose to choose the OU that the user should be connected to
    // show the ou selector and connect to all ou's that come back from that
    //
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);

    const selectableOrganizationUnitTypeIds = [DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION];

    if (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      selectableOrganizationUnitTypeIds.push(DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_CUSTOMER);
    }

    const browseOrganizationUnitDialog = this.dialog.open(BrowseOrganizationUnitDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant(_('user_list.browse_organizations.dialog.title')),
        description: this.$translateService.instant(_('user_list.browse_organizations.dialog.description')),
        allowMultipleSelection: true,
        initialOrganizationUnitSelection: [],
        selectableOrganizationUnitTypeIds,
        writeableOnly: true,
      },
    });

    const chosenOus: ISelectedOrganizationUnit[] = await firstValueFrom(browseOrganizationUnitDialog.afterClosed());
    return chosenOus;
  }

  async sendPasswordResetEmail(userDetails: IUserDetails) {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.$translateService.instant(_('user_list.password_reset.dialog.title')),
        description: this.$translateService.instant(_('user_list.password_reset.dialog.description'), { email: userDetails.email }),
      },
    });

    const confirmation = await firstValueFrom(confirmationDialog.afterClosed());

    if (confirmation !== 'confirm') {
      return;
    }

    const response = await this.organizationUnitUsersListService.sendPasswordResetEmail(userDetails);

    if (!isNil(response)) {
      this.$i18nToastProvider.success(_('user_list.password_reset.sent'));
    } else {
      this.$i18nToastProvider.error(_('user_list.password_reset.failed'));
    }
  }

  async sendVerificationEmail(userDetails: IUserDetails) {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.$translateService.instant(_('user_list.verification_email.dialog.title')),
        description: this.$translateService.instant(_('user_list.verification_email.dialog.description'), { email: userDetails.email }),
      },
    });

    const confirmation = await firstValueFrom(confirmationDialog.afterClosed());

    if (confirmation !== 'confirm') {
      return;
    }

    const response = await this.organizationUnitUsersListService.sendVerificationEmail(userDetails);

    if (!isNil(response)) {
      this.$i18nToastProvider.success(_('user_list.verification_email.sent'));
    } else {
      this.$i18nToastProvider.error(_('user_list.verification_email.failed'));
    }
  }

  async blockUser(userDetails: IUserDetails) {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.$translateService.instant(_('user_list.block_user.dialog.title')),
        description: this.$translateService.instant(_('user_list.block_user.dialog.description'), { email: userDetails.email }),
      },
    });

    const confirmation = await firstValueFrom(confirmationDialog.afterClosed());

    if (confirmation !== 'confirm') {
      return;
    }

    const response = await this.organizationUnitUsersListService.blockUser(userDetails);

    if (!isNil(response)) {
      this.$i18nToastProvider.success(_('user_list.block_user.sent'));
    } else {
      this.$i18nToastProvider.error(_('user_list.block_user.failed'));
    }
  }

  async unblockUser(userDetails: IUserDetails) {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.$translateService.instant(_('user_list.unblock_user.dialog.title')),
        description: this.$translateService.instant(_('user_list.unblock_user.dialog.description'), { email: userDetails.email }),
      },
    });

    const confirmation = await firstValueFrom(confirmationDialog.afterClosed());

    if (confirmation !== 'confirm') {
      return;
    }

    const response = await this.organizationUnitUsersListService.unblockUser(userDetails);

    if (!isNil(response)) {
      this.$i18nToastProvider.success(_('user_list.unblock_user.sent'));
    } else {
      this.$i18nToastProvider.error(_('user_list.unblock_user.failed'));
    }
  }

  async goToUserDetails(user: IUserDetails) {
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);

    if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      const organizationUnitId = await firstValueFrom(this.organizationUnitId$);
      this.router.navigate([`/organization/${organizationUnitId}/users/${user.id}`]);
    }
  }

  async speedDialClick(action: string) {
    switch (action) {
      case 'add-existing':
        this.addExistingUser();
        break;
      case 'add-new':
        this.openUserDialog();
        break;
      default:
        break;
    }
  }

  getUserLinkedOUString(user: IUserDetails) {
    const mappedLinkedOUs = user.linked_organization_units?.map((organization) => organization.name);

    return mappedLinkedOUs ? mappedLinkedOUs.join(', ') : '';
  }

  async downloadUserList() {
    const ou = await firstValueFrom(this.$session.getOrganizationUnit$);
    const inactiveUsers = await firstValueFrom(this.includeInActiveUsers$);
    const searchAllUsers = await firstValueFrom(this.searchInAllUsers$);

    this.organizationUnitUsersListService.downloadExcelList(ou.id, {
      search: this.searchQuery,
      limit: this.lastReloadEvent.limit,
      skip: this.lastReloadEvent.skip,
      sortField: this.lastReloadEvent.sortField,
      sortDirection: this.lastReloadEvent.sortDirection,
      active: !inactiveUsers,
      nodeOnly: searchAllUsers,
      filename: this.$translateService.instant(_('user_list.download.filename'), { organizationUnitName: ou.name }),
    });
  }

  async deactivateAllUsersOfOrganization() {
    const ouName = await firstValueFrom(this.currentOUName$);
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.$translateService.instant(_('user_list.deactivate-users.dialog.title')),
        description: this.$translateService.instant(_('user_list.deactivate-users.dialog.description'), { organizationName: ouName }),
      },
    });

    const confirmation = await firstValueFrom(confirmationDialog.afterClosed());

    if (confirmation !== 'confirm') {
      return;
    }

    try {
      const ouId = await firstValueFrom(this.organizationUnitId$);
      await this.organizationUnitUserService.deactivateUsers(ouId);
      this.$i18nToastProvider.success(_('user_list.deactivate-users.success'), { organizationName: ouName });
    } catch (e) {
      this.$i18nToastProvider.error(_('user_list.deactivate-users.error'), { organizationName: ouName });
    }
  }


}
