import { Injectable } from '@angular/core';
import { isNil } from '@datorama/akita';
import { IProgramTemplate, IProgram, IOrganizationUnitProgramTemplate } from 'typings/doenkids/doenkids';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IEntityWithAccessLevel, IServiceResponse } from 'typings/api-generic';
import { omit } from 'lodash';
import { ICountResponse, IProgramTemplateListResponse, IProgramTemplateOwnershipResponse } from 'typings/api-activity';
import { ProgramTemplateStore } from './program-template.store';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateService {
  constructor(private $baseApi: DoenKidsGenericApiProvider, private store: ProgramTemplateStore) {}

  async create(organizationUnitId: number, programTemplate: IProgramTemplate) {
    const response = await firstValueFrom(this.$baseApi.genericPostCall(
      '/activity/program-template', programTemplate, null, { organizationUnitId },
    )) as IProgramTemplate;
    this.store.update(response);
    return response;
  }

  async fetch(id: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program-template/${id}`)) as IEntityWithAccessLevel<IProgramTemplate>;
    this.store.update({
      ...response?.data,
      access_level: response?.access_level,
    });
    return response?.data;
  }

  async fetchRevoked(params: {
    organizationUnitId: number,
    limit: number,
    skip: number,
    sortDirection?: string,
    sortField?: string,
    language?: string,
  }) {
    let url = `/activity/program-template/organization-unit/${params.organizationUnitId}/approved`;

    url = this.$baseApi.appendQueryParam(url, 'organization_unit_role_id', 4);

    const response = (await firstValueFrom(this.$baseApi.genericListCall(url, params.limit, params.skip, params.sortField, params.sortDirection,
      { language: params.language }))) as IProgramTemplateListResponse;

    return response;
  }

  async update(programTemplate: IProgramTemplate) {
    const response = await firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program-template/${programTemplate.id}`, omit(programTemplate, 'access_level'),
    )) as IProgramTemplate;
    this.store.update(response);
    return response;
  }

  archive(id: number) {
    this.store.reset();
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/program-template/${id}`));
  }

  approvalFetch(organizationUnitId: number, programTemplateId: number) {
    return firstValueFrom(this.$baseApi.genericGetCall(
      `/activity/program-template/organization-unit/${organizationUnitId}/approved/${programTemplateId}`,
    )) as Promise<IOrganizationUnitProgramTemplate>;
  }

  approve(organizationUnitId: number, programTemplateId: number) {
    return firstValueFrom(this.$baseApi.genericPutCall(
      `/activity/program-template/organization-unit/${organizationUnitId}/approved/${programTemplateId}`,
    )) as Promise<IServiceResponse>;
  }

  approvalRemove(organizationUnitId: number, programTemplateId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(
      `/activity/program-template/organization-unit/${organizationUnitId}/approved/${programTemplateId}`,
    )) as Promise<IServiceResponse>;
  }

  async copy(organizationUnitId: number, programTemplateId: number, statusId: number = 1, archived: boolean = false, language?: string) {
    let url = `/activity/program-template/${programTemplateId}/organization-unit/${organizationUnitId}`;

    url = this.$baseApi.appendQueryParam(url, 'statusId', statusId);
    url = this.$baseApi.appendQueryParam(url, 'archived', archived);
    url = this.$baseApi.appendQueryParam(url, 'language', language);

    const copiedProgramTemplate = await firstValueFrom(this.$baseApi.genericPutCall(url)) as IProgramTemplate;
    return copiedProgramTemplate;
  }

  async fetchOwner(programTemplateId: number) {
    const { items } = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/program-template/${programTemplateId}/owner`)) as IProgramTemplateOwnershipResponse;
    return items;
  }

  async createProgram(programTemplateId: number, organizationUnitId: number, program: IProgram, bundleId?: number) {
    let url = `/activity/program-template/create-program/${programTemplateId}`;

    url = this.$baseApi.appendQueryParam(url, 'organizationUnitId', organizationUnitId);
    url = this.$baseApi.appendQueryParam(url, 'program_template_bundle_id', bundleId);

    const response = await firstValueFrom(this.$baseApi.genericPutCall(url, program)) as IProgram;

    if (!isNil(response)) {
      this.store.update(response);
    } else {
      console.warn('Program creation failed. Proceeding with incorrect state.');
    }
    return response;
  }

  async fetchApprovalCount(organizationUnitId: number) {
    try {
      const result = await firstValueFrom(this.$baseApi.genericListCall('/activity/program-template/approval/count/', undefined, undefined, undefined, undefined,
        { organization_unit_id: organizationUnitId }));

      return result as ICountResponse;
    } catch (e) {
      return null;
    }
  }

  relatedProgramTemplates(templateId: number, limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: string = 'desc') {
    return this.$baseApi.genericListCall(`/activity/related-program-template/${templateId}`, limit, skip, sortField, sortDirection) as Observable<IProgramTemplateListResponse>;
  }

  addRelatedProgramTemplate(fromTemplateId: number, toTemplateId: number) {
    return this.$baseApi.genericPutCall(`/activity/related-program-template/${fromTemplateId}/program-template/${toTemplateId}`);
  }

  removeRelatedProgramTemplate(fromTemplateId: number, toTemplateId: number) {
    return this.$baseApi.genericDeleteCall(`/activity/related-program-template/${fromTemplateId}/program-template/${toTemplateId}`);
  }
}
