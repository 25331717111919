import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ISearchUserListResponse, IUserDetails, IUserListRequest } from 'typings/api-customer';
import { isUndefined } from 'lodash';
import { UserListStore } from './user-list.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserListService {
  constructor(private store: UserListStore, private $baseApi: DoenKidsGenericApiProvider) { }

  @transaction()
  async search(request: IUserListRequest, concatenate: boolean, doNotPerist?: boolean, organizationUnitId?: number) {
    let url = '/customer/user/list';
    url = this.$baseApi.appendQueryParam(url, 'organization_unit_id', organizationUnitId);

    const response = await firstValueFrom(this.$baseApi.genericPostCall(url, request)) as ISearchUserListResponse;

    const hits = response?.items ?? [];

    if (isUndefined(doNotPerist) || !doNotPerist) {
      if (concatenate) {
        this.store.add(hits);
      } else {
        this.store.set(hits);
      }

      this.store.update({
        limit: request.limit,
        skip: request.skip,
        total: response?.total ?? 0,
        search: request.search,
      });
    }

    return hits;
  }

  async fetch(userId: number) {
    const response = await firstValueFrom(this.$baseApi.genericGetCallWithoutErrorHandler(`/customer/user/${userId}`)) as IUserDetails;

    return response;
  }
}
