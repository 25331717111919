import {
  Component, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RecommendationsService } from 'src/api/activity/recommendations/recommendations.service';
import { swiperStyles } from 'src/directives/swiper.directive';
import { ActivityToReviewCountProvider } from 'src/providers/activity-to-review-count.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { Navigation, SwiperOptions } from 'swiper';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { IActivityRecommendationsList } from 'typings/api-activity';
import { IActivity } from 'typings/doenkids/doenkids';
import { PermissionProvider } from 'src/providers/permission.provider';
import { environment } from 'src/environments/environment';

_('activity_overview.section.top_10');
_('activity_overview.section.trending');
_('activity_overview.section.newest');
_('activity_overview.section.featured');
_('activity.activity_kind.creative');
_('activity.activity_kind.creative.adjective');
_('activity.activity_kind.game');
_('activity.activity_kind.food_and_drink');
_('activity.activity_kind.discovery_technique_tests');
_('activity.activity_kind.sport');
_('activity.activity_kind.nature');
_('activity.activity_kind.media');
_('activity.activity_kind.music');
_('activity.activity_kind.music.adjective');
_('activity_overview.section.all');

const ACTIVITY_KIND_SECTIONS = ['creative', 'game', 'food-and-drink', 'discovery-technique-tests', 'sport', 'nature', 'media', 'music'];
const ORDERED_SECTIONS = [
  'top-10',
  'trending',
  'newest',
  'featured',
  ...ACTIVITY_KIND_SECTIONS,
  'all',
];
const OPTIONAL_SECTIONS = ['top-10', 'featured'];
const SEARCHABLE_SECTIONS = [...ACTIVITY_KIND_SECTIONS, 'all'];

interface ActivityOverviewSection {
  name: string;
  translationKey: string;
  seeMoreTranslationKeySuffix: string;
  activityKindName?: string;
  activities: IActivity[];
  isOptional: boolean;
  isSearchable: boolean;
}

@Component({
  selector: 'app-activity-overview',
  templateUrl: './activity-overview.component.html',
  styleUrls: ['./activity-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ActivityToReviewCountProvider],
})
export class ActivityOverviewComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  private allRecommendations$ = new BehaviorSubject<IActivityRecommendationsList>({});

  protected activitySections$: Observable<ActivityOverviewSection[]>;

  public loaded$ = new BehaviorSubject(false);

  public swiperConfig: SwiperOptions = {
    modules: [Navigation],
    a11y: {
      enabled: true,
    },
    slidesPerView: 'auto',
    spaceBetween: 20,
    initialSlide: 0,
    loop: false,
    keyboard: true,
    mousewheel: false,
    navigation: true,
    watchOverflow: true,
    injectStyles: [swiperStyles],
  };

  public searchControl = new UntypedFormControl();

  public countNumber$: Observable<number>;

  public isAdmin$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public activity404Image = environment.activity404Image;

  constructor(
    private $session: DoenkidsSessionProvider,
    private $recommendations: RecommendationsService,
    private router: Router,
    private $activityToReviewCountService: ActivityToReviewCountProvider,
    private $permission: PermissionProvider,
  ) {
    this.activitySections$ = this.allRecommendations$.pipe(
      map((allRecommendations) => ORDERED_SECTIONS.map((section) => {
        const recommendationKey = {
          trending: 'mostViewed',
          'top-10': 'mostPlanned',
          all: 'newest',
          'food-and-drink': 'food',
          'discovery-technique-tests': 'technique',
        }[section] ?? section;

        const translationKey = section.replace(/-/g, '_');

        const recommendation = allRecommendations[recommendationKey];

        return {
          name: section,
          translationKey: ACTIVITY_KIND_SECTIONS.includes(section)
            ? `activity.activity_kind.${translationKey}`
            : `activity_overview.section.${translationKey}`,
          seeMoreTranslationKeySuffix: ['creative', 'music'].includes(section) ? '.adjective' : '',
          activities: (recommendation?.items ?? []) as IActivity[],
          activityKindName: recommendation?.activity_kind_name,
          isOptional: OPTIONAL_SECTIONS.includes(section),
          isSearchable: SEARCHABLE_SECTIONS.includes(section),
        };
      })),
    );

    this.countNumber$ = this.$activityToReviewCountService.activityNewCount$.asObservable();
    this.isAdmin$ = this.$session.isAdmin$;
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$;
  }

  ngOnInit(): void {
    this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$)).subscribe(async (ou) => {
      this.loaded$.next(false);
      if (ou) {
        const all = await this.$recommendations.fetchAll(ou.id);
        this.allRecommendations$.next(all);
      } else {
        this.allRecommendations$.next({});
      }
      this.loaded$.next(true);
    });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  advancedSearch() {
    const searchTerm = this.searchControl.value;
    const queryParams: any = {};

    if (searchTerm) {
      queryParams.advancedSearch = searchTerm;
    }

    this.router.navigate(['/activities'], { queryParams });
  }

  activityKindSearch(activityKindName?: string) {
    if (!activityKindName) {
      this.router.navigate(['/activities']);
    }

    this.router.navigate(['/activities'], {
      queryParams: {
        activityKind: activityKindName,
      },
    });
  }

  goToActivityPreview(activity: IActivity) {
    this.router.navigate([`/activities/preview/${activity.id}`]);
  }

  async goToActivitiesToReview() {
    const ouId = await firstValueFrom(this.$session.currentOuId$);
    // Navigating to activity-search.component with query parameter for the filter
    this.router.navigate(['/activities'], {
      queryParams: { showActivities: ouId, activityStatus: 2 },
    });
  }
}
