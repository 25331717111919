import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IBaseActivitySearchRequest } from 'typings/api-search';
import { ISearchActivity } from 'typings/doenkids/doenkids';
import { SearchResponse } from '../../types';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { get } from 'lodash';
import { BaseActivitySearchStore } from './base-activity-search.store';

export interface BaseActivitySearchResponse {
  results: ISearchActivity[];
  aggregations: any;
  suggestions: any[];
}

@Injectable({
  providedIn: 'root',
})
export class BaseActivitySearchService {
  constructor(private store: BaseActivitySearchStore, private $baseApi: DoenKidsGenericApiProvider) {}

  @transaction()
  fetch(request: IBaseActivitySearchRequest, contatenate: boolean) {
    this.$baseApi.genericPostCall('/search/activity/base/search', request).subscribe((response: SearchResponse<ISearchActivity>) => {
      const hits = (response?.hits?.hits ?? [])?.map((hit) => hit._source);

      if (contatenate) {
        this.store.add(hits);
      } else {
        this.store.set(hits);
      }

      this.store.update({
        limit: request.limit,
        skip: request.skip,
        total: response?.hits?.total ?? 0,
        query: request.query,
        took: response?.took ?? 0,
      });

      this.store.setAggregations(response ? { language: request.language, countryCode: request.countryCode, ...response.aggregations } : {});
      this.store.setSuggestions(response ? get(response, 'suggest.simple_phrase') : []);
    });
  }

  setLoading(loading: boolean) {
    this.store.setLoading(loading);
  }
}
