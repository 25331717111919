import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { IProgramTemplateBundleAttachment } from 'typings/doenkids/doenkids';

export interface IDoenkidsProgramTemplateBundleAttachmentState extends EntityState<IProgramTemplateBundleAttachment> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-template-bundle-attachment-list' })
export class ProgramTemplateBundleAttachmentStore extends EntityStore<IDoenkidsProgramTemplateBundleAttachmentState, IProgramTemplateBundleAttachment> {
  constructor() {
    super([]);
  }
}
