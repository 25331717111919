import { Injectable } from '@angular/core';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { SortDirection } from '@angular/material/sort';
import { ILocationListResponse } from 'typings/api-activity';
import { LocationStore } from './location.store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private store: LocationStore, private $baseApi: DoenKidsGenericApiProvider) {}

  async fetchAll(limit: number = 100, skip: number = 0, sortField: string = 'created_at', sortDirection: SortDirection = 'desc') {
    const locationListRepsonse = await firstValueFrom(this.$baseApi.genericListCall('/activity/location', limit, skip, sortField, sortDirection)) as ILocationListResponse;

    this.store.set(locationListRepsonse?.items ?? []);
  }
}
