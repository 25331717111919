
<div class="aggregation-container" *ngIf="aggregationData; let aggs">
  <ng-container *ngFor="let key of aggregationKeys; let i = index;">
    <div class="filter" *ngIf="aggs[key] && (aggs[key]['buckets']?.length > 0 || key === 'tags') ">
      <ng-container *ngIf="key !== 'tags'">
        <ng-template [ngTemplateOutlet]="bucketSelectionOptions" [ngTemplateOutletContext]="{ options: aggs[key]['buckets'], key: key }"></ng-template>
      </ng-container>
      <ng-container *ngIf="key === 'tags'">
        <ng-template [ngTemplateOutlet]="tagsSelectionOptions" [ngTemplateOutletContext]="{ options: aggs[key], key: key }"></ng-template>
      </ng-container>
    </div>
  </ng-container>
  <app-empty-page-message
    *ngIf="aggregationLength === 0"
    icon="search"
    [message]="'search_aggregations.no_results' | translate"
  ></app-empty-page-message>
</div>

<ng-template #bucketSelectionOptions let-options="options" let-key="key">
  <ng-container [formGroup]="form">
    <h4>{{ aggregationLabels[key] }}</h4>
    <mat-selection-list [formControlName]="key">
      <ng-container *ngFor="let option of options">
        <mat-list-option
          *ngIf="option.key"
          [value]="option.key"
          [selected]="form.get(key)?.value?.includes(option.key)"
        >
          <span class="option-label">
            <ng-container *ngIf="key === 'range_of_development'">
              <span>{{ getRangeOfDevelopmentLabel(option.key) | doenkidsTranslate: translationPrefix + '.' + key }}</span>&nbsp;{{ ['duration', 'preparation'].includes(key) ? ('generic.minutes' | translate) + '&nbsp;' : '' }}
            </ng-container>
            <ng-container *ngIf="key === 'activity_kind'">
              <span>{{option.key | doenkidsTranslate: translationPrefix + '.aggregation.' + key }}</span>&nbsp;{{ ['duration', 'preparation'].includes(key) ? ('generic.minutes' | translate) + '&nbsp;' : '' }}
            </ng-container>
            <ng-container *ngIf="key !== 'range_of_development' && key !== 'activity_kind'">
              <span>{{option.key | doenkidsTranslate: translationPrefix + '.' + key }}</span>&nbsp;{{ ['duration', 'preparation'].includes(key) ? ('generic.minutes' | translate) + '&nbsp;' : '' }}
            </ng-container>

            <span class="count">
              ({{option.doc_count}})
            </span>
          </span>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
  </ng-container>
</ng-template>

<ng-template #tagsSelectionOptions let-options="options" let-key="key">
  <ng-container [formGroup]="form">
    <h4>{{ key | doenkidsTranslate: translationPrefix + '.labels' }}</h4>
    <mat-selection-list [formControlName]="key">
      <ng-container *ngIf="options['ou']">
        <ng-container *ngFor="let option of options['ou']['tags']['buckets']  | truncate_array: (showAllTags$ | async) ? 9999 : 25">
          <mat-list-option
            *ngIf="option.key"
            [value]="option.key"
            [selected]="form.get(key)?.value?.includes(option.key)"
            >
            <span class="option-label pinned-tag-aggregation">
              <mat-icon>push_pin</mat-icon><span>{{ option.key | doenkidsTranslate: translationPrefix + '.' + key }}</span>&nbsp;

              <span class="count">
                ({{option.doc_count}})
              </span>
            </span>
          </mat-list-option>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="options['dk']">
      <ng-container *ngFor="let option of options['dk']['tags']['buckets'] | truncate_array: (showAllTags$ | async) ? 9999 : 25">
          <mat-list-option
            *ngIf="option.key"
            [value]="option.key"
            [selected]="form.get(key)?.value?.includes(option.key)"
            >
            <span class="option-label">
              <span>{{ option.key | doenkidsTranslate: translationPrefix + '.' + key }}</span>&nbsp;

              <span class="count">
                ({{option.doc_count}})
              </span>
            </span>
          </mat-list-option>
        </ng-container>
      </ng-container>
    </mat-selection-list>
    <button mat-flat-button color="primary" class="show-all-tags-button" (click)="showMoreTags()" *ngIf="!(showAllTags$ | async)">{{ 'generic.show_all' | translate }}</button>
  </ng-container>
</ng-template>
